import React from 'react';
import { contactPersonListParams } from '../consts/contactPerson';
import User from '../service/types/User';
import CustomTableBody from './CustomTableBody';

interface Props {
	contactPersons: User[];
	onEditPress: (user: User) => void;
}

function ContactPersonList(props: Props) {
	const transformedContactPersons = React.useMemo(() => {
		return props.contactPersons.map((contactPerson, index) => ({ ...contactPerson, number: index + 1 }));
	}, [props.contactPersons]);

	return (
		<CustomTableBody
			data={transformedContactPersons}
			dataTablePramas={contactPersonListParams}
			margin={'0px'}
			tableKey={'ContactPerson'}
			onLinkClick={props.onEditPress}
		/>
	);
}

export default ContactPersonList;
