import * as React from 'react';

function ExpensessIcon(props: any) {
	return (
		<svg width={18} height={16} viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="square">
				<path
					d="M4.2 14.966H2.6c-.884 0-1.6-.695-1.6-1.552V1h5.867l1.6 2.586H17v9.828c0 .857-.716 1.552-1.6 1.552h-1.6"
					fill="#FFF7CC"
				/>
				<path d="M9.213 7v8M11 8.428c-.867-.509-3.653-.892-3.653.857 0 2.095 3.469 1.356 3.469 3.204 0 1.849-2.255 1.71-3.816 1.11" />
			</g>
		</svg>
	);
}

export default ExpensessIcon;
