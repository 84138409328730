import { UserRole } from '../service/types/User';

export enum Routes {
	Login = '/login',
	QAILogin = '/qailogin',
	Home = '/home',
	ForgotPassword = '/forgotpassword',
	ResetPassword = '/resetpassword',
	Settings = '/settings',
	Customer = '/customer',
	Dashboard = '/dashboard',
	AdminContracts = '/admincontracts',
	Users = '/users',
	User = '/user',
	Contract = '/contract',
	Customers = '/customers',
	ProcessContract = '/processcontract',
	ProcessContracts = '/processcontracts',
	StandardCaseInput = '/standardinput',
	ZoneOverview = '/zoneoverview',
	ContactUs = '/contactus',
	ContactPerson = '/contactperson',
	RhenusCaseInput = '/rhenusinput',
	WorkingTime = '/workingtime',
	Expenses = '/expenses',
	Summary = '/summary',
	ChangePassowrd = '/changepassword',
	CustomerContracts = '/customercontracts',
	Invoice = '/invoice',
	GroupOverview = '/groupoverview',
	GroupCreate = '/groupcreate',
	GroupUpdate = '/groupupdate',
	Entities = '/entities',
}

export const routeParamKeys = {
	contract_id: 'contract_id',
	invoice_id: 'invoice_id',
	customer_id: 'customer_id',
	zone_id: 'zone_id',
	user_id: 'user_id',
	id: 'id',
};

export interface RouteParams {
	contract_id?: string;
	invoice_id?: string;
	customer_id?: string;
	user_id?: string;
	zone_id?: string;
	id?: string;
}

export const RoutesName = {
	Login: Routes.Login,
	QAILogin: Routes.QAILogin,
	Home: Routes.Home,
	ForgotPassword: Routes.ForgotPassword,
	ResetPassword: Routes.ResetPassword + `/:${routeParamKeys.id}`,
	Settings: Routes.Settings,
	Customer: Routes.Customer + `/:${routeParamKeys.customer_id}?`,
	Dashboard: Routes.Dashboard + `/:${routeParamKeys.contract_id}`,
	AdminContracts: Routes.AdminContracts,
	Users: Routes.Users,
	User: Routes.User + `/:${routeParamKeys.user_id}?`,
	Contract: Routes.Contract + `/:${routeParamKeys.contract_id}?`,
	Customers: Routes.Customers,
	ProcessContract: Routes.ProcessContract + `/:${routeParamKeys.contract_id}`,
	ProcessContracts: Routes.ProcessContracts,
	StandardCaseInput: Routes.StandardCaseInput + `/:${routeParamKeys.contract_id}/:${routeParamKeys.id}?`,
	ZoneOverview: Routes.ZoneOverview + `/:${routeParamKeys.contract_id}/:${routeParamKeys.zone_id}`,
	ContactUs: Routes.ContactUs,
	ContactPerson: Routes.ContactPerson + `/:${routeParamKeys.customer_id}/:${routeParamKeys.user_id}?`,
	RhenusCaseInput:
		Routes.RhenusCaseInput + `/:${routeParamKeys.contract_id}/:${routeParamKeys.zone_id}/:${routeParamKeys.id}?`,
	WorkingTime: Routes.WorkingTime + `/:${routeParamKeys.contract_id}`,
	Expenses: Routes.Expenses + `/:${routeParamKeys.contract_id}`,
	Summary: Routes.Summary + `/:${routeParamKeys.contract_id}`,
	ChangePassowrd: Routes.ChangePassowrd,
	CustomerContracts: Routes.CustomerContracts,
	Invoice: Routes.Invoice + `/:${routeParamKeys.invoice_id}`,
	GroupOverview: Routes.GroupOverview,
	GroupCreate: Routes.GroupCreate,
	GroupUpdate: `${Routes.GroupUpdate}/:id`,
	Entities: Routes.Entities,
};

export const RouteRestrictions = {
	Login: [
		UserRole.Administrator,
		UserRole.Customer,
		UserRole.QualityAssuranceInspector,
		UserRole.TeamLeader,
		UserRole.Accounting,
	],
	CustomerContracts: [UserRole.Administrator, UserRole.Customer, UserRole.TeamLeader, UserRole.Accounting],
	Customers: [UserRole.Administrator, UserRole.TeamLeader, UserRole.Accounting],
	Contracts: [UserRole.Administrator, UserRole.TeamLeader, UserRole.Accounting],
	Settings: [
		UserRole.Administrator,
		UserRole.Customer,
		UserRole.QualityAssuranceInspector,
		UserRole.TeamLeader,
		UserRole.Accounting,
	],
	ChangePassowrd: [UserRole.Administrator, UserRole.Customer, UserRole.TeamLeader, UserRole.Accounting],
	Users: [UserRole.Administrator, UserRole.TeamLeader, UserRole.Accounting],
	ProcessContracts: [
		UserRole.Administrator,
		UserRole.QualityAssuranceInspector,
		UserRole.TeamLeader,
		UserRole.Accounting,
	],
	Invoice: [UserRole.Accounting],
	GroupOverview: [UserRole.Administrator, UserRole.Accounting],
	Entities: [UserRole.Administrator, UserRole.Accounting],
};
