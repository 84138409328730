import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
	addWorkingTimeFields,
	calculateTimeDifference,
	timeFormat,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { fonts } from '../consts/fonts';
import { Controller, useForm } from 'react-hook-form';
import { rules } from '../consts/rules';
import { useTranslation } from 'react-i18next';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import KeyValueAutoComplete from './KeyValueAutoComplete';
import WorkingTime from '../service/types/WorkingTime';
import StandardTimePicker from './StandardTimePicker';
import moment from 'moment';
import ErrorIcon from '../svgComponents/ErrorIcon';
import DeleteIcon from '../svgComponents/DeleteIcon';
import RemoveIcon from '../svgComponents/RemoveIcon';
import EditIcon from '../svgComponents/EditIcon';
import SaveIcon from '../svgComponents/SaveIcon';
import { createWorkingTimeWithID } from '../helpers/workingTimeHelper/updateWorkingTimeHelper';
import BilledItem from './BilledItem';
import { billedOptions } from '../helpers/expenses/generalExpensesHelper';
import { UserRole } from '../service/types/User';
import { useAuthStateContext } from '../context/auth/useAuth';
import { priceListOptions } from '../consts/priceListOptions';
import StandardDatePicker from './StandardDatePicker';

interface Props {
	index: number;
	record: WorkingTime;
	users: KeyValue[];
	onSaveRecordPress: (record: WorkingTime) => Promise<boolean | void>;
	onDeletePress: (record: WorkingTime) => void;
}
function RecordItem({ index, record, users, onSaveRecordPress, onDeletePress }: Props) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const [edit, setEdit] = React.useState(false);
	const classes = useStyles({ edit: edit });
	const { control, errors, watch, handleSubmit, setValue } = useForm();
	const { user } = useAuthStateContext();
	const fromTime = watch(addWorkingTimeFields.from);
	const toTime = watch(addWorkingTimeFields.to);

	const qai = React.useMemo(() => {
		return users.find((user) => user.value.id === record.qai);
	}, [record, users]);

	const reporter = React.useMemo(() => {
		return users.find((user) => user.value.id === record.reporter);
	}, [record, users]);

	const timeDiff = React.useMemo(() => {
		return calculateTimeDifference(fromTime, toTime);
	}, [fromTime, toTime]);

	const time = React.useMemo(() => {
		if (edit) {
			const duration = moment.duration(moment(toTime).diff(moment(fromTime)));
			return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
		} else {
			const duration = moment.duration(moment(record.endTime).diff(moment(record.startTime)));
			return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
		}
	}, [edit, toTime, fromTime, record]);

	React.useEffect(() => {
		if (edit) {
			setValue(addWorkingTimeFields.from, record.startTime);
			setValue(addWorkingTimeFields.to, record.endTime);
		}
	}, [edit, setValue, record]);

	const onEditPress = () => setEdit(true);

	const onSavePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				onSaveRecordPress(createWorkingTimeWithID(data, record, timeDiff))
					.then((res) => {
						if (res) {
							setEdit(false);
						}
					})
					.catch(() => {});
			}),
		[onSaveRecordPress, record, timeDiff, handleSubmit]
	);

	const onDeletePressHandler = () => onDeletePress(record);

	const onCancelPress = () => setEdit(false);

	const defaultProfession = React.useMemo(() => {
		const selectedProfession = priceListOptions.find((item) => item.value === record.profession);

		return selectedProfession
			? {
					key: selectedProfession.label,
					value: selectedProfession.value,
			  }
			: null;
	}, [record.profession]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.itemWrapper}>
				<div className={classes.indexWrapper}>
					<span className={globalClasses.smallText}> {`${index + 1}.`}</span>
				</div>
				<div>
					<div className={classes.itemWrapper}>
						<div className={classes.fromWrapper}>
							{!edit ? (
								<span className={globalClasses.smallTextDark}>
									{record.startTime.format(timeFormat)}
								</span>
							) : (
								<Controller
									defaultValue={record.startTime}
									control={control}
									name={addWorkingTimeFields.from}
									rules={{
										required: rules(t).required,
									}}
									render={({ onChange, value }) => (
										<StandardTimePicker
											label={''}
											value={value}
											input={globalClasses.smallTextDark}
											onChange={onChange}
											inputWrapper={classes.timeInputWrapper}
										/>
									)}
								/>
							)}
						</div>
						<div className={classes.dashWrapper}>
							<span className={globalClasses.smallTextDark}>&#8212;</span>
						</div>
						<div className={classes.toWrapper}>
							{!edit ? (
								<span className={globalClasses.smallTextDark}>{record.endTime.format(timeFormat)}</span>
							) : (
								<Controller
									defaultValue={record.endTime}
									control={control}
									name={addWorkingTimeFields.to}
									rules={{
										required: rules(t).required, //@ts-ignore
										validate: (item) => {
											if (
												moment(item).format(timeFormat) !== moment(fromTime).format(timeFormat)
											) {
												return true;
											} else return t('working_time_screen_add_error_time');
										},
									}}
									render={({ onChange, value }) => (
										<StandardTimePicker
											label={''}
											input={globalClasses.smallTextDark}
											value={value}
											onChange={onChange}
											inputWrapper={classes.timeInputWrapper}
										/>
									)}
								/>
							)}
						</div>
					</div>
					{errors[addWorkingTimeFields.to] ? (
						<div className={classes.itemWrapper}>
							<div className={classes.errorImageWrapper}>
								<ErrorIcon />
							</div>
							<span className={globalClasses.error}>{errors[addWorkingTimeFields.to].message}</span>
						</div>
					) : null}
				</div>
			</div>
			<div className={classes.itemWrapper}>
				<span className={globalClasses.smallTextDark}>{reporter?.key}</span>
			</div>
			<div className={classes.itemWrapper}>
				{!edit ? (
					<span className={globalClasses.smallTextDark}>{qai?.key}</span>
				) : (
					<Controller
						defaultValue={qai}
						control={control}
						name={addWorkingTimeFields.qai}
						rules={{
							required: rules(t).required,
							validate: (val: KeyValue | undefined) => {
								if (val && val.value) {
									return true;
								}
								return rules(t).required.message;
							},
						}}
						render={({ onChange, value }) => (
							<KeyValueAutoComplete
								label={''}
								value={value}
								inputWrapper={classes.inputAddWrapper}
								placeholder={t('working_time_screen_add_placeholder')}
								onChange={onChange}
								wrapper={classes.inputKeyValueWrapper}
								options={users.filter((user) => !user.value.deleted)}
								input={globalClasses.inputTableStyle}
								withoutArrow={true}
								withoutClose={true}
								error={errors[addWorkingTimeFields.qai] ? true : false}
								helperText={
									errors[addWorkingTimeFields.qai] ? errors[addWorkingTimeFields.qai].message : ''
								}
							/>
						)}
					/>
				)}
			</div>
			<div className={classes.itemWrapper}>
				{!edit ? (
					<span className={globalClasses.smallTextDark}>
						{priceListOptions[record.profession] ? t(priceListOptions[record.profession].label) : '-'}
					</span>
				) : (
					<Controller
						defaultValue={defaultProfession}
						control={control}
						name={addWorkingTimeFields.profession}
						rules={{
							required: rules(t).required,
						}}
						render={({ onChange, value }) => (
							<KeyValueAutoComplete
								label=""
								value={value}
								inputWrapper={classes.autoCompleteInputAddWrapper}
								placeholder={t('working_time_screen_select_profession')}
								onChange={onChange}
								wrapper={classes.autoCompletewrapper}
								options={priceListOptions.map((option) => ({
									key: option.label,
									value: option.value,
								}))}
								customGetOptionLabel={(option) => t(option.key)}
								input={globalClasses.inputTableStyle}
								withoutArrow={true}
								withoutClose={true}
								error={errors[addWorkingTimeFields.profession] ? true : false}
								helperText={
									errors[addWorkingTimeFields.profession]
										? errors[addWorkingTimeFields.profession].message
										: ''
								}
							/>
						)}
					/>
				)}
			</div>
			{user?.role !== UserRole.QualityAssuranceInspector ? (
				<div className={classes.itemWrapper}>
					{!edit ? (
						<BilledItem billed={record.billed!} />
					) : (
						<Controller
							defaultValue={billedOptions
								.map((item) => ({ ...item, key: t(item.key) }))
								.find((val) => val.value === record.billed)}
							control={control}
							name={addWorkingTimeFields.billed}
							rules={{
								required: rules(t).required,
								validate: (val: KeyValue | undefined) => {
									if (val && val.value !== undefined) {
										return true;
									}
									return rules(t).required.message;
								},
							}}
							render={({ onChange, value }) => (
								<KeyValueAutoComplete
									label={''}
									value={value}
									inputWrapper={classes.inputAddWrapper}
									placeholder={'working_time_screen_status'}
									onChange={onChange}
									wrapper={classes.inputKeyValueWrapper}
									options={billedOptions.map((item) => ({ ...item, key: t(item.key) }))}
									input={globalClasses.inputTableStyle}
									withoutArrow={true}
									withoutClose={true}
									error={errors[addWorkingTimeFields.billed] ? true : false}
									helperText={
										errors[addWorkingTimeFields.billed]
											? errors[addWorkingTimeFields.billed].message
											: ''
									}
								/>
							)}
						/>
					)}
				</div>
			) : null}
			<div className={classes.itemWrapperSpaceBetween}>
				<div className={classes.leftWrapper}>
					<div className={classes.itemWrapperEnd}>
						<div className={classes.timeTitleWrapper}>
							<span className={classes.smallTextLight}>{`${t('working_time_screen_time')}: `}</span>
						</div>
						<div>
							<span className={globalClasses.mediumTextDark}>{time}</span>
						</div>
					</div>

					<div className={classes.itemWrapper}>
						<div className={classes.toWrapper}>
							{!edit ? (
								<span className={globalClasses.smallTextDark}>{record.date.format('DD.MM.YYYY')}</span>
							) : (
								<Controller
									defaultValue={record.date}
									control={control}
									name={addWorkingTimeFields.date}
									rules={{ required: rules(t).required }}
									render={({ onChange, value }) => (
										<StandardDatePicker
											label=""
											onChange={onChange}
											isUserNotEditing={true}
											disabled={false}
											value={value}
											inputWrapper={classes.dateTimeInputWrapper}
											wrapper={classes.dateTimeWrapper}
											error={errors[addWorkingTimeFields.date] ? true : false}
											helperText={
												errors[addWorkingTimeFields.date]
													? errors[addWorkingTimeFields.date].message
													: ''
											}
										/>
									)}
								/>
							)}
						</div>
					</div>
				</div>
				{
					<div className={classes.buttonsWrapper}>
						{edit ? (
							<SaveIcon
								onClick={onSavePress}
								height={18}
								width={18}
								className={classes.editIconWrapper}
							/>
						) : (
							<EditIcon
								onClick={onEditPress}
								height={18}
								width={18}
								className={classes.editIconWrapper}
							/>
						)}
						{edit ? (
							<RemoveIcon
								onClick={onCancelPress}
								height={18}
								width={18}
								className={classes.closeIconWrapper}
							/>
						) : (
							<DeleteIcon onClick={onDeletePressHandler} className={classes.closeIconWrapper} />
						)}
					</div>
				}
			</div>
		</div>
	);
}

interface StyleProps {
	edit: boolean;
}

const useStyles = makeStyles(() => ({
	inputAddWrapper: {
		width: '100%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
	},
	inputWrapper: {
		width: '95%',
	},
	indexWrapper: {
		display: 'flex',
		marginRight: '24px',
		alignItems: 'center',
	},
	fromWrapper: {
		display: 'flex',
		marginRight: '16px',
		alignItems: 'center',
	},
	toWrapper: {
		display: 'flex',
		marginLeft: '16px',
		alignItems: 'center',
	},
	timeTitleWrapper: {
		display: 'flex',
		marginRight: '12px',
		alignItems: 'center',
	},

	wrapper: {
		display: 'flex',
		padding: '20px 16px',
		margin: '4px 0px',
		border: `1px solid ${colors.timetrackBorderd}`,
		//backgroundColor: colors.billGreen,
	},
	itemWrapper: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
	buttonsWrapper: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginLeft: '16px',
	},
	smallTextLight: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.gray,
		letterSpacing: 0.4,
	},
	itemWrapperSpaceBetween: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	itemWrapperEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	timeInputWrapper: {
		width: '45px',
		paddingLeft: '5px',
		transform: 'translateX(-6px)',
		padding: '0',
		border: `1px solid ${colors.addTimeBorder}`,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	inputKeyValueWrapper: {
		width: '95%',
		padding: '0px 0px',
		'& .MuiInput-input': {
			margin: 0,
			color: colors.darkGray,
			fontSize: '14px',
			lineHeight: '20px',
			fontFamily: fonts.regular,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
	},
	dashWrapper: (p: StyleProps) => ({
		transform: p.edit ? 'translate(-6px)' : '',
	}),
	editIconWrapper: {
		marginRight: '16px',
		cursor: 'pointer',
	},
	closeIconWrapper: {
		cursor: 'pointer',
	},
	input: {
		...globalInputStyle(),
	},
	inputWrapperDropdown: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	dateTimeInputWrapper: {
		width: '120px',
		border: `1px solid ${colors.addTimeBorder}`,
		marginLeft: 'auto',
	},
	dateTimeWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	autoCompleteInputAddWrapper: {
		width: '80%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
	},

	autoCompletewrapper: {
		width: '100%',
		padding: '0px 0px',
		'& .MuiInput-input': {
			margin: 0,
			color: colors.darkGray,
			fontSize: '14px',
			lineHeight: '20px',
			fontFamily: fonts.regular,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
	},
	leftWrapper: {
		display: 'flex',
		alignItem: 'center',
	},
}));

export default RecordItem;
