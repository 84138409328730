import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';

import { useGlobalStyles } from '../consts/globalStyles';
import FilterIcon from '../svgComponents/FilterIcon';

interface Props {
	onFilterPress: () => void;
	onClearAllPress: () => void;
}

function Filter(props: Props) {
	const globalClasses = useGlobalStyles();
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.wrapper}>
			<div className={classes.row}>
				<div className={classes.iconWrapper}>
					<FilterIcon />
				</div>
				<span className={globalClasses.filter} onClick={props.onFilterPress}>
					{t('filter_component_title')}
				</span>
			</div>
			<div className={classes.clearWrapper} onClick={props.onClearAllPress}>
				<span className={classes.clear}>{t('filter_component_clear')}</span>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
	},
	iconWrapper: {
		marginRight: '11px',
		display: 'flex',
		alignItems: 'center',
	},
	clearWrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	clear: {
		fontSize: '14px',
		lineHeight: '20px',
		color: colors.darkGray,
		fontFamily: fonts.regular,
	},
}));

export default Filter;
