import React from 'react';
import User from '../../service/types/User';
import { AuthState } from './authReducer';

export type AuthContextActions = {
	setUser: (user: User | undefined) => void;
};

const initialStateContext: AuthState = {
	user: undefined,
};

const initalActionContext: AuthContextActions = {
	setUser: (user: User | undefined) => {},
};

export const AuthStateContext = React.createContext(initialStateContext);
export const AuthActionsContext = React.createContext(initalActionContext);
