export const LANGUAGE_KEY = 'LANGUAGE_KEY';

export interface LanguageInterface {
	i18n: string;
	key: string;
}

export const languages = {
	english: {
		i18n: 'en',
		key: 'EN',
	},
	deutchland: {
		i18n: 'de',
		key: 'DE',
	},
};
