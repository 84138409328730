import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAuthActionContext, useAuthStateContext } from '../context/auth/useAuth';
import { Routes } from '../router/Routes';
import LoginScreen from '../screens/LoginScreen';
import { onAuthTokenError, onAuthTokenSuccess } from '../helpers/authHelper';
import { getDataForLogin } from '../consts/login';
import { Dialog } from '@material-ui/core';
import Transition from '../components/Transition';
import PinDialog from '../components/PinDialog';
import { setLoginError } from '../helpers/loginHelper';
import { goToForgotPassword } from '../helpers/navigationHelper';
import { StandardDialogInterface } from '../components/StandardDialog';
import { colors } from '../consts/colors';
import { useWorkingTimeActionContext } from '../context/workingTime/useWorkingTime';
import service from '../service/service';
import User, { UserRole } from '../service/types/User';
import { userAtom } from '../atoms/userAtom';
import { useAtom } from 'jotai';

interface Props {
	legalNoticeRef: React.MutableRefObject<null | StandardDialogInterface>;
	type: LoginType;
}

export enum LoginType {
	QALogin,
	StandardLogin,
}

export const loginFields = {
	email: 'email',
	password: 'password',
};

export type LoginFields = {
	email: string;
	password: string;
};

function LoginContainer({ legalNoticeRef, type }: Props) {
	const history = useHistory();
	const { control, handleSubmit, errors, setValue, watch, setError } = useForm();
	const { user } = useAuthStateContext();
	const { setUser } = useAuthActionContext();
	const { setRecord } = useWorkingTimeActionContext();
	const [, setUserAtom] = useAtom(userAtom);

	const [showPinModal, setShowPinModal] = React.useState(false);
	const [pin, setPin] = React.useState('');

	const dataForLogin = React.useMemo(() => getDataForLogin(type), [type]);

	React.useEffect(() => {
		if (user) {
			history.replace(Routes.Home);
		}
	}, [user, history]);

	const login = async ({ email, password }: LoginFields) => {
		try {
			const loginRes = await dataForLogin.login(email, password);

			if (loginRes) {
				const user = User.fromJWT(loginRes.at);
				if (user.role !== UserRole.Customer) {
					service.checkTrackingTime().then((res) => {
						setRecord(res);
					});
				}
				onAuthTokenSuccess(loginRes.at, setUser);
				history.replace(Routes.Home);
				if (loginRes.acceptedPp !== undefined && !loginRes.acceptedPp) {
					legalNoticeRef.current?.setDialogState(true);
				}
				setUserAtom(loginRes.at);
			}
		} catch (e) {
			if (e instanceof Error) {
				const error = JSON.parse(e.message);
				setLoginError(error, setError);
			}
			onAuthTokenError(setUser);
		}
	};

	const onPinPress = () => setShowPinModal(true);

	const onOKButtonPress = (val: string) => {
		setPin(val);
		setValue(loginFields.password, val);
		setShowPinModal(false);
	};

	return (
		<>
			<LoginScreen
				onLoginPress={handleSubmit(login)}
				setValue={setValue}
				watch={watch}
				control={control}
				onForgotPasswordPress={() => goToForgotPassword(history, type)}
				errors={errors}
				dataForLogin={dataForLogin}
				onPinPress={onPinPress}
				pin={pin}
			/>
			<Dialog
				fullScreen
				open={showPinModal}
				onClose={() => setShowPinModal(false)}
				PaperProps={{
					style: { backgroundColor: colors.backgroundColor },
				}}
				TransitionComponent={Transition}>
				<PinDialog
					pin={pin}
					onBackArrowPress={() => setShowPinModal(false)}
					onForgotPasswordPress={() => goToForgotPassword(history, type)}
					onOKButtonPress={onOKButtonPress}
					dataForLogin={dataForLogin}
				/>
			</Dialog>
		</>
	);
}

export default LoginContainer;
