import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { colors } from '../../consts/colors';
import { fonts } from '../../consts/fonts';
import { useGlobalStyles } from '../../consts/globalStyles';

interface Props {
	buttonText: string;
	index?: number;
	redirectTo?: string;
	value: string;
	onClick: () => void;
}

function WithButtonCell({ buttonText, index, redirectTo, value, onClick }: Props) {
	const globalClasses = useGlobalStyles({ description: colors.white });
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.wrapper}>
			<span className={globalClasses.description}>{value}</span>
			{index && redirectTo ? (
				<Link className={classes.buttonWrapper} to={`${redirectTo}/${index}`}>
					<span className={classes.buttonText}>{t(buttonText)}</span>
				</Link>
			) : (
				<div className={classes.buttonWrapper} onClick={onClick}>
					<span className={classes.buttonText}>{t(buttonText)}</span>
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	buttonText: {
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '0.4px',
		fontFamily: fonts.semiBold,
		color: colors.lightDarkGray,
	},
	buttonWrapper: {
		cursor: 'pointer',
		padding: '4px 19px',
		margin: '0px 20px',
		borderRadius: '4px',
		backgroundColor: colors.yellow,
	},
}));

export default WithButtonCell;
