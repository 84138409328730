import * as React from 'react';

function PdfAttachmentIcon(props: any) {
	return (
		<svg width={19} height={22} viewBox="0 0 19 22" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" strokeWidth={1.5} fill="none" fillRule="evenodd">
				<path d="M11.91 21v-5.455h5.454" />
				<path
					strokeLinecap="square"
					d="M11.91 21H1V1h16.364v14.545zM4.636 5.545h9.091M4.636 10.091h9.091M4.636 14.636h3.637"
				/>
			</g>
		</svg>
	);
}

export default PdfAttachmentIcon;
