import { Service } from '../../service/service';
import User from '../../service/types/User';
import { History, LocationState } from 'history';
import { goToUsers } from '../navigationHelper';
import { NotificationInterface } from '../../context/notification/notificationReducer';
import { Group } from '../../pages/groupOverview/GroupOverview';

export function deleteUser(
	service: Service,
	user: User | undefined,
	history: History<LocationState>,
	setNotification: (notification: NotificationInterface) => void,
	setGroups: (groups: Group[]) => void
) {
	if (user)
		service
			.deleteUserByID(user.id)
			.then((res) => {
				if (res) {
					// This will force new fetch on groups screen
					setGroups([]);

					goToUsers(history);

					setNotification({
						text: 'user_screen_delete_notification',
						additional: `${user.firstName} ${user.lastName}`,
					});
				}
			})
			.catch(() => {});
}
