import { contactPersonFields } from '../../containers/ContactPersonContainer';
import User from '../../service/types/User';
import { ContactPersonNavigationInterface, goToCustomers, goToHome } from '../navigationHelper';
import { LocationState, History } from 'history';
import { Service } from '../../service/service';
import { RouteParams } from '../../router/Routes';

export function setContactPersonInfo(contactPerson: User, setValue: (field: string, value: any) => void) {
	setValue(contactPersonFields.firstName, contactPerson.firstName);
	setValue(contactPersonFields.lastName, contactPerson.lastName);
	setValue(contactPersonFields.phonenumber, contactPerson.phonenumber);
	setValue(contactPersonFields.email, contactPerson.email);
}

export function onContactScreenMount(
	history: History<LocationState>,
	service: Service,
	routeParams: RouteParams,
	setContactPerson: (contactPerson: User | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	setCustomerID: (val: number) => void,
	setMounted: (val: boolean) => void
) {
	const historyState = history.location.state as undefined | ContactPersonNavigationInterface;
	if (historyState && historyState.contactPerson && routeParams.customer_id) {
		setCustomerID(Number(routeParams.customer_id));
		setContactPerson(historyState.contactPerson);
		setMounted(true);
	} else if (historyState && historyState.contactPersonID) {
		service
			.getCustomerFromUser(historyState.contactPersonID)
			.then((res) => {
				if (res) {
					setMounted(true);
					setCustomerID(res.id);
					setContactPerson(res.users.find((user) => user.id === historyState.contactPersonID));
					history.replace(history.location.pathname.replace('/0/', `/${res.id}/`));
				} else {
					history.goBack();
				}
			})
			.catch((e) => {
				history.goBack();
			});
	} else if (routeParams.customer_id && routeParams.user_id) {
		service
			.getUserByID(Number(routeParams.user_id))
			.then((res) => {
				if (res) {
					setMounted(true);
					setCustomerID(Number(routeParams.customer_id));
					setContactPerson(res);
				} else {
					history.goBack();
				}
			})
			.catch((e) => {
				goToHome(history);
			});
	} else if (routeParams.customer_id) {
		setIsUserEditing(true);
		setContactPerson(undefined);
		setCustomerID(Number(routeParams.customer_id));
		setMounted(true);
	} else {
		goToCustomers(history);
	}
}
