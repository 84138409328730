import React from 'react';

import { languages, LANGUAGE_KEY } from '../consts/language';
import Language from './Language';
import i18n from '../i18n';
import { makeStyles } from '@material-ui/core';

interface Props {
	inverted?: boolean;
}

function LanguageList(props: Props) {
	const classes = useStyles();
	const [activeLanguage, setActiveLanguage] = React.useState(localStorage.getItem(LANGUAGE_KEY));

	const onLanguagePress = (language: string) => {
		i18n.changeLanguage(language);
		localStorage.setItem(LANGUAGE_KEY, language);
		setActiveLanguage(language);
	};

	return (
		<div className={classes.row}>
			{Object.values(languages).map((language, index, array) => (
				<Language
					language={language}
					margin={index + 1 !== array.length}
					activeLanguage={activeLanguage}
					inverted={props.inverted}
					onLanguagePress={onLanguagePress}
					key={`Language${language.key}`}
				/>
			))}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
	},
}));

export default LanguageList;
