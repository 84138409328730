import { makeStyles } from '@material-ui/core';
import { colors } from './colors';
import { fonts } from './fonts';

export const globalInputStyle = (disabled?: boolean) => ({
	margin: 0,
	color: disabled ? colors.lightGray : colors.darkGray,
	fontSize: '16px',
	lineHeight: '24px',
	fontFamily: fonts.regular,
	opacity: 1,
});

interface StyleProps {
	disabled?: boolean;
	fixed?: boolean;
	description?: string;
	isTableTitleOpen?: boolean;
	showFilters?: boolean;
	isUserNotEditing?: boolean;
	number?: boolean;
	inputColor?: string;
	window?: {
		width: number;
		height: number;
	};
	filterHeight?: number;
}

const baseTableTitle = (p: StyleProps) => ({
	backgroundColor: colors.backgroundColor,
	borderWidth: '1px 1px 2px 1px',
	borderStyle: 'solid',
	borderColor: p.isTableTitleOpen
		? `#D0CECE #D0CECE #1A1A1A #D0CECE!important`
		: `rgba(0,0,0,0) rgba(0,0,0,0) #1A1A1A rgba(0,0,0,0)!important`,
	cursor: p.isTableTitleOpen !== undefined ? 'pointer' : 'auto',
	borderBottom: '2px solid #1A1A1A!important',
	borderBottomColor: '#1A1A1A!important',
	left: '0px',
	top: '0px',
	zIndex: 10,
});

export const useGlobalStyles = makeStyles(() => ({
	label: (props: StyleProps) => ({
		color: (props.disabled && !props.fixed) || props.isUserNotEditing ? colors.lightGray : colors.gray,
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		whiteSpace: 'pre',
	}),
	title: {
		color: colors.darkGray,
		fontSize: '40px',
		lineHeight: '60px',
		fontFamily: fonts.bold,
	},
	buttonTextYellow: {
		color: colors.yellow,
		lineHeight: '24px',
		fontSize: '16px',
		fontFamily: fonts.semiBold,
	},
	buttonText: {
		color: colors.darkGray,
		lineHeight: '24px',
		fontSize: '16px',
		fontFamily: fonts.semiBold,
	},
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '12px 32px',
		margin: '15px',
		cursor: 'pointer',
	},
	buttonWrapperBlack: {
		width: 'fit-content',
		backgroundColor: colors.black,
		borderRadius: 4,
		padding: '12px 32px',
		margin: '15px',
		cursor: 'pointer',
	},
	buttonYellowText: {
		color: colors.yellow,
		lineHeight: '24px',
		fontSize: '16px',
		fontFamily: fonts.semiBold,
	},
	buttonDarkWrapper: {
		width: 'fit-content',
		backgroundColor: '#000',
		borderRadius: 4,
		margin: '15px',
		// border: `1px solid ${colors.yellow}`,
		padding: '12px 32px',
		cursor: 'pointer',
	},
	buttonWrapperSecondary: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		padding: '12px 32px',
		cursor: 'pointer',
		marginLeft: '12px',
		height: 'fit-content',
		border: `1px solid ${colors.darkGray}`,
	},
	link: {
		color: colors.darkGray,
		fontSize: '12px',
		lineHeight: '16px',
		textDecoration: 'underline',
		fontFamily: fonts.regular,
	},
	linkWrapper: {
		width: 'fit-content',
		cursor: 'pointer',
	},
	description: (p: StyleProps) => ({
		color: p.description ? p.description : colors.darkGray,
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
	}),
	filter: {
		textDecoration: 'underline',
		color: colors.darkGray,
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
		cursor: 'pointer',
	},
	homeButtonWrapper: {
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		margin: '20px 0px 16px auto',
	},
	backArrowWrapper: {
		position: 'absolute',
		top: '30px',
		left: '32px',
	},
	tableTitle: {
		fontFamily: fonts.semiBold,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.darkGray,
	},
	tableTitleWrapper: (p: StyleProps) => ({
		...baseTableTitle(p),
		position: 'sticky',
	}),
	tableTitleWrapperSticky: (p: StyleProps) => ({
		...baseTableTitle(p),
		position: 'sticky',
		zIndex: 20,
	}),
	tableTitleWrapperStickyRight: (p: StyleProps) => ({
		...baseTableTitle(p),
		right: '0px',
		left: undefined,
		position: 'sticky',
		zIndex: 20,
	}),
	buttonTextInverted: {
		fontSize: '16px',
		lineHeight: '24px',
		color: colors.darkGray,
		fontFamily: fonts.semiBold,
	},
	buttonWrapperInverted: {
		marginLeft: '12px',
		height: 'fit-content',
		padding: '12px 29px',
		border: `1px solid ${colors.darkGray}`,
		borderRadius: '4px',
		backgroundColor: colors.white,
		cursor: 'pointer',
	},
	buttonWrapperPlain: {
		marginLeft: '42px',
		cursor: 'pointer',
	},
	error: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.error,
	},
	labelAddOn: {
		fontSize: '14px',
		lineHeight: '20px',
		color: colors.darkGray,
		fontFamily: fonts.regular,
		whiteSpace: 'pre',
		cursor: 'pointer',
	},
	subtitle: {
		fontSize: '20px',
		lineHeight: '32px',
		fontFamily: fonts.bold,
		color: colors.darkGray,
	},
	container: { paddingLeft: '32px', paddingRight: '32px', marginBottom: '40px' },
	filterWrapper: (p: StyleProps) => ({
		opacity: p.showFilters ? 1 : 0,
		pointerEvents: p.showFilters ? 'auto' : 'none',
		transition: 'all 1s',
	}),
	filterWrapperClosed: {
		opacity: 0,
		pointerEvents: 'none',
		maxHeight: '0px',
		transition: 'all 1s',
		marginTop: '11px',
	},
	filterWrapperOpened: (p: StyleProps) => ({
		opacity: 1,
		pointerEvents: 'auto',
		maxHeight: '100px',
	}),
	filterContainer: (p: StyleProps) => ({
		marginTop: p.showFilters ? '11px' : '0px',
		transition: 'all 1s',
	}),
	filterFieldWrapper: {
		margin: '8px 0px',
		padding: '0px 16px !important',
	},
	deleteLink: {
		fontSize: '16px',
		lineHeight: '24px',
		color: colors.darkGray,
		fontFamily: fonts.regular,
		textDecoration: 'underline',
	},
	deleteLinkWrapper: {
		marginLeft: '12px',
		cursor: 'pointer',
	},
	authContainerWrapper: (p: StyleProps) => ({
		width: p.window?.width,
		minHeight: p.window?.height,
		display: 'flex',
		alignItems: 'center',
	}),
	dialogTitle: {
		fontFamily: fonts.semiBold,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.darkGray,
	},
	dialogDescription: {
		fontFamily: fonts.regular,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.gray,
	},
	dialogDescriptionLink: {
		fontFamily: fonts.regular,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.gray,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	cameraButton: {
		fontFamily: fonts.semiBold,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.white,
	},
	underline: {
		height: '1px',
		width: '100%',
		backgroundColor: '#979797',
		opacity: 0.3,
	},
	errorInputMenuItem: {
		fontSize: '14px',
		lineHeight: '20px',
		color: colors.darkGray,
		fontFamily: fonts.regular,
	},
	errorInputMenuItemWrapper: {
		padding: '0 6px',
		backgroundColor: colors.lightPink,
		width: 'fit-content',
	},
	standardLink: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
		textDecoration: 'underline',
	},
	smallTextLight: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.lightGray,
	},
	smallerTextLight: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.gray,
		letterSpacing: 0.4,
	},
	smallText: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.gray,
	},
	smallTextDark: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
	},
	bigText: {
		fontSize: '20px',
		lineHeight: '32px',
		fontFamily: fonts.regular,
		color: colors.gray,
	},
	bigTextBold: {
		fontSize: '20px',
		lineHeight: '32px',
		fontFamily: fonts.bold,
		color: colors.darkGray,
	},

	mediumTextSemiLight: {
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.semiBold,
		color: colors.lightGray,
	},
	mediumTextSemiDark: {
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.semiBold,
		color: colors.darkGray,
	},
	mediumTextDark: {
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
	},
	inputTableStyle: (p: StyleProps) => ({
		margin: 0,
		textAlign: p.number ? 'end' : 'start',
		color: p.inputColor ? p.inputColor : colors.darkGray,
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		marginLeft: '6px',
	}),
	inputTableStyleNumber: (p: StyleProps) => ({
		margin: 0,
		textAlign: 'end',
		color: p.inputColor ? p.inputColor : colors.darkGray,
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		marginLeft: '6px',
	}),
	logo: { position: 'absolute', left: 32, top: 24 },
}));
