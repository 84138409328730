import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';

interface Props {
	billed: boolean;
	billable?: boolean;
}

function BilledItem(props: Props) {
	const classes = useStyles({ billed: props.billed, billable: props.billable });
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.wrapper}>
			<div className={classes.circle} />
			<div>
				<span
					className={globalClasses.smallTextDark}
					style={{ color: props.billable ? colors.gray : colors.darkGray }}>
					{props.billable ? t('Not billable') : t(props.billed ? 'Billed' : 'Not billed')}
				</span>
			</div>
		</div>
	);
}

interface StyleProps {
	billed: boolean;
	billable?: boolean;
}

const useStyles = makeStyles(() => ({
	circle: (p: StyleProps) => ({
		width: '8px',
		height: '8px',
		borderRadius: '4px',
		backgroundColor: p.billable ? colors.gray : p.billed ? colors.green : colors.red,
		marginRight: '8px',
	}),
	wrapper: (p: StyleProps) => ({
		padding: '4px 8px',
		backgroundColor: p.billable ? colors.borderColor : p.billed ? colors.lightGreen : colors.lightRed,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		minWidth: '50%',
		maxWidth: '80%',
	}),
}));

export default BilledItem;
