import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Controller, Control } from 'react-hook-form';
import { useWindowSize } from '@react-hook/window-size';

import { loginFields } from '../containers/LoginContainer';
import Button from '../components/Button';
import AuthTextField from '../components/AuthTextField';
import { LoginData } from '../consts/login';
import { useGlobalStyles } from '../consts/globalStyles';
import LanguageList from '../components/LanguageList';
import background from '../assets/images/backgroud.png';
import loginPhoto from '../assets/images/login_photo.png';
import IQRLogoBlack from '../svgComponents/IQRLogoBlack';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	dataForLogin: LoginData;
	pin: string;
	watch: (field: string) => any;
	setValue: (field: string, val: any) => void;
	onLoginPress: () => void;
	onForgotPasswordPress: () => void;
	onPinPress: () => void;
}

function LoginScreen(props: Props) {
	const classes = useStyles({});
	const [windowWidth, windowHeight] = useWindowSize();
	const globalClasses = useGlobalStyles({
		window: {
			width: windowWidth,
			height: windowHeight,
		},
	});
	const { t } = useTranslation();
	const password = props.watch(loginFields.password);

	return (
		<div className={globalClasses.authContainerWrapper}>
			<div className={classes.backgroundImageWrapper}>
				<img className={classes.backgroundImage} src={background} alt={'Background'} />
			</div>
			<div className={classes.loginImageWrapper}>
				<img className={classes.loginImage} src={loginPhoto} alt={'LoginImage'} />
			</div>
			<IQRLogoBlack className={globalClasses.logo} />
			<div className={classes.languageWarpper}>
				<LanguageList inverted={true} />
			</div>
			<Grid container>
				<Grid item sm={6} />
				<Grid item sm={5}>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t('login_screen_title')}</span>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={loginFields.email}
							rules={props.dataForLogin.rules(t).email}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('login_screen_email_placeholder')}
									error={props.errors[loginFields.email] ? true : false}
									helperText={
										props.errors[loginFields.email] ? props.errors[loginFields.email].message : ''
									}
									name="email"
									type="email"
									onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === 'Enter') {
											props.onLoginPress();
										}
									}}
								/>
							)}
						/>
					</div>
					<div
						className={classes.passwordWrapper}
						onClick={props.dataForLogin.disabled ? props.onPinPress : () => {}}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={loginFields.password}
							rules={props.dataForLogin.rules(t).password}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									value={props.dataForLogin.disabled ? props.pin : password ? password : ''}
									label={t(props.dataForLogin.label)}
									error={props.errors[loginFields.password] ? true : false}
									helperText={
										props.errors[loginFields.password]
											? props.errors[loginFields.password].message
											: ''
									}
									type={'password'}
									disabled={props.dataForLogin.disabled}
									onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === 'Enter') {
											props.onLoginPress();
										}
									}}
								/>
							)}
						/>
					</div>
					<div className={globalClasses.linkWrapper} onClick={props.onForgotPasswordPress}>
						<span className={globalClasses.link}>{t(props.dataForLogin.forgotTitle)}</span>
					</div>
					<div className={classes.buttonWrapper}>
						<Button
							onClick={props.onLoginPress}
							text={t('login_screen_button')}
							textClassName={globalClasses.buttonText}
							buttonWrapperClassName={globalClasses.buttonWrapper}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	buttonWrapper: {
		marginTop: '40px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},
	passwordWrapper: {
		marginBottom: '8px',
	},
	emailWrapper: {
		marginBottom: '20px',
	},
	languageWarpper: {
		position: 'absolute',
		top: '32px',
		right: '75px',
	},
	backgroundImage: {
		width: '100%',
	},
	backgroundImageWrapper: { position: 'absolute', top: 0, left: 0, right: 0 },
	loginImage: {
		height: '100%',
	},
	loginImageWrapper: { position: 'absolute', top: 0, left: 0, bottom: 0, zIndex: -1 },
}));

export default LoginScreen;
