import React from 'react';
import { Chip, CircularProgress, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete, createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import DropdownArrow from '../svgComponents/DropdownArrow';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { TextFieldWithCSS, useAutocompleteStyles } from './StandardAutoComplete';
import CheckboxEmpty from '../svgComponents/CheckboxEmpty';
import CheckboxFilled from '../svgComponents/CheckboxFilled';

const filter = createFilterOptions<KeyValue>();

export interface StandardAutoCompleteRef {
	setLoading: (val: boolean) => void;
	getLoading: () => boolean;
}

interface Props {
	getOptionSelected?: (option: KeyValue, value: KeyValue) => boolean;
	label: string;
	options: KeyValue[];
	error?: boolean;
	helperText?: string;
	multiple?: boolean;
	freeSolo?: boolean;
	disabled?: boolean;
	fixed?: boolean;
	value?: KeyValue | KeyValue[];
	labelAddOn?: JSX.Element;
	zoneName?: string;
	withoutArrow?: boolean;
	withoutClose?: boolean;
	inputWrapper?: string;
	placeholder?: string;
	wrapper?: string;
	input?: string;
	disableCloseOnSelect?: boolean;
	withCheckbox?: boolean;
	customGetOptionLabel?: (option: KeyValue) => string;
	onChange: (event: any) => void;
	onOpen?: () => void;
}

function KeyValueAutoComplete(props: Props, ref: any) {
	const { t } = useTranslation();
	const [rotateArrow, setRotateArrow] = React.useState(false);
	const [options, setOptions] = React.useState(props.options);
	const [loading, setLoading] = React.useState(false);
	const prevOptions = React.useRef(props.options);
	const [hasOptions, setHasOptions] = React.useState(true);
	const [focused, setFocused] = React.useState(false);
	const [limitTags, setLimitTags] = React.useState(10);
	const autoCompleteRef = React.useRef<any>(null);

	const classes = useAutocompleteStyles({
		rotate: rotateArrow,
		disabled: props.disabled,
		fixed: props.fixed,
		hasOptions: hasOptions,
		focused: focused,
		withCheckbox: props.withCheckbox,
	});

	const filterOptions = React.useMemo(
		() => (options: KeyValue[], params: FilterOptionsState<KeyValue>) => {
			const filtered = filter(options, params);
			if (filtered.length === 0 && !loading && props.freeSolo) {
				setHasOptions(false);
			} else {
				setHasOptions(true);
			}
			return filtered;
		},

		[loading, props.freeSolo]
	);

	React.useImperativeHandle(
		ref,
		(): StandardAutoCompleteRef => ({
			setLoading: (val: boolean) => setLoading(val),
			getLoading: () => loading,
		})
	);

	React.useEffect(() => {
		if (!prevOptions.current.some((prevOption) => props.options.some((option) => option === prevOption))) {
			prevOptions.current = props.options;
			setOptions(props.options);
		}
	}, [props.options]);

	React.useEffect(() => {
		if (props.multiple) {
			if (autoCompleteRef.current && 0 > 40 - autoCompleteRef.current.offsetHeight) {
				setLimitTags((val) => (val === 10 ? (props.value as KeyValue[]).length - 1 : val));
			}
		}
	}, [props.value, props.multiple]);

	return (
		<div className={props.wrapper}>
			<div className={classes.labelWrapper}>
				<span className={classes.label}>{props.label}</span>
				{props.labelAddOn ? props.labelAddOn : null}
			</div>
			<Autocomplete
				disabled={props.disabled}
				options={options}
				value={
					props.value
						? (props.value as KeyValue).value === ''
							? null
							: props.value
						: props.multiple
						? []
						: ''
				}
				classes={{
					paper: classes.dropdownContainer,
				}}
				limitTags={limitTags}
				ref={autoCompleteRef}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip
							label={option.key}
							{...getTagProps({ index })}
							className={classes.chipHeight}
							classes={{ deleteIcon: classes.deleteIcon }}
						/>
					))
				}
				closeIcon={props.withoutClose ? false : undefined}
				clearText=""
				openText=""
				closeText=""
				disableCloseOnSelect={props.disableCloseOnSelect}
				freeSolo={props.freeSolo}
				noOptionsText={t('autocomplete_no_options')}
				loadingText={t('autocomplete_loading')}
				onClose={() => setTimeout(() => setRotateArrow(false), 0)}
				onOpen={() => {
					setTimeout(() => setRotateArrow(true), 0);
					if (props.onOpen) props.onOpen();
				}}
				getOptionSelected={(option, value) => {
					if (props.getOptionSelected) return props.getOptionSelected(option, value);
					else {
						if (value.key === '') return false;
						return option.key === value.key;
					}
				}}
				popupIcon={
					!props.withoutArrow ? (
						<div className={classes.dropdownArrowWrapper}>
							<DropdownArrow className={classes.rotate} disabled={props.disabled} />
						</div>
					) : (
						false
					)
				}
				multiple={props.multiple}
				filterOptions={filterOptions}
				onChange={(_, newValue) => {
					if (props.multiple) {
						const tempNewValue = (newValue as KeyValue[]).map((item, index, array) => {
							if (index + 1 === array.length) {
								//return item.value.replace('Add "', '').replace('"', '');
							}
							return item;
						});
						if (
							tempNewValue &&
							tempNewValue.length !== 0 &&
							!options.some((value) => tempNewValue[tempNewValue.length - 1] === value)
						) {
							setOptions([...options, tempNewValue[tempNewValue.length - 1]]);
						}
						if (tempNewValue.length === 0) {
							props.onChange(undefined);
						} else {
							props.onChange(tempNewValue);
						}
					} else {
						props.onChange(newValue);
					}
				}}
				getOptionLabel={props.customGetOptionLabel ? props.customGetOptionLabel : (option) => option.key}
				renderOption={(option) => (
					<MenuItem key={option.key} value={option.value} className={classes.menuItem}>
						<div className={classes.dropDownInputWrapper}>
							{props.withCheckbox ? (
								props.value &&
								props.multiple &&
								(props.value as KeyValue[]).some((item: KeyValue) => item.value === option.value) ? (
									<div className={classes.checkboxWrapper}>
										<CheckboxFilled />
									</div>
								) : (
									<div className={classes.checkboxWrapper}>
										<CheckboxEmpty />
									</div>
								)
							) : null}
							<span className={classes.dropdownInput}>{t(option.key)}</span>
						</div>
					</MenuItem>
				)}
				loading={loading}
				renderInput={(params) => (
					<div className={classes.inputRow}>
						<TextFieldWithCSS
							{...params}
							InputProps={{
								...params.InputProps,
								onFocus: () => setFocused(true),
								onBlur: () => setFocused(false),
								style: {
									paddingRight: props.withoutArrow && props.withoutClose ? '0px' : '56px',
								},
								endAdornment: (
									<>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
							onChange={props.freeSolo ? (e) => props.onChange(e) : () => {}}
							label={undefined}
							error={props.error}
							helperText={''}
							placeholder={props.placeholder}
							className={props.inputWrapper ? props.inputWrapper : classes.inputWrapper}
						/>
					</div>
				)}
			/>
			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={classes.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
}

export default React.forwardRef(KeyValueAutoComplete);
