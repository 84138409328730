import React from 'react';
import { makeStyles } from '@material-ui/core';
import WorkingTime from '../service/types/WorkingTime';
import ReactList from 'react-list';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { useGlobalStyles } from '../consts/globalStyles';
import RecordTitleSummary from './RecordTitleSummary';
import RecordItemSummary from './RecordItemSummary';
import BreakTime from '../service/types/BreakTime';
import BreakItemSummary from './BreakItemSummary';

export const RECORD_LIST_PARAMS = {
	RANGE: '40.517%',
	TIME: '18.642%',
	REPORTER: '21.875%',
	STATUS: '18.966%',
};

interface Props {
	records: (WorkingTime | BreakTime)[];
	users: KeyValue[];
	timeTitle: string;
}

function RecordListSummary(props: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();

	const transformedRecords = React.useMemo(() => {
		const sortedRecordsByDate = props.records.sort((a, b) => b.date.unix() - a.date.unix());

		return sortedRecordsByDate.reduce((prev, curr) => {
			return {
				...prev,
				[curr.qai]: [...(prev[curr.qai] ? prev[curr.qai] : []), curr],
			};
		}, {} as { [key: string]: (WorkingTime | BreakTime)[] });
	}, [props.records]);

	const keys = Object.keys(transformedRecords);
	const values = Object.values(transformedRecords);

	return (
		<div className={classes.wrapper}>
			<ReactList
				length={values.length}
				itemRenderer={(index) => (
					<div
						key={`${props.records[index].id}key${props.records[index].type}`}
						className={props.users.find((user) => user.value.id.toString() === keys[index])?.key}>
						<div className={classes.mainTitleWrapper}>
							<span className={globalClasses.bigTextBold}>
								{props.users.find((user) => user.value.id.toString() === keys[index])?.key}
							</span>
						</div>

						<RecordTitleSummary records={values[index]} timeTitle={props.timeTitle} />

						<div className={classes.container}>
							<ReactList
								length={values[index].length}
								itemRenderer={(index2) => {
									return values[index][index2] instanceof WorkingTime ? (
										<RecordItemSummary
											key={`RecordItem${values[index][index2].id}${Math.random()}`}
											index={index2}
											record={values[index][index2] as WorkingTime}
											users={props.users}
										/>
									) : (
										<BreakItemSummary
											key={`RecordItem${values[index][index2].id}${Math.random()}`}
											index={index2}
											record={values[index][index2] as BreakTime}
											users={props.users}
										/>
									);
								}}
							/>
						</div>
					</div>
				)}
			/>
		</div>
	);
}
const useStyles = makeStyles(() => ({
	container: {
		maxHeight: 400,
		overflow: 'scroll',
	},
	wrapper: {
		marginTop: '0px',
	},
	mainTitleWrapper: {
		marginTop: '16px',
	},
	subtitle: {
		marginTop: '8px',
	},
}));

export default RecordListSummary;
