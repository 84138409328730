import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DashboardScreen from '../screens/DashboardScreen';
import { getErrorService } from '../helpers/dashboardHelper/readDashboardHelper';
import Contract, { ContractSummaryInterface } from '../service/types/Contract';
import Customer from '../service/types/Customer';
import service from '../service/service';
import { useAuthStateContext } from '../context/auth/useAuth';
import User from '../service/types/User';
import RhenusCase from '../service/types/RhenusCase';
import StandardCaseInput from '../service/types/StandardCase';
import {
	DashboardCaseFilterKeys,
	DashboardFilterInterface,
	dashboardInputFields,
	initDashboardFilters,
} from '../helpers/dashboardHelper/generalDashboardHelper';
import { filterData } from '../helpers/filters';
import { FormProvider, useForm } from 'react-hook-form';
import { RouteParams, Routes } from '../router/Routes';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import { ContractType } from '../helpers/contractHelper/generalContractHelper';
import moment from 'moment';
import { generateAndDownloadExcel } from '../helpers/fileDownloader';
import { languages, LANGUAGE_KEY } from '../consts/language';
import { useTranslation } from 'react-i18next';
import { unstable_batchedUpdates } from 'react-dom';
import Spinner from '../components/Spinner';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';

function DashboardContainer() {
	const history = useHistory();
	const { user } = useAuthStateContext();
	const methods = useForm();

	const [contract, setContract] = React.useState<Contract>();
	const [customer, setCustomer] = React.useState<Customer | undefined>();
	const [errors, setErrors] = React.useState<RhenusOverviewInformation[] | StandardCaseInput[]>([]);
	const [teamLeader, setTeamLeader] = React.useState<User | undefined>();
	const [contactQAI24, setContactQAI24] = React.useState<User | undefined>();
	const [filters, setFilters] = React.useState<DashboardFilterInterface>(initDashboardFilters);
	const [currentZone, setCurrentZone] = React.useState(0);
	const [zoneInformations, setZoneInformations] = React.useState<{ [key: string]: RhenusCase[] }>({});
	const [allStandardCaseInputs, setAllStandardCaseInputs] = React.useState<StandardCaseInput[]>([]);

	const allErrors = React.useRef<StandardCaseInput[] | RhenusOverviewInformation[]>([]);
	const zoneErrors = React.useRef<{ [key: string]: RhenusCase[] }>({});
	const routeParams: RouteParams = useParams();
	const [contractSummary, setContractSummary] = React.useState<ContractSummaryInterface | undefined>(undefined);
	const [mounted, setMounted] = React.useState(false);

	const [intervalDates, setIntervalDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const { t } = useTranslation();

	const fetchData = React.useCallback(async () => {
		try {
			let customer: Customer | undefined;
			let teamLeader: User | undefined;
			let contactQAI24: User | undefined;
			let errors: RhenusOverviewInformation[] | StandardCaseInput[] | void;
			let contractSummary: any;

			const contract = await service.getContract(Number(routeParams.contract_id));

			if (routeParams.contract_id) {
				const allInputs = await service.getAllStandardCaseInputs(Number(routeParams.contract_id));
				setAllStandardCaseInputs(allInputs);
			}

			if (contract && user) {
				customer = await service.getCustomerByContract(contract.id);
			}

			if (contract) {
				teamLeader = await service.getTeamLeaderByContract(contract.id);
				contactQAI24 = await service.getContactQAI24ByContract(contract.id);
				errors = await getErrorService(contract);
				contractSummary = await service.contractSummary(
					contract.id,
					intervalDates.fromDate,
					intervalDates.toDate
				);
			}

			unstable_batchedUpdates(() => {
				setContract(contract);
				if (customer) setCustomer(customer);
				if (teamLeader) setTeamLeader(teamLeader);
				if (contactQAI24) setContactQAI24(contactQAI24);

				if (errors) {
					setErrors(errors);
					allErrors.current = errors;
				}

				if (contractSummary) setContractSummary(contractSummary);
				setMounted(true);
			});
		} catch (e) {
			history.push(Routes.Home);
		}
	}, [history, routeParams.contract_id, user, intervalDates]);

	React.useEffect(() => {
		fetchData();
	}, [fetchData]);

	const onFilterChange = (key: DashboardCaseFilterKeys, value: any) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));
	};
	const clearAllFilters = () => {
		Object.values(dashboardInputFields).forEach((value) => methods.setValue(value, ''));
		setFilters(initDashboardFilters);
		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	React.useEffect(() => {
		if (contract) {
			if (contract.contractType === ContractType.Standard) setErrors(filterData(filters, allErrors.current));
			else {
				setZoneInformations(() => {
					return Object.keys(zoneErrors.current).reduce((acc, cur) => {
						//@ts-ignore
						return { ...acc, [cur]: filterData(filters, zoneErrors.current[cur]) };
					}, {});
				});
				if (filters.fromDate || filters.toDate) {
					service
						.getRhenusOverviewInformations(
							contract.id,
							filters.fromDate ? filters.fromDate : moment(contract.date),
							filters.toDate ? filters.toDate : moment()
						)
						.then((res) => {
							if (res) {
								setErrors(res);
								allErrors.current = res;
							}
						})
						.catch(() => {});
				}
			}
		}
	}, [filters, contract]);

	const getZoneData = React.useCallback(
		(item: RhenusOverviewInformation) => {
			if (zoneInformations[item.zone.id]) {
				setCurrentZone(item.zone.id);
			} else {
				service
					.getZoneCaseInputs(0, item.zone.id)
					.then((res) => {
						if (res) {
							setZoneInformations((prev) => {
								const newData = { ...prev, [item.zone.id]: res };
								zoneErrors.current = newData;
								return Object.keys(zoneErrors.current).reduce((acc, cur) => {
									//@ts-ignore
									return { ...acc, [cur]: filterData(filters, zoneErrors.current[cur]) };
								}, {});
							});
							setCurrentZone(item.zone.id);
						}
					})
					.catch(() => {});
			}
		},
		[filters, zoneInformations]
	);

	const setRhenusOverviewInforamtions = () => setCurrentZone(0);

	const downloadTable = async () => {
		let lan = localStorage.getItem(LANGUAGE_KEY);
		if (lan === null) {
			lan = languages.deutchland.i18n;
		}
		if (contract) {
			if (filters.fromDate) {
				const offset = moment(filters.fromDate).utcOffset();
				filters.fromDate = moment(filters.fromDate).add(offset);
			}
			if (filters.toDate) {
				const offset = moment(filters.toDate).utcOffset();
				filters.toDate = moment(filters.toDate).add(offset);
			}
			const excelData = await service.createStandardErrorInputsExcelSheet(contract.id, filters, lan);
			excelData && generateAndDownloadExcel(excelData, t('export_table_order_overview'), contract.contractNumber);
		}
	};

	const inputs: string[] = allStandardCaseInputs.map((val) => val.partNumber);

	const partNumberOptions = inputs.filter((item, index) => inputs.indexOf(item) === index);

	const onDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);

			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	if (!mounted || !contract) return <Spinner />;

	return (
		<FormProvider {...methods}>
			<DashboardScreen
				intervalDates={intervalDates}
				contract={contract}
				contractSummary={contractSummary}
				customer={customer}
				teamLeader={teamLeader}
				errors={errors}
				filters={filters}
				contactQAI24={contactQAI24}
				currentZone={currentZone}
				zoneInformations={zoneInformations}
				partNumberOptions={partNumberOptions}
				onFilterChange={onFilterChange}
				clearAllFilters={clearAllFilters}
				onZoneOverviewPress={getZoneData}
				onBackIconPress={setRhenusOverviewInforamtions}
				downloadTable={downloadTable}
				onKeyboardDateChange={onKeyboardDateChange}
				onDateChange={onDateChange}
				onDatesAccept={onDatesAccept}
				onDatesCancel={onDatesCancel}
				clearDates={clearDates}
				user={user}
			/>
		</FormProvider>
	);
}

export default DashboardContainer;
