import React from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import MaterialTool from '../service/types/MaterialTool';
import { useForm } from 'react-hook-form';
import { TableCellWithCss } from './table/StandardTableCell';
import { ExpenseTabelInterface } from '../consts/material';
import { colors } from '../consts/colors';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import ExpensesButtonCellType from './ExpensesButtonsCellType';
import { useGlobalStyles } from '../consts/globalStyles';
import { AddExpensesItemKeys } from './AddExpensesItem';
import Travel from '../service/types/Travel';
import { UserRole } from '../service/types/User';
import { useAuthStateContext } from '../context/auth/useAuth';
import Transport from '../service/types/Transport';
import ExpensesItemCell from './ExpensesItemCell';

interface Props {
	expense: MaterialTool | Travel | Transport;
	tableKey: string;
	expenseTablePramas: ExpenseTabelInterface;
	users: KeyValue[];
	isSummary: boolean;
	index: number;
	onUpdatePress: (expense: MaterialTool | Travel | Transport, data: any) => Promise<boolean>;
	onDeletePress: (expenseID: number) => void;
	contractNumber?: string;
}

function ExpenseTabelItem({
	expense,
	tableKey,
	expenseTablePramas,
	users,
	isSummary,
	index,
	contractNumber,
	onUpdatePress,
	onDeletePress,
}: Props) {
	const classes = useStyles({ isSummary: isSummary, index: index });
	const globalClasses = useGlobalStyles();
	const [edit, setEdit] = React.useState(false);
	const { control, errors, handleSubmit } = useForm();
	const { user } = useAuthStateContext();

	const onCancelPress = () => setEdit(false);

	const onEditPress = () => setEdit(true);

	const onSavePress = async (data: any) => {
		onUpdatePress(expense, data).then((res) => res && setEdit(false));
	};

	const onDeletePressHandler = () => {
		onDeletePress(expense.id);
	};

	const reporter = React.useMemo(() => {
		return users.find((user) => user.value.id === expense.reporter);
	}, [expense, users]);

	const stickyClass = React.useMemo(
		() => (index % 2 ? classes.itemWrapperStickyInverted : classes.itemWrapperSticky),
		[index, classes]
	);

	const itemClass = React.useMemo(
		() => (index % 2 ? classes.itemWrapperInverted : classes.itemWrapper),
		[index, classes]
	);

	const keys: AddExpensesItemKeys[] = React.useMemo(
		() => Object.keys(expenseTablePramas) as AddExpensesItemKeys[],
		[expenseTablePramas]
	);

	return (
		<TableRow key={tableKey}>
			{keys.map((key, index) => {
				if (key === 'edit')
					return (
						<TableCellWithCss
							key={`TabelItem${key + index + expense.id}`}
							className={classes.itemWrapperStickyRight}
							style={{
								width: expenseTablePramas?.edit?.width ? expenseTablePramas.edit.width : '20px',
							}}>
							{isSummary ? null : (
								<ExpensesButtonCellType
									edit={edit}
									onEditPress={onEditPress}
									onCancelPress={onCancelPress}
									onDeletePress={onDeletePressHandler}
									onSavePress={handleSubmit(onSavePress)}
								/>
							)}
						</TableCellWithCss>
					);
				return key === 'billed' && user?.role === UserRole.QualityAssuranceInspector ? null : (
					<ExpensesItemCell
						control={control}
						params={expenseTablePramas[key]}
						errors={errors}
						itemClass={index === 0 ? stickyClass : itemClass}
						textClass={globalClasses.smallTextDark}
						edit={edit}
						key={`TabelItem${key + index + expense.id}`}
						isSummary={isSummary}
						reporter={reporter}
						expense={expense}
						fieldKey={key}
						contractNumber={contractNumber}
						inputColor={index === 0 ? colors.white : colors.darkGray}
					/>
				);
			})}
		</TableRow>
	);
}

const baseWrapper = {
	alignItems: 'center',
	padding: '14px 16px',
	overflow: 'hidden',
	left: '0px',
	backgroundColor: colors.keyButton,
};

interface StyleProps {
	isSummary?: boolean;
	index: number;
}

const useStyles = makeStyles(() => ({
	itemWrapper: {
		...baseWrapper,
	},
	itemWrapperSticky: {
		...baseWrapper,
		position: 'sticky',
		backgroundColor: colors.darkGray,
	},
	itemWrapperInverted: {
		...baseWrapper,
		backgroundColor: '#d6d6d6',
	},
	itemWrapperStickyInverted: {
		...baseWrapper,
		position: 'sticky',
		backgroundColor: colors.lightDarkGray,
	},
	itemWrapperStickyRight: (p: StyleProps) => ({
		...baseWrapper,
		position: 'sticky',
		right: '0px',
		left: undefined,
		backgroundColor: p.isSummary ? (p.index % 2 === 0 ? colors.keyButton : '#d6d6d6') : colors.someDarkYellow,
	}),
}));

export default ExpenseTabelItem;
