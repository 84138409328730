import React from 'react';
import { AuthActionsContext, AuthStateContext } from './auth/AuthContext';

import authReducer, { authInitialState } from './auth/authReducer';
import { createActionAuthContext, createStateAuthContext } from './auth/createAuthReducer';
import notificationReducer, { notificationInitialState } from './notification/notificationReducer';
import { createActionWorkingTimeContext, createWorkingTimeState } from './workingTime/createWorkingTimeReducer';
import { WorkingTimeActionsContext, WorkingTimeStateContext } from './workingTime/WorkingTimeContext';
import workingTimeReducer, { workTimeInitialState } from './workingTime/workingTimeReducer';
import { createActionNotificationContext, createNotificationState } from './notification/createNotificationReducer';
import { NotificationActionsContext, NotificationStateContext } from './notification/NotificationContext';
import errorReducer, { errorInitialState } from './error/errorReducer';
import { createActionErrorContext, createErrorState } from './error/createErrorReducer';
import { ErrorStateContext, ErrorActionsContext } from './error/ErrorContext';

interface Props {
	children: JSX.Element;
}

function ContextProvider(props: Props) {
	const [authState, dispatchAuth] = React.useReducer(authReducer, authInitialState);
	const [errorState, dispatchError] = React.useReducer(errorReducer, errorInitialState);
	const [workingTimeState, dispatchWorkingTime] = React.useReducer(workingTimeReducer, workTimeInitialState);
	const [notificationState, dispatchNotificationTime] = React.useReducer(
		notificationReducer,
		notificationInitialState
	);

	const authStateContext = React.useMemo(() => createStateAuthContext(authState), [authState]);
	const authActionContext = React.useMemo(() => createActionAuthContext(dispatchAuth), [dispatchAuth]);

	const workingTimeStateContext = React.useMemo(() => createWorkingTimeState(workingTimeState), [workingTimeState]);
	const wokringTimeActionContext = React.useMemo(() => createActionWorkingTimeContext(dispatchWorkingTime), [
		dispatchWorkingTime,
	]);

	const notificationStateContext = React.useMemo(() => createNotificationState(notificationState), [
		notificationState,
	]);
	const notificationActionContext = React.useMemo(() => createActionNotificationContext(dispatchNotificationTime), [
		dispatchNotificationTime,
	]);

	const errorStateContext = React.useMemo(() => createErrorState(errorState), [errorState]);
	const errorActionContext = React.useMemo(() => createActionErrorContext(dispatchError), [dispatchError]);

	return (
		<AuthActionsContext.Provider value={authActionContext}>
			<AuthStateContext.Provider value={authStateContext}>
				<WorkingTimeActionsContext.Provider value={wokringTimeActionContext}>
					<WorkingTimeStateContext.Provider value={workingTimeStateContext}>
						<NotificationActionsContext.Provider value={notificationActionContext}>
							<NotificationStateContext.Provider value={notificationStateContext}>
								<ErrorActionsContext.Provider value={errorActionContext}>
									<ErrorStateContext.Provider value={errorStateContext}>
										{props.children}
									</ErrorStateContext.Provider>
								</ErrorActionsContext.Provider>
							</NotificationStateContext.Provider>
						</NotificationActionsContext.Provider>
					</WorkingTimeStateContext.Provider>
				</WorkingTimeActionsContext.Provider>
			</AuthStateContext.Provider>
		</AuthActionsContext.Provider>
	);
}

export default ContextProvider;
