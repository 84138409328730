import WorkingTime from '../../service/types/WorkingTime';
import { addWorkingTimeFields } from './generalWorkingTimeHelper';
import { setDateWithTime } from './createWorkingTimeHelper';
import BreakTime, { RecordTimeType } from '../../service/types/BreakTime';
import service from '../../service/service';
import moment from 'moment';
import { KeyValue } from '../userHelper/generalUserHelper';

export function createWorkingTimeWithID(data: any, record: WorkingTime, timeDiff: number) {
	const recordDateMoment = moment(data[addWorkingTimeFields.date]);

	return new WorkingTime(
		record.id,
		record.reporter,
		data[addWorkingTimeFields.qai].value.id,
		recordDateMoment,
		setDateWithTime(data[addWorkingTimeFields.from], recordDateMoment.toISOString()),
		setDateWithTime(data[addWorkingTimeFields.to], recordDateMoment.toISOString()),
		timeDiff,
		(data[addWorkingTimeFields.profession] as KeyValue).value,
		data[addWorkingTimeFields.billed]?.value !== undefined
			? data[addWorkingTimeFields.billed]?.value
			: record.billed
	);
}

export function getUpdateWorkingTimeService(contractID: number, record: WorkingTime | BreakTime) {
	if (record instanceof WorkingTime) return service.updateRecordByID(contractID, record);
	if (record.type === RecordTimeType.Break) return service.updateBreakByID(contractID, record);
	return service.updateWaitingByID(contractID, record);
}
