import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { makeStyles } from '@material-ui/core';
import { colors } from '../consts/colors';
import { useWindowSize } from '@react-hook/window-size';

interface Props {}

function Spinner(props: Props) {
	const [width, height] = useWindowSize();
	const classes = useStyles({ width, height });

	return (
		<div className={classes.wrapper}>
			<ClipLoader color={colors.darkGray} loading={true} size={150} />
		</div>
	);
}

interface StyleProps {
	width: number;
	height: number;
}

const useStyles = makeStyles(() => ({
	wrapper: (p: StyleProps) => ({
		minWidth: p.width,
		minHeight: p.height,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}),
}));

export default Spinner;
