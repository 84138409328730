import { Dispatch, SetStateAction } from 'react';
import { customerFields } from '../../containers/CustomerContainer';
import { Service } from '../../service/service';
import Address from '../../service/types/Address';
import Customer from '../../service/types/Customer';
import { NotificationInterface } from '../../context/notification/notificationReducer';

export type AddressKeys = 'billingAddress' | 'deliveryAddress';

export function createCustomer(
	data: any,
	service: Service,
	setCustomer: (customer: Customer | undefined) => void,
	emails: string[],
	setIsUserEditing: Dispatch<SetStateAction<boolean>>,
	setNotification: (notification: NotificationInterface) => void
) {
	const newCustomerWithoutID = getCustomerWithoutContactPersons(0, data, emails);

	service
		.createCustomer(newCustomerWithoutID)
		.then((id) => {
			const newCustomerWithID = { ...newCustomerWithoutID, id };
			setCustomer(newCustomerWithID);
			setIsUserEditing(false);
			setNotification({ text: 'customer_screen_create_notification', additional: newCustomerWithID.name });
		})
		.catch(() => {});
}

function getCustomerWithoutContactPersons(id: number, data: any, emails: string[] = []) {
	return new Customer(
		id,
		data[customerFields.name],
		createAddress('billingAddress', data),
		createAddress(
			data[customerFields.radio] ? 'billingAddress' : 'deliveryAddress',
			data,
			data[customerFields.deliveryAddress.costCenter!]
		),
		[],
		data[customerFields.customerNumber],
		data[customerFields.supplierNumber],
		data[customerFields.invoiceEmail],
		data[customerFields.reminderEmail],
		emails,
		data[customerFields.vatNumber],
		data[customerFields.termOfPayment]
	);
}

export function createAddress(key: AddressKeys, data: any, costCenter?: string) {
	return new Address(
		Number(data[customerFields[key].postcode]),
		data[customerFields[key].city],
		data[customerFields[key].street],
		data[customerFields[key].houseNumber],
		data[customerFields[key].country],
		costCenter ? costCenter : customerFields[key].costCenter ? data[customerFields[key].costCenter!] : ''
	);
}
