import service from '../../service/service';
import WorkingTime from '../../service/types/WorkingTime';
import moment from 'moment';
import { initTimeValue, addWorkingTimeFields } from './generalWorkingTimeHelper';
import User from '../../service/types/User';
import { KeyValue } from '../userHelper/generalUserHelper';
import BreakTime, { RecordTimeType } from '../../service/types/BreakTime';

export async function addWorkTime(
	contractID: number,
	user: User,
	qais: KeyValue[],
	data: any,
	manualWorkTime: number,
	setValue: (field: string, value: any) => void
) {
	if (data[addWorkingTimeFields.type] === RecordTimeType.Working) {
		const newRecord = createWorkingTimeWithoutID(user.id, qais, data, manualWorkTime);
		const res = await service.addManualWorkTime(contractID, newRecord).catch(() => {});
		if (res) {
			resetAddWorkingTimeFields(setValue);
			return WorkingTime.createWorkingTimeWithID(res, newRecord);
		}
	}
	const newRecord = createBreakTimeWithoutID(user.id, qais, data);
	const res = await service.addManualBreakTime(contractID, newRecord).catch(() => {});
	if (res) {
		resetAddWorkingTimeFields(setValue);
		return BreakTime.createWorkingTimeWithID(res, newRecord);
	}
}

function createWorkingTimeWithoutID(userID: number, qais: KeyValue[], data: any, manualWorkTime: number) {
	return new WorkingTime(
		0,
		userID,
		qais.find((qai) => qai.key === data[addWorkingTimeFields.qai].key)!.value.id,
		data[addWorkingTimeFields.date] ? moment(data[addWorkingTimeFields.date]) : moment(),
		setDateWithTime(data[addWorkingTimeFields.from], data[addWorkingTimeFields.date]),
		setEndDateWithTime(
			data[addWorkingTimeFields.from],
			data[addWorkingTimeFields.to],
			data[addWorkingTimeFields.date]
		),
		manualWorkTime,
		(data[addWorkingTimeFields.profession] as KeyValue).value,
		false
	);
}

function resetAddWorkingTimeFields(setValue: (fields: string, value: any) => void) {
	setValue(addWorkingTimeFields.from, initTimeValue);
	setValue(addWorkingTimeFields.to, initTimeValue);
	setValue(addWorkingTimeFields.date, moment());
	setValue(addWorkingTimeFields.qai, '');
	setValue(addWorkingTimeFields.duration, initTimeValue);
}

export function setDateWithTime(time: string, date?: string) {
	const newDate = moment(date);
	const newTime = moment(time);
	return newDate.set('minutes', newTime.get('minutes')).set('hour', newTime.get('hour'));
}

export function setEndDateWithTime(startTime: string, endTime: string, date?: string) {
	if (startTime < endTime) return setDateWithTime(endTime, date);
	else {
		const newDate = moment(date).add(1, 'days');
		const newTime = moment(endTime);
		return newDate.set('minutes', newTime.get('minutes')).set('hour', newTime.get('hour'));
	}
}

const HOURS_24 = 86400;

function createBreakTimeWithoutID(userID: number, qais: KeyValue[], data: any) {
	return new BreakTime(
		0,
		userID,
		qais.find((qai) => qai.key === data[addWorkingTimeFields.qai].key)!.value.id,
		data[addWorkingTimeFields.date] ? moment(data[addWorkingTimeFields.date]) : moment(),
		(Math.round(
			moment(data[addWorkingTimeFields.date])
				.set('s', 0)
				.set('h', moment(data[addWorkingTimeFields.duration]).get('h'))
				.set('m', moment(data[addWorkingTimeFields.duration]).get('m'))
				.diff(moment(data[addWorkingTimeFields.date]).set('s', 0).set('h', 0).set('m', 0)) /
				1000 /
				60
		) *
			60) %
			HOURS_24,
		data[addWorkingTimeFields.type]
	);
}
