import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import { Control, Controller } from 'react-hook-form';
import KeyValueAutoComplete from './KeyValueAutoComplete';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { rules } from '../consts/rules';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import BilledItem from './BilledItem';

interface Props {
	edit: boolean;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	valueID: number | boolean;
	values: KeyValue[];
	fieldKey: string;
	placeholder: string;
	inputColor: string;
	showAsValue?: boolean;
}
function ExpensesKeyValueCellType(props: Props) {
	const classes = useStyles({ inputColor: props.inputColor });
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	const currentValue = React.useMemo(() => {
		if (typeof props.valueID === 'boolean') return props.values.find((value) => value.value === props.valueID);
		if (props.values.length !== 0 && typeof props.values[0].value === 'number')
			return props.values.find((value) => value.value === props.valueID);
		return props.values.find((value) => value.value.id === props.valueID);
	}, [props.valueID, props.values]);

	return !props.edit ? (
		typeof props.valueID === 'boolean' ? (
			<BilledItem billed={props.valueID} />
		) : (
			<span
				className={globalClasses.smallTextDark}
				style={{ color: props.inputColor ? props.inputColor : colors.darkGray }}>
				{currentValue ? (props.showAsValue ? currentValue.value.id : t(currentValue.key)) : ''}
			</span>
		)
	) : (
		<Controller
			defaultValue={currentValue}
			control={props.control}
			name={props.fieldKey}
			rules={{
				required: rules(t).required,
				validate: (val: KeyValue | undefined) => {
					if (val && val.value !== undefined) {
						return true;
					}
					return rules(t).required.message;
				},
			}}
			render={({ onChange, value }) => (
				<KeyValueAutoComplete
					label={''}
					value={value}
					inputWrapper={classes.inputAddWrapper}
					placeholder={t(props.placeholder)}
					onChange={onChange}
					wrapper={classes.inputWrapper}
					options={props.values}
					input={globalClasses.inputTableStyle}
					withoutArrow={true}
					withoutClose={true}
					error={props.errors[props.fieldKey] ? true : false}
					helperText={props.errors[props.fieldKey] ? props.errors[props.fieldKey].message : ''}
				/>
			)}
		/>
	);
}

interface StyleProps {
	inputColor: string;
}

const useStyles = makeStyles(() => ({
	inputWrapper: {
		width: '95%',
	},

	inputAddWrapper: (p: StyleProps) => ({
		width: '100%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
		'& .MuiInput-input': {
			margin: 0,
			color: p.inputColor ? p.inputColor : colors.darkGray,
			fontSize: '14px',
			lineHeight: '20px',
			fontFamily: fonts.regular,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
	}),
}));

export default ExpensesKeyValueCellType;
