import React from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import { Routes } from '../router/Routes';
import service from '../service/service';
import ResetPasswordSuccessScreen from '../screens/ResetPasswordSuccessScreen';
import { useQuery } from '../hooks/useQuery';
import { useAuthStateContext } from '../context/auth/useAuth';
import { getDataForPasswordReset } from '../consts/login';

export const resetPasswordFields = {
	newPassword: 'newPassword',
	repeatPassword: 'repeatPassword',
};

function ResetPasswordContainer(props: RouteChildrenProps<any, unknown>) {
	const [isTokenValid, setIsTokenValid] = React.useState(false);
	const [isPasswordReseted, setIsPasswordReseted] = React.useState(false);
	const { control, handleSubmit, errors, getValues } = useForm();
	const { user } = useAuthStateContext();
	const history = useHistory();
	const query = useQuery();

	const id = React.useMemo(() => props.match?.params.id, [props.match]);
	const dataForResetPassword = React.useMemo(() => getDataForPasswordReset(query.get('qai')), [query]);

	React.useEffect(() => {
		if (user) {
			history.replace(Routes.Home);
		}
	}, [user, history]);

	React.useEffect(() => {
		if (id) {
			service
				.checkPasswordRessetToken(id)
				.then((res) => {
					if (res) {
						setIsTokenValid(true);
					}
				})
				.catch((e) => {
					history.replace(dataForResetPassword.redirectRoute);
				});
		}
	}, [id, history, dataForResetPassword]);

	const onLoginPress = () => history.push(dataForResetPassword.redirectRoute);

	const onResetPasswordPress = React.useMemo(
		() =>
			handleSubmit((data) => {
				dataForResetPassword
					.resetPassword({ uuid: id, password: data[resetPasswordFields.newPassword] })
					.then((res) => {
						if (res) {
							setIsPasswordReseted(true);
						}
					})
					.catch((e) => {});
			}),
		[handleSubmit, id, dataForResetPassword]
	);

	if (!isTokenValid) return <div></div>;

	return isPasswordReseted ? (
		<ResetPasswordSuccessScreen onLoginPress={onLoginPress} />
	) : (
		<ResetPasswordScreen
			errors={errors}
			control={control}
			dataForResetPassword={dataForResetPassword}
			onResetPasswordPress={onResetPasswordPress}
			getValues={getValues}
		/>
	);
}

export default ResetPasswordContainer;
