import { TransportType } from '../../service/types/Transport';
import { DEFAULT_NO_VALUE } from '../standardInputHelper/generalStandardInputHelper';

export enum TypeOfTravel {
	Own = 1,
	Company = 2,
}

export interface ExpensesFilterInterface {
	qai: any;
	reporter: any;
	name: any;
	fromDate: any;
	toDate: any;
	quantity?: any;
	billed: any;
}

export const initExpensesFilters: ExpensesFilterInterface = {
	qai: '',
	reporter: '',
	name: '',
	fromDate: null,
	toDate: null,
	billed: '',
};

export const expensesFilterFields: ExpensesFilterInterface = {
	qai: 'qai',
	reporter: 'reporter',
	name: 'name',
	fromDate: 'fromDate',
	toDate: 'toDate',
	quantity: 'quantity',
	billed: 'billed',
};

const data = Object.freeze({ ...initExpensesFilters });
export type ExpensesFilterKeys = keyof typeof data;

export interface Expenses {
	workingTime?: any;
	materials: any;
	tools: any;
	travels: any;
	transport: any;
	invoice: any;
}

export const initExpenses: Expenses = {
	materials: [],
	tools: [],
	travels: [],
	workingTime: [],
	transport: [],
	invoice: [],
};

export const billedOptions = [
	{ key: 'Billed', value: true },
	{ key: 'Not billed', value: false },
];
export const filterBilledOptions = [DEFAULT_NO_VALUE, { key: 'Billed', value: 1 }, { key: 'Not billed', value: 0 }];

export const TravelOptions = [
	{ key: 'Private', value: TypeOfTravel.Own },
	{ key: 'Company car', value: TypeOfTravel.Company },
];

export const TransportOptions = [
	{ key: 'PKW', value: TransportType.PKW },
	{ key: 'LKW', value: TransportType.LKW },
	{ key: 'Transporter', value: TransportType.Transporter },
	{ key: 'Spedition', value: TransportType.Spedition },
];

const expenses = Object.freeze({ ...initExpenses });
export type ExpensesKeys = keyof typeof expenses;
