import { LoginType } from '../containers/LoginContainer';
import { Routes } from '../router/Routes';
import Contract, { IContractWorkLocation } from '../service/types/Contract';
import Customer from '../service/types/Customer';
import StandardCaseInput from '../service/types/StandardCase';
import User from '../service/types/User';
import { LocationState, History } from 'history';
import { ContractStatusType, ContractZone } from './contractHelper/generalContractHelper';
import RhenusCase from '../service/types/RhenusCase';

export interface CustomerNavigationInterface {
	customer?: Customer;
}
export interface ContactPersonNavigationInterface {
	contactPersonID?: number;
	contactPerson?: User;
}
export interface UserNavigationInterface {
	user?: User;
}

export interface ContractNavigationInterface {
	contract?: string;
}

export interface StandardCaseNavigationInterface {
	data?: string;
	contract: string;
}

export interface RhenusCaseNavigationInterface {
	data?: string;
	contract: string;
	zone: ContractZone;
}

export interface ExpensesNavigationInterface {
	contractID: number;
	contractNumber: string;
}

export interface SummaryNavigationInterface {
	contractID: number;
	contractNumber: string;
	contractStatus: ContractStatusType;
	contractCustomer: string;
	workLocations: IContractWorkLocation[];
}

export interface ForgotPasswordNavigationInterface {
	from?: LoginType;
}

export interface ZoneOvervieNavigationInterface {
	params: string;
	zone: ContractZone;
}

export function goToCustomer(history: History<LocationState>, customer?: Customer) {
	history.push(Routes.Customer + `${customer ? '/' + customer.id : ''}`, { customer: customer });
}

export function goToCustomerWithID(history: History<LocationState>, customerID: number) {
	history.push(Routes.Customer + `${'/' + customerID}`);
}

export function replaceCustomerHistoryData(history: History<LocationState>, customer: Customer) {
	history.replace(history.location.pathname, { customer: customer });
}

export function goToCustomers(history: History<LocationState>) {
	history.push(Routes.Customers);
}

export function goToContactPerson(
	history: History<LocationState>,
	params: { customerID?: number; contactPersonID?: number; contactPerson?: User }
) {
	history.push(
		Routes.ContactPerson +
			`/${params.customerID ? params.customerID : '0'}${
				params.contactPersonID ? '/' + params.contactPersonID : ''
			}`,
		{
			contactPersonID: params.contactPersonID,
			contactPerson: params.contactPerson,
		}
	);
}

export function replaceContactPersonHistoryData(history: History<LocationState>, user: User) {
	history.replace(history.location.pathname, {
		...(history.location.state as ContactPersonNavigationInterface),
		contactPerson: user,
	});
}

export function goToUser(history: History<LocationState>, user?: User) {
	history.push(Routes.User + `${user ? `/${user.id}` : ''}`, {
		user: user,
	});
}

export function replaceUserHistoryData(history: History<LocationState>, user: User) {
	history.replace(history.location.pathname, { user: user });
}

export function goToUsers(history: History<LocationState>) {
	history.push(Routes.Users);
}

export function goToLogin(history: History<LocationState>) {
	history.push(Routes.Login);
}

export function goToAdminContracts(history: History<LocationState>) {
	history.push(Routes.AdminContracts);
}

export function goToDashboard(history: History<LocationState>, contract: Contract) {
	history.push(Routes.Dashboard + '/' + contract.id, { contract: JSON.stringify(contract) });
}

export function goToCustomerContracts(history: History<LocationState>) {
	history.push(Routes.CustomerContracts);
}

export function goToProcessContracts(history: History<LocationState>) {
	history.push(Routes.ProcessContracts);
}

export function goToHome(history: History<LocationState>) {
	history.push(Routes.Home);
}

export function goToContract(history: History<LocationState>, contract?: Contract) {
	history.push(Routes.Contract + `${contract ? '/' + contract.id : ''}`, { contract: JSON.stringify(contract) });
}

export function goToInvoice(history: History<LocationState>, invoice?: any) {
	history.push(Routes.Invoice + `${invoice ? '/' + invoice.id : ''}`, {
		invoice: JSON.stringify(invoice),
	});
}

export function replaceContractHistoryData(history: History<LocationState>, contract: Contract) {
	history.replace(history.location.pathname, {
		...(history.location.state as ContractNavigationInterface),
		contract: JSON.stringify(contract),
	});
}

export function goToProcessContract(history: History<LocationState>, contract: Contract) {
	history.push(Routes.ProcessContract + '/' + contract.id, { contract: JSON.stringify(contract) });
}

export function goToStandardCase(
	history: History<LocationState>,
	params: {
		data?: StandardCaseInput;
		contract: Contract;
	}
) {
	history.push(Routes.StandardCaseInput + '/' + params.contract.id + `${params.data ? '/' + params.data.id : ''}`, {
		data: JSON.stringify(params.data),
		contract: JSON.stringify(params.contract),
	});
}

export function replaceStandardCaseHistoryData(history: History<LocationState>, data: StandardCaseInput) {
	history.replace(history.location.pathname, {
		...(history.location.state as StandardCaseNavigationInterface),
		data: JSON.stringify(data),
	});
}

export function goToForgotPassword(history: History<LocationState>, from?: LoginType) {
	history.push(Routes.ForgotPassword, { from: from });
}

export function goToZoneOverview(history: History<LocationState>, params: Contract, zone: ContractZone) {
	history.push(Routes.ZoneOverview + `/${params.id}/${zone.id}`, { params: JSON.stringify(params), zone: zone });
}

export function goToRhenusCase(
	history: History<LocationState>,
	params: {
		data?: RhenusCase;
		contract: Contract;
	},
	zone: ContractZone
) {
	history.push(
		Routes.RhenusCaseInput + `/${params.contract.id}/${zone.id}${params.data ? '/' + params.data.id : ''}`,
		{
			data: JSON.stringify(params.data),
			contract: JSON.stringify(params.contract),
			zone: zone,
		}
	);
}

export function replaceRhenusCaseHistoryData(history: History<LocationState>, data: RhenusCase) {
	history.replace(history.location.pathname, {
		...(history.location.state as RhenusCaseNavigationInterface),
		data: JSON.stringify(data),
	});
}

export function goToWorkingTime(
	history: History<LocationState>,
	params: { contractID: number; contractNumber: string }
) {
	history.push(Routes.WorkingTime + '/' + params.contractID, {
		contractNumber: params.contractNumber,
		contractID: params.contractID,
	});
}

export function goToExpenses(history: History<LocationState>, params: { contractID: number; contractNumber: string }) {
	history.push(Routes.Expenses + '/' + params.contractID, {
		contractNumber: params.contractNumber,
		contractID: params.contractID,
	});
}

export function goToSummary(
	history: History<LocationState>,
	params: {
		contractID: number;
		contractNumber: string;
		status: ContractStatusType;
		contractCustomer: string;
		workLocations: IContractWorkLocation[];
	}
) {
	history.push(Routes.Summary + '/' + params.contractID, {
		contractNumber: params.contractNumber,
		contractID: params.contractID,
		contractStatus: params.status,
		contractCustomer: params.contractCustomer,
		workLocations: params.workLocations,
	});
}

export function replaceSummaryHistoryData(
	history: History<LocationState>,
	params: { contractID: number; contractNumber: string; status: ContractStatusType }
) {
	history.replace(history.location.pathname, {
		contractNumber: params.contractNumber,
		contractID: params.contractID,
		contractStatus: params.status,
	});
}

export function goToChangePassword(history: History<LocationState>) {
	history.push(Routes.ChangePassowrd);
}
