import * as React from 'react';

function ErrorScreenIcon(props: any) {
	return (
		<svg width={351} height={271} viewBox="0 0 351 271" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(142 99)">
					<circle stroke="#111" strokeWidth={2} strokeLinecap="square" cx={36.578} cy={36.578} r={36.578} />
					<circle fill="#111" fillRule="nonzero" cx={21.614} cy={31.59} r={4.988} />
					<circle fill="#111" fillRule="nonzero" cx={51.542} cy={31.59} r={4.988} />
					<path
						d="M26.602 56.53c0-5.51 4.466-9.976 9.976-9.976 5.51 0 9.976 4.466 9.976 9.975"
						stroke="#111"
						strokeWidth={2}
					/>
				</g>
				<g stroke="#111" strokeLinecap="square" strokeWidth={2} transform="translate(44 1)">
					<path d="M268.8 152.727v-36.654l-39.685-5.413a96.854 96.854 0 00-10.996-26.452l24.24-31.89-25.877-25.915-31.89 24.241a96.854 96.854 0 00-26.452-10.996L152.727 0h-36.654l-5.413 39.685A96.854 96.854 0 0084.208 50.68l-31.89-24.278-25.915 25.915 24.241 31.89a96.854 96.854 0 00-10.996 26.452L0 116.073v36.654l39.685 5.413a96.854 96.854 0 0010.996 26.452l-24.24 31.89 25.914 25.915 31.89-24.241a96.854 96.854 0 0026.452 10.996l5.376 39.648h36.654l5.413-39.685a96.854 96.854 0 0026.452-10.996l31.89 24.24 25.915-25.914-24.241-31.89a96.853 96.853 0 0010.996-26.452l39.648-5.376z" />
					<circle cx={134.4} cy={134.4} r={36.655} />
				</g>
				<path
					d="M35.066 47l1.207 8.843a21.603 21.603 0 015.9 2.453h0l7.113-5.407 5.772 5.78-5.407 7.113a21.603 21.603 0 012.453 5.9h0l8.851 1.208v8.176l-8.843 1.199a21.603 21.603 0 01-2.453 5.9h0l5.407 7.113-5.78 5.78-7.113-5.407a21.603 21.603 0 01-5.9 2.453h0l-1.207 8.851H26.89l-1.2-8.843a21.603 21.603 0 01-5.9-2.453h0l-7.112 5.407-5.78-5.78 5.406-7.113a21.603 21.603 0 01-2.452-5.9h0L1 81.066V72.89l8.843-1.207a21.603 21.603 0 012.453-5.9h0L6.89 58.668l5.78-5.78 7.113 5.415a21.603 21.603 0 015.9-2.452h0L26.89 47h8.176zm-4.088 21.802a8.176 8.176 0 100 16.351 8.176 8.176 0 000-16.351z"
					fill="#FFF7CC"
					stroke="#111"
					strokeLinecap="square"
					strokeWidth={1.2}
				/>
				<g fill="#FFF7CC" stroke="#111" strokeLinecap="square" strokeWidth={1.2}>
					<path d="M324.066 168l1.207 8.843a21.603 21.603 0 015.9 2.453h0l7.113-5.407 5.772 5.78-5.407 7.113a21.603 21.603 0 012.453 5.9h0l8.851 1.208v8.176l-8.843 1.199a21.603 21.603 0 01-2.453 5.9h0l5.407 7.113-5.78 5.78-7.113-5.407a21.603 21.603 0 01-5.9 2.453h0l-1.207 8.851h-8.176l-1.2-8.843a21.603 21.603 0 01-5.9-2.453h0l-7.112 5.407-5.78-5.78 5.406-7.113a21.603 21.603 0 01-2.452-5.9h0L290 202.066v-8.176l8.843-1.207a21.603 21.603 0 012.453-5.9h0l-5.407-7.114 5.78-5.78 7.113 5.415a21.603 21.603 0 015.9-2.452h0L315.89 168h8.176zm-4.088 21.802a8.176 8.176 0 100 16.351 8.176 8.176 0 000-16.351z" />
				</g>
			</g>
		</svg>
	);
}

export default ErrorScreenIcon;
