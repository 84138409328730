import { PriceListTypes } from './priceListTypes';

export interface IPriceListOption {
	value: number;
	label: string;
}

export const priceListOptions: IPriceListOption[] = [
	{
		value: PriceListTypes.Admin,
		label: 'Admin',
	},
	{
		value: PriceListTypes.TruckDriver,
		label: 'TruckDriver',
	},
	{
		value: PriceListTypes.QAISimpleRework,
		label: 'QAISimpleRework',
	},
	{
		value: PriceListTypes.QAIVisualControl,
		label: 'QAIVisualControl',
	},
	{
		value: PriceListTypes.Resident,
		label: 'Resident',
	},
	{
		value: PriceListTypes.ForkliftDriver,
		label: 'ForkliftDriver',
	},
	{
		value: PriceListTypes.Teamlead,
		label: 'Teamlead',
	},
];
