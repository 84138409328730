import moment from 'moment';
import React from 'react';
import { ExpenseTabelParamsInterface } from '../consts/material';
import { TableCellWithCss } from './table/StandardTableCell';

interface Props {
	mainClass: string;
	textClass: string;
	params: ExpenseTabelParamsInterface;
}
function AddExpensesItemDate(props: Props) {
	
	return (
		<TableCellWithCss
			className={props.mainClass}
			style={{
				width: props.params.width,
			}}>
			<span className={props.textClass}>{moment(props.params.value).format('DD.MM.YYYY')}</span>
		</TableCellWithCss>
	);
}

export default AddExpensesItemDate;
