import React, { ReactNode, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';

import service from '../service/service';
import { groupAtom } from '../atoms/group';
import { userAtom } from '../atoms/userAtom';
import { allCustomersAtom } from '../atoms/allCustomers';
import { unstable_batchedUpdates } from 'react-dom';

interface Props {
	children: ReactNode;
}

export default function InitWrapper({ children }: Props) {
	const mounted = useRef(false);
	const [groups, setGroups] = useAtom(groupAtom);
	const [, setAllCustomers] = useAtom(allCustomersAtom);
	const [user] = useAtom(userAtom);

	useEffect(() => {
		(async () => {
			try {
				if (!mounted.current && user) {
					const readGroups = await service.readGroups();
					const allCustomers = await service.getAllCustomers();

					unstable_batchedUpdates(() => {
						setGroups(readGroups);
						setAllCustomers(allCustomers);
						mounted.current = true;
					});
				}
			} catch {
				mounted.current = false;
			}
		})();
	}, [groups, setAllCustomers, setGroups, user]);

	return <>{children};</>;
}
