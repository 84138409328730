import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { colors } from '../consts/colors';
import DropdownArrow from '../svgComponents/DropdownArrow';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import ErrorIcon from '../svgComponents/ErrorIcon';
import BarcodeScannerIcon from '../svgComponents/BarcodeScannerIcon';

export const TextFieldWithCSS = withStyles({
	root: {
		'& .Mui-disabled': {
			color: colors.lightGray,
		},
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
	},
})(TextField);

interface Props {
	label: string;
	value?: string | number;
	error?: boolean;
	helperText?: string;
	onChange?: (event: any) => void;
	disabled?: boolean;
	fixed?: boolean;
	labelAddOn?: JSX.Element;
	select?: boolean;
	name?: string;
	children?: JSX.Element | JSX.Element[];
	type?: string;
	placeholder?: string;
	wrapperClassName?: string;
	multiline?: boolean;
	rows?: number;
	isUserNotEditing?: boolean;
	wrapper?: string;
	inputWrapper?: string;
	input?: string;
	withOutBackgroundColor?: boolean;
	maxInputWidth?: string;
	onScanClick?: () => void;
	onPaste?: (event: any) => void;
}

function StandardTextField(props: Props) {
	const [rotateArrow, setRotateArrow] = React.useState(false);
	const [focused, setFocused] = React.useState(false);
	const classes = useStyles({
		rotate: rotateArrow,
		disabled: props.disabled,
		fixed: props.fixed,
		focused: focused,
		withOutBackgroundColor: props.withOutBackgroundColor,
		maxInputWidth: props.maxInputWidth,
		onScanClick: props.onScanClick,
	});
	const globalClasses = useGlobalStyles({
		disabled: props.disabled,
		fixed: props.fixed,
		isUserNotEditing: props.isUserNotEditing,
	});

	return (
		<div className={props.wrapper}>
			<div className={classes.labelWrapper}>
				<span className={globalClasses.label}>{props.label}</span>
				{props.labelAddOn ? props.labelAddOn : null}
			</div>
			<TextFieldWithCSS
				value={props.value}
				select={props.select}
				name={props.name}
				disabled={props.disabled}
				onPaste={props.onPaste && props.onPaste}
				type={props.type}
				placeholder={props.placeholder}
				label={undefined}
				children={props.children}
				onChange={props.onChange}
				error={props.error}
				multiline={props.multiline}
				rows={props.rows}
				helperText={''}
				className={props.inputWrapper ? props.inputWrapper : classes.inputWrapper}
				SelectProps={{
					IconComponent: () => (
						<div className={classes.dropdownArrowWrapper}>
							<DropdownArrow className={classes.rotate} disabled={props.disabled} />
						</div>
					),
					onOpen: () => {
						if (!props.disabled) {
							setTimeout(() => setRotateArrow(true), 10);
							setFocused(true);
						}
					},
					onClose: () => {
						setTimeout(() => setRotateArrow(false), 10);
						setFocused(false);
					},
					MenuProps: {
						PaperProps: {
							classes: {
								rounded: classes.dropdownContainer,
							},
						},
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
						style: {
							transform: 'translateX(-1px) translateY(4px)',
						},
					},
					classes: {
						selectMenu: classes.dropdownMenuWrapper,
					},
				}}
				InputProps={{
					onFocus: () => setFocused(true),
					onBlur: () => setFocused(false),
					classes: {
						input: props.input ? props.input : classes.input,
						underline: classes.underlineField,
					},
					endAdornment: props.onScanClick ? (
						<div className={classes.barcode}>
							<BarcodeScannerIcon onClick={() => (!props.disabled ? props.onScanClick!() : {})} />
						</div>
					) : null,
				}}
			/>
			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	fixed?: boolean;
	focused?: boolean;
	disabled?: boolean;
	onScanClick?: () => void;
	rotate: boolean;
	withOutBackgroundColor?: boolean;
	maxInputWidth?: string;
}

const useStyles = makeStyles(() => ({
	barcode: (p: StyleProps) => ({
		backgroundColor: colors.someYellow,
		margin: '2px 8px 2px 0px ',
		height: 26,
		width: 28,
		borderRadius: 13,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		cursor: p.disabled ? 'auto' : 'pointer',
	}),
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: (p: StyleProps) => ({
		width: '100%',
		borderRadius: '4px',
		backgroundColor: p.withOutBackgroundColor ? undefined : p.fixed ? 'rgba(161, 161, 161, 0.2)' : colors.white,
		border: `1px solid ${p.focused ? colors.darkGray : '#C0C0C0'}`,
		maxWidth: p.maxInputWidth,
	}),
	dropdownContainer: {
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
	},
	dropdownArrowWrapper: (p: StyleProps) => ({
		position: 'absolute',
		right: !p.onScanClick ? '12.5px' : '42px',
		cursor: 'pointer',
		pointerEvents: 'none',
		display: p.disabled ? 'none' : 'block',
	}),
	dropdownMenuWrapper: {
		height: '1.1876em',
	},
	input: (p: StyleProps) => ({
		...globalInputStyle(p.disabled),
		whiteSpace: 'pre',
		color: p.fixed ? colors.lightGray : colors.darkGray,
		borderRadius: '5px',
		paddingLeft: '12px',
	}),
	rotate: (p: StyleProps) => ({
		transform: p.rotate ? 'rotate(180deg)' : 'rotate(0deg)',
		transition: 'all 0.5s',
	}),
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
}));

export default StandardTextField;
