import * as React from 'react';

function BackIcon(props: React.SVGProps<any>) {
	return (
		<svg width={30} height={22} viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>{'5E329B47-CBD2-491B-B272-50AB7C24E55C'}</title>
			<g stroke="#111" strokeWidth={2} fill="none" fillRule="evenodd">
				<path d="M30 11H2" />
				<path strokeLinecap="square" d="M11 2l-9 9 9 9" />
			</g>
		</svg>
	);
}

export default BackIcon;
