import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useGlobalStyles } from '../consts/globalStyles';
import BackArrow from '../svgComponents/BackArrow';
import { colors } from '../consts/colors';
import { Control, Controller } from 'react-hook-form';
import Button from '../components/Button';
import AuthTextField from '../components/AuthTextField';
import { rules } from '../consts/rules';
import { contactUsFields } from '../containers/ContactUsContainer';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	onBackArrowPress: () => void;
	onContactUsPress: () => void;
}
function ContactUsScreen(props: Props) {
	const globalClasses = useGlobalStyles({ description: colors.gray });
	const classes = useStyles({});
	const { t } = useTranslation();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow onBackArrowPress={props.onBackArrowPress} />
			</div>
			<Grid container className={classes.wrapper}>
				<Grid item sm={1} />
				<Grid item sm={4}>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t('contact_us_screen_title')}</span>
					</div>
					<div className={classes.descriptionWrapper}>
						<span className={globalClasses.description}>{t('contact_us_screen_description')}</span>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contactUsFields.name}
							rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('contact_us_screen_name')}
									error={props.errors[contactUsFields.name] ? true : false}
									helperText={
										props.errors[contactUsFields.name]
											? props.errors[contactUsFields.name].message
											: ''
									}
								/>
							)}
						/>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contactUsFields.email}
							rules={{ required: rules(t).required }}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('contact_us_screen_email')}
									error={props.errors[contactUsFields.email] ? true : false}
									helperText={
										props.errors[contactUsFields.email]
											? props.errors[contactUsFields.email].message
											: ''
									}
									type="email"
									name="email"
								/>
							)}
						/>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contactUsFields.message}
							rules={{
								required: rules(t).required,
								pattern: rules(t).notEmptyPattern,
								minLength: rules(t).minLength(10),
							}}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('contact_us_screen_message')}
									multiline={true}
									rows={6}
									error={props.errors[contactUsFields.message] ? true : false}
									helperText={
										props.errors[contactUsFields.message]
											? props.errors[contactUsFields.message].message
											: ''
									}
								/>
							)}
						/>
					</div>
					<Button
						onClick={props.onContactUsPress}
						text={t('contact_us_screen_button')}
						textClassName={globalClasses.buttonText}
						buttonWrapperClassName={globalClasses.buttonWrapper}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		marginTop: '50px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},
	emailWrapper: {
		marginBottom: '24px',
	},
	descriptionWrapper: {
		marginBottom: '12px',
	},
}));

export default ContactUsScreen;
