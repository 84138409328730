import { History, LocationState } from 'history';
import moment from 'moment';

import { Service } from '../../service/service';
import StandardCaseInput, { ErrorInputType } from '../../service/types/StandardCase';
import { standardCaseInputFields } from '../../containers/StandardCaseInputContainer';
import { replaceStandardCaseHistoryData } from '../navigationHelper';
import Contract from '../../service/types/Contract';

export function updateStandradCaseInput(
	data: any,
	service: Service,
	standardInputCase: StandardCaseInput | undefined,
	partsNIO: number,
	partsIO: number,
	errorParts: ErrorInputType[],
	contractIDRef: number,
	history: History<LocationState>,
	setStandardInputCase: (standardInputCase: StandardCaseInput | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	addSetValue: (field: string, val: any) => void,
	updateAllStandardCaseInputs?: (input: StandardCaseInput[]) => void,
	contract?: Contract
) {
	if (standardInputCase) {
		const tempStandardCase = getUpdatedStandradCase(
			data,
			standardInputCase,
			partsNIO,
			partsIO,
			errorParts,
			contract
		);
		service
			.updateStandradCaseInput(contractIDRef, {
				...tempStandardCase,
				photo: tempStandardCase.photo
					? {
							...tempStandardCase.photo,
							data:
								tempStandardCase.photo && tempStandardCase.photo.data
									? tempStandardCase.photo.data.split(',')[1]
									: '',
					  }
					: undefined,
			})
			.then((res) => {
				if (res) {
					setStandardInputCase(tempStandardCase);
					replaceStandardCaseHistoryData(history, tempStandardCase);
					addSetValue(standardCaseInputFields.checkNumber, '');
					addSetValue(standardCaseInputFields.errorType, '');
					setIsUserEditing(false);
				}

				if (updateAllStandardCaseInputs) {
					service.getAllStandardCaseInputs(contractIDRef).then((res) => {
						updateAllStandardCaseInputs(res);
					});
				}
			})
			.catch(() => {});
	}
}

function getUpdatedStandradCase(
	data: any,
	standardInputCase: StandardCaseInput,
	partsNIO: number,
	partsIO: number,
	errorParts: ErrorInputType[],
	contract?: Contract
) {
	return new StandardCaseInput(
		standardInputCase.id,
		data[standardCaseInputFields.partNumber],
		data[standardCaseInputFields.deliveryNoteNumber] !== '' &&
		data[standardCaseInputFields.deliveryNoteNumber] !== null
			? +data[standardCaseInputFields.deliveryNoteNumber]
			: null,
		moment(data[standardCaseInputFields.deliveryNoteDate]),
		data[standardCaseInputFields.totalPartsTested],
		partsNIO,
		partsIO,
		data[standardCaseInputFields.partsReworked] ? data[standardCaseInputFields.partsReworked] : 0,
		standardInputCase.checkNumber,
		standardInputCase.qai,
		data[standardCaseInputFields.typeOfInspection],
		errorParts,
		parseInt(data[standardCaseInputFields.shift]),
		moment(data[standardCaseInputFields.date]),
		data[standardCaseInputFields.approved] === undefined ? true : data[standardCaseInputFields.approved],
		data[standardCaseInputFields.comment],
		data[standardCaseInputFields.photo],
		contract
			? contract?.workLocations?.find((el) => el.name === data[standardCaseInputFields.location])?.id
			: undefined
	);
}
