export interface NotificationInterface {
	text: string;
	additional: string;
}

export type NotificationState = {
	notification: NotificationInterface;
	visible: boolean;
};

export type Action = {
	type: string;
	[key: string]: any;
};

export const notificationInitialState: NotificationState = {
	notification: {
		text: '',
		additional: '',
	},
	visible: false,
};

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

const notificationReducer = (state = notificationInitialState, action: Action) => {
	switch (action.type) {
		case SET_NOTIFICATION:
			return { ...state, notification: action.notification, visible: true };
		case HIDE_NOTIFICATION:
			return { ...notificationInitialState };
		default:
			return state;
	}
};

export default notificationReducer;
