import moment, { Moment } from 'moment';

class WorkTimeTrack {
	contractID: number;
	startTime: Moment;
	contractNumber: string;
	profession?: number;

	constructor(contractID: number, startTime: Moment, contractNumber: string, profession?: number) {
		this.contractID = contractID;
		this.startTime = startTime;
		this.contractNumber = contractNumber;
		this.profession = profession;
	}

	public static fromJSON(maybe: any): WorkTimeTrack {
		if (!maybe) {
			throw new Error('must exist');
		}
		if (typeof maybe.contract_id !== 'number') {
			throw new Error('contract_id must be number');
		}
		if (!maybe.contract_id) {
			throw new Error('contract_id must exist');
		}
		if (typeof maybe.contract_number !== 'string') {
			throw new Error('contract_number must be string');
		}
		const startTime = moment(maybe.start_time);
		if (!startTime.isValid()) {
			throw new Error('start_time is not valid date');
		}
		return new WorkTimeTrack(maybe.contract_id, startTime, maybe.contract_number, maybe.profession);
	}
}

export default WorkTimeTrack;
