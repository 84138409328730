export const colors = {
	yellow: '#FFD600',
	darkGray: '#1A1A1A',
	lightGray: '#A1A1A1',
	gray: '#555252',
	white: '#FFFFFF',
	error: '#DE1C22',
	keyButton: '#E4E4E4',
	borderColor: '#C7C7C7',
	iconColor: '#111',
	timetrack: '#EEEEEE',
	timetrackBorderd: '#888888',
	addTimeBorder: '#DDDBDB',
	backgroundColor: '#F4F4F4',
	lightDarkGray: '#2E2E2E',
	lightYellow: '#FFF7CC',
	lighterYellow: '#FFFCEB',
	lightPink: '#F9D3D4',
	someYellow: '#FFEF99',
	someDarkYellow: '#FFDE33',
	lightGreen: '#C2EDD4',
	green: '#008556',
	photoGray: '#cccccc',
	partsNIOChart: '#998000',
	partReworkedChart: '#332B00',
	billGreen: '#E3F7EB',
	billRed: '#F9C8C9',
	billOragne: '#FFE1BE',
	red: '#DE1C22',
	lightRed: '#F7BFC0',
	black: '#000000',
};
