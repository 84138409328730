import React from 'react';

import { NotificationActionsContext, NotificationStateContext } from './NotificationContext';

export function useNotificationStateContext() {
	return React.useContext(NotificationStateContext);
}

export function useNotificationActionContext() {
	return React.useContext(NotificationActionsContext);
}
