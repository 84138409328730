import React from 'react';
import { useTranslation } from 'react-i18next';

import { rhenusOverviewParams } from '../consts/rhenusOverview';
import CustomTableBody from './CustomTableBody';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import { Grid } from '@material-ui/core';
import StandardDateTimePicker from './StandardDateTimePicker';
import { Control, Controller } from 'react-hook-form';
import { rhenusOverviewFields } from '../containers/RhenusOverviewContainer';
import moment from 'moment';
import { UserRole } from '../service/types/User';
import { useAuthStateContext } from '../context/auth/useAuth';

interface Props {
	informations: RhenusOverviewInformation[];
	control?: Control<Record<string, any>>;
	onZonePress: (item: RhenusOverviewInformation) => void;
}

function AdminTLZoneOverview(props: Props) {
	const { t } = useTranslation();
	const { user } = useAuthStateContext();

	const transformedZones = React.useMemo(
		() =>
			props.informations.map((information) => {
				return {
					id: information.zone.id,
					zoneName: information.zone.name,
					totalPartsTested: information.totalPartsTested,
					percentageOfErrorParts: information.precentageOfErrorParts,
					numberOfErrorParts: information.numberOfErrorParts,
					numberOfQAI: information.numberOfQAI,
				};
			}),
		[props.informations]
	);
	return (
		<div>
			{props.control && user?.role !== UserRole.QualityAssuranceInspector ? (
				<Grid container spacing={3}>
					<Grid item sm={3}>
						<Controller
							defaultValue={moment().subtract(8, 'h')}
							control={props.control}
							name={rhenusOverviewFields.fromDate}
							render={({ value, onChange }) => (
								<StandardDateTimePicker
									label={t('rhenus_case_zone_overview_screen_contract_from_date')}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3}>
						<Controller
							defaultValue={moment()}
							control={props.control}
							name={rhenusOverviewFields.toDate}
							render={({ value, onChange }) => (
								<StandardDateTimePicker
									label={t('rhenus_case_zone_overview_screen_contract_to_date')}
									onChange={onChange}
									value={value}
								/>
							)}
						/>
					</Grid>
				</Grid>
			) : null}

			<CustomTableBody
				data={transformedZones}
				dataTablePramas={rhenusOverviewParams}
				tableKey={'ZoneOverview'}
				buttonProps={{
					zoneName: {
						text: 'rhenus_case_overview_screen_view',
						onClick: (index: number) => props.onZonePress(props.informations[index]),
					},
				}}
			/>
		</div>
	);
}

export default AdminTLZoneOverview;
