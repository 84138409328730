import React from 'react';
import { makeStyles, MenuItem, Theme } from '@material-ui/core';
import moment from 'moment';
import { Control, Controller } from 'react-hook-form';
import { useWindowSize } from '@react-hook/window-size';

import KeyValueAutoComplete from './KeyValueAutoComplete';
import StandardDatePicker from './StandardDatePicker';
import { rules } from '../consts/rules';
import {
	initTimeValue,
	timeFormat,
	addWorkingTimeFields,
	createRecordTimeKeyValuePairs,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import StandardTimePicker from './StandardTimePicker';
import ErrorIcon from '../svgComponents/ErrorIcon';
import Button from './Button';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';
import StandardTextField from './StandardTextField';
import { RecordTimeType } from '../service/types/BreakTime';
import { priceListOptions } from '../consts/priceListOptions';

interface Props {
	qais: KeyValue[];
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	manualWorkTime: string;
	fromTime: string;
	onAddManualWorkTime: () => void;
	watch: (field: string) => any;
}

function AddWorkingTime(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { user } = useAuthStateContext();
	const [windowWidth] = useWindowSize();
	const { t } = useTranslation();
	const type = props.watch(addWorkingTimeFields.type);

	return (
		<div className={classes.addContainer}>
			<div className={classes.addContainerInnerWrapper}>
				<Controller
					defaultValue={createRecordTimeKeyValuePairs[0].value}
					control={props.control}
					name={addWorkingTimeFields.type}
					rules={{
						required: rules(t).required,
					}}
					render={({ onChange, value }) => (
						<StandardTextField
							label=""
							select
							value={value}
							withOutBackgroundColor={true}
							maxInputWidth={windowWidth < 1300 ? '100px' : undefined}
							onChange={(e) => {
								onChange(e.target.value);
							}}>
							{createRecordTimeKeyValuePairs.map((item) => (
								<MenuItem key={item.key} value={item.value}>
									<div className={classes.inputWrapperDropdown}>
										<span className={classes.input}>{t(item.key)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					)}
				/>

				<div className={classes.item}>
					<Controller
						defaultValue={null}
						control={props.control}
						name={addWorkingTimeFields.qai}
						rules={{
							required: rules(t).required,
							validate: (val: KeyValue | undefined) => {
								if (val && val.value) {
									return true;
								}
								return rules(t).required.message;
							},
						}}
						render={({ onChange, value }) => (
							<KeyValueAutoComplete
								label=""
								value={value}
								inputWrapper={classes.inputAddWrapper}
								placeholder={t('working_time_screen_add_placeholder')}
								onChange={onChange}
								wrapper={classes.wrapper}
								options={props.qais}
								withoutArrow={true}
								withoutClose={true}
								error={props.errors[addWorkingTimeFields.qai] ? true : false}
								helperText={
									props.errors[addWorkingTimeFields.qai]
										? props.errors[addWorkingTimeFields.qai].message
										: ''
								}
							/>
						)}
					/>
				</div>

				<div className={classes.item}>
					{type === RecordTimeType.Working ? (
						<Controller
							defaultValue={''}
							control={props.control}
							name={addWorkingTimeFields.profession}
							rules={{
								required: rules(t).required,
							}}
							render={({ onChange, value }) => (
								<KeyValueAutoComplete
									label=""
									value={value}
									inputWrapper={classes.inputAddWrapper}
									placeholder={t('working_time_screen_select_profession')}
									onChange={onChange}
									wrapper={classes.wrapper}
									options={priceListOptions.map((option) => ({
										key: option.label,
										value: option.value,
									}))}
									customGetOptionLabel={(option) => t(option.key)}
									withoutArrow={true}
									withoutClose={true}
									error={props.errors[addWorkingTimeFields.profession] ? true : false}
									helperText={
										props.errors[addWorkingTimeFields.profession]
											? props.errors[addWorkingTimeFields.profession].message
											: ''
									}
								/>
							)}
						/>
					) : null}
				</div>

				<div className={classes.dateTimeErrorWrapper}>
					<div className={classes.addItemWrapper}>
						{user?.role === UserRole.QualityAssuranceInspector ? null : (
							<div style={{ transform: 'translateX(-40px)' }}>
								<Controller
									defaultValue={moment()}
									control={props.control}
									name={addWorkingTimeFields.date}
									rules={{ required: rules(t).required }}
									render={({ onChange, value }) => (
										<StandardDatePicker
											label=""
											onChange={onChange}
											isUserNotEditing={true}
											disabled={false}
											value={value}
											inputWrapper={classes.dateTimeInputWrapper}
											wrapper={classes.dateTimeWrapper}
											error={props.errors[addWorkingTimeFields.date] ? true : false}
											helperText={
												props.errors[addWorkingTimeFields.date]
													? props.errors[addWorkingTimeFields.date].message
													: ''
											}
										/>
									)}
								/>
							</div>
						)}

						{type === RecordTimeType.Working ? (
							<div className={classes.addTimeItemWrapper}>
								<Controller
									defaultValue={initTimeValue}
									control={props.control}
									name={addWorkingTimeFields.from}
									rules={{
										required: rules(t).required,
									}}
									render={({ onChange, value }) => (
										<StandardTimePicker
											label=""
											value={value}
											onChange={(e) => {
												onChange(e);
											}}
											inputWrapper={classes.inputWrapper}
										/>
									)}
								/>
								<span>{' - '}</span>
								<Controller
									defaultValue={initTimeValue}
									control={props.control}
									name={addWorkingTimeFields.to}
									rules={{
										required: rules(t).required, //@ts-ignore
										validate: (item) => {
											if (
												moment(item).format(timeFormat) !==
												moment(props.fromTime).format(timeFormat)
											) {
												return true;
											} else return t('working_time_screen_add_error_time');
										},
									}}
									render={({ onChange, value }) => (
										<StandardTimePicker
											label=""
											value={value}
											onChange={onChange}
											inputWrapper={classes.inputWrapper}
										/>
									)}
								/>
							</div>
						) : (
							<div style={{ marginRight: '20px' }}>
								<Controller
									defaultValue={initTimeValue}
									control={props.control}
									name={addWorkingTimeFields.duration}
									rules={{
										required: rules(t).required, //@ts-ignore
										validate: (value) => {
											if (value && (moment(value).get('hours') || moment(value).get('minutes')))
												return true;
											return t('time_error');
										},
									}}
									render={({ onChange, value }) => (
										<StandardTimePicker
											label=""
											value={value}
											openTo="minutes"
											step={15}
											onChange={onChange}
											inputWrapper={classes.inputWrapper}
										/>
									)}
								/>
							</div>
						)}
					</div>
					{props.errors[
						type === RecordTimeType.Working ? addWorkingTimeFields.to : addWorkingTimeFields.duration
					] ? (
						<div className={classes.rowEnd}>
							<div className={classes.errorImageWrapper}>
								<ErrorIcon />
							</div>
							<span className={globalClasses.error}>
								{
									props.errors[
										type === RecordTimeType.Working
											? addWorkingTimeFields.to
											: addWorkingTimeFields.duration
									].message
								}
							</span>
						</div>
					) : null}
				</div>
			</div>
			<div className={classes.trackItemWrapperEnd}>
				{type === RecordTimeType.Working && (
					<div className={classes.addTimeWrapper}>
						<span className={globalClasses.description}>{props.manualWorkTime}</span>
					</div>
				)}
				<Button
					text={t('working_time_screen_add_button')}
					textClassName={globalClasses.buttonText}
					buttonWrapperClassName={classes.buttonWrapper}
					onClick={props.onAddManualWorkTime}
				/>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	dateTimeErrorWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	trackItemWrapper: {
		flex: 1,
		display: 'flex',
	},
	trackItemWrapperEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	rowEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	addItemWrapper: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	addTimeWrapper: {
		marginRight: '16px',
		padding: '4px 8px',
		border: `1px solid ${colors.addTimeBorder}`,
	},
	inputWrapper: {
		width: '50px',
		paddingLeft: '5px',
		padding: '0',
		border: `1px solid ${colors.addTimeBorder}`,
	},
	addTimeItemWrapper: {
		flex: 1,
		display: 'flex',
		whiteSpace: 'pre',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},

	addContainer: {
		padding: '20px 16px',
		display: 'flex',
		alignItems: 'center',
		border: `1px solid ${colors.timetrackBorderd}`,
		[theme.breakpoints.down(1100)]: {
			flexDirection: 'column',
			alignItems: 'flex-end',
		},
	},
	addContainerInnerWrapper: {
		flex: 1,
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down(1100)]: {
			marginBottom: '16px',
		},
	},
	inputAddWrapper: {
		width: '80%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	wrapper: {
		width: '100%',
	},
	dateTimeInputWrapper: {
		width: '120px',
		border: `1px solid ${colors.addTimeBorder}`,
		marginLeft: 'auto',
	},
	dateTimeWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '8px 22px',
		cursor: 'pointer',
	},
	clockWrapper: {
		marginLeft: '24px',
	},
	input: {
		...globalInputStyle(),
	},
	inputWrapperDropdown: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	item: {
		width: '100%',
		maxWidth: 250,
	},
}));

export default AddWorkingTime;
