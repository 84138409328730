import { Service } from '../../service/service';
import { OriginOfEmployee, UserRole } from '../../service/types/User';
import { History, LocationState } from 'history';
import { contactPersonFields } from '../../containers/ContactPersonContainer';
import { goToCustomerWithID } from '../navigationHelper';

export function createContactPerson(
	data: any,
	service: Service,
	customerID: number,
	history: History<LocationState>,
	setError: (field: string, val: any) => void
) {
	service
		.createUserForCustomer(customerID, {
			email: data[contactPersonFields.email].trim(),
			origin: OriginOfEmployee.External,
			role: UserRole.Customer,
			first_name: data[contactPersonFields.firstName].trim(),
			last_name: data[contactPersonFields.lastName].trim(),
			phonenumber: data[contactPersonFields.phonenumber].trim(),
			password: data[contactPersonFields.password],
		})
		.then(() => {
			goToCustomerWithID(history, customerID);
		})
		.catch((e) => {
			try {
				const error = JSON.parse(e.message);
				setContactPersonError(error, setError);
			} catch (e) {}
		});
}

function setContactPersonError(error: any, setError: (name: string, value: { message: string }) => void) {
	if (error.error.code === -32000) {
		setError(contactPersonFields.email, { message: 'contact_person_screen_email_in_use' });
	}
}
