import { Grid } from '@material-ui/core';
import React from 'react';

import { ContractZone } from '../helpers/contractHelper/generalContractHelper';
import CustomerQAIZoneItem from './CustomerQAIZoneItem';

interface Props {
	zones: ContractZone[];
	onZonePress: (item: ContractZone) => void;
}

function CustomerQAIZoneOverview(props: Props) {
	return (
		<Grid container spacing={2}>
			{props.zones.map((zone, index) => (
				<Grid item sm={6} key={`ZoneName${zone.name}`}>
					<CustomerQAIZoneItem name={zone.name} onPress={() => props.onZonePress(zone)} />
				</Grid>
			))}
		</Grid>
	);
}

export default CustomerQAIZoneOverview;
