import { Service } from '../service/service';
import Contract from '../service/types/Contract';
import User, { CreateEmployeeParams, UpdateEmployeeParams } from '../service/types/User';
import { QAILoginParams, ResetPasswordParams, UserLoginParams } from '../helpers/userHelper/generalUserHelper';
import Customer from '../service/types/Customer';
import StandardCaseInput, { StandardCaseFilterInterface } from '../service/types/StandardCase';
import { ContactParams } from '../containers/ContactUsContainer';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import RhenusCase from '../service/types/RhenusCase';
import WorkingTime from '../service/types/WorkingTime';
import MaterialTool from '../service/types/MaterialTool';
import Travel from '../service/types/Travel';
import { ContractStatusType, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import { LoginResponse } from '../consts/login';
import Transport from '../service/types/Transport';
import { Moment } from 'moment';
import BreakTime from '../service/types/BreakTime';
import { DashboardFilterInterface } from '../helpers/dashboardHelper/generalDashboardHelper';
import { WorkingTimeFilterInterface } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { InvoiceItem } from '../service/types/Invoice';
import { CreateGroup, UpdateGroup } from '../pages/GroupCreatePage';
import { Group } from '../pages/groupOverview/GroupOverview';

class ErrorMiddleware implements Service {
	next: Service;
	setError: (error: string) => void;
	constructor(next: Service, setError?: (error: string) => void) {
		this.next = next;
		this.setError = setError ? setError : () => {};
	}

	public setService(next: Service) {
		this.next = next;
	}

	public setJWTToken(JWTToken: string) {
		this.next.setJWTToken(JWTToken);
	}

	public async login(params: UserLoginParams): Promise<LoginResponse | undefined> {
		try {
			return await this.next.login(params);
		} catch (e) {
			const error = JSON.parse(e.message);
			if (error && error.error && !(error.error.code === -32001 || error.error.code === -32006)) {
				this.setError('login_request');
			}
			throw e;
		}
	}

	public async loginQAI(params: QAILoginParams): Promise<LoginResponse | undefined> {
		try {
			return await this.next.loginQAI(params);
		} catch (e) {
			const error = JSON.parse(e.message);
			if (error && error.error && !(error.error.code === -32001 || error.error.code === -32006)) {
				this.setError('login_qai_request');
			}
			throw e;
		}
	}

	public async checkToken(): Promise<boolean> {
		return await this.next.checkToken();
	}
	public async getAuthToken(refreshToken: string): Promise<string | undefined> {
		return await this.next.getAuthToken(refreshToken);
	}

	public async requestPasswordReset(email: string): Promise<boolean> {
		try {
			return await this.next.requestPasswordReset(email);
		} catch (e) {
			const error = JSON.parse(e.message);

			if (error && error.error && !(error.error.code === -32001 || error.error.code === -32006)) {
				this.setError('request_password_reset_request');
			}
			throw e;
		}
	}

	public async checkPasswordRessetToken(token: string): Promise<boolean> {
		try {
			return await this.next.checkPasswordRessetToken(token);
		} catch (e) {
			this.setError('check_password_reset_token_request');
			throw e;
		}
	}

	public async resetPassword(params: ResetPasswordParams): Promise<boolean> {
		try {
			return await this.next.resetPassword(params);
		} catch (e) {
			this.setError('reset_password_request');
			throw e;
		}
	}
	public async resetPasswordQAI(params: ResetPasswordParams): Promise<boolean> {
		try {
			return await this.next.resetPasswordQAI(params);
		} catch (e) {
			this.setError('reset_password_request');
			throw e;
		}
	}
	public async getAllUsers(): Promise<User[]> {
		return await this.next.getAllUsers();
	}

	public async readGroups(): Promise<Group[]> {
		return await this.next.readGroups();
	}
	public async createGroup(data: CreateGroup): Promise<boolean> {
		return await this.next.createGroup(data);
	}
	public async readGroup(id: number): Promise<Group> {
		return await this.next.readGroup(id);
	}
	public async updateGroup(data: UpdateGroup): Promise<boolean> {
		return await this.next.updateGroup(data);
	}
	public async deleteGroup(id: number): Promise<boolean> {
		return await this.next.deleteGroup(id);
	}

	public async logout(): Promise<boolean> {
		try {
			return await this.next.logout();
		} catch (e) {
			this.setError('logout_request');
			throw e;
		}
	}

	public async getContracts(): Promise<Contract[]> {
		return await this.next.getContracts();
	}
	public async createEmployee(params: CreateEmployeeParams): Promise<boolean> {
		try {
			return await this.next.createEmployee(params);
		} catch (e) {
			const error = JSON.parse(e.message);
			if ((error && error.error && error.code !== 32000) || error.error.code === -32006) {
				this.setError('create_employee_request');
			}
			throw e;
		}
	}
	public async createQAI(params: CreateEmployeeParams): Promise<boolean> {
		try {
			return await this.next.createQAI(params);
		} catch (e) {
			const error = JSON.parse(e.message);

			if (error && error.error && !(error.error.code === -32000 || error.error.code === -32006)) {
				this.setError('create_employee_request');
			}
			throw e;
		}
	}
	public async getUserInfo(): Promise<User> {
		return await this.next.getUserInfo();
	}
	public async getAllQAIs(): Promise<User[]> {
		return await this.next.getAllQAIs();
	}
	public async getAllTeamLeaders(): Promise<User[]> {
		return await this.next.getAllTeamLeaders();
	}
	public async getAllCustomers(): Promise<Customer[]> {
		return await this.next.getAllCustomers();
	}
	public async getAllQAIsByTeamLeader(teamLeaderID: number): Promise<User[]> {
		return await this.next.getAllQAIsByTeamLeader(teamLeaderID);
	}
	public async createCustomer(customer: Customer): Promise<number> {
		try {
			return await this.next.createCustomer(customer);
		} catch (e) {
			this.setError('create_customer_request');
			throw e;
		}
	}
	public async createUserForCustomer(id: number, params: CreateEmployeeParams): Promise<number> {
		try {
			return await this.next.createUserForCustomer(id, params);
		} catch (e) {
			const error = JSON.parse(e.message);
			if (error && error.error && error.error.code !== -32000) {
				this.setError('create_contact_person_request');
			}
			throw e;
		}
	}
	public async getCustomerFromUser(id: number): Promise<Customer> {
		return await this.next.getCustomerFromUser(id);
	}
	public async deleteUserByID(id: number): Promise<boolean> {
		try {
			return await this.next.deleteUserByID(id);
		} catch (e) {
			this.setError('delete_user_request');
			throw e;
		}
	}
	public async updateUserByID(id: number, params: UpdateEmployeeParams): Promise<boolean> {
		try {
			return await this.next.updateUserByID(id, params);
		} catch (e) {
			this.setError('update_user_request');
			throw e;
		}
	}
	public async updateCustomerByID(id: number, customer: Customer): Promise<boolean> {
		try {
			return await this.next.updateCustomerByID(id, customer);
		} catch (e) {
			this.setError('update_customer_request');
			throw e;
		}
	}
	public async getAllStandardCaseInputs(contractID: number): Promise<StandardCaseInput[]> {
		return await this.next.getAllStandardCaseInputs(contractID);
	}
	public async updateStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		try {
			return await this.next.updateStandradCaseInput(contractID, errorInput);
		} catch (e) {
			this.setError('update_standrad_case_input_request');
			throw e;
		}
	}
	public async createContract(contract: Contract): Promise<number> {
		try {
			return await this.next.createContract(contract);
		} catch (e) {
			this.setError('create_contract_request');
			throw e;
		}
	}

	public async createInvoice(
		contractID: number,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment
	): Promise<number> {
		try {
			return await this.next.createInvoice(contractID, dateOfInvoice, fromDate, toDate);
		} catch (e) {
			this.setError('create_invoice_request');
			throw e;
		}
	}

	public async updateInvoice(
		id: number,
		creationDate: Moment,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment,
		billNumber: string
	): Promise<void> {
		try {
			return await this.next.updateInvoice(id, creationDate, dateOfInvoice, fromDate, toDate, billNumber);
		} catch (e) {
			throw e;
		}
	}

	public async completeInvoice(id: number): Promise<void> {
		try {
			return await this.next.completeInvoice(id);
		} catch (e) {
			throw e;
		}
	}

	public async deleteInvoice(id: number): Promise<void> {
		try {
			return await this.next.deleteInvoice(id);
		} catch (e) {
			throw e;
		}
	}

	public async deleteContractByID(contractID: number): Promise<boolean> {
		try {
			return await this.next.deleteContractByID(contractID);
		} catch (e) {
			this.setError('delete_contract_request');
			throw e;
		}
	}
	public async contact(params: ContactParams): Promise<boolean> {
		try {
			return await this.next.contact(params);
		} catch (e) {
			this.setError('contact');
			throw e;
		}
	}
	public async createStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		try {
			return await this.next.createStandradCaseInput(contractID, errorInput);
		} catch (e) {
			this.setError('create_standrad_case_input_request');
			throw e;
		}
	}
	public async deleteStandradCaseInputByID(contractID: number, errorInputID: number): Promise<boolean> {
		try {
			return await this.next.deleteStandradCaseInputByID(contractID, errorInputID);
		} catch (e) {
			this.setError('delete_standrad_case_input_request');
			throw e;
		}
	}
	public async getRhenusOverviewInformations(
		contractID: number,
		from: Moment,
		to: Moment
	): Promise<RhenusOverviewInformation[]> {
		return await this.next.getRhenusOverviewInformations(contractID, from, to);
	}
	public async getZoneCaseInputs(contractID: number, zoneID: number): Promise<RhenusCase[]> {
		return await this.next.getZoneCaseInputs(contractID, zoneID);
	}
	public async deleteRhenusCaseInputByID(contractID: number, zoneID: number, errorID: number): Promise<boolean> {
		try {
			return await this.next.deleteRhenusCaseInputByID(contractID, zoneID, errorID);
		} catch (e) {
			this.setError('delete_standrad_case_input_request');
			throw e;
		}
	}
	public async createRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		try {
			return await this.next.createRhenusCaseInput(contractID, zoneID, rhenusCaseInput);
		} catch (e) {
			this.setError('create_standrad_case_input_request');
			throw e;
		}
	}
	public async updateRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		try {
			return await this.next.updateRhenusCaseInput(contractID, zoneID, rhenusCaseInput);
		} catch (e) {
			this.setError('update_standrad_case_input_request');
			throw e;
		}
	}
	public async sendErrorReport(message: string): Promise<boolean> {
		try {
			return await this.next.sendErrorReport(message);
		} catch (e) {
			this.setError('sendErrorReport');
			throw e;
		}
	}
	public async addManualWorkTime(contractID: number, worktime: WorkingTime): Promise<number> {
		try {
			return await this.next.addManualWorkTime(contractID, worktime);
		} catch (e) {
			this.setError('add_manual_working_time_request');
			throw e;
		}
	}
	public async addManualBreakTime(contractID: number, worktime: BreakTime): Promise<number> {
		try {
			return await this.next.addManualBreakTime(contractID, worktime);
		} catch (e) {
			this.setError('add_manual_working_time_request');
			throw e;
		}
	}
	public async startTrackingTime(contractID: number): Promise<boolean> {
		try {
			return await this.next.startTrackingTime(contractID);
		} catch (e) {
			this.setError('start_tracking_time_request');
			throw e;
		}
	}
	public async stopTrackingTime(
		contractID: number,
		profession: number | undefined
	): Promise<WorkingTime | undefined> {
		try {
			return await this.next.stopTrackingTime(contractID, profession);
		} catch (e) {
			this.setError('stop_tracking_time_request');
			throw e;
		}
	}
	public async getAllRecords(contractID: number): Promise<(WorkingTime | BreakTime)[]> {
		return await this.next.getAllRecords(contractID);
	}

	public async readShifts(contractID: number): Promise<ShiftInterface[]> {
		return await this.next.readShifts(contractID);
	}

	public async deleteRecordByID(contractID: number, recordID: number): Promise<boolean> {
		try {
			return await this.next.deleteRecordByID(contractID, recordID);
		} catch (e) {
			this.setError('delete_working_time_request');
			throw e;
		}
	}
	public async deleteBreakByID(contractID: number, recordID: number): Promise<boolean> {
		try {
			return await this.next.deleteBreakByID(contractID, recordID);
		} catch (e) {
			this.setError('delete_working_time_request');
			throw e;
		}
	}
	public async deleteWaitingByID(contractID: number, recordID: number): Promise<boolean> {
		try {
			return await this.next.deleteWaitingByID(contractID, recordID);
		} catch (e) {
			this.setError('delete_working_time_request');
			throw e;
		}
	}
	public async updateRecordByID(contractID: number, record: WorkingTime): Promise<boolean> {
		try {
			return await this.next.updateRecordByID(contractID, record);
		} catch (e) {
			this.setError('update_working_time_request');
			throw e;
		}
	}
	public async updateBreakByID(contractID: number, record: BreakTime): Promise<boolean> {
		try {
			return await this.next.updateBreakByID(contractID, record);
		} catch (e) {
			this.setError('update_working_time_request');
			throw e;
		}
	}
	public async updateWaitingByID(contractID: number, record: BreakTime): Promise<boolean> {
		try {
			return await this.next.updateWaitingByID(contractID, record);
		} catch (e) {
			this.setError('update_working_time_request');
			throw e;
		}
	}
	public async getMaterialExpenses(contractID: number): Promise<MaterialTool[]> {
		return await this.next.getMaterialExpenses(contractID);
	}
	public async getToolExpenses(contractID: number): Promise<MaterialTool[]> {
		return await this.next.getToolExpenses(contractID);
	}

	public async getAllInvoices(contractID: number): Promise<any> {
		return await this.next.getAllInvoices(contractID);
	}

	public async exportInvoice(id: number): Promise<string | undefined> {
		try {
			return await this.next.exportInvoice(id);
		} catch (e) {
			throw e;
		}
	}

	public async getInvoiceById(invoiceID: number): Promise<InvoiceItem | undefined> {
		return await this.next.getInvoiceById(invoiceID);
	}
	public async getTravelExpenses(contractID: number): Promise<Travel[]> {
		return await this.next.getTravelExpenses(contractID);
	}
	public async createMaterialExpenses(contractID: number, material: MaterialTool): Promise<number> {
		try {
			return await this.next.createMaterialExpenses(contractID, material);
		} catch (e) {
			this.setError('create_expense_request');
			throw e;
		}
	}
	public async createTravelExpenses(contractID: number, travel: Travel): Promise<number> {
		try {
			return await this.next.createTravelExpenses(contractID, travel);
		} catch (e) {
			this.setError('create_expense_request');
			throw e;
		}
	}
	public async createToolExpenses(contractID: number, tool: MaterialTool): Promise<number> {
		try {
			return await this.next.createToolExpenses(contractID, tool);
		} catch (e) {
			this.setError('create_expense_request');
			throw e;
		}
	}
	public async updateMaterialExpenses(contractID: number, material: MaterialTool): Promise<boolean> {
		try {
			return await this.next.updateMaterialExpenses(contractID, material);
		} catch (e) {
			this.setError('update_expense_request');
			throw e;
		}
	}
	public async updateTravelExpenses(contractID: number, travel: Travel): Promise<boolean> {
		try {
			return await this.next.updateTravelExpenses(contractID, travel);
		} catch (e) {
			this.setError('update_expense_request');
			throw e;
		}
	}
	public async updateToolExpenses(contractID: number, tool: MaterialTool): Promise<boolean> {
		try {
			return await this.next.updateToolExpenses(contractID, tool);
		} catch (e) {
			this.setError('update_expense_request');
			throw e;
		}
	}

	public async deleteMaterialExpensesByID(contractID: number, materialID: number): Promise<boolean> {
		try {
			return await this.next.deleteMaterialExpensesByID(contractID, materialID);
		} catch (e) {
			this.setError('delete_expense_request');
			throw e;
		}
	}
	public async deleteTravelExpensesByID(contractID: number, travelID: number): Promise<boolean> {
		try {
			return await this.next.deleteTravelExpensesByID(contractID, travelID);
		} catch (e) {
			this.setError('delete_expense_request');
			throw e;
		}
	}
	public async deleteToolExpensesByID(contractID: number, toolID: number): Promise<boolean> {
		try {
			return await this.next.deleteToolExpensesByID(contractID, toolID);
		} catch (e) {
			this.setError('delete_expense_request');
			throw e;
		}
	}
	public async openCloseContract(contractID: number, status: ContractStatusType): Promise<boolean> {
		try {
			return await this.next.openCloseContract(contractID, status);
		} catch (e) {
			this.setError('contract_status_error');
			throw e;
		}
	}

	public async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
		try {
			return await this.next.changePassword(oldPassword, newPassword);
		} catch (e) {
			this.setError('change_password_request');
			throw e;
		}
	}
	public async aprroveInput(contractID: number, inputID: number, zoneID?: number): Promise<boolean> {
		try {
			return await this.next.aprroveInput(contractID, inputID, zoneID);
		} catch (e) {
			this.setError('approve_inuput_request');
			throw e;
		}
	}
	public async updateContract(contract: Contract): Promise<boolean> {
		try {
			return await this.next.updateContract(contract);
		} catch (e) {
			this.setError('update_contract_request');
			throw e;
		}
	}
	public async checkTrackingTime(): Promise<WorkTimeTrack | undefined> {
		try {
			return await this.next.checkTrackingTime();
		} catch (e) {
			this.setError('check_tracking_time_request');
			throw e;
		}
	}
	public async getTeamLeaderByContract(contractID: number): Promise<User | undefined> {
		return await this.next.getTeamLeaderByContract(contractID);
	}
	public async getCustomerByContract(contractID: number): Promise<Customer | undefined> {
		return await this.next.getCustomerByContract(contractID);
	}
	public async acceptPrivacyPolicy(): Promise<boolean> {
		try {
			return await this.next.acceptPrivacyPolicy();
		} catch (e) {
			this.setError('accept_privacy_policy');
			throw e;
		}
	}

	public async getContract(contractID: number): Promise<Contract | undefined> {
		return await this.next.getContract(contractID);
	}
	public async getStandardCaseInputByID(
		contractID: number,
		errorInputID: number
	): Promise<StandardCaseInput | undefined> {
		return await this.next.getStandardCaseInputByID(contractID, errorInputID);
	}
	public async getUserByID(userID: number): Promise<User | undefined> {
		return await this.next.getUserByID(userID);
	}
	public async getCustomerByID(customerID: number): Promise<Customer | undefined> {
		return await this.next.getCustomerByID(customerID);
	}
	public async getTransportExpenses(contractID: number): Promise<Transport[]> {
		return await this.next.getTransportExpenses(contractID);
	}
	public async updateTransportExpenses(contractID: number, transport: Transport): Promise<boolean> {
		try {
			return await this.next.updateTransportExpenses(contractID, transport);
		} catch (e) {
			this.setError('update_expense_request');
			throw e;
		}
	}
	public async deleteTransportExpensesByID(contractID: number, transportID: number): Promise<boolean> {
		try {
			return await this.next.deleteTransportExpensesByID(contractID, transportID);
		} catch (e) {
			this.setError('delete_expense_request');
			throw e;
		}
	}
	public async createTransportExpenses(contractID: number, transport: Transport): Promise<number> {
		try {
			return await this.next.createTransportExpenses(contractID, transport);
		} catch (e) {
			this.setError('create_expense_request');
			throw e;
		}
	}
	public async getContactQAI24ByContract(contractID: number): Promise<User | undefined> {
		return await this.next.getContactQAI24ByContract(contractID);
	}

	public async getRhenusCaseInputByID(errorID: number): Promise<RhenusCase | undefined> {
		return await this.next.getRhenusCaseInputByID(errorID);
	}
	public async getPhoto(photoURL: string): Promise<string | undefined> {
		return await this.next.getPhoto(photoURL);
	}
	public async deleteCustomerByID(customerID: number): Promise<boolean> {
		try {
			return await this.next.deleteCustomerByID(customerID);
		} catch (e) {
			this.setError('delete_customer_request');
			throw e;
		}
	}

	public async createStandardErrorInputsExcelSheet(
		contractID: number,
		data: DashboardFilterInterface,
		language: string
	): Promise<string | undefined> {
		try {
			return await this.next.createStandardErrorInputsExcelSheet(contractID, data, language);
		} catch (e) {
			this.setError('create_standard_error_inputs_excel_sheet');
			throw e;
		}
	}

	public async createProcessContractStandardErrorInputsExcelSheet(
		contractID: number,
		data: StandardCaseFilterInterface,
		language: string
	): Promise<string | undefined> {
		try {
			return await this.next.createProcessContractStandardErrorInputsExcelSheet(contractID, data, language);
		} catch (e) {
			this.setError('create_standard_error_inputs_excel_sheet');
			throw e;
		}
	}

	public async createWorkingTimeExcelSheet(
		contractID: number,
		data: WorkingTimeFilterInterface,
		language: string
	): Promise<string | undefined> {
		try {
			return await this.next.createWorkingTimeExcelSheet(contractID, data, language);
		} catch (e) {
			this.setError('create_working_time_excel_sheet');
			throw e;
		}
	}

	public async contractSummary(contractID: number, fromDate: Moment | null, toDate: Moment | null): Promise<any> {
		return await this.next.contractSummary(contractID, fromDate, toDate);
	}
}

export default ErrorMiddleware;
