import React from 'react';
import service from '../../service/service';
import WorkTimeTrack from '../../service/types/WorkTimeTrack';
import { WorkingTimeContextActions } from './WorkingTimeContext';

import { WorkingTimeState, Action, START_TRACKING_TIME, STOP_TRACKING_TIME } from './workingTimeReducer';

export const createWorkingTimeState = (state: WorkingTimeState): WorkingTimeState => ({
	...state,
});

export const createActionWorkingTimeContext = (dispatch: React.Dispatch<Action>): WorkingTimeContextActions => ({
	setRecord: (record: WorkTimeTrack | undefined) => dispatch({ type: START_TRACKING_TIME, record }),
	startTracking: async (record: WorkTimeTrack) => {
		const res = await service.startTrackingTime(record.contractID).catch(() => {});
		if (res) {
			dispatch({ type: START_TRACKING_TIME, record });
		}
		return res;
	},
	stopTracking: async (record: WorkTimeTrack) => {
		const res = await service.stopTrackingTime(record.contractID, record.profession).catch(() => {});
		if (res) {
			dispatch({ type: STOP_TRACKING_TIME });
		}
		return res;
	},
});
