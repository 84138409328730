import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import StandardRadioButton from './StandardRadioButton';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rules } from '../consts/rules';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { colors } from '../consts/colors';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';

interface Props {
	isUserEditing: boolean;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	fieldName: string;
}

function ApproveItem(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const { user } = useAuthStateContext();

	if (user?.role === UserRole.QualityAssuranceInspector) return null;

	return (
		<div className={classes.aprroveWrapper}>
			<div className={classes.approveTextWrapper}>
				<span className={globalClasses.description}>{t('approve_explanation')} </span>
			</div>
			<Controller
				defaultValue={false}
				control={props.control}
				name={props.fieldName}
				rules={{
					validate: (val) => {
						if (val !== undefined) {
							return true;
						}
						return rules(t).required.message;
					},
				}}
				render={({ onChange, value }) => (
					<div>
						<div className={classes.row}>
							<StandardRadioButton
								label={'approve'}
								value={value}
								disabled={!props.isUserEditing}
								onChange={onChange}
								radioButtonValue={true}
							/>
							<StandardRadioButton
								label={'dont_approve'}
								value={value}
								disabled={!props.isUserEditing}
								onChange={onChange}
								radioButtonValue={false}
							/>
						</div>
						{props.errors[props.fieldName] ? (
							<div className={classes.errorRow}>
								<div className={classes.errorImageWrapper}>
									<ErrorIcon />
								</div>
								<span className={globalClasses.error}>
									{t(props.errors[props.fieldName] ? props.errors[props.fieldName].message : '')}
								</span>
							</div>
						) : null}
					</div>
				)}
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	errorRow: {
		marginTop: '10px',
		display: 'flex',
		alignItems: 'center',
	},
	aprroveWrapper: {
		backgroundColor: colors.lightYellow,
		padding: '20px',
		marginTop: '56px',
		display: 'flex',
		alignItems: 'center',
	},
	approveTextWrapper: {
		width: '33%',
		marginRight: '50px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	row: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default ApproveItem;
