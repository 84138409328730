export const fonts = {
	bold: 'IBM-Plex-Sans-Bold',
	boldItalic: 'IBM-Plex-Sans-Bold-Italic',
	extraLight: 'IBM-Plex-Sans-Extra-Light',
	extraLightItalic: 'IBM-Plex-Sans-Extra-Light-Italic',
	italic: 'IBM-Plex-Sans-Italic',
	light: 'IBM-Plex-Sans-Light',
	lightItalic: 'IBM-Plex-Sans-Light-Italic',
	medium: 'IBM-Plex-Sans-Medium',
	mediumItalic: 'IBM-Plex-Sans-Medium-Italic',
	regular: 'IBM-Plex-Sans-Regular',
	semiBold: 'IBM-Plex-Sans-Semi-Bold',
	semiBoldItalic: 'IBM-Plex-Sans-Semi-Bold-Italic',
	thin: 'IBM-Plex-Sans-Thin',
	thinItalic: 'IBM-Plex-Sans-Thin-Italic',
};
