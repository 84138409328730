import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import {
	addBreakTimeFields,
	filterRecordTimeTypeKeyValuePairs,
	timeFromUnixToStringFormat,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { fonts } from '../consts/fonts';
import { Controller, useForm } from 'react-hook-form';
import { rules } from '../consts/rules';
import { useTranslation } from 'react-i18next';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import KeyValueAutoComplete from './KeyValueAutoComplete';
import DeleteIcon from '../svgComponents/DeleteIcon';
import RemoveIcon from '../svgComponents/RemoveIcon';
import EditIcon from '../svgComponents/EditIcon';
import SaveIcon from '../svgComponents/SaveIcon';
import BilledItem from './BilledItem';
import { UserRole } from '../service/types/User';
import { useAuthStateContext } from '../context/auth/useAuth';
import BreakTime, { RecordTimeType } from '../service/types/BreakTime';
import { createBreakTimeWithID } from '../helpers/breakTimeHelper/updateBreakTimeHelper';
import StandardTimePicker from './StandardTimePicker';
import ErrorIcon from '../svgComponents/ErrorIcon';

interface Props {
	index: number;
	record: BreakTime;
	users: KeyValue[];
	onSaveRecordPress: (record: BreakTime) => Promise<boolean | void>;
	onDeletePress: (record: BreakTime) => void;
}
function BreakItem({ index, record, users, onSaveRecordPress, onDeletePress }: Props) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const [edit, setEdit] = React.useState(false);
	const classes = useStyles({ edit: edit, type: record.type });
	const { control, errors, handleSubmit, getValues } = useForm();
	const { user } = useAuthStateContext();

	const qai = React.useMemo(() => {
		return users.find((user) => user.value.id === record.qai);
	}, [record, users]);

	const reporter = React.useMemo(() => {
		return users.find((user) => user.value.id === record.reporter);
	}, [record, users]);

	const time = timeFromUnixToStringFormat(record.duration);

	const onEditPress = () => setEdit(true);

	const onSavePress = (data: any) => {
		const breakTime = getValues(addBreakTimeFields.duration);
		const date = new Date(breakTime);
		const fullBreakTime = date.getHours() * 3600 + date.getMinutes() * 60;

		onSaveRecordPress({ ...createBreakTimeWithID(data, record), duration: fullBreakTime })
			.then((res) => {
				if (res) {
					setEdit(false);
				}
			})
			.catch(() => {});
	};

	const onDeletePressHandler = () => onDeletePress(record);

	const onCancelPress = () => setEdit(false);

	return (
		<div className={classes.wrapper}>
			<div className={classes.itemWrapper}>
				<div className={classes.indexWrapper}>
					<span className={globalClasses.smallText}> {`${index + 1}.`}</span>
				</div>
				<div>
					<div className={classes.itemWrapper}>
						<span className={globalClasses.smallTextDark}>
							{t(filterRecordTimeTypeKeyValuePairs.find((item) => item.value === record.type)?.key || '')}
						</span>
					</div>
				</div>
			</div>
			<div className={classes.itemWrapper}>
				<span className={globalClasses.smallTextDark}>{reporter?.key}</span>
			</div>
			<div className={classes.itemWrapper}>
				{edit ? (
					<Controller
						defaultValue={qai}
						control={control}
						name={addBreakTimeFields.qai}
						rules={{
							required: rules(t).required,
							validate: (val: KeyValue | undefined) => {
								if (val && val.value) {
									return true;
								}
								return rules(t).required.message;
							},
						}}
						render={({ onChange, value }) => (
							<KeyValueAutoComplete
								label=""
								value={value}
								inputWrapper={classes.inputAddWrapper}
								placeholder={t('working_time_screen_add_placeholder')}
								onChange={onChange}
								wrapper={classes.inputKeyValueWrapper}
								options={users.filter((user) => !user.value.deleted)}
								input={globalClasses.inputTableStyle}
								withoutArrow={true}
								withoutClose={true}
								error={errors[addBreakTimeFields.qai] ? true : false}
								helperText={
									errors[addBreakTimeFields.qai] ? errors[addBreakTimeFields.qai].message : ''
								}
							/>
						)}
					/>
				) : (
					<span className={globalClasses.smallTextDark}>{qai?.key}</span>
				)}
			</div>

			<div className={classes.itemWrapper}>
				<span className={globalClasses.smallTextDark}>-</span>
			</div>
			{user?.role !== UserRole.QualityAssuranceInspector ? (
				<div className={classes.itemWrapper}>
					<BilledItem billed={record.billed} billable={true} />
				</div>
			) : null}
			<div className={classes.itemWrapperSpaceBetween}>
				<div className={classes.itemWrapperEnd}>
					<div className={classes.timeTitleWrapper}>
						<span className={classes.smallTextLight}>{`${t('working_time_screen_time')}: `}</span>
					</div>
					<div>
						{edit ? (
							<div className={classes.errorRow}>
								<Controller
									defaultValue={moment()
										.set('hour', record.duration / 60 / 60)
										.set('minute', (record.duration / 60) % 60)}
									control={control}
									name={addBreakTimeFields.duration}
									rules={{
										required: rules(t).required,
										//@ts-ignore:next-line
										validate: (value) => {
											if (value && (moment(value).get('hours') || moment(value).get('minutes')))
												return true;
											return t('time_error')!;
										},
									}}
									render={({ onChange, value }) => (
										<StandardTimePicker
											label=""
											step={15}
											openTo="minutes"
											value={value}
											input={globalClasses.smallTextDark}
											onChange={onChange}
											inputWrapper={classes.timeInputWrapper}
										/>
									)}
								/>
								{errors[addBreakTimeFields.duration] ? (
									<div className={classes.itemWrapper}>
										<div className={classes.errorImageWrapper}>
											<ErrorIcon />
										</div>
										<span className={globalClasses.error}>
											{errors[addBreakTimeFields.duration].message}
										</span>
									</div>
								) : null}
							</div>
						) : (
							<span className={globalClasses.mediumTextDark}>{time}</span>
						)}
					</div>
				</div>
				{
					<div className={classes.buttonsWrapper}>
						{edit ? (
							<SaveIcon
								onClick={handleSubmit(onSavePress)}
								height={18}
								width={18}
								className={classes.editIconWrapper}
							/>
						) : (
							<EditIcon
								onClick={onEditPress}
								height={18}
								width={18}
								className={classes.editIconWrapper}
							/>
						)}
						{edit ? (
							<RemoveIcon
								onClick={onCancelPress}
								height={18}
								width={18}
								className={classes.closeIconWrapper}
							/>
						) : (
							<DeleteIcon onClick={onDeletePressHandler} className={classes.closeIconWrapper} />
						)}
					</div>
				}
			</div>
		</div>
	);
}

interface StyleProps {
	edit: boolean;
	type: RecordTimeType;
}

const useStyles = makeStyles(() => ({
	errorRow: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	inputAddWrapper: {
		width: '100%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
	},
	inputWrapper: {
		width: '95%',
	},
	indexWrapper: {
		display: 'flex',
		marginRight: '24px',
		alignItems: 'center',
	},
	fromWrapper: {
		display: 'flex',
		marginRight: '16px',
		alignItems: 'center',
	},
	toWrapper: {
		display: 'flex',
		marginLeft: '16px',
		alignItems: 'center',
	},
	timeTitleWrapper: {
		display: 'flex',
		marginRight: '12px',
		alignItems: 'center',
	},

	wrapper: (p: StyleProps) => ({
		display: 'flex',
		padding: '20px 16px',
		margin: '4px 0px',
		border: `1px solid ${colors.timetrackBorderd}`,
		//backgroundColor: p.type === RecordTimeType.Break ? colors.billRed : colors.billOragne,
	}),
	itemWrapper: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},
	buttonsWrapper: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	smallTextLight: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.gray,
		letterSpacing: 0.4,
	},
	itemWrapperSpaceBetween: {
		display: 'flex',
		flex: 1,
		minWidth: '270px',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	itemWrapperEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	timeInputWrapper: {
		width: '45px',
		paddingLeft: '5px',
		transform: 'translateX(-6px)',
		padding: '0',
		border: `1px solid ${colors.addTimeBorder}`,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	inputKeyValueWrapper: {
		width: '95%',
		padding: '0px 0px',
		'& .MuiInput-input': {
			margin: 0,
			color: colors.darkGray,
			fontSize: '14px',
			lineHeight: '20px',
			fontFamily: fonts.regular,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
	},
	dashWrapper: (p: StyleProps) => ({
		transform: p.edit ? 'translate(-6px)' : '',
	}),
	editIconWrapper: {
		marginRight: '16px',
		cursor: 'pointer',
	},
	closeIconWrapper: {
		cursor: 'pointer',
	},
}));

export default BreakItem;
