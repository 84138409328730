import { Service } from '../../service/service';
import WorkingTime from '../../service/types/WorkingTime';
import { StandardDialogInterface } from '../../components/StandardDialog';
import React from 'react';
import BreakTime, { RecordTimeType } from '../../service/types/BreakTime';

export function deleteWorkingTime(
	service: Service,
	contractID: number,
	deleteRecord: WorkingTime | BreakTime,
	records: (WorkingTime | BreakTime)[],
	dialogRef: React.MutableRefObject<null | StandardDialogInterface>,
	allRecordsRef: React.MutableRefObject<(WorkingTime | BreakTime)[]>,
	setRecords: (records: (WorkingTime | BreakTime)[]) => void
) {
	getDeleteService(deleteRecord, contractID, service)
		.then((res) => {
			if (res) {
				const filtereRecords = records.filter(
					(item) => !(item.id === deleteRecord.id && item.type === deleteRecord.type)
				);
				setRecords(filtereRecords);
				allRecordsRef.current = filtereRecords;
				dialogRef.current?.setDialogState(false);
			}
		})
		.catch(() => {});
}

function getDeleteService(deleteRecord: WorkingTime | BreakTime, contractID: number, service: Service) {
	if (deleteRecord instanceof WorkingTime) return service.deleteRecordByID(contractID, deleteRecord.id);
	if (deleteRecord.type === RecordTimeType.Break) return service.deleteBreakByID(contractID, deleteRecord.id);
	return service.deleteWaitingByID(contractID, deleteRecord.id);
}
