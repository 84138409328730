import moment from 'moment';

const CHAR_OBJ = {
	ä: 'ae',
	Ä: 'Ae',
	ö: 'oe',
	Ö: 'Oe',
	ü: 'ue',
	Ü: 'Ue',
	ß: 'ss',
};

export const formatFileName = (name: string) => {
	return name
		.replace(/[ ]/g, '-')
		.replace(/[äöüßÄÖÜ]/g, function (char) {
			//@ts-ignore
			return CHAR_OBJ[char];
		})
		.replace(/[^a-z-]/gi, '');
};

const generateDocumentName = (overviewType: string, contractNumber: string) => {
	const date = moment().format('DDMMYYYY');
	return `${date}_${overviewType}_${contractNumber}`;
};

export const generateAndDownloadExcel = (data: string, overviewType: string, contractNumber: string) => {
	const link = document.createElement('a');
	const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

	link.download = `${generateDocumentName(overviewType, contractNumber)}.xlsx`;
	link.href = mediaType + data;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
