import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useWindowHeight } from '@react-hook/window-size';

import '../App.css';
import Avatar from '../components/Avatar';
import LanguageList from '../components/LanguageList';
import MenuItem from '../components/MenuItem';
import ReduceWorkTimeTracking from '../components/ReduceWorkTimeTracking';
import { menuItemsData } from '../consts/menuItems';
import { useWorkingTimeStateContext } from '../context/workingTime/useWorkingTime';
import { useGlobalStyles } from '../consts/globalStyles';
import background from '../assets/images/backgroud.png';
import IQRLogoBlack from '../svgComponents/IQRLogoBlack';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';

function HomeScreen() {
	const windowHeight = useWindowHeight();
	const { user } = useAuthStateContext();
	const classes = useStyles({ windowHeight: windowHeight });
	const globalClasses = useGlobalStyles();
	const { record } = useWorkingTimeStateContext();

	return (
		<Grid container className={classes.containerWrapper} justify="center" direction={'column'}>
			<div className={classes.backgroundImageWrapper}>
				<img className={classes.backgroundImage} src={background} alt={'Background'} />
			</div>
			<IQRLogoBlack className={globalClasses.logo} />

			<Grid item className={classes.avatarWrapper}>
				{record ? (
					<div className={classes.recordWrapper}>
						<ReduceWorkTimeTracking
							contractID={record.contractID}
							contractNumber={record.contractNumber}
							homeScreen={true}
						/>
					</div>
				) : null}
				<div className={classes.languageWarpper}>
					<LanguageList inverted={true} />
				</div>
				<Avatar />
			</Grid>
			<Grid container item spacing={4} className={classes.contentWrapper} alignItems="center" justify="center">
				{menuItemsData(user?.role === UserRole.Administrator || user?.role === UserRole.Accounting).map(
					(item, index) => (
						<MenuItem {...item} key={`MenuItem${index}`} />
					)
				)}
			</Grid>
		</Grid>
	);
}

interface StyleProps {
	windowHeight: number;
}

const useStyles = makeStyles(() => ({
	containerWrapper: (p: StyleProps) => ({
		minHeight: p.windowHeight,
		overflow: 'hidden',
	}),
	avatarWrapper: { position: 'absolute', top: '0', right: '0', display: 'flex' },
	contentWrapper: { marginTop: 0 },
	languageWarpper: {
		marginTop: '32px',
		marginRight: '25px',
	},
	recordWrapper: {
		marginTop: '10px',
		marginRight: '15px',
	},
	backgroundImage: {
		width: '100%',
	},
	backgroundImageWrapper: { position: 'absolute', top: 0, left: 0, right: 0, pointerEvents: 'none' },
	loginImage: {
		height: '100%',
	},
	loginImageWrapper: { position: 'absolute', top: 0, left: 0, bottom: 0, zIndex: -1 },
}));

export default HomeScreen;
