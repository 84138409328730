import * as React from 'react';

function UserIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={59}
			height={59}
			viewBox="0 0 59 59"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<defs>
				<circle id="usericon1" cx={29.5} cy={29.5} r={29.5} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id="usericon2" fill="#fff">
					<use xlinkHref="#usericon1" />
				</mask>
				<use fill="#FFEF99" xlinkHref="#usericon1" />
				<g mask="url(#usericon2)" stroke="#111" strokeLinecap="square" strokeWidth={2}>
					<g transform="translate(9 9)">
						<path d="M30.316 48V25.108l4.814 4.596L31 35.948a2.947 2.947 0 004.529 3.773l5.894-8.36a2.947 2.947 0 00-.229-4.02L31.93 18.5a2.948 2.948 0 00-2.035-.815H12.21c-.759 0-1.487.292-2.036.815L.912 27.341a2.947 2.947 0 00-.23 4.02l5.896 8.36a2.947 2.947 0 104.529-3.773l-4.132-6.244 4.814-4.596V48" />
						<circle cx={21.053} cy={6.737} r={6.737} />
						<path d="M21.895 25.263h4.21" />
					</g>
				</g>
			</g>
		</svg>
	);
}

export default UserIcon;
