import { InvoiceItem } from './types/Invoice';
import { Service } from './service';
import Contract from './types/Contract';
import User, { CreateEmployeeParams, UpdateEmployeeParams } from './types/User';
import { QAILoginParams, ResetPasswordParams, UserLoginParams } from '../helpers/userHelper/generalUserHelper';
import Customer from './types/Customer';
import StandardCaseInput, { StandardCaseFilterInterface } from './types/StandardCase';
import { ContactParams } from '../containers/ContactUsContainer';
import RhenusOverviewInformation from './types/RhenusOverviewInformation';
import RhenusCase from './types/RhenusCase';
import WorkingTime from './types/WorkingTime';
import MaterialTool from './types/MaterialTool';
import Travel from './types/Travel';
import { ContractStatusType, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import WorkTimeTrack from './types/WorkTimeTrack';
import { LoginResponse } from '../consts/login';
import Transport from './types/Transport';
import BreakTime from './types/BreakTime';
import { DashboardFilterInterface } from '../helpers/dashboardHelper/generalDashboardHelper';
import { WorkingTimeFilterInterface } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { Moment } from 'moment';
import { CreateGroup, UpdateGroup } from '../pages/GroupCreatePage';
import { Group } from '../pages/groupOverview/GroupOverview';

class NotImplementedService implements Service {
	public setJWTToken(JWTToken: string): void {
		throw new Error('setJWTToken is not implemented');
	}

	public async login(params: UserLoginParams): Promise<LoginResponse | undefined> {
		throw new Error('login is not implemented');
	}

	public async loginQAI(params: QAILoginParams): Promise<LoginResponse | undefined> {
		throw new Error('login is not implemented');
	}

	public async getAuthToken(refreshToken: string): Promise<string | undefined> {
		throw new Error('getAuthToken is not implemented');
	}
	public async checkToken(): Promise<boolean> {
		throw new Error('checkToken is not implemented');
	}
	public async contact(params: ContactParams): Promise<boolean> {
		throw new Error('contact is not implemented');
	}
	public async requestPasswordReset(email: string): Promise<boolean> {
		throw new Error('requestPasswordReset is not implemented');
	}

	public async checkPasswordRessetToken(token: string): Promise<boolean> {
		throw new Error('checkPasswordRessetToken is not implemented');
	}

	public async resetPassword(params: ResetPasswordParams): Promise<boolean> {
		throw new Error('resetPassword is not implemented');
	}

	public async resetPasswordQAI(params: ResetPasswordParams): Promise<boolean> {
		throw new Error('resetPassword is not implemented');
	}

	public async getAllUsers(): Promise<User[]> {
		throw new Error('readAllUsers is not implemented');
	}

	public async readGroups(): Promise<Group[]> {
		throw new Error('Unexpected error happened. please try again later.');
	}
	public async createGroup(data: CreateGroup): Promise<boolean> {
		throw new Error('Unexpected error happened. please try again later.');
	}
	public async readGroup(id: number): Promise<Group> {
		throw new Error('Unexpected error happened. please try again later.');
	}
	public async updateGroup(data: UpdateGroup): Promise<boolean> {
		throw new Error('Unexpected error happened. please try again later.');
	}
	public async deleteGroup(id: number): Promise<boolean> {
		throw new Error('Unexpected error happened. please try again later.');
	}

	public async logout(): Promise<boolean> {
		throw new Error('logout is not implemented');
	}
	public async getContracts(): Promise<Contract[]> {
		throw new Error('getContracts is not implemented');
	}
	public async createEmployee(params: CreateEmployeeParams): Promise<boolean> {
		throw new Error('createEmployee is not implemented');
	}
	public async createQAI(params: CreateEmployeeParams): Promise<boolean> {
		throw new Error('createQAI is not implemented');
	}
	public async getUserInfo(): Promise<User> {
		throw new Error('getUserInfo is not implemented');
	}

	public async readShifts(contractID: number): Promise<ShiftInterface[]> {
		throw new Error('readShifts is not implemented');
	}

	public async getAllQAIs(): Promise<User[]> {
		throw new Error('getAllQAIs is not implemented');
	}
	public async getAllTeamLeaders(): Promise<User[]> {
		throw new Error('getAllTeamLeaders is not implemented');
	}
	public async getAllQAIsByTeamLeader(teamLeaderID: number): Promise<User[]> {
		throw new Error('getAllQAIsByTeamLeader is not implemented');
	}
	public async getAllCustomers(): Promise<Customer[]> {
		throw new Error('getAllCustomers is not implemented');
	}
	public async createCustomer(customer: Customer): Promise<number> {
		throw new Error('createCustomer is not implemented');
	}
	public async createUserForCustomer(id: number, params: CreateEmployeeParams): Promise<number> {
		throw new Error('createUserForCustomer is not implemented');
	}
	public async getCustomerFromUser(id: number): Promise<Customer> {
		throw new Error('getCustomerFromUser is not implemented');
	}
	public async deleteUserByID(id: number): Promise<boolean> {
		throw new Error('deleteUsers is not implemented');
	}
	public async updateUserByID(id: number, params: UpdateEmployeeParams): Promise<boolean> {
		throw new Error('updateUserByID is not implemented');
	}
	public async updateCustomerByID(id: number, customer: Customer): Promise<boolean> {
		throw new Error('updateCustomerByID is not implemented');
	}
	public async getAllStandardCaseInputs(contractID: number): Promise<StandardCaseInput[]> {
		throw new Error('getAllStandardCaseInputs is not implemented');
	}
	public async updateStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		throw new Error('getAllStandardCaseInputs is not implemented');
	}
	public async createContract(contract: Contract): Promise<number> {
		throw new Error('createContract is not implemented');
	}
	public async createInvoice(
		contractID: number,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment
	): Promise<number> {
		throw new Error('createInvoice is not implemented');
	}

	public async updateInvoice(
		id: number,
		creationDate: Moment,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment,
		billNumber: string
	): Promise<void> {
		throw new Error('updateInvoice is not implemented');
	}

	public async deleteInvoice(id: number): Promise<void> {
		throw new Error('deleteInvoice is not implemented');
	}

	public async completeInvoice(id: number): Promise<void> {
		throw new Error('completeInvoice is not implemented');
	}

	public async getInvoiceById(invoiceId: number): Promise<InvoiceItem> {
		throw new Error('getInvoiceById is not implemented');
	}

	public async exportInvoice(id: number): Promise<string | undefined> {
		throw new Error('exportInvoice is not implemented');
	}

	public async deleteContractByID(contractID: number): Promise<boolean> {
		throw new Error('deleteContractByID is not implemented');
	}
	public async createStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		throw new Error('createStandradCaseInput is not implemented');
	}
	public async deleteStandradCaseInputByID(contractID: number, errorInputID: number): Promise<boolean> {
		throw new Error('createStandradCaseInput is not implemented');
	}
	public async getRhenusOverviewInformations(contractID: number): Promise<RhenusOverviewInformation[]> {
		throw new Error('getRhenusOverviewInformations is not implemented');
	}
	public async getZoneCaseInputs(contractID: number, zoneId: number): Promise<RhenusCase[]> {
		throw new Error('getZoneCaseInputs is not implemented');
	}
	public async deleteRhenusCaseInputByID(contractID: number, zoneId: number, errorID: number): Promise<boolean> {
		throw new Error('deleteRhenusCaseInputByID is not implemented');
	}
	public async createRhenusCaseInput(contractID: number, zoneId: number, rhenusCase: RhenusCase): Promise<boolean> {
		throw new Error('createRhenusCaseInput is not implemented');
	}
	public async updateRhenusCaseInput(contractID: number, zoneId: number, rhenusCase: RhenusCase): Promise<boolean> {
		throw new Error('updateRhenusCaseInput is not implemented');
	}
	public async sendErrorReport(message: string): Promise<boolean> {
		throw new Error('sendErrorReport is not implemented');
	}
	public async addManualWorkTime(contractID: number, worktime: WorkingTime): Promise<number> {
		throw new Error('addManualWorkTime is not implemented');
	}
	public async addManualBreakTime(contractID: number, worktime: BreakTime): Promise<number> {
		throw new Error('addManualBreakTime is not implemented');
	}
	public async startTrackingTime(contractID: number): Promise<boolean> {
		throw new Error('startTrackingTime is not implemented');
	}
	public async stopTrackingTime(
		contractID: number,
		profession: number | undefined
	): Promise<WorkingTime | undefined> {
		throw new Error('stopTrackingTime is not implemented');
	}
	public async getAllRecords(contractID: number): Promise<(WorkingTime | BreakTime)[]> {
		throw new Error('getAllRecords is not implemented');
	}
	public async deleteRecordByID(contractID: number, recordID: number): Promise<boolean> {
		throw new Error('deleteRecord is not implemented');
	}
	public async deleteBreakByID(contractID: number, recordID: number): Promise<boolean> {
		throw new Error('deleteBreakByID is not implemented');
	}
	public async deleteWaitingByID(contractID: number, recordID: number): Promise<boolean> {
		throw new Error('deleteWaitingByID is not implemented');
	}
	public async updateRecordByID(contractID: number, record: WorkingTime): Promise<boolean> {
		throw new Error('updateRecord is not implemented');
	}
	public async updateBreakByID(contractID: number, record: BreakTime): Promise<boolean> {
		throw new Error('updateBreakByID is not implemented');
	}
	public async updateWaitingByID(contractID: number, record: BreakTime): Promise<boolean> {
		throw new Error('updateWaitingByID is not implemented');
	}
	public async getMaterialExpenses(contractID: number): Promise<MaterialTool[]> {
		throw new Error('getMaterialExpenses is not implemented');
	}
	public async getToolExpenses(contractID: number): Promise<MaterialTool[]> {
		throw new Error('getToolExpenses is not implemented');
	}
	public async getTravelExpenses(contractID: number): Promise<Travel[]> {
		throw new Error('getTravelExpenses is not implemented');
	}
	public async createMaterialExpenses(contractID: number, material: MaterialTool): Promise<number> {
		throw new Error('createMaterialExpenses is not implemented');
	}
	public async createTravelExpenses(contractID: number, travel: Travel): Promise<number> {
		throw new Error('createTravelExpenses is not implemented');
	}
	public async createToolExpenses(contractID: number, tool: MaterialTool): Promise<number> {
		throw new Error('createToolExpenses is not implemented');
	}
	public async updateMaterialExpenses(contractID: number, material: MaterialTool): Promise<boolean> {
		throw new Error('updateMaterialExpenses is not implemented');
	}
	public async updateTravelExpenses(contractID: number, travel: Travel): Promise<boolean> {
		throw new Error('updateTravelExpenses is not implemented');
	}
	public async updateToolExpenses(contractID: number, tool: MaterialTool): Promise<boolean> {
		throw new Error('updateToolExpenses is not implemented');
	}
	public async deleteMaterialExpensesByID(contractID: number, materialID: number): Promise<boolean> {
		throw new Error('deleteMaterialExpensesByID is not implemented');
	}
	public async deleteTravelExpensesByID(contractID: number, travelID: number): Promise<boolean> {
		throw new Error('deleteTravelExpensesByID is not implemented');
	}
	public async deleteToolExpensesByID(contractID: number, toolID: number): Promise<boolean> {
		throw new Error('deleteToolExpensesByID is not implemented');
	}
	public async openCloseContract(contractID: number, status: ContractStatusType): Promise<boolean> {
		throw new Error('openCloseContract is not implemented');
	}
	public async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
		throw new Error('changePassword is not implemented');
	}
	public async aprroveInput(contractID: number, inputID: number, zoneID?: number): Promise<boolean> {
		throw new Error('aprroveInput is not implemented');
	}
	public async updateContract(contract: Contract): Promise<boolean> {
		throw new Error('updateContract is not implemented');
	}
	public async checkTrackingTime(): Promise<WorkTimeTrack | undefined> {
		throw new Error('checkTrackingTime is not implemented');
	}
	public async getTeamLeaderByContract(contractID: number): Promise<User | undefined> {
		throw new Error('getTeamLeadrByContract is not implemented');
	}

	public async getAllInvoices(contractID: number): Promise<any> {
		throw new Error('getAllInvoices not implemented');
	}
	public async getCustomerByContract(contractID: number): Promise<Customer | undefined> {
		throw new Error('getCustomerByContract is not implemented');
	}
	public async acceptPrivacyPolicy(): Promise<boolean> {
		throw new Error('acceptPrivacyPolicy is not implemented');
	}
	public async getContract(contractID: number): Promise<Contract | undefined> {
		throw new Error('getContract is not implemented');
	}
	public async getStandardCaseInputByID(
		contractID: number,
		errorInputID: number
	): Promise<StandardCaseInput | undefined> {
		throw new Error('getStandardCaseInputByID is not implemented');
	}
	public async getUserByID(userID: number): Promise<User | undefined> {
		throw new Error('readUserByID is not implemented');
	}
	public async getCustomerByID(customerID: number): Promise<Customer | undefined> {
		throw new Error('readCustomerByID is not implemented');
	}
	public async getTransportExpenses(contractID: number): Promise<Transport[]> {
		throw new Error('getTransportExpenses is not implemented');
	}
	public async updateTransportExpenses(contractID: number, transport: Transport): Promise<boolean> {
		throw new Error('updateTransportExpenses is not implemented');
	}
	public async deleteTransportExpensesByID(contractID: number, transportID: number): Promise<boolean> {
		throw new Error('deleteTransportExpensesByID is not implemented');
	}
	public async createTransportExpenses(contractID: number, transport: Transport): Promise<number> {
		throw new Error('createTransportExpenses is not implemented');
	}
	public async getContactQAI24ByContract(contractID: number): Promise<User | undefined> {
		throw new Error('getContactPersonByContract is not implemented');
	}

	public async getRhenusCaseInputByID(errorID: number): Promise<RhenusCase | undefined> {
		throw new Error('getContactPersonByContract is not implemented');
	}

	public async getPhoto(photoURL: string): Promise<string | undefined> {
		throw new Error('getPhoto is not implemented');
	}

	public async deleteCustomerByID(customerID: number): Promise<boolean> {
		throw new Error('deleteCustomerByID is not implemented');
	}

	public async createStandardErrorInputsExcelSheet(
		contractID: number,
		data: DashboardFilterInterface,
		language: string
	): Promise<string | undefined> {
		throw new Error('createStandardErrorInputsExcelSheet is not implemented');
	}

	public async createProcessContractStandardErrorInputsExcelSheet(
		contractID: number,
		data: StandardCaseFilterInterface,
		language: string
	): Promise<string | undefined> {
		throw new Error('createProcessContractStandardErrorInputsExcelSheet is not implemented');
	}

	public async createWorkingTimeExcelSheet(
		contractID: number,
		data: WorkingTimeFilterInterface,
		language: string
	): Promise<string | undefined> {
		throw new Error('createWorkingTimeExcelSheet is not implemented');
	}

	public async contractSummary(contractID: number): Promise<any> {
		throw new Error('contractSummary is not implemented');
	}
}

export default NotImplementedService;
