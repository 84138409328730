import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../consts/colors';
import { fonts } from '../../consts/fonts';
import { useGlobalStyles } from '../../consts/globalStyles';
import { ApprovedStatus } from '../../helpers/standardInputHelper/generalStandardInputHelper';

interface Props {
	onClick: () => void;
	buttonText: string;
	value: string;
}

function ApproveCell(props: Props) {
	const globalClasses = useGlobalStyles();
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.wrapper}>
			<span className={globalClasses.description}>{t(props.value)}</span>
			{props.value === ApprovedStatus.NotApproved ? (
				<div className={classes.buttonWrapper} onClick={props.onClick}>
					<span className={classes.buttonText}>{t(props.buttonText)}</span>
				</div>
			) : null}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	buttonText: {
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '0.4px',
		fontFamily: fonts.semiBold,
		color: colors.white,
	},
	buttonWrapper: {
		cursor: 'pointer',
		padding: '4px 19px',
		margin: '0px 20px',
		borderRadius: '4px',
		backgroundColor: colors.darkGray,
	},
}));

export default ApproveCell;
