import React from 'react';
import { makeStyles } from '@material-ui/core';
import WorkingTime from '../service/types/WorkingTime';
import ReactList from 'react-list';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import RecordItem from './RecordItem';
import RecordTitle from './RecordTitle';
import { useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BreakTime from '../service/types/BreakTime';
import BreakItem from './BreakItem';

interface Props {
	records: (WorkingTime | BreakTime)[];
	users: KeyValue[];
	timeTitle?: string;
	onSaveRecordPress: (record: WorkingTime | BreakTime) => Promise<boolean | void>;
	onDeletePress: (record: WorkingTime | BreakTime) => void;
}

function RecordListWorkingTime(props: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	const transformedRecords = React.useMemo(() => {
		const sortedRecordsByDate = props.records.sort((a, b) => b.date.unix() - a.date.unix());

		return sortedRecordsByDate.reduce((prev, curr) => {
			const key =
				curr instanceof WorkingTime ? curr.startTime.format('DD.MM.YYYY') : curr.date.format('DD.MM.YYYY');
			return {
				...prev,
				[key]: {
					...(prev[key] ? prev[key] : {}),
					[curr.qai]: [...(prev[key] && prev[key][curr.qai] ? prev[key][curr.qai] : []), curr],
				},
			};
		}, {} as { [key: string]: { [key: number]: (WorkingTime | BreakTime)[] } });
	}, [props.records]);

	const keys = React.useMemo(() => Object.keys(transformedRecords), [transformedRecords]);
	const values = React.useMemo(() => Object.values(transformedRecords), [transformedRecords]);

	return values.length === 0 ? null : (
		<div className={classes.wrapper}>
			<ReactList
				length={values.length}
				itemRenderer={(index) => (
					<div key={`RecordItem2${index}`}>
						<div className={classes.mainTitleWrapper}>
							<span className={globalClasses.bigTextBold}>
								{keys[index] === moment().format('DD.MM.YYYY')
									? t('working_time_screen_today')
									: keys[index]}
							</span>
						</div>
						{Object.values(values[index]).map((records) => (
							<div key={`RecordTitle${keys[index]}QAI${records[0].qai}`}>
								<div className={classes.subtitle}>
									<span className={globalClasses.smallText}>{t('working_time_screen_employee')}</span>
								</div>
								<RecordTitle
									records={records}
									users={props.users}
									key={`RecordTitle2${keys[index]}QAI${records[0].qai}`}
								/>
								<ReactList
									key={`RecordList2${keys[index]}QAI${records[0].qai}`}
									length={records.length}
									itemsRenderer={(items, ref) => (
										<div ref={ref} className={classes.container}>
											{items}
										</div>
									)}
									// Fix for getting the scroll parent, otherwise there is 11 items max
									// @ts-ignore
									scrollParentGetter={(props) => props.items}
									itemRenderer={(index2) =>
										records[index2] instanceof WorkingTime ? (
											<RecordItem
												key={`RecordItem${records[index2].id}`}
												index={index2}
												record={records[index2] as WorkingTime}
												users={props.users}
												onSaveRecordPress={props.onSaveRecordPress}
												onDeletePress={props.onDeletePress}
											/>
										) : (
											<BreakItem
												key={`RecordItem${records[index2].id}`}
												index={index2}
												record={records[index2] as BreakTime}
												users={props.users}
												onSaveRecordPress={props.onSaveRecordPress}
												onDeletePress={props.onDeletePress}
											/>
										)
									}
								/>
							</div>
						))}
					</div>
				)}
			/>
		</div>
	);
}
const useStyles = makeStyles(() => ({
	container: {
		maxHeight: 400,
		overflow: 'scroll',
	},
	wrapper: {
		marginTop: '28px',
	},
	mainTitleWrapper: {
		marginTop: '24px',
	},
	subtitle: {
		marginTop: '8px',
	},
}));

export default RecordListWorkingTime;
