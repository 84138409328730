import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { StandardDialogInterface } from '../components/StandardDialog';
import { latin } from '../consts/latinLetters';
import { useNotificationActionContext } from '../context/notification/useNotification';
import { goToUsers } from '../helpers/navigationHelper';
import { createUser } from '../helpers/userHelper/createUserHelper';
import { deleteUser } from '../helpers/userHelper/deleteUserHelper';

import { KeyValue, createUserRoleKeyValuePairs, externalEmployeeRoles } from '../helpers/userHelper/generalUserHelper';
import { getUserOptionsKeyValuePairs, onUserScreenMount, setUserInfo } from '../helpers/userHelper/readUserHelper';
import { updateUser } from '../helpers/userHelper/updateUserHelper';
import { RouteParams } from '../router/Routes';
import UserScreen from '../screens/UserScreen';
import service from '../service/service';
import User, { OriginOfEmployee, UserRole } from '../service/types/User';
import { useSetAtom } from 'jotai';
import { groupAtom } from '../atoms/group';

export const userFields = {
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	originOfEmployee: 'originOfEmployee',
	role: 'role',
	tlGroups: 'tlGroups',
	teamLeader: 'teamLeader',
	password: 'password',
	username: 'username',
	phonenumber: 'phonenumber',
	contactFor: 'contactFor',
};

export interface BackTo {
	backTo?: string;
}

export const MIN_PHONE_NUMBER_LENGTH = 4;
export const MIN_USER_NAME_LENGTH = 2;
export const MIN_ABBRIVIATION_LENGTH = 4;

function UserContainer() {
	const { control, handleSubmit, errors, setValue, watch, setError, clearErrors } = useForm();
	const [isUserEditing, setIsUserEditing] = React.useState(false);
	const [user, setUser] = React.useState<undefined | User>(undefined);
	const [mounted, setMounted] = React.useState(false);
	const [teamLeaders, setTeamLeaders] = React.useState<KeyValue[]>([]);
	const autoCompleteRef = React.useRef<boolean>(false);
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const history = useHistory();
	const { t } = useTranslation();
	const { setNotification } = useNotificationActionContext();
	const routeParams: RouteParams = useParams();
	const origin = watch(userFields.originOfEmployee);
	const userRole = watch(userFields.role);
	const userFirstName = watch(userFields.firstName);
	const userLastName = watch(userFields.lastName);
	const [userRoles, setUserRoles] = useState(externalEmployeeRoles);
	const { state }: { state?: BackTo } = useLocation();
	const setGroups = useSetAtom(groupAtom);

	useEffect(() => {
		onUserScreenMount(history, routeParams, setUser, setIsUserEditing, setMounted);
	}, [history, routeParams]);

	const onTeamLeaderDropdownPress = async () => {
		if (!autoCompleteRef.current && teamLeaders.length === 0) {
			autoCompleteRef.current = true;
			const resUsers = await getUserOptionsKeyValuePairs(service.getAllTeamLeaders(), () => {});
			autoCompleteRef.current = false;
			setTeamLeaders(
				resUsers.filter((teamleader) => teamleader.value.id !== user?.id && !teamleader.value.deleted)
			);
		}
	};

	useEffect(() => {
		if (!user && userRole === UserRole.QualityAssuranceInspector) {
			const firstCharOfName = userFirstName ? (userFirstName as string).charAt(0).toLowerCase() : '';
			const lastfName = userLastName ? (userLastName as string).toLowerCase() : '';
			setValue(
				userFields.username,
				(firstCharOfName + lastfName).split('').reduce((prev: string, cur: string) => {
					//@ts-ignore
					return prev + latin[cur];
				}, '')
			);
		}
	}, [user, setValue, userFirstName, userLastName, userRole]);

	useEffect(() => {
		if (user) {
			setUserInfo(user, autoCompleteRef, service, setValue, setTeamLeaders);
		}
	}, [user, setValue]);

	useEffect(() => {
		setUserRoles(origin === OriginOfEmployee.External ? externalEmployeeRoles : createUserRoleKeyValuePairs);

		if (origin === OriginOfEmployee.FromIQR && userRole === UserRole.Customer) setValue(userFields.role, '');
	}, [origin, setValue, userRole]);

	const onCreatePress = useMemo(
		() =>
			handleSubmit((data) => {
				createUser(data, history, service, setError, t, setGroups);
			}),
		[handleSubmit, history, setError, t, setGroups]
	);
	const onEditPress = () => setIsUserEditing(true);

	const onUpdatePress = useMemo(
		() =>
			handleSubmit((data) => {
				updateUser(data, service, user, history, setUser, setIsUserEditing, setGroups);
			}),
		[user, history, handleSubmit, setGroups]
	);
	const onCancelPress = () => {
		if (user) {
			setUserInfo(user, autoCompleteRef, service, setValue, setTeamLeaders);
			setIsUserEditing(false);
			clearErrors();
		} else goToUsers(history);
	};

	const onDeletePress = () => dialogRef.current?.setDialogState(true);

	const onCancelDialogPress = () => dialogRef.current?.setDialogState(false);

	const onDeleteUserDialogPress = () => {
		deleteUser(service, user, history, setNotification, setGroups);
	};

	const onSubmitPress = () => {
		if (user) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		} else {
			onCreatePress();
		}
	};

	if (!mounted) return <Spinner />;

	return (
		<UserScreen
			control={control}
			errors={errors}
			userRole={userRole}
			user={user}
			isUserEditing={isUserEditing}
			teamLeaders={teamLeaders}
			dialogRef={dialogRef}
			userRoles={userRoles}
			route={state?.backTo}
			onTeamLeaderFieldPress={onTeamLeaderDropdownPress}
			onSubmitPress={onSubmitPress}
			onCancelPress={onCancelPress}
			onDeletePress={onDeletePress}
			onCancelDialogPress={onCancelDialogPress}
			onDeleteUserDialogPress={onDeleteUserDialogPress}
		/>
	);
}

export default UserContainer;
