import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { goToLogin } from '../helpers/navigationHelper';
import ContactUsScreen from '../screens/ContactUsScreen';
import service from '../service/service';

export const contactUsFields = {
	name: 'name',
	email: 'email',
	message: 'message',
};

export interface ContactParams {
	name: string;
	email: string;
	message: string;
}

function ContactUsContainer() {
	const { control, handleSubmit, errors } = useForm();
	const history = useHistory();

	const onBackArrowPress = () => goToLogin(history);

	const onContactUsPress = (data: any) => {
		service
			.contact({
				name: data[contactUsFields.name],
				email: data[contactUsFields.email],
				message: data[contactUsFields.message],
			})
			.then(() => {})
			.catch(() => {});
	};

	return (
		<ContactUsScreen
			control={control}
			onContactUsPress={handleSubmit(onContactUsPress)}
			errors={errors}
			onBackArrowPress={onBackArrowPress}
		/>
	);
}

export default ContactUsContainer;
