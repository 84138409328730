import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import WorkingTime from '../service/types/WorkingTime';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { UserRole } from '../service/types/User';
import { useAuthStateContext } from '../context/auth/useAuth';
import BreakTime, { RecordTimeType } from '../service/types/BreakTime';
import { timeFromSeconds } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';

interface Props {
	records: (WorkingTime | BreakTime)[];
	users: KeyValue[];
}
function RecordTitle(props: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const { user } = useAuthStateContext();

	const sumTime = React.useMemo(() => {
		return props.records.reduce((prev, curr) => {
			if (curr instanceof WorkingTime) {
				return prev + curr.duration;
			}
			if (curr.type === RecordTimeType.Break) {
				return prev - curr.duration;
			}
			return prev;
		}, 0);
	}, [props.records]);

	const qai = React.useMemo(() => {
		return props.users.find((user) => user.value.id === props.records[0].qai);
	}, [props.users, props.records]);

	return (
		<div className={classes.row}>
			<div className={classes.itemWrapper}>
				<span className={globalClasses.mediumTextSemiDark}>{`${qai?.key}`}</span>
			</div>
			<div className={classes.itemWrapper}>
				<div className={classes.translateWrapper}>
					<span className={globalClasses.mediumTextSemiDark}>{t('working_time_screen_reporter')}</span>
				</div>
			</div>
			<div className={classes.itemWrapper}>
				<span className={globalClasses.mediumTextSemiDark}>{t('working_time_screen_employee')}</span>
			</div>
			<div className={classes.itemWrapper}>
				<span className={globalClasses.mediumTextSemiDark}>{t('working_time_screen_occupation')}</span>
			</div>
			{user?.role !== UserRole.QualityAssuranceInspector ? (
				<div className={classes.itemWrapper} style={{ transform: 'translateX(-8px)' }}>
					<span className={globalClasses.mediumTextSemiDark}>{t('working_time_screen_status')}</span>
				</div>
			) : null}
			<div className={classes.itemWrapperEnd}>
				<div className={classes.timeTitleWrapper}>
					<span className={globalClasses.smallerTextLight}>{`${t('working_time_screen_total')}: `}</span>
				</div>
				<div>
					<span className={globalClasses.mediumTextSemiDark}>{timeFromSeconds(+sumTime)}</span>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		marginTop: '6px',
	},
	itemWrapperEnd: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingRight: '0px',
	},
	itemWrapper: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},

	timeTitleWrapper: {
		display: 'flex',
		marginRight: '12px',
		alignItems: 'center',
	},
	translateWrapper: {
		transform: 'translateX(8px)',
	},
}));

export default RecordTitle;
