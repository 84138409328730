import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { TFunction } from 'i18next';

import StandardTableCell, { TableCellType } from './StandardTableCell';
import { useGlobalStyles } from '../../consts/globalStyles';
import { ContractSummaryInterface } from '../../service/types/Contract';

interface Props {
	contractSummary: ContractSummaryInterface;
	t: TFunction;
}

export default function SimpleDataTable({ contractSummary, t }: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();

	const DATA = [
		{
			column: t('simple_data_table_contract_employees'),
			value: contractSummary.employeeTotalAmount,
		},
		{
			column: t('simple_data_table_billable_hours'),
			value: parseFloat(contractSummary.totalBillableHours.toFixed(2)),
		},
		{
			column: t('simple_data_table_billed_hours'),
			value: parseFloat(contractSummary.billedHours.toFixed(2)),
		},
		{
			column: t('simple_data_table_not_billed_hours'),
			value: parseFloat(contractSummary.notBilledHours.toFixed(2)),
		},
	];

	return (
		<TableContainer className={classes.tableContainer}>
			<Table className={classes.fixed}>
				<TableHead>
					<TableRow>
						{DATA.map((el, index) => (
							<StandardTableCell
								key={`${el.column}-${index}`}
								className={globalClasses.tableTitleWrapper}
								value={el.column}
								restProps={{
									type: TableCellType.Title,
								}}
								style={{
									width: 'auto',
								}}
							/>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						{DATA.map((el, index) => (
							<StandardTableCell
								className={globalClasses.tableTitle}
								value={el.value}
								restProps={{
									type: TableCellType.Text,
								}}
								style={{
									width: 'auto',
								}}
								key={`${el.value.toString()}-${index}`}
							/>
						))}
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const useStyles = makeStyles(() => ({
	tableContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '70%',
		marginBottom: '50px',
	},
	fixed: {
		tableLayout: 'fixed',
		borderCollapse: 'separate',
	},
}));
