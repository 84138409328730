import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { useAuthActionContext } from '../context/auth/useAuth';
import { onAuthTokenError } from '../helpers/authHelper';
import service from '../service/service';
import CheckboxFilled from '../svgComponents/CheckboxFilled';
import CheckboxEmpty from '../svgComponents/CheckboxEmpty';
import CloseIcon from '../svgComponents/CloseIcon';
import Transition from './Transition';

export interface StandardDialogInterface {
	setDialogState: (val: boolean) => void;
}

interface Props {}

function LegalNoticeDialog(props: Props, ref: any) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const { setUser } = useAuthActionContext();
	const [isOpen, setIsOpen] = React.useState(false);
	const [warning, setWarning] = React.useState(false);
	const [accepted, setAccepted] = React.useState(false);

	const onCancle = () => {
		onAuthTokenError(setUser);
		setIsOpen(false);
	};

	const onAccept = () => {
		if (accepted)
			service
				.acceptPrivacyPolicy()
				.then(() => {
					setIsOpen(false);
				})
				.catch(() => {});
		else {
			setWarning(true);
		}
	};

	const onCheckboxPress = () =>
		setAccepted((curr) => {
			setWarning(!curr);
			return !curr;
		});

	React.useImperativeHandle(
		ref,
		(): StandardDialogInterface => ({
			setDialogState: (val: boolean) => {
				setIsOpen(val);
			},
		})
	);

	return (
		<Dialog
			className={classes.wrapper}
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={onCancle}>
			<div className={classes.container}>
				<div>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.dialogTitle}>{t('legal_notice_dialog_title')}</span>
					</div>
					<div className={classes.descriptionWrapper}>
						{accepted ? (
							<CheckboxFilled className={classes.margin} onClick={onCheckboxPress} />
						) : (
							<CheckboxEmpty
								color={warning ? colors.error : '#111'}
								className={classes.margin}
								onClick={onCheckboxPress}
							/>
						)}

						<span className={globalClasses.dialogDescription}>{t('legal_notice_dialog_description')}</span>
						<span className={globalClasses.dialogDescriptionLink}>
							{t('legal_notice_dialog_privacy_policy')}
						</span>
					</div>
					<div className={classes.row}>
						<div className={classes.buttonWrapper} onClick={onAccept}>
							<span className={globalClasses.dialogTitle}>{t('legal_notice_dialog_accept')}</span>
						</div>

						<div className={classes.buttonWrapper} onClick={onCancle}>
							<span className={globalClasses.dialogTitle}>{t('legal_notice_dialog_cancel')}</span>
						</div>
					</div>
				</div>
				<div className={classes.closeIconWarpper} onClick={onCancle}>
					<CloseIcon color={colors.darkGray} />
				</div>
			</div>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: { maxWidth: '40vw', marginLeft: 'auto', marginRight: 'auto' },
	container: { display: 'flex', padding: '16px 16px 16px 20px', borderRadius: '4px', flex: 1 },
	row: { display: 'flex' },
	titleWrapper: {
		marginBottom: '8px',
		transform: 'translateY(-2px)',
	},
	descriptionWrapper: {
		marginBottom: '20px',
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'pre',
	},
	buttonWrapper: {
		marginRight: '24px',
		cursor: 'pointer',
	},
	closeIconWarpper: {
		marginLeft: '12px',
		cursor: 'pointer',
		height: 'fit-content',
	},
	margin: {
		marginRight: '12px',
		cursor: 'pointer',
	},
}));

export default React.forwardRef(LegalNoticeDialog);
