import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { colors } from '../consts/colors';
import { SHIFT_FROM_WIDTH, SHIFT_NAME_WIDTH } from '../screens/ContractScreen';

import TableTextField from './TableTextField';
import { IContractWorkLocation } from '../service/types/Contract';
import { Controller, useForm } from 'react-hook-form';
import { WorkLocationFormValues } from '../containers/ContractContainer';
import { rules } from '../consts/rules';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../svgComponents/DeleteIcon';
import SaveIcon from '../svgComponents/SaveIcon';
import EditIcon from '../svgComponents/EditIcon';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { useGlobalStyles } from '../consts/globalStyles';

interface Props {
	isUserEditing: boolean;
	workLocation: IContractWorkLocation;
	index: number;
	setWorkLocations: React.Dispatch<React.SetStateAction<IContractWorkLocation[]>>;
}

function WorkLocationInputRow({ isUserEditing, workLocation, index, setWorkLocations }: Props) {
	const classes = useStyles({});

	const { control, errors, handleSubmit, reset } = useForm<WorkLocationFormValues>();

	const globalClasses = useGlobalStyles();

	const { t } = useTranslation();

	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		if (!isUserEditing) {
			reset({
				distance: workLocation.distance.toString(),
				name: workLocation.name,
			});
		}
	}, [isUserEditing, reset, workLocation]);

	const onSavePress = useMemo(() => {
		return handleSubmit((data) => {
			setWorkLocations((prev) => {
				const old = [...prev];

				const toUpdate = { ...old[index] };

				toUpdate.distance = parseFloat(data.distance);
				toUpdate.name = data.name;

				old[index] = toUpdate;

				return old;
			});

			setEditMode(false);
		});
	}, [handleSubmit, index, setWorkLocations]);

	const onDelete = () => {
		setWorkLocations((prev) => prev.filter((val, ind) => ind !== index));
	};

	const onEdit = () => {
		setEditMode(true);
	};

	return (
		<div
			className={classes.row123}
			style={{
				backgroundColor: editMode ? colors.white : colors.keyButton,
			}}>
			<div className={classes.shiftNameWrapper}>
				<Controller
					defaultValue={workLocation.name}
					control={control}
					disabled={!isUserEditing}
					name={'name'}
					rules={{ required: rules(t).required }}
					render={({ onChange, value }) => (
						<TableTextField
							value={value}
							onChange={onChange}
							placeholder={t('location')}
							customClassName={!editMode ? classes.inputDisabled : undefined}
							disabled={!isUserEditing || !editMode}></TableTextField>
					)}
				/>
			</div>
			<div className={classes.shiftNameWrapper}>
				<Controller
					defaultValue={workLocation.distance}
					control={control}
					disabled={!isUserEditing}
					name={'distance'}
					rules={{ pattern: rules(t).numberPatternAny, required: rules(t).required }}
					render={({ onChange, value }) => (
						<TableTextField
							value={value}
							onChange={onChange}
							placeholder={t('distance')}
							customClassName={!editMode ? classes.inputDisabled : undefined}
							disabled={!isUserEditing || !editMode}></TableTextField>
					)}
				/>
			</div>
			<div className={classes.checkBoxWrapper}>
				{!editMode ? (
					<div>
						<IconButton color="primary" onClick={onEdit} disabled={!isUserEditing}>
							<EditIcon />
						</IconButton>
						<IconButton color="primary" onClick={onDelete} disabled={!isUserEditing}>
							<DeleteIcon />
						</IconButton>
					</div>
				) : (
					<div>
						<IconButton color="primary" onClick={onSavePress} disabled={!isUserEditing}>
							<SaveIcon />
						</IconButton>
					</div>
				)}
			</div>

			{Object.keys(errors).length > 0 ? (
				<div className={classes.errorRow}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error}>{t('invalid_input')}</span>
				</div>
			) : null}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	shiftNameWrapper: {
		width: SHIFT_NAME_WIDTH,
		display: 'flex',
		color: colors.gray,
		padding: '0px 12px',
	},
	fromWrapper: {
		width: SHIFT_FROM_WIDTH,
		display: 'flex',
		alignSelf: 'flex-start',
		paddingLeft: '26px',
	},
	checkBoxWrapper: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-end',
	},
	shiftWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	row123: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #C7C7C7',
		padding: '2px 12px',
	},
	errorImageWrapper: { marginLeft: '5px', height: '16px', display: 'flex', alignItems: 'center', marginRight: '5px' },

	inputDisabled: {
		backgroundColor: colors.keyButton,
	},
	errorRow: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default WorkLocationInputRow;
