import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import service from '../service/service';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import { getDataForForgotPassword } from '../consts/login';
import { loginFields, LoginType } from './LoginContainer';
import MailSentScreen from '../screens/MailSentScreen';
import { Routes } from '../router/Routes';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ForgotPasswordNavigationInterface } from '../helpers/navigationHelper';
import { setLoginError } from '../helpers/loginHelper';

export enum ForgotPasswordState {
	EmailNotSent,
	EmailSent,
	EmailResent,
}

function ForgotPasswordContainer() {
	const { control, handleSubmit, errors, setError } = useForm();
	const history = useHistory();
	const [forgotPasswordState, setForgotPasswordState] = React.useState(ForgotPasswordState.EmailNotSent);
	const emailRef = React.useRef('');
	const { user } = useAuthStateContext();

	React.useEffect(() => {
		if (user) {
			history.replace(Routes.Home);
		}
	}, [user, history]);

	const dataForForgotPassword = React.useMemo(() => {
		const historyState = history.location.state as undefined | ForgotPasswordNavigationInterface;
		if (historyState && historyState.from !== undefined && historyState.from === LoginType.QALogin) {
			return getDataForForgotPassword(LoginType.QALogin, forgotPasswordState);
		} else {
			return getDataForForgotPassword(LoginType.StandardLogin, forgotPasswordState);
		}
	}, [forgotPasswordState, history.location.state]);

	const onLinkPress = () => {
		if (forgotPasswordState === ForgotPasswordState.EmailSent) {
			service
				.requestPasswordReset(emailRef.current)
				.then((res) => {
					if (res) {
						setForgotPasswordState(ForgotPasswordState.EmailResent);
					}
				})
				.catch();
		} else {
			history.push(dataForForgotPassword.route);
		}
	};

	const onSearchPress = (data: any) => {
		service
			.requestPasswordReset(data[loginFields.email])
			.then((res) => {
				if (res) {
					emailRef.current = data[loginFields.email];
					setForgotPasswordState(ForgotPasswordState.EmailSent);
				}
			})
			.catch((e) => {
				const error = JSON.parse(e.message);
				setLoginError(error, setError);
			});
	};

	if (forgotPasswordState !== ForgotPasswordState.EmailNotSent) {
		return <MailSentScreen dataForForgotPassword={dataForForgotPassword} onLinkPress={onLinkPress} />;
	}

	return (
		<ForgotPasswordScreen
			onSearchPress={handleSubmit(onSearchPress)}
			control={control}
			errors={errors}
			dataForForgotPassword={dataForForgotPassword}
		/>
	);
}

export default ForgotPasswordContainer;
