import { History, LocationState } from 'history';
import { contractFields, IHourlyRateField, ShiftsFormValues } from '../../containers/ContractContainer';
import Contract, { IContractWorkLocation } from '../../service/types/Contract';
import { ShiftInterface } from './generalContractHelper';
import { KeyValue } from '../userHelper/generalUserHelper';
import moment from 'moment';
import service from '../../service/service';
import User from '../../service/types/User';
import { IPriceListItem } from '../../consts/IPriceListItem';
import { priceListOptions } from '../../consts/priceListOptions';
import { GroupUser } from '../../pages/groupOverview/GroupOverview';

export function updateContract(
	contract: Contract,
	data: any,
	shifts: ShiftsFormValues,
	checkedShifts: string[],
	customers: KeyValue[],
	teamLeaders: KeyValue[],
	qais: KeyValue[],
	history: History<LocationState>,
	setIsUserEditing: (val: boolean) => void,
	setContract: (contract: Contract) => void,
	workLocations: IContractWorkLocation[],
	hourlyRateFields?: IHourlyRateField[]
) {
	let shiftsData: ShiftInterface[] = [];

	let hasError = false;

	checkedShifts.forEach((checkedShift) => {
		const { from, to } = shifts[checkedShift as keyof ShiftsFormValues];

		if (from && to) {
			shiftsData.push({
				from,
				to,
				name: checkedShift,
			});
		} else {
			hasError = true;
		}
	});

	if (!hasError) {
		const updatedContract = createNewContractWithID(
			contract,
			data,
			customers,
			teamLeaders,
			qais,
			shiftsData,
			workLocations,
			hourlyRateFields
		);
		service
			.updateContract(updatedContract)
			.then((res) => {
				if (res) {
					setIsUserEditing(false);
					setContract(updatedContract);
				}
			})
			.catch(() => {});
	}
}

function createNewContractWithID(
	contract: Contract,
	data: any,
	customers: KeyValue[],
	teamLeaders: KeyValue[],
	qais: KeyValue[],
	shifts: ShiftInterface[],
	workLocations: IContractWorkLocation[],
	hourlyRateFields?: IHourlyRateField[]
) {
	const customer = customers.find((item) => item.key === data[contractFields.customer]);

	let priceListArray: IPriceListItem[] = [];
	if (data[contractFields.priceListType]) {
		priceListArray = (data[contractFields.priceListType] as string[]).map((type) => {
			const hourlyRate = hourlyRateFields?.find((item) => item.selectedValue === type);
			const intType = priceListOptions.find((opt) => opt.label === type);

			return {
				type: intType ? intType.value : 0,
				hourly_rate: hourlyRate ? parseInt(hourlyRate.value) : undefined,
			};
		});
	}

	const contactPerson: number[] = data[contractFields.contactPerson].map(
		(person: string) =>
			customer?.value.users.find((user: User) => `${user.firstName} ${user.lastName}` === person).id
	);

	return new Contract(
		contract.id,
		data[contractFields.contractNumber],
		customer?.value.id,
		moment(data[contractFields.date]),
		workLocations,
		data[contractFields.typeOfActivity],
		data[contractFields.partDesignation],
		data[contractFields.markingOfIOparts],
		shifts.map((shift) => ({
			...shift,
			name: shift.name.replaceAll('"', ''),

			id: contract.shiftTimes.find((prevShift) => prevShift.name === shift.name.replaceAll('"', ''))
				? contract.shiftTimes.find((prevShift) => prevShift.name === shift.name.replaceAll('"', ''))!.id
				: 0,
		})),
		data[contractFields.errorTypes],
		data[contractFields.typesOfInspection],
		data[contractFields.contractType],
		contract.status,
		data[contractFields.takingPhotos],
		data[contractFields.automatedUpdates],
		data[contractFields.assignQAI].map((qai: string) => qais.find((item) => item.key === qai)?.value.id),
		teamLeaders.find((item) => item.key === data[contractFields.assignTeamLeader])?.value.id,
		data[contractFields.orderDescription],
		contactPerson.map((el) => {
			return { id: el };
		}),
		qais.find((item) => item.key === data[contractFields.contactQAI24])?.value.id,
		typeof data[contractFields.billingCycle] === 'number' ? data[contractFields.billingCycle] : undefined,
		data[contractFields.drivingRate] !== '' ? parseInt(data[contractFields.drivingRate]) : undefined,
		data[contractFields.driving_time] !== ''
			? parseFloat(data[contractFields.driving_time].toString().replace(/,/g, '.'))
			: undefined,
		data[contractFields.kilometer] !== '' ? parseInt(data[contractFields.kilometer]) : undefined,
		priceListArray,
		data[contractFields.projectType] !== '' ? data[contractFields.projectType] : undefined,
		data[contractFields.calculationType] !== '' ? data[contractFields.calculationType] : undefined,
		data[contractFields.administrationFee] !== '' ? parseInt(data[contractFields.administrationFee]) : undefined,

		contract.zones
			? data[contractFields.zones].map((name: string) => ({
					id: contract.zones?.find((zone) => zone.name === name)
						? contract.zones?.find((zone) => zone.name === name)!.id
						: 0,
					name,
			  }))
			: [],
		data[contractFields.pdfContract],
		data[contractFields.attachments],
		data[contractFields.amountOfParts],
		data[contractFields.applicationIntake] !== '' ? parseInt(data[contractFields.applicationIntake]) : undefined,
		data[contractFields.tlGroups],
		data[contractFields.tlGroups]?.map((el: GroupUser) => el.id) ?? []
	);
}
