import { Service } from '../../service/service';
import User from '../../service/types/User';
import { History, LocationState } from 'history';
import moment from 'moment';
import RhenusCase from '../../service/types/RhenusCase';
import { rhenusCaseInputFields } from '../../containers/RhenusCaseContainer';

export function createRhenusCaseInput(
	data: any,
	history: History<LocationState>,
	contractIDRef: React.MutableRefObject<number>,
	service: Service,
	user: User,
	automatedUpdates: boolean,
	zone: number,
	shift: number
) {
	const rhenusCase = getRhenusCaseInputWithoutID(data, user, automatedUpdates, zone, shift);
	service
		.createRhenusCaseInput(contractIDRef.current, zone, rhenusCase)
		.then((res) => {
			if (res) {
				history.goBack();
			}
		})
		.catch(() => {});
}

function getRhenusCaseInputWithoutID(data: any, user: User, automatedUpdates: boolean, zone: number, shift: number) {
	return new RhenusCase(
		0,
		moment(data[rhenusCaseInputFields.date]),
		shift,
		Number(data[rhenusCaseInputFields.productionNumber]),
		data[rhenusCaseInputFields.partNumber],
		zone,
		data[rhenusCaseInputFields.storage],
		data[rhenusCaseInputFields.partStatus],
		user.id,
		automatedUpdates ? true : data[rhenusCaseInputFields.approved],
		data[rhenusCaseInputFields.typeOfInspection],
		data[rhenusCaseInputFields.errorType],
		data[rhenusCaseInputFields.partNumberShould],
		data[rhenusCaseInputFields.colorNumber],
		data[rhenusCaseInputFields.colorNumberShould],
		data[rhenusCaseInputFields.amountOfParts],
		data[rhenusCaseInputFields.comment],
		data[rhenusCaseInputFields.photo]
			? {
					...data[rhenusCaseInputFields.photo],
					data: data[rhenusCaseInputFields.photo].data.split(',')[1],
			  }
			: undefined
	);
}
