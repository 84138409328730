import { Service } from '../../service/service';
import StandardCaseInput from '../../service/types/StandardCase';
import { History, LocationState } from 'history';
import { NotificationInterface } from '../../context/notification/notificationReducer';

export function deleteStandardInput(
	service: Service,
	standardInputCase: StandardCaseInput | undefined,
	contractID: number,
	history: History<LocationState>,
	setNotification: (notification: NotificationInterface) => void
) {
	if (standardInputCase)
		service
			.deleteStandradCaseInputByID(contractID, standardInputCase.id)
			.then((res) => {
				if (res) {
					history.goBack();
					setNotification({
						text: 'standard_case_screen_delete_notification',
						additional: standardInputCase.partNumber,
					});
				}
			})
			.catch(() => {});
}
