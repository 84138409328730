import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { invoiceTableParams } from '../consts/invoice';
import { Routes } from '../router/Routes';
import Invoice from '../service/types/Invoice';
import CustomTableBody from './CustomTableBody';

interface TransformedInvoice {
	creation_date: string;
	date_of_invoice: string;
	from_date: string;
	id: number;
	to_date: string;
}
interface TransformedInvoices {
	[key: number]: TransformedInvoice[];
}

interface Props {
	invoices: Invoice[];
}

const InvoiceListSummary = ({ invoices }: Props) => {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();

	const { t } = useTranslation();

	const history = useHistory();

	const transformedInvoices: TransformedInvoices = React.useMemo(() => {
		const dateOfInvoiceArr = invoices.map((inv) => moment(inv.date_of_invoice));

		let transformedInvoices: TransformedInvoices = {};

		const maxYear = moment.max(dateOfInvoiceArr).year();
		const minYear = moment.min(dateOfInvoiceArr).year();

		for (let i = minYear; i <= maxYear; i++) {
			const invoicesArr: TransformedInvoice[] = invoices
				.filter((invoice) => moment(invoice.date_of_invoice).year() === i)
				.map((invoice) => ({
					creation_date: moment(invoice.creation_date).format('DD.MM.YYYY'),
					date_of_invoice: moment(invoice.date_of_invoice).format('DD.MM.YYYY'),
					from_date: moment(invoice.from_date).format('DD.MM.YYYY'),
					id: invoice.id,
					to_date: moment(invoice.to_date).format('DD.MM.YYYY'),
				}));

			if (invoicesArr.length > 0) {
				transformedInvoices[i] = invoicesArr;
			}
		}

		return transformedInvoices;
	}, [invoices]);

	const onEditPress = (id: number) => {
		history.push(`${Routes.Invoice}/${id.toString()}`);
	};

	return (
		<div>
			{Object.keys(transformedInvoices).map((year) => (
				<div className={classes.section}>
					<div className={`${classes.yearText} ${globalClasses.label}`}>{t('year').toUpperCase()}</div>
					<div className={globalClasses.mediumTextSemiDark}>{year}</div>
					<CustomTableBody
						margin={classes.tableMargin}
						buttonText={t('invoice_screen_edit')}
						onClickItemId={onEditPress}
						//@ts-ignore
						data={transformedInvoices[parseInt(year)]}
						dataTablePramas={invoiceTableParams}
						tableKey={'Invoices'}
					/>
				</div>
			))}
		</div>
	);
};

const useStyles = makeStyles(() => ({
	titleWrapper: {
		// marginBottom: '12px',
	},
	yearText: {
		color: colors.gray,
	},
	section: {
		marginBottom: '32px',
	},
	tableMargin: {
		margin: '0px',
	},
}));

export default InvoiceListSummary;
