import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import { useAuthStateContext } from '../context/auth/useAuth';
import Button from './Button';
import { colors } from '../consts/colors';
import { useWorkingTimeActionContext, useWorkingTimeStateContext } from '../context/workingTime/useWorkingTime';
import { timeFromUnixToStringFormat, updateWorkTime } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import moment from 'moment';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import { useTranslation } from 'react-i18next';
import StandardDialog, { StandardDialogInterface } from './StandardDialog';
import WorkingTime from '../service/types/WorkingTime';
import { Controller, useForm } from 'react-hook-form';
import { priceListOptions } from '../consts/priceListOptions';
import { rules } from '../consts/rules';
import KeyValueAutoComplete from './KeyValueAutoComplete';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';

interface Props {
	contractNumber: string;
	contractID: number;
	onStopButtonPressWhenCIDAndRID: (record: WorkingTime) => void;
}

export interface TrackWorkingTimeFormValues {
	profession: any;
}

function TrackWorkingTime(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const [workingTime, setWorkingTime] = React.useState('');
	const [buttonText, setButtonText] = React.useState('');
	const { user } = useAuthStateContext();
	const { record } = useWorkingTimeStateContext();
	const { startTracking, stopTracking } = useWorkingTimeActionContext();
	const timer = React.useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));
	const { t } = useTranslation();
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const { control, errors, handleSubmit } = useForm<TrackWorkingTimeFormValues>();

	React.useEffect(() => {
		if (record && record.contractID === props.contractID) {
			setButtonText('working_time_screen_stop_button');
			timer.current = updateWorkTime(moment().diff(record.startTime), timer, setWorkingTime);
		} else {
			setButtonText('working_time_screen_start_button');
			setWorkingTime(timeFromUnixToStringFormat(0));
		}
		return () => {
			clearTimeout(timer.current);
		};
	}, [record, props.contractID]);

	const onButtonPress = async (data: TrackWorkingTimeFormValues) => {
		if (record && record.contractID === props.contractID) {
			const recordToSave: WorkTimeTrack = { ...record, profession: (data.profession as KeyValue).value };
			const res = await stopTracking(recordToSave);
			if (res) {
				props.onStopButtonPressWhenCIDAndRID(res);
			}
		} else if (record) {
			dialogRef.current?.setDialogState(true);
		} else {
			startTracking(new WorkTimeTrack(props.contractID, moment(), props.contractNumber));
		}
	};

	const startCurrentStopPreviousRecord = async () => {
		if (record) {
			const res = await stopTracking(record).catch(() => {});
			if (res) {
				//change 0 to profession form value
				startTracking(new WorkTimeTrack(props.contractID, moment(), props.contractNumber));
				dialogRef.current?.setDialogState(false);
			}
		}
	};

	const closeDialog = () => {
		dialogRef.current?.setDialogState(false);
	};

	return (
		<div className={classes.trackContainer}>
			<div className={classes.trackItemWrapper} style={{ flex: 0.6 }}>
				<span className={globalClasses.mediumTextSemiDark}>{t('working_time_screen_track_time_title')}</span>
			</div>
			<div className={classes.trackItemWrapper}>
				<span className={globalClasses.description}>{`${user?.firstName} ${user?.lastName}`}</span>
			</div>
			{record ? (
				<div className={classes.trackItemWrapper}>
					<Controller
						defaultValue={''}
						control={control}
						name={'profession'}
						rules={{
							required: rules(t).required,
						}}
						render={({ onChange, value }) => (
							<KeyValueAutoComplete
								label=""
								value={value}
								inputWrapper={classes.inputAddWrapper}
								placeholder={t('working_time_screen_select_profession')}
								onChange={onChange}
								wrapper={classes.wrapper}
								options={priceListOptions.map((option) => ({
									key: option.label,
									value: option.value,
								}))}
								customGetOptionLabel={(option) => t(option.key)}
								withoutArrow={true}
								withoutClose={true}
								error={errors.profession ? true : false}
								helperText={errors.profession ? errors.profession.message : ''}
							/>
						)}
					/>
				</div>
			) : null}

			<div className={classes.trackItemWrapperEnd}>
				<div className={classes.timeWrapper}>
					<span className={globalClasses.description}>{workingTime}</span>
				</div>
				<Button
					text={t(buttonText)}
					textClassName={globalClasses.buttonText}
					buttonWrapperClassName={classes.buttonWrapper}
					onClick={handleSubmit(onButtonPress)}
				/>
			</div>
			<StandardDialog
				acceptText={'working_time_screen_warning_dialog_accept_button'}
				description={'working_time_screen_warning_dialog_description'}
				title={'working_time_screen_warning_dialog_title'}
				cancleText={'working_time_screen_warning_dialog_cancel_button'}
				onCancle={closeDialog}
				onAccept={startCurrentStopPreviousRecord}
				ref={dialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	trackItemWrapper: {
		flex: 1,
		display: 'flex',
	},

	trackItemWrapperEnd: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	timeWrapper: {
		marginRight: '24px',
	},
	trackContainer: {
		padding: '20px 16px',
		display: 'flex',
		marginBottom: '16px',
		alignItems: 'center',
		border: `1px solid ${colors.timetrackBorderd}`,
	},
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '8px 22px',
		cursor: 'pointer',
	},
	clockWrapper: {
		marginLeft: '24px',
	},

	inputAddWrapper: {
		width: '80%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
	},
	wrapper: {
		width: '100%',
	},
}));

export default TrackWorkingTime;
