import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';

import { fonts } from '../../consts/fonts';
import { colors } from '../../consts/colors';
import { TableCellWithCss } from './StandardTableCell';
import DropdownIcon from '../../svgComponents/DropdownIcon';
import { useGlobalStyles } from '../../consts/globalStyles';
import { KeyValue } from '../../helpers/userHelper/generalUserHelper';
import { Sort } from '../ContractList';
import { SortingDir } from '../../helpers/dataSorter';
import { ArrowSort } from './TitleTypeCell';

interface Props {
	value: string;
	restProps?: {
		filter: any;
		filterKey: string;
		filterOptions: KeyValue[];
		onFilterChange: (key: string, value: any) => void;
	};
	[key: string]: any;
	sort?: Sort;
	setSort?: React.Dispatch<React.SetStateAction<Sort>>;
}

function WithDropdownCellType(props: Props) {
	const [isOpen, setIsOpen] = React.useState(false);
	const anchorRef = React.useRef<any>(null);

	const globalClasses = useGlobalStyles({ isTableTitleOpen: isOpen });
	const classes = useStyles({ isOpen: isOpen });
	const { t } = useTranslation();

	const onClick = () => setIsOpen((prev) => !prev);

	const handlePress = () => {
		props.setSort &&
			props.setSort((prev) => {
				if (prev.field !== props.restProps?.filterKey) {
					return {
						field: props.restProps?.filterKey,
						direction: SortingDir.ASC,
					};
				} else {
					if (prev.direction === SortingDir.ASC)
						return {
							field: props.restProps?.filterKey,
							direction: SortingDir.DCS,
						};

					return {
						field: undefined,
						direction: undefined,
					};
				}
			});
	};

	return (
		<TableCellWithCss
			key={props.key}
			style={props.style}
			className={globalClasses.tableTitleWrapper}
			ref={anchorRef}>
			<div className={classes.contentWrapper}>
				<div className={classes.wrapper}>
					<span className={globalClasses.tableTitle}>{t(props.value)}</span>
					<button className={`${classes.button} ${globalClasses.tableTitle}`} onClick={onClick}>
						<DropdownIcon className={classes.rotate} width={12} height={12} />
					</button>
					{props.restProps?.filterKey && props.sort && props.setSort && (
						<button className={`${classes.button} ${globalClasses.tableTitle}`} onClick={handlePress}>
							<ArrowSort field={props.restProps?.filterKey} sort={props.sort} />
						</button>
					)}
				</div>
				<Menu
					PaperProps={{
						classes: { rounded: classes.dropdownContainer },
						style: { width: anchorRef.current ? anchorRef.current.offsetWidth - 2 : 'auto' },
					}}
					MenuListProps={{ className: classes.menuList }}
					anchorEl={anchorRef.current}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					style={{
						transform: 'translateY(1px)',
					}}
					open={isOpen}
					onBackdropClick={onClick}>
					{props.restProps!.filterOptions.map((item) => (
						<MenuItem
							key={item.key}
							value={item.value}
							selected={props.filter === item.value}
							onClick={() => {
								props.restProps?.onFilterChange(props.restProps?.filterKey, item.value);
								onClick();
							}}>
							<div className={classes.inputWrapper}>
								<span className={classes.input}>{t(item.key)}</span>
							</div>
						</MenuItem>
					))}
				</Menu>
			</div>
		</TableCellWithCss>
	);
}

interface StyleProps {
	isOpen: boolean;
}

const useStyles = makeStyles(() => ({
	button: {
		border: 'none',
		backgroundColor: 'transparent',
		cursor: 'pointer',
	},
	contentWrapper: {
		padding: '0px 16px 0px 0px',
	},
	rotate: (p: StyleProps) => ({
		transform: p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
		transition: 'all 0.5s',
		marginLeft: '5px',
	}),
	wrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	input: {
		margin: 0,
		color: colors.gray,
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
		whiteSpace: 'pre-wrap',
	},
	inputWrapper: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
	},
	dropdownContainer: {
		borderRadius: '0px',
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
		borderTopWidth: '0px',
	},
	menuList: {
		padding: '0',
	},
}));

export default WithDropdownCellType;
