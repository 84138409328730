import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ContractZone } from '../helpers/contractHelper/generalContractHelper';
import { goToZoneOverview } from '../helpers/navigationHelper';

import RhenusOverviewScreen from '../screens/RhenusOverviewScreen';
import service from '../service/service';
import Contract from '../service/types/Contract';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import { UserRole } from '../service/types/User';
import moment from 'moment';
import { useForm } from 'react-hook-form';

interface Props {
	contractData: Contract;
}

export const rhenusOverviewFields = {
	fromDate: 'fromDate',
	toDate: 'toDate',
};

function RhenusOverviewContainer(props: Props) {
	const [informations, setInformations] = React.useState<RhenusOverviewInformation[] | ContractZone[]>([]);
	const searchRef = React.useRef('');
	const allZonesRef = React.useRef<RhenusOverviewInformation[] | ContractZone[]>([]);
	const history = useHistory();
	const { user } = useAuthStateContext();
	const fetchnig = React.useRef(false);
	const { control, watch } = useForm();
	const fromDate = watch(rhenusOverviewFields.fromDate);
	const toDate = watch(rhenusOverviewFields.toDate);

	React.useEffect(() => {
		if (!fetchnig.current) {
			if (
				user?.role === UserRole.Administrator ||
				user?.role === UserRole.TeamLeader ||
				user?.role === UserRole.Accounting
			) {
				fetchnig.current = true;
				service
					.getRhenusOverviewInformations(props.contractData.id, moment().subtract(8, 'h'), moment())
					.then((informations) => {
						setInformations(informations);
						allZonesRef.current = informations;
						fetchnig.current = false;
					})
					.catch((e) => {
						fetchnig.current = false;
					});
			} else {
				setInformations(props.contractData.zones!);
				allZonesRef.current = props.contractData.zones!;
			}
		}
	}, [props.contractData, user]);

	React.useEffect(() => {
		if (
			user?.role === UserRole.Administrator ||
			user?.role === UserRole.TeamLeader ||
			user?.role === UserRole.Accounting
		) {
			if (!fetchnig.current) {
				fetchnig.current = true;
				service
					.getRhenusOverviewInformations(props.contractData.id, moment(fromDate), moment(toDate))
					.then((informations) => {
						setInformations(
							informations.filter((information) =>
								information.zone.name.toLowerCase().includes(searchRef.current.toLowerCase())
							)
						);
						allZonesRef.current = informations;
						fetchnig.current = false;
					})
					.catch((e) => {
						fetchnig.current = false;
					});
			}
		}
	}, [user, fromDate, toDate, props.contractData]);

	const onSearchChange = (search: string) => {
		searchRef.current = search;
		setTimeout(() => {
			if (allZonesRef.current.length !== 0) {
				if (searchRef.current === search) {
					if (allZonesRef.current[0] instanceof RhenusOverviewInformation) {
						setInformations(
							(allZonesRef.current as RhenusOverviewInformation[]).filter((information) =>
								information.zone.name.toLowerCase().includes(search.toLowerCase())
							)
						);
					} else {
						setInformations(
							(allZonesRef.current as ContractZone[]).filter((zone) =>
								zone.name.toLowerCase().includes(search.toLowerCase())
							)
						);
					}
				}
			}
		}, 500);
	};

	const onZonePress = React.useCallback(
		(item: RhenusOverviewInformation | ContractZone) => {
			if (item instanceof RhenusOverviewInformation) {
				goToZoneOverview(history, props.contractData, (item as RhenusOverviewInformation).zone);
			} else {
				goToZoneOverview(history, props.contractData, item as ContractZone);
			}
		},
		[history, props.contractData]
	);

	return (
		<RhenusOverviewScreen
			contractName={props.contractData.contractNumber}
			informations={informations}
			control={control}
			onZonePress={onZonePress}
			onSearchChange={onSearchChange}
		/>
	);
}

export default RhenusOverviewContainer;
