import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Controller, useFormContext } from 'react-hook-form';
import Filter from '../components/Filter';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import NewLink from '../components/NewLink';
import StandardCaseInputList from '../components/StandardCaseInputList';
import StandardDatePicker from '../components/StandardDatePicker';
import StandardTextField from '../components/StandardTextField';
import WorkinTimeMaterialExpensess from '../components/WorkingTimeMaterialExpenses';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { standardCaseInputFields } from '../containers/StandardCaseInputContainer';
import { initStandardCaseInputFilters, StandardCaseFilterKeys } from '../containers/StandardCaseOverviewContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import { checkIfUserStopWriting } from '../helpers/filters';
import { filterApprovedStatusKeyValuePairs } from '../helpers/standardInputHelper/generalStandardInputHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import Contract from '../service/types/Contract';
import StandardCaseInput, { StandardCaseFilterInterface } from '../service/types/StandardCase';
import { UserRole } from '../service/types/User';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import Button from '../components/Button';

interface Props {
	intervalDates: IntervalDatePickerDates;
	filters: StandardCaseFilterInterface;
	standardCaseInputs: StandardCaseInput[];
	allAssignedQAIs: KeyValue[];
	updatedText: string;
	contractData: Contract;
	onBackArrowPress: () => void;
	onNewErrorCasePress: () => void;
	clearAllFilters: () => void;
	onFilterChange: (key: StandardCaseFilterKeys, value: any) => void;
	onViewPress: (standardInputCase: StandardCaseInput) => void;
	onApprovePress: (standardInputCase: StandardCaseInput) => void;
	onWorkingTimePress: () => void;
	onExpensesPress: () => void;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
	downloadTable: () => void;
}

function StandardCaseOverviewScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const globalClasses = useGlobalStyles({ showFilters, description: colors.lightGray });
	const classes = useStyles({});
	const { t } = useTranslation();
	const filterListRef = React.useRef<any>(null);
	const { user } = useAuthStateContext();
	const searchRef = React.useRef<StandardCaseFilterInterface>({ ...initStandardCaseInputFilters });
	const onFilterPress = () => setShowFilters((prev) => !prev);
	const { errors, control } = useFormContext();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow onBackArrowPress={props.onBackArrowPress} />
				<HomeButton contractID={props.contractData.id} contractNumber={props.contractData.contractNumber} />
			</div>
			<div className={classes.row}>
				<div className={classes.titleWrapper}>
					<span className={globalClasses.title}>{props.contractData.contractNumber}</span>
				</div>
				<NewLink onClick={props.onNewErrorCasePress} text={'standard_case_overview_screen_add_new'} />
			</div>
			<WorkinTimeMaterialExpensess
				onMaterialAndExpensses={props.onExpensesPress}
				onWorkingTimePress={props.onWorkingTimePress}
			/>
			<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />
			<div
				className={globalClasses.filterWrapper}
				style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
				<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={standardCaseInputFields.partNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('standard_case_overview_screen_part_number')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'partNumber');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={standardCaseInputFields.deliveryNoteNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('standard_case_overview_screen_delivery_note_number')}
									type="number"
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(
											e,
											props.onFilterChange,
											searchRef,
											'deliveryNoteNumber'
										);
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={2} className={globalClasses.filterFieldWrapper}>
						<StandardDatePicker
							label={t('standard_case_overview_screen_delivery_note_date')}
							onChange={(e) => props.onFilterChange('deliveryNoteDate', e)}
							value={props.filters.deliveryNoteDate}
						/>
					</Grid>
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<IntervalDatePicker
								id="start"
								error={errors['fromDate']}
								helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
								value={props.filters.fromDate}
								label={t('standard_overview_screen_contract_from_date')}
								dates={props.intervalDates}
								clearable={true}
								onKeyboardDateChange={props.onKeyboardDateChange}
								onDateChange={props.onDateChange}
								onDatesAccept={props.onDatesAccept}
								onDatesCancel={props.onDatesCancel}
								clearDates={props.clearDates}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<IntervalDatePicker
								id="end"
								error={errors['toDate']}
								helperText={errors['toDate'] ? errors['toDate'].message : ''}
								value={props.filters.toDate}
								label={t('standard_overview_screen_contract_to_date')}
								dates={props.intervalDates}
								clearable={true}
								onKeyboardDateChange={props.onKeyboardDateChange}
								onDateChange={props.onDateChange}
								onDatesAccept={props.onDatesAccept}
								onDatesCancel={props.onDatesCancel}
								clearDates={props.clearDates}
							/>
						</Grid>
					) : null}
					<Grid item sm={2} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={standardCaseInputFields.checkNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('standard_case_overview_screen_check_number')}
									type="number"
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'checkNumber');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<KeyValueAutoComplete
							label={t('standard_case_overview_screen_type_of_inspection')}
							multiple={false}
							onChange={(e) => {
								if (e) {
									props.onFilterChange('typeOfInspection', e.value);
								} else {
									props.onFilterChange('typeOfInspection', '');
								}
							}}
							options={props.contractData.typesOfInspection.map((el) => ({ key: el, value: el }))}
							value={{
								key: props.filters.typeOfInspection,
								value: props.filters.typeOfInspection,
							}}
							withoutClose={false}
						/>
					</Grid>
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<KeyValueAutoComplete
								label={t('standard_case_overview_screen_reporter')}
								onChange={(e) => {
									if (e) {
										props.onFilterChange('qai', e.value.id.toString());
									} else {
										props.onFilterChange('qai', '');
									}
								}}
								options={props.allAssignedQAIs}
								multiple={false}
								value={{
									key: props.allAssignedQAIs.find(
										(qai) => qai.value.id.toString() === props.filters.qai
									)?.key
										? props.allAssignedQAIs.find(
												(qai) => qai.value.id.toString() === props.filters.qai
										  )!.key
										: '',
									value: props.filters.qai,
								}}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<KeyValueAutoComplete
								label={t('standard_case_overview_screen_shift')}
								multiple={false}
								onChange={(e) => {
									if (e) {
										props.onFilterChange('shift', e.value);
									} else {
										props.onFilterChange('shift', '');
									}
								}}
								options={props.contractData.shiftTimes.map((el) => ({ key: el.name, value: el.id }))}
								value={{
									key:
										props.filters.shift === ''
											? ''
											: t(
													props.contractData.shiftTimes.find(
														(el) => el.id === props.filters.shift
													)?.name || ''
											  ),
									value: props.filters.shift,
								}}
								withoutClose={false}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector && !props.contractData.automatedUpdates ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<StandardTextField
								label={t('standard_overview_screen_contract_approved')}
								select
								value={props.filters.approved}
								onChange={(e) => props.onFilterChange('approved', e.target.value)}>
								{filterApprovedStatusKeyValuePairs.map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						</Grid>
					) : null}
				</Grid>
			</div>
			<Button
				text={t('dashboard_screen_export_table')}
				textClassName={globalClasses.buttonText}
				buttonWrapperClassName={classes.buttonWrapper}
				onClick={props.downloadTable}
			/>
			{user?.role !== UserRole.QualityAssuranceInspector ? (
				<div>
					<div className={classes.lastUpdateWrapper}>
						<span className={globalClasses.description}>{`${t(
							'standard_overview_screen_last_updated'
						)}: ${t(props.updatedText)}`}</span>
					</div>
					<div className={classes.subtitleWrapper}>
						<span className={globalClasses.subtitle}>{`${t(
							'standard_overview_screen_previously_reported'
						)}`}</span>
					</div>
				</div>
			) : null}
			<StandardCaseInputList
				onApprovePress={props.onApprovePress}
				filters={props.filters}
				shiftTimes={props.contractData.shiftTimes}
				photo={props.contractData.isPhotoAllowed}
				automatedUpdates={props.contractData.automatedUpdates}
				typesOfInspections={[' ', ...props.contractData.typesOfInspection]}
				onFilterChange={props.onFilterChange}
				allAssignedQAIs={props.allAssignedQAIs}
				standardCaseInputs={props.standardCaseInputs}
				onViewPress={props.onViewPress}
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	subtitleWrapper: {
		marginTop: '8px',
	},
	lastUpdateWrapper: {
		marginTop: '40px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleWrapper: {
		marginBottom: '12px',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '8px 22px',
		cursor: 'pointer',
		marginTop: '15px',
	},
}));

export default StandardCaseOverviewScreen;
