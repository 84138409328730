import React from 'react';
import { ExpenseTabelParamsInterface } from '../consts/material';
import { TableCellWithCss } from './table/StandardTableCell';

interface Props {
	mainClass: string;
	textClass: string;
	params: ExpenseTabelParamsInterface;
}
function AddExpensesItemStatic(props: Props) {
	return (
		<TableCellWithCss
			className={props.mainClass}
			style={{
				width: props.params.width,
			}}>
			<span className={props.textClass}>{props.params.value}</span>
		</TableCellWithCss>
	);
}

export default AddExpensesItemStatic;
