import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import { useGlobalStyles } from '../consts/globalStyles';
import { FileInterface } from '../helpers/contractHelper/generalContractHelper';
import ErrorIcon from '../svgComponents/ErrorIcon';
import RemoveIcon from '../svgComponents/RemoveIcon';
import FileLink, { SPLITTER } from './FileLink';

interface Props {
	label: string;
	name: string;
	onChange: (event: any) => void;
	disabled: boolean;
	icon: JSX.Element;
	multiple: boolean;
	value?: FileInterface | FileInterface[];
	error?: boolean;
	helperText?: string;
	placeholder?: string;
	margin?: number;
}

export interface FileInputIntreface {
	setNewValues: (val: FileInterface[]) => void;
}

function StandardFileInput(
	{ label, name, onChange, disabled, icon, multiple, value, error, helperText, placeholder, margin }: Props,
	ref: any
) {
	const classes = useStyles({ disabled: disabled, multiple: multiple, margin: margin });
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles();
	const newValues = React.useRef<FileInterface[]>([]);

	React.useImperativeHandle(
		ref,
		(): FileInputIntreface => ({
			setNewValues: (val: FileInterface[]) => {
				newValues.current = val;
			},
		})
	);

	const onChangeHandler = (files: FileList, index: number) => {
		try {
			let reader = new FileReader();
			if (files) reader.readAsDataURL(files[index]);
			reader.onload = function () {
				if (reader.result && files)
					if (!multiple)
						onChange({
							name: files[index].name,
							data: reader.result.toString().split(',')[1],
						});
					else {
						newValues.current = [
							{ name: files[index].name, data: reader.result.toString().split(',')[1] },
							...newValues.current.filter((val) => {
								const splittedName = val.name.split(SPLITTER);
								if (splittedName.length > 1) return splittedName[1] !== files[index].name;
								return val.name !== files[index].name;
							}),
						];
						onChange(newValues.current);
					}
			};
			reader.onerror = function (error) {};
		} catch (e) {}
	};

	const removeFile = (index: number) => {
		newValues.current = newValues.current.filter((_, itemIndex) => itemIndex !== index);
		onChange(newValues.current);
	};

	React.useEffect(() => {
		if (value && (value as FileInterface[]).length && newValues.current.length === 0) {
			newValues.current = value as FileInterface[];
		}
	}, [value]);

	return (
		<div>
			<div className={classes.labelWrapper}>
				<span className={globalClasses.label}>{t(label).toUpperCase()}</span>
			</div>
			<div className={classes.rowName}>
				<label className={classes.link}>
					<input
						type="file"
						accept=".pdf, .png"
						disabled={disabled}
						className={classes.input}
						multiple={multiple}
						onChange={(e) => {
							if (e.target.files)
								for (let index = 0; index < e.target.files.length; index++) {
									onChangeHandler(e.target.files, index);
								}
						}}
					/>
					{icon}
					{value && !multiple ? null : t(name)}
				</label>
				{value ? (
					(value as FileInterface[]).length ? (
						<div className={classes.multipleAttachments}>
							{(value as FileInterface[]).map((val: FileInterface, index: number) => (
								<div className={classes.mulitpleWrapper} key={`FileAttachment${index}`}>
									<FileLink value={val} />
									{disabled ? null : (
										<RemoveIcon
											className={classes.removeIconWrapper}
											onClick={() => removeFile(index)}
										/>
									)}
								</div>
							))}
						</div>
					) : (
						<div>
							<FileLink value={value as FileInterface} />
						</div>
					)
				) : null}
			</div>
			{error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error}>{helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	disabled: boolean;
	multiple: boolean;
	margin?: number;
}

const useStyles = makeStyles(() => ({
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: {
		width: '100%',
		borderRadius: '4px',
	},
	dropdownContainer: {
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
	},
	dropdownArrowWrapper: { position: 'absolute', right: '12.5px', cursor: 'pointer' },
	dropdownMenuWrapper: {
		height: '1.1876em',
	},
	input: {
		display: 'none',
	},

	rowName: (p: StyleProps) => ({
		display: 'flex',
		flexDirection: p.multiple ? 'column' : 'row',
		marginTop: p.margin ? p.margin + 'px' : '3px',
	}),
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	link: (p: StyleProps) => ({
		display: 'flex',
		alignItems: 'center',
		cursor: p.disabled ? 'default' : 'pointer',
		color: colors.darkGray,
		fontSize: '14px',
		lineHeight: '20px',
		textDecoration: 'underline',
		fontFamily: fonts.regular,
		width: 'fit-content',
	}),

	multipleAttachments: {
		marginLeft: '29px',
	},
	mulitpleWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '4px',
	},
	removeIconWrapper: (p: StyleProps) => ({
		marginLeft: '15px',
		cursor: p.disabled ? 'default' : 'pointer',
	}),
}));

export default React.forwardRef(StandardFileInput);
