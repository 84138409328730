import * as React from 'react';

function DashboardIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={150}
			height={150}
			viewBox="0 0 150 150"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<defs>
				<filter
					x="-5.6%"
					y="-2.8%"
					width="111.3%"
					height="111.3%"
					filterUnits="objectBoundingBox"
					id="dashboard1">
					<feMorphology radius={2} in="SourceAlpha" result="shadowSpreadOuter1" />
					<feOffset dy={4} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0.2 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<rect id="dashboard2" x={0} y={0} width={142} height={142} rx={4} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(4)">
					<use fill="#000" filter="url(#dashboard1)" xlinkHref="#dashboard2" />
					<use fill="#FFF" xlinkHref="#dashboard2" />
				</g>
				<path fill="#FFD500" opacity={0.2} d="M146 0L4 143V0z" />
				<g transform="translate(28 40)" stroke="#111" strokeWidth={2.5}>
					<path d="M0 0h94.8v63.6H0zM55.8 15.6h21.6M55.8 25.2h21.6M55.8 34.8h21.6" />
					<circle cx={25.8} cy={25.8} r={13.8} />
					<path d="M35.796 35.208l-19.56-18.982" />
				</g>
			</g>
		</svg>
	);
}

export default DashboardIcon;
