import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../consts/globalStyles';
import CheckedIcon from '../svgComponents/CheckedIcon';
import UncheckedIcon from '../svgComponents/UncheckedIcon';

interface Props {
	radioButtonValue: any;
	value: any;
	label: string;
	disabled: boolean;
	onChange: (value: any) => void;
}

function StandardRadioButton(props: Props) {
	const classes = useStyles({ disabled: props.disabled });
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles();
	const isChecked = React.useMemo(() => props.radioButtonValue === props.value, [
		props.value,
		props.radioButtonValue,
	]);

	return (
		<div
			className={classes.container}
			onClick={() => {
				if (!props.disabled) props.onChange(props.radioButtonValue);
			}}>
			{isChecked ? (
				<CheckedIcon iconKey={props.radioButtonValue} />
			) : (
				<UncheckedIcon iconKey={props.radioButtonValue} />
			)}
			<div className={classes.textWrapper}>
				<span className={globalClasses.smallText}>{t(props.label)}</span>
			</div>
		</div>
	);
}

interface StyleProps {
	disabled: boolean;
}

const useStyles = makeStyles(() => ({
	container: (p: StyleProps) => ({
		display: 'flex',
		alignItems: 'center',
		cursor: p.disabled ? 'default' : 'pointer',
		paddingRight: '40px',
	}),
	textWrapper: {
		paddingLeft: '10px',
	},
}));

export default StandardRadioButton;
