import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Spinner from '../components/Spinner';

import { filterData } from '../helpers/filters';
import { goToUser } from '../helpers/navigationHelper';
import UserOverviewScreen from '../screens/UserOverviewScreen';
import service from '../service/service';
import User, { UserFilterInterface } from '../service/types/User';
import { userFields } from './UserContainer';

export const initUserFilters: UserFilterInterface = {
	role: '',
	lastName: '',
	firstName: '',
	originOfEmployee: '',
};
const data = Object.freeze({ ...initUserFilters });
export type UserFilterKeys = keyof typeof data;

function UserOverviewContainer() {
	const { control, setValue } = useForm();
	const [users, setUsers] = React.useState<User[]>([]);
	const [filters, setFilters] = React.useState<UserFilterInterface>(initUserFilters);
	const allUsersRef = React.useRef<User[]>([]);
	const history = useHistory();
	const [mounted, setMounted] = React.useState(false);

	const onFilterChange = (key: UserFilterKeys, value: string) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));
		setUsers(filterData({ ...filters, [key]: tempValue }, allUsersRef.current));
	};

	const clearAllFilters = () => {
		Object.values(userFields).forEach((val) => setValue(val, ''));
		setFilters(initUserFilters);
	};

	const onNewUserClick = () => goToUser(history);

	React.useEffect(() => {
		service
			.getAllUsers()
			.then((users) => {
				unstable_batchedUpdates(() => {
					setUsers(users);
					allUsersRef.current = users;
					setMounted(true);
				});
			})
			.catch(() => {});
	}, []);

	React.useEffect(() => {
		setUsers(filterData(filters, allUsersRef.current));
	}, [filters]);

	const onEditPress = (user: User) => {
		const currentUser = allUsersRef.current.find((item) => item.id === user.id)!;
		goToUser(history, currentUser);
	};

	if (!mounted) return <Spinner />;

	return (
		<UserOverviewScreen
			users={users}
			filters={filters}
			control={control}
			onEditPress={onEditPress}
			onFilterChange={onFilterChange}
			onNewUserClick={onNewUserClick}
			clearAllFilters={clearAllFilters}
		/>
	);
}

export default UserOverviewContainer;
