import React from 'react';
import { createStyles, TableCell, withStyles } from '@material-ui/core';

import ButtonCell from './ButtonCell';
import { Sort } from '../ContractList';
import ApproveCell from './ApproveCell';
import LinkTypeCell from './LinkTypeCell';
import TextTypeCell from './TextTypeCell';
import TitleTypeCell from './TitleTypeCell';
import { Routes } from '../../router/Routes';
import WithButtonCell from './WithButtonCell';
import ExpandableCell from './ExpandableCell';
import WithDropdownCellType from './WithDropdownTitleCell';
import { KeyValue } from '../../helpers/userHelper/generalUserHelper';

export enum TableCellType {
	Title,
	Text,
	TitleWithDropdown,
	Link,
	Sticky,
	Button,
	Expandable,
	Approve,
	Date,
}

export const TableCellWithCss = withStyles(() =>
	createStyles({
		head: {
			lineHeight: '1rem',
		},
		body: {
			borderBottom: '1px solid rgba(199,199,199, 0.5)',
		},
	})
)(TableCell);

interface Props {
	className: string;
	displaySort?: boolean;
	key: string;
	restProps: {
		type: TableCellType;
		sticky?: boolean;
		filter?: any;
		filterOptions?: KeyValue[];
		buttonText?: string;
		filterKey?: string;
		index?: number;
		redirectTo?: Routes;
		onButtonClick?: () => void;
		onLinkClick?: () => void;
		onFilterChange?: (key: string, value: any) => void;
		markTextAsRed?: boolean;
	};
	style: any;
	sort?: Sort;
	setSort?: React.Dispatch<React.SetStateAction<Sort>>;
	value: any;
}

function getTableCell(props: Props) {
	switch (props.restProps!.type) {
		case TableCellType.Title:
			return (
				<TitleTypeCell
					displaySort={props.displaySort}
					field={props.restProps.filterKey}
					sort={props.sort}
					setSort={props.setSort}
					value={props.value}
				/>
			);
		case TableCellType.Sticky:
			return (
				<WithButtonCell
					value={props.value}
					onClick={props.restProps?.onButtonClick!}
					buttonText={props.restProps?.buttonText!}
					index={props.restProps.index}
					redirectTo={props.restProps.redirectTo}
				/>
			);
		case TableCellType.Approve:
			return (
				<ApproveCell
					value={props.value}
					onClick={props.restProps?.onButtonClick!}
					buttonText={props.restProps?.buttonText!}
				/>
			);
		case TableCellType.Button:
			return (
				<ButtonCell
					value={props.value}
					onClick={props.restProps?.onButtonClick!}
					buttonText={props.restProps?.buttonText!}
				/>
			);
		case TableCellType.Text:
			return (
				<TextTypeCell
					value={props.value}
					sticky={props.restProps.sticky}
					markTextAsRed={props.restProps.markTextAsRed}
				/>
			);
		case TableCellType.Date:
			return <TextTypeCell value={props.value} sticky={props.restProps.sticky} />;
		case TableCellType.Expandable:
			return <ExpandableCell value={props.value} />;
		case TableCellType.Link:
			return <LinkTypeCell value={props.value} onClick={props.restProps?.onLinkClick!} />;
		case TableCellType.TitleWithDropdown:
			return (
				<WithDropdownCellType
					{...props}
					width="10vw"
					value={props.value}
					restProps={{
						filter: props.restProps?.filter,
						filterOptions: props.restProps?.filterOptions!,
						filterKey: props.restProps?.filterKey!,
						onFilterChange: props.restProps?.onFilterChange!,
					}}
					sort={props.sort}
					setSort={props.setSort}
				/>
			);
		default:
			<div></div>;
	}
}

function StandardTableCell(props: Props) {
	const cell = getTableCell(props);

	if (props.restProps.type === TableCellType.TitleWithDropdown) {
		return <>{cell}</>;
	}

	return (
		<TableCellWithCss className={props.className} style={props.style} onClick={() => {}}>
			{cell}
		</TableCellWithCss>
	);
}

export default StandardTableCell;
