import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../../consts/colors';
import { fonts } from '../../consts/fonts';

interface Props {
	value: string;
	onClick: () => void;
}

function LinkTypeCell(props: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<span className={classes.text} onClick={props.onClick}>
			{t('link_cell_text')}
		</span>
	);
}

const useStyles = makeStyles(() => ({
	text: {
		fontFamily: fonts.regular,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.darkGray,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}));

export default LinkTypeCell;
