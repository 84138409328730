import React from 'react';
import { Grid } from '@material-ui/core';
import { Control } from 'react-hook-form';
import { AddressInterface } from '../service/types/Address';
import { useTranslation } from 'react-i18next';
import { rules } from '../consts/rules';
import StandardTextFieldWithControler from '../screens/StandardTextFieldWithContoler';
import { MIN_CUSTOMER_LENGTH } from '../containers/CustomerContainer';
import { MAX_PASSWORD_LENGTH } from '../consts/login';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	isUserEditing: boolean;
	keys: AddressInterface;
	disabled?: boolean;
}

function AddressItem(props: Props) {
	const { t } = useTranslation();

	const localRules = React.useMemo(() => rules(t), [t]);

	return (
		<Grid container spacing={3}>
			<Grid item sm={12}>
				<StandardTextFieldWithControler
					errors={props.errors}
					rules={
						props.disabled
							? {}
							: {
									required: localRules.required,
									minLength: localRules.minLength(MIN_CUSTOMER_LENGTH),
									maxLength: localRules.maxLength(MAX_PASSWORD_LENGTH),
									pattern: rules(t).notEmptyPattern,
							  }
					}
					isUserEditing={props.isUserEditing}
					label={t('customer_address_country').toUpperCase()}
					control={props.control}
					name={props.keys.country}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item sm={8}>
				<StandardTextFieldWithControler
					errors={props.errors}
					rules={
						props.disabled
							? {}
							: {
									required: localRules.required,
									minLength: localRules.minLength(MIN_CUSTOMER_LENGTH),
									maxLength: localRules.maxLength(MAX_PASSWORD_LENGTH),
									pattern: rules(t).notEmptyPattern,
							  }
					}
					isUserEditing={props.isUserEditing}
					label={t('customer_address_city').toUpperCase()}
					control={props.control}
					name={props.keys.city}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item sm={4}>
				<StandardTextFieldWithControler
					errors={props.errors}
					rules={
						props.disabled
							? {}
							: {
									required: localRules.required,
									minLength: localRules.minLength(MIN_CUSTOMER_LENGTH),
									maxLength: localRules.maxLength(MAX_PASSWORD_LENGTH),
									pattern: rules(t).notEmptyPattern,
							  }
					}
					isUserEditing={props.isUserEditing}
					label={t('customer_address_postcode').toUpperCase()}
					control={props.control}
					name={props.keys.postcode.toString()}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item sm={9}>
				<StandardTextFieldWithControler
					errors={props.errors}
					rules={
						props.disabled
							? {}
							: {
									required: localRules.required,
									minLength: localRules.minLength(MIN_CUSTOMER_LENGTH),
									maxLength: localRules.maxLength(MAX_PASSWORD_LENGTH),
									pattern: rules(t).notEmptyPattern,
							  }
					}
					isUserEditing={props.isUserEditing}
					label={t('customer_address_street').toUpperCase()}
					control={props.control}
					name={props.keys.street}
					disabled={props.disabled}
				/>
			</Grid>
			<Grid item sm={3}>
				<StandardTextFieldWithControler
					errors={props.errors}
					rules={
						props.disabled
							? {}
							: {
									required: localRules.required,
									maxLength: localRules.maxLength(MAX_PASSWORD_LENGTH),
									pattern: rules(t).notEmptyPattern,
							  }
					}
					isUserEditing={props.isUserEditing}
					label={t('customer_address_house_number').toUpperCase()}
					control={props.control}
					name={props.keys.houseNumber}
					disabled={props.disabled}
				/>
			</Grid>
			{props.keys.costCenter ? (
				<Grid item sm={12}>
					<StandardTextFieldWithControler
						errors={props.errors}
						rules={{}}
						isUserEditing={props.isUserEditing}
						label={t('customer_address_cost_center').toUpperCase()}
						control={props.control}
						name={props.keys.costCenter}
					/>
				</Grid>
			) : null}
		</Grid>
	);
}

export default AddressItem;
