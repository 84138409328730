import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useGlobalStyles } from '../consts/globalStyles';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import SearchInput from '../components/SearchInput';
import { ContractZone } from '../helpers/contractHelper/generalContractHelper';
import CustomerQAIZoneOverview from '../components/CustomerQAIZoneOverview';
import AdminTLZoneOverview from '../components/AdminTLZoneOverview';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import { Control } from 'react-hook-form';

interface Props {
	contractName: string;
	informations: ContractZone[] | RhenusOverviewInformation[];
	control: Control<Record<string, any>>;
	onZonePress: (item: RhenusOverviewInformation | ContractZone) => void;
	onSearchChange: (val: string) => void;
}

function RhenusOverviewScreen(props: Props) {
	const classes = useStyles({});
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles({});
	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
				<HomeButton />
			</div>
			<Grid container spacing={3}>
				<Grid item sm={1} />
				<Grid item sm={10}>
					<div>
						<div className={classes.row}>
							<span className={globalClasses.smallText}>
								{t('rhenus_case_overview_screen_contract_number')}
							</span>
							<div className={classes.contractNumberWrapper}>
								<span className={globalClasses.smallTextDark}>{props.contractName}</span>
							</div>
						</div>
					</div>
					<div className={classes.titleRow}>
						<div className={classes.titleWrapper}>
							<span className={globalClasses.title}>
								{t('rhenus_case_overview_screen_available_zones')}
							</span>
						</div>
						<SearchInput
							onChange={props.onSearchChange}
							placeholder={t('rhenus_case_overview_screen_search')}
						/>
					</div>
					{props.informations.length !== 0 ? (
						props.informations[0] instanceof RhenusOverviewInformation ? (
							<AdminTLZoneOverview
								informations={props.informations as RhenusOverviewInformation[]}
								control={props.control}
								onZonePress={props.onZonePress}
							/>
						) : (
							<CustomerQAIZoneOverview
								zones={props.informations as ContractZone[]}
								onZonePress={props.onZonePress}
							/>
						)
					) : null}
				</Grid>
				<Grid item sm={1} />
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginRight: '32px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
	},
	titleRow: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: '24px',
	},
	contractNumberWrapper: {
		marginLeft: '4px',
	},
}));

export default RhenusOverviewScreen;
