import React from 'react';
import { makeStyles } from '@material-ui/core';
import SaveIcon from '../svgComponents/SaveIcon';
import EditIcon from '../svgComponents/EditIcon';
import RemoveIcon from '../svgComponents/RemoveIcon';
import DeleteIcon from '../svgComponents/DeleteIcon';

interface Props {
	edit: boolean;
	onSavePress: () => void;
	onEditPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
}
function ExpensesButtonCellType(props: Props) {
	const classes = useStyles({});
	return (
		<div className={classes.buttonsWrapper}>
			{props.edit ? (
				<SaveIcon onClick={props.onSavePress} height={18} width={18} className={classes.editIconWrapper} />
			) : (
				<EditIcon onClick={props.onEditPress} height={18} width={18} className={classes.editIconWrapper} />
			)}
			{props.edit ? (
				<RemoveIcon onClick={props.onCancelPress} height={18} width={18} className={classes.closeIconWrapper} />
			) : (
				<DeleteIcon onClick={props.onDeletePress} className={classes.closeIconWrapper} />
			)}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	buttonsWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	editIconWrapper: {
		marginRight: '16px',
		cursor: 'pointer',
	},
	closeIconWrapper: {
		cursor: 'pointer',
	},
}));

export default ExpensesButtonCellType;
