import configValues from './configValues';

class Config {
	private BACKEND_SCHEME: string;
	private BACKEND: string;
	private BACKEND_VERSION: string;
	private TIMEOUT: number;

	constructor(scheme: string, backend: string, version: string, timeout: number) {
		this.BACKEND_SCHEME = scheme;
		this.BACKEND = backend;
		this.BACKEND_VERSION = version;
		this.TIMEOUT = timeout;
	}

	public static getConfig(): Config {
		return new Config(
			configValues.BACKEND_SCHEME,
			configValues.BACKEND,
			configValues.BACKEND_VERSION,
			configValues.TIMEOUT
		);
	}

	public getBackendScheme() {
		return this.BACKEND_SCHEME;
	}

	public getBackend() {
		return this.BACKEND;
	}

	public getBackendVersion() {
		return this.BACKEND_VERSION;
	}

	public getTimeout() {
		return this.TIMEOUT;
	}
}

export default Config;
