import moment from 'moment';

import { Service } from '../service/service';
import Contract from '../service/types/Contract';
import User, { CreateEmployeeParams, UpdateEmployeeParams } from '../service/types/User';
import { QAILoginParams, ResetPasswordParams, UserLoginParams } from '../helpers/userHelper/generalUserHelper';
import Customer from '../service/types/Customer';
import StandardCaseInput, { StandardCaseFilterInterface } from '../service/types/StandardCase';
import { ContactParams } from '../containers/ContactUsContainer';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import RhenusCase from '../service/types/RhenusCase';
import WorkingTime from '../service/types/WorkingTime';
import MaterialTool from '../service/types/MaterialTool';
import Travel from '../service/types/Travel';
import { ContractStatusType, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import { LoginResponse } from '../consts/login';
import Transport from '../service/types/Transport';
import { Moment } from 'moment';
import BreakTime from '../service/types/BreakTime';
import { DashboardFilterInterface } from '../helpers/dashboardHelper/generalDashboardHelper';
import { WorkingTimeFilterInterface } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { InvoiceItem } from '../service/types/Invoice';
import { CreateGroup, UpdateGroup } from '../pages/GroupCreatePage';
import { Group } from '../pages/groupOverview/GroupOverview';

const UPDATE_DIFF = 1;
const ALL_USERS = 'ALL_USERS';
const ALL_TEAM_LEADERS = 'ALL_TEAM_LEADERS';
const ALL_QAIS = 'ALL_QAIS';
const ALL_CUSTOMERS = 'ALL_CUSTOMERS';

interface LocalData {
	lastPull: string;
	data: any;
}

class CacheMiddleware implements Service {
	next: Service;
	constructor(next: Service) {
		this.next = next;
	}
	public setJWTToken(JWTToken: string) {
		this.next.setJWTToken(JWTToken);
	}

	public async login(params: UserLoginParams): Promise<LoginResponse | undefined> {
		return this.next.login(params);
	}

	public async loginQAI(params: QAILoginParams): Promise<LoginResponse | undefined> {
		return await this.next.loginQAI(params);
	}

	public async checkToken(): Promise<boolean> {
		return await this.next.checkToken();
	}

	public async getAuthToken(refreshToken: string): Promise<string | undefined> {
		return await this.next.getAuthToken(refreshToken);
	}

	public async requestPasswordReset(refreshToken: string): Promise<boolean> {
		return await this.next.requestPasswordReset(refreshToken);
	}
	public async checkPasswordRessetToken(token: string): Promise<boolean> {
		return await this.next.checkPasswordRessetToken(token);
	}

	public async resetPassword(params: ResetPasswordParams): Promise<boolean> {
		return await this.next.resetPassword(params);
	}
	public async resetPasswordQAI(params: ResetPasswordParams): Promise<boolean> {
		return await this.next.resetPasswordQAI(params);
	}

	public async getAllUsers(): Promise<User[]> {
		const data = getData(ALL_USERS);
		if (data) return data;
		const res = await this.next.getAllUsers();
		saveData(ALL_USERS, res);
		return res;
	}

	public async readGroups(): Promise<Group[]> {
		return await this.next.readGroups();
	}
	public async createGroup(data: CreateGroup): Promise<boolean> {
		return await this.next.createGroup(data);
	}
	public async readGroup(id: number): Promise<Group> {
		return await this.next.readGroup(id);
	}
	public async updateGroup(data: UpdateGroup): Promise<boolean> {
		return await this.next.updateGroup(data);
	}
	public async deleteGroup(id: number): Promise<boolean> {
		return await this.next.deleteGroup(id);
	}

	public async logout(): Promise<boolean> {
		return await this.next.logout();
	}
	public async getContracts(): Promise<Contract[]> {
		return await this.next.getContracts();
	}
	public async createEmployee(params: CreateEmployeeParams): Promise<boolean> {
		localStorage.removeItem(ALL_TEAM_LEADERS);
		localStorage.removeItem(ALL_USERS);
		return await this.next.createEmployee(params);
	}
	public async createQAI(params: CreateEmployeeParams): Promise<boolean> {
		localStorage.removeItem(ALL_QAIS);
		localStorage.removeItem(ALL_USERS);
		return await this.next.createQAI(params);
	}
	public async getUserInfo(): Promise<User> {
		return await this.next.getUserInfo();
	}
	public async getAllQAIs(): Promise<User[]> {
		const data = getData(ALL_QAIS);
		if (data) return data;
		const res = await this.next.getAllQAIs();
		saveData(ALL_QAIS, res);
		return res;
	}
	public async getAllTeamLeaders(): Promise<User[]> {
		const data = getData(ALL_TEAM_LEADERS);
		if (data) return data;
		const res = await this.next.getAllTeamLeaders();
		saveData(ALL_TEAM_LEADERS, res);
		return res;
	}
	public async getAllCustomers(): Promise<Customer[]> {
		const data = getData(ALL_CUSTOMERS);
		if (data) return data;
		const res = await this.next.getAllCustomers();
		saveData(ALL_CUSTOMERS, res);
		return res;
	}
	public async createCustomer(customer: Customer): Promise<number> {
		localStorage.removeItem(ALL_CUSTOMERS);
		return await this.next.createCustomer(customer);
	}
	public async createUserForCustomer(id: number, params: CreateEmployeeParams): Promise<number> {
		removeAllData();
		return await this.next.createUserForCustomer(id, params);
	}
	public async getCustomerFromUser(id: number): Promise<Customer> {
		return await this.next.getCustomerFromUser(id);
	}
	public async deleteUserByID(id: number): Promise<boolean> {
		removeAllData();
		return await this.next.deleteUserByID(id);
	}
	public async updateUserByID(id: number, params: UpdateEmployeeParams): Promise<boolean> {
		removeAllData();
		return await this.next.updateUserByID(id, params);
	}
	public async updateCustomerByID(id: number, customer: Customer): Promise<boolean> {
		removeAllData();
		return await this.next.updateCustomerByID(id, customer);
	}
	public async getAllStandardCaseInputs(contractID: number): Promise<StandardCaseInput[]> {
		return await this.next.getAllStandardCaseInputs(contractID);
	}
	public async updateStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		return await this.next.updateStandradCaseInput(contractID, errorInput);
	}
	public async createContract(contract: Contract): Promise<number> {
		return await this.next.createContract(contract);
	}
	public async deleteContractByID(contractID: number): Promise<boolean> {
		return await this.next.deleteContractByID(contractID);
	}

	public async createInvoice(
		contractID: number,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment
	): Promise<number> {
		return await this.next.createInvoice(contractID, dateOfInvoice, fromDate, toDate);
	}

	public async updateInvoice(
		id: number,
		creationDate: Moment,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment,
		billNumber: string
	): Promise<void> {
		await this.next.updateInvoice(id, creationDate, dateOfInvoice, fromDate, toDate, billNumber);
	}

	public async completeInvoice(id: number): Promise<void> {
		await this.next.completeInvoice(id);
	}

	public async deleteInvoice(id: number): Promise<void> {
		await this.next.deleteInvoice(id);
	}

	public async contact(params: ContactParams): Promise<boolean> {
		return await this.next.contact(params);
	}
	public async createStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		return await this.next.createStandradCaseInput(contractID, errorInput);
	}
	public async deleteStandradCaseInputByID(contractID: number, errorInputID: number): Promise<boolean> {
		return await this.next.deleteStandradCaseInputByID(contractID, errorInputID);
	}
	public async getRhenusOverviewInformations(
		contractID: number,
		from: Moment,
		to: Moment
	): Promise<RhenusOverviewInformation[]> {
		return await this.next.getRhenusOverviewInformations(contractID, from, to);
	}
	public async getZoneCaseInputs(contractID: number, zoneID: number): Promise<RhenusCase[]> {
		return await this.next.getZoneCaseInputs(contractID, zoneID);
	}
	public async deleteRhenusCaseInputByID(contractID: number, zoneID: number, errorID: number): Promise<boolean> {
		return await this.next.deleteRhenusCaseInputByID(contractID, zoneID, errorID);
	}
	public async createRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		return await this.next.createRhenusCaseInput(contractID, zoneID, rhenusCaseInput);
	}
	public async updateRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		return await this.next.updateRhenusCaseInput(contractID, zoneID, rhenusCaseInput);
	}

	public async sendErrorReport(message: string): Promise<boolean> {
		return await this.next.sendErrorReport(message);
	}
	public async addManualWorkTime(contractID: number, worktime: WorkingTime): Promise<number> {
		return await this.next.addManualWorkTime(contractID, worktime);
	}
	public async addManualBreakTime(contractID: number, worktime: BreakTime): Promise<number> {
		return await this.next.addManualBreakTime(contractID, worktime);
	}
	public async startTrackingTime(contractID: number): Promise<boolean> {
		return await this.next.startTrackingTime(contractID);
	}
	public async stopTrackingTime(
		contractID: number,
		profession: number | undefined
	): Promise<WorkingTime | undefined> {
		return await this.next.stopTrackingTime(contractID, profession);
	}
	public async getAllRecords(contractID: number): Promise<(WorkingTime | BreakTime)[]> {
		return await this.next.getAllRecords(contractID);
	}

	public async readShifts(contractID: number): Promise<ShiftInterface[]> {
		return await this.next.readShifts(contractID);
	}

	public async getAllInvoices(contractID: number): Promise<any> {
		return await this.next.getAllInvoices(contractID);
	}
	public async getInvoiceById(invoiceID: number): Promise<InvoiceItem | undefined> {
		return await this.next.getInvoiceById(invoiceID);
	}

	public async exportInvoice(id: number): Promise<string | undefined> {
		return await this.next.exportInvoice(id);
	}

	public async deleteRecordByID(contractID: number, recordID: number): Promise<boolean> {
		return await this.next.deleteRecordByID(contractID, recordID);
	}
	public async deleteBreakByID(contractID: number, recordID: number): Promise<boolean> {
		return await this.next.deleteBreakByID(contractID, recordID);
	}
	public async deleteWaitingByID(contractID: number, recordID: number): Promise<boolean> {
		return await this.next.deleteWaitingByID(contractID, recordID);
	}
	public async updateRecordByID(contractID: number, record: WorkingTime): Promise<boolean> {
		return await this.next.updateRecordByID(contractID, record);
	}
	public async updateBreakByID(contractID: number, record: BreakTime): Promise<boolean> {
		return await this.next.updateBreakByID(contractID, record);
	}
	public async updateWaitingByID(contractID: number, record: BreakTime): Promise<boolean> {
		return await this.next.updateWaitingByID(contractID, record);
	}
	public async getMaterialExpenses(contractID: number): Promise<MaterialTool[]> {
		return await this.next.getMaterialExpenses(contractID);
	}
	public async getToolExpenses(contractID: number): Promise<MaterialTool[]> {
		return await this.next.getToolExpenses(contractID);
	}
	public async getTravelExpenses(contractID: number): Promise<Travel[]> {
		return await this.next.getTravelExpenses(contractID);
	}
	public async createMaterialExpenses(contractID: number, material: MaterialTool): Promise<number> {
		return await this.next.createMaterialExpenses(contractID, material);
	}
	public async createTravelExpenses(contractID: number, travel: Travel): Promise<number> {
		return await this.next.createTravelExpenses(contractID, travel);
	}
	public async createToolExpenses(contractID: number, tool: MaterialTool): Promise<number> {
		return await this.next.createToolExpenses(contractID, tool);
	}
	public async updateMaterialExpenses(contractID: number, material: MaterialTool): Promise<boolean> {
		return await this.next.updateMaterialExpenses(contractID, material);
	}
	public async updateTravelExpenses(contractID: number, travel: Travel): Promise<boolean> {
		return await this.next.updateTravelExpenses(contractID, travel);
	}
	public async updateToolExpenses(contractID: number, tool: MaterialTool): Promise<boolean> {
		return await this.next.updateToolExpenses(contractID, tool);
	}

	public async deleteMaterialExpensesByID(contractID: number, materialID: number): Promise<boolean> {
		return await this.next.deleteMaterialExpensesByID(contractID, materialID);
	}
	public async deleteTravelExpensesByID(contractID: number, travelID: number): Promise<boolean> {
		return await this.next.deleteTravelExpensesByID(contractID, travelID);
	}
	public async deleteToolExpensesByID(contractID: number, toolID: number): Promise<boolean> {
		return await this.next.deleteToolExpensesByID(contractID, toolID);
	}
	public async openCloseContract(contractID: number, status: ContractStatusType): Promise<boolean> {
		return await this.next.openCloseContract(contractID, status);
	}
	public async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
		return await this.next.changePassword(oldPassword, newPassword);
	}
	public async getAllQAIsByTeamLeader(teamLeaderID: number): Promise<User[]> {
		return await this.next.getAllQAIsByTeamLeader(teamLeaderID);
	}
	public async aprroveInput(contractID: number, inputID: number, zoneID?: number): Promise<boolean> {
		return await this.next.aprroveInput(contractID, inputID, zoneID);
	}
	public async updateContract(contract: Contract): Promise<boolean> {
		return await this.next.updateContract(contract);
	}
	public async checkTrackingTime(): Promise<WorkTimeTrack | undefined> {
		return await this.next.checkTrackingTime();
	}
	public async getTeamLeaderByContract(contractID: number): Promise<User | undefined> {
		return await this.next.getTeamLeaderByContract(contractID);
	}
	public async getCustomerByContract(contractID: number): Promise<Customer | undefined> {
		return await this.next.getCustomerByContract(contractID);
	}
	public async acceptPrivacyPolicy(): Promise<boolean> {
		return await this.next.acceptPrivacyPolicy();
	}
	public async getContract(contractID: number): Promise<Contract | undefined> {
		return await this.next.getContract(contractID);
	}
	public async getStandardCaseInputByID(
		contractID: number,
		errorInputID: number
	): Promise<StandardCaseInput | undefined> {
		return await this.next.getStandardCaseInputByID(contractID, errorInputID);
	}
	public async getUserByID(userID: number): Promise<User | undefined> {
		return await this.next.getUserByID(userID);
	}
	public async getCustomerByID(customerID: number): Promise<Customer | undefined> {
		return await this.next.getCustomerByID(customerID);
	}
	public async getTransportExpenses(contractID: number): Promise<Transport[]> {
		return await this.next.getTransportExpenses(contractID);
	}
	public async updateTransportExpenses(contractID: number, transport: Transport): Promise<boolean> {
		return await this.next.updateTransportExpenses(contractID, transport);
	}
	public async deleteTransportExpensesByID(contractID: number, transportID: number): Promise<boolean> {
		return await this.next.deleteTransportExpensesByID(contractID, transportID);
	}
	public async createTransportExpenses(contractID: number, transport: Transport): Promise<number> {
		return await this.next.createTransportExpenses(contractID, transport);
	}
	public async getContactQAI24ByContract(contractID: number): Promise<User | undefined> {
		return await this.next.getContactQAI24ByContract(contractID);
	}

	public async getRhenusCaseInputByID(errorID: number): Promise<RhenusCase | undefined> {
		return await this.next.getRhenusCaseInputByID(errorID);
	}
	public async getPhoto(photoURL: string): Promise<string | undefined> {
		return await this.next.getPhoto(photoURL);
	}
	public async deleteCustomerByID(contractID: number): Promise<boolean> {
		removeAllData();
		return await this.next.deleteCustomerByID(contractID);
	}

	public async createStandardErrorInputsExcelSheet(
		contractID: number,
		data: DashboardFilterInterface,
		language: string
	): Promise<string | undefined> {
		return await this.next.createStandardErrorInputsExcelSheet(contractID, data, language);
	}

	public async createProcessContractStandardErrorInputsExcelSheet(
		contractID: number,
		data: StandardCaseFilterInterface,
		language: string
	): Promise<string | undefined> {
		return await this.next.createProcessContractStandardErrorInputsExcelSheet(contractID, data, language);
	}

	public async createWorkingTimeExcelSheet(
		contractID: number,
		data: WorkingTimeFilterInterface,
		language: string
	): Promise<string | undefined> {
		return await this.next.createWorkingTimeExcelSheet(contractID, data, language);
	}

	public async contractSummary(contractID: number, fromDate: Moment | null, toDate: Moment | null): Promise<any> {
		return await this.next.contractSummary(contractID, fromDate, toDate);
	}
}

function getHoursDiffFromTimeAndCurrentTime(time: string) {
	const tempTime = moment(time);
	const currentTime = moment();
	const diff = Math.abs(currentTime.diff(tempTime));
	return diff / 1000 / 60;
}

function saveData(key: string, data: any) {
	localStorage.setItem(key, JSON.stringify({ lastPull: moment().toISOString(), data: data }));
}

function getData(key: string) {
	const localAllUsers = localStorage.getItem(key);
	if (localAllUsers) {
		const localAllUsersParsed: LocalData = JSON.parse(localAllUsers);
		if (getHoursDiffFromTimeAndCurrentTime(localAllUsersParsed.lastPull) < UPDATE_DIFF) {
			return localAllUsersParsed.data;
		}
	}
}
export function removeAllData() {
	const keys = [ALL_TEAM_LEADERS, ALL_USERS, ALL_QAIS, ALL_CUSTOMERS];
	keys.forEach((key) => localStorage.removeItem(key));
}

export default CacheMiddleware;
