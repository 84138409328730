import { AUTH_TOKEN, REFRESH_TOKEN } from '../context/auth/authReducer';
import service from '../service/service';
import User from '../service/types/User';
import { removeAllData } from '../serviceMiddlewares/cacheMiddleware';

export function onAuthTokenSuccess(at: string, setUser: (user: User | undefined) => void) {
	localStorage.setItem(AUTH_TOKEN, at);
	setUser(User.fromJWT(at));
	service.setJWTToken(at);
}

export function onAuthTokenError(setUser: (user: User | undefined) => void) {
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(REFRESH_TOKEN);
	removeAllData();
	setUser(undefined);
}
