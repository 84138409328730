import { KeyValue } from '../userHelper/generalUserHelper';

export enum PartStatusType {
	PartIO = 1,
	PartNIO = 2,
	PartRework = 3,
}

export const filterRhenusCasePartStatusKeyValuePairs: KeyValue[] = [
	{ key: ' ', value: '' },
	{ key: 'rhenus_part_status_part_io', value: PartStatusType.PartIO },
	{ key: 'rhenus_part_status_part_nio', value: PartStatusType.PartNIO },
	{ key: 'rhenus_part_status_part_rework', value: PartStatusType.PartRework },
];

export const createRhenusCasePartStatusKeyValuePairs: KeyValue[] = [
	{ key: 'rhenus_part_status_part_io', value: PartStatusType.PartIO },
	{ key: 'rhenus_part_status_part_nio', value: PartStatusType.PartNIO },
	{ key: 'rhenus_part_status_part_rework', value: PartStatusType.PartRework },
];

export const textsForRhenusAutomaticalUpdate = [
	'rhenus_update_data_text_now',
	'rhenus_update_data_text_one_minute',
	'rhenus_update_data_text_two_minutes',
	'rhenus_update_data_text_three_minutes',
	'rhenus_update_data_text_four_minutes',
	'rhenus_update_data_text_five_minutes',
];
