import { Service } from '../../service/service';
import Customer from '../../service/types/Customer';
import { customerFields } from '../../containers/CustomerContainer';
import { History, LocationState } from 'history';
import { replaceCustomerHistoryData } from '../navigationHelper';
import { createAddress } from './createCustomerHelper';

export function updateCustomer(
	data: any,
	service: Service,
	customer: Customer | undefined,
	history: History<LocationState>,
	setCustomer: (customer: Customer | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	emails: string[]
) {
	if (customer) {
		const updatedCustomer = getUpdatedCustomer(data, customer, emails);
		service
			.updateCustomerByID(customer.id, updatedCustomer)
			.then((res) => {
				if (res) {
					setIsUserEditing(false);
					setCustomer(updatedCustomer);
					replaceCustomerHistoryData(history, updatedCustomer);
				}
			})
			.catch(() => {});
	}
}

function getUpdatedCustomer(data: any, customer: Customer, emails: string[]) {
	return new Customer(
		customer.id,
		data[customerFields.name],
		createAddress('billingAddress', data),
		createAddress(
			data[customerFields.radio] ? 'billingAddress' : 'deliveryAddress',
			data,
			data[customerFields.deliveryAddress.costCenter!]
		),
		customer.users,

		data[customerFields.customerNumber],
		data[customerFields.supplierNumber],
		data[customerFields.invoiceEmail],
		data[customerFields.reminderEmail],
		emails,
		data[customerFields.vatNumber],
		data[customerFields.termOfPayment]
	);
}
