import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { StandardDialogInterface } from '../components/StandardDialog';
import ContactPersonScreen from '../screens/ContactPersonScreen';
import service from '../service/service';
import User from '../service/types/User';
import { onContactScreenMount, setContactPersonInfo } from '../helpers/contactPersonHelper/readContactHelper';
import { deleteContactPerson } from '../helpers/contactPersonHelper/deleteContactHelper';
import { createContactPerson } from '../helpers/contactPersonHelper/createContactHelper';
import { updateContactPerson } from '../helpers/contactPersonHelper/updateContactHelper';
import { useNotificationActionContext } from '../context/notification/useNotification';
import Spinner from '../components/Spinner';

export const contactPersonFields = {
	firstName: 'firstName',
	lastName: 'lastName',
	phonenumber: 'phonenumber',
	email: 'email',
	password: 'password',
};

function ContactPersonContainer() {
	const { control, handleSubmit, errors, setValue, setError } = useForm();
	const { setNotification } = useNotificationActionContext();
	const [isUserEditing, setIsUserEditing] = React.useState(false);
	const [mounted, setMounted] = React.useState(false);
	const [contactPerson, setContactPerson] = React.useState<User | undefined>(undefined);
	const [customerID, setCustomerID] = React.useState<number>();
	const deleteDialogRef = React.useRef<StandardDialogInterface | null>(null);
	const history = useHistory();
	const routeParams = useParams();

	React.useEffect(() => {
		onContactScreenMount(
			history,
			service,
			routeParams,
			setContactPerson,
			setIsUserEditing,
			setCustomerID,
			setMounted
		);
	}, [history, routeParams]);

	React.useEffect(() => {
		if (contactPerson) {
			setContactPersonInfo(contactPerson, setValue);
		}
	}, [contactPerson, setValue]);

	const onDeletePress = () => deleteDialogRef.current?.setDialogState(true);

	const onCancelDeleteDialogPress = () => deleteDialogRef.current?.setDialogState(false);

	const onDeleteContactPersonDialogPress = () =>
		deleteContactPerson(service, contactPerson, history, setNotification);

	const onEditPress = () => setIsUserEditing(true);

	const onCreatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (customerID) createContactPerson(data, service, customerID, history, setError);
			}),
		[handleSubmit, setError, customerID, history]
	);

	const onUpdatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				updateContactPerson(data, contactPerson, service, history, setContactPerson, setIsUserEditing);
			}),
		[contactPerson, history, handleSubmit]
	);

	const onCancelPress = () => {
		if (contactPerson) {
			setIsUserEditing(false);
			setContactPersonInfo(contactPerson, setValue);
		} else history.goBack();
	};

	const onSubmitPress = () => {
		if (contactPerson) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		} else {
			onCreatePress();
		}
	};

	if (!mounted) return <Spinner />;

	return (
		<ContactPersonScreen
			control={control}
			errors={errors}
			isUserEditing={isUserEditing}
			contactPerson={contactPerson}
			deleteDialogRef={deleteDialogRef}
			onSubmitPress={onSubmitPress}
			onDeleteContactPersonDialogPress={onDeleteContactPersonDialogPress}
			onCancelDeleteDialogPress={onCancelDeleteDialogPress}
			onDeletePress={onDeletePress}
			onCancelPress={onCancelPress}
		/>
	);
}

export default ContactPersonContainer;
