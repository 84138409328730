import { TableCellType } from '../components/table/StandardTableCell';
import { CustomerInterface } from '../service/types/Customer';
import { TableParamInterface } from './rhenusOverview';

interface CustomerParamInterface extends CustomerInterface {
	customerNumber: TableParamInterface;
	name: TableParamInterface;
	billingAddressGenerated: TableParamInterface;
	deliveryAddressGenerated: TableParamInterface;
	edit: TableParamInterface;
}

export const customerListParams: CustomerParamInterface = {
	customerNumber: {
		width: '6.15vw',
		title: 'customer_overview_customer_number',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	name: {
		width: '13.32vw',
		title: 'customer_overview_screen_name',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	billingAddressGenerated: {
		width: '29.11vw',
		title: 'customer_overview_screen_billing',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	deliveryAddressGenerated: {
		width: '28vw',
		title: 'customer_overview_screen_delivery',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	edit: {
		title: '',
		width: '6.15vw',
		type: TableCellType.Link,
		titleType: TableCellType.Title,
	},
};
