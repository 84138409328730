import * as React from 'react';

function RemoveIcon(props: any) {
	return (
		<svg width={17} height={17} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g transform="translate(1.5 1.5)" stroke="#111" fill="none" fillRule="evenodd">
				<circle strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" cx={7} cy={7} r={7} />
				<g strokeLinecap="square" strokeWidth={1.25}>
					<path d="M9.5 4.5l-5 5M4.5 4.5l5 5" />
				</g>
			</g>
		</svg>
	);
}

export default RemoveIcon;
