import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
	CustomerFilterKeys,
	customerOverviewFields,
	initCustomerFilters,
} from '../helpers/customerHelper/generalCustomerHelper';

import { filterData } from '../helpers/filters';
import { goToCustomer } from '../helpers/navigationHelper';
import CustomerOverviewScreen from '../screens/CustomerOverviewScreen';
import service from '../service/service';
import Customer, { CustomerInterface } from '../service/types/Customer';

function CustomerOverviewContainer() {
	const { control, setValue } = useForm();
	const [customers, setCustomers] = React.useState<Customer[]>([]);
	const [filters, setFilters] = React.useState<CustomerInterface>(initCustomerFilters);
	const allCustomersRef = React.useRef<Customer[]>([]);
	const history = useHistory();

	const onFilterChange = (key: CustomerFilterKeys, value: any) => {
		setFilters((prev) => ({ ...prev, [key]: value }));
	};

	const clearAllFilters = () => {
		Object.values(customerOverviewFields).forEach((val) => setValue(val, ''));
		setFilters(initCustomerFilters);
	};

	const onNewContractScreenPress = () => goToCustomer(history);
	const onEditPress = (customer: Customer) => goToCustomer(history, customer);

	React.useEffect(() => {
		setCustomers(filterData(filters, allCustomersRef.current));
	}, [filters]);

	React.useEffect(() => {
		service
			.getAllCustomers()
			.then((customers) => {
				setCustomers(customers);
				allCustomersRef.current = customers;
			})
			.catch(() => {});
	}, []);

	return (
		<CustomerOverviewScreen
			filters={filters}
			customers={customers}
			control={control}
			onEditPress={onEditPress}
			onFilterChange={onFilterChange}
			onNewContractPress={onNewContractScreenPress}
			clearAllFilters={clearAllFilters}
		/>
	);
}

export default CustomerOverviewContainer;
