import { History, LocationState } from 'history';
import { RouteParams } from '../../router/Routes';
import service from '../../service/service';
import Contract from '../../service/types/Contract';
import { ContractType } from '../contractHelper/generalContractHelper';
import { ContractNavigationInterface, goToHome } from '../navigationHelper';
import moment from 'moment';

export function onDashboardScreenMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setContract: (customer: Contract | undefined) => void
) {
	const historyState = history.location.state as ContractNavigationInterface | undefined;

	if (historyState && historyState.contract) {
		setContract(Contract.fromJSON(JSON.parse(historyState.contract)));
	} else if (routeParams.contract_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				setContract(res);
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		goToHome(history);
	}
}

export async function getErrorService(contract: Contract) {
	if (contract.contractType === ContractType.Standard) {
		return await service.getAllStandardCaseInputs(contract.id).catch(() => {});
	} else {
		return await service.getRhenusOverviewInformations(contract.id, contract.date, moment()).catch(() => {});
	}
}
