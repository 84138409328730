import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../consts/globalStyles';
import Customer, { CustomerInterface } from '../service/types/Customer';
import HomeButton from '../svgComponents/HomeButton';
import StandardTextField from '../components/StandardTextField';
import { checkIfUserStopWriting } from '../helpers/filters';
import CustomerList from '../components/CustomerList';
import NewLink from '../components/NewLink';
import Filter from '../components/Filter';
import { Control, Controller } from 'react-hook-form';
import { customerOverviewFields } from '../helpers/customerHelper/generalCustomerHelper';
import IQRLogo from '../svgComponents/IQRLogo';
import { CustomerFilterKeys, initCustomerFilters } from '../helpers/customerHelper/generalCustomerHelper';
import { Routes } from '../router/Routes';

interface Props {
	customers: Customer[];
	filters: CustomerInterface;
	control: Control<Record<string, any>>;
	onFilterChange: (key: CustomerFilterKeys, value: any) => void;
	onNewContractPress: () => void;
	onEditPress: (customer: Customer) => void;
	clearAllFilters: () => void;
}

function CustomerOverviewScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const globalClasses = useGlobalStyles({ showFilters });
	const classes = useStyles({});
	const { t } = useTranslation();
	const filterListRef = React.useRef<any>(null);
	const searchRef = React.useRef<CustomerInterface>({ ...initCustomerFilters });

	const onFilterPress = () => setShowFilters((prev) => !prev);

	return (
		<div className={globalClasses.container}>
			<IQRLogo className={globalClasses.logo} />
			<div className={globalClasses.homeButtonWrapper}>
				<div />
				<HomeButton />
			</div>
			<div className={classes.row}>
				<div className={classes.titleWrapper}>
					<span className={globalClasses.title}>{t('customer_overview_screen_title')}</span>
				</div>
				<NewLink route={Routes.Customer} text={'customer_overview_screen_add_new'} />
			</div>
			<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />
			<div
				className={globalClasses.filterWrapper}
				style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
				<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
					<Grid item sm={2} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.name}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_overview_screen_name')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'name');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={5} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.billingAddressGenerated}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_overview_screen_billing')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(
											e,
											props.onFilterChange,
											searchRef,
											'billingAddressGenerated'
										);
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={5} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.deliveryAddressGenerated}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_overview_screen_delivery')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(
											e,
											props.onFilterChange,
											searchRef,
											'deliveryAddressGenerated'
										);
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.country}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_address_country')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'country');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.city}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_address_city')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'city');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={2} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.postcode}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_address_postcode')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'postcode');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={4} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.street}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_address_street')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'street');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={2} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.houseNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_address_house_number')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'houseNumber');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={customerOverviewFields.costCenter}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('customer_address_cost_center')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'costCenter');
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</div>
			<CustomerList customers={props.customers} onEditPress={props.onEditPress} />
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '12px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));

export default CustomerOverviewScreen;
