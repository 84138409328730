import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import WorkingTimeIcon from '../svgComponents/WorkingTimeIcon';
import ExpensessIcon from '../svgComponents/ExpensessIcon';
import { globalInputStyle } from '../consts/globalStyles';

interface Props {
	onWorkingTimePress: () => void;
	onMaterialAndExpensses: () => void;
}

function WorkinTimeMaterialExpensess(props: Props) {
	const classes = useStyles({});
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<div className={classes.wrapper} onClick={props.onWorkingTimePress}>
				<WorkingTimeIcon />
				<div className={classes.linkWrapper}>
					<span className={classes.link}>{t('working_time_title')}</span>
				</div>
			</div>
			<div className={classes.wrapper} onClick={props.onMaterialAndExpensses}>
				<ExpensessIcon />
				<div className={classes.linkWrapper}>
					<span className={classes.link}>{t('material_and_expensess')}</span>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	container: { display: 'flex', alignItems: 'center', marginBottom: '36px' },
	wrapper: { display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '28px' },
	link: {
		...globalInputStyle(),
		textDecoration: 'underline',
	},
	linkWrapper: {
		marginLeft: '8px',
	},
}));

export default WorkinTimeMaterialExpensess;
