import { customerFields } from '../../containers/CustomerContainer';

export const initCustomerFilters = {
	name: '',
	deliveryAddressGenerated: '',
	billingAddressGenerated: '',
	postcode: '',
	country: '',
	city: '',
	houseNumber: '',
	costCenter: '',
	street: '',
};

const data = Object.freeze({ ...initCustomerFilters });
export type CustomerFilterKeys = keyof typeof data;

export const customerOverviewFields = {
	...customerFields,
	billingAddress: '',
	deliveryAddress: '',
	postcode: 'postcode',
	country: 'country',
	city: 'city',
	houseNumber: 'houseNumber',
	costCenter: 'costCenter',
	street: 'street',
};
