import moment, { Moment } from 'moment';

export enum TransportType {
	PKW = 1,
	LKW = 2,
	Transporter = 3,
	Spedition = 4,
}

class Transport {
	id: number;
	type: TransportType;
	reporter: number;
	qai: number;
	date: Moment;
	billed: boolean;

	constructor(id: number, type: TransportType, reporter: number, qai: number, date: Moment, billed: boolean) {
		this.id = id;
		this.type = type;
		this.reporter = reporter;
		this.qai = qai;
		this.date = date;
		this.billed = billed;
	}

	public static fromJSON(maybe: any): Transport {
		if (!maybe) {
			throw new Error('must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw new Error('id must be number');
		}
		if (typeof maybe.type !== 'number') {
			throw new Error('name must be string');
		}
		if (typeof maybe.reporter !== 'number') {
			throw new Error('reporter must be number');
		}
		if (typeof maybe.qai !== 'number') {
			throw new Error('qai must be number');
		}
		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}

		return new Transport(maybe.id, maybe.type, maybe.reporter, maybe.qai, date, maybe.billed);
	}

	public static createTransportWithID(id: number, withoutIDTravel: Transport) {
		return new Transport(
			id,
			withoutIDTravel.type,
			withoutIDTravel.reporter,
			withoutIDTravel.qai,
			withoutIDTravel.date,
			withoutIDTravel.billed
		);
	}

	public static toJSON(transport: Transport) {
		return {
			id: transport.id,
			type: transport.type,
			reporter: transport.reporter,
			qai: transport.qai,
			date: transport.date.toISOString(),
			billed: transport.billed,
		};
	}
}

export default Transport;
