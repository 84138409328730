import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRole } from '../service/types/User';
import { Link } from 'react-router-dom';
import { useGlobalStyles } from '../consts/globalStyles';
import { colors } from '../consts/colors';
import { useAuthStateContext } from '../context/auth/useAuth';
import { Routes } from '../router/Routes';

interface Props {
	name: string;
	imageSource: JSX.Element;
	route: Routes;
	accessRoles: UserRole[];
}

function MenuItem({ name, imageSource, route, accessRoles }: Props) {
	const globalClasses = useGlobalStyles({ description: colors.gray });
	const classes = useStyles({});
	const { user } = useAuthStateContext();
	const { t } = useTranslation();

	if (accessRoles.some((role) => user?.role === role))
		return (
			<Grid item sm={2}>
				<Link to={route}>
					<div className={classes.imageWarpper}>{imageSource}</div>
					<div className={classes.nameWrapper}>
						<span className={globalClasses.description}>{t(name)}</span>
					</div>
				</Link>
			</Grid>
		);
	return null;
}

const useStyles = makeStyles(() => ({
	imageWarpper: {
		marginBottom: '12px',
		cursor: 'pointer',
	},
	nameWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
}));

export default MenuItem;
