import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getExpensesData, onExpensesMount } from '../helpers/expenses/readExpensesHelper';
import { ExpensesNavigationInterface } from '../helpers/navigationHelper';
import {
	ExpensesFilterInterface,
	initExpensesFilters,
	ExpensesFilterKeys,
	Expenses,
	expensesFilterFields,
	ExpensesKeys,
} from '../helpers/expenses/generalExpensesHelper';
import ExpensesScreen from '../screens/ExpensesScreen';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import service from '../service/service';
import { UserRole } from '../service/types/User';
import { useAuthStateContext } from '../context/auth/useAuth';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { filterData } from '../helpers/filters';
import { FormProvider, useForm } from 'react-hook-form';
import MaterialTool from '../service/types/MaterialTool';
import Travel from '../service/types/Travel';
import { createExpense, createExpensesData } from '../helpers/expenses/createExpensesHelper';
import { updateExpensesData, getUpdateExpense } from '../helpers/expenses/updateExpensesHelper';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import { deleteExpensesData } from '../helpers/expenses/deleteExpensesHelper';
import { RouteParams } from '../router/Routes';
import Transport from '../service/types/Transport';
import { IntervalDatePickerDates } from '../components/IntervalDatePicker';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IContractWorkLocation } from '../service/types/Contract';
import { useTranslation } from 'react-i18next';

export interface IExpensesContractData extends ExpensesNavigationInterface {
	workLocations: IContractWorkLocation[];
}

function ExpensesContainer() {
	const [tabType, setTabType] = React.useState<ExpensesKeys>('materials');
	const [filters, setFilters] = React.useState<ExpensesFilterInterface>(initExpensesFilters);
	const [expenses, setExpenses] = React.useState<Expenses>({
		materials: [],
		tools: [],
		travels: [],
		transport: [],
		invoice: [],
	});
	const [allUsers, setAllUsers] = React.useState<KeyValue[]>([]);
	const history = useHistory();
	const methods = useForm();
	const { t } = useTranslation();

	const { user } = useAuthStateContext();
	const [contractData, setContractData] = React.useState<IExpensesContractData>({
		contractNumber: '',
		contractID: 0,
		workLocations: [],
	});
	const allExpensesRef = React.useRef<Expenses>({
		materials: [],
		tools: [],
		travels: [],
		transport: [],
		invoice: [],
	});
	const filterNameRef = React.useRef({
		materials: '',
		tools: '',
		travels: '',
		workingTime: '',
		transport: '',
		invoice: '',
	});
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const deleteExpenseID = React.useRef<number>(0);
	const routeParams: RouteParams = useParams();
	const [intervalDates, setIntervalDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const onFilterChange = (key: ExpensesFilterKeys, value: string) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));

		if (key === 'name') {
			if (tabType !== 'travels' && tabType !== 'transport') {
				filterNameRef.current[tabType] = tempValue;
			}
		}
	};

	React.useEffect(() => {
		setExpenses({
			...allExpensesRef.current,
			[tabType]: filterData(filters, allExpensesRef.current[tabType]),
		});
	}, [filters, tabType]);

	const clearAllFilters = () => {
		Object.values(expensesFilterFields).forEach((val) => methods.setValue(val, ''));
		setFilters(initExpensesFilters);
	};

	React.useEffect(() => {
		onExpensesMount(history, routeParams, setContractData);
	}, [history, routeParams]);

	React.useEffect(() => {
		methods.setValue(expensesFilterFields.name, filterNameRef.current[tabType]);
		setFilters((filters) => ({ ...filters, name: filterNameRef.current[tabType] }));
		if (allExpensesRef.current[tabType].length === 0 && contractData.contractID) {
			getExpensesData(tabType, contractData.contractID)
				.then((res: MaterialTool[] | Travel[] | Transport[]) => {
					allExpensesRef.current = { ...allExpensesRef.current, [tabType]: res };
					setExpenses({ ...allExpensesRef.current, [tabType]: res });
				})
				.catch(() => {});
		}
	}, [tabType, contractData, methods.setValue]);

	React.useEffect(() => {
		getUserOptionsKeyValuePairs(
			user?.role === UserRole.QualityAssuranceInspector ? service.getAllQAIs() : service.getAllUsers(),
			() => {}
		)
			.then((users) => {
				const tempUsers = users.filter((user) => user.value.role !== UserRole.Customer && !user.value.deleted);
				setAllUsers(tempUsers);
			})
			.catch(() => {});
	}, [user]);

	const addExpense = async (data: any) => {
		const expense = createExpense(tabType, user!, data);

		const res = await createExpensesData(tabType, contractData.contractID, expense);
		if (res) {
			setExpenses((expenses) => {
				const newExpenses = { ...expenses, [tabType]: [res, ...expenses[tabType]] };
				allExpensesRef.current = newExpenses;
				return newExpenses;
			});
		}
		return res;
	};

	const updateExpense = async (expense: MaterialTool | Travel | Transport, data: any) => {
		const newExpense = getUpdateExpense(tabType, expense, data);

		const res = await updateExpensesData(tabType, contractData.contractID, newExpense);
		if (res) {
			setExpenses((expenses) => {
				const expenseIndex = expenses[tabType].findIndex(
					(item: MaterialTool | Travel | Transport) => item.id === newExpense.id
				);
				expenses[tabType][expenseIndex] = newExpense;
				allExpensesRef.current = { ...expenses };
				return { ...expenses };
			});
		}
		return res;
	};

	const openDeleteDialog = (expenseID: number) => {
		dialogRef.current?.setDialogState(true);
		deleteExpenseID.current = expenseID;
	};

	const closeDeleteDialog = () => {
		dialogRef.current?.setDialogState(false);
	};

	const deleteExpense = () => {
		deleteExpensesData(tabType, contractData.contractID, deleteExpenseID.current)
			.then((res) => {
				if (res) {
					dialogRef.current?.setDialogState(false);
					setExpenses((expenses) => {
						const newExpenses = {
							...expenses,
							[tabType]: expenses[tabType].filter(
								(item: MaterialTool | Travel) => item.id !== deleteExpenseID.current
							),
						};
						allExpensesRef.current = newExpenses;
						return newExpenses;
					});
				}
			})
			.catch(() => {});
	};

	const onDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);

			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	return (
		<div>
			<FormProvider {...methods}>
				<ExpensesScreen
					intervalDates={intervalDates}
					expenses={expenses}
					allUsers={allUsers}
					contractID={contractData.contractID}
					contractNumber={contractData.contractNumber}
					tabType={tabType}
					filters={filters}
					workLocations={contractData.workLocations}
					onDeletePress={openDeleteDialog}
					onUpdatePress={updateExpense}
					routeParams={routeParams}
					onAddPress={addExpense}
					clearAllFilters={clearAllFilters}
					onTabPress={setTabType}
					onFilterChange={onFilterChange}
					onKeyboardDateChange={onKeyboardDateChange}
					onDateChange={onDateChange}
					onDatesAccept={onDatesAccept}
					onDatesCancel={onDatesCancel}
					clearDates={clearDates}
				/>
				<StandardDialog
					acceptText={'expenses_screen_delete_dialog_accept_button'}
					description={'expenses_screen_delete_dialog_description'}
					title={'expenses_screen_delete_dialog_title'}
					cancleText={'expenses_screen_delete_dialog_cancel_button'}
					onCancle={closeDeleteDialog}
					onAccept={deleteExpense}
					ref={dialogRef}
					showWarning
				/>
			</FormProvider>
		</div>
	);
}

export default ExpensesContainer;
