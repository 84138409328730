import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import { Routes } from '../router/Routes';
import Plus from '../svgComponents/Plus';

interface Props {
	text: string;
	route?: Routes;
	onClick?: () => void;
}

function NewLink({ text, route, onClick }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			{route ? (
				<Link className={classes.newWrapper} to={route}>
					<Plus />
					<span className={classes.new}>{t(text)}</span>
				</Link>
			) : (
				<div className={classes.newWrapper} onClick={onClick}>
					<Plus />
					<span className={classes.new}>{t(text)}</span>
				</div>
			)}
		</>
	);
}

const useStyles = makeStyles(() => ({
	newWrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	new: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
		textDecoration: 'underline',
		marginLeft: '8px',
	},
}));

export default NewLink;
