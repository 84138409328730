import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FileInterface } from '../helpers/contractHelper/generalContractHelper';
import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';

interface Props {
	value: FileInterface;
}

export const SPLITTER = '__';
const FILE_LOCATION_NAME = '/contract-attachments/';

function FileLink(props: Props) {
	const classes = useStyles({ disabled: props.value.data ? true : false });

	const name = React.useMemo(() => {
		return showFormatedName(props.value.name);
	}, [props.value]);

	return !props.value.data && props.value.name ? (
		<a className={classes.pdf} href={FILE_LOCATION_NAME + props.value.name} download={name}>
			{name}
		</a>
	) : (
		<span className={classes.pdf}>{name}</span>
	);
}

interface StyleProps {
	disabled: boolean;
}

const useStyles = makeStyles(() => ({
	pdf: (p: StyleProps) => ({
		cursor: p.disabled ? 'default' : 'pointer',
		color: colors.darkGray,
		fontSize: '14px',
		lineHeight: '20px',
		textDecoration: 'underline',
		fontFamily: fonts.regular,
		width: 'fit-content',
	}),
}));

function showFormatedName(name: string) {
	if (name) {
		const splittedName = name.split(SPLITTER);
		return splittedName.length > 1 ? splittedName[1] : splittedName[0];
	}
	return '';
}

export default FileLink;
