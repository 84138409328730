import React from 'react';

import { customerListParams } from '../consts/customer';
import { dataSorter } from '../helpers/dataSorter';
import Customer from '../service/types/Customer';
import { Sort } from './ContractList';
import CustomTableBody from './CustomTableBody';

interface Props {
	customers: Customer[];
	onEditPress: (customer: Customer) => void;
}

function CustomerList(props: Props) {
	const [sort, setSort] = React.useState<Sort>({
		field: undefined,
		direction: undefined,
	});

	const sortedData = React.useMemo(() => {
		if (sort.field === undefined) return props.customers;
		return dataSorter({ data: props.customers, sort });
	}, [props.customers, sort]);

	return (
		<CustomTableBody
			data={sortedData}
			dataTablePramas={customerListParams}
			displaySort
			sort={sort}
			tableKey={`Customer`}
			onLinkClick={props.onEditPress}
			setSort={setSort}
		/>
	);
}

export default CustomerList;
