import { JSONRPCClient } from 'jsonrpc2-client/lib';
import Axios, { AxiosResponse } from 'axios';

import ErrorMiddleware from '../serviceMiddlewares/errorMiddleware';
import CacheMiddleware from '../serviceMiddlewares/cacheMiddleware';
import Config from '../config';
import Client from './client';
import User, { CreateEmployeeParams, UpdateEmployeeParams } from './types/User';
import NotImplementedService from './notImplemented';
import { REFRESH_TOKEN } from '../context/auth/authReducer';
import { onAuthTokenError, onAuthTokenSuccess } from '../helpers/authHelper';
import Contract from './types/Contract';
import { QAILoginParams, ResetPasswordParams, UserLoginParams } from '../helpers/userHelper/generalUserHelper';
import Customer from './types/Customer';
import StandardCaseInput, { StandardCaseFilterInterface } from './types/StandardCase';
import { ContactParams } from '../containers/ContactUsContainer';
import RhenusOverviewInformation from './types/RhenusOverviewInformation';
import RhenusCase from './types/RhenusCase';
import WorkingTime from './types/WorkingTime';
import MaterialTool from './types/MaterialTool';
import Travel from './types/Travel';
import { ContractStatusType, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import WorkTimeTrack from './types/WorkTimeTrack';
import { LoginResponse } from '../consts/login';
import SuccessMiddleware from '../serviceMiddlewares/successMiddleware';
import { NotificationInterface } from '../context/notification/notificationReducer';
import Transport from './types/Transport';
import { Moment } from 'moment';
import BreakTime from './types/BreakTime';
import { DashboardFilterInterface } from '../helpers/dashboardHelper/generalDashboardHelper';
import { WorkingTimeFilterInterface } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { InvoiceItem } from './types/Invoice';
import { CreateGroup, UpdateGroup } from '../pages/GroupCreatePage';
import { Group } from '../pages/groupOverview/GroupOverview';

export const services = ['auth', 'contract'];

let service: ErrorMiddleware = new ErrorMiddleware(new NotImplementedService());

export interface Instances {
	auth: JSONRPCClient;
	contract: JSONRPCClient;
}

export function createServiceWithConfigAndToken(
	config: Config,
	token: string,
	setUser: (user: User | undefined) => void,
	setError: (error: string) => void,
	setNotification: (notification: NotificationInterface) => void
): Service {
	const instances: any = {};
	const AxiosInstance = Axios.create({ timeout: config.getTimeout() * 1000 });

	AxiosInstance.interceptors.response.use(async (response: AxiosResponse<any>) => {
		if (response.data?.error?.code === -32006) {
			const refreshToken = localStorage.getItem(REFRESH_TOKEN);
			if (refreshToken) {
				const at = await service.getAuthToken(refreshToken).catch(() => onAuthTokenError(setUser));
				if (at) {
					onAuthTokenSuccess(at, setUser);
					service.setJWTToken(at);
					const rq = {
						url: response.config.url,
						headers: { 'Authorization-JWT': at },
						method: response.config.method,
						data: response.config.data,
					};
					return await Axios.request(rq);
				} else {
					onAuthTokenError(setUser);
					return response;
				}
			} else {
				onAuthTokenError(setUser);
				return response;
			}
		}
		return response;
	});

	services.forEach(
		(item) =>
			(instances[item] = new JSONRPCClient(
				config.getBackendScheme() + config.getBackend() + item + '/' + config.getBackendVersion(),
				token ? token : '',
				//@ts-ignore
				AxiosInstance
			))
	);
	const client = new Client(instances);
	const cacheMiddleware = new CacheMiddleware(client);
	const errorMiddleware = new ErrorMiddleware(cacheMiddleware, setError);
	const successMiddleware = new SuccessMiddleware(errorMiddleware, setNotification);

	return successMiddleware;
}

interface AuthService {
	login(params: UserLoginParams): Promise<LoginResponse | undefined>;
	loginQAI(params: QAILoginParams): Promise<LoginResponse | undefined>;
	logout(): Promise<boolean>;
	contact(params: ContactParams): Promise<boolean>;
	sendErrorReport(message: string): Promise<boolean>;
	acceptPrivacyPolicy(): Promise<boolean>;

	checkToken(): Promise<boolean>;
	getAuthToken(refreshToken: string): Promise<string | undefined>;

	requestPasswordReset(email: string): Promise<boolean>;
	checkPasswordRessetToken(token: string): Promise<boolean>;
	resetPassword(params: ResetPasswordParams): Promise<boolean>;
	resetPasswordQAI(params: ResetPasswordParams): Promise<boolean>;
	changePassword(oldPassword: string, newPassword: string): Promise<boolean>;

	getAllUsers(): Promise<User[]>;
	getAllQAIs(): Promise<User[]>;
	getAllTeamLeaders(): Promise<User[]>;
	getAllQAIsByTeamLeader(teamLeaderID: number): Promise<User[]>;
	getUserInfo(): Promise<User>;
	createEmployee(params: CreateEmployeeParams): Promise<boolean>;
	createQAI(params: CreateEmployeeParams): Promise<boolean>;
	updateUserByID(id: number, params: UpdateEmployeeParams): Promise<boolean>;
	deleteUserByID(id: number): Promise<boolean>;
	getUserByID(userID: number): Promise<User | undefined>;

	getAllCustomers(): Promise<Customer[]>;
	getCustomerFromUser(id: number): Promise<Customer>;
	createCustomer(customer: Customer): Promise<number>;
	createUserForCustomer(id: number, params: CreateEmployeeParams): Promise<number>;
	updateCustomerByID(id: number, customer: Customer): Promise<boolean>;
	getCustomerByID(customerID: number): Promise<Customer | undefined>;
	deleteCustomerByID(customerID: number): Promise<boolean>;

	readGroups(): Promise<Group[]>;
	createGroup(data: CreateGroup): Promise<boolean>;
	readGroup(id: number): Promise<Group>;
	updateGroup(data: UpdateGroup): Promise<boolean>;
	deleteGroup(id: number): Promise<boolean>;
}

interface ProcessContractInterface {
	getAllStandardCaseInputs(contractID: number): Promise<StandardCaseInput[]>;
	updateStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean>;
	createStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean>;
	deleteStandradCaseInputByID(contractID: number, errorInputID: number): Promise<boolean>;
	getStandardCaseInputByID(contractID: number, errorInputID: number): Promise<StandardCaseInput | undefined>;

	getRhenusOverviewInformations(contractID: number, from: Moment, to: Moment): Promise<RhenusOverviewInformation[]>;
	getZoneCaseInputs(contractID: number, zoneId: number): Promise<RhenusCase[]>;
	createRhenusCaseInput(contractID: number, zoneID: number, rhenusCaseInput: RhenusCase): Promise<boolean>;
	updateRhenusCaseInput(contractID: number, zoneID: number, rhenusCaseInput: RhenusCase): Promise<boolean>;
	deleteRhenusCaseInputByID(contractID: number, zoneIDRef: number, errorInputID: number): Promise<boolean>;
	getRhenusCaseInputByID(errorInputID: number): Promise<RhenusCase | undefined>;

	aprroveInput(contractID: number, inputID: number, zoneID?: number): Promise<boolean>;
	getPhoto(photoURL: string): Promise<string | undefined>;
}

interface ContractManipulationInterface {
	getContracts(): Promise<Contract[]>;
	getContract(contractID: number): Promise<Contract | undefined>;
	createContract(contract: Contract): Promise<number>;
	deleteContractByID(contractID: number): Promise<boolean>;
	updateContract(contract: Contract): Promise<boolean>;
	openCloseContract(contractID: number, status: ContractStatusType): Promise<boolean>;
	createInvoice(contractID: number, dateOfInvoice: Moment, fromDate: Moment, toDate: Moment): Promise<number>;
	getAllInvoices(contractID: number): Promise<number>;
	getInvoiceById(invoiceID: number): Promise<InvoiceItem | undefined>;
	updateInvoice(
		id: number,
		creationDate: Moment,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment,
		billNumber: string
	): Promise<void>;
	completeInvoice(id: number): Promise<void>;
	deleteInvoice(id: number): Promise<void>;
	exportInvoice(id: number): Promise<string | undefined>;
	readShifts(contractID: number): Promise<ShiftInterface[]>;
}

interface WorkingTimeInterface {
	addManualWorkTime(contractID: number, worktime: WorkingTime): Promise<number>;
	addManualBreakTime(contractID: number, worktime: BreakTime): Promise<number>;
	startTrackingTime(contractID: number): Promise<boolean>;
	stopTrackingTime(contractID: number, profession: number | undefined): Promise<WorkingTime | undefined>;
	getAllRecords(contractID: number): Promise<(WorkingTime | BreakTime)[]>;
	deleteRecordByID(contractID: number, recordID: number): Promise<boolean>;
	deleteBreakByID(contractID: number, recordID: number): Promise<boolean>;
	deleteWaitingByID(contractID: number, recordID: number): Promise<boolean>;

	updateRecordByID(contractID: number, record: WorkingTime): Promise<boolean>;
	updateBreakByID(contractID: number, record: BreakTime): Promise<boolean>;
	updateWaitingByID(contractID: number, record: BreakTime): Promise<boolean>;

	checkTrackingTime(): Promise<WorkTimeTrack | undefined>;
}

interface ExpensesInterface {
	getMaterialExpenses(contractID: number): Promise<MaterialTool[]>;
	getTravelExpenses(contractID: number): Promise<Travel[]>;
	getToolExpenses(contractID: number): Promise<MaterialTool[]>;
	getTransportExpenses(contractID: number): Promise<Transport[]>;

	createMaterialExpenses(contractID: number, material: MaterialTool): Promise<number>;
	createTravelExpenses(contractID: number, travel: Travel): Promise<number>;
	createToolExpenses(contractID: number, tool: MaterialTool): Promise<number>;
	createTransportExpenses(contractID: number, tool: Transport): Promise<number>;

	updateMaterialExpenses(contractID: number, material: MaterialTool): Promise<boolean>;
	updateTravelExpenses(contractID: number, travel: Travel): Promise<boolean>;
	updateToolExpenses(contractID: number, tool: MaterialTool): Promise<boolean>;
	updateTransportExpenses(contractID: number, transport: Transport): Promise<boolean>;

	deleteMaterialExpensesByID(contractID: number, materialID: number): Promise<boolean>;
	deleteTravelExpensesByID(contractID: number, travelID: number): Promise<boolean>;
	deleteToolExpensesByID(contractID: number, toolID: number): Promise<boolean>;
	deleteTransportExpensesByID(contractID: number, transportID: number): Promise<boolean>;
}

interface DashboardInterface {
	getTeamLeaderByContract(contractID: number): Promise<User | undefined>;
	getCustomerByContract(contractID: number): Promise<Customer | undefined>;
	getContactQAI24ByContract(contractID: number): Promise<User | undefined>;
	createStandardErrorInputsExcelSheet(
		contractID: number,
		data: DashboardFilterInterface,
		language: string
	): Promise<string | undefined>;
	createProcessContractStandardErrorInputsExcelSheet(
		contractID: number,
		data: StandardCaseFilterInterface,
		language: string
	): Promise<string | undefined>;
	createWorkingTimeExcelSheet(
		contractID: number,
		data: WorkingTimeFilterInterface,
		language: string
	): Promise<string | undefined>;
	contractSummary(contractID: number, fromDate: Moment | null, toDate: Moment | null): Promise<any>;
}

export interface Service
	extends AuthService,
		ProcessContractInterface,
		ContractManipulationInterface,
		WorkingTimeInterface,
		ExpensesInterface,
		DashboardInterface {
	setJWTToken(JWTToken: string): void;
}

export default service;
