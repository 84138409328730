import { History, LocationState } from 'history';
import { RouteParams } from '../../router/Routes';
import service from '../../service/service';
import { goToHome } from '../navigationHelper';
import { ExpensesKeys } from './generalExpensesHelper';
import { IExpensesContractData } from '../../containers/ExpensesContainer';

export function onExpensesMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setContractData: (contractData: IExpensesContractData) => void
) {
	if (routeParams.contract_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				if (res)
					setContractData({
						contractID: res.id,
						contractNumber: res.contractNumber,
						workLocations: res.workLocations,
					});
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		goToHome(history);
	}
}

export async function getExpensesData(key: ExpensesKeys, contractID: number) {
	if (key === 'materials') {
		return await service.getMaterialExpenses(contractID);
	} else if (key === 'tools') {
		return await service.getToolExpenses(contractID);
	} else if (key === 'travels') {
		return await service.getTravelExpenses(contractID);
	} else {
		return await service.getTransportExpenses(contractID);
	}
}
