import { FileInterface } from '../../helpers/contractHelper/generalContractHelper';
import moment, { Moment } from 'moment';
import { PartStatusType } from '../../helpers/rhenusInputHelper/generalRhenusHelper';

export interface RhenusCaseFilterInterface {
	productionNumber: any;
	partStatus: any;
	storage: any;
	colorNumber: any;
	partNumber: any;
	qai: any;
	approved: any;
	typeOfInspection: any;
	errorType: any;
	shift: any;
	amountOfParts: any;
	fromDate?: any;
	toDate?: any;
}

export interface RhenusCaseTableInterface extends RhenusCaseFilterInterface {
	date: any;
	shift: any;
	comment?: any;
	photo?: any;
	partNumberShould: any;
	colorNumberShould: any;
}

class RhenusCase implements RhenusCaseTableInterface {
	id: number;
	date: Moment;
	shift: number;
	errorType: string;
	productionNumber: number;
	partNumber: string;
	partNumberShould: string;
	zone: number;
	storage: string;
	partStatus: PartStatusType;
	qai: number;
	approved: boolean;
	typeOfInspection: string;
	colorNumber: string;
	colorNumberShould: string;
	amountOfParts: string;
	photo?: FileInterface;
	comment?: string;

	constructor(
		id: number,
		date: Moment,
		shift: number,
		productionNumber: number,
		partNumber: string,
		zone: number,
		storage: string,
		partStatus: PartStatusType,
		qai: number,
		approved: boolean,
		typeOfInspection: string,
		errorType: string,
		partNumberShould: string,
		colorNumber: string,
		colorNumberShould: string,
		amountOfParts: string,
		comment?: string,
		photo?: FileInterface
	) {
		this.id = id;
		this.date = date;
		this.shift = shift;
		this.productionNumber = productionNumber;
		this.partNumber = partNumber.trim();
		this.zone = zone;
		this.storage = storage.trim();
		this.partStatus = partStatus;
		this.qai = qai;
		this.approved = approved;
		this.photo = photo;
		this.comment = comment ? comment.trim() : undefined;
		this.typeOfInspection = typeOfInspection.trim();
		this.errorType = errorType.trim();
		this.partNumberShould = partNumberShould.trim();
		this.colorNumber = colorNumber;
		this.colorNumberShould = colorNumberShould;
		this.amountOfParts = amountOfParts;
	}

	public static fromJSON(maybe: any): RhenusCase {
		if (!maybe) {
			throw Error('must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw Error('id must be number');
		}
		if (typeof maybe.production_number !== 'number') {
			throw Error('productionNumber must be number');
		}
		if (typeof maybe.part_number !== 'string') {
			throw Error('partNumber must be string');
		}
		if (typeof maybe.part_status !== 'number') {
			throw Error('partStatus must be number');
		}
		if (typeof maybe.storage !== 'string') {
			throw Error('storage must be string');
		}
		if (typeof maybe.qai_id !== 'number') {
			throw Error('qai must be number');
		}
		if (typeof maybe.type_of_inspection !== 'string') {
			throw Error('typeOfInspection must be number');
		}
		if (!maybe.zone_id) {
			throw Error('zone must exist');
		}
		if (!maybe.shift_id) {
			throw Error('shift must exist');
		}
		if (maybe.approved === undefined) {
			throw Error('approved must exist');
		}
		if (typeof maybe.part_number_should !== 'string') {
			throw Error('partNumberShould must be number');
		}
		if (typeof maybe.color_number !== 'string') {
			throw Error('colorNumber must be number');
		}
		if (typeof maybe.color_number_should !== 'string') {
			throw Error('colorNumberShould must be number');
		}
		if (typeof maybe.amount_of_parts !== 'string') {
			throw Error('amountOfParts must be number');
		}
		if (typeof maybe.error_type !== 'string') {
			throw Error('errorType must be string');
		}
		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}

		return new RhenusCase(
			maybe.id,
			date,
			maybe.shift_id,
			maybe.production_number,
			maybe.part_number,
			maybe.zone_id,
			maybe.storage,
			maybe.part_status,
			maybe.qai_id,
			maybe.approved,
			maybe.type_of_inspection,
			maybe.error_type,
			maybe.part_number_should,
			maybe.color_number,
			maybe.color_number_should,
			maybe.amount_of_parts,
			maybe.comment,
			maybe.photo && maybe.photo.name ? maybe.photo : undefined
		);
	}

	public static toJSON(rhenusCase: RhenusCase) {
		return {
			id: rhenusCase.id,
			date: rhenusCase.date.toISOString(),
			shift_id: rhenusCase.shift,
			production_number: rhenusCase.productionNumber,
			part_number: rhenusCase.partNumber,
			storage: rhenusCase.storage,
			part_status: rhenusCase.partStatus,
			qai_id: rhenusCase.qai,
			zone_id: rhenusCase.zone,
			approved: rhenusCase.approved,
			photo: rhenusCase.photo,
			comment: rhenusCase.comment,
			error_type: rhenusCase.errorType,
			type_of_inspection: rhenusCase.typeOfInspection,
			part_number_should: rhenusCase.partNumberShould,
			color_number: rhenusCase.colorNumber,
			color_number_should: rhenusCase.colorNumberShould,
			amount_of_parts: rhenusCase.amountOfParts,
		};
	}
}

export default RhenusCase;
