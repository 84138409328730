import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { ContractType } from '../helpers/contractHelper/generalContractHelper';
import { ContractNavigationInterface, goToHome } from '../helpers/navigationHelper';
import { RouteParams } from '../router/Routes';
import service from '../service/service';
import Contract from '../service/types/Contract';
import RhenusOverviewContainer from './RhenusOverviewContainer';
import StandardCaseOverviewContainer from './StandardCaseOverviewContainer';

function ProcessContractContainer() {
	const history = useHistory();
	const [contractData, setContractData] = React.useState<Contract>();
	const routeParams: RouteParams = useParams();

	React.useEffect(() => {
		const historyState = history.location.state as undefined | ContractNavigationInterface;
		if (historyState && historyState.contract) {
			const contract: Contract = Contract.fromJSON(JSON.parse(historyState.contract));
			setContractData(contract);
		} else if (routeParams.contract_id) {
			service
				.getContract(Number(routeParams.contract_id))
				.then((res) => {
					if (res) setContractData(res);
				})
				.catch((e) => {
					goToHome(history);
				});
		} else {
			goToHome(history);
		}
	}, [history, routeParams]);

	if (contractData) {
		if (contractData.contractType === ContractType.Standard) {
			return <StandardCaseOverviewContainer contractData={contractData} />;
		} else {
			return <RhenusOverviewContainer contractData={contractData} />;
		}
	}
	return <Spinner />;
}

export default ProcessContractContainer;
