import React from 'react';
import StandardTextField from './StandardTextField';
import { useTranslation } from 'react-i18next';
import { TableCellWithCss } from './table/StandardTableCell';
import { Control, Controller } from 'react-hook-form';
import { ExpenseTabelParamsInterface } from '../consts/material';
import { addExpenseFields } from './AddExpensesItem';
import { useGlobalStyles } from '../consts/globalStyles';
import { AddExpensesItemKeys } from './AddExpensesItem';
import { rules } from '../consts/rules';

interface Props {
	params: ExpenseTabelParamsInterface;
	fieldKey: AddExpensesItemKeys;
	errors: Record<string, any>;
	control: Control<Record<string, any>>;
	mainClass: string;
	wrapper: string;
	inputTextWrapper: string;
}

function AddExpensesItemStandard(props: Props) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	return (
		<TableCellWithCss
			className={props.mainClass}
			style={{
				width: props.params.width,
			}}>
			<Controller
				defaultValue={''}
				control={props.control}
				name={addExpenseFields[props.fieldKey]}
				rules={
					props.params.rules
						? { ...props.params.rules }
						: {
								required: rules(t).required,
						  }
				}
				render={({ onChange, value }) => (
					<StandardTextField
						label={''}
						value={value}
						placeholder={t(props.params.placeholder)}
						type={props.params.inputType}
						inputWrapper={props.inputTextWrapper}
						onChange={onChange}
						wrapper={props.wrapper}
						input={
							props.params.inputType || props.params.rightAlign
								? globalClasses.inputTableStyleNumber
								: globalClasses.inputTableStyle
						}
						error={props.errors[addExpenseFields[props.fieldKey]] ? true : false}
						helperText={
							props.errors[addExpenseFields[props.fieldKey]]
								? props.errors[addExpenseFields[props.fieldKey]].message
								: ''
						}
					/>
				)}
			/>
		</TableCellWithCss>
	);
}

export default AddExpensesItemStandard;
