import { AddExpenseItemCellType } from '../components/AddExpenseItemCell';
import { TableCellType } from '../components/table/StandardTableCell';
import { billedOptions, TravelOptions } from '../helpers/expenses/generalExpensesHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { IContractWorkLocation } from '../service/types/Contract';
import User from '../service/types/User';
import { ExpenseTabelInterface } from './material';

export function travelRules(regex: RegExp, t: (value: string) => string) {
	return {
		required: {
			value: true,
			message: t('field_required'),
		},
		pattern: {
			value: regex,
			message: t('expenses_screen_travel_table_pattern'),
		},
	};
}

export const travelTabelParams = (
	t: (value: string) => string,
	users: KeyValue[],
	user: User | undefined,
	workLocations: IContractWorkLocation[]
): ExpenseTabelInterface => ({
	travelTime: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_travel_table_name_title',
		width: '10.66vh',
		placeholder: 'expenses_screen_travel_table_name_placeholder',
		inputType: undefined,
		rightAlign: true,
		addType: AddExpenseItemCellType.Standard,
		rules: {
			...travelRules(/^([0-9]+h+\s?)|[0-9]+min$/, t),
		},
	},
	date: {
		titleType: TableCellType.Title,
		title: 'invoice_screen_creation_date',
		width: '15.66vh',
		placeholder: 'invoice_screen_creation_date',
		addType: AddExpenseItemCellType.Date,
		inputType: undefined,
		rightAlign: true,
	},
	waitedTime: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_travel_table_waited_time_title',
		width: '10.66vh',
		placeholder: 'expenses_screen_travel_table_name_placeholder',
		inputType: undefined,
		rightAlign: true,
		addType: AddExpenseItemCellType.Standard,
		rules: {
			...travelRules(/^([0-9]+h+\s?)|[0-9]+min$/, t),
		},
	},
	distance: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_travel_table_quantity_title',
		width: '9.54vh',
		placeholder: 'location',
		inputType: undefined,
		rightAlign: true,
		addType: AddExpenseItemCellType.KeyValue,
		options: workLocations
			? workLocations.map((location) => ({
					key: location.name,
					value: {
						id: `${location.distance}km`,
					},
			  }))
			: [],
		showAsValue: true,
	},
	typeOfTransport: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_travel_table_type_of_transport_title',
		width: '20.66vh',
		placeholder: 'expenses_screen_travel_table_type_of_transport_placeholder',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.KeyValue,
		options: TravelOptions,
	},
	reporter: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_reporter_title',
		width: '22.438vh',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
		value: `${user?.firstName} ${user?.lastName}`,
	},
	qai: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_qai_title',
		width: '22.414vh',
		placeholder: 'expenses_screen_name',
		options: users,
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.KeyValue,
	},
	billed: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_status',
		width: '15.66vh',
		placeholder: 'expenses_screen_status',
		inputType: undefined,
		rightAlign: false,
		options: billedOptions,
		addType: AddExpenseItemCellType.KeyValue,
	},
	edit: {
		titleType: TableCellType.Title,
		title: '',
		width: '50px',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
	},
});
