import { Dialog, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import { v4 as uuid } from 'uuid';
import { useGlobalStyles } from '../consts/globalStyles';
import { FileInterface } from '../helpers/contractHelper/generalContractHelper';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import PhotoButton from '../svgComponents/PhotoButton';
import Button from './Button';
import Transition from './Transition';

export interface CameraDialogInterface {
	setDialogState: (open: boolean, isPreview: boolean, image: FileInterface | undefined) => void;
}

interface Props {
	saveText?: string;
	cancelText?: string;
	description?: string;
	partNumber?: string;
	onChange?: (image: FileInterface) => void;
}

function CameraDialog({ saveText, cancelText, description, partNumber, onChange }: Props, ref: any) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);
	const [isPreview, setIsPreview] = React.useState(true);
	const [image, setImage] = React.useState<FileInterface>();
	const classes = useStyles({ photo: image });
	const webcamRef = React.useRef<Webcam | null>(null);

	const captureImage = () => {
		if (webcamRef.current) {
			const maybeImage = webcamRef.current.getScreenshot();
			if (maybeImage) setImage({ name: image?.name ? image.name : uuid(), data: maybeImage });
		}
	};

	const saveImage = () => {
		if (onChange) {
			if (image) {
				onChange(image);
				setIsOpen(false);
				setIsPreview(true);
			}
		}
	};

	const retakeImage = () => {
		setImage(undefined);
	};

	const cancel = () => {
		setIsOpen(false);
		setIsPreview(true);
	};

	React.useImperativeHandle(
		ref,
		(): CameraDialogInterface => ({
			setDialogState: (val: boolean, isPreview: boolean, image: FileInterface | undefined) => {
				setIsOpen(val);
				setIsPreview(isPreview);
				setImage(image);
			},
		})
	);
	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition} keepMounted onClose={cancel}>
			<div className={globalClasses.container}>
				<div className={globalClasses.homeButtonWrapper}>
					<BackArrow onBackArrowPress={cancel} />
					<HomeButton />
				</div>
				<Grid container className={classes.container} justify="center" alignItems="center">
					<Grid item sm={4} />
					<Grid item sm={4}>
						{isPreview ? null : (
							<div className={classes.descriptionWrapper}>
								<span className={globalClasses.description}>{t(description ? description : '')}</span>
							</div>
						)}
					</Grid>
					<Grid item sm={4} />
					<Grid item sm={3} />
					<Grid item sm={6} className={classes.itemWrapper}>
						{isPreview ? (
							<div>
								<div className={classes.previewPartWrapper}>
									<span className={globalClasses.smallTextLight}>
										{`${t('camera_dialog_part_number')}: ${partNumber}`}
									</span>
								</div>
								<div className={classes.previewTitleWrapper}>
									<span className={globalClasses.title}>{t('camera_dialog_preview_title')}</span>
								</div>
								<div className={classes.image} />
							</div>
						) : image ? (
							<div>
								<div className={classes.image} />
								<div className={classes.row}>
									<Button
										text={t(saveText ? saveText : '')}
										textClassName={globalClasses.buttonText}
										buttonWrapperClassName={globalClasses.buttonWrapper}
										onClick={saveImage}
									/>
									<Button
										text={t(cancelText ? cancelText : '')}
										textClassName={globalClasses.buttonTextInverted}
										buttonWrapperClassName={globalClasses.buttonWrapperInverted}
										onClick={retakeImage}
									/>
								</div>
							</div>
						) : (
							<div>
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									width={'100%'}
									videoConstraints={{ facingMode: { ideal: 'environment' } }}
								/>
								<div className={classes.photoWrapper}>
									<PhotoButton onClick={captureImage} className={classes.photo} />
								</div>
							</div>
						)}
					</Grid>
					<Grid item sm={3} />
				</Grid>
			</div>
		</Dialog>
	);
}

interface StyleProps {
	photo?: FileInterface;
}

const useStyles = makeStyles(() => ({
	itemWrapper: {
		marginTop: '32px',
	},
	previewTitleWrapper: {
		marginBottom: '40px',
		textAlign: 'center',
	},
	previewPartWrapper: {
		marginBottom: '4px',
		textAlign: 'center',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '40px 0px',
	},
	descriptionWrapper: {
		textAlign: 'center',
		marginTop: '20px',
	},
	container: { display: 'flex', flex: 1 },

	image: ({ photo }: StyleProps) => ({
		width: '100%',
		height: '60vh',
		backgroundImage: photo ? `url(${photo.data})` : undefined,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 60vh',
	}),
	photo: {
		margin: '24px 0 40px 0',
		cursor: 'pointer',
	},
	photoWrapper: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default React.forwardRef(CameraDialog);
