import * as React from 'react';

function PhotoButton(props: any) {
	return (
		<svg width={64} height={64} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="none" fillRule="evenodd">
				<circle fill="#E4E4E4" cx={32} cy={32} r={32} />
				<g transform="translate(21 22)" stroke="#111" strokeLinecap="square" strokeWidth={2}>
					<circle cx={11} cy={11} r={5} />
					<path d="M20 20H2a2 2 0 01-2-2V5a2 2 0 012-2h4l2-3h6l2 3h4a2 2 0 012 2v13a2 2 0 01-2 2z" />
				</g>
			</g>
		</svg>
	);
}

export default PhotoButton;
