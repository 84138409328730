import { customerFields } from '../../containers/CustomerContainer';
import Customer from '../../service/types/Customer';
import { History, LocationState } from 'history';
import { KeyValue } from '../userHelper/generalUserHelper';
import service from '../../service/service';
import { goToHome } from '../navigationHelper';
import { RouteParams } from '../../router/Routes';
import Address, { AddressInterface } from '../../service/types/Address';
import { AddressKeys } from './createCustomerHelper';

export function setCustomerInfo(customer: Customer, setValue: (field: string, val: any) => void) {
	setValue(customerFields.name, customer.name);
}

export function setAddressInfo(keys: AddressInterface, address: Address, setValue: (field: string, val: any) => void) {
	Object.keys(keys).forEach((key) => {
		//@ts-ignore
		if (keys[key]) setValue(keys[key], address[key]);
	});
}

export function onCustomerScreenMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setCustomer: (customer: Customer | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	setMounted: (val: boolean) => void
) {
	if (routeParams.customer_id) {
		service
			.getCustomerByID(Number(routeParams.customer_id))
			.then((res) => {
				if (res) {
					setCustomer({ ...res, id: Number(routeParams.customer_id) });
					setMounted(true);
				}
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		setIsUserEditing(true);
		setMounted(true);
		setCustomer(undefined);
	}
}

export async function getCustomerOptionsKeyValuePairs(
	data: Promise<Customer[]>,
	setLoading: (val: boolean) => void
): Promise<KeyValue[]> {
	setLoading(true);
	const users = await data.catch((e) => {
		setLoading(false);
	});

	setLoading(false);
	if (users)
		return users.map((customer) => ({
			value: customer,
			key: customer.name,
		}));
	return [];
}

export function watchFields(key: AddressKeys, watch: (name: string) => any): AddressInterface {
	return {
		postcode: watch(customerFields[key].postcode.toString()),
		country: watch(customerFields[key].country),
		city: watch(customerFields[key].city),
		houseNumber: watch(customerFields[key].houseNumber),
		street: watch(customerFields[key].street),
		costCenter: watch(customerFields[key].costCenter ? customerFields[key].costCenter! : ''),
	};
}

export function transformFields(data: AddressInterface) {
	let formatedAddress = '';
	const street = data.street ? data.street.trim() + ' ' : '';
	const houseNumber = data.houseNumber ? data.houseNumber : '';
	formatedAddress = street + houseNumber.trim();
	const postcode = data.postcode ? (formatedAddress ? ', ' : '') + data.postcode.toString().trim() + ' ' : '';
	const city = data.city ? (formatedAddress ? ', ' : '') + data.city : '';
	formatedAddress = formatedAddress + postcode + city.trim();
	const country = data.country ? (formatedAddress ? ', ' : '') + data.country + ' ' : '';
	formatedAddress = formatedAddress + country.trim();
	const costCenter = data.costCenter ? (formatedAddress ? ', ' : '') + data.costCenter : '';

	return formatedAddress + costCenter.trim();
}
