import moment from 'moment';

import StandardCaseInput from '../service/types/StandardCase';

export const getEarliestDate = (standardCaseInputs: StandardCaseInput[]) => {
	let earliestDate = moment();

	standardCaseInputs.forEach((el) => {
		if (el.deliveryNoteDate && el?.deliveryNoteDate < earliestDate) earliestDate = el.deliveryNoteDate;
	});

	return earliestDate;
};
