import React from 'react';
import { Control } from 'react-hook-form';
import { ExpenseTabelParamsInterface } from '../consts/material';
import { AddExpensesItemKeys } from './AddExpensesItem';
import AddExpensesItemKeyValue from './AddExpensesItemKeyValue';
import AddExpensesItemStandard from './AddExpensesItemStandard';
import AddExpensesItemStatic from './AddExpensesItemStatic';
import AddExpensesItemDate from './AddExpenseItemDate';
import AddExpensesItemEdit from './AddExpensesItemEdit';

export enum AddExpenseItemCellType {
	Standard,
	Static,
	KeyValue,
	Date,
	Edit,
}

interface Props {
	params: ExpenseTabelParamsInterface | undefined;
	fieldKey: AddExpensesItemKeys;
	errors: Record<string, any>;
	control: Control<Record<string, any>>;
	mainClass: string;
	wrapper: string;
	inputTextWrapper: string;
	inputKeyValueWrapper: string;
	textClass: string;
}

function AddExpenseItemCell(props: Props) {
	if (props.params)
		switch (props.params.addType) {
			case AddExpenseItemCellType.Standard:
				return (
					<AddExpensesItemStandard
						control={props.control}
						wrapper={props.wrapper}
						params={props.params}
						mainClass={props.mainClass}
						inputTextWrapper={props.inputTextWrapper}
						fieldKey={props.fieldKey}
						errors={props.errors}
					/>
				);

			case AddExpenseItemCellType.Edit:
				return (
					<AddExpensesItemEdit
						// control={props.control}
						// wrapper={props.wrapper}
						textClass={''}
						params={props.params}
						mainClass={props.mainClass}
						buttonWrapper={{
							fontSize: '12px',
							lineHeight: '16px',
							letterSpacing: '0.4px',
							// fontFamily: fonts.semiBold,
							color: `rgba(26, 26, 26,  1})`,
						}}
						buttonText={{
							cursor: 'pointer',
							padding: '4px 19px',
							margin: '0px 10px',
							borderRadius: '4px',
							backgroundColor: `rgba(255, 214, 0,  1})`,
							width: 'fit-content',
						}}
						// inputTextWrapper={props.inputTextWrapper}
						// fieldKey={props.fieldKey}
						// errors={props.errors}
					/>
				);
			case AddExpenseItemCellType.KeyValue:
				return (
					<AddExpensesItemKeyValue
						control={props.control}
						wrapper={props.wrapper}
						params={props.params}
						mainClass={props.mainClass}
						inputKeyValueWrapper={props.inputKeyValueWrapper}
						fieldKey={props.fieldKey}
						errors={props.errors}
					/>
				);
			case AddExpenseItemCellType.Static:
				return (
					<AddExpensesItemStatic
						params={props.params}
						mainClass={props.mainClass}
						textClass={props.textClass}
					/>
				);
			case AddExpenseItemCellType.Date:
				return (
					<AddExpensesItemDate
						params={props.params}
						mainClass={props.mainClass}
						textClass={props.textClass}
					/>
				);
		}
	return null;
}

export default AddExpenseItemCell;
