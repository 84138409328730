import { Grid, makeStyles } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ContractList from '../components/ContractList';
import Filter from '../components/Filter';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import NewLink from '../components/NewLink';
import StandardTextField from '../components/StandardTextField';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { contractFields } from '../containers/ContractContainer';
import {
	ContractContainerProps,
	ContractFilterKeys,
	ContractOverviewFilterInterface,
	initContractFilter,
} from '../containers/ContractOverviewContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import {
	ContractViewType,
	filterContractData,
	filterOpenClosed,
} from '../helpers/contractHelper/generalContractHelper';
import { checkIfUserStopWriting } from '../helpers/filters';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { Routes } from '../router/Routes';
import Contract from '../service/types/Contract';
import { UserRole } from '../service/types/User';
import HomeButton from '../svgComponents/HomeButton';
import IQRLogo from '../svgComponents/IQRLogo';

interface Props extends ContractContainerProps {
	intervalDates: IntervalDatePickerDates;
	contracts: Contract[];
	filters: ContractOverviewFilterInterface;
	teamLeaders: KeyValue[];
	assignedQAIs: KeyValue[];
	customers: KeyValue[];
	buttonAction: (contract: Contract) => void;
	onFilterChange: (key: ContractFilterKeys, value: any) => void;
	clearAllFilters: () => void;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
}

function ContractOverviewScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const globalClasses = useGlobalStyles({ showFilters });
	const classes = useStyles({});
	const { t } = useTranslation();
	const { user } = useAuthStateContext();
	const filterListRef = React.useRef<any>(null);
	const searchRef = React.useRef<ContractOverviewFilterInterface>({ ...initContractFilter });
	const { control, errors } = useFormContext();
	return (
		<div className={globalClasses.container}>
			<IQRLogo className={globalClasses.logo} />
			<div className={globalClasses.homeButtonWrapper}>
				<div />
				<HomeButton />
			</div>
			<div className={classes.row}>
				<div className={classes.titleWrapper}>
					<span className={globalClasses.title}>{t('contract_overview_screen_title')}</span>
				</div>
				{props.viewType === ContractViewType.Admin ? (
					<NewLink route={Routes.Contract} text={'contract_overview_screen_add_new'} />
				) : null}
			</div>
			<Filter onFilterPress={() => setShowFilters((prev) => !prev)} onClearAllPress={props.clearAllFilters} />
			<div
				className={globalClasses.filterWrapper}
				style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
				<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={contractFields.contractNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('contract_overview_screen_contract_number')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'contractNumber');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<IntervalDatePicker
							id="start"
							error={errors['fromDate']}
							helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
							value={props.filters.fromDate}
							label={t('contract_overview_screen_contract_from_date')}
							dates={props.intervalDates}
							clearable={true}
							onDateChange={props.onDateChange}
							onKeyboardDateChange={props.onKeyboardDateChange}
							onDatesAccept={props.onDatesAccept}
							onDatesCancel={props.onDatesCancel}
							clearDates={props.clearDates}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<IntervalDatePicker
							id="end"
							error={errors['toDate']}
							helperText={errors['toDate'] ? errors['toDate'].message : ''}
							value={props.filters.toDate}
							label={t('contract_overview_screen_contract_to_date')}
							dates={props.intervalDates}
							clearable={true}
							onKeyboardDateChange={props.onKeyboardDateChange}
							onDateChange={props.onDateChange}
							onDatesAccept={props.onDatesAccept}
							onDatesCancel={props.onDatesCancel}
							clearDates={props.clearDates}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={'workLocation'}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('contract_overview_screen_contract_work_location')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'workLocation');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={contractFields.partDesignation}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('contract_overview_screen_contract_part_designation')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'partDesignation');
									}}
								/>
							)}
						/>
					</Grid>
					{user?.role !== UserRole.QualityAssuranceInspector && user?.role !== UserRole.Customer ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<KeyValueAutoComplete
								label={t('contract_overview_screen_contract_assigned_team_leader')}
								onChange={(e) => {
									if (e) {
										props.onFilterChange('assignedTeamLeader', e.value.id.toString());
									} else {
										props.onFilterChange('assignedTeamLeader', '');
									}
								}}
								options={props.teamLeaders}
								multiple={false}
								value={{
									key: props.teamLeaders.find(
										(teamLeader) =>
											teamLeader.value.id.toString() === props.filters.assignedTeamLeader
									)?.key
										? props.teamLeaders.find(
												(teamLeader) =>
													teamLeader.value.id.toString() === props.filters.assignedTeamLeader
										  )!.key
										: '',
									value: props.filters.assignedTeamLeader,
								}}
								withoutClose={false}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<KeyValueAutoComplete
								getOptionSelected={(option, value) => {
									if (value.key === '') return false;
									return t(option.key) === t(value.key);
								}}
								label={t('contract_overview_screen_contract_status')}
								onChange={(e) => {
									if (e) {
										props.onFilterChange('status', e.value);
									} else {
										props.onFilterChange('status', '');
									}
								}}
								options={filterOpenClosed}
								multiple={false}
								value={{
									key:
										props.filters.status === ''
											? ''
											: t(filterOpenClosed[props.filters.status].key),

									value: props.filters.status,
								}}
								withoutClose={false}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector && user?.role !== UserRole.Customer ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<KeyValueAutoComplete
								getOptionSelected={(option, value) => {
									if (value.key === '') return false;
									return t(option.key) === t(value.key);
								}}
								label={t('contract_overview_screen_attachment')}
								multiple={false}
								onChange={(e) => {
									if (e) {
										props.onFilterChange('contractData', e.value);
									} else {
										props.onFilterChange('contractData', '');
									}
								}}
								options={filterContractData}
								value={{
									key:
										props.filters.contractData === ''
											? ''
											: props.filters.contractData === true
											? t(filterContractData[0].key)
											: t(filterContractData[1].key),
									value: props.filters.contractData,
								}}
								withoutClose={false}
							/>
						</Grid>
					) : null}
				</Grid>
			</div>

			<ContractList
				allQAIs={props.assignedQAIs}
				allTeamLeaders={props.teamLeaders}
				buttonAction={props.buttonAction}
				buttonText={props.buttonText}
				contracts={props.contracts}
				customers={props.customers}
				displaySort={props.displaySort}
				filters={props.filters}
				onFilterChange={props.onFilterChange}
				redirectTo={props.redirectTo}
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '12px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

export default ContractOverviewScreen;
