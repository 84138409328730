import React from 'react';
import { ExpenseTabelParamsInterface } from '../consts/material';
import Button from './Button';
import { TableCellWithCss } from './table/StandardTableCell';

interface Props {
	mainClass: string;
	textClass: string;
	params: ExpenseTabelParamsInterface;
	buttonWrapper: any;
	buttonText: any;
}
function AddExpensesItemEdit(props: Props) {
	return (
		<TableCellWithCss
			className={props.mainClass}
			style={{
				width: props.params.width,
			}}>
			<span className={props.textClass}>{props.params.value}</span>
			<Button
				onClick={() => null}
				buttonWrapperClassName={props.buttonWrapper}
				text={'Edit'}
				textClassName={props.buttonText}
			/>
		</TableCellWithCss>
	);
}

export default AddExpensesItemEdit;
