import React from 'react';

import { UserRole } from '../service/types/User';
import UsersIcon from '../svgComponents/UsersIcon';
import ContractsIcon from '../svgComponents/ContractsIcon';
import DashboardIcon from '../svgComponents/DashboardIcon';
import CustomersIcon from '../svgComponents/CustomersIcon';
import EditContractIcon from '../svgComponents/EditContractsIcon';

import { RouteRestrictions, Routes } from '../router/Routes';
import GroupIcon from '../svgComponents/GroupIcon';

export interface MenuItemData {
	name: string;
	imageSource: JSX.Element;
	accessRoles: UserRole[];
	route: Routes;
}

export const menuItemsData = (admin: boolean) => {
	return [
		{
			name: 'home_screen_dashboard',
			imageSource: <DashboardIcon style={{ width: '100%', height: '100%' }} />,
			accessRoles: RouteRestrictions.CustomerContracts,
			route: Routes.CustomerContracts,
		},
		{
			name: 'home_screen_customer',
			imageSource: <CustomersIcon style={{ width: '100%', height: '100%' }} />,
			accessRoles: RouteRestrictions.Customers,
			route: Routes.Customers,
		},
		{
			name: 'home_screen_contracts',
			imageSource: <EditContractIcon style={{ width: '100%', height: '100%' }} />,
			accessRoles: RouteRestrictions.Contracts,
			route: Routes.AdminContracts,
		},
		admin
			? {
					name: 'menu_item_users_groups',
					imageSource: <UsersIcon style={{ width: '100%', height: '100%' }} />,
					accessRoles: RouteRestrictions.Entities,
					route: Routes.Entities,
			  }
			: {
					name: 'home_screen_users',
					imageSource: <UsersIcon style={{ width: '100%', height: '100%' }} />,
					accessRoles: RouteRestrictions.Users,
					route: Routes.Users,
			  },
		{
			name: 'home_screen_process_contracts',
			imageSource: <ContractsIcon style={{ width: '100%', height: '100%' }} />,
			accessRoles: RouteRestrictions.ProcessContracts,
			route: Routes.ProcessContracts,
		},
	] as MenuItemData[];
};

export const entitiesItems: MenuItemData[] = [
	{
		name: 'home_screen_users',
		imageSource: <UsersIcon style={{ width: '100%', height: '100%' }} />,
		accessRoles: RouteRestrictions.Users,
		route: Routes.Users,
	},
	{
		name: 'menu_item_group',
		imageSource: <GroupIcon style={{ width: '100%', height: '100%' }} />,
		accessRoles: RouteRestrictions.GroupOverview,
		route: Routes.GroupOverview,
	},
];
