import * as React from 'react';
import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M2 3.333a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10.334a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.333Z"
			stroke="#1A1A1A"
		/>
		<path d="M2 6h12" stroke="#1A1A1A" strokeLinecap="round" />
		<path d="M5.334 1.333v2M10.666 1.333v2" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round" />
		<circle cx={8.001} cy={10} r={0.667} fill="#1A1A1A" />
		<circle cx={10.667} cy={10} r={0.667} fill="#1A1A1A" />
		<circle cx={5.333} cy={10} r={0.667} fill="#1A1A1A" />
	</svg>
);

export default CalendarIcon;
