import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import { useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import User from '../service/types/User';
import StandardTextField from '../components/StandardTextField';
import { Control, Controller } from 'react-hook-form';
import { rules } from '../consts/rules';
import { contactPersonFields } from '../containers/ContactPersonContainer';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, standardPasswordReset } from '../consts/login';

const MIN_PHONE_NUMBER_LENGTH = 4;

interface Props {
	contactPerson: User | undefined;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	isUserEditing: boolean;
	deleteDialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	onSubmitPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDeleteDialogPress: () => void;
	onDeleteContactPersonDialogPress: () => void;
}
function ContactPersonScreen(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow edit={props.isUserEditing} />
				<HomeButton edit={props.isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>
					{props.contactPerson
						? `${props.contactPerson.firstName} ${props.contactPerson.lastName}`
						: t('contact_person_screen_title')}
				</span>
			</div>

			<Grid container>
				<Grid item sm={5} className={classes.textFieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						disabled={!props.isUserEditing}
						name={contactPersonFields.firstName}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contact_person_screen_first_name').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contactPersonFields.firstName] ? true : false}
								helperText={
									props.errors[contactPersonFields.firstName]
										? props.errors[contactPersonFields.firstName].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.textFieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contactPersonFields.lastName}
						disabled={!props.isUserEditing}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contact_person_screen_last_name').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contactPersonFields.lastName] ? true : false}
								helperText={
									props.errors[contactPersonFields.lastName]
										? props.errors[contactPersonFields.lastName].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.textFieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						disabled={!props.isUserEditing}
						name={contactPersonFields.phonenumber}
						rules={{
							required: rules(t).required,
							pattern: rules(t).phoneNumberPattern,
							minLength: rules(t).minLength(MIN_PHONE_NUMBER_LENGTH),
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contact_person_screen_phone_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contactPersonFields.phonenumber] ? true : false}
								helperText={
									props.errors[contactPersonFields.phonenumber]
										? props.errors[contactPersonFields.phonenumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.textFieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						disabled={!props.isUserEditing}
						name={contactPersonFields.email}
						rules={{ required: rules(t).required, pattern: rules(t).emailPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contact_person_screen_email_address').toUpperCase()}
								onChange={onChange}
								value={value}
								type="email"
								disabled={!props.isUserEditing}
								error={props.errors[contactPersonFields.email] ? true : false}
								helperText={
									props.errors[contactPersonFields.email]
										? props.errors[contactPersonFields.email].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={5} className={classes.textFieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contactPersonFields.password}
						rules={
							props.contactPerson
								? {
										validate: (val) => {
											if (!val) return true;
											else {
												if (val.length < MIN_PASSWORD_LENGTH) {
													return rules(t).minLength(MIN_PASSWORD_LENGTH).message;
												}
												if (val.length > MAX_PASSWORD_LENGTH) {
													return rules(t).minLength(MAX_PASSWORD_LENGTH).message;
												}
												return true;
											}
										},
								  }
								: { ...standardPasswordReset.rules(t).password }
						}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contact_person_screen_password').toUpperCase()}
								onChange={onChange}
								value={value}
								type={'password'}
								disabled={!props.isUserEditing}
								error={props.errors[contactPersonFields.password] ? true : false}
								helperText={
									props.errors[contactPersonFields.password]
										? props.errors[contactPersonFields.password].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'contact_person_screen_cancel'}
					updateButtonText={'contact_person_screen_update'}
					createButtonText={'contact_person_screen_create'}
					deleteButtonText={'contact_person_screen_delete'}
					editButtonText={'contact_person_screen_edit'}
					data={props.contactPerson}
					isUserEditing={props.isUserEditing}
					onCancelPress={props.onCancelPress}
					onDeletePress={props.onDeletePress}
					onSubmitPress={props.onSubmitPress}
				/>
			</div>
			<StandardDialog
				acceptText={'contact_person_screen_dialog_accept_button'}
				description={'contact_person_screen_dialog_description'}
				title={'contact_person_screen_dialog_title'}
				cancleText={'contact_person_screen_dialog_cancel_button'}
				onCancle={props.onCancelDeleteDialogPress}
				onAccept={props.onDeleteContactPersonDialogPress}
				ref={props.deleteDialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '24px',
	},
	textFieldWrapper: {
		marginBottom: '32px',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
}));

export default ContactPersonScreen;
