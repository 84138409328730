import { Service } from '../../service/service';
import User from '../../service/types/User';
import { History, LocationState } from 'history';
import { NotificationInterface } from '../../context/notification/notificationReducer';

export function deleteContactPerson(
	service: Service,
	contactPerson: User | undefined,
	history: History<LocationState>,
	setNotification: (notification: NotificationInterface) => void
) {
	if (contactPerson)
		service
			.deleteUserByID(contactPerson.id)
			.then((res) => {
				if (res) {
					history.goBack();
					setNotification({
						text: 'contact_person_screen_delete_notification',
						additional: `${contactPerson.firstName} ${contactPerson.lastName}`,
					});
				}
			})
			.catch(() => {});
}
