import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import StandardTextField from '../components/StandardTextField';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import {
	standardPasswordReset,
	qaiPasswordReset,
	MAX_PASSWORD_LENGTH,
	MIN_PASSWORD_LENGTH,
	QAI_PASSWORD_LENGTH,
} from '../consts/login';
import { rules } from '../consts/rules';
import {
	MIN_ABBRIVIATION_LENGTH,
	MIN_PHONE_NUMBER_LENGTH,
	MIN_USER_NAME_LENGTH,
	userFields,
} from '../containers/UserContainer';
import { KeyValue, originOfEmployeeKeyValuePairs } from '../helpers/userHelper/generalUserHelper';
import { Routes } from '../router/Routes';
import User, { UserRole } from '../service/types/User';
import GoBack from '../svgComponents/GoBack';
import HomeButton from '../svgComponents/HomeButton';
import Autocomplete from '../components/Autocomplete';
import { useAtom } from 'jotai';
import { groupAtom } from '../atoms/group';
import { allCustomersAtom } from '../atoms/allCustomers';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	userRole: UserRole;
	user: User | undefined;
	isUserEditing: boolean;
	teamLeaders: KeyValue[];
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	userRoles: KeyValue[];
	route?: string;
	onTeamLeaderFieldPress: () => void;
	onSubmitPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDialogPress: () => void;
	onDeleteUserDialogPress: () => void;
}

function UserScreen({
	control,
	errors,
	userRole,
	user,
	isUserEditing,
	teamLeaders,
	dialogRef,
	userRoles,
	route = Routes.Users,
	onTeamLeaderFieldPress,
	onSubmitPress,
	onCancelPress,
	onDeletePress,
	onCancelDialogPress,
	onDeleteUserDialogPress,
}: Props) {
	const classes = useStyles({ disabled: !isUserEditing });
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const [groups] = useAtom(groupAtom);
	const [allCustomers] = useAtom(allCustomersAtom);

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack route={route} edit={isUserEditing} />
				<HomeButton edit={isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>
					{user ? `${user.firstName} ${user.lastName}` : t('user_screen_title')}
				</span>
			</div>
			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.subtitle}>{t('user_screen_subtitle')}</span>
			</div>
			<Grid container spacing={4}>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={user ? user.firstName : ''}
						control={control}
						name={userFields.firstName}
						rules={{
							required: rules(t).required,
							minLength: rules(t).minLength(MIN_USER_NAME_LENGTH),
							pattern: rules(t).notEmptyPattern,
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('user_overview_screen_first_name').toUpperCase()}
								onChange={onChange}
								disabled={!isUserEditing}
								value={value}
								error={errors[userFields.firstName] ? true : false}
								helperText={errors[userFields.firstName] ? errors[userFields.firstName].message : ''}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={user ? user.lastName : ''}
						control={control}
						name={userFields.lastName}
						rules={{
							required: rules(t).required,
							minLength: rules(t).minLength(MIN_USER_NAME_LENGTH),
							pattern: rules(t).notEmptyPattern,
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('user_overview_screen_last_name').toUpperCase()}
								onChange={onChange}
								disabled={!isUserEditing}
								value={value}
								error={errors[userFields.lastName] ? true : false}
								helperText={errors[userFields.lastName] ? errors[userFields.lastName].message : ''}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />

				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={control}
						name={userFields.originOfEmployee}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('user_overview_screen_origin').toUpperCase()}
								select
								onChange={onChange}
								disabled={!isUserEditing}
								value={value}
								error={errors[userFields.originOfEmployee] ? true : false}
								helperText={
									errors[userFields.originOfEmployee]
										? errors[userFields.originOfEmployee].message
										: ''
								}>
								{originOfEmployeeKeyValuePairs.map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={user?.role ?? ''}
						control={control}
						name={userFields.role}
						rules={{ required: rules(t).required }}
						render={({ value, onChange }) => (
							<StandardTextField
								label={t('user_overview_screen_role').toUpperCase()}
								select
								onChange={onChange}
								value={value}
								disabled={!isUserEditing}
								error={errors[userFields.role] ? true : false}
								helperText={errors[userFields.role] ? errors[userFields.role].message : ''}>
								{userRoles.map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={user && user.phonenumber ? user.phonenumber : ''}
						control={control}
						name={userFields.phonenumber}
						rules={{
							required: rules(t).required,
							pattern: rules(t).phoneNumberPattern,
							minLength: rules(t).minLength(MIN_PHONE_NUMBER_LENGTH),
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('user_overview_screen_phone_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!isUserEditing}
								error={errors[userFields.phonenumber] ? true : false}
								helperText={
									errors[userFields.phonenumber] ? errors[userFields.phonenumber].message : ''
								}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5} className={classes.fieldWrapper}>
					{userRole === UserRole.TeamLeader ? (
						<Controller
							defaultValue={user?.tlGroups ?? []}
							control={control}
							name={userFields.tlGroups}
							render={({ onChange, value }) => {
								return (
									<Autocomplete
										disabled={!isUserEditing}
										label={t('contract_screen_team_leader_group').toUpperCase()}
										onChange={onChange}
										options={groups.map((group) => ({ id: group.groupId, name: group.name })) ?? []}
										value={value}
										disableCloseOnSelect={false}
										multiple
										placeholder=""
										error={errors[userFields.tlGroups] ? true : false}
										helperText={
											errors[userFields.tlGroups] ? errors[userFields.tlGroups].message : ''
										}
									/>
								);
							}}
						/>
					) : null}

					{userRole === UserRole.Customer ? (
						<Controller
							defaultValue={user?.contactFor || []}
							control={control}
							name={userFields.contactFor}
							render={({ onChange, value }) => {
								return (
									<Autocomplete
										disabled={!isUserEditing}
										label={t('contract_screen_contact').toUpperCase()}
										onChange={onChange}
										options={allCustomers.map((el) => ({
											id: el.id,
											name: el.name,
										}))}
										value={value}
										disableCloseOnSelect={false}
										multiple
										placeholder=""
										error={errors[userFields.contactFor] ? true : false}
										helperText={
											errors[userFields.contactFor] ? errors[userFields.contactFor].message : ''
										}
									/>
								);
							}}
						/>
					) : null}
				</Grid>

				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={user ? user.email : ''}
						control={control}
						name={userFields.email}
						rules={
							userRole !== UserRole.QualityAssuranceInspector
								? { required: rules(t).required, pattern: rules(t).emailPattern }
								: {}
						}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('user_screen_email').toUpperCase()}
								onChange={onChange}
								type="email"
								disabled={!isUserEditing}
								value={value}
								error={errors[userFields.email] ? true : false}
								helperText={errors[userFields.email] ? errors[userFields.email].message : ''}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />

				{userRole === UserRole.QualityAssuranceInspector ? (
					<>
						<Grid item sm={1} className={classes.fieldWrapper}>
							<Controller
								defaultValue={''}
								control={control}
								name={userFields.password}
								rules={
									user
										? {
												validate: (val) => {
													if (user!.role !== UserRole.QualityAssuranceInspector && !val)
														return rules(t).minLength(QAI_PASSWORD_LENGTH).message;
													if (!val) return true;
													else {
														if (val.length < QAI_PASSWORD_LENGTH) {
															return rules(t).minLength(QAI_PASSWORD_LENGTH).message;
														}
														if (val.length > QAI_PASSWORD_LENGTH) {
															return rules(t).minLength(QAI_PASSWORD_LENGTH).message;
														}
														if (!/^\d+$/.test(val)) {
															return rules(t).numberPattern.message;
														}
														return true;
													}
												},
										  }
										: { ...qaiPasswordReset.rules(t).password }
								}
								render={({ onChange, value }) => (
									<StandardTextField
										label={t('user_screen_pin').toUpperCase()}
										onChange={onChange}
										value={value}
										type={'password'}
										disabled={!isUserEditing}
										error={errors[userFields.password] ? true : false}
										helperText={
											errors[userFields.password] ? errors[userFields.password].message : ''
										}
									/>
								)}
							/>
						</Grid>
						<Grid item sm={5} />
						<Grid item sm={5} className={classes.fieldWrapper}>
							<Controller
								defaultValue={[]}
								control={control}
								name={userFields.teamLeader}
								rules={{
									required: rules(t).required,
									validate: (val: KeyValue[] | undefined) => {
										if (val && val.length !== 0) {
											return true;
										}
										return rules(t).required.message;
									},
								}}
								render={({ onChange, value }) => (
									<KeyValueAutoComplete
										label={t('user_screen_assign_team_leader').toUpperCase()}
										onChange={onChange}
										options={teamLeaders.filter((el) => !el.value.deleted)}
										multiple={true}
										value={value}
										disableCloseOnSelect={true}
										onOpen={onTeamLeaderFieldPress}
										disabled={!isUserEditing}
										error={errors[userFields.teamLeader] ? true : false}
										helperText={
											errors[userFields.teamLeader] ? errors[userFields.teamLeader].message : ''
										}
									/>
								)}
							/>
						</Grid>
					</>
				) : (
					<Grid item sm={5} className={classes.fieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={userFields.password}
							rules={
								user
									? {
											validate: (val) => {
												if (user!.role === UserRole.QualityAssuranceInspector && !val)
													return rules(t).minLength(MAX_PASSWORD_LENGTH).message;
												if (!val) return true;
												else {
													if (val.length < MIN_PASSWORD_LENGTH) {
														return rules(t).minLength(MIN_PASSWORD_LENGTH).message;
													}
													if (val.length > MAX_PASSWORD_LENGTH) {
														return rules(t).minLength(MAX_PASSWORD_LENGTH).message;
													}
													return true;
												}
											},
									  }
									: { ...standardPasswordReset.rules(t).password }
							}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('user_screen_password').toUpperCase()}
									onChange={onChange}
									value={value}
									type={'password'}
									disabled={!isUserEditing}
									error={errors[userFields.password] ? true : false}
									helperText={errors[userFields.password] ? errors[userFields.password].message : ''}
								/>
							)}
						/>
					</Grid>
				)}
				<Grid item sm={1} />

				{userRole === UserRole.QualityAssuranceInspector ? (
					<Grid item sm={5} className={classes.fieldWrapper}>
						<Controller
							defaultValue={user ? user.username : ''}
							control={control}
							name={userFields.username}
							rules={{
								required: rules(t).required,
								minLength: rules(t).minLength(MIN_ABBRIVIATION_LENGTH),
								pattern: rules(t).notEmptyPattern,
							}}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('user_screen_username').toUpperCase()}
									onChange={onChange}
									disabled={!isUserEditing}
									value={value}
									error={errors[userFields.username] ? true : false}
									helperText={errors[userFields.username] ? errors[userFields.username].message : ''}
								/>
							)}
						/>
					</Grid>
				) : null}

				<Grid item sm={1} />
			</Grid>
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'user_screen_cancel'}
					updateButtonText={'user_screen_update'}
					createButtonText={'user_screen_create'}
					deleteButtonText={'user_screen_delete'}
					editButtonText={'user_screen_edit'}
					data={user}
					isUserEditing={isUserEditing}
					onCancelPress={onCancelPress}
					onDeletePress={onDeletePress}
					onSubmitPress={onSubmitPress}
				/>
			</div>
			<StandardDialog
				acceptText={'user_screen_dialog_accept_button'}
				description={'user_screen_dialog_description'}
				title={'user_screen_dialog_title'}
				cancleText={'user_screen_dialog_cancel_button'}
				onCancle={onCancelDialogPress}
				onAccept={onDeleteUserDialogPress}
				ref={dialogRef}
				showWarning
			/>
		</div>
	);
}

interface StyleProps {
	disabled: boolean;
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '24px',
	},
	subtitleWrapper: {
		marginBottom: '28px',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	fieldWrapper: {
		padding: '16px !important',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
	pinWrapper: {
		marginTop: '16px',
	},
}));

export default UserScreen;
