import { History, LocationState } from 'history';
import { RouteParams } from '../../router/Routes';
import service from '../../service/service';
import { ContractStatusType } from '../contractHelper/generalContractHelper';
import { ExpensesKeys } from '../expenses/generalExpensesHelper';
import { goToHome, SummaryNavigationInterface } from '../navigationHelper';

export function onSummaryMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setContractData: (contractData: SummaryNavigationInterface) => void,
	setContractStatus: (status: ContractStatusType) => void
) {
	const historyState = history.location.state as undefined | SummaryNavigationInterface;

	if (historyState && historyState.contractID) {
		setContractData({ ...historyState });
		setContractStatus(historyState.contractStatus);
	} else if (routeParams.contract_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				if (res) {
					setContractData({
						contractID: res.id,
						contractStatus: res.status,
						contractNumber: res.contractNumber,
						contractCustomer: res.customer.toString(),
						workLocations: res.workLocations,
					});
					setContractStatus(res.status);
				}
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		goToHome(history);
	}
}

export async function getSummaryData(key: ExpensesKeys, contractID: number) {
	if (key === 'materials') {
		return await service.getMaterialExpenses(contractID);
	} else if (key === 'tools') {
		return await service.getToolExpenses(contractID);
	} else if (key === 'travels') {
		return await service.getTravelExpenses(contractID);
	} else if (key === 'workingTime') {
		return await service.getAllRecords(contractID);
	} else if (key === 'invoice') {
		return await service.getAllInvoices(contractID);
	} else {
		return await service.getTransportExpenses(contractID);
	}
}
