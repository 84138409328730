import { standardCaseInputFields } from '../../containers/StandardCaseInputContainer';
import StandardCaseInput, { ErrorInputType } from '../../service/types/StandardCase';
import { goToHome, StandardCaseNavigationInterface } from '../navigationHelper';
import { History, LocationState } from 'history';
import User, { UserRole } from '../../service/types/User';
import Contract from '../../service/types/Contract';
import { RouteParams } from '../../router/Routes';
import service from '../../service/service';

export function setStandardInputCaseInfo(
	standardInputCase: StandardCaseInput | undefined,
	user: User | undefined,
	setValue: (field: string, val: any) => void,
	setErrorParts: (errorParts: ErrorInputType[]) => void
) {
	if (standardInputCase) {
		setValue(standardCaseInputFields.partNumber, standardInputCase.partNumber);
		setValue(standardCaseInputFields.date, standardInputCase.date);
		setValue(standardCaseInputFields.deliveryNoteNumber, standardInputCase.deliveryNoteNumber);
		setValue(standardCaseInputFields.deliveryNoteDate, standardInputCase.deliveryNoteDate);
		setValue(standardCaseInputFields.totalPartsTested, standardInputCase.totalPartsTested);
		setValue(standardCaseInputFields.partsReworked, standardInputCase.partsReworked);
		setValue(standardCaseInputFields.comment, standardInputCase.comment);
		setValue(standardCaseInputFields.typeOfInspection, standardInputCase.typeOfInspection);
		setErrorParts(standardInputCase.errorTypes);
		setValue(standardCaseInputFields.approved, standardInputCase.approved);
		setValue(standardCaseInputFields.location, standardInputCase.locationID);

		if (user?.role !== UserRole.QualityAssuranceInspector) {
			if (standardInputCase.photo)
				service
					.getPhoto(standardInputCase.photo.name)
					.then((res) => {
						if (res) {
							setValue(standardCaseInputFields.photo, { name: '', data: res });
						}
					})
					.catch(() => {});
		}
	}
}

export function onStandardInputScreenMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setIsUserEditing: (val: boolean) => void,
	setStandardInputCase: (standardInputCase: StandardCaseInput | undefined) => void,
	setContract: (contract: Contract) => void,
	setMounted: (val: boolean) => void
) {
	const historyState = history.location.state as undefined | StandardCaseNavigationInterface;

	if (historyState && historyState.contract) {
		setContract(Contract.fromJSON(JSON.parse(historyState.contract)));
		if (historyState.data) {
			setStandardInputCase(JSON.parse(historyState.data));
		} else {
			setIsUserEditing(true);
		}
		setMounted(true);
	} else if (routeParams.contract_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				if (res) setContract(res);
			})
			.catch((e) => {
				goToHome(history);
			});
		if (routeParams.id && ((historyState && !historyState.data) || !historyState)) {
			service
				.getStandardCaseInputByID(Number(routeParams.contract_id), Number(routeParams.id))
				.then((res) => {
					if (res) {
						setMounted(true);
						setStandardInputCase(res);
					}
				})
				.catch((e) => {
					goToHome(history);
				});
		} else {
			setMounted(true);
		}
	} else {
		goToHome(history);
	}
}
