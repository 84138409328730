import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { StandardDialogInterface } from '../components/StandardDialog';
import { useAuthStateContext } from '../context/auth/useAuth';
import { createRhenusCaseInput } from '../helpers/rhenusInputHelper/createRhenusCaseHelper';
import { updateRhenusCaseInput } from '../helpers/rhenusInputHelper/updateRhenusCaseHelper';
import { setRhenusInputCaseInfo, onRhenusInputScreenMount } from '../helpers/rhenusInputHelper/readRhenusCaseHelper';
import RhenusCaseScreen from '../screens/RhenusCaseScreen';
import service from '../service/service';
import RhenusCase from '../service/types/RhenusCase';
import { ContractZone, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import { deleteRhenusInput } from '../helpers/rhenusInputHelper/deleteRhenusCaseHelper';
import { useNotificationActionContext } from '../context/notification/useNotification';
import Contract from '../service/types/Contract';
import Spinner from '../components/Spinner';
import { RouteParams } from '../router/Routes';

export const rhenusCaseInputFields = {
	partNumber: 'partNumber',
	partNumberShould: 'partNumberShould',
	colorNumber: 'colorNumber',
	colorNumberShould: 'colorNumberShould',
	amountOfParts: 'amountOfParts',
	partStatus: 'partStatus',
	productionNumber: 'productionNumber',
	qai: 'qai',
	storage: 'storage',
	approved: 'approved',
	typeOfInspection: 'typeOfInspection',
	date: 'date',
	shift: 'shift',
	zone: 'zone',
	comment: 'comment',
	errorType: 'errorType',
	photo: 'photo',
};

function RhenusCaseContainer() {
	const { control, handleSubmit, errors, setValue, watch } = useForm();
	const [isUserEditing, setIsUserEditing] = React.useState(false);
	const [contract, setContract] = React.useState<Contract>();
	const [rhenusCaseInput, setRhenusCaseInput] = React.useState<undefined | RhenusCase>(undefined);
	const [mounted, setMounted] = React.useState(false);
	const { user } = useAuthStateContext();
	const { setNotification } = useNotificationActionContext();
	const contractIDRef = React.useRef<number>(0);
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const history = useHistory();
	const routeParams: RouteParams = useParams();

	const [shift, setShift] = React.useState<ShiftInterface | undefined>();
	const [zone, setZone] = React.useState<ContractZone | undefined>();
	const onDeletePress = () => dialogRef.current?.setDialogState(true);
	const onCancelDialogPress = () => dialogRef.current?.setDialogState(false);
	const onDeleteErrorInputDialogPress = () => {
		deleteRhenusInput(service, rhenusCaseInput, contractIDRef, history, setNotification);
	};

	React.useEffect(() => {
		onRhenusInputScreenMount(
			history,
			contractIDRef,
			routeParams,
			setContract,
			setIsUserEditing,
			setRhenusCaseInput,
			setZone,
			setMounted
		);
	}, [history, routeParams]);

	React.useEffect(() => {
		if (contract) setRhenusInputCaseInfo(rhenusCaseInput, user, contract, setValue, setShift, setZone);
	}, [rhenusCaseInput, setValue, user, contract]);

	const onEditPress = () => setIsUserEditing(true);

	const onUpdatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				updateRhenusCaseInput(
					data,
					service,
					rhenusCaseInput,
					contractIDRef,
					history,
					setRhenusCaseInput,
					setIsUserEditing
				);
			}),
		[handleSubmit, rhenusCaseInput, history]
	);
	const onCreatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (user && contract && zone && shift)
					createRhenusCaseInput(
						data,
						history,
						contractIDRef,
						service,
						user,
						contract.automatedUpdates,
						zone.id,
						shift.id || 0
					);
			}),
		[history, user, contract, zone, shift, handleSubmit]
	);

	const onCancelPress = () => {
		if (rhenusCaseInput && contract) {
			setRhenusInputCaseInfo(rhenusCaseInput, user, contract, setValue, setShift, setZone);
			setIsUserEditing(false);
		} else history.goBack();
	};

	const onSubmitPress = React.useCallback(() => {
		if (rhenusCaseInput) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		} else {
			onCreatePress();
		}
	}, [rhenusCaseInput, isUserEditing, onUpdatePress, onCreatePress]);

	if (!contract || !mounted) return <Spinner />;

	return (
		<RhenusCaseScreen
			onCancelDialogPress={onCancelDialogPress}
			onCancelPress={onCancelPress}
			onDeletePress={onDeletePress}
			onSubmitPress={onSubmitPress}
			onDeleteErrorInputDialogPress={onDeleteErrorInputDialogPress}
			watch={watch}
			shift={shift}
			zone={zone}
			contract={contract}
			control={control}
			dialogRef={dialogRef}
			errors={errors}
			isUserEditing={isUserEditing}
			rhenusCaseInput={rhenusCaseInput}
		/>
	);
}

export default RhenusCaseContainer;
