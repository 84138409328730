import { History, LocationState } from 'history';

import { rhenusCaseInputFields } from '../../containers/RhenusCaseContainer';
import { Service } from '../../service/service';
import RhenusCase from '../../service/types/RhenusCase';
import { replaceRhenusCaseHistoryData } from '../navigationHelper';
import moment from 'moment';

export function updateRhenusCaseInput(
	data: any,
	service: Service,
	rhenusCaseInput: RhenusCase | undefined,
	contractIDRef: React.MutableRefObject<number>,
	history: History<LocationState>,
	setRhenusCaseInput: (rhenusCase: RhenusCase | undefined) => void,
	setIsUserEditing: (val: boolean) => void
) {
	if (rhenusCaseInput) {
		const tempRhenusCase = getUpdatedRhenusCase(data, rhenusCaseInput);
		service
			.updateRhenusCaseInput(contractIDRef.current, tempRhenusCase.zone, {
				...tempRhenusCase,
				photo: tempRhenusCase.photo
					? {
							...tempRhenusCase.photo,
							data:
								tempRhenusCase.photo && tempRhenusCase.photo.data
									? tempRhenusCase.photo.data.split(',')[1]
									: '',
					  }
					: undefined,
			})
			.then((res) => {
				if (res) {
					setRhenusCaseInput(tempRhenusCase);
					setIsUserEditing(false);
					replaceRhenusCaseHistoryData(history, tempRhenusCase);
				}
			})
			.catch(() => {});
	}
}

function getUpdatedRhenusCase(data: any, rhenusCaseInput: RhenusCase) {
	return new RhenusCase(
		rhenusCaseInput.id,
		moment(data[rhenusCaseInputFields.date]),
		rhenusCaseInput.shift,
		Number(data[rhenusCaseInputFields.productionNumber]),
		data[rhenusCaseInputFields.partNumber],
		rhenusCaseInput.zone,
		data[rhenusCaseInputFields.storage],
		data[rhenusCaseInputFields.partStatus],
		rhenusCaseInput.qai,
		data[rhenusCaseInputFields.approved] === undefined ? true : data[rhenusCaseInputFields.approved],
		data[rhenusCaseInputFields.typeOfInspection],
		data[rhenusCaseInputFields.errorType],
		data[rhenusCaseInputFields.partNumberShould],
		data[rhenusCaseInputFields.colorNumber],
		data[rhenusCaseInputFields.colorNumberShould],
		data[rhenusCaseInputFields.amountOfParts],
		data[rhenusCaseInputFields.comment],
		data[rhenusCaseInputFields.photo]
			? {
					...data[rhenusCaseInputFields.photo],
					data: data[rhenusCaseInputFields.photo].data.split(',')[1],
			  }
			: undefined
	);
}
