import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import { Control, Controller } from 'react-hook-form';
import { rules, RulesInterface } from '../consts/rules';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import StandardTextField from './StandardTextField';

interface Props {
	edit: boolean;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	value: string | number;
	fieldKey: string;
	placeholder?: string;
	number?: string;
	rightAlign?: boolean;
	rules?: Partial<RulesInterface>;
	inputColor?: string;
}
function ExpensesTextFieldCellType(props: Props) {
	const classes = useStyles({ number: props.number || props.rightAlign ? true : false });
	const globalClasses = useGlobalStyles({
		number: props.number || props.rightAlign ? true : false,
		inputColor: props.inputColor,
	});
	const { t } = useTranslation();

	return !props.edit ? (
		<div className={classes.textWrapper}>
			<span
				className={globalClasses.smallTextDark}
				style={{ color: props.inputColor ? props.inputColor : colors.darkGray }}>
				{props.value}
			</span>
		</div>
	) : (
		<Controller
			defaultValue={props.value}
			control={props.control}
			name={props.fieldKey}
			rules={props.rules ? { ...props.rules } : { required: rules(t).required }}
			render={({ onChange, value }) => (
				<StandardTextField
					label={''}
					value={value}
					type={props.number}
					inputWrapper={classes.inputWrapper}
					placeholder={t(props.placeholder ? props.placeholder : '')}
					onChange={onChange}
					input={globalClasses.inputTableStyle}
					wrapper={props.number || props.rightAlign ? classes.inputEndWrapper : classes.wrapper}
					error={props.errors[props.fieldKey] ? true : false}
					helperText={props.errors[props.fieldKey] ? props.errors[props.fieldKey].message : ''}
				/>
			)}
		/>
	);
}

interface StyleProps {
	number: boolean;
}

const useStyles = makeStyles(() => ({
	wrapper: {
		width: '95%',
	},
	inputWrapper: {
		width: '100%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
	},
	inputEndWrapper: {
		width: '95%',
		marginLeft: 'auto',
	},
	textWrapper: (p: StyleProps) => ({
		display: 'flex',
		alignContent: 'center',
		justifyContent: p.number ? 'flex-end' : 'flex-start',
	}),
}));

export default ExpensesTextFieldCellType;
