import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LocationState, History } from 'history';
import { unstable_batchedUpdates } from 'react-dom';

import service from '../service/service';
import { Routes } from '../router/Routes';
import { filterData } from '../helpers/filters';
import { UserRole } from '../service/types/User';
import { contractFields } from './ContractContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import ContractOverviewScreen from '../screens/ContractOverviewScreen';
import Contract from '../service/types/Contract';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import { getCustomerOptionsKeyValuePairs } from '../helpers/customerHelper/readCustomerHelper';
import { ContractViewType, ContractStatusType } from '../helpers/contractHelper/generalContractHelper';
import { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';

export interface ContractOverviewFilterInterface {
	contractType: any;
	contractData?: any;
	status: any;
	isPhotoAllowed: any;
	automatedUpdates: any;
	contractNumber: any;
	customer: any;
	date: any;
	workLocation: any;
	partDesignation: any;
	markingOfIOparts: any;
	shiftTimes: any;
	errorTypes: any;
	typesOfInspection: any;
	assignedQAIs: any;
	assignedTeamLeader: any;
	fromDate?: any;
	toDate?: any;
}

export const initContractFilter: ContractOverviewFilterInterface = {
	customer: '',
	contractNumber: '',
	date: null,
	errorTypes: '',
	markingOfIOparts: '',
	partDesignation: '',
	shiftTimes: '',
	typesOfInspection: '',
	workLocation: '',
	assignedQAIs: '',
	assignedTeamLeader: '',
	automatedUpdates: '',
	contractType: '',
	isPhotoAllowed: '',
	contractData: '',
	status: '',
	fromDate: null,
	toDate: null,
};

const data = Object.freeze({ ...initContractFilter });
export type ContractFilterKeys = keyof typeof data;

export interface ContractContainerProps {
	buttonText: string;
	displaySort: boolean;
	redirectTo?: Routes;
	viewType: ContractViewType;
	route: (history: History<LocationState>, contract: Contract) => void;
}

function ContractOverviewContainer({ buttonText, displaySort, redirectTo, viewType, route }: ContractContainerProps) {
	const { t } = useTranslation();
	const [teamLeaders, setTeamLeaders] = useState<KeyValue[]>([]);
	const [assignedQAIs, setAssignedQAIs] = useState<KeyValue[]>([]);
	const [customers, setCustomers] = useState<KeyValue[]>([]);
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [filters, setFilters] = useState<ContractOverviewFilterInterface>(initContractFilter);
	const methods = useForm();
	const allContractsRef = useRef<Contract[]>([]);
	const fetchingData = useRef<any>({
		qai: createRef(),
		teamLeaders: createRef(),
		customer: createRef(),
	});
	const { user } = useAuthStateContext();
	const history = useHistory();

	const [intervalDates, setIntervalDates] = useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const onFilterChange = (key: ContractFilterKeys, value: any) => {
		setFilters((prev) => ({ ...prev, [key]: value }));
	};

	const onContractPress = (contract: Contract) => route(history, contract);

	const clearAllFilters = () => {
		Object.values(contractFields).forEach((value) => methods.setValue(value, ''));
		setFilters(initContractFilter);
	};

	useEffect(() => {
		setContracts(filterData(filters, allContractsRef.current));
	}, [filters]);

	const fetchData = useCallback(async () => {
		try {
			const contracts = await service.getContracts();
			let assignedQAIs: KeyValue[];
			let teamLeaders: KeyValue[];
			let customers: KeyValue[];

			if (user?.role !== UserRole.QualityAssuranceInspector && user?.role !== UserRole.Customer) {
				if (!fetchingData.current.qai.current) {
					fetchingData.current.qai.current = true;
					assignedQAIs = await getUserOptionsKeyValuePairs(service.getAllQAIs(), () => {});
				}
				if (!fetchingData.current.teamLeaders.current) {
					fetchingData.current.teamLeaders.current = true;
					teamLeaders = await getUserOptionsKeyValuePairs(service.getAllTeamLeaders(), () => {});
				}
				if (!fetchingData.current.customer.current) {
					fetchingData.current.customer.current = true;
					customers = await getCustomerOptionsKeyValuePairs(service.getAllCustomers(), () => {});
				}
			}

			unstable_batchedUpdates(() => {
				setContracts(contracts.filter((contract) => contract.status !== ContractStatusType.Closed));
				allContractsRef.current = contracts;
				setFilters({
					...initContractFilter,
				});

				if (assignedQAIs) setAssignedQAIs(assignedQAIs);
				if (teamLeaders) setTeamLeaders(teamLeaders.filter((el) => !el.value.deleted));
				if (customers) setCustomers(customers);
			});
		} catch (e) {
			fetchingData.current.qai.current = false;
			fetchingData.current.teamLeaders.current = false;
			fetchingData.current.customer.current = false;
		}
	}, [user?.role]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const onDateChange = useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);
			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	return (
		<FormProvider {...methods}>
			<ContractOverviewScreen
				intervalDates={intervalDates}
				assignedQAIs={assignedQAIs}
				buttonText={buttonText}
				buttonAction={onContractPress}
				contracts={contracts}
				customers={customers}
				displaySort={displaySort}
				filters={filters}
				redirectTo={redirectTo}
				route={route}
				viewType={viewType}
				teamLeaders={teamLeaders}
				onFilterChange={onFilterChange}
				clearAllFilters={clearAllFilters}
				onKeyboardDateChange={onKeyboardDateChange}
				onDateChange={onDateChange}
				onDatesAccept={onDatesAccept}
				onDatesCancel={onDatesCancel}
				clearDates={clearDates}
			/>
		</FormProvider>
	);
}

export default ContractOverviewContainer;
