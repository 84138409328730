import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Controller, Control } from 'react-hook-form';

import Button from '../components/Button';
import { colors } from '../consts/colors';
import AuthTextField from '../components/AuthTextField';
import BackArrow from '../svgComponents/BackArrow';
import { ForgotPasswordData } from '../consts/login';
import { useGlobalStyles } from '../consts/globalStyles';
import { loginFields } from '../containers/LoginContainer';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	dataForForgotPassword: ForgotPasswordData;
	onSearchPress: () => void;
}

function ForgotPasswordScreen(props: Props) {
	const globalClasses = useGlobalStyles({ description: colors.gray });
	const classes = useStyles({});
	const { t } = useTranslation();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
			</div>
			<Grid container className={classes.wrapper}>
				<Grid item sm={1} />
				<Grid item sm={4}>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t(props.dataForForgotPassword.title)}</span>
					</div>
					<div className={classes.descriptionWrapper}>
						<span className={globalClasses.description}>{t(props.dataForForgotPassword.description)}</span>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={loginFields.email}
							rules={props.dataForForgotPassword.rules(t).email}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('forgot_password_screen_email_placeholder')}
									error={props.errors[loginFields.email] ? true : false}
									helperText={
										props.errors[loginFields.email] ? props.errors[loginFields.email].message : ''
									}
									type="email"
									name="email"
									onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === 'Enter') {
											props.onSearchPress();
										}
									}}
								/>
							)}
						/>
					</div>
					<Button
						onClick={props.onSearchPress}
						text={t('forgot_password_screen_button')}
						textClassName={globalClasses.buttonText}
						buttonWrapperClassName={globalClasses.buttonWrapper}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		marginTop: '50px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},
	emailWrapper: {
		marginBottom: '24px',
	},
	descriptionWrapper: {
		marginBottom: '12px',
	},
}));

export default ForgotPasswordScreen;
