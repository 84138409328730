import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import Button from '../components/Button';
import GoBack from '../svgComponents/GoBack';
import HomeButton from '../svgComponents/HomeButton';
import MuiAutocomplete from '../components/MuiAutocomplete';
import StandardTextField from '../components/StandardTextField';

import { rules } from '../consts/rules';
import { fonts } from '../consts/fonts';
import User from '../service/types/User';
import service from '../service/service';
import { colors } from '../consts/colors';
import { Routes } from '../router/Routes';
import { useGlobalStyles } from '../consts/globalStyles';
import { useAtom } from 'jotai';
import { groupAtom } from '../atoms/group';
import Spinner from '../components/Spinner';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';

export interface UpdateGroup extends CreateGroup {
	groupId: number;
}
export interface CreateGroup {
	name: string;
	teamLeadsIds: number[];
}

type FormValues = {
	groupName: string;
	groupMembers: User[];
};

const formFields = {
	groupName: 'groupName',
	groupMembers: 'groupMembers',
};

export default function GroupCreatePage() {
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();
	const [, setGroups] = useAtom(groupAtom);
	const [loading, setLoading] = useState(false);
	const [teamLeaders, setTeamLeaders] = useState<User[]>([]);
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const globalClasses = useGlobalStyles({ description: colors.lightGray });
	const { control, handleSubmit, errors, formState, reset } = useForm<FormValues>({
		mode: 'onSubmit',
	});

	const resetForm = () => {
		reset({ groupMembers: [], groupName: '' });
	};

	const onSubmit = async (data: FormValues) => {
		const teamLeadsIds = data.groupMembers.map((el) => el.id);

		try {
			setLoading(true);
			const res = await service.createGroup({ name: data.groupName, teamLeadsIds });

			if (res) {
				const readGroups = await service.readGroups();
				setGroups(readGroups);
				history.goBack();
			}
		} finally {
			setLoading(false);
		}
	};

	const onAcceptDialog = () => history.goBack();
	const onCancelDialog = () => dialogRef.current?.setDialogState(false);

	const onCancel = () => {
		if (formState.isDirty) {
			dialogRef.current?.setDialogState(true);
		} else {
			resetForm();
			history.goBack();
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const teamLeaders = await service.getAllTeamLeaders();
				setTeamLeaders(teamLeaders.filter((el) => el.deleted === false));
			} catch {}
		})();
	}, []);

	if (loading) return <Spinner />;

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack edit={formState.isDirty} route={Routes.GroupOverview} />
				<HomeButton edit={formState.isDirty} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>{t('group_create_title')}</span>
			</div>

			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.subtitle}>{t('group_create_subtitle')}</span>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container style={{ marginBottom: '32px' }}>
					<Grid item sm={5}>
						<Controller
							defaultValue=""
							control={control}
							name={formFields.groupName}
							rules={{
								required: rules(t).required,
								minLength: rules(t).minLength(3),
							}}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('group_create_name').toUpperCase()}
									onChange={onChange}
									value={value}
									error={Boolean(errors.groupName)}
									helperText={Boolean(errors.groupName) ? errors.groupName?.message : ''}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={5}>
						<Controller
							defaultValue={[]}
							control={control}
							name={formFields.groupMembers}
							rules={{
								validate: (val) => {
									if (val === undefined) return rules(t).required.message;
									return val?.length === 0 ? rules(t).required.message : true;
								},
							}}
							render={({ onChange, value }) => (
								<MuiAutocomplete
									label={t('group_create_member').toUpperCase()}
									onChange={onChange}
									options={teamLeaders}
									value={value}
									disableCloseOnSelect
									placeholder=""
									error={Boolean(errors.groupMembers)}
									helperText={Boolean(errors.groupMembers) ? t('field_required') : ''}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<div className={classes.rowWrapper}>
					<input
						className={`${globalClasses.buttonText} ${globalClasses.buttonWrapper} ${classes.noMargin}`}
						type="submit"
						value={`${t('group_create_save')}`}
					/>

					<Button
						text={t('group_create_cancel')}
						textClassName={globalClasses.buttonTextInverted}
						buttonWrapperClassName={globalClasses.buttonWrapperInverted}
						onClick={onCancel}
					/>
				</div>
			</form>
			<StandardDialog
				ref={dialogRef}
				onAccept={onAcceptDialog}
				onCancle={onCancelDialog}
				acceptText={t('warning_data_dialog_accept_button')}
				title={t('warning_data_dialog_title')}
				description={t('warning_data_dialog_description')}
				cancleText={t('warning_data_dialog_cancel_button')}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '24px',
	},
	subtitleWrapper: {
		margin: '28px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
	noMargin: {
		margin: 'unset',
	},
	addMember: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
		textDecoration: 'underline',
		border: 'none',
		cursor: 'pointer',
		marginTop: '10px',
		marginLeft: 'auto',
		display: 'block',
		backgroundColor: 'transparent',
	},
}));
