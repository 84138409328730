import { TableCellType } from '../components/table/StandardTableCell';
import { UserTableInterface } from '../service/types/User';

export interface ContactPersonListInterface extends UserTableInterface {
	email: any;
	phonenumber: any;
}

export const contactPersonListParams: ContactPersonListInterface = {
	//@ts-ignore
	number: {
		title: '#',
		width: '7.27%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sticky: true,
	},
	firstName: {
		title: 'user_overview_screen_first_name',
		width: '21.36%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	lastName: {
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		title: 'user_overview_screen_last_name',
		width: '21.36%',
	},
	phonenumber: {
		title: 'customer_screen_phone_number',
		width: '20.45%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	email: {
		title: 'customer_screen_email_address',
		width: '20.45%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	//@ts-ignore
	edit: {
		title: '',
		width: '9.1%',
		type: TableCellType.Link,
		titleType: TableCellType.Title,
	},
};
