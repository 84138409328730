import React from 'react';
import { Grid, IconButton, makeStyles, MenuItem } from '@material-ui/core';
import { ArrayField, Control, Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
	ContractType,
	createContractErrorTypeKeyValuePairs,
	createContractIsPhotoKeyValuePairs,
} from '../helpers/contractHelper/generalContractHelper';
import HomeButton from '../svgComponents/HomeButton';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { contractFields, contractWorkLocationFields, IHourlyRateField } from '../containers/ContractContainer';
import StandardTextField from '../components/StandardTextField';
import StandardCheckbox from '../components/StandardCheckbox';
import { rules } from '../consts/rules';
import { colors } from '../consts/colors';
import { createContractTypeOfInspectionKeyValuePairs } from '../helpers/contractHelper/generalContractHelper';
import StandardAutoComplete from '../components/StandardAutoComplete';
import StandardRadioButton from '../components/StandardRadioButton';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import Contract, { IContractWorkLocation } from '../service/types/Contract';
import ErrorIcon from '../svgComponents/ErrorIcon';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import StandardDatePicker from '../components/StandardDatePicker';
import StandardFileInput, { FileInputIntreface } from '../components/StandardFileInput';
import AttachmentIcon from '../svgComponents/AttachmentIcon';
import PdfAttachmentIcon from '../svgComponents/PdfAttachmentIcon';
import User from '../service/types/User';
import GoBack from '../svgComponents/GoBack';
import { Routes } from '../router/Routes';
import { BillingCycleType } from '../consts/billingCycleType';
import { priceListOptions } from '../consts/priceListOptions';
import { ProjectType } from '../consts/projectType';
import { CalculationType } from '../consts/calculationType';
import DeleteIcon from '../svgComponents/DeleteIcon';
import { fonts } from '../consts/fonts';
import ShiftInputRow from '../components/ShiftInputRow';
import TableTextField from '../components/TableTextField';
import WorkLocationInputRow from '../components/WorkLocationInputRow';
import { groupAtom } from '../atoms/group';
import { useAtom } from 'jotai';
import Autocomplete from '../components/Autocomplete';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	shiftControl: Control<Record<string, any>>;
	shiftErrors: Record<string, any>;

	workLocationControl: Control<Record<string, any>>;
	workLocationErrors: Record<string, any>;

	onWorkLocationSubmitPress: () => void;

	workLocations: IContractWorkLocation[];

	register: UseFormMethods['register'];
	setValue: UseFormMethods['setValue'];
	getValues: UseFormMethods['getValues'];
	contractType: ContractType;
	teamLeaders: KeyValue[];
	qais: KeyValue[];
	customers: KeyValue[];
	isUserEditing: boolean;
	contract: Contract | undefined;
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	attachmentsInputRef: React.MutableRefObject<FileInputIntreface | undefined>;
	assignedQAIs: string[] | undefined;
	customer: string;
	hourlyRateFields: Partial<ArrayField<Record<string, any>, 'id'>>[];
	checkedShifts: string[];
	appendHourlyRateField: (
		value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
		shouldFocus?: boolean | undefined
	) => void;
	removeHourlyRateField: (index?: number | number[] | undefined) => void;
	onShiftCheckboxChange: (val: boolean, name: string) => void;
	onSummaryPress: () => void;
	onSubmitPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDialogPress: () => void;
	onDeleteContractDialogPress: () => void;
	clearShiftErrors: (name?: string | string[] | undefined) => void;
	setWorkLocations: React.Dispatch<React.SetStateAction<IContractWorkLocation[]>>;
}

export const SHIFT_NAME_WIDTH = '30%';
export const SHIFT_FROM_WIDTH = '20%';

function ContractScreen(props: Props) {
	const classes = useStyles({ disabled: !props.isUserEditing, contractStatus: props.contract?.status });
	const globalClasses = useGlobalStyles({ disabled: !props.isUserEditing });
	const { t } = useTranslation();

	const [groups] = useAtom(groupAtom);

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack route={Routes.AdminContracts} edit={props.isUserEditing} />
				<HomeButton edit={props.isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				{props.contract ? (
					<div className={classes.titleWrapper}>
						<div className={classes.titleRow}>
							<span className={globalClasses.title}>{props.contract.contractNumber}</span>
							<div className={classes.statusWrapper}>
								<span className={globalClasses.mediumTextDark}>
									{props.contract.status ? t('contract_screen_opened') : t('contract_screen_closed')}
								</span>
							</div>
						</div>
						<div className={classes.summaryWrapper} onClick={props.onSummaryPress}>
							<span className={globalClasses.link}>{t('contract_screen_summary')}</span>
						</div>
					</div>
				) : (
					<span className={globalClasses.title}>{t('contract_screen_title')}</span>
				)}
			</div>
			<Grid container>
				<Grid item sm={5}>
					<Controller
						defaultValue={moment()}
						control={props.control}
						name={contractFields.date}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('contract_screen_date_of_creation').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[contractFields.date] ? true : false}
								helperText={
									props.errors[contractFields.date] ? props.errors[contractFields.date].message : ''
								}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={4} />
				<Grid item sm={2}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.pdfContract}
						render={({ onChange, value }) => (
							<StandardFileInput
								label={'contract_screem_upload_pdf_label'}
								name={'contract_screem_upload_pdf'}
								value={value}
								icon={<PdfAttachmentIcon className={classes.iconWrappper} />}
								multiple={false}
								disabled={!props.isUserEditing}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Grid container className={classes.topWrapper}>
				<Grid item sm={5}>
					<div className={classes.labelWrapper}>
						<span className={globalClasses.label}>{t('contract_screen_type').toUpperCase()}</span>
					</div>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.contractType}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<div>
								<div className={classes.row}>
									<StandardRadioButton
										label={'contract_type_standard'}
										value={value}
										disabled={!props.isUserEditing}
										onChange={onChange}
										radioButtonValue={ContractType.Standard}
									/>
									<StandardRadioButton
										label={'contract_type_rhenus'}
										value={value}
										disabled={!props.isUserEditing}
										onChange={onChange}
										radioButtonValue={ContractType.RhenusType}
									/>
								</div>
								{props.errors[contractFields.contractType] ? (
									<div className={classes.errorRow}>
										<div className={classes.errorImageWrapper}>
											<ErrorIcon />
										</div>
										<span className={globalClasses.error}>
											{t(
												props.errors[contractFields.contractType]
													? props.errors[contractFields.contractType].message
													: ''
											)}
										</span>
									</div>
								) : null}
							</div>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<Controller
						defaultValue={[]}
						control={props.control}
						name={contractFields.zones}
						rules={{
							validate: (val: any) => {
								if (props.contractType === ContractType.RhenusType && val.length === 0) {
									return rules(t).required.message;
								} else return true;
							},
						}}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								label={t('contract_screen_avaiable_zones').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={props.contractType !== ContractType.RhenusType || !props.isUserEditing}
								multiple={true}
								disableCloseOnSelect
								withCheckbox={true}
								placeholder={'Zones'}
								noOptionText={'autocomplete_no_options_zone'}
								options={
									props.contract && props.contract.zones
										? [...props.contract.zones.map((zone) => zone.name)]
										: []
								}
								error={props.errors[contractFields.zones] ? true : false}
								helperText={
									props.errors[contractFields.zones] ? props.errors[contractFields.zones].message : ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<div className={classes.labelWrapper}>
						<span className={globalClasses.label}>{t('billing_cycle').toUpperCase()}</span>
					</div>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.billingCycle}
						// rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<div>
								<div className={classes.row}>
									<StandardRadioButton
										label={'bi-weekly'}
										value={value}
										disabled={!props.isUserEditing}
										onChange={onChange}
										radioButtonValue={BillingCycleType.BiWeekly}
									/>
									<StandardRadioButton
										label={'monthly'}
										value={value}
										disabled={!props.isUserEditing}
										onChange={onChange}
										radioButtonValue={BillingCycleType.Monthly}
									/>
								</div>
								{props.errors[contractFields.billingCycle] ? (
									<div className={classes.errorRow}>
										<div className={classes.errorImageWrapper}>
											<ErrorIcon />
										</div>
										<span className={globalClasses.error}>
											{t(
												props.errors[contractFields.billingCycle]
													? props.errors[contractFields.billingCycle].message
													: ''
											)}
										</span>
									</div>
								) : null}
							</div>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper} />
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.contractNumber}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contract_overview_screen_contract_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.contractNumber] ? true : false}
								helperText={
									props.errors[contractFields.contractNumber]
										? props.errors[contractFields.contractNumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.partDesignation}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contract_overview_screen_contract_part_designation').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.partDesignation] ? true : false}
								helperText={
									props.errors[contractFields.partDesignation]
										? props.errors[contractFields.partDesignation].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.projectType}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('project_type').toUpperCase()}
								select
								disabled={!props.isUserEditing}
								onChange={onChange}
								value={value}
								error={props.errors[contractFields.projectType] ? true : false}
								helperText={
									props.errors[contractFields.projectType]
										? props.errors[contractFields.projectType].message
										: ''
								}>
								<MenuItem value={ProjectType.WorkContract}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t('work_contract')}</span>
									</div>
								</MenuItem>
								<MenuItem value={ProjectType.TemporaryEmployment}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t('temporary_employment')}</span>
									</div>
								</MenuItem>
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.calculationType}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('calculation_type').toUpperCase()}
								select
								disabled={!props.isUserEditing}
								onChange={onChange}
								value={value}
								error={props.errors[contractFields.calculationType] ? true : false}
								helperText={
									props.errors[contractFields.calculationType]
										? props.errors[contractFields.calculationType].message
										: ''
								}>
								<MenuItem value={CalculationType.HourlyRate}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t('hourly_rate')}</span>
									</div>
								</MenuItem>
								<MenuItem value={CalculationType.UnitPrice}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t('unit_price')}</span>
									</div>
								</MenuItem>
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.administrationFee}
						rules={{ pattern: rules(t).numberPatternAny }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('administration_fee').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.administrationFee] ? true : false}
								helperText={
									props.errors[contractFields.administrationFee]
										? props.errors[contractFields.administrationFee].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.applicationIntake}
						rules={{ pattern: rules(t).numberPatternAny }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('application_intake').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.applicationIntake] ? true : false}
								helperText={
									props.errors[contractFields.applicationIntake]
										? props.errors[contractFields.applicationIntake].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				{/* Price list section */}
				<Grid container className={classes.wrapper}>
					<Grid item sm={5}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contractFields.priceListType}
							render={({ onChange, value }) => (
								<StandardAutoComplete
									label={t('price_list').toUpperCase()}
									onChange={(e) => {
										if (e) {
											const selected = e as string[];
											const oldValues = [...props.hourlyRateFields];

											const fields = selected.map((field) => {
												const value = oldValues.find((item) => item['selectedValue'] === field);

												const fieldObj = {
													value: value ? value['value'] : 0,
													selectedValue: field,
												};

												return fieldObj;
											});

											props.setValue(contractFields.hourlyRate, fields);
										} else {
											props.setValue(contractFields.hourlyRate, []);
										}

										onChange(e);
									}}
									options={priceListOptions.map((opt) => opt.label)}
									multiple={true}
									value={value}
									disableCloseOnSelect
									withOutAddOption={true}
									placeholder={t('price_list')}
									disabled={!props.isUserEditing}
									error={props.errors[contractFields.priceListType] ? true : false}
									helperText={
										props.errors[contractFields.priceListType]
											? props.errors[contractFields.priceListType].message
											: ''
									}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={1} />
					<Grid item sm={5}>
						{props.hourlyRateFields.map((field, index) => {
							return (
								// make sure to set field.id to top mappped item
								<div key={field.id}>
									<div className={classes.inputWrapper}>
										<div className={globalClasses.label}>
											{t(field['selectedValue']).toUpperCase()}
										</div>
										<input
											disabled={!props.isUserEditing}
											className={classes.inputForFields}
											ref={props.register()}
											onChange={(e) => {
												const value = e.target.value;

												const oldValues = [...props.hourlyRateFields];
												const newValue = { ...(oldValues[index] as IHourlyRateField) };
												newValue['value'] = value;
												oldValues[index] = newValue;
												props.setValue(contractFields.hourlyRate, oldValues);
											}}
											name={`${contractFields.hourlyRate}[${index}].value`}
											defaultValue={field.value} // make sure to include defaultValue
										/>

										{props.isUserEditing && (
											<IconButton
												color="primary"
												onClick={() => {
													const previousPriceListType: string[] = props.getValues(
														contractFields.priceListType
													);

													const newPriceListType = previousPriceListType.filter(
														(item) =>
															item !== props.hourlyRateFields[index]['selectedValue']
													);

													props.removeHourlyRateField(index);
													props.setValue(contractFields.priceListType, newPriceListType);
												}}>
												<DeleteIcon />
											</IconButton>
										)}
									</div>
								</div>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
			<div className={globalClasses.underline} />
			<Grid container className={classes.wrapper}>
				<Grid item sm={5}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.orderDescription}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contract_screen_order_description').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.orderDescription] ? true : false}
								helperText={
									props.errors[contractFields.orderDescription]
										? props.errors[contractFields.orderDescription].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.customer}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								label={t('contract_overview_screen_contract_customer').toUpperCase()}
								onChange={onChange}
								options={props.customers.map((customer) => customer.key)}
								multiple={false}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.customer] ? true : false}
								helperText={
									props.errors[contractFields.customer]
										? props.errors[contractFields.customer].message
										: ''
								}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<div className={classes.shiftWrapper}>
						<div className={classes.labelWrapper}>
							<span className={globalClasses.label}>
								{t('contract_overview_screen_contract_work_location').toUpperCase()}
							</span>

							{Object.keys(props.workLocationErrors).length > 0 ? (
								<div className={classes.errorRow}>
									<div className={classes.shiftErrorImageWrapper}>
										<ErrorIcon />
									</div>
									<span className={globalClasses.error}>{t('invalid_input')}</span>
								</div>
							) : null}
						</div>
						<div className={classes.tableTitleWrapper}>
							<div className={classes.shiftNameWrapper}>
								<span className={globalClasses.tableTitle}>{t('location')}</span>
							</div>
							<div className={classes.fromWrapper}>
								<span className={globalClasses.tableTitle}>{t('distance')}</span>
							</div>
						</div>
						<div className={classes.row123}>
							<div className={classes.shiftNameWrapper}>
								<Controller
									defaultValue={''}
									control={props.workLocationControl}
									disabled={!props.isUserEditing}
									name={contractWorkLocationFields.name}
									rules={{ required: rules(t).required }}
									render={({ onChange, value }) => (
										<TableTextField
											value={value}
											onChange={onChange}
											placeholder={t('location')}
											disabled={!props.isUserEditing}></TableTextField>
									)}
								/>
							</div>
							<div className={classes.shiftNameWrapper}>
								<Controller
									defaultValue={''}
									control={props.workLocationControl}
									disabled={!props.isUserEditing}
									name={contractWorkLocationFields.distance}
									rules={{ pattern: rules(t).numberPatternAny, required: rules(t).required }}
									render={({ onChange, value }) => (
										<TableTextField
											value={value}
											onChange={onChange}
											placeholder={t('distance')}
											disabled={!props.isUserEditing}></TableTextField>
									)}
								/>
							</div>

							{!props.isUserEditing ? null : (
								<div className={classes.add} onClick={props.onWorkLocationSubmitPress}>
									<span className={globalClasses.standardLink}>{t('contract_screen_shift_add')}</span>
								</div>
							)}
						</div>

						{props.workLocations.map((location, index) => (
							<WorkLocationInputRow
								isUserEditing={props.isUserEditing}
								workLocation={location}
								setWorkLocations={props.setWorkLocations}
								key={`${location.name}-${location.distance}`}
								index={index}
							/>
						))}
					</div>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.contactPerson}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								label={t('contract_screen_contact_person').toUpperCase()}
								placeholder={t('contract_screen_contact_person_tab')}
								onChange={onChange}
								value={value}
								multiple
								disableCloseOnSelect
								withOutAddOption={true}
								options={
									props.customers &&
									props.customers.find((customer) => customer.key === props.customer)
										? props.customers
												.find((customer) => customer.key === props.customer)!
												.value.users.map((user: User) => `${user.firstName} ${user.lastName}`)
										: []
								}
								disabled={!props.isUserEditing || !props.customer}
								error={props.errors[contractFields.contactPerson] ? true : false}
								helperText={
									props.errors[contractFields.contactPerson]
										? props.errors[contractFields.contactPerson].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<div className={globalClasses.underline} />
			<Grid container className={classes.wrapper}>
				<Grid item sm={5}>
					<Controller
						defaultValue={[]}
						control={props.control}
						name={contractFields.tlGroups}
						render={({ onChange, value }) => (
							<Autocomplete
								disabled={!props.isUserEditing}
								label={t('contract_screen_team_leader_group').toUpperCase()}
								onChange={onChange}
								options={groups.map((group) => ({ id: group.groupId, name: group.name })) ?? []}
								value={value}
								disableCloseOnSelect={false}
								multiple
								placeholder=""
								error={props.errors[contractFields.tlGroups] ? true : false}
								helperText={
									props.errors[contractFields.tlGroups]
										? props.errors[contractFields.tlGroups].message
										: ''
								}
							/>
						)}
					/>

					<div className={classes.fieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contractFields.assignTeamLeader}
							rules={{ required: rules(t).required }}
							render={({ onChange, value }) => (
								<StandardAutoComplete
									label={t('contract_screen_assign_team_leader').toUpperCase()}
									onChange={onChange}
									options={props.teamLeaders.map((teamLeader) => teamLeader.key)}
									multiple={false}
									value={value}
									disabled={!props.isUserEditing}
									error={props.errors[contractFields.assignTeamLeader] ? true : false}
									helperText={
										props.errors[contractFields.assignTeamLeader]
											? props.errors[contractFields.assignTeamLeader].message
											: ''
									}
								/>
							)}
						/>
					</div>

					<div className={classes.fieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contractFields.assignQAI}
							rules={{ required: rules(t).required }}
							render={({ onChange, value }) => (
								<StandardAutoComplete
									label={t('contract_screen_assign_qais').toUpperCase()}
									onChange={onChange}
									options={props.qais.map((qai) => qai.key)}
									multiple={true}
									value={value}
									disableCloseOnSelect
									withOutAddOption={true}
									withCheckbox={true}
									placeholder={'QAIs'}
									disabled={props.qais.length === 0 || !props.isUserEditing}
									error={props.errors[contractFields.assignQAI] ? true : false}
									helperText={
										props.errors[contractFields.assignQAI]
											? props.errors[contractFields.assignQAI].message
											: ''
									}
								/>
							)}
						/>
					</div>
					<div className={classes.fieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={contractFields.contactQAI24}
							rules={{ required: rules(t).required }}
							render={({ onChange, value }) => (
								<StandardAutoComplete
									label={t('contract_screen_contact_qai_24').toUpperCase()}
									onChange={onChange}
									options={props.assignedQAIs ? props.assignedQAIs : []}
									value={value}
									withOutAddOption={true}
									disabled={
										!props.assignedQAIs || props.assignedQAIs.length === 0 || !props.isUserEditing
									}
									error={props.errors[contractFields.contactQAI24] ? true : false}
									helperText={
										props.errors[contractFields.contactQAI24]
											? props.errors[contractFields.contactQAI24].message
											: ''
									}
								/>
							)}
						/>
					</div>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<div className={classes.shiftWrapper}>
						<div className={classes.labelWrapper}>
							<span className={globalClasses.label}>{t('contract_screen_shifts').toUpperCase()}</span>
							{Object.keys(props.shiftErrors).length > 0 ? (
								<div className={classes.errorRow}>
									<div className={classes.shiftErrorImageWrapper}>
										<ErrorIcon />
									</div>
									<span className={globalClasses.error}>{t('contract_screen_shift_error')}</span>
								</div>
							) : null}
						</div>
						<div className={classes.tableTitleWrapper}>
							<div className={classes.shiftNameWrapper}>
								<span className={globalClasses.tableTitle}>{t('contract_screen_shift_name')}</span>
							</div>
							<div className={classes.fromWrapper}>
								<span className={globalClasses.tableTitle}>{t('contract_screen_shift_from')}</span>
							</div>
							<div className={classes.fromWrapper}>
								<span className={globalClasses.tableTitle}>{t('contract_screen_shift_to')}</span>
							</div>
						</div>
					</div>

					<ShiftInputRow
						shiftControl={props.shiftControl}
						shiftErrors={props.shiftErrors}
						shiftTypeName="earlyShift"
						shiftNameLabel="earlyShift"
						isUserEditing={props.isUserEditing}
						checkedShifts={props.checkedShifts}
						onShiftCheckboxChange={props.onShiftCheckboxChange}
						clearShiftErrors={props.clearShiftErrors}
					/>
					<ShiftInputRow
						shiftControl={props.shiftControl}
						shiftErrors={props.shiftErrors}
						shiftTypeName="dayShift"
						shiftNameLabel="dayShift"
						isUserEditing={props.isUserEditing}
						checkedShifts={props.checkedShifts}
						onShiftCheckboxChange={props.onShiftCheckboxChange}
						clearShiftErrors={props.clearShiftErrors}
					/>
					<ShiftInputRow
						shiftControl={props.shiftControl}
						shiftErrors={props.shiftErrors}
						shiftTypeName="lateShift"
						shiftNameLabel="lateShift"
						isUserEditing={props.isUserEditing}
						checkedShifts={props.checkedShifts}
						onShiftCheckboxChange={props.onShiftCheckboxChange}
						clearShiftErrors={props.clearShiftErrors}
					/>
					<ShiftInputRow
						shiftControl={props.shiftControl}
						shiftErrors={props.shiftErrors}
						shiftTypeName="nightShift"
						shiftNameLabel="nightShift"
						isUserEditing={props.isUserEditing}
						checkedShifts={props.checkedShifts}
						onShiftCheckboxChange={props.onShiftCheckboxChange}
						clearShiftErrors={props.clearShiftErrors}
					/>
				</Grid>
			</Grid>
			<div className={globalClasses.underline} />
			{/* Arrival section */}
			<Grid container className={classes.wrapper}>
				<Grid item sm={5}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.drivingRate}
						rules={{ pattern: rules(t).numberPatternAny }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('driving_rate').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.drivingRate] ? true : false}
								helperText={
									props.errors[contractFields.drivingRate]
										? props.errors[contractFields.drivingRate].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.driving_time}
						rules={{ pattern: rules(t).numberPatternAny }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('driving_time').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.driving_time] ? true : false}
								helperText={
									props.errors[contractFields.driving_time]
										? props.errors[contractFields.driving_time].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.kilometer}
						rules={{ pattern: rules(t).numberPatternAny }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('kilometers').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.kilometer] ? true : false}
								helperText={
									props.errors[contractFields.kilometer]
										? props.errors[contractFields.kilometer].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
			</Grid>
			<div className={globalClasses.underline} />
			<Grid container className={classes.wrapper}>
				<Grid item sm={5}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.errorTypes}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								label={t('contract_overview_screen_contract_error_types').toUpperCase()}
								onChange={onChange}
								value={value}
								multiple={true}
								disableCloseOnSelect
								withCheckbox={true}
								placeholder={'Errors'}
								disabled={!props.isUserEditing}
								options={createContractErrorTypeKeyValuePairs.map((item) => item.value)}
								error={props.errors[contractFields.errorTypes] ? true : false}
								helperText={
									props.errors[contractFields.errorTypes]
										? props.errors[contractFields.errorTypes].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.typesOfInspection}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								label={t('contract_overview_screen_contract_types_of_inspection').toUpperCase()}
								onChange={onChange}
								options={createContractTypeOfInspectionKeyValuePairs.map((item) => item.value)}
								multiple={true}
								value={value}
								disableCloseOnSelect
								withCheckbox={true}
								placeholder={'Types'}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.typesOfInspection] ? true : false}
								helperText={
									props.errors[contractFields.typesOfInspection]
										? props.errors[contractFields.typesOfInspection].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={contractFields.markingOfIOparts}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contract_overview_screen_contract_mark_of_io_parts').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[contractFields.markingOfIOparts] ? true : false}
								helperText={
									props.errors[contractFields.markingOfIOparts]
										? props.errors[contractFields.markingOfIOparts].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={false}
						control={props.control}
						name={contractFields.takingPhotos}
						rules={{
							//@ts-ignore
							validate: (item) => {
								if (item !== undefined) {
									return true;
								} else return t('field_required');
							},
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('contract_screen_taking_photos').toUpperCase()}
								select
								disabled={!props.isUserEditing}
								onChange={onChange}
								value={value}
								error={props.errors[contractFields.takingPhotos] ? true : false}
								helperText={
									props.errors[contractFields.takingPhotos]
										? props.errors[contractFields.takingPhotos].message
										: ''
								}>
								{createContractIsPhotoKeyValuePairs.map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={2} className={classes.fieldWrapper}>
					<Controller
						defaultValue={[]}
						control={props.control}
						name={contractFields.attachments}
						render={({ onChange, value }) => (
							<StandardFileInput
								label={'contract_screem_upload_attachments_label'}
								name={'contract_screem_upload_attachments'}
								value={value}
								icon={<AttachmentIcon className={classes.iconWrappper} />}
								ref={props.attachmentsInputRef}
								multiple={true}
								disabled={!props.isUserEditing}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={4} />

				{props.contractType === ContractType.RhenusType ? (
					<Grid item sm={5} className={classes.fieldWrapper}>
						<Controller
							defaultValue={props.contract?.amountOfParts}
							control={props.control}
							name={contractFields.amountOfParts}
							rules={{ required: rules(t).required }}
							render={({ onChange, value }) => (
								<StandardAutoComplete
									label={t('contract_overview_screen_contract_amount_of_parts').toUpperCase()}
									onChange={onChange}
									value={value}
									multiple={true}
									disableCloseOnSelect
									withCheckbox={true}
									placeholder={''}
									disabled={!props.isUserEditing}
									options={
										props.contract && props.contract.amountOfParts
											? props.contract.amountOfParts
											: []
									}
									error={props.errors[contractFields.amountOfParts] ? true : false}
									helperText={
										props.errors[contractFields.amountOfParts]
											? props.errors[contractFields.amountOfParts].message
											: ''
									}
								/>
							)}
						/>
					</Grid>
				) : null}
			</Grid>
			<Controller
				defaultValue={true}
				control={props.control}
				name={contractFields.automatedUpdates}
				render={({ onChange, value }) => (
					<StandardCheckbox
						onChange={onChange}
						value={value}
						disabled={!props.isUserEditing}
						name={t('contract_screen_automated_updates')}
					/>
				)}
			/>
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'contract_screen_cancel'}
					updateButtonText={'contract_screen_update'}
					createButtonText={'contract_screen_create'}
					deleteButtonText={'contract_screen_delete'}
					editButtonText={'contract_screen_edit'}
					data={props.contract}
					isUserEditing={props.isUserEditing}
					onCancelPress={props.onCancelPress}
					onDeletePress={props.onDeletePress}
					onSubmitPress={props.onSubmitPress}
				/>
			</div>
			<StandardDialog
				acceptText={'contract_screen_dialog_accept_button'}
				description={'contract_screen_dialog_description'}
				title={'contract_screen_dialog_title'}
				cancleText={'contract_screen_dialog_cancel_button'}
				onCancle={props.onCancelDialogPress}
				onAccept={props.onDeleteContractDialogPress}
				ref={props.dialogRef}
				showWarning
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
	contractStatus?: boolean;
}

const useStyles = makeStyles(() => ({
	labelWrapper: {
		alignItems: 'center',
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre',
	},
	titleWrapper: {
		marginBottom: '12px',
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},
	fieldWrapper: { marginTop: '32px' },
	topWrapper: {
		marginBottom: '43px',
		marginTop: '25px',
	},

	wrapper: {
		marginTop: '40px',
		marginBottom: '43px',
	},
	rowWrapper: {
		display: 'flex',
		marginTop: '48px',
		alignItems: 'center',
		marginBottom: '32px',
	},
	checkboxWrapper: {},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputForFields: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
		border: `1px solid ${colors.borderColor}`,
		borderRadius: '4px',
	}),
	inputWrapper: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		transform: 'translateY(-2px)',
	},
	dropdownContainer: {
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
	},
	menuItem: {
		padding: 0,
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		height: '32px',
	},
	assignQAIWrapper: { display: 'flex', flex: 1, flexDirection: 'column' },
	add: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		cursor: 'pointer',
		marginRight: '24px',
	},
	tableTitleWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		borderBottom: `2px solid #656565`,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	shiftErrorImageWrapper: { marginRight: '5px', height: '16px', marginLeft: '10px' },
	errorRow: {
		display: 'flex',
		alignItems: 'center',
	},
	statusWrapper: (p: StyleProps) => ({
		padding: '8px 10px',
		marginLeft: '20px',
		borderRadius: '4px',
		height: 'fit-content',
		alignSelf: 'center',
		backgroundColor: p.contractStatus ? colors.lightGreen : colors.lightPink,
	}),
	titleRow: {
		display: 'flex',
	},
	summaryWrapper: {
		marginRight: '16px',
		cursor: 'pointer',
		alignSelf: 'flex-end',
	},
	iconWrappper: {
		marginRight: '12px',
	},
	dropdownInput: {
		margin: 0,
		color: colors.darkGray,
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
		whiteSpace: 'pre-wrap',
	},
	dropDownInputWrapper: {
		display: 'flex',
		alignItems: 'center',
		transform: 'translateY(-2px)',
	},
	shiftNameWrapper: {
		width: SHIFT_NAME_WIDTH,
		padding: '6px 12px',
		display: 'flex',
		alignSelf: 'flex-start',
	},
	shiftWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	fromWrapper: {
		width: SHIFT_FROM_WIDTH,
		padding: '6px 12px',
		display: 'flex',
		alignSelf: 'flex-start',
	},
	row123: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #C7C7C7',
		backgroundColor: colors.white,
	},
}));

export default ContractScreen;
