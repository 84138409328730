import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import service from '../service/service';
import { RouteParams, Routes } from '../router/Routes';
import { InvoiceItem } from '../service/types/Invoice';
import { StandardDialogInterface } from '../components/StandardDialog';
import InvoiceScreen from '../screens/InvoiceScreen';

export const invoiceInputFields = {
	creation_date: 'creation_date',
	date_of_invoice: 'date_of_invoice',
	from_date: 'from_date',
	to_date: 'to_date',
	bill_number: 'bill_number',

	number_of_shifts: '',
	number_of_workers: '',
	//QA
	inspector_hours: '',
	inspector_late_hours: '',
	inspector_night_hours: '',
	inspector_sunday_night_hours: '',
	inspector_week_overtime_hours: '',

	//TEAMLEADER
	teamlead_hours: '',
	teamlead_late_hours: '',
	teamlead_night_hours: '',
	teamlead_sunday_night_hours: '',
	teamlead_week_overtime_hours: '',

	//RESIDENT
	// resident_hours: '',
	// resident_late_hours: '',
	// resident_night_hours: '',
	// resident_sunday_night_hours: '',
	// resident_week_overtime_hours: '',

	gloves: '',
	travel_time: '',
	kilometers: '',
	travel_fee: '',
	administration_fee: '',
	application_intake: '',
	customer: '',
};

function InvoiceContainer() {
	const { control, handleSubmit, errors, reset } = useForm();

	const [isUserEditing, setIsUserEditing] = React.useState(false);

	const [invoice, setInvoice] = React.useState<InvoiceItem | undefined>(undefined);

	const dialogRef = React.useRef<null | StandardDialogInterface>(null);

	const history = useHistory();

	const routeParams: RouteParams = useParams();

	React.useEffect(() => {
		const id = routeParams.invoice_id;

		if (id) {
			service
				.getInvoiceById(parseInt(id))
				.then(setInvoice)
				.catch(() => {});
		}
	}, [routeParams.invoice_id]);

	const onCancelPress = () => {
		if (invoice) {
			reset({
				[invoiceInputFields.creation_date]: invoice.creation_date,
				[invoiceInputFields.from_date]: invoice.from_date,
				[invoiceInputFields.to_date]: invoice.to_date,
				[invoiceInputFields.bill_number]: invoice.bill_number ? invoice.bill_number : 0,
				[invoiceInputFields.date_of_invoice]: invoice.date_of_invoice,
			});
			setIsUserEditing(false);
		}
	};

	const onDeletePress = () => dialogRef.current?.setDialogState(true);
	const onCancelDialogPress = () => dialogRef.current?.setDialogState(false);

	const onUpdatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (invoice) {
					service
						.updateInvoice(
							invoice.id,
							data[invoiceInputFields.creation_date],
							data[invoiceInputFields.date_of_invoice],
							data[invoiceInputFields.from_date],
							data[invoiceInputFields.to_date],
							data[invoiceInputFields.bill_number]
						)
						.then(() => {
							setIsUserEditing(false);
						});
				}
			}),
		[handleSubmit, invoice]
	);

	const onSubmitPress = () => {
		if (invoice) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		}
	};

	const onEditPress = () => setIsUserEditing(true);

	const onDeleteInvoiceDialogPress = () => {
		if (invoice) {
			service.deleteInvoice(invoice.id).then(() => {
				history.push(`${Routes.Summary}/${invoice.contract_id}`);
			});
		}
	};

	const closeBilling = () => {
		if (invoice) {
			service.completeInvoice(invoice.id).then(() => {
				setInvoice((prev) => {
					return prev ? { ...prev, completed: true } : undefined;
				});
			});
		}
	};

	const downloadAsPdf = () => {
		if (invoice) {
			service.exportInvoice(invoice.id).then((res) => {
				if (res) {
					const linkSource = `data:application/pdf;base64,${res}`;
					const downloadLink = document.createElement('a');
					const fileName = `${invoice.id.toString()}.pdf`;
					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				}
			});
		}
	};

	if (!invoice) {
		return <></>;
	}

	return (
		<InvoiceScreen
			onCancelDialogPress={onCancelDialogPress}
			onCancelPress={onCancelPress}
			onDeletePress={onDeletePress}
			onSubmitPress={onSubmitPress}
			onDeleteInvoiceDialogPress={onDeleteInvoiceDialogPress}
			closeBilling={closeBilling}
			downloadAsPdf={downloadAsPdf}
			control={control}
			dialogRef={dialogRef}
			errors={errors}
			isUserEditing={isUserEditing}
			invoice={invoice}
		/>
	);
}

export default InvoiceContainer;
