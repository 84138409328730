import { OriginOfEmployee, UserRole } from '../../service/types/User';
import { DEFAULT_NO_VALUE } from '../standardInputHelper/generalStandardInputHelper';

export interface UserLoginParams {
	email: string;
	password: string;
}

export interface QAILoginParams {
	username: string;
	password: string;
}

export interface ResetPasswordParams {
	uuid: string;
	password: string;
}

export interface JWT_TOKEN_PARAMS {
	role: number;
	sub: string;
}

export interface KeyValue {
	key: string;
	value: any;
}

export const filterUserRoleKeyValuePairs: KeyValue[] = [
	DEFAULT_NO_VALUE,
	{ key: 'user_role_administrator', value: UserRole.Administrator },
	{ key: 'user_role_team_leader', value: UserRole.TeamLeader },
	{ key: 'user_role_qai', value: UserRole.QualityAssuranceInspector },
	{ key: 'user_role_customer', value: UserRole.Customer },
	{ key: 'user_role_accounting', value: UserRole.Accounting },
];

export const filterUserRole: KeyValue[] = [
	{ key: 'user_role_administrator', value: UserRole.Administrator },
	{ key: 'user_role_team_leader', value: UserRole.TeamLeader },
	{ key: 'user_role_qai', value: UserRole.QualityAssuranceInspector },
	{ key: 'user_role_customer', value: UserRole.Customer },
	{ key: 'user_role_accounting', value: UserRole.Accounting },
];

export const createUserRoleKeyValuePairs: KeyValue[] = [
	{ key: 'user_role_administrator', value: UserRole.Administrator },
	{ key: 'user_role_team_leader', value: UserRole.TeamLeader },
	{ key: 'user_role_qai', value: UserRole.QualityAssuranceInspector },
	{ key: 'user_role_accounting', value: UserRole.Accounting },
];

export const externalEmployeeRoles: KeyValue[] = [
	...createUserRoleKeyValuePairs,
	{ key: 'user_role_customer', value: UserRole.Customer },
];

export const originOfEmployeeKeyValuePairs: KeyValue[] = [
	{ key: 'user_origin_from_iqr', value: OriginOfEmployee.FromIQR },
	{ key: 'user_origin_external', value: OriginOfEmployee.External },
];

export const filterOriginOfEmployeeKeyValuePairs: KeyValue[] = [
	DEFAULT_NO_VALUE,
	{ key: 'user_origin_from_iqr', value: OriginOfEmployee.FromIQR },
	{ key: 'user_origin_external', value: OriginOfEmployee.External },
];

export const filterOriginOfEmployee: KeyValue[] = [
	{ key: 'user_origin_from_iqr', value: OriginOfEmployee.FromIQR },
	{ key: 'user_origin_external', value: OriginOfEmployee.External },
];
