import WorkTimeTrack from '../../service/types/WorkTimeTrack';

export type WorkingTimeState = {
	record: WorkTimeTrack | undefined;
};

export type Action = {
	type: string;
	[key: string]: any;
};

export const workTimeInitialState: WorkingTimeState = {
	record: undefined,
};

export const SET_RECORD = 'SET_RECORD';
export const START_TRACKING_TIME = 'START_TRACKING_TIME';
export const STOP_TRACKING_TIME = 'STOP_TRACKING_TIME';

const workingTimeReducer = (state = workTimeInitialState, action: Action) => {
	switch (action.type) {
		case SET_RECORD:
			return { ...state, record: action.record };
		case START_TRACKING_TIME:
			return { ...state, record: action.record };
		case STOP_TRACKING_TIME:
			return { ...state, record: undefined };
		default:
			return state;
	}
};

export default workingTimeReducer;
