import React from 'react';
import { useAuthActionContext, useAuthStateContext } from '../context/auth/useAuth';

import HomeScreen from '../screens/HomeScreen';
import service from '../service/service';

interface Props {}

function HomeContainer(props: Props) {
	const { user } = useAuthStateContext();
	const { setUser } = useAuthActionContext();

	React.useEffect(() => {
		if (!user || user.firstName === '') {
			service
				.getUserInfo()
				.then((user) => {
					if (user) setUser(user);
				})
				.catch(() => {});
		}
	}, [user, setUser]);

	return <HomeScreen />;
}

export default HomeContainer;
