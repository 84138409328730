import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';

import { Group } from '../GroupOverview';

import { fonts } from '../../../consts/fonts';
import { Routes } from '../../../router/Routes';
import { colors } from '../../../consts/colors';
import { useGlobalStyles } from '../../../consts/globalStyles';

const TABLE_HEAD = [
	{
		name: 'group_overview_group_name',
		width: '5vw',
	},
	{
		name: 'group_overview_group_members',
		width: '20vw',
	},
	{
		name: '',
		width: '2vw',
	},
];

interface Props {
	groups: Group[];
}

function GroupList({ groups }: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles({ description: colors.gray });
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<TableContainer>
				<Table className={classes.fixed}>
					<TableHead>
						<TableRow>
							{TABLE_HEAD.map((el) => (
								<TableCell
									key={el.name}
									className={globalClasses.tableTitleWrapper}
									style={{ width: el.width }}>
									<span className={globalClasses.tableTitle}>{t(el.name)}</span>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{groups?.map((group) => (
							<TableRow key={group.groupId} className="table-row">
								<TableCell
									className={`${globalClasses.description} ${classes.itemWrapper}`}
									style={{ width: '5vw' }}>
									<span className={globalClasses.description}>{group.name}</span>
								</TableCell>

								<TableCell
									className={`${globalClasses.description} ${classes.itemWrapper}`}
									style={{ width: '20vw' }}>
									<span className={globalClasses.description}>
										{group.users.map((el) => el.name).join(', ')}
									</span>
								</TableCell>

								<TableCell
									className={`${globalClasses.description} ${classes.itemWrapper}`}
									style={{ width: '2vw' }}>
									<Link
										to={{ pathname: `${Routes.GroupUpdate}/${group.groupId}`, state: { ...group } }}
										className={classes.link}>
										<span className={globalClasses.description}>{t('group_overview_edit')}</span>
									</Link>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	container: {
		margin: '48px 0px',
		overflow: 'auto',
	},
	fixed: {
		tableLayout: 'fixed',
		borderCollapse: 'separate',
	},
	itemWrapper: {
		padding: '14px 16px',
		overflow: 'hidden',
		left: '0px',
	},
	link: {
		fontFamily: fonts.regular,
		lineHeight: '20px',
		fontSize: '14px',
		color: colors.darkGray,
		textDecoration: 'underline !important',
		cursor: 'pointer',
	},
}));

export default GroupList;
