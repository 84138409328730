import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCellWithCss } from './table/StandardTableCell';
import { Control, Controller } from 'react-hook-form';
import { ExpenseTabelParamsInterface } from '../consts/material';
import { addExpenseFields } from './AddExpensesItem';
import { useGlobalStyles } from '../consts/globalStyles';
import { AddExpensesItemKeys } from './AddExpensesItem';
import KeyValueAutoComplete from './KeyValueAutoComplete';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { rules } from '../consts/rules';

interface Props {
	params: ExpenseTabelParamsInterface;
	fieldKey: AddExpensesItemKeys;
	errors: Record<string, any>;
	control: Control<Record<string, any>>;
	mainClass: string;
	wrapper: string;
	inputKeyValueWrapper: string;
}

function AddExpensesItemKeyValue(props: Props) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	return (
		<TableCellWithCss
			className={props.mainClass}
			style={{
				width: props.params.width,
			}}>
			<Controller
				defaultValue={{ key: '', value: '' }}
				control={props.control}
				name={addExpenseFields[props.fieldKey]}
				rules={
					props.params.rules
						? { ...props.params.rules }
						: {
								required: rules(t).required,
								validate: (val: KeyValue | undefined) => {
									if (val && (val.value || typeof val.value === 'boolean')) {
										return true;
									}
									return rules(t).required.message;
								},
						  }
				}
				render={({ onChange, value }) => (
					<KeyValueAutoComplete
						label={''}
						value={value}
						inputWrapper={props.inputKeyValueWrapper}
						placeholder={t(props.params.placeholder)}
						onChange={onChange}
						wrapper={props.wrapper}
						options={props.params.options ? props.params.options : []}
						withoutArrow={true}
						withoutClose={true}
						customGetOptionLabel={(option) => {
							return t(option.key);
						}}
						error={props.errors[addExpenseFields[props.fieldKey]] ? true : false}
						helperText={
							props.errors[addExpenseFields[props.fieldKey]]
								? props.errors[addExpenseFields[props.fieldKey]].message
								: ''
						}
						input={globalClasses.inputTableStyle}
					/>
				)}
			/>
		</TableCellWithCss>
	);
}

export default AddExpensesItemKeyValue;
