import * as React from 'react';

function SearchIcon(props: any) {
	return (
		<svg width={14} height={14} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g transform="translate(1 1)" stroke="#A1A1A1" fill="none" fillRule="evenodd" strokeLinecap="square">
				<path strokeWidth={1.2} d="M12 12L9.6 9.6" />
				<circle strokeWidth={1.5} cx={4.8} cy={4.8} r={4.8} />
			</g>
		</svg>
	);
}

export default SearchIcon;
