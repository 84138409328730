import moment, { Moment } from 'moment';
import { IPriceListItem } from '../../consts/IPriceListItem';
import {
	ContractStatusType,
	ContractType,
	ContractZoneFromJSON,
	ContractZone,
	ContractZoneToJSON,
	ShiftFromJSON,
	ShiftInterface,
	ShiftToJSON,
	FileInterface,
} from '../../helpers/contractHelper/generalContractHelper';
import { GroupUser } from '../../pages/groupOverview/GroupOverview';
import { IdNamePair } from './User';

export interface ContractSummaryInterface {
	billedHours: number;
	employeeTotalAmount: number;
	notBilledHours: number;
	totalBillableHours: number;
}

export interface ContractFilterInterface {
	contractNumber: any;
	customer: any;
	date: any;
	workLocation: any;
	partDesignation: any;
	markingOfIOparts: any;
	shiftTimes: any;
	errorTypes: any;
	typesOfInspection: any;
	assignedQAIs: any;
	assignedTeamLeader: any;
	fromDate?: any;
	toDate?: any;
}

export interface ContractInterface {
	contractType: any;
	contractData?: any;
	status: any;
	isPhotoAllowed: any;
	automatedUpdates: any;
	contractNumber: any;
	customer: any;
	date: any;
	workLocations: any;
	partDesignation: any;
	markingOfIOparts: any;
	shiftTimes: any;
	errorTypes: any;
	typesOfInspection: any;
	assignedQAIs: any;
	assignedTeamLeader: any;
	fromDate?: any;
	toDate?: any;
}

export interface IContractWorkLocation {
	id: number;
	name: string;
	distance: number;
}

class Contract implements ContractInterface {
	public id: number;
	public contractType: ContractType;
	contractNumber: string;
	customer: number;
	date: Moment;
	workLocations: IContractWorkLocation[];
	typeOfActivity: string;
	partDesignation: string;
	markingOfIOparts: string;
	shiftTimes: ShiftInterface[];
	errorTypes: string[];
	typesOfInspection: string[];
	status: ContractStatusType;
	isPhotoAllowed: boolean;
	automatedUpdates: boolean;
	assignedQAIs: number[];
	assignedTeamLeader: number;
	orderDescription: string;
	contactPerson: IdNamePair[] | number[] | { id: number }[];
	contactQAI24: number;
	billingCycle?: number;
	drivingRate?: number;
	drivingTime?: number;
	kilometer?: number;
	priceList?: IPriceListItem[];
	projectType?: number;
	calculationType?: number;
	administrationFee?: number;
	zones?: ContractZone[];
	attachments?: FileInterface[];
	contractData?: FileInterface;
	amountOfParts?: string[];
	applicationIntake?: number;
	tlGroups?: GroupUser[];
	tlGroupsIds?: number[];
	billed_until?: Moment;
	next_billing?: Moment;

	constructor(
		id: number,
		contractNumber: string,
		customer: number,
		date: Moment,
		workLocations: IContractWorkLocation[],
		typeOfActivity: string,
		partDesignation: string,
		markingOfIOparts: string,
		shiftTimes: ShiftInterface[],
		errorTypes: string[],
		typesOfInspection: string[],
		contractType: ContractType,
		status: ContractStatusType,
		isPhotoAllowed: boolean,
		automatedUpdates: boolean,
		assignedQAIs: number[],
		assignedTeamLeader: number,
		orderDescription: string,
		contactPerson: IdNamePair[] | number[] | { id: number }[],
		contactQAI24: number,
		billingCycle?: number,
		drivingRate?: number,
		drivingTime?: number,
		kilometer?: number,
		priceList?: IPriceListItem[],
		projectType?: number,
		calculationType?: number,
		administrationFee?: number,
		zones?: ContractZone[],
		contractData?: FileInterface,
		attachments?: FileInterface[],
		amountOfParts?: string[],
		applicationIntake?: number,
		tlGroups?: GroupUser[],
		tlGroupsIds?: number[],
		billed_until?: Moment,
		next_billing?: Moment
	) {
		this.contractNumber = contractNumber.trim();
		this.customer = customer;
		this.date = date;
		this.workLocations = workLocations;
		this.typeOfActivity = typeOfActivity;
		this.partDesignation = partDesignation.trim();
		this.markingOfIOparts = markingOfIOparts.trim();
		this.shiftTimes = shiftTimes;
		this.errorTypes = errorTypes;
		this.typesOfInspection = typesOfInspection;
		this.id = id;
		this.contractType = contractType;
		this.status = status;
		this.isPhotoAllowed = isPhotoAllowed;
		this.automatedUpdates = automatedUpdates;
		this.assignedQAIs = assignedQAIs;
		this.assignedTeamLeader = assignedTeamLeader;
		this.zones = zones;
		this.contractData = contractData;
		this.attachments = attachments;
		this.orderDescription = orderDescription.trim();
		this.contactPerson = contactPerson;
		this.contactQAI24 = contactQAI24;
		this.billingCycle = billingCycle;
		this.drivingRate = drivingRate;
		this.drivingTime = drivingTime;
		this.kilometer = kilometer;
		this.amountOfParts = amountOfParts;
		this.priceList = priceList;
		this.projectType = projectType;
		this.calculationType = calculationType;
		this.administrationFee = administrationFee;
		this.applicationIntake = applicationIntake;
		this.tlGroups = tlGroups;
		this.tlGroupsIds = tlGroupsIds;
		this.billed_until = billed_until;
		this.next_billing = next_billing;
	}

	public static fromJSON(maybe: any) {
		if (typeof maybe.contract_number !== 'string') {
			throw new Error('contractNumber must be string');
		}

		if (typeof maybe.id !== 'number') {
			throw new Error('id must be number');
		}

		if (typeof maybe.customer_id !== 'number') {
			throw new Error('customer must be number');
		}
		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}

		if (typeof maybe.type_of_activity !== 'string') {
			throw new Error('typeOfActivity must be string');
		}
		if (typeof maybe.part_designation !== 'string') {
			throw new Error('partDesignation must be string');
		}

		if (!maybe.errors) {
			throw new Error('errorTypes must exist');
		}
		if (!maybe.shifts) {
			throw new Error('shifts must be exist');
		}
		if (!maybe.checking_types) {
			throw new Error('typesOfInspection must exist');
		}
		if (typeof maybe.marking_of_parts !== 'string') {
			throw new Error('markingOfIOparts must be string');
		}
		if (typeof maybe.contract_type !== 'number') {
			throw new Error('contractType must be number');
		}
		if (maybe.contract_status === undefined) {
			throw new Error('contract_status must exist');
		}
		if (maybe.is_photo_allowed === undefined) {
			throw new Error('isPhotoAllowed must exist');
		}
		if (!maybe.assigned_qais) {
			throw new Error('assignedQAIs must exist');
		}
		if (!maybe.assigned_team_leader) {
			throw new Error('assignedTeamLeader must exist');
		}
		if (maybe.automated_updates === undefined) {
			throw new Error('automatedUpdates must exist');
		}
		if (typeof maybe.contact_person !== 'object') {
			throw new Error('contact_person must be an array');
		}
		if (typeof maybe.contact_qai_24 !== 'number') {
			throw new Error('contact_qai_24 must be number');
		}
		if (typeof maybe.order_description !== 'string') {
			throw new Error('order_description must be string');
		}

		return new Contract(
			maybe.id,
			maybe.contract_number,
			maybe.customer_id,
			date,
			maybe.work_locations,
			maybe.type_of_activity,
			maybe.part_designation,
			maybe.marking_of_parts,
			maybe.shifts.map(ShiftFromJSON),
			maybe.errors,
			maybe.checking_types,
			maybe.contract_type,
			maybe.contract_status,
			maybe.is_photo_allowed,
			maybe.automated_updates,
			maybe.assigned_qais,
			maybe.assigned_team_leader,
			maybe.order_description,
			maybe.contact_person,
			maybe.contact_qai_24,
			maybe.billing_cycle,
			maybe.driving_rate,
			maybe.driving_time,
			maybe.kilometer,
			maybe.price_list,
			maybe.project_type,
			maybe.calculation_type,
			maybe.administration_fee,
			maybe.zones && maybe.zones.length !== 0 && maybe.zones[0].id
				? maybe.zones?.map(ContractZoneFromJSON)
				: undefined,
			maybe.contract_data && maybe.contract_data.name ? maybe.contract_data : undefined,
			maybe.attachments,
			maybe.amount_of_parts,
			maybe.application_intake,
			maybe.tlGroups,
			maybe.tlGroupsIds,
			maybe.billed_until,
			maybe.next_billing
		);
	}

	public toJSON() {
		return {
			contract_number: this.contractNumber,
			customer_id: this.customer,
			date: this.date.toISOString(),
			work_locations: this.workLocations,
			type_of_activity: this.typeOfActivity,
			part_designation: this.partDesignation,
			marking_of_parts: this.markingOfIOparts,
			shifts: this.shiftTimes.map(ShiftToJSON),
			errors: this.errorTypes,
			checking_types: this.typesOfInspection,
			id: this.id,
			contract_type: this.contractType,
			contract_status: this.status,
			is_photo_allowed: this.isPhotoAllowed,
			automated_updates: this.automatedUpdates,
			assigned_qais: this.assignedQAIs,
			assigned_team_leader: this.assignedTeamLeader,
			zones: this.zones?.map(ContractZoneToJSON),
			contract_data: this.contractData ? this.contractData : {},
			attachments: this.attachments,
			order_description: this.orderDescription,
			contact_qai_24: this.contactQAI24,
			contact_person: this.contactPerson,
			amount_of_parts: this.amountOfParts,
			billing_cycle: this.billingCycle,
			driving_rate: this.drivingRate,
			driving_time: this.drivingTime,
			kilometer: this.kilometer,
			price_list: this.priceList,
			project_type: this.projectType,
			calculation_type: this.calculationType,
			administration_fee: this.administrationFee,
			application_intake: this.applicationIntake,
			tlGroupsIds: this.tlGroupsIds,
			billed_until: this.billed_until,
			next_billing: this.next_billing,
		};
	}
}

export default Contract;
