import React from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';
import { useWorkingTimeActionContext, useWorkingTimeStateContext } from '../context/workingTime/useWorkingTime';
import { useTranslation } from 'react-i18next';
import { timeFromUnixToStringFormat, updateWorkTime } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import moment from 'moment';
import { fonts } from '../consts/fonts';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { TrackWorkingTimeFormValues } from './TrackWorkingTime';
import { Controller, useForm } from 'react-hook-form';
import { rules } from '../consts/rules';
import StandardTextField from './StandardTextField';
import { priceListOptions } from '../consts/priceListOptions';

interface Props {
	contractID?: number;
	contractNumber?: string;
	homeScreen?: boolean;
}

function ReduceWorkTimeTracking(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles({});
	const [workingTime, setWorkingTime] = React.useState('');
	const [buttonText, setButtonText] = React.useState('');
	const { record } = useWorkingTimeStateContext();
	const { startTracking, stopTracking } = useWorkingTimeActionContext();
	const timer = React.useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));
	const { t } = useTranslation();
	const { control, errors, handleSubmit } = useForm<TrackWorkingTimeFormValues>();

	React.useEffect(() => {
		if (record) {
			setButtonText('working_time_screen_stop_button');
			timer.current = updateWorkTime(moment().diff(record.startTime), timer, setWorkingTime);
		} else {
			setButtonText('working_time_screen_start_button');
			setWorkingTime(timeFromUnixToStringFormat(0));
		}
		return () => {
			clearTimeout(timer.current);
		};
	}, [record]);

	const onButtonPress = (data: TrackWorkingTimeFormValues) => {
		if (record) {
			const recordToSave: WorkTimeTrack = { ...record, profession: parseInt(data.profession as string) };
			stopTracking(recordToSave);
		} else if (props.contractID && props.contractNumber)
			startTracking(new WorkTimeTrack(props.contractID, moment(), props.contractNumber));
	};

	return props.contractID || record ? (
		<div className={classes.wrapper}>
			<div className={classes.titleWrapper}>
				<span className={`${props.homeScreen ? classes.homeScreen : ''} ${classes.contractTitle}`}>
					{t('working_time_screen_reduce_track_title')} &#8470;
					{`: ${record ? record.contractNumber : props.contractNumber}`}
				</span>
			</div>
			<div className={classes.row}>
				<span className={`${props.homeScreen ? classes.homeScreen : ''} ${classes.timeText}`}>
					{t('working_time_screen_reduce_track_time')}:
				</span>
				<div className={classes.timeWrapper}>
					<span className={`${props.homeScreen ? classes.homeScreen : ''} ${globalClasses.mediumTextDark}`}>
						{workingTime}
					</span>
				</div>
				{record ? (
					<Controller
						defaultValue={record && record.profession ? record.profession : ''}
						control={control}
						name={'profession'}
						rules={{
							required: rules(t).required,
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('working_time_screen_select_profession')}
								select
								value={value}
								withOutBackgroundColor={true}
								maxInputWidth={'220px'}
								onChange={(e) => {
									onChange(e.target.value);
								}}
								error={errors.profession ? true : false}
								helperText={errors.profession ? errors.profession.message : ''}>
								{priceListOptions.map((item) => (
									<MenuItem key={item.value} value={item.value}>
										<div className={classes.inputWrapperDropdown}>
											<span className={classes.input}>{t(item.label)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				) : null}

				<span
					onClick={handleSubmit(onButtonPress)}
					className={`${props.homeScreen ? classes.homeScreen : ''} ${classes.button} `}>
					{t(buttonText)}
				</span>
			</div>
		</div>
	) : null;
}

const useStyles = makeStyles(() => ({
	homeScreen: {
		color: 'white !important',
	},
	wrapper: {
		marginRight: '28px',
	},
	titleWrapper: {
		marginBottom: '4px',
		textAlign: 'right',
	},
	contractTitle: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.lightGray,
		letterSpacing: 0.8,
	},
	timeText: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.lightGray,
		marginRight: '8px',
	},
	timeWrapper: {
		marginRight: '16px',
	},
	button: {
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.semiBold,
		color: colors.darkGray,
		cursor: 'pointer',
		marginLeft: '8px',
	},
	row: {
		display: 'flex',
		alignItems: 'end',
	},
	input: {
		...globalInputStyle(),
	},
	inputWrapperDropdown: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

export default ReduceWorkTimeTracking;
