import * as React from 'react';

function AvatarIcon(props: any) {
	return (
		<svg width={60} height={44} viewBox="0 0 60 44" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h60v44H0z" />
				<g transform="translate(15 7)" stroke="#fff" strokeWidth={2}>
					<circle strokeLinecap="square" cx={15} cy={10} r={3} />
					<path
						d="M21 21v-1.983a2 2 0 00-.983-1.725C19.044 16.717 17.358 16 15 16c-2.388 0-4.064.713-5.026 1.288A1.998 1.998 0 009 19.007V21h12z"
						strokeLinecap="square"
					/>
					<circle strokeLinecap="square" cx={26} cy={5} r={3} />
					<path d="M29.7 12c.197.969.3 1.973.3 3 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c3.317 0 6.378 1.073 8.862 2.896" />
				</g>
			</g>
		</svg>
	);
}

export default AvatarIcon;
