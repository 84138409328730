import * as React from 'react';

function Dots(props: any) {
	return (
		<svg width={30} height={6} viewBox="-2 -2 50 12" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" strokeWidth={2.5} fill="none" fillRule="evenodd" strokeLinecap="square">
				<circle cx={18} cy={4} r={4} />
				<circle cx={4} cy={4} r={4} />
				<circle cx={30} cy={4} r={4} />
			</g>
		</svg>
	);
}

export default Dots;
