import {
	ContractZone,
	ContractZoneFromJSON,
	ContractZoneToJSON,
} from '../../helpers/contractHelper/generalContractHelper';

class RhenusOverviewInformation {
	zone: ContractZone;
	totalPartsTested: number;
	numberOfErrorParts: number;
	precentageOfErrorParts: string;
	numberOfQAI: number;

	constructor(
		zone: ContractZone,
		totalPartsTested: number,
		numberOfErrorParts: number,
		precentageOfErrorParts: string,
		numberOfQAI: number
	) {
		this.zone = zone;
		this.totalPartsTested = totalPartsTested;
		this.numberOfErrorParts = numberOfErrorParts;
		this.precentageOfErrorParts = precentageOfErrorParts;
		this.numberOfQAI = numberOfQAI;
	}

	public static fromJSON(maybe: any): RhenusOverviewInformation {
		if (!maybe) {
			throw Error('must exist');
		}
		if (!maybe.zone) {
			throw Error('zonename must exist');
		}
		if (typeof maybe.total_parts_tested !== 'number') {
			throw Error('totalPartsTested be number');
		}
		if (typeof maybe.number_of_error_parts !== 'number') {
			throw Error('numberOfErrorParts be number');
		}
		if (typeof maybe.percentage_of_error_parts !== 'string') {
			throw Error('precentageOfErrorParts be string');
		}
		if (typeof maybe.number_of_qai !== 'number') {
			throw Error('numberOfQAI be number');
		}

		return new RhenusOverviewInformation(
			ContractZoneFromJSON(maybe.zone),
			maybe.total_parts_tested,
			maybe.number_of_error_parts,
			maybe.percentage_of_error_parts,
			maybe.number_of_qai
		);
	}

	public toJSON() {
		return {
			zone: ContractZoneToJSON(this.zone),
			total_parts_tested: this.totalPartsTested,
			number_of_error_parts: this.numberOfErrorParts,
			precentage_of_error_parts: this.precentageOfErrorParts,
			number_of_qai: this.numberOfQAI,
		};
	}
}

export default RhenusOverviewInformation;
