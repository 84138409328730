import React from 'react';
import LoadingWrapper from './components/LoadingWrapper';

import ContextProvider from './context/ContextProvider';
import MainRouter from './router/MainRouter';
import moment from 'moment-timezone';
import InitWrapper from './wrappers/InitWrapper';

function App() {
	moment.tz.setDefault('Germany/Berlin');
	return (
		<InitWrapper>
			<ContextProvider>
				<LoadingWrapper>
					<MainRouter />
				</LoadingWrapper>
			</ContextProvider>
		</InitWrapper>
	);
}

export default App;
