import { TableCellType } from '../components/table/StandardTableCell';
import { StandardCaseTableInterface } from '../service/types/StandardCase';
import { TableParamInterface } from './rhenusOverview';

interface StandardCaseParamInterface extends StandardCaseTableInterface {
	date: TableParamInterface;
	qai: TableParamInterface | undefined;
	partNumber: TableParamInterface;
	typeOfInspection: TableParamInterface;
	approved: TableParamInterface | undefined;
	photo: TableParamInterface | undefined;
	shift: TableParamInterface | undefined;
	comment: TableParamInterface | undefined;
	checkNumber: TableParamInterface | undefined;
	deliveryNoteNumber: TableParamInterface;
	deliveryNoteDate: TableParamInterface;
	totalPartsTested: TableParamInterface;
	partsNIO: TableParamInterface;
	partsIO: TableParamInterface;
	partsReworked: TableParamInterface;
}

export const standardCaseInputListParams: StandardCaseParamInterface = {
	partNumber: {
		title: 'standard_case_overview_screen_part_number',
		width: '21.56vw',
		type: TableCellType.Sticky,
		titleType: TableCellType.Title,
		sticky: true,
		sortable: true,
	},
	date: {
		title: 'standard_case_overview_screen_order_date',
		width: '7vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	qai: {
		title: 'standard_case_overview_screen_reporter',
		width: '17.957vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	deliveryNoteNumber: {
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		title: 'standard_case_overview_screen_delivery_note_number',
		width: '9.94vw',
		sortable: true,
	},
	deliveryNoteDate: {
		title: 'standard_case_overview_screen_delivery_note_date',
		width: '11.01vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	typeOfInspection: {
		title: 'standard_case_overview_screen_type_of_inspection',
		width: '11.01vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
		sortable: false,
	},
	approved: {
		title: 'standard_overview_screen_contract_approved',
		width: '18.18vw',
		type: TableCellType.Approve,
		titleType: TableCellType.TitleWithDropdown,
		sortable: true,
	},
	totalPartsTested: {
		title: 'standard_case_overview_screen_total_parts_tested',
		width: '8.18vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	partsNIO: {
		title: 'standard_case_overview_screen_parts_nio',
		width: '8.18vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	partsIO: {
		title: 'standard_case_overview_screen_parts_rio',
		width: '8.18vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	partsReworked: {
		title: 'standard_case_overview_screen_parts_reworked',
		width: '8.18vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	errorTypes: {
		title: 'standard_case_overview_screen_errorType',
		width: '28.957vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	shift: {
		title: 'standard_case_overview_screen_shift',
		width: '13.76vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
		sortable: true,
	},
	checkNumber: {
		title: 'standard_case_overview_screen_check_number',
		width: '8.76vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	photo: {
		title: 'standard_case_overview_screen_photo',
		width: '12.87vw',
		type: TableCellType.Button,
		titleType: TableCellType.Title,
		sortable: true,
	},
	comment: {
		title: 'standard_case_overview_screen_comment',
		width: '29.87vw',
		type: TableCellType.Expandable,
		titleType: TableCellType.Title,
		sortable: true,
	},
};
