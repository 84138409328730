import { TableCellType } from '../components/table/StandardTableCell';

export interface TableParamInterface {
	title: string;
	width: string;
	type: TableCellType;
	titleType: TableCellType;
	sticky?: boolean;
	sortable?: boolean;
}

interface RhenusParamsInterface extends RhenusOverviewParamInterface {
	zoneName: TableParamInterface;
	totalPartsTested: TableParamInterface;
	numberOfErrorParts: TableParamInterface;
	percentageOfErrorParts: TableParamInterface;
	numberOfQAI: TableParamInterface;
}

export const rhenusOverviewParams: RhenusParamsInterface = {
	zoneName: {
		title: 'rhenus_case_overview_screen_zone_name',
		width: '25.22%',
		type: TableCellType.Sticky,
		titleType: TableCellType.Title,
		sticky: true,
	},
	totalPartsTested: {
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		title: 'rhenus_case_overview_screen_total_quantity_of_parts',
		width: '16.06%',
	},
	numberOfErrorParts: {
		title: 'rhenus_case_overview_screen_quantity_of_parts_with_errors',
		width: '19.57%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	percentageOfErrorParts: {
		title: 'rhenus_case_overview_screen_percentage_of_error_parts',
		width: '19.57%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	numberOfQAI: {
		title: 'rhenus_case_overview_screen_number_of_QAIs_in_the_zone',
		width: '19.57%',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
};

export interface RhenusOverviewParamInterface {
	zoneName: any;
	totalPartsTested: any;
	percentageOfErrorParts: any;
	numberOfErrorParts: any;
	numberOfQAI: any;
}
