import React from 'react';
import { makeStyles } from '@material-ui/core';
import { colors } from '../consts/colors';
import { SHIFT_FROM_WIDTH, SHIFT_NAME_WIDTH } from '../screens/ContractScreen';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import StandardTimePicker from './StandardTimePicker';
import StandardCheckbox from './StandardCheckbox';

interface Props {
	shiftControl: Control<Record<string, any>>;
	shiftErrors: Record<string, any>;
	shiftTypeName: string;
	isUserEditing: boolean;
	shiftNameLabel: string;
	checkedShifts: string[];
	onShiftCheckboxChange: (val: boolean, name: string) => void;
	clearShiftErrors: (name?: string | string[] | undefined) => void;
}

function ShiftInputRow({
	shiftControl,
	shiftErrors,
	shiftTypeName,
	shiftNameLabel,
	isUserEditing,
	checkedShifts,
	onShiftCheckboxChange,
	clearShiftErrors,
}: Props) {
	const classes = useStyles({});
	const { t } = useTranslation();

	const isRowChecked = checkedShifts.some((shift) => shift === shiftTypeName);

	return (
		<div className={classes.row123}>
			<div className={classes.shiftNameWrapper}>{t(shiftNameLabel)}</div>
			<div className={classes.fromWrapper}>
				<Controller
					control={shiftControl}
					name={`${shiftTypeName}.from`}
					render={({ onChange, value }) => (
						<StandardTimePicker
							value={value}
							onChange={(e) => {
								clearShiftErrors(`${shiftTypeName}.from`);
								onChange(e);
							}}
							label={''}
							disabled={!isUserEditing}
							placeholder={'00:00'}
						/>
					)}
				/>
			</div>
			<div className={classes.fromWrapper}>
				<Controller
					control={shiftControl}
					name={`${shiftTypeName}.to`}
					render={({ onChange, value }) => (
						<StandardTimePicker
							value={value}
							onChange={(e) => {
								clearShiftErrors(`${shiftTypeName}.to`);
								onChange(e);
							}}
							label={''}
							disabled={!isUserEditing}
							placeholder={'00:00'}
						/>
					)}
				/>
			</div>
			<div className={classes.checkBoxWrapper}>
				<StandardCheckbox
					onChange={() => {}}
					value={isRowChecked}
					disabled={!isUserEditing}
					name={''}
					customOnChange={onShiftCheckboxChange}
					customName={shiftTypeName}
				/>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	shiftNameWrapper: {
		width: SHIFT_NAME_WIDTH,
		display: 'flex',
		color: colors.gray,
	},
	fromWrapper: {
		width: SHIFT_FROM_WIDTH,
		display: 'flex',
		alignSelf: 'flex-start',
		paddingLeft: '26px',
	},
	checkBoxWrapper: {
		display: 'flex',
	},
	shiftWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	row123: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #C7C7C7',
		padding: '6px 12px',
		backgroundColor: colors.white,
	},
}));

export default ShiftInputRow;
