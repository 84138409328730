import { goToHome, RhenusCaseNavigationInterface } from '../navigationHelper';
import { History, LocationState } from 'history';
import { ContractZone, ShiftInterface } from '../contractHelper/generalContractHelper';
import RhenusCase from '../../service/types/RhenusCase';
import { rhenusCaseInputFields } from '../../containers/RhenusCaseContainer';
import User, { UserRole } from '../../service/types/User';
import Contract from '../../service/types/Contract';
import { findCurrentShift } from '../standardInputHelper/createStandardInputHelper';
import { RouteParams } from '../../router/Routes';
import service from '../../service/service';

export function setRhenusInputCaseInfo(
	rhenusInputCase: RhenusCase | undefined,
	user: User | undefined,
	contract: Contract,
	setValue: (field: string, val: any) => void,
	setShift: (shift: ShiftInterface | undefined) => void,
	setZone: (zone: ContractZone | undefined) => void
) {
	if (rhenusInputCase) {
		setValue(rhenusCaseInputFields.partNumber, rhenusInputCase.partNumber);
		setValue(rhenusCaseInputFields.productionNumber, rhenusInputCase.productionNumber);
		setValue(rhenusCaseInputFields.date, rhenusInputCase.date);
		setValue(rhenusCaseInputFields.storage, rhenusInputCase.storage);
		setValue(rhenusCaseInputFields.typeOfInspection, rhenusInputCase.typeOfInspection);
		setValue(rhenusCaseInputFields.errorType, rhenusInputCase.errorType);
		setValue(rhenusCaseInputFields.partStatus, rhenusInputCase.partStatus);
		setValue(rhenusCaseInputFields.partNumberShould, rhenusInputCase.partNumberShould);
		setValue(rhenusCaseInputFields.colorNumber, rhenusInputCase.colorNumber);
		setValue(rhenusCaseInputFields.colorNumberShould, rhenusInputCase.colorNumberShould);
		setValue(rhenusCaseInputFields.amountOfParts, rhenusInputCase.amountOfParts);
		setValue(rhenusCaseInputFields.comment, rhenusInputCase.comment);
		setValue(rhenusCaseInputFields.approved, rhenusInputCase.approved);
		setShift(contract.shiftTimes.find((shift) => shift.id === rhenusInputCase.shift));
		setZone(contract.zones!.find((zone) => zone.id === rhenusInputCase.zone));
		if (user?.role !== UserRole.QualityAssuranceInspector) {
			if (rhenusInputCase.photo)
				service
					.getPhoto(rhenusInputCase.photo.name)
					.then((res) => {
						if (res) {
							setValue(rhenusCaseInputFields.photo, { name: '', data: res });
						}
					})
					.catch(() => {});
		}
	} else {
		setShift(findCurrentShift(contract.shiftTimes));
	}
}

export function onRhenusInputScreenMount(
	history: History<LocationState>,
	contractIDRef: React.MutableRefObject<number>,
	routeParams: RouteParams,
	setContract: (contract: Contract) => void,
	setIsUserEditing: (val: boolean) => void,
	setRhenusInputCase: (rhenusCaseInput: RhenusCase | undefined) => void,
	setZone: (zone: ContractZone | undefined) => void,
	setMounted: (val: boolean) => void
) {
	const historyState = history.location.state as undefined | RhenusCaseNavigationInterface;

	if (historyState && historyState.zone) {
		const contractFromState = Contract.fromJSON(JSON.parse(historyState.contract));
		contractIDRef.current = contractFromState.id;
		setContract(contractFromState);
		setZone(historyState.zone);
		if (historyState.data) {
			setRhenusInputCase(JSON.parse(historyState.data));
		} else {
			setIsUserEditing(true);
		}
		setMounted(true);
	} else if (routeParams.contract_id && routeParams.zone_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				if (res) {
					setContract(res);
					if (routeParams.zone_id && res.zones) {
						const zone = res.zones.find((zone) => zone.id === Number(routeParams.zone_id));
						if (routeParams.id && ((historyState && !historyState.data) || !historyState)) {
							service
								.getRhenusCaseInputByID(Number(routeParams.id))
								.then((res) => {
									if (res) setRhenusInputCase(res);
									setMounted(true);
								})
								.catch((e) => {
									goToHome(history);
								});
						} else {
							setMounted(true);
						}
						if (zone) setZone(zone);
						else goToHome(history);
					} else {
						goToHome(history);
					}
				}
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		goToHome(history);
	}
}
