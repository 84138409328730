import * as React from 'react';

export function BackKey(props: any) {
	return (
		<svg width={24} height={16} viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
				<path d="M23 15H8L2 8l6-7h15zM12 6l4 4M16 6l-4 4" />
			</g>
		</svg>
	);
}

export default BackKey;
