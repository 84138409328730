import * as React from 'react';

function EditIcon(props: any) {
	return (
		<svg width={17} height={17} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" strokeWidth={1.5} fill="none" fillRule="evenodd">
				<path strokeLinecap="square" d="M6.25 3.25H1V16h12.75v-5.25" />
				<path d="M11.5 3.25l2.25 2.25" />
				<path strokeLinecap="square" d="M7.75 11.5l-3 .75.75-3L13.75 1 16 3.25z" />
			</g>
		</svg>
	);
}

export default EditIcon;
