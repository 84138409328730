import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import { StandardDialogInterface } from '../components/StandardDialog';
import { LANGUAGE_KEY, languages } from '../consts/language';
import { useAuthStateContext } from '../context/auth/useAuth';
import { generateAndDownloadExcel } from '../helpers/fileDownloader';
import { filterData } from '../helpers/filters';
import { ExpensesNavigationInterface } from '../helpers/navigationHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import { addWorkTime } from '../helpers/workingTimeHelper/createWorkingTimeHelper';
import { deleteWorkingTime } from '../helpers/workingTimeHelper/deleteWorkingTimeHelper';
import {
	ExportWorkingTimeInterface,
	WorkingTimeFilterInterface,
	WorkingTimeFilterKeys,
	addWorkingTimeFields,
	calculateTimeDifference,
	initWorkingTimeFilters,
	timeFromUnixToStringFormat,
	workingTimeFields,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { onWorkingTimeMount } from '../helpers/workingTimeHelper/readWorkingTimeHelper';
import { getUpdateWorkingTimeService } from '../helpers/workingTimeHelper/updateWorkingTimeHelper';
import WorkingTimeScreen from '../screens/WorkingTimeScreen';
import service from '../service/service';
import BreakTime from '../service/types/BreakTime';
import { UserRole } from '../service/types/User';
import WorkingTime from '../service/types/WorkingTime';

function WorkingTimeContainer() {
	const [allUsers, setAllUsers] = React.useState<KeyValue[]>([]);
	const [records, setRecords] = React.useState<(WorkingTime | BreakTime)[]>([]);
	const [filters, setFilters] = React.useState<WorkingTimeFilterInterface>(initWorkingTimeFilters);
	const methods = useForm();
	const [contractData, setContractData] = React.useState<ExpensesNavigationInterface>({
		contractNumber: '',
		contractID: 0,
	});
	const allRecordsRef = React.useRef<(WorkingTime | BreakTime)[]>([]);
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const deleteRecordID = React.useRef<WorkingTime | BreakTime>();
	const { user } = useAuthStateContext();
	const fromTime = methods.watch(addWorkingTimeFields.from);
	const toTime = methods.watch(addWorkingTimeFields.to);
	const history = useHistory();
	const fetching = React.useRef(false);
	const fetchingRecords = React.useRef(false);
	const routeParmas = useParams();

	const [intervalDates, setIntervalDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const [exportWorkingTimeFields, setExportWorkingTimeFields] = React.useState<ExportWorkingTimeInterface>({
		ExportMaterials: false,
		ExportTools: false,
		ExportTravels: false,
		ExportTransport: false,
		Anonymous: false,
	});

	const { t } = useTranslation();

	const onFilterChange = (key: WorkingTimeFilterKeys, value: string) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));
	};

	const clearAllFilters = () => {
		Object.values(workingTimeFields).forEach((val) => methods.setValue(val, ''));
		setExportWorkingTimeFields({
			ExportMaterials: false,
			ExportTools: false,
			ExportTravels: false,
			ExportTransport: false,
			Anonymous: false,
		});
		setFilters(initWorkingTimeFilters);
	};

	React.useEffect(() => {
		setRecords(filterData(filters, allRecordsRef.current));
	}, [filters]);

	const manualWorkTime = React.useMemo(() => {
		return calculateTimeDifference(fromTime, toTime);
	}, [fromTime, toTime]);

	const manualWorkTimeFromUnix = React.useMemo(() => {
		return timeFromUnixToStringFormat(manualWorkTime);
	}, [manualWorkTime]);

	React.useEffect(() => {
		onWorkingTimeMount(history, routeParmas, setContractData);
	}, [history, routeParmas]);

	React.useEffect(() => {
		if (contractData.contractID !== 0 && !fetchingRecords.current) {
			fetchingRecords.current = true;
			service
				.getAllRecords(contractData.contractID)
				.then((records) => {
					unstable_batchedUpdates(() => {
						setRecords(records);
						allRecordsRef.current = records;
						fetchingRecords.current = false;
					});
				})
				.catch((e) => {
					fetchingRecords.current = false;
				});
		}
	}, [contractData]);

	React.useEffect(() => {
		if (user && !fetching.current) {
			fetching.current = true;
			getUserOptionsKeyValuePairs(
				user.role === UserRole.QualityAssuranceInspector ? service.getAllQAIs() : service.getAllUsers(),
				() => {}
			)
				.then((users) => {
					let tempUsers = users.filter((user) => user.value.role !== UserRole.Customer);
					setAllUsers(tempUsers);
				})
				.catch((e) => {
					fetching.current = false;
				});
		}
	}, [user]);

	const addWorkingTime = async (data: any) => {
		if (
			moment(data[addWorkingTimeFields.from]).isValid() &&
			moment(data[addWorkingTimeFields.to]).isValid() &&
			moment(data[addWorkingTimeFields.date]).isValid()
		) {
			const newRecord = await addWorkTime(
				contractData.contractID,
				user!,
				allUsers,
				data,
				manualWorkTime,
				methods.setValue
			);
			if (newRecord) {
				setRecords((records) => {
					allRecordsRef.current = [...records, newRecord];
					return [...records, newRecord];
				});
			}
		}
	};

	const addRecordToCurrentRecords = (record: WorkingTime) => {
		setRecords((records) => {
			allRecordsRef.current = [...records, record];
			return [...records, record];
		});
	};

	const updateRecord = async (record: WorkingTime | BreakTime) => {
		if (record instanceof WorkingTime) {
			if (moment(record.startTime).isValid() && moment(record.endTime).isValid()) {
				const start = record.startTime;
				let end = record.endTime;

				if (end < start) {
					end = end.add(1, 'd');
					record.endTime = end;
				}
				const duration = end.diff(start);
				record.duration = Math.abs(duration / 1000);
			}
		}
		const res = await getUpdateWorkingTimeService(contractData.contractID, record);
		if (res) {
			const recordIndex = records.findIndex((item) => item.id === record.id);
			records[recordIndex] = record;
			allRecordsRef.current = [...records];
			setRecords([...records]);
		}
		return res;
	};

	const openDeleteDialog = (record: WorkingTime | BreakTime) => {
		deleteRecordID.current = record;
		dialogRef.current?.setDialogState(true);
	};

	const deleteRecord = () =>
		deleteWorkingTime(
			service,
			contractData.contractID,
			deleteRecordID.current!,
			records,
			dialogRef,
			allRecordsRef,
			setRecords
		);

	const cancelDialog = () => dialogRef.current?.setDialogState(false);

	const downloadTable = async () => {
		let lan = localStorage.getItem(LANGUAGE_KEY);
		if (lan === null) {
			lan = languages.deutchland.i18n;
		}

		const newFilters = { ...filters, ...exportWorkingTimeFields };
		const excelData = await service.createWorkingTimeExcelSheet(contractData.contractID, newFilters, lan);
		excelData && generateAndDownloadExcel(excelData, t('export_table_hours_overview'), contractData.contractNumber);
	};

	const onDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);
			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { shouldFocus: true, message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	const changeExportFields = React.useCallback((value: boolean, name: string) => {
		setExportWorkingTimeFields((prev) => ({
			...prev,
			[name]: value,
		}));
	}, []);

	return (
		<FormProvider {...methods}>
			<WorkingTimeScreen
				intervalDates={intervalDates}
				allUsers={allUsers}
				fromTime={fromTime}
				records={records}
				manualWorkTime={manualWorkTimeFromUnix}
				contractID={contractData.contractID}
				contractNumber={contractData.contractNumber}
				onAddManualWorkTime={methods.handleSubmit(addWorkingTime)}
				clearAllFilters={clearAllFilters}
				onFilterChange={onFilterChange}
				filters={filters}
				onStopButtonPressWhenCIDAndRID={addRecordToCurrentRecords}
				onSaveRecordPress={updateRecord}
				onDeletePress={openDeleteDialog}
				dialogRef={dialogRef}
				onCancelDialogPress={cancelDialog}
				onDeleteRecordDialogPress={deleteRecord}
				downloadTable={downloadTable}
				onKeyboardDateChange={onKeyboardDateChange}
				onDateChange={onDateChange}
				onDatesAccept={onDatesAccept}
				onDatesCancel={onDatesCancel}
				clearDates={clearDates}
				changeExportFields={changeExportFields}
				exportWorkingTimeFields={exportWorkingTimeFields}
			/>
		</FormProvider>
	);
}

export default WorkingTimeContainer;
