import Contract, { IContractWorkLocation } from '../../service/types/Contract';
import { goToHome } from '../navigationHelper';
import { History, LocationState } from 'history';
import { contractFields } from '../../containers/ContractContainer';
import moment from 'moment';
import { getUserOptionsKeyValuePairs } from '../userHelper/readUserHelper';
import service from '../../service/service';
import { KeyValue } from '../userHelper/generalUserHelper';
import { FileInputIntreface } from '../../components/StandardFileInput';
import { RouteParams } from '../../router/Routes';
import User from '../../service/types/User';

export function onContractScreenMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setContract: (contract: Contract | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	setMounted: (val: boolean) => void
) {
	if (routeParams.contract_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				setMounted(true);
				setContract(res);
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		setIsUserEditing(true);
		setContract(undefined);
		setMounted(true);
	}
}

export function setBasicContractInfo(contract: Contract, setValue: (field: string, val: any) => void) {
	setValue(contractFields.date, moment(contract.date));
	setValue(contractFields.contractType, contract.contractType);
	setValue(contractFields.errorTypes, contract.errorTypes);
	setValue(contractFields.amountOfParts, contract.amountOfParts);
	setValue(contractFields.typesOfInspection, contract.typesOfInspection);
	setValue(contractFields.contractNumber, contract.contractNumber);
	setValue(contractFields.partDesignation, contract.partDesignation);
	setValue(contractFields.markingOfIOparts, contract.markingOfIOparts);
	setValue(contractFields.takingPhotos, contract.isPhotoAllowed);
	setValue(contractFields.typeOfActivity, contract.typeOfActivity);

	setValue(contractFields.orderDescription, contract.orderDescription);
	setValue(contractFields.automatedUpdates, contract.automatedUpdates);

	setValue(
		contractFields.pdfContract,
		contract.contractData && contract.contractData.name ? contract.contractData : ''
	);
	setValue(contractFields.attachments, contract.attachments);

	if (contract.zones) {
		const tempZones = contract.zones.map((zone) => {
			return zone.name;
		});
		setValue(contractFields.zones, tempZones);
	}
}

export function setContractInfo(
	contract: Contract,
	teamLeaders: KeyValue[],
	customers: KeyValue[],
	qaisLoading: React.MutableRefObject<boolean>,
	qais: React.MutableRefObject<boolean>,
	attachmentsInputRef: React.MutableRefObject<FileInputIntreface | undefined>,
	setQAIs: (qais: KeyValue[]) => void,
	setValue: (field: string, val: any) => void,
	setShiftValue: (
		name: string,
		value: unknown,
		options?:
			| Partial<{
					shouldValidate: boolean;
					shouldDirty: boolean;
			  }>
			| undefined
	) => void,
	setCheckedShifts: React.Dispatch<React.SetStateAction<string[]>>,
	setWorkLocations: React.Dispatch<React.SetStateAction<IContractWorkLocation[]>>
) {
	const currentTeamLeader = teamLeaders.find((item) => item.value.id === contract.assignedTeamLeader);
	const currCustomer = customers.find((item) => item.value.id === contract.customer);
	const contactPerson: User[] = contract.contactPerson.map((person) =>
		currCustomer?.value.users.find((user: User) => user.id === person)
	);
	// setBasicContractInfo(contract, setValue, setShifts);
	setValue(contractFields.assignTeamLeader, currentTeamLeader?.key);
	setValue(contractFields.customer, currCustomer?.key);

	if (contactPerson)
		setValue(
			contractFields.contactPerson,
			contactPerson.map((el) => `${el.firstName} ${el.lastName}`)
		);

	if (attachmentsInputRef.current && contract.attachments) {
		attachmentsInputRef.current.setNewValues(contract.attachments);
	}

	setWorkLocations(contract.workLocations);

	const shiftKeys = ['dayShift', 'earlyShift', 'lateShift', 'nightShift'];
	const defaultCheckedShifts = contract.shiftTimes
		.filter((shift) => shiftKeys.some((key) => key === shift.name))
		.map((val) => val.name);

	const defaultUnchecked = shiftKeys.filter((key) => !defaultCheckedShifts.some((val) => val === key));

	defaultUnchecked.forEach((shift) => {
		setShiftValue(`${shift}.from`, '');
		setShiftValue(`${shift}.to`, '');
	});

	defaultCheckedShifts.forEach((shift) => {
		const defaultShift = contract.shiftTimes.find((el) => el.name === shift);

		if (defaultShift) {
			setShiftValue(`${shift}.from`, defaultShift.from);
			setShiftValue(`${shift}.to`, defaultShift.to);
		}
	});

	setCheckedShifts(defaultCheckedShifts);

	qais.current = false;
	qaisLoading.current = true;
	getUserOptionsKeyValuePairs(service.getAllQAIsByTeamLeader(currentTeamLeader?.value.id), () => {})
		.then((res) => {
			setQAIs(res);
			qaisLoading.current = false;
		})
		.catch((e) => {
			qaisLoading.current = false;
		});
}
