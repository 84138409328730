import { userFields } from '../../containers/UserContainer';
import { History, LocationState } from 'history';
import { CreateEmployeeParams, IdNamePair } from '../../service/types/User';
import { goToUsers } from '../navigationHelper';
import { Service } from '../../service/service';
import { KeyValue } from './generalUserHelper';
import { Group, GroupUser } from '../../pages/groupOverview/GroupOverview';

export function createUser(
	data: any,
	history: History<LocationState>,
	service: Service,
	setError: (name: string, value: { message: string }) => void,
	t: (text: string) => string,
	setGroups: (groups: Group[]) => void
) {
	getCreateService(service, {
		email: data[userFields.email].trim(),
		first_name: data[userFields.firstName].trim(),
		last_name: data[userFields.lastName].trim(),
		origin: data[userFields.originOfEmployee],
		password: data[userFields.password],
		role: data[userFields.role],
		tl:
			userFields.teamLeader in data && data[userFields.teamLeader].length !== 0
				? data[userFields.teamLeader].map((item: KeyValue) => item.value.id)
				: undefined,
		username: userFields.username in data ? data[userFields.username].trim() : undefined,
		phonenumber: userFields.phonenumber in data ? data[userFields.phonenumber] : undefined,
		tlGroupsIds:
			(userFields.tlGroups in data &&
				data[userFields.tlGroups].length > 0 &&
				data[userFields.tlGroups]?.map((el: GroupUser) => el.id)) ||
			[],
		contactFor:
			(userFields.contactFor in data &&
				data[userFields.contactFor].length > 0 &&
				data[userFields.contactFor].map((el: IdNamePair) => el.id)) ||
			[],
	})
		.then((res) => {
			if (res) {
				// This will force new fetch on groups screen
				setGroups([]);

				goToUsers(history);
			}
		})
		.catch((e) => {
			const error = JSON.parse(e.message);
			setCreateUserError(error, setError, t);
		});
}

export function getCreateService(service: Service, params: CreateEmployeeParams) {
	if (params.tl) {
		return service.createQAI(params);
	} else {
		return service.createEmployee(params);
	}
}

function setCreateUserError(
	error: any,
	setError: (name: string, value: { message: string }) => void,
	t: (text: string) => string
) {
	if (error && error.error) {
		if (error.error.code === -32000) {
			setError(userFields.email, { message: t('user_screen_email_in_use') });
		} else if (error.error.code === -32006) {
			setError(userFields.username, { message: t('user_screen_username_in_use') });
		}
	}
}
