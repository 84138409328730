import * as React from 'react';

function CheckedIcon(props: any) {
	return (
		<svg
			width={16}
			height={16}
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
				<path d="M8 0a8 8 0 110 16A8 8 0 018 0z" id={`${props.iconKey}prefix__ac`} />
				<path d="M4 0a4 4 0 110 8 4 4 0 010-8z" id={`${props.iconKey}prefix__bc`} />
				<path d="M8 0a8 8 0 110 16A8 8 0 018 0z" id={`${props.iconKey}prefix__cc`} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<use fill="#FFF" xlinkHref={`#${props.iconKey}prefix__ac`} />
				<use fill="#1A1A1A" xlinkHref={`#${props.iconKey}prefix__bc`} transform="translate(4 4)" />
				<path
					stroke="#1A1A1A"
					strokeWidth={1.5}
					d="M8 .75c2.002 0 3.815.811 5.127 2.123A7.227 7.227 0 0115.25 8a7.227 7.227 0 01-2.123 5.127A7.227 7.227 0 018 15.25a7.227 7.227 0 01-5.127-2.123A7.227 7.227 0 01.75 8c0-2.002.811-3.815 2.123-5.127A7.227 7.227 0 018 .75z"
				/>
			</g>
		</svg>
	);
}

export default CheckedIcon;
