import DateFnsUtils from '@date-io/date-fns';
import { Button, ThemeProvider, createMuiTheme, makeStyles, withStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import ErrorIcon from '../svgComponents/ErrorIcon';
import CustomDate from './CustomDate';

import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { de, enGB } from 'date-fns/locale';
import i18n from '../i18n';
import CalendarArrowIcon from '../svgComponents/CalendarArrowIcon';

type overridesNameToClassKey = {
	[P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
	MuiPickersBasePicker: {
		container: {
			backgroundColor?: string;
		};
		pickerView: {
			minWidth: string;
			width: string;
		};
	};
	MuiPickersStaticWrapper: {
		staticWrapperRoot: {
			backgroundColor?: string;
		};
	};
};

declare module '@material-ui/core/styles/overrides' {
	interface ComponentNameToClassKey extends overridesNameToClassKey {}
	export interface ComponentNameToClassKey extends CustomType {}
}

export const IntervalDatePickerWithStyles = withStyles({
	root: {
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiInput-input': {
			cursor: 'text',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
	},
})(KeyboardDatePicker);

export interface IntervalDatePickerDates {
	fromDate: Moment | null;
	toDate: Moment | null;
}

export type IntervalKey = 'start' | 'end';

interface Props {
	id: IntervalKey;
	label: string;
	dates: IntervalDatePickerDates;
	value: Moment | null;
	wrapper?: string;
	error?: boolean;
	helperText?: string;
	isUserNotEditing?: boolean;
	disabled?: boolean;
	fixed?: boolean;
	clearable?: boolean;
	inputWrapper?: string;
	endAdornment?: React.ReactNode;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
}

const IntervalDatePicker = (props: Props) => {
	const classes = useStyles({
		disabled: props.disabled,
		fixed: props.fixed,
	});
	const globalClasses = useGlobalStyles({
		disabled: props.disabled,
		isUserNotEditing: props.isUserNotEditing,
		fixed: props.fixed,
	});
	const [isOpen, setIsOpen] = React.useState(false);
	const { t } = useTranslation();

	const isAccepted = React.useRef(false);

	const currentLanguage = i18n.language;

	const renderDay = React.useCallback(
		(startDate: Moment | null, endDate: Moment | null) =>
			(date: MaterialUiPickersDate, _: MaterialUiPickersDate, dayInCurrentMonth: boolean) => {
				const currentDate = moment(date);

				const disabled = !dayInCurrentMonth;

				const dayInWeek = date?.getUTCDay();
				const day = date?.getDate();

				if (currentDate.isSame(startDate, 'day')) {
					return (
						<CustomDate
							disabled={disabled}
							date={day}
							type="start"
							hasRoundWrapper={endDate !== null}
							btnStyle={classes.customDateBtn}
						/>
					);
				} else if (currentDate.isSame(endDate, 'day')) {
					return (
						<CustomDate
							disabled={disabled}
							date={day}
							type="end"
							hasRoundWrapper={startDate !== null}
							btnStyle={classes.customDateBtn}
						/>
					);
				} else if (currentDate.isBetween(startDate, endDate)) {
					return (
						<CustomDate
							date={day}
							disabled={disabled}
							type="range"
							hasRoundWrapper={false}
							btnStyle={classes.customDateBtn}
						/>
					);
				}

				//set disabled to true if weekend days should be disabled
				if (dayInWeek === 5 || dayInWeek === 6) {
					return (
						<CustomDate
							date={day}
							type="weekend"
							disabled={disabled}
							hasRoundWrapper={false}
							btnStyle={classes.customDateBtn}
						/>
					);
				}

				return (
					<CustomDate
						date={day}
						type="default"
						disabled={disabled}
						hasRoundWrapper={false}
						btnStyle={classes.customDateBtn}
					/>
				);
			},
		[classes.customDateBtn]
	);

	return (
		<div className={props.wrapper}>
			{props.label !== '' ? (
				<div className={classes.labelWrapper}>
					<span className={globalClasses.label}>{props.label}</span>
				</div>
			) : null}
			{/* locale={currentLanguage === 'de' ? de : en} */}
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLanguage === 'de' ? de : enGB}>
				<ThemeProvider theme={intervalCalendarTheme}>
					<IntervalDatePickerWithStyles
						maxDate={moment().add(100, 'years')}
						value={props.value}
						onChange={(date) => {
							if (isOpen) {
								//prevents autoOk triggering extra onChange call and checks if provided date is not null
								if (!isAccepted.current && date) {
									props.onDateChange(date);
								}
							} else {
								if (moment(date).isValid()) props.onKeyboardDateChange(date, props.id);
							}
						}}
						format="dd.MM.yyyy"
						disabled={props.disabled}
						cancelLabel={
							<Button
								className={classes.cancelBtn}
								onClick={() => {
									props.onDatesCancel();
									setIsOpen(false);
								}}>
								{t('data_cancel_button')}
							</Button>
						}
						open={isOpen}
						autoOk
						onOpen={() => {
							isAccepted.current = false;
							setIsOpen(true);
						}}
						clearable={props.clearable}
						clearLabel={
							<Button
								className={classes.resetBtn}
								onClick={() => {
									setIsOpen(false);
									props.clearDates();
								}}>
								{t('data_reset_button')}
							</Button>
						}
						okLabel={
							<Button
								className={classes.useBtn}
								onClick={() => {
									isAccepted.current = true;
									setIsOpen(false);
									props.onDatesAccept();
								}}>
								{t('data_use_button')}
							</Button>
						}
						className={props.inputWrapper ? props.inputWrapper : classes.inputWrapper}
						renderDay={renderDay(props.dates.fromDate, props.dates.toDate)}
						InputProps={{
							endAdornment: props.endAdornment,
							classes: {
								input: classes.input,
								underline: classes.underlineField,
							},
						}}
						DialogProps={{
							onBackdropClick: () => {
								props.onDatesCancel();
								setIsOpen(false);
							},
						}}
						rightArrowIcon={<CalendarArrowIcon />}
						leftArrowIcon={<CalendarArrowIcon className={classes.leftArrowIcon} />}
						ToolbarComponent={({ date }) => {
							const year = date?.getFullYear() || null;
							const month = date?.toLocaleString('default', { month: 'long' }) || null;

							const startDate = props.dates.fromDate
								? `${t(props.dates.fromDate.format('MMM'))} ${props.dates.fromDate.format('D')}`
								: '';
							const endDate = props.dates.toDate
								? `${t(props.dates.toDate.format('MMM'))} ${props.dates.toDate.format('D')}`
								: '';

							return (
								<div className={classes.toolbarContainer}>
									<div className={classes.toolbarWrapper}>
										<div className={classes.toolbarContent}>
											<div className={classes.toolbarText}>{year}</div>
											<div className={classes.toolbarText}>{month ? t(month) : null}</div>
											<div className={classes.toolbarText}>{`${startDate} ${
												startDate && endDate && '-'
											} ${endDate}`}</div>
										</div>
									</div>
								</div>
							);
						}}
					/>
				</ThemeProvider>
			</MuiPickersUtilsProvider>

			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
};

interface StyleProps {
	disabled?: boolean;
	fixed?: boolean;
}

const intervalCalendarTheme = createMuiTheme({
	overrides: {
		MuiDialog: {
			paperWidthSm: {
				maxWidth: '350px',
			},
		},
		MuiPaper: {
			root: {
				width: '100%',
			},
		},
		MuiPickersBasePicker: {
			pickerViewLandscape: {
				padding: 0,
			},
			container: {
				display: 'block',
				height: '100%',
				overflow: 'hidden',
				width: '100%',
			},
			pickerView: {
				backgroundColor: colors.white,
				overflow: 'hidden',

				maxWidth: 'unset',
				width: '100%',
			},
		},
		MuiDialogActions: {
			root: {
				marginTop: '24px',
			},
		},
		MuiPickersStaticWrapper: {
			staticWrapperRoot: {
				minWidth: '390px',
				display: 'block',
				backgroundColor: 'transparent',
			},
		},
		MuiButton: {
			root: {
				textTransform: 'unset',
			},
		},
		MuiPickersDay: {
			daySelected: {
				borderRadius: 4,
				color: colors.white,
				backgroundColor: colors.red,
			},
			hidden: {
				opacity: 0.5,
			},
			day: {
				margin: '0',
				boxSizing: 'inherit',
			},
		},
		MuiTypography: {
			body1: {
				fontSize: '14px',
				fontWeight: 'bold',
			},
			noWrap: {
				display: 'hidden',
			},
		},
		MuiPickersCalendarHeader: {
			iconButton: {
				padding: 0,
				'&:hover': {
					backgroundColor: colors.white,
				},
				'&:disabled': {
					opacity: 0.2,
				},
			},
			dayLabel: {
				width: 'unset',
				margin: 0,
				color: `${colors.darkGray}`,
				fontWeight: 'bold',
				fontSize: '14px',
			},
			switchHeader: {
				position: 'relative',
				margin: '32px 0 24px 0 !important',
				padding: '0 24px',
			},
			daysHeader: {
				position: 'relative',
				padding: '0 18px',
				justifyContent: 'space-between',
			},
		},
		MuiDialogContent: {
			root: {
				display: 'flex',
				justifyContent: 'center',
			},
		},
		MuiPickersCalendar: {
			week: {
				justifyContent: 'space-between',
				padding: '0px 6px',
			},
			transitionContainer: {
				flexGrow: 1,
				marginTop: '12px',
				'&:has(> div > div:nth-child(6))': {
					minHeight: '260px',
				},
			},
		},
	},
});

const useStyles = makeStyles(() => ({
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: (p: StyleProps) => ({
		width: '100%',
		borderRadius: '4px',
		backgroundColor: p.fixed ? 'rgba(161, 161, 161, 0.2)' : colors.white,
		border: '1px solid #C0C0C0',
	}),

	'& .Mui-disabled': (p: StyleProps) => ({
		color: `${p.disabled ? colors.lightGray : colors.darkGray} !important`,
	}),
	input: (p: StyleProps) => ({
		...globalInputStyle(p.disabled),
		whiteSpace: 'pre',
		borderRadius: '5px',
		paddingLeft: '12px',
		cursor: p.disabled ? 'default' : 'pointer',
	}),

	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	leftArrowIcon: {
		transform: 'rotate(180deg)',
	},
	resetBtn: {
		textDecoration: 'underline',
		color: colors.darkGray,
		fontSize: '14px',
	},
	cancelBtn: {
		color: colors.darkGray,
		fontSize: '14px',
		fontWeight: 'bold',
		border: `1px solid ${colors.darkGray}`,
		borderRadius: '4px',
		padding: '12px 24px',
	},
	useBtn: {
		color: colors.darkGray,
		fontSize: '14px',
		fontWeight: 'bold',
		background: colors.yellow,
		borderRadius: '4px',
		padding: '12px 24px',
	},
	toolbarContainer: {
		backgroundColor: colors.white,
		width: '100%',
	},
	toolbarWrapper: {
		padding: '12px',
		margin: '0 auto',
	},
	toolbarContent: {
		border: `1px solid ${colors.darkGray}`,
		borderRadius: '4px',
		padding: '12px 24px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	toolbarText: {
		color: colors.darkGray,
		fontSize: '14px',
		fontWeight: 'bold',
	},
	customDateBtn: {
		minWidth: '48px !important',
		padding: '8px 0px !important',
	},
}));

export default IntervalDatePicker;
