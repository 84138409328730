export type ErrorState = {
	error: string;
};

export type Action = {
	type: string;
	[key: string]: any;
};

export const errorInitialState: ErrorState = {
	error: '',
};

export const SET_ERROR = 'SET_ERROR';

const errorReducer = (state = errorInitialState, action: Action) => {
	switch (action.type) {
		case SET_ERROR:
			return { ...state, error: action.error };

		default:
			return state;
	}
};

export default errorReducer;
