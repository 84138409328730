import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Filter from '../components/Filter';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import NewLink from '../components/NewLink';
import RhenusCaseList from '../components/RhenusCaseList';
import StandardTextField from '../components/StandardTextField';
import WorkinTimeMaterialExpensess from '../components/WorkingTimeMaterialExpenses';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { rhenusCaseInputFields } from '../containers/RhenusCaseContainer';
import { initRhenusCaseInputFilters, RhenusCaseFilterKeys } from '../containers/ZoneOverviewContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ContractZone } from '../helpers/contractHelper/generalContractHelper';
import { checkIfUserStopWriting } from '../helpers/filters';
import { filterRhenusCasePartStatusKeyValuePairs } from '../helpers/rhenusInputHelper/generalRhenusHelper';
import {
	DEFAULT_NO_VALUE,
	filterApprovedStatusKeyValuePairs,
} from '../helpers/standardInputHelper/generalStandardInputHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import Contract from '../service/types/Contract';
import RhenusCase, { RhenusCaseFilterInterface } from '../service/types/RhenusCase';
import { UserRole } from '../service/types/User';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';

interface Props {
	intervalDates: IntervalDatePickerDates;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
	contractData: Contract | undefined;
	zone: ContractZone | undefined;
	rhenusCaseInputs: RhenusCase[];
	allAssignedQAIs: KeyValue[];
	filters: RhenusCaseFilterInterface;
	updatedText: string;
	onNewErrorCasePress: () => void;
	clearAllFilters: () => void;
	onFilterChange: (key: RhenusCaseFilterKeys, value: any) => void;
	onViewPress: (rhenusCase: RhenusCase) => void;
	onApprovePress: (rhenusCase: RhenusCase) => void;
	onWorkingTimePress: () => void;
	onExpensesPress: () => void;
}
function ZoneOverviewScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const globalClasses = useGlobalStyles({ showFilters, description: colors.lightGray });
	const classes = useStyles({});
	const { t } = useTranslation();
	const filterListRef = React.useRef<any>(null);
	const { user } = useAuthStateContext();
	const onFilterPress = () => setShowFilters((prev) => !prev);
	const searchRef = React.useRef<RhenusCaseFilterInterface>({ ...initRhenusCaseInputFilters });
	const { control, errors } = useFormContext();
	if (!props.contractData || !props.zone) return <div></div>;
	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
				<HomeButton contractID={props.contractData.id} contractNumber={props.contractData.contractNumber} />
			</div>
			<div className={classes.row}>
				<div className={classes.titleWrapper}>
					<span
						className={
							globalClasses.title
						}>{`${props.contractData.contractNumber} - ${props.zone.name}`}</span>
				</div>
				<NewLink onClick={props.onNewErrorCasePress} text={'rhenus_case_zone_overview_screen_add_new'} />
			</div>
			<WorkinTimeMaterialExpensess
				onMaterialAndExpensses={props.onExpensesPress}
				onWorkingTimePress={props.onWorkingTimePress}
			/>
			<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />
			<div
				className={globalClasses.filterWrapper}
				style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
				<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={rhenusCaseInputFields.productionNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('rhenus_case_zone_overview_screen_production_number')}
									value={value}
									type="number"
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'productionNumber');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={rhenusCaseInputFields.partNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('rhenus_case_zone_overview_screen_part_number')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'partNumber');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={rhenusCaseInputFields.colorNumber}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('rhenus_case_zone_overview_screen_color_number')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'colorNumber');
									}}
								/>
							)}
						/>
					</Grid>
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<IntervalDatePicker
								id="start"
								error={errors['fromDate']}
								helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
								value={props.filters.fromDate}
								label={t('rhenus_case_zone_overview_screen_contract_from_date')}
								dates={props.intervalDates}
								clearable={true}
								onKeyboardDateChange={props.onKeyboardDateChange}
								onDateChange={props.onDateChange}
								onDatesAccept={props.onDatesAccept}
								onDatesCancel={props.onDatesCancel}
								clearDates={props.clearDates}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<IntervalDatePicker
								id="end"
								error={errors['toDate']}
								helperText={errors['toDate'] ? errors['toDate'].message : ''}
								value={props.filters.toDate}
								label={t('rhenus_case_zone_overview_screen_contract_to_date')}
								dates={props.intervalDates}
								clearable={true}
								onKeyboardDateChange={props.onKeyboardDateChange}
								onDateChange={props.onDateChange}
								onDatesAccept={props.onDatesAccept}
								onDatesCancel={props.onDatesCancel}
								clearDates={props.clearDates}
							/>
						</Grid>
					) : null}
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('rhenus_case_zone_overview_screen_type_of_inspection')}
							select
							value={props.filters.typeOfInspection}
							onChange={(e) => props.onFilterChange('typeOfInspection', e.target.value)}>
							{[' ', ...props.contractData.typesOfInspection].map((item) => (
								<MenuItem key={item} value={item}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t(item)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('rhenus_case_zone_overview_screen_error_type')}
							select
							value={props.filters.errorType}
							onChange={(e) => props.onFilterChange('errorType', e.target.value)}>
							{[' ', ...props.contractData.errorTypes].map((item) => (
								<MenuItem key={item} value={item}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t(item)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('rhenus_case_zone_overview_screen_amount_of_parts')}
							select
							value={props.filters.errorType}
							onChange={(e) => props.onFilterChange('amountOfParts', e.target.value)}>
							{[' ', ...(props.contractData.amountOfParts ? props.contractData.amountOfParts : [])].map(
								(item) => (
									<MenuItem key={item} value={item}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item)}</span>
										</div>
									</MenuItem>
								)
							)}
						</StandardTextField>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('rhenus_case_zone_overview_screen_part_status')}
							select
							value={props.filters.partStatus}
							onChange={(e) => props.onFilterChange('partStatus', e.target.value)}>
							{filterRhenusCasePartStatusKeyValuePairs.map((item) => (
								<MenuItem key={item.key} value={item.value}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t(item.key)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={rhenusCaseInputFields.storage}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('rhenus_case_zone_overview_screen_storage')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'storage');
									}}
								/>
							)}
						/>
					</Grid>

					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<KeyValueAutoComplete
								label={t('rhenus_case_zone_overview_screen_assigned_qai')}
								onChange={(e) => {
									if (e) {
										props.onFilterChange('qai', e.value.id.toString());
									} else {
										props.onFilterChange('qai', '');
									}
								}}
								options={props.allAssignedQAIs}
								multiple={false}
								value={{
									key: props.allAssignedQAIs.find(
										(qai) => qai.value.id.toString() === props.filters.qai
									)?.key
										? props.allAssignedQAIs.find(
												(qai) => qai.value.id.toString() === props.filters.qai
										  )!.key
										: '',
									value: props.filters.qai,
								}}
							/>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_shift')}
								select
								value={props.filters.shift}
								onChange={(e) => props.onFilterChange('shift', e.target.value)}>
								{[
									DEFAULT_NO_VALUE,
									...props.contractData.shiftTimes.map((shift) => ({
										key: shift.name,
										value: shift.id,
									})),
								].map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						</Grid>
					) : null}
					{user?.role !== UserRole.QualityAssuranceInspector && !props.contractData.automatedUpdates ? (
						<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_contract_approved')}
								select
								value={props.filters.approved}
								onChange={(e) => props.onFilterChange('approved', e.target.value)}>
								{filterApprovedStatusKeyValuePairs.map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						</Grid>
					) : null}
				</Grid>
			</div>
			<div className={classes.lastUpdateWrapper}>
				<span className={globalClasses.description}>{`${t(
					'rhenus_case_zone_overview_screen_last_updated'
				)}: ${t(props.updatedText)}`}</span>
			</div>
			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.subtitle}>{`${t(
					'rhenus_case_zone_overview_screen_previously_reported'
				)}`}</span>
			</div>
			<RhenusCaseList
				onApprovePress={props.onApprovePress}
				filters={props.filters}
				shiftTimes={props.contractData.shiftTimes}
				amountOfParts={props.contractData.amountOfParts ? [' ', ...props.contractData.amountOfParts] : [' ']}
				onFilterChange={props.onFilterChange}
				automatedUpdates={props.contractData.automatedUpdates}
				onViewPress={props.onViewPress}
				typesOfInspections={[' ', ...props.contractData.typesOfInspection]}
				errorTypes={[' ', ...props.contractData.errorTypes]}
				allAssignedQAIs={props.allAssignedQAIs}
				photo={props.contractData.isPhotoAllowed}
				rhenusCaseInputs={props.rhenusCaseInputs}
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	subtitleWrapper: {
		marginTop: '8px',
	},
	lastUpdateWrapper: {
		marginTop: '40px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleWrapper: {
		marginBottom: '12px',
	},
	input: {
		...globalInputStyle(),
		whiteSpace: 'pre',
	},
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

export default ZoneOverviewScreen;
