import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from 'moment';
import React from 'react';

import { de, enGB } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import i18n from '../i18n';
import ErrorIcon from '../svgComponents/ErrorIcon';

export const DatePickerWithStyles = withStyles({
	root: {
		minWidth: 170,
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiInput-input': {
			cursor: 'text',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
	},
})(KeyboardDatePicker);

interface Props {
	label: string;
	onChange: (event: any) => void;
	error?: boolean;
	helperText?: string;
	isUserNotEditing?: boolean;
	inputWrapper?: string;
	wrapper?: string;
	disabled?: boolean;
	fixed?: boolean;
	value?: string | Moment;
}

function StandardDateTimePicker(props: Props) {
	const classes = useStyles({
		disabled: props.disabled,
		fixed: props.fixed,
	});
	const globalClasses = useGlobalStyles({
		disabled: props.disabled,
		isUserNotEditing: props.isUserNotEditing,
		fixed: props.fixed,
	});
	const [isOpen, setIsOpen] = React.useState(false);
	const { t } = useTranslation();

	const currentLanguage = i18n.language;

	return (
		<div className={props.wrapper}>
			{props.label !== '' ? (
				<div className={classes.labelWrapper}>
					<span className={globalClasses.label}>{props.label}</span>
				</div>
			) : null}
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLanguage === 'de' ? de : enGB}>
				<DatePickerWithStyles
					format="dd.MM.yyyy HH:mm"
					ampm={false}
					value={props.value}
					cancelLabel={t('data_cancel_button')}
					okLabel={t('data_cancel_ok')}
					open={isOpen}
					onOpen={() => setIsOpen(true)}
					clearLabel={t('data_clear_button')}
					onClose={() => {
						setIsOpen(false);
					}}
					className={props.inputWrapper ? props.inputWrapper : classes.inputWrapper}
					onChange={(val) => {
						props.onChange(val);
					}}
					InputProps={{
						classes: {
							input: classes.input,
							underline: classes.underlineField,
						},
					}}
				/>
			</MuiPickersUtilsProvider>

			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
	fixed?: boolean;
}

const useStyles = makeStyles(() => ({
	hide: {
		display: 'none',
	},
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: (p: StyleProps) => ({
		width: '100%',
		borderRadius: '4px',
		backgroundColor: p.fixed ? 'rgba(161, 161, 161, 0.2)' : colors.white,
		border: '1px solid #C0C0C0',
	}),
	dropdownContainer: {
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
	},
	dropdownArrowWrapper: { position: 'absolute', right: '12.5px', cursor: 'pointer' },
	dropdownMenuWrapper: {
		height: '1.1876em',
	},
	'& .Mui-disabled': (p: StyleProps) => ({
		color: `${p.disabled ? colors.lightGray : colors.darkGray} !important`,
	}),
	input: (p: StyleProps) => ({
		...globalInputStyle(p.disabled),
		whiteSpace: 'pre',
		borderRadius: '5px',
		paddingLeft: '12px',
		cursor: 'pointer',
	}),

	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
}));

export default StandardDateTimePicker;
