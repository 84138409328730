import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { useTranslation } from 'react-i18next';

const TextFieldWithCSS = withStyles({
	root: {
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `1px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'solid',
		},
		'& .Mui-error': {
			color: colors.error,
		},
		'& .MuiInput-underline:after': {
			transform: `scaleX(1)`,
		},
	},
})(TextField);

interface Props {
	label: string;
	error: boolean;
	helperText: string;
	onChange: (event: any) => void;
	[key: string]: any;
}

function AuthTextField(props: Props) {
	const classes = useStyles({ disabled: props.disabled });
	const globalClasses = useGlobalStyles({ description: colors.lightGray });
	const { t } = useTranslation();

	return (
		<TextFieldWithCSS
			{...props}
			label={props.label}
			onChange={props.onChange}
			error={props.error}
			helperText={
				props.error ? (
					<div className={classes.row}>
						<div className={classes.errorImageWrapper}>
							<ErrorIcon />
						</div>
						<span className={globalClasses.error}>{t(props.helperText)}</span>
					</div>
				) : null
			}
			className={classes.inputWrapper}
			InputLabelProps={{
				classes: {
					shrink: classes.shrinkLabel,
					formControl: globalClasses.description,
				},
			}}
			InputProps={{
				classes: {
					input: classes.input,
					underline: classes.underlineField,
				},
			}}
		/>
	);
}

interface StyleProps {
	disabled: boolean;
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	shrinkLabel: {
		color: colors.lightGray,
		fontSize: '12px',
		lineHeight: '16px',
	},

	underlineField: {
		'&:before': {
			borderBottom: `1px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `1px solid ${colors.lightGray}`,
		},
	},
	input: (p: StyleProps) => ({
		...globalInputStyle(),
		zIndex: p.disabled ? -1 : 0,
	}),
	inputWrapper: {
		width: '100%',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
}));

export default AuthTextField;
