import BreakTime from '../../service/types/BreakTime';
import { addBreakTimeFields, addWorkingTimeFields } from '../workingTimeHelper/generalWorkingTimeHelper';
import moment from 'moment';

export function createBreakTimeWithID(data: any, record: BreakTime) {
	return new BreakTime(
		record.id,
		record.reporter,
		data[addBreakTimeFields.qai].value.id,
		record.date,
		Math.round(
			moment(data[addWorkingTimeFields.duration])
				.set('s', 0)
				.diff(moment(record.date).set('s', 0).set('h', 0).set('m', 0)) /
				1000 /
				60
		) * 60,
		record.type
	);
}
