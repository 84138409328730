import React from 'react';

interface Props {
	buttonWrapperClassName: string;
	renderAsButton?: boolean;
	text: string;
	textClassName: string;
	style?: any;
	onClick: () => void;
	otherProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

function Button(props: Props) {
	return (
		<>
			{props.renderAsButton ? (
				<button
					{...props.otherProps}
					className={props.buttonWrapperClassName}
					style={props?.style}
					onClick={props.onClick}>
					<span className={props.textClassName}>{props.text}</span>
				</button>
			) : (
				<div className={props.buttonWrapperClassName} style={props?.style} onClick={props.onClick}>
					<span className={props.textClassName}>{props.text}</span>
				</div>
			)}
		</>
	);
}

export default Button;
