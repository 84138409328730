import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import RouteGuard from 'react-guard';
import i18n from '../i18n';

import LoginContainer, { LoginType } from '../containers/LoginContainer';
import { RouteRestrictions, Routes, RoutesName } from './Routes';
import ForgotPasswordContainer from '../containers/ForgotPasswordContainer';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import { usePrevious } from '../hooks/usePrevious';
import { getLoginRedirectRoute } from '../consts/login';
import { languages, LANGUAGE_KEY } from '../consts/language';
import ContactUsContainer from '../containers/ContactUsContainer';
import ErrorWrapper from '../components/ErrorWrapper';
import LegalNoticeDialog from '../components/LegalNoticeDialog';
import ErrorServiceDialog from '../components/ErrorServiceDialog';
import HomeContainer from '../containers/HomeContainer';
import SettingsContainer from '../containers/SettingsContainer';
import UserOverviewContainer from '../containers/UserOverviewContainer';
import ContractContainer from '../containers/ContractContainer';
import CustomerOverviewContainer from '../containers/CustomerOverviewContainer';
import CustomerContainer from '../containers/CustomerContainer';
import StandardCaseInputContainer from '../containers/StandardCaseInputContainer';
import ProcessContractContainer from '../containers/ProcessContractContainer';
import ContractOverviewContainer from '../containers/ContractOverviewContainer';
import { ContractViewType } from '../helpers/contractHelper/generalContractHelper';
import ZoneOverviewContainer from '../containers/ZoneOverviewContainer';
import ContactPersonContainer from '../containers/ContactPersonContainer';
import { goToContract, goToDashboard, goToExpenses, goToProcessContract } from '../helpers/navigationHelper';
import RhenusCaseContainer from '../containers/RhenusCaseContainer';
import WorkingTimeContainer from '../containers/WorkingTimeContainer';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import { useWorkingTimeStateContext } from '../context/workingTime/useWorkingTime';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import ExpensesContainer from '../containers/ExpensesContainer';
import SummaryContainer from '../containers/SummaryContainer';
import ChangePasswordContainer from '../containers/ChangePasswordContainer';
import DashboardContainer from '../containers/DashboardContainer';
import Notification from '../components/Notification';
import { createBrowserHistory } from 'history';
import InvoiceContainer from '../containers/InvoiceContainer';

import Entities from '../pages/Entities';
import GroupCreatePage from '../pages/GroupCreatePage';
import GroupUpdatePage from '../pages/groupUpdatePage/GroupUpdatePage';
import GroupOverview from '../pages/groupOverview/GroupOverview';
import UserContainer from '../containers/UserContainer';

interface Props {}

function MainRouter(props: Props) {
	const { user } = useAuthStateContext();
	const previousUserRole = usePrevious(user?.role);

	const legalNoticeRef = React.useRef<null | StandardDialogInterface>(null);
	const { record } = useWorkingTimeStateContext();
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const recordRef = React.useRef<WorkTimeTrack>();

	const closeDialog = () => {
		dialogRef.current?.setDialogState(false);
	};

	const onAccept = () => {
		dialogRef.current?.setDialogState(false);
		if (recordRef.current) {
			goToExpenses(createBrowserHistory(), recordRef.current);
			window.location.reload();
		}
	};

	React.useEffect(() => {
		if (user?.firstName) {
			if (record) {
				recordRef.current = record;
			} else if (recordRef.current) {
				dialogRef.current?.setDialogState(true);
			}
		} else {
			recordRef.current = undefined;
		}
	}, [record, user]);

	React.useEffect(() => {
		const language = localStorage.getItem(LANGUAGE_KEY);
		if (language === null) {
			localStorage.setItem(LANGUAGE_KEY, languages.deutchland.i18n);
		} else {
			i18n.changeLanguage(language);
		}
	}, []);

	return (
		<Router>
			<ErrorWrapper>
				<Switch>
					<Route strict exact path={RoutesName.Login}>
						<LoginContainer legalNoticeRef={legalNoticeRef} type={LoginType.StandardLogin} />
					</Route>
					<Route strict exact path={RoutesName.QAILogin}>
						<LoginContainer legalNoticeRef={legalNoticeRef} type={LoginType.QALogin} />
					</Route>
					<Route strict exact path={RoutesName.ForgotPassword}>
						<ForgotPasswordContainer />
					</Route>
					<Route strict exact path={RoutesName.ContactUs}>
						<ContactUsContainer />
					</Route>
					<Route strict exact path={RoutesName.ResetPassword}>
						{(p: any) => <ResetPasswordContainer {...p} />}
					</Route>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Login}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Home}>
						<HomeContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Settings}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Settings}>
						<SettingsContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Users}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Users}>
						<UserOverviewContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Contracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.AdminContracts}>
						<ContractOverviewContainer
							route={goToContract}
							displaySort
							buttonText={'contract_overview_screen_contract_edit'}
							viewType={ContractViewType.Admin}
							redirectTo={Routes.Contract}
						/>
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Users}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.User}>
						<UserContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Contracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Contract}>
						<ContractContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Customers}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Customers}>
						<CustomerOverviewContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Customers}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Customer}>
						<CustomerContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.ProcessContracts}>
						<ContractOverviewContainer
							route={goToProcessContract}
							displaySort={false}
							buttonText={'contract_overview_screen_contract_view'}
							viewType={ContractViewType.Process}
							redirectTo={Routes.ProcessContract}
						/>
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.ProcessContract}>
						<ProcessContractContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.StandardCaseInput}>
						<StandardCaseInputContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.ZoneOverview}>
						<ZoneOverviewContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Contracts}
						userRole={user?.role}
						path={RoutesName.ContactPerson}>
						<ContactPersonContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.RhenusCaseInput}>
						<RhenusCaseContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.WorkingTime}>
						<WorkingTimeContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ProcessContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Expenses}>
						<ExpensesContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Invoice}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Invoice}>
						<InvoiceContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Contracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Summary}>
						<SummaryContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.ChangePassowrd}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.ChangePassowrd}>
						<ChangePasswordContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.CustomerContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.CustomerContracts}>
						<ContractOverviewContainer
							buttonText={'contract_overview_screen_contract_view'}
							displaySort
							redirectTo={Routes.Dashboard}
							route={goToDashboard}
							viewType={ContractViewType.Customer}
						/>
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.CustomerContracts}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Dashboard}>
						<DashboardContainer />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.GroupOverview}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.GroupOverview}>
						<GroupOverview />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.Entities}
						userRole={user?.role}
						strict
						exact
						path={RoutesName.Entities}>
						<Entities />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.GroupOverview}
						userRole={user?.role}
						strict={true}
						exact={true}
						path={RoutesName.GroupCreate}>
						<GroupCreatePage />
					</RouteGuard>
					<RouteGuard
						homeRoute={RoutesName.Home}
						loginRoute={getLoginRedirectRoute(previousUserRole)}
						authenticationToken={user}
						accessRoles={RouteRestrictions.GroupOverview}
						userRole={user?.role}
						strict={true}
						exact={true}
						path={RoutesName.GroupUpdate}>
						<GroupUpdatePage />
					</RouteGuard>
					<Redirect to={RoutesName.Home} />
				</Switch>
				<ErrorServiceDialog />
				<LegalNoticeDialog ref={legalNoticeRef} />
				<Notification />
				<StandardDialog
					acceptText={'working_time_screen_expensses_dialog_accept_button'}
					description={'working_time_screen_expensses_dialog_description'}
					title={'working_time_screen_expensses_dialog_title'}
					cancleText={'working_time_screen_expensses_dialog_cancel_button'}
					onCancle={closeDialog}
					onAccept={onAccept}
					ref={dialogRef}
					showWarning
				/>
			</ErrorWrapper>
		</Router>
	);
}

export default MainRouter;
