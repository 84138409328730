import React from 'react';
import { makeStyles } from '@material-ui/core';

import { LanguageInterface } from '../consts/language';
import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';

interface Props {
	margin: boolean;
	activeLanguage: string | null;
	language: LanguageInterface;
	inverted?: boolean;
	onLanguagePress: (language: string) => void;
}

interface StyleProps {
	margin: boolean;
	active: boolean;
	inverted?: boolean;
}

function Language(props: Props) {
	const classes = useStyles({
		margin: props.margin,
		active: props.activeLanguage === props.language.i18n,
		inverted: props.inverted,
	});

	return (
		<div className={classes.textWrapper} onClick={() => props.onLanguagePress(props.language.i18n)}>
			<span className={classes.text}>{props.language.key}</span>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	text: (props: StyleProps) => ({
		fontSize: '14px',
		lineHeight: '20px',
		color: props.active
			? props.inverted
				? colors.white
				: colors.darkGray
			: props.inverted
			? 'rgba(255,255,255,0.8)'
			: colors.lightGray,
		fontFamily: props.active ? fonts.semiBold : fonts.regular,
	}),
	textWrapper: (props: StyleProps) => ({
		marginRight: props.margin ? '24px' : '0px',
		cursor: 'pointer',
	}),
}));

export default Language;
