import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import { StandardDialogInterface } from '../components/StandardDialog';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ContractStatusType } from '../helpers/contractHelper/generalContractHelper';
import { Expenses, ExpensesKeys } from '../helpers/expenses/generalExpensesHelper';
import { filterData } from '../helpers/filters';
import { SummaryNavigationInterface, replaceSummaryHistoryData } from '../helpers/navigationHelper';
import { getSummaryData, onSummaryMount } from '../helpers/summaryHelper/readSummaryHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import SummaryScreen from '../screens/SummaryScreen';
import service from '../service/service';
import BreakTime from '../service/types/BreakTime';
import Customer from '../service/types/Customer';
import Invoice from '../service/types/Invoice';
import MaterialTool from '../service/types/MaterialTool';
import Transport from '../service/types/Transport';
import Travel from '../service/types/Travel';
import { UserRole } from '../service/types/User';
import WorkingTime from '../service/types/WorkingTime';

export const startBillingFields = {
	billingPeriodStart: 'billingPeriodStart',
	billingPeriodEnd: 'billingPeriodEnd',
	creationDate: 'creationDate',
	dateOfInvoice: 'dateOfInvoice',
	customer: 'customer',
};

export interface IStartBillingValues {
	billingPeriodStart: Moment | null;
	billingPeriodEnd: Moment | null;
	creationDate: Moment;
	dateOfInvoice: Moment;
	customer: string;
}

export interface SummaryFilterInterface {
	fromDate: Moment | null;
	toDate: Moment | null;
	billed: any;
	qai: any;
}

export const initSummaryFilters: SummaryFilterInterface = {
	fromDate: null,
	toDate: null,
	billed: '',
	qai: [],
};
const data = Object.freeze({ ...initSummaryFilters });
export type SummaryFilterKeys = keyof typeof data;

function SummaryContainer() {
	const [tabType, setTabType] = React.useState<ExpensesKeys>('workingTime');
	const [contractStatus, setContractStatus] = React.useState(ContractStatusType.NotClosed);
	const [filters, setFilters] = React.useState<SummaryFilterInterface>({ ...initSummaryFilters });
	const [summaryData, setSummaryData] = React.useState<Expenses>({
		materials: [],
		tools: [],
		travels: [],
		workingTime: [],
		transport: [],
		invoice: [],
	});
	const [allUsers, setAllUsers] = React.useState<KeyValue[]>([]);
	const history = useHistory();
	const { user } = useAuthStateContext();
	const allSummaryDataRef = React.useRef<Expenses>({
		materials: [],
		tools: [],
		travels: [],
		workingTime: [],
		transport: [],
		invoice: [],
	});
	const [contractData, setContractData] = React.useState<SummaryNavigationInterface>({
		contractNumber: '',
		contractID: 0,
		contractStatus: ContractStatusType.Closed,
		contractCustomer: '',
		workLocations: [],
	});
	const routeParmas = useParams();
	const { t } = useTranslation();

	const methods = useForm<IStartBillingValues>({});

	const [customers, setCustomers] = React.useState<Customer[]>([]);

	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const [intervalDates, setIntervalDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const [startBillingDates, setStartBillingDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const onFilterChange = (key: SummaryFilterKeys, value: string) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));
	};

	React.useEffect(() => {
		service
			.getAllCustomers()
			.then((customers) => {
				setCustomers(customers);
			})
			.catch(() => {});
	}, []);

	React.useEffect(() => {
		setSummaryData({
			...allSummaryDataRef.current,
			[tabType]:
				tabType !== 'invoice'
					? filterData(filters, allSummaryDataRef.current[tabType])
					: allSummaryDataRef.current[tabType],
		});
	}, [filters, tabType]);

	React.useEffect(() => {
		getUserOptionsKeyValuePairs(service.getAllUsers(), () => {})
			.then((users) => setAllUsers(users.filter((user) => user.value.role !== UserRole.Customer)))
			.catch(() => {});
	}, [user]);

	React.useEffect(() => {
		onSummaryMount(history, routeParmas, setContractData, setContractStatus);
	}, [history, routeParmas]);

	React.useEffect(() => {
		if (allSummaryDataRef.current[tabType].length === 0 && contractData.contractID) {
			getSummaryData(tabType, contractData.contractID)
				.then((res: MaterialTool[] | Travel[] | (WorkingTime | BreakTime)[] | Transport[] | Invoice[]) => {
					allSummaryDataRef.current = { ...allSummaryDataRef.current, [tabType]: res };

					setSummaryData({
						...allSummaryDataRef.current,
						[tabType]: tabType !== 'invoice' ? filterData(filters, res) : res,
					});
				})
				.catch(() => {});
		}
	}, [tabType, contractData, filters]);

	const openCloseContract = () => {
		const newContractStatus = contractStatus ? ContractStatusType.Closed : ContractStatusType.NotClosed;
		service
			.openCloseContract(contractData.contractID, newContractStatus)
			.then((res) => {
				if (res) {
					setContractStatus(newContractStatus);
					replaceSummaryHistoryData(history, { ...contractData, status: newContractStatus });
				}
			})
			.catch(() => {});
	};

	const openDialog = () => {
		dialogRef.current?.setDialogState(true);
	};

	const onDialogCancel = () => {
		dialogRef.current?.setDialogState(false);

		methods.reset({
			billingPeriodEnd: null,
			billingPeriodStart: null,
			creationDate: moment(),
			customer: contractData.contractCustomer,
			dateOfInvoice: moment(),
		});
	};

	const onSubmit = React.useCallback(
		(data: IStartBillingValues) => {
			if (data.billingPeriodStart && data.billingPeriodEnd) {
				service
					.createInvoice(
						contractData.contractID,
						data.dateOfInvoice,
						data.billingPeriodStart,
						data.billingPeriodEnd
					)
					.then((res) => {
						dialogRef.current?.setDialogState(false);
						methods.reset({
							billingPeriodEnd: null,
							billingPeriodStart: null,
							creationDate: moment(),
							customer: contractData.contractCustomer,
							dateOfInvoice: moment(),
						});
						setStartBillingDates({
							fromDate: null,
							toDate: null,
						});
					});
			}
		},
		[contractData.contractCustomer, contractData.contractID, methods]
	);

	const onDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);

			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardBillingDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const offset = moment(date).utcOffset();
		let dateMoment = moment(date).add(offset);
		methods.clearErrors(['billingPeriodStart', 'billingPeriodEnd']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(startBillingDates.toDate) || !startBillingDates.toDate) {
				setStartBillingDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				methods.setValue(startBillingFields.billingPeriodStart, dateMoment);
				methods.clearErrors(startBillingFields.billingPeriodStart);
			} else {
				methods.setError('billingPeriodStart', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(startBillingDates.fromDate) || !startBillingDates.fromDate) {
				setStartBillingDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				methods.setValue(startBillingFields.billingPeriodEnd, dateMoment);
				methods.clearErrors(startBillingFields.billingPeriodEnd);
			} else {
				methods.setError('billingPeriodEnd', { message: t('end_interval_error') });
			}
		}
	};

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	const onStartBillingDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);

			if (!startBillingDates.fromDate) {
				setStartBillingDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				startBillingDates.fromDate &&
				!startBillingDates.toDate &&
				moment(dateMoment).isSameOrAfter(startBillingDates.fromDate)
			) {
				setStartBillingDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setStartBillingDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[startBillingDates]
	);

	const onStartBillingDatesAccept = () => {
		methods.setValue(startBillingFields.billingPeriodStart, startBillingDates.fromDate);
		startBillingDates.fromDate && methods.clearErrors(startBillingFields.billingPeriodStart);

		methods.setValue(startBillingFields.billingPeriodEnd, startBillingDates.toDate);
		startBillingDates.toDate && methods.clearErrors(startBillingFields.billingPeriodEnd);
	};

	const onStartBillingDatesCancel = () => {
		const previousStartDate = methods.getValues().billingPeriodStart;
		const previousEndDate = methods.getValues().billingPeriodEnd;

		setStartBillingDates({
			fromDate: previousStartDate,
			toDate: previousEndDate,
		});
	};

	const clearStartBillingDates = () => {
		setStartBillingDates({
			fromDate: null,
			toDate: null,
		});

		methods.reset({
			billingPeriodEnd: null,
			billingPeriodStart: null,
		});
	};

	return (
		<FormProvider {...methods}>
			<SummaryScreen
				tabType={tabType}
				summaryData={summaryData}
				allUsers={allUsers}
				onFilterChange={onFilterChange}
				filters={filters}
				openCloseContractPress={openCloseContract}
				onTabPress={setTabType}
				contractNumber={contractData.contractNumber}
				contractCustomer={contractData.contractCustomer}
				workLocations={contractData.workLocations}
				contractStatus={contractStatus}
				isAdmin={user?.role === UserRole.Administrator}
				dialogRef={dialogRef}
				openDialog={openDialog}
				customers={customers}
				onDialogCancel={onDialogCancel}
				onSubmit={onSubmit}
				clearDates={clearDates}
				intervalDates={intervalDates}
				onKeyboardDateChange={onKeyboardDateChange}
				onDateChange={onDateChange}
				onDatesAccept={onDatesAccept}
				onDatesCancel={onDatesCancel}
				clearStartBillingDates={clearStartBillingDates}
				onKeyboardBillingDateChange={onKeyboardBillingDateChange}
				onStartBillingDateChange={onStartBillingDateChange}
				onStartBillingDatesAccept={onStartBillingDatesAccept}
				onStartBillingDatesCancel={onStartBillingDatesCancel}
				startBillingDates={startBillingDates}
			/>
		</FormProvider>
	);
}

export default SummaryContainer;
