import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ErrorInputType } from '../service/types/StandardCase';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { CHECK_NUMBER_WIDTH, ERROR_TYPE_WIDTH, TABLE_PADDING } from '../screens/StandardCaseInputScreen';
import RemoveIcon from '../svgComponents/RemoveIcon';

interface Props {
	errorInput: ErrorInputType;
	disabled: boolean;
	index: number;
	onRemovePress: (index: number) => void;
}

function ErrorInputRow(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.wrapper}>
			<div className={classes.row}>
				<div className={classes.checkNumberWrapper}>
					<span className={globalClasses.label}>{props.errorInput.amount}</span>
				</div>
				<div className={classes.errorTypeWrapper}>
					<div className={globalClasses.errorInputMenuItemWrapper}>
						<span className={globalClasses.errorInputMenuItem}>{t(props.errorInput.type)}</span>
					</div>
				</div>
			</div>
			{props.disabled ? null : (
				<div className={classes.removeIconWrapper} onClick={() => props.onRemovePress(props.index)}>
					<RemoveIcon />
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		backgroundColor: colors.keyButton,
		borderBottom: '1px solid #C7C7C7',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	checkNumberWrapper: {
		width: CHECK_NUMBER_WIDTH,
		padding: TABLE_PADDING,
	},
	errorTypeWrapper: {
		width: ERROR_TYPE_WIDTH,
		padding: TABLE_PADDING,
	},
	removeIconWrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: '24px',
	},
}));

export default ErrorInputRow;
