import * as React from 'react';

function EditContractIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={150}
			height={150}
			viewBox="0 0 150 150"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<defs>
				<filter
					x="-5.6%"
					y="-2.8%"
					width="111.3%"
					height="111.3%"
					filterUnits="objectBoundingBox"
					id="editContract1">
					<feMorphology radius={2} in="SourceAlpha" result="shadowSpreadOuter1" />
					<feOffset dy={4} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0.2 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<rect id="editContract2" x={0} y={0} width={142} height={142} rx={4} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(4)">
					<use fill="#000" filter="url(#editContract1)" xlinkHref="#editContract2" />
					<use fill="#FFF" xlinkHref="#editContract2" />
				</g>
				<path fill="#FFD500" opacity={0.2} d="M146 0L4 143V0z" />
				<g stroke="#111" strokeWidth={2.5}>
					<g strokeLinecap="square">
						<path d="M86.078 110.944l-11.85 2.962 2.963-11.849 26.66-26.66 8.887 8.886zM63.86 113.906H42V28h66.295v38.51" />
					</g>
					<g transform="translate(52 39)" strokeLinecap="round" strokeLinejoin="round">
						<circle cx={11.186} cy={11.831} r={3.208} />
						<path d="M19.74 11.831a8.443 8.443 0 00-.304-2.138l2.738-2.353-1.604-2.78-3.377 1.19a8.53 8.53 0 00-3.738-2.14L12.79.07H9.582l-.665 3.55A8.53 8.53 0 005.18 5.757L1.802 4.56.198 7.34l2.738 2.353a8.443 8.443 0 00-.304 2.138c.01.723.111 1.442.304 2.139L.198 16.322l1.604 2.78 3.377-1.187a8.53 8.53 0 003.738 2.139l.665 3.539h3.208l.665-3.55a8.53 8.53 0 003.738-2.139l3.377 1.187 1.604-2.78-2.738-2.341a8.443 8.443 0 00.304-2.139z" />
					</g>
				</g>
			</g>
		</svg>
	);
}

export default EditContractIcon;
