import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import BackArrow from '../svgComponents/BackArrow';
import { ForgotPasswordData } from '../consts/login';
import { useGlobalStyles } from '../consts/globalStyles';

interface Props {
	dataForForgotPassword: ForgotPasswordData;
	onLinkPress: () => void;
}

function MailSentScreen(props: Props) {
	const globalClasses = useGlobalStyles({ description: colors.gray });
	const classes = useStyles({});
	const { t } = useTranslation();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
			</div>
			<Grid container className={classes.wrapper}>
				<Grid item sm={1} />
				<Grid item sm={4}>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t(props.dataForForgotPassword.sentTitle)}</span>
					</div>
					<div className={classes.descriptionWrapper}>
						<span className={globalClasses.description}>
							{t(props.dataForForgotPassword.sentDescription)}
						</span>
					</div>
					<div className={classes.linkTitleWrapper}>
						<span className={classes.linkTitle}>{t(props.dataForForgotPassword.linkTitle)}</span>
					</div>
					<div className={globalClasses.linkWrapper} onClick={props.onLinkPress}>
						<span className={globalClasses.link}>{t(props.dataForForgotPassword.link)}</span>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: {
		marginTop: '50px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},
	descriptionWrapper: {
		marginBottom: '36px',
	},
	linkTitleWrapper: {
		marginBottom: '4px',
	},
	linkTitle: {
		color: colors.gray,
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		letterSpacing: '0.4px',
	},
}));

export default MailSentScreen;
