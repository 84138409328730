import { standardCaseInputFields } from '../../containers/StandardCaseInputContainer';
import { Service } from '../../service/service';
import StandardCaseInput, { ErrorInputType } from '../../service/types/StandardCase';
import User from '../../service/types/User';
import { History, LocationState } from 'history';
import { ShiftInterface } from '../contractHelper/generalContractHelper';
import moment from 'moment';
import Contract from '../../service/types/Contract';

export function createStandardInputCase(
	data: any,
	history: History<LocationState>,
	contract: Contract,
	service: Service,
	user: User,
	partsNIO: number,
	partsIO: number,
	errorParts: ErrorInputType[],
	refreshForm?: () => void,
	updateAllStandardCaseInputs?: (input: StandardCaseInput[]) => void
) {
	const standardCase = getStandardCaseInputWithoutID(data, partsNIO, partsIO, user, errorParts, contract);
	service
		.createStandradCaseInput(contract.id, standardCase)
		.then((res) => {
			if (res) {
				refreshForm ? refreshForm() : history.goBack();

				if (updateAllStandardCaseInputs) {
					service.getAllStandardCaseInputs(contract.id).then((res) => {
						updateAllStandardCaseInputs(res);
					});
				}
			}
		})
		.catch(() => {});
}

function getStandardCaseInputWithoutID(
	data: any,
	partsNIO: number,
	partsIO: number,
	user: User,
	errorParts: ErrorInputType[],
	contract: Contract
) {
	return new StandardCaseInput(
		0,
		data[standardCaseInputFields.partNumber],
		data[standardCaseInputFields.deliveryNoteNumber] !== '' &&
		data[standardCaseInputFields.deliveryNoteNumber] !== null
			? +data[standardCaseInputFields.deliveryNoteNumber]
			: null,
		moment(data[standardCaseInputFields.deliveryNoteDate]),
		data[standardCaseInputFields.totalPartsTested],
		partsNIO,
		partsIO,
		data[standardCaseInputFields.partsReworked] ? data[standardCaseInputFields.partsReworked] : 0,
		0,
		user.id,
		data[standardCaseInputFields.typeOfInspection],
		errorParts,
		parseInt(data[standardCaseInputFields.shift]),
		moment(data[standardCaseInputFields.date]),
		contract.automatedUpdates ? true : data[standardCaseInputFields.approved],
		data[standardCaseInputFields.comment],
		data[standardCaseInputFields.photo]
			? {
					...data[standardCaseInputFields.photo],
					data: data[standardCaseInputFields.photo].data.split(',')[1],
			  }
			: undefined,
		// @ts-ignore: Object is possibly 'undefined'
		contract && contract?.workLocations
			? contract.workLocations?.find((el) => el.name === data?.[standardCaseInputFields.location])?.id
			: undefined
	);
}

export function createNewErrorInput(
	data: any,
	errorParts: ErrorInputType[],
	setErrorParts: (errorParts: ErrorInputType[]) => void,
	setError: (field: string, error: { message: string }) => void,
	setValue: (field: string, val: any) => void,
	getValues: (field: string) => any
) {
	let checkNumberError = false;
	let errorTypeError = false;

	checkNumberError = checkIfCheckNumberIsValid(data, setError);
	errorTypeError = checkIfErrorTypeIsValid(data, setError);

	if (checkNumberError || errorTypeError) {
		return;
	}

	if (!checkIfThereAreEnoughTestedParts(data, errorParts, setError, getValues)) return;

	setErrorParts([
		{
			amount: Number(data[standardCaseInputFields.checkNumber]),
			type: data[standardCaseInputFields.errorType],
		},
		...errorParts,
	]);
	setValue(standardCaseInputFields.checkNumber, '');
	setValue(standardCaseInputFields.errorType, '');
}

function checkIfThereAreEnoughTestedParts(
	data: any,
	errorParts: ErrorInputType[],
	setError: (field: string, error: { message: string }) => void,
	getValues: (field: string) => any
) {
	const numberOfErrorParts =
		Number(data[standardCaseInputFields.checkNumber]) +
		errorParts.reduce((prev, curr) => {
			return prev + Number(curr.amount);
		}, 0);

	if (numberOfErrorParts > Number(getValues(standardCaseInputFields.totalPartsTested))) {
		setError(standardCaseInputFields.checkNumber, { message: 'standard_case_screen_total_parts_tested_error' });
		return false;
	} else return true;
}

function checkIfCheckNumberIsValid(data: any, setError: (field: string, error: { message: string }) => void) {
	if (!data[standardCaseInputFields.checkNumber] || data[standardCaseInputFields.checkNumber].length === 0) {
		setError(standardCaseInputFields.checkNumber, { message: 'standard_case_screen_table_required' });
		return true;
	}
	return false;
}

function checkIfErrorTypeIsValid(data: any, setError: (field: string, error: { message: string }) => void) {
	if (!data[standardCaseInputFields.checkNumber] || data[standardCaseInputFields.checkNumber].length === 0) {
		setError(standardCaseInputFields.errorType, { message: 'standard_case_screen_table_required' });
		return true;
	}
	return false;
}

export function findCurrentShift(shiftTimes: ShiftInterface[]) {
	const currentShift = shiftTimes.find((shift) => {
		const startTime = moment()
			.set('hour', Number(shift.from.split(':')[0]))
			.set('minutes', Number(shift.from.split(':')[1]));
		const endTime = moment()
			.set('hour', Number(shift.to.split(':')[0]))
			.set('minutes', Number(shift.to.split(':')[1]));

		return moment().isBetween(startTime, endTime);
	});

	if (currentShift) {
		return currentShift;
	} else {
		let closestIndex = 0;
		let smallestDifference = 36000;

		shiftTimes.forEach((shift, index) => {
			const startTime = moment()
				.set('hour', Number(shift.from.split(':')[0]))
				.set('minutes', Number(shift.from.split(':')[1]));
			const stratTimeDiff = Math.abs(moment().diff(startTime));
			if (stratTimeDiff < smallestDifference) {
				smallestDifference = stratTimeDiff;
				closestIndex = index;
			}
			const endTime = moment()
				.set('hour', Number(shift.to.split(':')[0]))
				.set('minutes', Number(shift.to.split(':')[1]));
			const endTimeDiff = Math.abs(moment().diff(endTime));
			if (endTimeDiff < smallestDifference) {
				smallestDifference = endTimeDiff;
				closestIndex = index;
			}
		});
		return shiftTimes[closestIndex];
	}
}
