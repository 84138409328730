import { History, LocationState } from 'history';
import { RouteParams } from '../../router/Routes';
import service from '../../service/service';
import { goToHome } from '../navigationHelper';
import { IExpensesContractData } from '../../containers/ExpensesContainer';

export function onWorkingTimeMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setContractData: (contractData: IExpensesContractData) => void
) {
	if (routeParams.contract_id) {
		service
			.getContract(Number(routeParams.contract_id))
			.then((res) => {
				if (res)
					setContractData({
						contractID: res.id,
						contractNumber: res.contractNumber,
						workLocations: res.workLocations,
					});
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		goToHome(history);
	}
}
