import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import { Sort } from '../ContractList';
import { SortingDir } from '../../helpers/dataSorter';
import { useGlobalStyles } from '../../consts/globalStyles';

interface Props {
	displaySort?: boolean;
	field?: string;
	sort?: Sort;
	value: string;
	setSort?: React.Dispatch<React.SetStateAction<Sort>>;
}

function TitleTypeCell({ displaySort = false, field, sort, setSort, value }: Props) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const classes = useStyles({});

	const handlePress = () => {
		setSort &&
			setSort((prev) => {
				if (prev.field !== field) {
					return {
						field,
						direction: SortingDir.ASC,
					};
				} else {
					if (prev.direction === SortingDir.ASC)
						return {
							field,
							direction: SortingDir.DCS,
						};

					return {
						field: undefined,
						direction: undefined,
					};
				}
			});
	};

	if (field && sort && setSort && displaySort) {
		return (
			<button className={`${classes.button} ${globalClasses.tableTitle}`} onClick={handlePress}>
				{t(value)}
				<ArrowSort field={field} sort={sort} />
			</button>
		);
	}

	return <span className={globalClasses.tableTitle}>{t(value)}</span>;
}

interface ArrowProps {
	field?: string;
	sort: Sort;
}

export function ArrowSort({ field, sort }: ArrowProps) {
	if (field === sort.field) {
		if (sort.direction === SortingDir.ASC) return <span>&#8595;</span>;
		else return <span>&#8593;</span>;
	} else return <span>&#8597;</span>;
}

const useStyles = makeStyles(() => ({
	button: {
		border: 'none',
		backgroundColor: 'transparent',
		cursor: 'pointer',
	},
}));

export default TitleTypeCell;
