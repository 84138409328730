import Address from './Address';
import User from './User';

export interface CustomerInterface {
	name: any;
	billingAddressGenerated: any;
	deliveryAddressGenerated: any;
}

class Customer implements CustomerInterface {
	id: number;
	name: string;
	billingAddressGenerated: string;
	deliveryAddressGenerated: string;
	billingAdrress: Address;
	deliveryAddress: Address;
	users: User[];

	customerNumber?: string;
	supplierNumber?: string;
	invoiceEmail?: string;
	reminderEmail?: string;
	otherEmails?: string[];
	vatNumber?: string;
	termOfPayment?: string;

	constructor(
		id: number,
		name: string,
		billingAddress: Address,
		deliveryAddress: Address,
		users: User[],
		customerNumber: string,
		supplierNumber: string,
		invoiceEmail: string,
		reminderEmail: string,
		otherEmails: string[],
		vatNumber: string,
		termOfPayment: string
	) {
		this.id = id;
		this.name = name.trim();
		this.billingAddressGenerated = billingAddress.toString();
		this.deliveryAddressGenerated = deliveryAddress.toString();
		this.billingAdrress = billingAddress;
		this.deliveryAddress = deliveryAddress;
		this.users = users;

		this.customerNumber = customerNumber;
		this.supplierNumber = supplierNumber;
		this.invoiceEmail = invoiceEmail;
		this.reminderEmail = reminderEmail;
		this.otherEmails = otherEmails;
		this.vatNumber = vatNumber;
		this.termOfPayment = termOfPayment;
	}

	public static fromJSON(maybe: any): Customer {
		if (!maybe) {
			throw Error('must exist');
		}

		if (typeof maybe.name !== 'string') {
			throw Error('id must be type string');
		}
		const users = maybe.users ? maybe.users.map(User.fromCustomer) : [];

		return new Customer(
			maybe.id,
			maybe.name,
			Address.fromJSON(maybe.billing_address),
			Address.fromJSON(maybe.delivery_address),
			users,
			maybe.customer_number,
			maybe.supplier_number,
			maybe.invoice_email,
			maybe.reminder_email,
			maybe.other_emails,
			maybe.vat_number,
			maybe.term_of_payment

		);
	}

	public static toJSON(customer: Customer) {
		return {
			id: customer.id,
			name: customer.name,
			billing_address: Address.toJSON(customer.billingAdrress),
			delivery_address: Address.toJSON(customer.deliveryAddress),
			users: customer.users,
			customer_number: customer.customerNumber,
			supplier_number: customer.supplierNumber,
			invoice_email: customer.invoiceEmail,
			reminder_email: customer.reminderEmail,
			other_emails: customer.otherEmails,
			vat_number: customer.vatNumber,
			term_of_payment: customer.termOfPayment
			
		};
	}
}

export default Customer;
