import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Filter from '../components/Filter';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import NewLink from '../components/NewLink';

import StandardTextField from '../components/StandardTextField';
import UserList from '../components/UserList';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { userFields } from '../containers/UserContainer';
import { initUserFilters, UserFilterKeys } from '../containers/UserOverviewContainer';
import { checkIfUserStopWriting } from '../helpers/filters';
import { filterOriginOfEmployee, filterUserRole } from '../helpers/userHelper/generalUserHelper';
import { Routes } from '../router/Routes';
import User, { UserFilterInterface } from '../service/types/User';
import HomeButton from '../svgComponents/HomeButton';
import IQRLogo from '../svgComponents/IQRLogo';

interface Props {
	users: User[];
	filters: UserFilterInterface;
	control: Control<Record<string, any>>;
	onFilterChange: (key: UserFilterKeys, value: any) => void;
	onEditPress: (user: User) => void;
	onNewUserClick: () => void;
	clearAllFilters: () => void;
}

function UserOverviewScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const globalClasses = useGlobalStyles({ showFilters });
	const classes = useStyles({});
	const { t } = useTranslation();
	const filterListRef = React.useRef<any>(null);
	const searchRef = React.useRef<UserFilterInterface>({ ...initUserFilters });
	const onFilterPress = () => setShowFilters((prev) => !prev);

	return (
		<div className={globalClasses.container}>
			<IQRLogo className={globalClasses.logo} />
			<div className={globalClasses.homeButtonWrapper}>
				<div />
				<HomeButton />
			</div>
			<div className={classes.row}>
				<div className={classes.titleWrapper}>
					<span className={globalClasses.title}>{t('user_overview_screen_title')}</span>
				</div>
				<NewLink route={Routes.User} text={'user_overview_screen_add_new'} />
			</div>
			<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />
			<div
				className={globalClasses.filterWrapper}
				style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
				<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={userFields.firstName}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('user_overview_screen_first_name')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'firstName');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={userFields.lastName}
							render={({ onChange, value }) => (
								<StandardTextField
									label={t('user_overview_screen_last_name')}
									value={value}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'lastName');
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<KeyValueAutoComplete
							getOptionSelected={(option, value) => {
								if (value.key === '') return false;
								return t(option.key) === t(value.key);
							}}
							label={t('user_overview_screen_origin')}
							multiple={false}
							onChange={(e) => {
								if (e) {
									props.onFilterChange('originOfEmployee', e.value);
								} else {
									props.onFilterChange('originOfEmployee', '');
								}
							}}
							options={filterOriginOfEmployee}
							value={{
								key:
									props.filters.originOfEmployee === ''
										? ''
										: t(filterOriginOfEmployee[props.filters.originOfEmployee - 1].key),
								value: props.filters.originOfEmployee,
							}}
							withoutClose={false}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<KeyValueAutoComplete
							getOptionSelected={(option, value) => {
								if (value.key === '') return false;
								return t(option.key) === t(value.key);
							}}
							label={t('user_overview_screen_role')}
							multiple={false}
							onChange={(e) => {
								if (e) {
									props.onFilterChange('role', e.value);
								} else {
									props.onFilterChange('role', '');
								}
							}}
							options={filterUserRole}
							value={{
								key: props.filters.role === '' ? '' : t(filterUserRole[props.filters.role].key),
								value: props.filters.role,
							}}
							withoutClose={false}
						/>
					</Grid>
				</Grid>
			</div>
			<UserList
				users={props.users.filter((item) => !item.deleted)}
				onFilterChange={props.onFilterChange}
				filters={props.filters}
				onEditPress={props.onEditPress}
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleWrapper: {
		marginBottom: '12px',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapper: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

export default UserOverviewScreen;
