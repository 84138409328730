import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../consts/colors';

export type DateTypes = 'default' | 'range' | 'start' | 'end' | 'weekend';

interface Props {
	date?: number;
	type: DateTypes;
	disabled?: boolean;
	hasRoundWrapper: boolean;
	btnStyle?: string;
}

const CustomDate = ({ type, date, disabled, hasRoundWrapper, btnStyle }: Props) => {
	const classes = useStyles();

	const buttonClassname = React.useMemo(() => {
		if (type === 'start' || type === 'end') return classes.startEndDate;
		if (type === 'range') return classes.rangeDate;
		return classes.default;
	}, [classes.default, classes.rangeDate, classes.startEndDate, type]);

	const textClassName = React.useMemo(() => {
		if (type === 'weekend' || disabled) return classes.disabledText;
		return classes.darkText;
	}, [classes.darkText, classes.disabledText, disabled, type]);

	return (
		<div className={classes.btnWrapper}>
			{hasRoundWrapper && (
				<div className={type === 'start' ? classes.startDateWrapper : classes.endDateWrapper}></div>
			)}
			<Button
				disabled={disabled}
				classes={{ text: ` ${textClassName}`, root: classes.btn }}
				className={`${classes.day} ${buttonClassname} ${btnStyle}`}>
				{date}
			</Button>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	btn: {
		minWidth: '48px',
		'@media(max-width : 800px)': {
			minWidth: '32px',
		},
	},
	whiteText: {
		color: `${colors.white} !important`,
	},
	darkText: {
		color: `${colors.darkGray} !important`,
	},
	disabledText: {
		color: `${colors.lightGray} !important`,
	},
	day: {
		margin: '2px 0px',
		padding: '8px 0px',
		'@media(max-width : 800px)': {
			padding: '3px 0px',
		},
	},
	default: {
		backgroundColor: 'none',
	},
	startEndDate: {
		backgroundColor: colors.yellow,
		borderRadius: '50%',
	},
	btnWrapper: {
		position: 'relative',
	},
	startDateWrapper: {
		position: 'absolute',
		height: 'calc(100% - 4px)',
		top: '50%',
		transform: 'translateY(-50%)',
		width: '50%',
		right: 0,
		backgroundColor: colors.lightYellow,
	},
	endDateWrapper: {
		position: 'absolute',
		height: 'calc(100% - 4px)',
		top: '50%',
		transform: 'translateY(-50%)',
		width: '50%',
		left: 0,
		backgroundColor: colors.lightYellow,
	},
	rangeDate: {
		backgroundColor: colors.lightYellow,
	},
}));

export default CustomDate;
