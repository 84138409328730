import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { BACK_SPACE, LoginData } from '../consts/login';
import BackArrow from '../svgComponents/BackArrow';
import Button from './Button';
import NumericKeyboard from './NumericKeyboard';

interface Props {
	pin: string;
	dataForLogin: LoginData;
	onForgotPasswordPress: () => void;
	onOKButtonPress: (val: string) => void;
	onBackArrowPress: () => void;
}

function PinDialog({ pin, dataForLogin, onForgotPasswordPress, onOKButtonPress, onBackArrowPress }: Props) {
	const globalClasses = useGlobalStyles({});
	const classes = useStyles();
	const { t } = useTranslation();
	const [pins, setPins] = React.useState(pin);

	const onKeyPress = (val: string) => {
		if (val !== BACK_SPACE) {
			pins.length < 4 && setPins(pins + val);
		} else {
			setPins(pins.slice(0, -1));
		}
	};

	return (
		<div>
			<div className={classes.backButtonWrapper}>
				<BackArrow onBackArrowPress={onBackArrowPress} />
			</div>
			<Grid container spacing={4} className={classes.container}>
				<Grid item sm={3} />
				<Grid container item sm={6} alignContent={'center'} justify="center" direction="column">
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t('pin_dialog_title')}</span>
					</div>
					<div className={classes.dotWrapper}>
						{Array(pins.length)
							.fill(null)
							.map((_, index) => (
								<div
									key={`${index}`}
									className={classes.dot}
									style={{ marginRight: index === pins.length - 1 ? '0px' : '16px' }}
								/>
							))}
					</div>
					<NumericKeyboard onClick={onKeyPress} />
					<div className={classes.center}>
						<div className={classes.buttonWrapper}>
							<Button
								text={t('pin_dialog_button')}
								onClick={() => onOKButtonPress(pins)}
								textClassName={globalClasses.buttonText}
								buttonWrapperClassName={globalClasses.buttonWrapper}
							/>
						</div>
						<div className={classes.forgotPasswordWrapper} onClick={onForgotPasswordPress}>
							<span className={globalClasses.link}>{t(dataForLogin.forgotTitle)}</span>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	center: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	titleWrapper: {
		marginBottom: '48px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},

	forgotPasswordWrapper: {
		width: 'fit-content',
		marginLeft: 'auto',
		marginRight: 'auto',
		cursor: 'pointer',
	},
	backButtonWrapper: {
		position: 'absolute',
		left: '32px',
		top: '20px',
	},
	buttonWrapper: {
		marginTop: '44px',
		marginBottom: '24px',
	},
	dot: {
		height: '12px',
		width: '12px',
		borderRadius: '6px',
		backgroundColor: colors.gray,
	},
	dotWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '44px',
	},
	container: {
		width: '100vw',
		height: '100vh',
	},
}));

export default PinDialog;
