import { addExpenseFields } from '../../components/AddExpensesItem';
import service from '../../service/service';
import MaterialTool from '../../service/types/MaterialTool';
import Travel from '../../service/types/Travel';
import User from '../../service/types/User';
import { ExpensesKeys } from './generalExpensesHelper';
import moment from 'moment';
import Transport from '../../service/types/Transport';

export async function createExpensesData(
	key: ExpensesKeys,
	contractID: number,
	expense: MaterialTool | Travel | Transport
) {
	if (key === 'materials' && expense instanceof MaterialTool) {
		const id = await service.createMaterialExpenses(contractID, expense);
		if (id) {
			return MaterialTool.createMaterialToolWithID(id, expense);
		}
		return false;
	} else if (key === 'tools' && expense instanceof MaterialTool) {
		const id = await service.createToolExpenses(contractID, expense);
		if (id) {
			return MaterialTool.createMaterialToolWithID(id, expense);
		}
		return false;
	} else if (expense instanceof Travel) {
		const id = await service.createTravelExpenses(contractID, expense);
		if (id) {
			return Travel.createTravelWithID(id, expense);
		}
		return false;
	} else if (expense instanceof Transport) {
		const id = await service.createTransportExpenses(contractID, expense);
		if (id) {
			return Transport.createTransportWithID(id, expense);
		}
		return false;
	} else {
		return Promise.resolve(false);
	}
}

export function createExpense(key: ExpensesKeys, user: User, data: any) {
	if (key === 'materials') {
		return new MaterialTool(
			0,
			data[addExpenseFields.name],
			Number(data[addExpenseFields.quantity]),
			user!.id,
			data[addExpenseFields.qai].value.id,
			moment(),
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : false,
			data[addExpenseFields.price]
		);
	} else if (key === 'tools') {
		return new MaterialTool(
			0,
			data[addExpenseFields.name],
			Number(data[addExpenseFields.quantity]),
			user!.id,
			data[addExpenseFields.qai].value.id,
			moment(),
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : false,
			data[addExpenseFields.price]
		);
	} else if (key === 'travels') {
		return new Travel(
			0,
			data[addExpenseFields.travelTime],
			data[addExpenseFields.distance].value.id,
			user!.id,
			data[addExpenseFields.qai].value.id,
			moment(),
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : false,
			data[addExpenseFields.waitedTime],
			data[addExpenseFields.typeOfTransport].value
		);
	} else {
		return new Transport(
			0,
			data[addExpenseFields.type].value,
			user!.id,
			data[addExpenseFields.qai].value.id,
			moment(),
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : false
		);
	}
}
