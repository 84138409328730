import React, { useState } from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import ErrorIcon from '../svgComponents/ErrorIcon';

import { fonts } from '../consts/fonts';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import DropdownArrow from '../svgComponents/DropdownArrow';

const TextFieldWithCSS = withStyles({
	root: {
		'& .MuiAutocomplete-popupIndicatorOpen': {
			marginTop: '3px',
		},
		'& .MuiAutocomplete-popupIndicator': {
			marginRight: '5px',
			marginTop: '3px',
			transition: 'all 0.5s',
			backgroundColor: 'white',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'white',
		},
		'& .Mui-disabled': {
			color: colors.gray,
			opacity: '0.7',
		},
		'& .MuiAutocomplete-inputRoot': {
			paddingLeft: '12px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-input': {
			...globalInputStyle(),
			borderRadius: '5px',
			paddingLeft: '12px',
		},
		'& .MuiInput-input.Mui-disabled': {
			...globalInputStyle(),
			color: colors.lightGray,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
		'& .MuiInput-underline:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
		'& .MuiTouchRipple-root': {
			display: 'none',
		},
	},
})(TextField);

interface Props {
	disabled?: boolean;
	label: string;
	value: any[];
	options: any[];
	error?: boolean;
	multiple?: boolean;
	limitTags?: number;
	helperText?: string;
	placeholder?: string;
	disableCloseOnSelect?: boolean;
	onChange: (event: any) => void;
}

export default function Autocomplete({
	disabled = false,
	label,
	value,
	options,
	error,
	multiple = false,
	limitTags = 10,
	helperText,
	placeholder,
	disableCloseOnSelect,
	onChange,
}: Props) {
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles();
	const [focused, setFocused] = useState(false);

	const classes = useAutocompleteStyles({ focused, disabled });

	return (
		<div>
			<div className={classes.labelWrapper}>
				<span className={classes.label}>{label}</span>
			</div>
			<AutoComplete
				disabled={disabled}
				value={value}
				isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
				options={options}
				getOptionLabel={(option) => option.name || ''}
				limitTags={limitTags}
				multiple={multiple}
				classes={{
					inputRoot: classes.inputRoot,
					paper: classes.dropdownContainer,
					option: classes.optionClass,
					clearIndicator: classes.clearIndicator,
					listbox: classes.listBox,
				}}
				getLimitTagsText={(val) => <span className={globalClasses.smallText}>{`+${val} ${placeholder}`}</span>}
				disableCloseOnSelect={disableCloseOnSelect}
				noOptionsText={t('autocomplete_no_options')}
				loadingText={t('autocomplete_loading')}
				onChange={(event, value) => onChange(value)}
				renderOption={(props, option) => {
					return (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					);
				}}
				renderInput={(params) => (
					<div className={classes.inputRow}>
						<TextFieldWithCSS
							{...params}
							InputProps={{
								...params.InputProps,
								classes: {
									input: classes.input,
								},
								onFocus: () => setFocused(true),
								onBlur: () => setFocused(false),

								endAdornment: (
									<div className={classes.endAdormentWrapper}>{params.InputProps.endAdornment}</div>
								),
							}}
							label={undefined}
							error={error}
							helperText=""
							className={classes.inputWrapper}
						/>
					</div>
				)}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => {
						return (
							<Chip
								{...getTagProps({ index })}
								label={option.name}
								className={classes.chipHeight}
								classes={{ deleteIcon: classes.deleteIcon }}
							/>
						);
					})
				}
				popupIcon={
					<div className={classes.dropdownArrowWrapper}>
						<DropdownArrow />
					</div>
				}
			/>
			{error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={classes.error}>{helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	focused: boolean;
	disabled: boolean;
}

const useAutocompleteStyles = makeStyles(() => ({
	label: (p: StyleProps) => ({
		color: p.disabled ? colors.lightGray : colors.gray,
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
	}),
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre-line',
	},
	inputWrapper: (p: StyleProps) => ({
		width: '100%',
		borderRadius: '4px',
		backgroundColor: colors.white,
		border: `1px solid ${p.focused ? colors.darkGray : '#C0C0C0'}`,
	}),
	dropdownContainer: {
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	error: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.error,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	inputRow: { display: 'flex', alignItems: 'center' },
	chipHeight: {
		height: '26px',
		margin: '3px',
		backgroundColor: colors.someYellow,
	},
	input: {
		...globalInputStyle(),
		whiteSpace: 'pre',
		color: colors.darkGray,
		borderRadius: '5px',
		paddingLeft: '12px',
	},
	optionClass: {
		'&[aria-selected=true]': {
			backgroundColor: colors.white,
		},
		'&[aria-selected=true]:hover': {
			backgroundColor: 'rgba(0,0,0,0.04)',
		},
	},
	deleteIcon: {
		color: colors.darkGray,
	},
	inputRoot: {
		paddingRight: '30px',
		minHeight: '35px',
	},
	clearIndicator: {
		display: 'inline-flex',
	},
	listBox: {
		overflow: 'hidden',
		maxHeight: 'unset',
	},
	endAdormentWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	dropdownArrowWrapper: {
		height: 'fit-content',
		padding: '5px 5px',
		alignItems: 'center',
		display: 'flex',
	},
}));
