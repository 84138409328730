import moment, { Moment } from 'moment';
import { RecordTimeType } from './BreakTime';

class WorkingTime {
	id: number;
	reporter: number;
	qai: number;
	date: Moment;
	billed?: boolean | undefined;
	startTime: Moment;
	endTime: Moment;
	duration: number;
	type: RecordTimeType;
	profession: number;

	constructor(
		id: number,
		reporter: number,
		qai: number,
		date: Moment,
		startTime: Moment,
		endTime: Moment,
		duration: number,
		profession: number,
		billed?: boolean | undefined
	) {
		this.id = id;
		this.reporter = reporter;
		this.qai = qai;
		this.date = date;
		this.billed = billed;
		this.startTime = startTime;
		this.endTime = endTime;
		this.duration = duration;
		this.type = RecordTimeType.Working;
		this.profession = profession;
	}

	public static fromJSON(maybe: any): WorkingTime {
		if (!maybe) {
			throw Error('workingtime must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw Error('id must be number');
		}
		if (typeof maybe.reporter !== 'number') {
			throw Error('reporter must be number');
		}
		if (typeof maybe.qai !== 'number') {
			throw Error('qai must be number');
		}
		if (typeof maybe.duration !== 'number') {
			throw Error('duration must be number');
		}
		if (maybe.billed === undefined) {
			throw Error('billed must be exist');
		}
		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}
		const startTime = moment(maybe.start_time);
		if (!date.isValid()) {
			throw new Error('start_time is not valid date');
		}
		const endTime = moment(maybe.end_time);
		if (!date.isValid()) {
			throw new Error('end_time is not valid date');
		}

		return new WorkingTime(
			maybe.id,
			maybe.reporter,
			maybe.qai,
			date,
			startTime,
			endTime,
			maybe.duration < 0 ? maybe.duration + 60 * 60 * 24 : maybe.duration,
			maybe.profession,
			maybe.billed
		);
	}

	public static createWorkingTimeWithID(id: number, withoutIDWorkingTime: WorkingTime) {
		return new WorkingTime(
			id,
			withoutIDWorkingTime.reporter,
			withoutIDWorkingTime.qai,
			withoutIDWorkingTime.date,
			withoutIDWorkingTime.startTime,
			withoutIDWorkingTime.endTime,
			withoutIDWorkingTime.duration,
			withoutIDWorkingTime.profession,
			withoutIDWorkingTime.billed
		);
	}

	public static toJSON(workingTime: WorkingTime) {
		return {
			id: workingTime.id,
			reporter: workingTime.reporter,
			qai: workingTime.qai,
			date: workingTime.date.toISOString(),
			billed: workingTime.billed,
			start_time: workingTime.startTime.toISOString(),
			end_time: workingTime.endTime.toISOString(),
			duration: workingTime.duration,
			profession: workingTime.profession,
		};
	}
}

export default WorkingTime;
