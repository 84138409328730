import moment, { Moment } from 'moment';

export enum RecordTimeType {
	Working = 1,
	Break,
	Waiting,
}

class BreakTimeQA {
	id: number;
	reporter: number;
	qai: number;
	date: Moment;
	duration: number;
	billed: boolean;
	type: RecordTimeType;

	constructor(id: number, reporter: number, qai: number, date: Moment, duration: number, type: RecordTimeType) {
		this.id = id;
		this.reporter = reporter;
		this.qai = qai;
		this.date = date;
		this.duration = duration;
		this.type = type;
		this.billed = false;
	}

	public static fromJSON(maybe: any, type: RecordTimeType): BreakTimeQA {
		if (!maybe) {
			throw Error('workingtime must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw Error('id must be number');
		}
		if (typeof maybe.reporter !== 'number') {
			throw Error('reporter must be number');
		}
		if (typeof maybe.qai !== 'number') {
			throw Error('qai must be number');
		}
		if (typeof maybe.duration !== 'number') {
			throw Error('duration must be number');
		}

		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}

		return new BreakTimeQA(
			maybe.id,
			maybe.reporter,
			maybe.qai,
			date,
			maybe.duration < 0 ? maybe.duration + 60 * 60 * 24 : maybe.duration,
			type
		);
	}

	public static createWorkingTimeWithID(id: number, withoutIDWorkingTime: BreakTimeQA) {
		return new BreakTimeQA(
			id,
			withoutIDWorkingTime.reporter,
			withoutIDWorkingTime.qai,
			withoutIDWorkingTime.date,
			withoutIDWorkingTime.duration,
			withoutIDWorkingTime.type
		);
	}

	public static toJSON(workingTime: BreakTimeQA) {
		return {
			id: workingTime.id,
			reporter: workingTime.reporter,
			qaiID: workingTime.qai,
			date: workingTime.date.toISOString(),
			duration: workingTime.duration,
		};
	}
}

export default BreakTimeQA;

export const breakTimefromJSON = (maybe: any, type: RecordTimeType): BreakTimeQA => {
	if (!maybe) {
		throw Error('workingtime must exist');
	}
	if (typeof maybe.id !== 'number') {
		throw Error('id must be number');
	}
	if (typeof maybe.reporter !== 'number') {
		throw Error('reporter must be number');
	}
	if (typeof maybe.qaiID !== 'number') {
		throw Error('qai must be number');
	}
	if (typeof maybe.duration !== 'number') {
		throw Error('duration must be number');
	}

	const date = moment(maybe.date);
	if (!date.isValid()) {
		throw new Error('date is not valid date');
	}

	return new BreakTimeQA(
		maybe.id,
		maybe.reporter,
		maybe.qaiID,
		date,
		maybe.duration < 0 ? maybe.duration + 60 * 60 * 24 : maybe.duration,
		type
	);
};
