import moment, { Moment } from 'moment';

class MaterialTool {
	id: number;
	name: string;
	quantity: number;
	reporter: number;
	qai: number;
	date: Moment;
	billed: boolean;
	price: number;

	constructor(
		id: number,
		name: string,
		quantity: number,
		reporter: number,
		qai: number,
		date: Moment,
		billed: boolean,
		price: number
	) {
		this.id = id;
		this.name = name.trim();
		this.quantity = quantity;
		this.reporter = reporter;
		this.qai = qai;
		this.date = date;
		this.billed = billed;
		this.price = price;
	}

	public static fromJSON(maybe: any): MaterialTool {
		if (!maybe) {
			throw new Error('must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw new Error('id must be number');
		}

		if (typeof maybe.name !== 'string') {
			throw new Error('name must be string');
		}
		if (typeof maybe.quantity !== 'number') {
			throw new Error('quantity must be number');
		}
		if (typeof maybe.reporter !== 'number') {
			throw new Error('reporter must be number');
		}
		if (typeof maybe.qai !== 'number') {
			throw new Error('qai must be number');
		}
		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}
		return new MaterialTool(
			maybe.id,
			maybe.name,
			maybe.quantity,
			maybe.reporter,
			maybe.qai,
			date,
			maybe.billed,
			maybe.price
		);
	}

	public static createMaterialToolWithID(id: number, withoutIDMaterialTool: MaterialTool) {
		return new MaterialTool(
			id,
			withoutIDMaterialTool.name,
			withoutIDMaterialTool.quantity,
			withoutIDMaterialTool.reporter,
			withoutIDMaterialTool.qai,
			withoutIDMaterialTool.date,
			withoutIDMaterialTool.billed,
			withoutIDMaterialTool.price
		);
	}

	public static toJSON(material: MaterialTool) {
		return {
			id: material.id,
			name: material.name,
			quantity: material.quantity,
			reporter: material.reporter,
			qai: material.qai,
			date: material.date.toISOString(),
			billed: material.billed,
		};
	}
}

export default MaterialTool;
