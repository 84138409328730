import { AddExpenseItemCellType } from '../components/AddExpenseItemCell';
import { TableCellType } from '../components/table/StandardTableCell';
import { billedOptions } from '../helpers/expenses/generalExpensesHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import User from '../service/types/User';
import { rules, RulesInterface } from './rules';

export interface ExpenseTabelParamsInterface {
	titleType: TableCellType;
	title: string;
	width: string;
	placeholder: string;
	addType: AddExpenseItemCellType;
	inputType: 'number' | undefined;
	rightAlign: boolean;
	rules?: Partial<RulesInterface>;
	options?: KeyValue[];
	value?: string;
	showAsValue?: boolean;
}

export interface ExpenseTabelInterface {
	id?: ExpenseTabelParamsInterface;
	qai?: ExpenseTabelParamsInterface;
	billed?: ExpenseTabelParamsInterface;
	reporter?: ExpenseTabelParamsInterface;
	edit?: ExpenseTabelParamsInterface;
	date?: ExpenseTabelParamsInterface;
	date_of_invoice?: ExpenseTabelParamsInterface;
	from_date?: ExpenseTabelParamsInterface;
	to_date?: ExpenseTabelParamsInterface;
	name?: ExpenseTabelParamsInterface;
	quantity?: ExpenseTabelParamsInterface;
	price?: ExpenseTabelParamsInterface;
	travelTime?: ExpenseTabelParamsInterface;
	distance?: ExpenseTabelParamsInterface;
	waitedTime?: ExpenseTabelParamsInterface;
	typeOfTransport?: ExpenseTabelParamsInterface;
	type?: ExpenseTabelParamsInterface;
}

export const materialTabelParams = (
	users: KeyValue[],
	user: User | undefined,
	t: (message: string) => string
): ExpenseTabelInterface => ({
	name: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_material_table_name_title',
		width: '22.66vh',
		placeholder: 'expenses_screen_material_table_name_placeholder',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Standard,
	},
	date: {
		titleType: TableCellType.Title,
		title: 'invoice_screen_creation_date',
		width: '15.66vh',
		placeholder: 'invoice_screen_creation_date',
		addType: AddExpenseItemCellType.Date,
		inputType: undefined,
		rightAlign: true,
	},
	quantity: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_material_table_quantity_title',
		width: '80px',
		placeholder: 'expenses_screen_material_table_quantity_placeholder',
		inputType: 'number',
		rightAlign: true,
		addType: AddExpenseItemCellType.Standard,
		rules: {
			pattern: rules(t).numberPatternBelow999,
			required: rules(t).required,
		},
	},
	reporter: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_reporter_title',
		width: '20.438vh',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
		value: `${user?.firstName} ${user?.lastName}`,
	},
	qai: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_qai_title',
		width: '22.414vh',
		placeholder: 'expenses_screen_name',
		options: users,
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.KeyValue,
	},
	billed: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_status',
		width: '15.66vh',
		placeholder: 'expenses_screen_status',
		inputType: undefined,
		rightAlign: false,
		options: billedOptions,
		addType: AddExpenseItemCellType.KeyValue,
	},
	edit: {
		titleType: TableCellType.Title,
		title: '',
		width: '50px',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
	},
});
