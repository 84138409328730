import { Grid } from '@material-ui/core';
import React from 'react';
import { getKeysForPin } from '../consts/login';

import Key from './Key';

interface Props {
	onClick: (val: string) => void;
}

function NumericKeyboard(props: Props) {
	const keys = React.useMemo(() => getKeysForPin(), []);

	return (
		<Grid container spacing={4}>
			{keys.map((item, index) => (
				<Grid item sm={4} key={`KeyNumber${index}`}>
					<Key keyValue={item} onClick={props.onClick} />
				</Grid>
			))}
		</Grid>
	);
}

export default NumericKeyboard;
