import service from '../../service/service';
import { ExpensesKeys } from './generalExpensesHelper';

export async function deleteExpensesData(key: ExpensesKeys, contractID: number, expenseID: number) {
	if (key === 'materials') {
		return await service.deleteMaterialExpensesByID(contractID, expenseID);
	} else if (key === 'tools') {
		return await service.deleteToolExpensesByID(contractID, expenseID);
	} else if (key === 'travels') {
		return await service.deleteTravelExpensesByID(contractID, expenseID);
	} else {
		return await service.deleteTransportExpensesByID(contractID, expenseID);
	}
}
