import React from 'react';
import { Moment } from 'moment';

import { useGlobalStyles } from '../../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import { colors } from '../../consts/colors';
import { makeStyles } from '@material-ui/core';

interface Props {
	value: string | Moment;
	sticky?: boolean;
	markTextAsRed?: boolean;
}

function TextTypeCell(props: Props) {
	const globalClasses = useGlobalStyles({ description: props.sticky ? colors.white : colors.gray });

	const classes = useStyles();
	const { t } = useTranslation();
	//@ts-ignore
	const value = props.value && props.value.format ? props.value.format('DD.MM.YYYY') : props.value;
	return (
		<span className={`${globalClasses.description} ${props.markTextAsRed ? classes.redCell : ''}`}>
			{value && value.toString().split(':').length === 1 ? t(value.toString()) : value}
		</span>
	);
}

const useStyles = makeStyles(() => ({
	redCell: {
		color: colors.red,
	},
}));

export default TextTypeCell;
