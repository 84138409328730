import React from 'react';

import { Routes } from '../router/Routes';
import CustomTableBody from './CustomTableBody';
import { UserRole } from '../service/types/User';
import { contractListParams } from '../consts/contract';
import { useAuthStateContext } from '../context/auth/useAuth';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { ContractFilterKeys } from '../containers/ContractOverviewContainer';
import Contract, { ContractFilterInterface } from '../service/types/Contract';
import { dataSorter } from '../helpers/dataSorter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Props {
	allQAIs: KeyValue[];
	allTeamLeaders: KeyValue[];
	buttonText: string;
	contracts: Contract[];
	customers: KeyValue[];
	displaySort?: boolean;
	filters: ContractFilterInterface;
	redirectTo?: Routes;
	buttonAction: (contract: Contract) => void;
	onFilterChange: (key: ContractFilterKeys, value: any) => void;
}

export interface Sort {
	field?: string;
	direction?: string;
}

function ContractList({
	allQAIs,
	allTeamLeaders,
	buttonText,
	contracts,
	customers,
	displaySort,
	filters,
	redirectTo,
	buttonAction,
	onFilterChange,
}: Props) {
	const { t } = useTranslation();
	const { user } = useAuthStateContext();
	const [sort, setSort] = React.useState<Sort>({
		field: undefined,
		direction: undefined,
	});

	const transformedContract = React.useMemo(() => {
		return contracts.map((contract) => ({
			...contract,
			workLocation: contract.workLocations.map((loc) => loc.name).join(','),
			billed_until: contract.billed_until ? moment(contract.billed_until).format('DD.MM.YYYY') : '-',
			next_billing: contract.next_billing ? moment(contract.next_billing).format('DD.MM.YYYY') : '-',
			shiftTimes: contract.shiftTimes.map((item) => t(`${item.name}`)),
			customer: customers.find((customer) => customer.value.id === contract.customer)?.key,
			typesOfInspection: contract.typesOfInspection.reduce((prev, curr, index, array) => {
				if (index + 1 < array.length) {
					return prev + curr + ', ';
				} else {
					return prev + curr;
				}
			}, ''),
			errorTypes: contract.errorTypes.reduce((prev, curr, index, array) => {
				if (index + 1 < array.length) {
					return prev + curr + ', ';
				} else {
					return prev + curr;
				}
			}, ''),
			assignedTeamLeader: allTeamLeaders.find((teamleader) => teamleader.value.id === contract.assignedTeamLeader)
				?.key,
			assignedQAIs: contract.assignedQAIs.reduce((prev, curr, index, array) => {
				if (index + 1 < array.length) {
					return prev + allQAIs.find((qai) => qai.value.id === curr)?.key + ', ';
				} else {
					return prev + allQAIs.find((qai) => qai.value.id === curr)?.key;
				}
			}, ''),
		}));
	}, [contracts, customers, allTeamLeaders, t, allQAIs]);

	const transformedListParams = React.useMemo(() => {
		if (user) {
			const tempParams = { ...contractListParams };
			if (user?.role !== UserRole.QualityAssuranceInspector && user?.role !== UserRole.Customer)
				return tempParams;
			else {
				delete tempParams.customer;
				delete tempParams.assignedQAIs;
				delete tempParams.assignedTeamLeader;
				return tempParams;
			}
		}
	}, [user]);

	const sortedData = React.useMemo(() => {
		if (sort.field === undefined || !displaySort) return transformedContract;
		return dataSorter({ data: transformedContract, sort });
	}, [displaySort, sort, transformedContract]);

	const markTextAsRedFn = (key: string, value: any) => {
		if (key === 'next_billing') {
			const today = moment();
			const valMoment = moment(value, 'DD-MM-YYYY', false);

			if (valMoment.isValid() && valMoment.isBefore(today)) {
				return true;
			}
		}

		return false;
	};

	return (
		<CustomTableBody
			displaySort={displaySort}
			buttonProps={{
				contractNumber: {
					text: buttonText,
					onClick: (index) => buttonAction(contracts[index]),
				},
			}}
			//@ts-ignore
			data={sortedData}
			dataTablePramas={transformedListParams}
			redirectTo={redirectTo}
			sort={sort}
			setSort={setSort}
			tableKey={'Contract'}
			markTextAsRedFn={markTextAsRedFn}
		/>
	);
}

export default ContractList;
