import * as React from 'react';

function CheckboxFilled(props: any) {
	return (
		<svg width={16} height={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<path d="M4.5 7.5L7 10l5-5" />
				<path d="M14.5 15.5h-13a1 1 0 01-1-1v-13a1 1 0 011-1h13a1 1 0 011 1v13a1 1 0 01-1 1z" />
			</g>
		</svg>
	);
}

export default CheckboxFilled;
