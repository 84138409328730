import React from 'react';

export default function Home() {
	return (
		<svg width={38} height={31} viewBox="0 0 38 31">
			<g fill="none" fillRule="evenodd">
				<path d="M-11-6h60v44h-60z" />
				<g stroke="#111" strokeWidth={2}>
					<path d="M1 14L19 2l18 12" />
					<path fill="#FFF7CC" d="M16 30v-8h7v8" />
					<path strokeLinecap="square" d="M6 16v14h26V16" />
				</g>
			</g>
		</svg>
	);
}
