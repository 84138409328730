import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../consts/colors';
import SearchIcon from '../svgComponents/SearchIcon';
import { globalInputStyle } from '../consts/globalStyles';
import { TextFieldWithCSS } from './StandardAutoComplete';

interface Props {
	onChange: (event: any) => void;
	placeholder: string;
}

function SearchInput(props: Props) {
	const [focused, setFocused] = React.useState(false);
	const classes = useStyles({
		focused: focused,
	});

	return (
		<TextFieldWithCSS
			placeholder={props.placeholder}
			label={undefined}
			onChange={(event) => props.onChange(event.target.value)}
			className={classes.inputWrapper}
			InputProps={{
				onFocus: () => setFocused(true),
				onBlur: () => setFocused(false),
				startAdornment: <SearchIcon className={classes.searchIcon} />,
				classes: {
					input: classes.input,
					underline: classes.underlineField,
				},
			}}
		/>
	);
}

interface StyleProps {
	focused: boolean;
}

const useStyles = makeStyles(() => ({
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: (p: StyleProps) => ({
		display: 'flex',
		flex: 1,
		borderRadius: '16px',
		backgroundColor: colors.white,
		marginBottom: '10px',
		border: `1px solid ${p.focused ? colors.darkGray : '#C0C0C0'}`,
	}),
	input: {
		...globalInputStyle(),
		whiteSpace: 'pre',
		color: colors.darkGray,
		borderRadius: '5px',
		paddingLeft: '12px',
	},
	searchIcon: {
		marginLeft: '18px',
	},
}));

export default SearchInput;
