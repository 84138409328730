import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuthActionContext } from '../context/auth/useAuth';
import { onAuthTokenError } from '../helpers/authHelper';
import ChangePasswordScreen from '../screens/ChangePasswordScreen';
import service from '../service/service';

export const changePasswordFields = {
	oldPassword: 'oldPassword',
	newPassword: 'newPassword',
	repeatPassword: 'repeatPassword',
};

function ChangePasswordContainer() {
	const { control, handleSubmit, errors, getValues } = useForm();
	const { setUser } = useAuthActionContext();

	const onChangePasswordPress = React.useMemo(
		() =>
			handleSubmit((data) => {
				service
					.changePassword(data[changePasswordFields.oldPassword], data[changePasswordFields.newPassword])
					.then((res) => {
						if (res) {
							service
								.logout()
								.then((res) => {
									if (res) {
										onAuthTokenError(setUser);
									}
								})
								.catch(() => {});
						}
					})
					.catch(() => {});
			}),
		[handleSubmit, setUser]
	);

	return (
		<ChangePasswordScreen
			control={control}
			errors={errors}
			getValues={getValues}
			onChangePasswordPRess={onChangePasswordPress}
		/>
	);
}

export default ChangePasswordContainer;
