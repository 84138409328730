import { Validate, ValidationRule, ValidationValueMessage } from 'react-hook-form';
import { ForgotPasswordState } from '../containers/ForgotPasswordContainer';
import { LoginType } from '../containers/LoginContainer';
import { ResetPasswordParams } from '../helpers/userHelper/generalUserHelper';
import { Routes } from '../router/Routes';
import service from '../service/service';
import { UserRole } from '../service/types/User';
import { rules } from './rules';

export const BACK_SPACE = 'back';
export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 50;
export const QAI_PASSWORD_LENGTH = 4;

export interface LoginResponse {
	at: string;
	acceptedPp: boolean;
}

export interface RulesIntreface {
	required: string | boolean | ValidationValueMessage<boolean>;
	min: ValidationRule<React.ReactText>;
	max: ValidationRule<React.ReactText>;
	maxLength: ValidationRule<React.ReactText>;
	minLength: ValidationRule<React.ReactText>;
	pattern: ValidationRule<RegExp>;
	validate: Validate | Record<string, Validate>;
}

export interface LoginData {
	login: (email: string, password: string) => Promise<LoginResponse | undefined>;
	forgotTitle: string;
	label: string;
	disabled: boolean;
	rules: (
		t: (value: string) => string
	) => { email: Partial<RulesIntreface> | undefined; password: Partial<RulesIntreface> | undefined };
}

const standardLogin: LoginData = {
	login: (email: string, password: string) => service.login({ email: email, password: password }),
	forgotTitle: 'login_screen_forgot_password',
	label: 'login_screen_password_placeholder',
	disabled: false,
	rules: (t: (value: string) => string) => ({
		email: {
			required: rules(t).required,
			pattern: rules(t).emailPattern,
		},
		password: {
			required: rules(t).required,
			minLength: rules(t).minLength(MIN_PASSWORD_LENGTH),
			maxLength: rules(t).maxLength(MAX_PASSWORD_LENGTH),
		},
	}),
};

const qaiLogin: LoginData = {
	login: (username: string, password: string) => service.loginQAI({ username: username, password: password }),
	forgotTitle: 'login_screen_forgot_pin',
	label: 'login_screen_pin_placeholder',
	disabled: true,
	rules: (t: (value: string) => string) => ({
		email: {
			required: rules(t).required,
		},
		password: {
			required: rules(t).required,
			minLength: rules(t).minLength(QAI_PASSWORD_LENGTH),
			maxLength: rules(t).maxLength(QAI_PASSWORD_LENGTH),
		},
	}),
};

export function getDataForLogin(type: LoginType): LoginData {
	if (type === LoginType.QALogin) {
		return qaiLogin;
	} else {
		return standardLogin;
	}
}

export function getKeysForPin() {
	let keys: any[] = [];
	for (let i = 0; i < 10; ++i) keys[i] = i;
	let tmp,
		current,
		top = keys.length;
	if (top)
		while (--top) {
			current = Math.floor(Math.random() * (top + 1));
			tmp = keys[current];
			keys[current] = keys[top];
			keys[top] = tmp;
		}
	keys = keys.map((key) => key.toString());
	keys.splice(9, 0, '');
	keys.splice(keys.length, 0, BACK_SPACE);

	return keys;
}

interface MailSentInterface {
	sentDescription: string;
	sentTitle: string;
	link: string;
	linkTitle: string;
	route: Routes;
}

interface MailNotSentInterface {
	title: string;
	description: string;
	rules: (
		t: (value: string) => string
	) => {
		email: Partial<RulesIntreface>;
	};
}

export interface ForgotPasswordData extends MailSentInterface, MailNotSentInterface {}

const qaSentMail: MailSentInterface = {
	sentTitle: 'mail_sent_screen_title',
	sentDescription: 'mail_sent_screen_description',
	link: 'mail_sent_screen_link',
	linkTitle: 'mail_sent_screen_link_title',
	route: Routes.ContactUs,
};

const qaResentMail: MailSentInterface = {
	sentTitle: 'mail_resent_screen_qai_title',
	sentDescription: 'mail_resent_screen_qai_description',
	link: 'mail_resent_screen_qai_link',
	linkTitle: 'mail_resent_screen_qai_link_title',
	route: Routes.ContactUs,
};

const qaNotSentMail: MailNotSentInterface = {
	title: 'forgot_password_screen_qai_title',
	description: 'forgot_password_screen_qai_description',
	rules: (t: (value: string) => string) => ({
		email: { required: rules(t).required },
	}),
};

const standardSentMail: MailSentInterface = {
	sentTitle: 'mail_sent_screen_title',
	sentDescription: 'mail_sent_screen_description',
	link: 'mail_sent_screen_link',
	linkTitle: 'mail_sent_screen_link_title',
	route: Routes.ContactUs,
};

const standardResentMail: MailSentInterface = {
	sentTitle: 'mail_resent_screen_title',
	sentDescription: 'mail_resent_screen_description',
	link: 'mail_resent_screen_link',
	linkTitle: 'mail_resent_screen_link_title',
	route: Routes.ContactUs,
};

const standardNotSentMail: MailNotSentInterface = {
	title: 'forgot_password_screen_title',
	description: 'forgot_password_screen_description',
	rules: (t: (value: string) => string) => ({
		email: { required: rules(t).required, pattern: rules(t).emailPattern },
	}),
};

function qaForgotPassword(state: ForgotPasswordState): ForgotPasswordData {
	if (state === ForgotPasswordState.EmailSent) {
		return { ...qaSentMail, ...qaNotSentMail };
	}
	return { ...qaResentMail, ...qaNotSentMail };
}

function standardForgotPassword(state: ForgotPasswordState): ForgotPasswordData {
	if (state === ForgotPasswordState.EmailSent) {
		return { ...standardNotSentMail, ...standardSentMail };
	}
	return { ...standardNotSentMail, ...standardResentMail };
}

export function getDataForForgotPassword(type: LoginType, state: ForgotPasswordState): ForgotPasswordData {
	if (type === LoginType.QALogin) {
		return qaForgotPassword(state);
	} else {
		return standardForgotPassword(state);
	}
}

export interface PasswordResetInterface {
	resetPassword: (params: ResetPasswordParams) => Promise<boolean>;
	redirectRoute: Routes;
	rules: (
		t: (value: string) => string
	) => {
		password: Partial<RulesIntreface>;
	};
}

export const standardPasswordReset: PasswordResetInterface = {
	resetPassword: (params: ResetPasswordParams) => service.resetPassword(params),
	redirectRoute: Routes.Login,
	rules: (t: (value: string) => string) => ({
		password: {
			required: rules(t).required,
			minLength: rules(t).minLength(MIN_PASSWORD_LENGTH),
			maxLength: rules(t).maxLength(MAX_PASSWORD_LENGTH),
		},
	}),
};

export const qaiPasswordReset: PasswordResetInterface = {
	resetPassword: (params: ResetPasswordParams) => service.resetPasswordQAI(params),
	redirectRoute: Routes.QAILogin,
	rules: (t: (value: string) => string) => ({
		password: {
			required: rules(t).required,
			pattern: rules(t).numberPattern,
			minLength: rules(t).minLength(QAI_PASSWORD_LENGTH),
			maxLength: rules(t).maxLength(QAI_PASSWORD_LENGTH),
		},
	}),
};

export function getDataForPasswordReset(qai: string | null): PasswordResetInterface {
	if (qai === 'true') {
		return qaiPasswordReset;
	}
	return standardPasswordReset;
}

export function getLoginRedirectRoute(role: UserRole | undefined): Routes {
	if (role && role === UserRole.QualityAssuranceInspector) {
		return Routes.QAILogin;
	} else {
		return Routes.Login;
	}
}
