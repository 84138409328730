import { AddExpenseItemCellType } from '../components/AddExpenseItemCell';
import { TableCellType } from '../components/table/StandardTableCell';
import { billedOptions, TransportOptions } from '../helpers/expenses/generalExpensesHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import User from '../service/types/User';
import { ExpenseTabelInterface } from './material';

export const transportTabelParams = (users: KeyValue[], user: User | undefined): ExpenseTabelInterface => ({
	type: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_transport_table_name_title',
		width: '22.66vh',
		placeholder: 'expenses_screen_transport_table_name_placeholder',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.KeyValue,
		options: TransportOptions,
	},
	date: {
		titleType: TableCellType.Title,
		title: 'invoice_screen_creation_date',
		width: '15.66vh',
		placeholder: 'invoice_screen_creation_date',
		addType: AddExpenseItemCellType.Date,
		inputType: undefined,
		rightAlign: true,
	},
	reporter: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_reporter_title',
		width: '20.438vh',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
		value: `${user?.firstName} ${user?.lastName}`,
	},
	qai: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_qai_title',
		width: '22.414vh',
		placeholder: 'expenses_screen_name',
		options: users,
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.KeyValue,
	},
	billed: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_status',
		width: '15.66vh',
		placeholder: 'expenses_screen_status',
		inputType: undefined,
		rightAlign: false,
		options: billedOptions,
		addType: AddExpenseItemCellType.KeyValue,
	},
	edit: {
		titleType: TableCellType.Title,
		title: '',
		width: '50px',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
	},
});
