import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import Arrow from '../assets/images/svgs/Arrow';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';

interface Props {
	route: string;
	onBackArrowPress?: () => void;
	edit?: boolean;
}

function GoBack({ edit, route, onBackArrowPress }: Props) {
	const history = useHistory();
	const classes = useStyles({});
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const { t } = useTranslation();

	const onBackPress =
		//@ts-ignore
		(event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (onBackArrowPress) onBackArrowPress();
			else if (edit) {
				event.preventDefault();
				dialogRef.current?.setDialogState(true);
			}
		};

	const onAcceptDialog = () => history.goBack();
	const onCancelDialog = () => dialogRef.current?.setDialogState(false);

	return (
		<div>
			<Link className={classes.container} to={route} onClick={(event) => onBackPress(event)}>
				<Arrow />
			</Link>

			<StandardDialog
				onAccept={onAcceptDialog}
				onCancle={onCancelDialog}
				acceptText={t('warning_data_dialog_accept_button')}
				title={t('warning_data_dialog_title')}
				description={t('warning_data_dialog_description')}
				cancleText={t('warning_data_dialog_cancel_button')}
				ref={dialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	container: {
		cursor: 'pointer',
	},
}));

export default GoBack;
