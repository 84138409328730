import React from 'react';

import { standardCaseInputListParams } from '../consts/standardCaseInput';
import { StandardCaseFilterKeys } from '../containers/StandardCaseOverviewContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import {
	ApprovedStatus,
	DEFAULT_NO_VALUE,
	filterApprovedStatusKeyValuePairs,
} from '../helpers/standardInputHelper/generalStandardInputHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import service from '../service/service';
import StandardCaseInput, { StandardCaseFilterInterface } from '../service/types/StandardCase';
import { UserRole } from '../service/types/User';
import CameraDialog, { CameraDialogInterface } from './CameraDialog';
import { Sort } from './ContractList';
import CustomTableBody from './CustomTableBody';
import { TableCellType } from './table/StandardTableCell';
import { dataSorter } from '../helpers/dataSorter';

interface Props {
	displaySort?: boolean;
	standardCaseInputs: StandardCaseInput[];
	allAssignedQAIs: KeyValue[];
	filters: StandardCaseFilterInterface;
	photo: boolean;
	typesOfInspections: string[];
	automatedUpdates: boolean;
	shiftTimes: ShiftInterface[];
	dashboard?: boolean;
	maxHeight?: string;
	onFilterChange: (key: StandardCaseFilterKeys, value: any) => void;
	onViewPress: (standardInputCase: StandardCaseInput) => void;
	onApprovePress: (standardInputCase: StandardCaseInput) => void;
	sort?: Sort;
	setSort?: React.Dispatch<React.SetStateAction<Sort>>;
}
function StandardCaseInputList({
	standardCaseInputs,
	allAssignedQAIs,
	filters,
	photo,
	typesOfInspections,
	automatedUpdates,
	shiftTimes,
	dashboard,
	maxHeight,
	displaySort,
	onFilterChange,
	onViewPress,
	onApprovePress,
}: Props) {
	const { user } = useAuthStateContext();
	const cameraDialogRef = React.useRef<CameraDialogInterface | undefined>();
	const [sort, setSort] = React.useState<Sort>({
		field: undefined,
		direction: undefined,
	});
	const [partNumber, setPartNumber] = React.useState('');
	const transformedStandardCaseInputs = React.useMemo(() => {
		return standardCaseInputs.map((standardCaseInput) => {
			return {
				...standardCaseInput,
				errorTypes: standardCaseInput.errorTypes.reduce((prev, curr) => {
					return `${prev} ${curr.amount}x ${curr.type};`;
				}, ''),
				qai: allAssignedQAIs.find((qai) => qai.value.id === standardCaseInput.qai)?.key,
				approved: standardCaseInput.approved ? ApprovedStatus.Approved : ApprovedStatus.NotApproved,
				shift: shiftTimes.find((shift) => shift.id === standardCaseInput.shift)?.name,
			};
		});
	}, [standardCaseInputs, allAssignedQAIs, shiftTimes]);

	const onPreviewImage = React.useCallback((standardInputCase: StandardCaseInput) => {
		if (standardInputCase.photo)
			service
				.getPhoto(standardInputCase.photo.name)
				.then((res) => {
					if (res) {
						cameraDialogRef.current?.setDialogState(true, true, { name: '', data: res });
						setPartNumber(standardInputCase.partNumber);
					}
				})
				.catch(() => {});
	}, []);

	const transformedListParams = React.useMemo(() => {
		if (user) {
			const tempParams = { ...standardCaseInputListParams };
			if (!photo) delete tempParams.photo;
			if (automatedUpdates) delete tempParams.approved;
			if (user.role === UserRole.QualityAssuranceInspector) {
				delete tempParams.qai;
				delete tempParams.shift;
				delete tempParams.photo;
				delete tempParams.approved;
			}
			if (dashboard) {
				delete tempParams.qai;
				delete tempParams.approved;
				delete tempParams.checkNumber;
				delete tempParams.comment;
				delete tempParams.photo;
				tempParams.partNumber = { ...tempParams.partNumber, type: TableCellType.Text, width: '15.56vw' };
			}
			return tempParams;
		}
	}, [photo, automatedUpdates, user, dashboard]);

	const filtersOptions: Partial<StandardCaseFilterInterface> = React.useMemo(
		() => ({
			typeOfInspection: typesOfInspections.map((item) => ({ key: item, value: item })),
			approved: filterApprovedStatusKeyValuePairs,
			shift: [
				DEFAULT_NO_VALUE,
				...shiftTimes.map((shift) => ({
					key: shift.name,
					value: shift.id,
				})),
			],
		}),
		[typesOfInspections, shiftTimes]
	);

	const sortedData = React.useMemo(() => {
		if (sort.field === undefined || !displaySort) return transformedStandardCaseInputs;
		return dataSorter({ data: transformedStandardCaseInputs, sort });
	}, [displaySort, sort, transformedStandardCaseInputs]);

	return (
		<div>
			<CustomTableBody
				displaySort={displaySort}
				margin={'16px 0px'}
				data={sortedData}
				dataTablePramas={transformedListParams}
				onFilterChange={onFilterChange}
				filters={filters}
				filtersOptions={filtersOptions}
				tableKey={'StandardInputList'}
				maxHeight={maxHeight}
				buttonProps={{
					approved: {
						text: 'standard_overview_screen_contract_approve',
						onClick: (index) => onApprovePress(standardCaseInputs[index]),
					},
					photo: {
						text: 'standard_case_overview_screen_photo_button',
						onClick: (index) => onPreviewImage(standardCaseInputs[index]),
					},
					partNumber: {
						text: 'standard_case_overview_screen_part_number_button',
						onClick: (index) => onViewPress(standardCaseInputs[index]),
					},
				}}
				sort={sort}
				setSort={setSort}
			/>
			<CameraDialog ref={cameraDialogRef} partNumber={partNumber} />
		</div>
	);
}

export default StandardCaseInputList;
