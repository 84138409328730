import moment, { Moment } from 'moment';
import { TypeOfTravel } from '../../helpers/expenses/generalExpensesHelper';

class Travel {
	id: number;
	distance: string;
	travelTime: string;
	reporter: number;
	qai: number;
	date: Moment;
	billed: boolean;
	waitedTime: string;
	typeOfTransport: TypeOfTravel;

	constructor(
		id: number,
		travelTime: string,
		distance: string,
		reporter: number,
		qai: number,
		date: Moment,
		billed: boolean,
		waitedTime: string,
		typeOfTransport: TypeOfTravel
	) {
		this.id = id;
		this.distance = distance.trim();
		this.travelTime = travelTime.trim();
		this.reporter = reporter;
		this.qai = qai;
		this.date = date;
		this.billed = billed;
		this.waitedTime = waitedTime;
		this.typeOfTransport = typeOfTransport;
	}

	public static fromJSON(maybe: any): Travel {
		if (!maybe) {
			throw new Error('must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw new Error('id must be number');
		}

		if (typeof maybe.distance !== 'string') {
			throw new Error('distance must be string');
		}
		if (typeof maybe.travel_time !== 'string') {
			throw new Error('travel_time must be string');
		}
		if (typeof maybe.reporter !== 'number') {
			throw new Error('reporter must be number');
		}
		if (typeof maybe.qai !== 'number') {
			throw new Error('qai must be number');
		}
		if (typeof maybe.type_of_transport !== 'number') {
			throw new Error('type_of_transport must be number');
		}
		if (typeof maybe.waited_time !== 'string') {
			throw new Error('waited_time must be string');
		}
		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}

		return new Travel(
			maybe.id,
			maybe.travel_time,
			maybe.distance,
			maybe.reporter,
			maybe.qai,
			date,
			maybe.billed,
			maybe.waited_time,
			maybe.type_of_transport
		);
	}

	public static createTravelWithID(id: number, withoutIDTravel: Travel) {
		return new Travel(
			id,
			withoutIDTravel.travelTime,
			withoutIDTravel.distance,
			withoutIDTravel.reporter,
			withoutIDTravel.qai,
			withoutIDTravel.date,
			withoutIDTravel.billed,
			withoutIDTravel.waitedTime,
			withoutIDTravel.typeOfTransport
		);
	}

	public static toJSON(material: Travel) {
		return {
			id: material.id,
			distance: material.distance,
			travel_time: material.travelTime,
			reporter: material.reporter,
			qai: material.qai,
			date: material.date.toISOString(),
			billed: material.billed,
			waited_time: material.waitedTime,
			type_of_transport: material.typeOfTransport,
		};
	}
}

export default Travel;
