import React from 'react';
import { ErrorContextActions } from './ErrorContext';

import { Action, SET_ERROR, ErrorState } from './errorReducer';

export const createErrorState = (state: ErrorState): ErrorState => ({
	...state,
});

export const createActionErrorContext = (dispatch: React.Dispatch<Action>): ErrorContextActions => ({
	setError: (error: string) => dispatch({ type: SET_ERROR, error }),
});
