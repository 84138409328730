import { Moment } from 'moment';
import { colors } from '../../consts/colors';
import RhenusCase from '../../service/types/RhenusCase';
import StandardCaseInput from '../../service/types/StandardCase';
import { PartStatusType } from '../rhenusInputHelper/generalRhenusHelper';

export interface DashboardFilterInterface {
	partNumber: any;
	typeOfInspection: any;
	shift: any;
	fromDate: Moment | null;
	toDate: Moment | null;
}

export const initDashboardFilters: DashboardFilterInterface = {
	partNumber: '',
	typeOfInspection: '',
	shift: '',
	fromDate: null,
	toDate: null,
};

const data = Object.freeze({ ...initDashboardFilters });
export type DashboardCaseFilterKeys = keyof typeof data;

export const dashboardInputFields = {
	partNumber: 'partNumber',
};

interface Chart {
	partsIO: ChartData;
	partsNIO: ChartData;
	partsReworked: ChartData;
}

interface ChartData {
	name: string;
	value: number;
}

export const chartColors = [colors.someDarkYellow, colors.partsNIOChart, colors.partReworkedChart];

export function getTotalPartsTested(errors: StandardCaseInput[] | RhenusCase[]) {
	if (errors.length !== 0) {
		if (errors[0] instanceof StandardCaseInput) {
			return (errors as StandardCaseInput[]).reduce((prev, curr) => prev + curr.totalPartsTested, 0);
		} else {
			return errors.length;
		}
	}
	return 0;
}

export function getChartData(errors: StandardCaseInput[] | RhenusCase[]): ChartData[] {
	if (errors.length !== 0) {
		if (errors[0] instanceof StandardCaseInput) {
			return Object.values(
				(errors as StandardCaseInput[]).reduce(
					(prev, curr) => {
						return {
							partsIO: { ...prev.partsIO, value: prev.partsIO.value + curr.partsIO },
							partsNIO: { ...prev.partsNIO, value: prev.partsNIO.value + curr.partsNIO },
							partsReworked: {
								...prev.partsReworked,
								value: prev.partsReworked.value + curr.partsReworked,
							},
						};
					},
					{
						partsIO: {
							name: 'Parts IO',
							value: 0,
						},
						partsNIO: { name: 'Parts NIO', value: 0 },
						partsReworked: { name: 'Parts Reworked', value: 0 },
					} as Chart
				)
			);
		} else {
			return Object.values(
				(errors as RhenusCase[]).reduce(
					(prev, curr) => {
						return {
							partsIO: {
								...prev.partsIO,
								value: prev.partsIO.value + (curr.partStatus === PartStatusType.PartIO ? 1 : 0),
							},
							partsNIO: {
								...prev.partsNIO,
								value: prev.partsNIO.value + (curr.partStatus === PartStatusType.PartNIO ? 1 : 0),
							},
							partsReworked: {
								...prev.partsReworked,
								value:
									prev.partsReworked.value + (curr.partStatus === PartStatusType.PartRework ? 1 : 0),
							},
						};
					},
					{
						partsIO: {
							name: 'Parts IO',
							value: 0,
						},
						partsNIO: { name: 'Parts NIO', value: 0 },
						partsReworked: { name: 'Parts Reworked', value: 0 },
					} as Chart
				)
			);
		}
	}

	return [] as ChartData[];
}
