import React from 'react';
import { NotificationContextActions } from './NotificationContext';

import {
	NotificationState,
	Action,
	HIDE_NOTIFICATION,
	SET_NOTIFICATION,
	NotificationInterface,
} from './notificationReducer';

export const createNotificationState = (state: NotificationState): NotificationState => ({
	...state,
});

export const createActionNotificationContext = (dispatch: React.Dispatch<Action>): NotificationContextActions => ({
	setNotification: (notification: NotificationInterface) => dispatch({ type: SET_NOTIFICATION, notification }),
	hideNotification: () => dispatch({ type: HIDE_NOTIFICATION }),
});
