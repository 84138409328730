import React from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import moment from 'moment';

import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import StandardCaseInput, { ErrorInputType } from '../service/types/StandardCase';
import { rules } from '../consts/rules';
import StandardTextField from '../components/StandardTextField';
import { standardCaseInputFields } from '../containers/StandardCaseInputContainer';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import ErrorInputRow from '../components/ErrorInputRow';
import TableAutoCompleteInput from '../components/TableAutoCompleteInput';
import { fonts } from '../consts/fonts';
import { colors } from '../consts/colors';
import ErrorIcon from '../svgComponents/ErrorIcon';
import StandardDatePicker from '../components/StandardDatePicker';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import { ErrorType, FileInterface, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import CameraDialog, { CameraDialogInterface } from '../components/CameraDialog';
import Contract from '../service/types/Contract';
import ApproveItem from '../components/ApproveItem';
import PhotoIcon from '../svgComponents/PhotoIcon';
import BarcodeScanner, { BarcodeScannerInterface } from '../components/BarcodeScanner';
import StandardAutoComplete from '../components/StandardAutoComplete';

interface Props {
	commentRef: React.MutableRefObject<any>;
	isUserEditing: boolean;
	standardCaseInput: StandardCaseInput | undefined;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	addControl: Control<Record<string, any>>;
	addErrors: Record<string, any>;
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	errorParts: ErrorInputType[];
	contract: Contract | undefined;
	totalPartsTested: number;
	partNumberOptions: string[];
	partsIO: number;
	partsNIO: number;
	contractShifts: ShiftInterface[];
	onSubmitPress: () => void;
	onSubmitStartNewPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDialogPress: () => void;
	onDeleteErrorInputDialogPress: () => void;
	onRemoveErrorPart: (index: number) => void;
	addNewErrorPart: () => void;
	watch: (field: string) => any;
	disablePaste: (e: any) => void;
}

export const CHECK_NUMBER_WIDTH = '8.33vw';
export const ERROR_TYPE_WIDTH = '20.77vw';
export const TABLE_PADDING = '12px 16px';

function StandardCaseInputScreen(props: Props) {
	const photo = props.watch(standardCaseInputFields.photo);
	const classes = useStyles({ disabled: !props.isUserEditing, photo: photo });
	const globalClasses = useGlobalStyles();

	const cameraDialogRef = React.useRef<CameraDialogInterface | undefined>();
	const barcodeDialogRef = React.useRef<BarcodeScannerInterface | undefined>();
	const { t } = useTranslation();

	const openCameraInCaptureMode = () => {
		cameraDialogRef.current?.setDialogState(true, false, undefined);
	};

	const openCameraInPreviewMode = () => {
		if (photo) cameraDialogRef.current?.setDialogState(true, true, photo);
	};

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow edit={props.isUserEditing} />
				<HomeButton edit={props.isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>
					{props.standardCaseInput ? props.standardCaseInput.partNumber : t('standard_case_title')}
				</span>
			</div>
			<Grid container className={classes.topWrapper}>
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput ? props.standardCaseInput.date : moment()}
						control={props.control}
						name={standardCaseInputFields.date}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('standard_case_timestamp').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								clearable={true}
								value={value}
								error={props.errors[standardCaseInputFields.date] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.date]
										? props.errors[standardCaseInputFields.date].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput?.partNumber || ''}
						control={props.control}
						name={standardCaseInputFields.partNumber}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								key={`${props.partNumberOptions.length}-parts`}
								value={value}
								disabled={!props.isUserEditing}
								freeSolo
								autoSelect
								removeCloseIcon
								label={t('standard_case_overview_screen_part_number').toUpperCase()}
								onChange={onChange}
								options={props.partNumberOptions}
								error={props.errors[standardCaseInputFields.partNumber] ? true : false}
								multiple={false}
								helperText={
									props.errors[standardCaseInputFields.partNumber]
										? props.errors[standardCaseInputFields.partNumber].message
										: ''
								}
								onScanClick={() => {
									barcodeDialogRef.current?.setDialogState(true, (text: string) => {
										onChange(text);
									});
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput?.deliveryNoteNumber || ''}
						control={props.control}
						name={standardCaseInputFields.deliveryNoteNumber}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('standard_case_overview_screen_delivery_note_number').toUpperCase()}
								onChange={onChange}
								onPaste={props.disablePaste}
								type="number"
								disabled={!props.isUserEditing}
								value={value}
								onScanClick={() => {
									barcodeDialogRef.current?.setDialogState(true, (text: string) => {
										onChange(text.replaceAll(/\D/g, ''));
									});
								}}
								error={props.errors[standardCaseInputFields.deliveryNoteNumber] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.deliveryNoteNumber]
										? props.errors[standardCaseInputFields.deliveryNoteNumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput ? props.standardCaseInput.deliveryNoteDate : null}
						control={props.control}
						name={standardCaseInputFields.deliveryNoteDate}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('standard_case_overview_screen_delivery_note_date').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								isUserNotEditing={!props.isUserEditing}
								error={props.errors[standardCaseInputFields.deliveryNoteDate] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.deliveryNoteDate]
										? props.errors[standardCaseInputFields.deliveryNoteDate].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput ? props.standardCaseInput.totalPartsTested : ''}
						control={props.control}
						name={standardCaseInputFields.totalPartsTested}
						rules={{
							required: rules(t).required,
							pattern: rules(t).numberPatternBelow999,
							//@ts-ignore
							validate: (val) => {
								if (val && Number(val) < props.partsIO + props.partsNIO)
									return t('standard_case_screen_total_parts_tested_error');
								return true;
							},
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('standard_case_overview_screen_total_parts_tested').toUpperCase()}
								onChange={onChange}
								type="number"
								disabled={!props.isUserEditing}
								value={value}
								onScanClick={() => {
									barcodeDialogRef.current?.setDialogState(true, (text: string) => {
										onChange(text.replaceAll(/\D/g, ''));
									});
								}}
								error={props.errors[standardCaseInputFields.totalPartsTested] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.totalPartsTested]
										? props.errors[standardCaseInputFields.totalPartsTested].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<StandardTextField
						label={t('standard_case_overview_screen_parts_nio').toUpperCase()}
						onChange={() => {}}
						type="number"
						disabled={true}
						fixed={true}
						isUserNotEditing={!props.isUserEditing}
						value={props.partsNIO}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<StandardTextField
						label={t('standard_case_overview_screen_parts_rio').toUpperCase()}
						onChange={() => {}}
						isUserNotEditing={!props.isUserEditing}
						type="number"
						disabled={true}
						fixed={true}
						value={props.partsIO}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput ? props.standardCaseInput.partsReworked : ''}
						control={props.control}
						name={standardCaseInputFields.partsReworked}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('standard_case_overview_screen_parts_reworked').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								type="number"
								error={props.errors[standardCaseInputFields.partsReworked] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.partsReworked]
										? props.errors[standardCaseInputFields.partsReworked].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput?.typeOfInspection}
						control={props.control}
						name={standardCaseInputFields.typeOfInspection}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('standard_case_overview_screen_type_of_inspection').toUpperCase()}
								select
								disabled={!props.isUserEditing}
								value={value}
								onChange={onChange}
								error={props.errors[standardCaseInputFields.typeOfInspection] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.typeOfInspection]
										? props.errors[standardCaseInputFields.typeOfInspection].message
										: ''
								}>
								{props.contract?.typesOfInspection.map((item, index) => (
									<MenuItem key={`${item}-${index}`} value={item}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{item}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>

				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.standardCaseInput?.shift}
						control={props.control}
						name={standardCaseInputFields.shift}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('standard_case_input_screen_shift').toUpperCase()}
								select
								disabled={!props.isUserEditing}
								value={value}
								onChange={onChange}
								error={props.errors[standardCaseInputFields.shift] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.shift]
										? props.errors[standardCaseInputFields.shift].message
										: ''
								}>
								{props.contractShifts?.map((shift) => (
									<MenuItem key={`${shift.id}`} value={shift.id}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(shift.name)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3}>
					<Controller
						defaultValue={
							props.standardCaseInput?.locationID
								? props?.contract?.workLocations?.find(
										(zone) => zone.id === props.standardCaseInput?.locationID
								  )?.name
								: ''
						}
						control={props.control}
						name={standardCaseInputFields.location}
						rules={{}}
						render={({ onChange, value }) => (
							<StandardAutoComplete
								label={t('standard_case_location').toUpperCase()}
								onChange={(e) => {
									onChange(e);
								}}
								value={value}
								disabled={!props.isUserEditing}
								multiple={false}
								placeholder={'Locations'}
								noOptionText={'autocomplete_no_options_zone'}
								options={
									props.contract && props.contract.workLocations
										? [...props.contract.workLocations.map((zone) => zone.name)]
										: []
								}
								error={props.errors[standardCaseInputFields.location] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.location]
										? props.errors[standardCaseInputFields.location].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={5} className={classes.fieldWrapper} />
			</Grid>
			<div className={globalClasses.underline} />
			<Grid container className={classes.bottomWrapper}>
				<Grid item sm={4} />
				<Grid item sm={8} className={classes.tableTitleWrapper}>
					<div className={classes.checkNumberWrapper}>
						<span className={globalClasses.tableTitle}>{t('standard_case_check_number')}</span>
					</div>
					<div className={classes.errorTypeTitleWrapper}>
						<span className={globalClasses.tableTitle}>{t('standard_case_overview_screen_errorType')}</span>
					</div>
				</Grid>
				<Grid item sm={2} className={classes.totalCheckWrapper}>
					<div className={classes.totalCheckContentWrapper}>
						<span className={globalClasses.label} style={{ whiteSpace: 'normal' }}>
							{t('standard_case_total_check_number').toUpperCase()}
						</span>
					</div>
				</Grid>
				<Grid item sm={1}>
					<StandardTextField
						label={''}
						onChange={() => {}}
						type={'number'}
						disabled={true}
						fixed={true}
						value={props.totalPartsTested}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={8}>
					<div className={classes.tableRowWrapper}>
						<div className={classes.row}>
							<div className={classes.checkNumberWrapper}>
								<Controller
									defaultValue={''}
									control={props.addControl}
									name={standardCaseInputFields.checkNumber}
									rules={{ pattern: rules(t).numberPatternBelow999, required: rules(t).required }}
									render={({ onChange, value }) => (
										<>
											<input
												onChange={onChange}
												value={value}
												disabled={!props.isUserEditing}
												placeholder={'#'}
												type="number"
												className={classes.inputCheckNumber}
											/>
											{props.addErrors[standardCaseInputFields.checkNumber] ? (
												<div className={classes.errorWrapper}>
													<div className={classes.errorImageWrapper}>
														<ErrorIcon />
													</div>
													<span className={classes.error}>
														{props.addErrors[standardCaseInputFields.checkNumber]
															? t(
																	props.addErrors[standardCaseInputFields.checkNumber]
																		.message
															  )
															: ''}
													</span>
												</div>
											) : null}
										</>
									)}
								/>
							</div>
							<div className={classes.errorTypeWrapper}>
								<Controller
									defaultValue={''}
									control={props.addControl}
									name={standardCaseInputFields.errorType}
									render={({ onChange, value }) => (
										<TableAutoCompleteInput
											options={[
												...(props.contract ? props.contract.errorTypes : []),
												ErrorType.Other,
											]}
											disabled={!props.isUserEditing}
											error={props.addErrors[standardCaseInputFields.errorType] ? true : false}
											helperText={
												props.addErrors[standardCaseInputFields.errorType]
													? t(props.addErrors[standardCaseInputFields.errorType].message)
													: ''
											}
											placeholder={t('standard_case_total_error_type_placeholder')}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</div>
						</div>
						{!props.isUserEditing ? null : (
							<div className={classes.addErrorPart} onClick={props.addNewErrorPart}>
								<span className={globalClasses.standardLink}>{t('standard_case_total_error_add')}</span>
							</div>
						)}
					</div>
					{props.errorParts.map((errorPart, index) => (
						<ErrorInputRow
							key={`ErrorInputRow${index}`}
							index={index}
							errorInput={errorPart}
							disabled={!props.isUserEditing}
							onRemovePress={props.onRemoveErrorPart}
						/>
					))}
				</Grid>
				<Grid
					item
					sm={3}
					className={classes.uploadImageWrapper}
					style={{ height: props.commentRef.current ? props.commentRef.current.offsetHeight : 'undefined' }}>
					{props.contract?.automatedUpdates ? (
						<div className={classes.row}>
							<div className={classes.uploadPhotoWrapper}>
								<span
									className={globalClasses.standardLink}
									onClick={() => {
										if (props.isUserEditing) openCameraInCaptureMode();
									}}>
									{t('standard_case_total_upload_photo')}
								</span>
							</div>
							<div className={classes.image} onClick={openCameraInPreviewMode}>
								{photo ? null : <PhotoIcon />}
							</div>
						</div>
					) : null}
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={8} className={classes.commentWrapper} ref={props.commentRef}>
					<Controller
						defaultValue={props.standardCaseInput?.comment || ''}
						control={props.control}
						name={standardCaseInputFields.comment}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('standard_case_overview_screen_comment').toUpperCase()}
								onChange={onChange}
								multiline={true}
								rows={4}
								placeholder={t('standard_case_screen_comment_placeholder')}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[standardCaseInputFields.comment] ? true : false}
								helperText={
									props.errors[standardCaseInputFields.comment]
										? props.errors[standardCaseInputFields.comment].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
			</Grid>
			{props.contract?.automatedUpdates === false ? (
				<ApproveItem
					control={props.control}
					errors={props.errors}
					fieldName={standardCaseInputFields.approved}
					isUserEditing={props.isUserEditing}
				/>
			) : null}
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'standard_case_screen_cancel'}
					updateButtonText={'standard_case_screen_update'}
					createButtonText={'standard_case_screen_create'}
					createStartNewButtonText={'standard_case_screen_crete_start_new'}
					deleteButtonText={'standard_case_screen_delete'}
					editButtonText={'standard_case_screen_edit'}
					data={props.standardCaseInput}
					isUserEditing={props.isUserEditing}
					onCancelPress={props.onCancelPress}
					onDeletePress={props.onDeletePress}
					onSubmitPress={props.onSubmitPress}
					onSubmitStartNewPress={props.onSubmitStartNewPress}
				/>
			</div>
			<Controller
				control={props.control}
				name={standardCaseInputFields.photo}
				render={({ onChange }) => (
					<CameraDialog
						onChange={onChange}
						ref={cameraDialogRef}
						partNumber={props.standardCaseInput?.partNumber}
						cancelText={'standard_case_screen_camera_dialog_cancel_button'}
						saveText={'standard_case_screen_camera_dialog_save_button'}
						description={'standard_case_screen_camera_dialog_description'}
					/>
				)}
			/>
			<BarcodeScanner ref={barcodeDialogRef} />
			<StandardDialog
				acceptText={'standard_case_screen_dialog_accept_button'}
				description={'standard_case_screen_dialog_description'}
				title={'standard_case_screen_dialog_title'}
				cancleText={'standard_case_screen_dialog_cancel_button'}
				onCancle={props.onCancelDialogPress}
				onAccept={props.onDeleteErrorInputDialogPress}
				ref={props.dialogRef}
				showWarning
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
	photo?: FileInterface;
}

const useStyles = makeStyles(() => ({
	totalCheckWrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		transform: 'translateY(10px)',
	},
	totalCheckContentWrapper: {
		alignItems: 'center',
		paddingRight: '40px',
		transform: 'translateY(2px)',
	},
	titleWrapper: {
		marginBottom: '24px',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	fieldWrapper: {
		marginBottom: '40px !important',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
	bottomWrapper: {
		marginTop: '48px',
	},
	topWrapper: {
		marginBottom: '22px',
	},

	tableTitleWrapper: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: `2px solid #656565`,
	},
	checkNumberWrapper: {
		width: CHECK_NUMBER_WIDTH,
		padding: TABLE_PADDING,
	},
	errorTypeTitleWrapper: {
		width: ERROR_TYPE_WIDTH,
		padding: TABLE_PADDING,
		display: 'flex',
		alignSelf: 'flex-start',
	},
	errorTypeWrapper: {
		width: ERROR_TYPE_WIDTH,
		display: 'flex',
		alignSelf: 'flex-start',
	},
	commentWrapper: {
		marginTop: '64px',
	},
	uploadImageWrapper: {
		marginTop: '64px',
		display: 'flex',
		alignItems: 'flex-end',
	},
	tableRowWrapper: {
		borderBottom: '1px solid #C7C7C7',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: colors.white,
	},
	row: {
		display: 'flex',
		alignItems: 'center',
	},
	addErrorPart: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: '24px',
	},

	inputCheckNumber: {
		...globalInputStyle(),
		border: 'none',
		width: '70%',
		outline: 'none',
	},
	error: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.error,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	errorWrapper: {
		paddingTop: '9px',
		display: 'flex',
		alignItems: 'center',
	},
	uploadPhotoWrapper: (props: StyleProps) => ({
		marginRight: '27px',
		cursor: props.disabled ? 'defalut' : 'pointer',
	}),
	image: (props: StyleProps) => ({
		position: 'relative',
		width: '100px',
		height: '100px',
		backgroundImage: props.photo ? `url(${props.photo.data})` : undefined,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100px 100px',
		backgroundColor: colors.photoGray,
		cursor: props.disabled || !props.photo ? 'default' : 'pointer',
	}),
}));

export default StandardCaseInputScreen;
