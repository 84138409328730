import React from 'react';
import { Control, Controller } from 'react-hook-form';
import StandardTextField from '../components/StandardTextField';
import { RulesInterface } from '../consts/rules';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	isUserEditing: boolean;
	name: string;
	rules: Partial<RulesInterface>;
	label: string;
	disabled?: boolean;
}
function StandardTextFieldWithControler(props: Props) {
	return (
		<Controller
			defaultValue={''}
			control={props.control}
			name={props.name}
			rules={props.rules}
			render={({ onChange, value }) => (
				<StandardTextField
					label={props.label}
					onChange={onChange}
					value={value}
					disabled={!props.isUserEditing || props.disabled}
					fixed={props.disabled}
					error={props.errors[props.name] ? true : false}
					helperText={props.errors[props.name] ? props.errors[props.name].message : ''}
				/>
			)}
		/>
	);
}

export default StandardTextFieldWithControler;
