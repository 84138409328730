import React from 'react';
import { ExpenseTabelParamsInterface } from '../consts/material';
import { addExpenseFields, AddExpensesItemKeys } from './AddExpensesItem';
import { Control } from 'react-hook-form';
import { AddExpenseItemCellType } from './AddExpenseItemCell';
import { TableCellWithCss } from './table/StandardTableCell';
import ExpensesTextFieldCellType from './ExpensesTextFieldCellType';
import MaterialTool from '../service/types/MaterialTool';
import Travel from '../service/types/Travel';
import Transport from '../service/types/Transport';
import ExpensesKeyValueCellType from './ExpensesKeyValueCellType';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import ExpensesDateFieldCellType from './ExpensesDateFieldCellType';
import ExpensesEditFieldCellType from './ExpensesEditFieldCellType';
// import Contract from '../service/types/Contract';

interface Props {
	params: ExpenseTabelParamsInterface | undefined;
	fieldKey: AddExpensesItemKeys;
	errors: Record<string, any>;
	control: Control<Record<string, any>>;
	itemClass: string;
	edit: boolean;
	isSummary: boolean;
	expense: MaterialTool | Travel | Transport;
	reporter: KeyValue | undefined;
	textClass: string;
	inputColor: string;
	contractNumber?: string;
}
function ExpensesItemCell(props: Props) {
	if (props.params)
		switch (props.params.addType) {
			case AddExpenseItemCellType.Standard:
				return (
					<TableCellWithCss
						className={props.itemClass}
						style={{
							width: props.params.width,
						}}>
						<ExpensesTextFieldCellType
							edit={props.edit && !props.isSummary}
							//@ts-ignore
							value={props.expense[props.fieldKey]}
							control={props.control}
							errors={props.errors}
							number={props.params.inputType}
							rules={props.params.rules}
							rightAlign={props.params.rightAlign}
							placeholder={props.params.placeholder}
							fieldKey={addExpenseFields[props.fieldKey]}
							inputColor={props.inputColor}
						/>
					</TableCellWithCss>
				);
			case AddExpenseItemCellType.KeyValue:
				return (
					<TableCellWithCss
						className={props.itemClass}
						style={{
							width: props.params.width,
						}}>
						<ExpensesKeyValueCellType
							edit={props.edit && !props.isSummary}
							//@ts-ignore
							valueID={props.expense[props.fieldKey]}
							values={props.params.options ? props.params.options : []}
							control={props.control}
							errors={props.errors}
							placeholder={props.params.placeholder!}
							fieldKey={addExpenseFields[props.fieldKey]}
							inputColor={props.inputColor}
							showAsValue={props.params.showAsValue}
						/>
					</TableCellWithCss>
				);
			case AddExpenseItemCellType.Static:
				return (
					<TableCellWithCss
						className={props.itemClass}
						style={{
							width: props.params.width,
						}}>
						<span className={props.textClass}>{props.reporter?.key}</span>
					</TableCellWithCss>
				);
			case AddExpenseItemCellType.Date:
				return (
					<TableCellWithCss
						className={props.itemClass}
						style={{
							width: props.params.width,
						}}>
						<ExpensesDateFieldCellType
							edit={props.edit && !props.isSummary}
							//@ts-ignore
							value={props.expense[props.fieldKey]}
							control={props.control}
							errors={props.errors}
							number={props.params.inputType}
							rules={props.params.rules}
							rightAlign={props.params.rightAlign}
							placeholder={props.params.placeholder}
							fieldKey={addExpenseFields[props.fieldKey]}
							inputColor={props.inputColor}
						/>
					</TableCellWithCss>
				);

			case AddExpenseItemCellType.Edit:
				return (
					<TableCellWithCss
						className={props.itemClass}
						style={{
							width: props.params.width,
						}}>
						<ExpensesEditFieldCellType
							edit={props.edit && !props.isSummary}
							//@ts-ignore
							value={props.expense[props.fieldKey]}
							contractNumber={props.contractNumber}
							control={props.control}
							errors={props.errors}
							number={props.params.inputType}
							rules={props.params.rules}
							rightAlign={props.params.rightAlign}
							placeholder={props.params.placeholder}
							fieldKey={addExpenseFields[props.fieldKey]}
							inputColor={props.inputColor}
						/>
					</TableCellWithCss>
				);
		}
	return null;
}

export default ExpensesItemCell;
