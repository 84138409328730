import * as React from 'react';
import { colors } from '../consts/colors';

function DropdownArrow(props: any) {
	return (
		<svg width={12} height={7} viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M.5.5l5.5 6 5.5-6"
				stroke={props.disabled ? colors.lightGray : colors.darkGray}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default DropdownArrow;
