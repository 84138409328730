import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';

interface Props {
	onLoginPress: () => void;
}

function ResetPasswordSuccessScreen(props: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles({ description: colors.gray });
	const { t } = useTranslation();
	return (
		<div className={classes.wrappper}>
			<Grid container className={globalClasses.container} spacing={4}>
				<Grid item sm={1} />
				<Grid item>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t('resest_password_success_screen_title')}</span>
					</div>
					<div className={classes.descriptionWrapper}>
						<span className={globalClasses.description}>
							{t('resest_password_success_screen_description')}
						</span>
					</div>
					<div className={classes.buttonWrapper}>
						<Button
							onClick={props.onLoginPress}
							text={t('resest_password_success_screen_button')}
							textClassName={globalClasses.buttonText}
							buttonWrapperClassName={globalClasses.buttonWrapper}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrappper: {
		marginTop: '108px',
	},
	buttonWrapper: {
		marginTop: '40px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},

	descriptionWrapper: {
		marginBottom: '12px',
	},
}));

export default ResetPasswordSuccessScreen;
