import { TableCellType } from '../components/table/StandardTableCell';
import { TableParamInterface } from './rhenusOverview';

interface InvoiceParamInterface {
	id: TableParamInterface;
	creation_date: TableParamInterface;
	date_of_invoice: TableParamInterface;
	from_date: TableParamInterface;
	to_date: TableParamInterface;
}

export const invoiceTableParams: InvoiceParamInterface = {
	id: {
		width: '18.5vw',
		title: 'id',
		type: TableCellType.Sticky,
		titleType: TableCellType.Title,
		sticky: true,
	},
	creation_date: {
		width: '10.5vw',
		title: 'invoice_screen_creation_date',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	date_of_invoice: {
		width: '9.035vw',
		title: 'invoice_screen_billing_date',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	from_date: {
		width: '12.96vw',
		title: 'invoice_screen_billed_from',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	to_date: {
		width: '26.711vw',
		title: 'invoice_screen_billed_until',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
};
