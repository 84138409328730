import { loginFields } from '../containers/LoginContainer';

export function setLoginError(error: any, setError: (name: string, value: { message: string }) => void) {
	if (error && error.error) {
		if (error.error.code === -32001) {
			setError(loginFields.email, { message: 'login_screen_no_user_email_error' });
		} else if (error.error.code === -32006) {
			setError(loginFields.password, { message: 'login_screen_password_error' });
		}
	}
}
