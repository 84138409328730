import { AddExpenseItemCellType } from '../components/AddExpenseItemCell';
import { TableCellType } from '../components/table/StandardTableCell';
import { billedOptions } from '../helpers/expenses/generalExpensesHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import User from '../service/types/User';
import { ExpenseTabelInterface } from './material';
import { rules } from './rules';

export const toolTabelParams = (
	users: KeyValue[],
	user: User | undefined,
	t: (message: string) => string
): ExpenseTabelInterface => ({
	name: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_tool_table_name_title',
		width: '22.66vh',
		placeholder: 'expenses_screen_tool_table_name_placeholder',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Standard,
	},
	date: {
		titleType: TableCellType.Title,
		title: 'invoice_screen_creation_date',
		width: '15.66vh',
		placeholder: 'invoice_screen_creation_date',
		addType: AddExpenseItemCellType.Date,
		inputType: undefined,
		rightAlign: true,
	},
	quantity: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_tool_table_quantity_title',
		width: '80px',
		placeholder: 'expenses_screen_tool_table_quantity_placeholder',
		inputType: 'number',
		rightAlign: true,
		addType: AddExpenseItemCellType.Standard,
		rules: {
			pattern: rules(t).numberPatternBelow999,
			required: rules(t).required,
		},
	},
	price: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_tool_table_price_title',
		width: '80px',
		placeholder: '€',
		inputType: 'number',
		rightAlign: true,
		addType: AddExpenseItemCellType.Standard,
		rules: {
			pattern: rules(t).numberPatternAny,
			required: rules(t).required,
		},
	},
	reporter: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_reporter_title',
		width: '20.438vh',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
		value: `${user?.firstName} ${user?.lastName}`,
	},
	qai: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_table_qai_title',
		width: '22.414vh',
		placeholder: 'expenses_screen_name',
		options: users,
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.KeyValue,
	},
	billed: {
		titleType: TableCellType.Title,
		title: 'expenses_screen_status',
		width: '15.66vh',
		placeholder: 'expenses_screen_status',
		inputType: undefined,
		rightAlign: false,
		options: billedOptions,
		addType: AddExpenseItemCellType.KeyValue,
	},
	edit: {
		titleType: TableCellType.Title,
		title: '',
		width: '50px',
		placeholder: '',
		inputType: undefined,
		rightAlign: false,
		addType: AddExpenseItemCellType.Static,
	},
});
