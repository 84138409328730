import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import Spinner from '../../components/Spinner';
import GroupList from './components/GroupList';
import StandardTextField from '../../components/StandardTextField';

import Filter from '../../components/Filter';
import service from '../../service/service';
import { useGlobalStyles } from '../../consts/globalStyles';
import { useAtom } from 'jotai';
import { groupAtom } from '../../atoms/group';

export interface GroupUser {
	id: number;
	name: string;
}
export interface Group {
	groupId: number;
	name: string;
	users: GroupUser[];
}

export default function GroupOverview() {
	const { t } = useTranslation();

	const [groups, setGroups] = useAtom(groupAtom);
	const [showFilters, setShowFilters] = useState(false);
	const [groupNameFilter, setGroupNameFilter] = useState('');
	const [groupMemberFilter, setGroupMemberFilter] = useState('');

	const groupData = useMemo(() => {
		let data = groups;
		if (groupNameFilter.trim() !== '') {
			data = data.filter((el) => el.name.toLowerCase().startsWith(groupNameFilter.trim().toLowerCase()));
		}
		if (groupMemberFilter.trim() !== '') {
			data = data.filter(
				(el) =>
					el.users.filter((member) =>
						member.name.toLowerCase().includes(groupMemberFilter.trim().toLowerCase())
					).length > 0
			);
		}

		return data;
	}, [groups, groupNameFilter, groupMemberFilter]);

	const globalClasses = useGlobalStyles();
	const onFilterPress = () => setShowFilters((prev) => !prev);

	const clearAllFilters = () => {
		setGroupNameFilter('');
		setGroupMemberFilter('');
	};

	useEffect(() => {
		(async () => {
			try {
				if (groups.length <= 0) {
					const readGroups = await service.readGroups();
					setGroups(readGroups);
				}
			} catch {}
		})();
	}, [groups, setGroups]);

	if (groups?.length === 0) return <Spinner />;

	return (
		<div className={globalClasses.container}>
			<Header />
			<Filter onFilterPress={onFilterPress} onClearAllPress={clearAllFilters} />

			<Grid
				container
				spacing={4}
				className={`${globalClasses.filterWrapperClosed} ${
					showFilters ? globalClasses.filterWrapperOpened : ''
				}`}>
				<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
					<StandardTextField
						label={t('group_overview_group_name')}
						value={groupNameFilter}
						onChange={(e) => setGroupNameFilter(e.target.value)}
					/>
				</Grid>
				<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
					<StandardTextField
						label={t('group_overview_group_members')}
						value={groupMemberFilter}
						onChange={(e) => setGroupMemberFilter(e.target.value)}
					/>
				</Grid>
			</Grid>

			<GroupList groups={groupData} />
		</div>
	);
}
