import React from 'react';
import { Moment } from 'moment';
import { makeStyles } from '@material-ui/core';
import Dots from '../../svgComponents/Dots';
import { colors } from '../../consts/colors';
import { fonts } from '../../consts/fonts';

interface Props {
	value: string | Moment;
}

function ExpandableCell(props: Props) {
	const [isExpanded, setIsExpanded] = React.useState(true);
	const [overflow, setOverflow] = React.useState(false);
	const spanRef = React.useRef<HTMLSpanElement>(null);

	const classes = useStyles({ isExpanded });

	React.useEffect(() => {
		if (spanRef.current && 0 > 24 - spanRef.current?.offsetHeight) {
			setOverflow(true);
			setIsExpanded(false);
		}
	}, []);

	return (
		<div className={classes.row}>
			<div className={classes.descriptionWarapper}>
				<span className={classes.description} ref={spanRef}>
					{props.value}
				</span>
			</div>
			{overflow ? (
				<div
					className={classes.dotsWrapper}
					onClick={() => {
						setIsExpanded(!isExpanded);
					}}>
					<Dots className={classes.dots} />
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	isExpanded: boolean;
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dots: {
		marginLeft: '2px',
	},
	dotsWrapper: {
		display: 'flex',
		height: '20px',
		alignItems: 'center',
		cursor: 'pointer',
	},
	description: {
		color: colors.darkGray,
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
	},
	descriptionWarapper: (p: StyleProps) => ({
		whiteSpace: p.isExpanded ? 'normal' : 'pre',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	}),
}));

export default ExpandableCell;
