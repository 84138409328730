import { Service } from '../../service/service';
import { History, LocationState } from 'history';
import RhenusCase from '../../service/types/RhenusCase';
import { NotificationInterface } from '../../context/notification/notificationReducer';

export function deleteRhenusInput(
	service: Service,
	rhenusInputCase: RhenusCase | undefined,
	contractIDRef: React.MutableRefObject<number>,
	history: History<LocationState>,
	setNotification: (notification: NotificationInterface) => void
) {
	if (rhenusInputCase)
		service
			.deleteRhenusCaseInputByID(contractIDRef.current, rhenusInputCase.zone, rhenusInputCase.id)
			.then((res) => {
				if (res) {
					history.goBack();
					setNotification({
						text: 'rhenus_case_screen_delete_notification',
						additional: rhenusInputCase.partNumber,
					});
				}
			})
			.catch(() => {});
}
