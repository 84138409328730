import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
	filterRecordTimeTypeKeyValuePairs,
	timeFromUnixToStringFormat,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { fonts } from '../consts/fonts';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import BilledItem from './BilledItem';
import { RECORD_LIST_PARAMS } from './RecordListSummary';
import BreakTime, { RecordTimeType } from '../service/types/BreakTime';
import { useTranslation } from 'react-i18next';

interface Props {
	index: number;
	record: BreakTime;
	users: KeyValue[];
}
function BreakItemSummary(props: Props) {
	const globalClasses = useGlobalStyles();
	const classes = useStyles({ type: props.record.type });
	const { t } = useTranslation();

	const reporter = React.useMemo(() => {
		return props.users.find((user) => user.value.id === props.record.reporter);
	}, [props.record, props.users]);

	const time = React.useMemo(() => {
		return timeFromUnixToStringFormat(props.record.duration);
	}, [props.record]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.rangeWrapper}>
				<div className={classes.indexWrapper}>
					<span className={globalClasses.smallText}> {`${props.index + 1}.`}</span>
				</div>
				<div>
					<div className={classes.row}>
						<div className={classes.dateWrapper}>
							<span className={globalClasses.smallTextDark}>
								{props.record.date.format('DD.MM.YYYY')}
							</span>
						</div>
						<div className={classes.fromWrapper}>
							<span className={globalClasses.smallTextDark}>
								{t(
									filterRecordTimeTypeKeyValuePairs.find((item) => item.value === props.record.type)
										?.key || ''
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.timeWrapper}>
				<div className={classes.timeTitleWrapper}>
					<span className={globalClasses.smallerTextLight}>{'Time: '}</span>
				</div>
				<div>
					<span className={globalClasses.mediumTextSemiDark}>{time}</span>
				</div>
			</div>
			<div className={classes.reporterWrapper}>
				<span className={globalClasses.smallTextDark}>{reporter?.key}</span>
			</div>
			<div className={classes.statusWrapper}>
				<BilledItem billed={props.record.billed!} billable={true} />
			</div>
		</div>
	);
}

interface StyleProps {
	type: RecordTimeType;
}

const useStyles = makeStyles(() => ({
	inputAddWrapper: {
		width: '100%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
		transform: 'translateX(-10px)',
	},
	inputWrapper: {
		width: '95%',
	},
	indexWrapper: {
		display: 'flex',
		marginRight: '24px',
		alignItems: 'center',
	},
	fromWrapper: {
		display: 'flex',
		marginRight: '16px',
		alignItems: 'center',
	},
	toWrapper: {
		display: 'flex',
		marginLeft: '16px',
		alignItems: 'center',
	},
	timeTitleWrapper: {
		display: 'flex',
		marginRight: '12px',
		alignItems: 'center',
	},
	timeWrapper: {
		width: RECORD_LIST_PARAMS.TIME,
		display: 'flex',
		alignItems: 'center',
	},
	reporterWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: RECORD_LIST_PARAMS.REPORTER,
	},
	statusWrapper: {
		width: RECORD_LIST_PARAMS.STATUS,
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: (p: StyleProps) => ({
		display: 'flex',
		padding: '20px 16px',
		margin: '4px 0px',
		border: `1px solid ${colors.timetrackBorderd}`,
		//backgroundColor: p.type === RecordTimeType.Break ? colors.billRed : colors.billOragne,
	}),
	rangeWrapper: {
		width: RECORD_LIST_PARAMS.RANGE,
		display: 'flex',
		alignItems: 'center',
	},
	smallTextLight: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.gray,
		letterSpacing: 0.4,
	},
	row: {
		display: 'flex',
		alignItems: 'center',
	},
	timeInputWrapper: {
		width: '45px',
		paddingLeft: '5px',
		transform: 'translateX(-6px)',
		padding: '0',
		border: `1px solid ${colors.addTimeBorder}`,
	},
	dateWrapper: {
		marginRight: '50px',
	},
}));

export default BreakItemSummary;
