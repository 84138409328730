import React from 'react';

import { WorkingTimeActionsContext, WorkingTimeStateContext } from './WorkingTimeContext';

export function useWorkingTimeStateContext() {
	return React.useContext(WorkingTimeStateContext);
}

export function useWorkingTimeActionContext() {
	return React.useContext(WorkingTimeActionsContext);
}
