import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import CloseIcon from '../svgComponents/CloseIcon';
import WarningIcon from '../svgComponents/WarningIcon';
import Transition from './Transition';

export interface StandardDialogInterface {
	setDialogState: (val: boolean) => void;
}

interface Props {
	title: string;
	description: string | JSX.Element;
	acceptText: string;
	onAccept: () => void;
	onCancle?: () => void;
	cancleText?: string;
	testID?: string;
	showWarning: boolean;
	notShrinkable?: boolean;
	paperClass?: string;
}

function StandardDialog(props: Props, ref: any) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);

	React.useImperativeHandle(
		ref,
		(): StandardDialogInterface => ({
			setDialogState: (val: boolean) => {
				setIsOpen(val);
			},
		})
	);

	return (
		<Dialog
			classes={{
				paper: `${props.notShrinkable ? classes.paper : ''} ${props.paperClass}`,
			}}
			className={classes.wrapper}
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => {
				setIsOpen(false);
				if (props.onCancle) props.onCancle();
			}}>
			<div className={classes.container} data-testid={props.testID}>
				{props.showWarning && (
					<div className={classes.warningIconWrapper}>
						<WarningIcon />
					</div>
				)}

				<div>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.dialogTitle}>{t(props.title)}</span>
					</div>
					{typeof props.description === 'string' ? (
						<div className={classes.descriptionWrapper}>
							<span className={globalClasses.dialogDescription}>{t(props.description)}</span>
						</div>
					) : (
						props.description
					)}
					<div className={classes.row}>
						<div className={classes.buttonWrapper} onClick={props.onAccept}>
							<span className={globalClasses.dialogTitle}>{t(props.acceptText)}</span>
						</div>
						{props.cancleText ? (
							<div className={classes.buttonWrapper} onClick={props.onCancle}>
								<span className={globalClasses.dialogTitle}>{t(props.cancleText)}</span>
							</div>
						) : null}
					</div>
				</div>
				<div className={classes.closeIconWarpper} onClick={() => setIsOpen(false)}>
					<CloseIcon color={colors.darkGray} />
				</div>
			</div>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: { maxWidth: '40vw', marginLeft: 'auto', marginRight: 'auto' },
	container: { display: 'flex', padding: '16px 16px 16px 20px', borderRadius: '4px', flex: 1 },
	row: { display: 'flex' },
	titleWrapper: {
		marginBottom: '8px',
		transform: 'translateY(-2px)',
	},
	descriptionWrapper: {
		marginBottom: '20px',
	},
	buttonWrapper: {
		marginRight: '24px',
		cursor: 'pointer',
	},
	warningIconWrapper: {
		marginRight: '16px',
	},
	closeIconWarpper: {
		marginLeft: '12px',
		cursor: 'pointer',
		height: 'fit-content',
	},
	paper: {
		flexShrink: 0,
		maxWidth: 'unset',
	},
}));

export default React.forwardRef(StandardDialog);
