import { makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import CheckboxEmpty from '../svgComponents/CheckboxEmpty';
import CheckboxFilled from '../svgComponents/CheckboxFilled';

interface Props {
	value: boolean;
	name: string;
	disabled: boolean;
	onChange: (val: boolean) => void;
	customOnChange?: (val: boolean, name: string) => void;
	customName?: string;
}

function StandardCheckbox(props: Props) {
	const classes = useStyles({ disabled: props.disabled });
	const globalClasses = useGlobalStyles({ description: colors.gray });

	return (
		<div
			className={classes.wrapper}
			onClick={() => {
				if (!props.disabled) {
					if (props.customOnChange && props.customName) {
						props.customOnChange(!props.value, props.customName);
					}
					props.onChange(!props.value);
				}
			}}>
			{props.value ? <CheckboxFilled className={classes.margin} /> : <CheckboxEmpty className={classes.margin} />}
			{props.name ? <span className={globalClasses.description}>{props.name}</span> : null}
		</div>
	);
}

interface StyleProps {
	disabled: boolean;
}

const useStyles = makeStyles({
	wrapper: (p: StyleProps) => ({
		display: 'flex',
		alignItems: 'center',
		cursor: p.disabled ? 'default' : 'pointer',
		width: 'fit-content',
	}),
	margin: {
		marginRight: '12px',
	},
});
export default StandardCheckbox;
