import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthStateContext } from '../context/auth/useAuth';
import { goToChangePassword, goToHome } from '../helpers/navigationHelper';

import SettingsScreen from '../screens/SettingsScreen';
import service from '../service/service';
import { UserRole } from '../service/types/User';

function SettingsContainer() {
	const { user } = useAuthStateContext();
	const history = useHistory();

	const onChangePasswordPress = () => {
		if (user?.role !== UserRole.QualityAssuranceInspector) {
			goToChangePassword(history);
		} else {
			service
				.requestPasswordReset(user.email)
				.then(() => {
					goToHome(history);
				})
				.catch(() => {});
		}
	};

	return <SettingsScreen onChangePasswordPress={onChangePasswordPress} />;
}

export default SettingsContainer;
