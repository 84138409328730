import React from 'react';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '../svgComponents/CloseIcon';
import { useGlobalStyles } from '../consts/globalStyles';
import { colors } from '../consts/colors';
import { useNotificationActionContext, useNotificationStateContext } from '../context/notification/useNotification';
import { useTranslation } from 'react-i18next';

function Notification() {
	const { notification, visible } = useNotificationStateContext();
	const { hideNotification } = useNotificationActionContext();
	const [shown, setShown] = React.useState(false);
	const [display, setDisplay] = React.useState(true);

	const classes = useStyles({ shown: shown, visible: visible, display: display });
	const globalClasses = useGlobalStyles({});
	const { t } = useTranslation();

	const onCloseNotification = () => {
		setShown(false);
		hideNotification();
		setDisplay(false);
		setTimeout(() => {
			setDisplay(true);
		}, 1500);
	};

	React.useEffect(() => {
		if (visible) {
			setShown(true);
			setTimeout(() => {
				setShown(false);
				setTimeout(() => {
					hideNotification();
				}, 1500);
			}, 3500);
		}
	}, [visible, hideNotification]);

	return (
		<div className={classes.wrapper}>
			<span className={globalClasses.smallTextDark}>
				{t(notification.text, { text: notification.additional })}
			</span>
			<CloseIcon color={colors.darkGray} onClick={onCloseNotification} className={classes.closeIcon} />
		</div>
	);
}

interface StyleProps {
	visible: boolean;
	shown: boolean;
	display: boolean;
}

const useStyles = makeStyles(() => ({
	wrapper: (p: StyleProps) => ({
		position: 'fixed',
		bottom: '20px',
		left: '110px',
		right: '110px',
		padding: '10px 40px 10px 32px',
		display: p.display ? 'flex' : 'none',
		boxShadow: '0 4px 8px -4px rgba(26,26,26,0.2)',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: colors.lightYellow,
		opacity: p.shown ? 1 : 0,
		transition: 'opacity 1.5s',
		pointerEvents: p.visible ? 'auto' : 'none',
		zIndex: 30,
	}),
	closeIcon: {
		cursor: 'pointer',
	},
}));

export default Notification;
