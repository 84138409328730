import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Expenses, ExpensesKeys, initExpenses } from '../helpers/expenses/generalExpensesHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import MaterialTool from '../service/types/MaterialTool';
import Transport from '../service/types/Transport';
import Travel from '../service/types/Travel';
import { useAuthStateContext } from '../context/auth/useAuth';
import { useTranslation } from 'react-i18next';
import { expensesTabelParams } from '../consts/expenses';
import ExpensesTabel from './ExpensesTabel';
import { useGlobalStyles } from '../consts/globalStyles';
import { IContractWorkLocation } from '../service/types/Contract';

interface Props {
	summaryData: Expenses;
	users: KeyValue[];
	tabType: ExpensesKeys;
	timeTitle: string;
	workLocations: IContractWorkLocation[];
}

function SummaryExpensesWrapper(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const { user } = useAuthStateContext();

	const transformedSummaryData = React.useMemo(() => {
		return (Object.keys(props.summaryData) as ExpensesKeys[]).reduce((prev, curr: ExpensesKeys) => {
			if (curr === 'workingTime') return prev;

			const currentExpense = props.summaryData[curr].reduce(
				(
					prev: { [key: string]: (MaterialTool | Transport | Travel)[] },
					curr: MaterialTool | Transport | Travel
				) => {
					return { ...prev, [curr.qai]: [...(prev[curr.qai] ? prev[curr.qai] : []), curr] };
				},
				{} as {
					[key: string]: (MaterialTool | Transport | Travel)[];
				}
			);
			return {
				...prev,
				...Object.keys(currentExpense).reduce((prevState, currKey) => {
					return {
						...prevState,
						[currKey]: {
							...prev[currKey],
							[curr]: currentExpense[currKey],
						},
					};
				}, {} as { [key: string]: Expenses }),
			};
		}, {} as { [key: string]: Expenses });
	}, [props.summaryData]);

	return (
		<div>
			{Object.keys(transformedSummaryData).map((id) =>
				transformedSummaryData[id][props.tabType] ? (
					<div key={`SummaryExpenses${id}`}>
						<div className={classes.mainTitleWrapper}>
							<span className={globalClasses.bigTextBold}>
								{props.users.find((user) => user.value.id.toString() === id)?.key}
							</span>
						</div>
						<div className={classes.rangeWrapper}>
							<span className={globalClasses.mediumTextSemiDark}>{props.timeTitle}</span>
						</div>
						<ExpensesTabel
							isSummary={true}
							onDeletePress={() => {}}
							users={props.users}
							expenses={{ ...initExpenses, ...transformedSummaryData[id] }}
							expensesTableParams={expensesTabelParams(t, props.users, user, props.workLocations)}
							tableKey={'Expenses'}
							tabType={props.tabType}
							onAddPress={() => {
								return Promise.resolve(false);
							}}
							onUpdatePress={() => {
								return Promise.resolve(false);
							}}
						/>
					</div>
				) : null
			)}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	mainTitleWrapper: {
		marginTop: '16px',
	},
	rangeWrapper: {},
}));

export default SummaryExpensesWrapper;
