import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import { useGlobalStyles } from '../consts/globalStyles';
import AuthTextField from '../components/AuthTextField';
import { useTranslation } from 'react-i18next';
import { changePasswordFields } from '../containers/ChangePasswordContainer';
import Button from '../components/Button';
import { standardPasswordReset } from '../consts/login';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	onChangePasswordPRess: () => void;
	getValues: (field: string) => string;
}

function ChangePasswordScreen(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles({});
	const { t } = useTranslation();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
				<HomeButton />
			</div>
			<div className={classes.wrappper}>
				<Grid container className={globalClasses.container}>
					<Grid item sm={1} />
					<Grid item sm={4}>
						<div className={classes.titleWrapper}>
							<span className={globalClasses.title}>{t('change_password_screen_title')}</span>
						</div>
						<div className={classes.emailWrapper}>
							<Controller
								defaultValue={''}
								control={props.control}
								name={changePasswordFields.oldPassword}
								rules={standardPasswordReset.rules(t).password}
								render={({ onChange }) => (
									<AuthTextField
										onChange={onChange}
										label={t('change_password_screen_old_password')}
										error={props.errors[changePasswordFields.oldPassword] ? true : false}
										helperText={
											props.errors[changePasswordFields.oldPassword]
												? props.errors[changePasswordFields.oldPassword].message
												: ''
										}
										type="password"
										onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
											if (e.key === 'Enter') {
												props.onChangePasswordPRess();
											}
										}}
									/>
								)}
							/>
						</div>
						<div className={classes.emailWrapper}>
							<Controller
								defaultValue={''}
								control={props.control}
								name={changePasswordFields.newPassword}
								rules={standardPasswordReset.rules(t).password}
								render={({ onChange }) => (
									<AuthTextField
										onChange={onChange}
										label={t('change_password_screen_new_password')}
										error={props.errors[changePasswordFields.newPassword] ? true : false}
										helperText={
											props.errors[changePasswordFields.newPassword]
												? props.errors[changePasswordFields.newPassword].message
												: ''
										}
										type="password"
										onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
											if (e.key === 'Enter') {
												props.onChangePasswordPRess();
											}
										}}
									/>
								)}
							/>
						</div>
						<div className={classes.emailWrapper}>
							<Controller
								defaultValue={''}
								control={props.control}
								name={changePasswordFields.repeatPassword}
								rules={{
									...standardPasswordReset.rules(t).password,
									//@ts-ignore
									validate: (val) => {
										if (props.getValues(changePasswordFields.newPassword) === val) return true;
										else return t('change_password_screen_must_match');
									},
								}}
								render={({ onChange }) => (
									<AuthTextField
										onChange={onChange}
										label={t('change_password_screen_repeat_password')}
										error={props.errors[changePasswordFields.repeatPassword] ? true : false}
										helperText={
											props.errors[changePasswordFields.repeatPassword]
												? props.errors[changePasswordFields.repeatPassword].message
												: ''
										}
										type="password"
										onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
											if (e.key === 'Enter') {
												props.onChangePasswordPRess();
											}
										}}
									/>
								)}
							/>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.onChangePasswordPRess}
								text={t('change_password_screen_button')}
								textClassName={globalClasses.buttonText}
								buttonWrapperClassName={globalClasses.buttonWrapper}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrappper: {
		marginTop: '108px',
	},
	buttonWrapper: {
		marginTop: '40px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},
	emailWrapper: {
		marginBottom: '20px',
	},
}));

export default ChangePasswordScreen;
