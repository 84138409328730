import * as React from 'react';

function BarcodeScannerIcon(props: React.SVGProps<any>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 24 24" {...props}>
			<g className="prefix__nc-icon-wrapper" fill="#111">
				<path d="M15 0h5v19h-5zM4 0h5v19H4zM11 0h2v19h-2zM0 0h2v24H0zM22 0h2v24h-2z" />
			</g>
		</svg>
	);
}

export default BarcodeScannerIcon;
