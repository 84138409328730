import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import { MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { ERROR_TYPE_WIDTH } from '../screens/StandardCaseInputScreen';
import DropdownArrow from '../svgComponents/DropdownArrow';

export const TextFieldWithCSS = withStyles({
	root: {
		'& .MuiAutocomplete-popupIndicatorOpen': {
			transform: 'rotate(0deg)',
		},
		'& .MuiAutocomplete-popupIndicator': {
			marginRight: '2px',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'white',
		},
		'& .Mui-disabled': {
			color: colors.gray,
		},
		'& .MuiAutocomplete-inputRoot': {
			paddingLeft: '12px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-input': {
			...globalInputStyle(),
			borderRadius: '5px',
			paddingLeft: '12px',
		},
		'& .MuiInput-input.Mui-disabled': {
			...globalInputStyle(),
			color: colors.gray,
			borderRadius: '5px',
			paddingLeft: '12px',
		},

		'& .MuiInput-underline:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
	},
})(TextField);

interface Props {
	options: string[];
	onChange: (event: any) => void;
	error?: boolean;
	helperText?: string;
	multiple?: boolean;
	loading?: boolean;
	withoutArrow?: boolean;
	onOpen?: () => void;
	inputWrapper?: string;
	disabled?: boolean;
	value?: string;
	placeholder?: string;
}

function TableAutoCompleteInput(props: Props) {
	const { t } = useTranslation();
	const [options, setOptions] = React.useState(props.options);
	const [foucsed, setFoucsed] = React.useState(false);
	const [rotateArrow, setRotateArrow] = React.useState(false);
	const classes = useStyles({ foucsed: foucsed, rotate: rotateArrow, disabled: props.disabled });
	const globalClasses = useGlobalStyles();

	React.useEffect(() => setOptions(props.options), [props.options]);

	return (
		<div className={classes.menuItem}>
			<Autocomplete
				disabled={props.disabled}
				options={options}
				onFocus={() => setFoucsed(true)}
				onBlur={() => setFoucsed(false)}
				value={props.value}
				classes={{
					paper: classes.dropdownContainer,
				}}
				openText=""
				closeText=""
				onClose={() => setRotateArrow(false)}
				onOpen={() => {
					setRotateArrow(true);
				}}
				popupIcon={
					!props.withoutArrow ? (
						<div className={classes.dropdownArrowWrapper}>
							<DropdownArrow className={classes.rotate} disabled={props.disabled} />
						</div>
					) : null
				}
				onChange={(_, newValue) => {
					props.onChange(newValue);
				}}
				getOptionLabel={(option) => t(option)}
				renderOption={(option) => (
					<MenuItem key={option} value={option} className={classes.menuItem}>
						<div className={globalClasses.errorInputMenuItemWrapper}>
							<span className={globalClasses.errorInputMenuItem}>{t(option)}</span>
						</div>
					</MenuItem>
				)}
				loading={props.loading}
				renderInput={(params) => (
					<TextFieldWithCSS
						{...params}
						onChange={() => {}}
						placeholder={props.placeholder}
						label={undefined}
						error={props.error}
						helperText={''}
						className={props.inputWrapper ? props.inputWrapper : classes.inputWrapper}
					/>
				)}
			/>
			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={classes.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	foucsed: boolean;
	rotate: boolean;
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: (p: StyleProps) => ({
		width: '100%',
		backgroundColor: p.foucsed ? '#F6F6F6' : colors.white,
		padding: '6px 0px',
	}),
	dropdownContainer: {
		boxShadow: 'none',
		transform: 'translateY(6px)',
		border: `1px solid ${colors.borderColor}`,
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	error: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.error,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	menuItem: {
		padding: 0,
		width: ERROR_TYPE_WIDTH,
	},
	rotate: (p: StyleProps) => ({
		transform: p.rotate ? 'rotate(180deg)' : `rotate(0deg) translateY(${p.disabled ? 5 : 2}px)`,
		transition: 'all 0.5s',
	}),
	dropdownArrowWrapper: {
		height: 'fit-content',
		padding: '5px 12px',
		alignItems: 'center',
		display: 'flex',
	},
}));

export default TableAutoCompleteInput;
