import { TableCellType } from '../components/table/StandardTableCell';
import { ContractFilterInterface } from '../service/types/Contract';
import { TableParamInterface } from './rhenusOverview';

interface ContractParamInterface extends ContractFilterInterface {
	contractNumber: TableParamInterface;
	customer: TableParamInterface | undefined;
	date: TableParamInterface;
	billed_until: TableParamInterface;
	next_billing: TableParamInterface;
	workLocation: TableParamInterface;
	partDesignation: TableParamInterface;
	markingOfIOparts: TableParamInterface;
	shiftTimes: TableParamInterface;
	errorTypes: TableParamInterface;
	typesOfInspection: TableParamInterface;
	assignedQAIs: TableParamInterface | undefined;
	assignedTeamLeader: TableParamInterface | undefined;
}

export const contractListParams: ContractParamInterface = {
	contractNumber: {
		width: '18.5vw',
		title: 'contract_overview_screen_contract_number',
		type: TableCellType.Sticky,
		titleType: TableCellType.Title,
		sticky: true,
		sortable: true,
	},
	customer: {
		width: '10.5vw',
		title: 'contract_overview_screen_contract_customer',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	billed_until: {
		width: '9.035vw',
		title: 'admin_contracts_screen_billed_until',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	next_billing: {
		width: '9.035vw',
		title: 'admin_contracts_screen_next_billing_on',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	date: {
		width: '9.035vw',
		title: 'contract_overview_screen_contract_date',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	workLocation: {
		width: '12.96vw',
		title: 'contract_overview_screen_contract_work_location',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	partDesignation: {
		width: '26.711vw',
		title: 'contract_overview_screen_contract_part_designation',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	markingOfIOparts: {
		width: '19.875vw',
		title: 'contract_overview_screen_contract_mark_of_io_parts',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	shiftTimes: {
		width: '25.71vw',
		title: 'contract_overview_screen_contract_shift_times',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	errorTypes: {
		width: '11.45vw',
		title: 'contract_overview_screen_contract_error_types',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	typesOfInspection: {
		width: '19.29vw',
		title: 'contract_overview_screen_contract_types_of_inspection',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		sortable: true,
	},
	assignedQAIs: {
		width: '25.71vw',
		title: 'contract_overview_screen_contract_assigned_qais',
		type: TableCellType.Expandable,
		titleType: TableCellType.Title,
	},
	assignedTeamLeader: {
		width: '15.71vw',
		title: 'contract_overview_screen_contract_team_leader',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
};
