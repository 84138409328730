import React from 'react';

import { userListParams } from '../consts/user';
import { UserFilterKeys } from '../containers/UserOverviewContainer';
import { dataSorter } from '../helpers/dataSorter';
import {
	filterOriginOfEmployeeKeyValuePairs,
	filterUserRoleKeyValuePairs,
} from '../helpers/userHelper/generalUserHelper';
import User, { UserFilterInterface } from '../service/types/User';
import { Sort } from './ContractList';
import CustomTableBody from './CustomTableBody';

interface Props {
	users: User[];
	filters: UserFilterInterface;
	onEditPress: (user: User) => void;
	onFilterChange: (key: UserFilterKeys, value: any) => void;
}

function UserList(props: Props) {
	const [sort, setSort] = React.useState<Sort>({
		field: undefined,
		direction: undefined,
	});

	const transformedUsers = React.useMemo(() => {
		return props.users.map((user) => ({
			...user,
			role: filterUserRoleKeyValuePairs.find((role) => user.role === role.value)?.key,
			originOfEmployee: filterOriginOfEmployeeKeyValuePairs.find(
				(origin) => user.originOfEmployee === origin.value
			)?.key,
		}));
	}, [props.users]);

	const filtersOptions: Partial<UserFilterInterface> = React.useMemo(
		() => ({
			role: filterUserRoleKeyValuePairs,
			originOfEmployee: filterOriginOfEmployeeKeyValuePairs,
		}),
		[]
	);

	const sortedData = React.useMemo(() => {
		if (sort.field === undefined) return transformedUsers;
		return dataSorter({ data: transformedUsers, sort });
	}, [sort, transformedUsers]);

	return (
		<CustomTableBody
			data={sortedData}
			displaySort
			dataTablePramas={userListParams}
			tableKey={'User'}
			onLinkClick={props.onEditPress}
			onFilterChange={props.onFilterChange}
			filtersOptions={filtersOptions}
			filters={props.filters}
			sort={sort}
			setSort={setSort}
		/>
	);
}

export default UserList;
