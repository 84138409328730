import * as React from 'react';

function PhotoIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={46}
			height={41}
			viewBox="0 0 46 41"
			xmlns="http://www.w3.org/2000/svg"
			style={{ left: 28, top: 30, position: 'absolute' }}
			{...props}>
			<g
				transform="translate(1 1)"
				stroke="#111"
				strokeWidth={2}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="square">
				<path d="M4 39h36a4 4 0 004-4V11a4 4 0 00-4-4h-7l-4-7H17l-4 7H4a4 4 0 00-4 4v24a4 4 0 004 4z" />
				<circle cx={23} cy={23} r={10} />
				<circle cx={7} cy={14} r={1} />
			</g>
		</svg>
	);
}

export default PhotoIcon;
