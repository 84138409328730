import { Service } from '../../service/service';
import Customer from '../../service/types/Customer';
import { goToCustomers } from '../navigationHelper';
import { History, LocationState } from 'history';
import { NotificationInterface } from '../../context/notification/notificationReducer';

export function deleteCustomer(
	history: History<LocationState>,
	service: Service,
	customer: Customer | undefined,
	setNotification: (notification: NotificationInterface) => void
) {
	if (customer)
		service
			.deleteCustomerByID(customer.id)
			.then((res) => {
				if (res) {
					goToCustomers(history);
					setNotification({ text: 'customer_screen_delete_notification', additional: customer.name });
				}
			})
			.catch(() => {});
}
