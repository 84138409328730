import React from 'react';
import ErrorScreen from '../screens/ErrorScreen';

class ErrorWrapper extends React.Component {
	state: {
		hasError: boolean;
	};
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: any, info: any) {
		// Display fallback UI

		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorScreen />;
		}
		return this.props.children;
	}
}

export default ErrorWrapper;
