import { makeStyles } from '@material-ui/core';
import React from 'react';

import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import BackKey from '../svgComponents/BackKey';

interface Props {
	keyValue: string;
	onClick: (val: string) => void;
}

function Key(props: Props) {
	const classes = useStyles({ key: props.keyValue.length === 0 });
	return (
		<div className={classes.container} onClick={() => props.onClick(props.keyValue)}>
			{props.keyValue === 'back' ? <BackKey /> : <span>{props.keyValue}</span>}
		</div>
	);
}

interface StyleProps {
	key: boolean;
}

const useStyles = makeStyles(() => ({
	container: (p: StyleProps) => ({
		display: 'flex',
		marginBottom: '24px',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		backgroundColor: colors.white,
		boxShadow: '0 4px 8px -4px rgba(26,26,26,0.2)',
		cursor: p.key ? 'auto' : 'pointer',
	}),
	text: {
		fontSize: '20px',
		lineHeight: '32px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
	},
}));

export default Key;
