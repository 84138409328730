import { addExpenseFields } from '../../components/AddExpensesItem';
import service from '../../service/service';
import MaterialTool from '../../service/types/MaterialTool';
import Transport from '../../service/types/Transport';
import Travel from '../../service/types/Travel';
import { ExpensesKeys } from './generalExpensesHelper';

export async function updateExpensesData(
	key: ExpensesKeys,
	contractID: number,
	expense: MaterialTool | Travel | Transport
) {
	try {
		if (key === 'materials' && expense instanceof MaterialTool) {
			return await service.updateMaterialExpenses(contractID, expense);
		} else if (key === 'tools' && expense instanceof MaterialTool) {
			return await service.updateToolExpenses(contractID, expense);
		} else if (expense instanceof Travel) {
			return await service.updateTravelExpenses(contractID, expense);
		} else if (expense instanceof Transport) {
			return await service.updateTransportExpenses(contractID, expense);
		} else {
			return Promise.resolve(false);
		}
	} catch (e) {
		return false;
	}
}

export function getUpdateExpense(key: ExpensesKeys, expense: MaterialTool | Travel | Transport, data: any) {
	if (key === 'materials') {
		return new MaterialTool(
			expense.id,
			data[addExpenseFields.name],
			Number(data[addExpenseFields.quantity]),
			expense.reporter,
			data[addExpenseFields.qai].value.id,
			data[addExpenseFields.date],
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : expense.billed,
			data[addExpenseFields.price]
		);
	} else if (key === 'tools') {
		return new MaterialTool(
			expense.id,
			data[addExpenseFields.name],
			Number(data[addExpenseFields.quantity]),
			expense.reporter,
			data[addExpenseFields.qai].value.id,
			data[addExpenseFields.date],
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : expense.billed,
			data[addExpenseFields.price]
		);
	} else if (key === 'travels') {
		return new Travel(
			expense.id,
			data[addExpenseFields.travelTime],
			data[addExpenseFields.distance].value.id,
			expense.reporter,
			data[addExpenseFields.qai].value.id,
			data[addExpenseFields.date],
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : expense.billed,
			data[addExpenseFields.waitedTime],
			data[addExpenseFields.typeOfTransport].value
		);
	} else {
		return new Transport(
			expense.id,
			data[addExpenseFields.type].value,
			expense.reporter,
			data[addExpenseFields.qai].value.id,
			data[addExpenseFields.date],
			data[addExpenseFields.billed] !== undefined ? data[addExpenseFields.billed].value : expense.billed
		);
	}
}
