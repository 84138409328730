import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { colors } from '../consts/colors';
import BackArrow from '../svgComponents/BackArrow';
import StandardTextField from '../components/StandardTextField';

import { UserRole } from '../service/types/User';
import { useGlobalStyles } from '../consts/globalStyles';
import { useAuthStateContext } from '../context/auth/useAuth';

interface Props {
	onChangePasswordPress: () => void;
}

const DEFAULT_QAI_PASSWORD = '****';

function SettingsScreen(props: Props) {
	const globalClasses = useGlobalStyles({});
	const classes = useStyles({});
	const { t } = useTranslation();
	const { user } = useAuthStateContext();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
			</div>
			<Grid container>
				<Grid item sm={1} />

				<Grid item sm={9}>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t('account_settings_screen_title')}</span>
					</div>
					<Grid container spacing={4} direction="row">
						<Grid item sm={6}>
							<StandardTextField
								disabled={true}
								label={t('account_settings_screen_name')}
								value={`${user?.firstName} ${user?.lastName}`}
							/>
						</Grid>
						<Grid item sm={6}>
							{user?.role === UserRole.QualityAssuranceInspector ? (
								<StandardTextField
									labelAddOn={
										<span
											className={globalClasses.labelAddOn}
											onClick={props.onChangePasswordPress}>
											{t('account_settings_screen_change_pin')}
										</span>
									}
									disabled={true}
									value={DEFAULT_QAI_PASSWORD}
									label={t('account_settings_screen_pin')}
									type="password"
								/>
							) : (
								<StandardTextField
									labelAddOn={
										<span
											className={globalClasses.labelAddOn}
											onClick={props.onChangePasswordPress}>
											{t('account_settings_screen_change_password')}
										</span>
									}
									disabled={true}
									value={'********'}
									label={t('account_settings_screen_password')}
									type="password"
								/>
							)}
						</Grid>
					</Grid>
					<Grid container spacing={4} direction="row">
						<Grid item sm={6}>
							<StandardTextField
								disabled={true}
								label={t('account_settings_screen_email')}
								value={user?.email}
							/>
						</Grid>
						{user?.role === UserRole.QualityAssuranceInspector ? (
							<Grid item sm={6}>
								<StandardTextField
									disabled={true}
									label={t('account_settings_screen_abbrevation')}
									value={`${user.username}`}
								/>
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '36px',
	},
	imageWrapper: {
		marginRight: '25px',
	},
	image: {
		width: '110px',
		height: '110px',
		borderRadius: '55px',
		backgroundColor: colors.gray,
	},
	rowWrapper: {
		display: 'flex',
		marginBottom: '52px',
		alignItems: 'center',
	},
}));

export default SettingsScreen;
