import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { v4 as uuidv4 } from 'uuid';

import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import DropdownArrow from '../svgComponents/DropdownArrow';
import { Chip, CircularProgress, MenuItem } from '@material-ui/core';
import { Autocomplete, createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../svgComponents/ErrorIcon';
import CheckboxFilled from '../svgComponents/CheckboxFilled';
import CheckboxEmpty from '../svgComponents/CheckboxEmpty';
import BarcodeScannerIcon from '../svgComponents/BarcodeScannerIcon';

const filter = createFilterOptions<string>();

export interface StandardAutoCompleteRef {
	setLoading: (val: boolean) => void;
	getLoading: () => boolean;
}

export const TextFieldWithCSS = withStyles({
	root: {
		'& .MuiAutocomplete-popupIndicatorOpen': {
			transform: 'rotate(0deg)',
		},
		'& .MuiAutocomplete-popupIndicator': {
			marginRight: '2px',
		},
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'white',
		},
		'& .Mui-disabled': {
			color: colors.gray,
		},
		'& .MuiAutocomplete-inputRoot': {
			paddingLeft: '12px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-input': {
			...globalInputStyle(),
			borderRadius: '5px',
			paddingLeft: '12px',
		},
		'& .MuiInput-input.Mui-disabled': {
			...globalInputStyle(),
			color: colors.lightGray,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
		'& .MuiInput-underline:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
	},
})(TextField);

interface Props {
	label: string;
	options: string[];
	onChange: (event: any) => void;
	withOutAddOption?: boolean;
	error?: boolean;
	helperText?: string;
	multiple?: boolean;
	onOpen?: () => void;
	onScanClick?: () => void;
	freeSolo?: boolean;
	removeCloseIcon?: boolean;
	autoSelect?: boolean;
	disabled?: boolean;
	fixed?: boolean;
	value?: string | string[];
	labelAddOn?: JSX.Element;
	zoneName?: string;
	withCheckbox?: boolean;
	placeholder?: string;
	disableCloseOnSelect?: boolean;
	noOptionText?: string;
}

function StandardAutoComplete(props: Props, ref: any) {
	const { t } = useTranslation();
	const [rotateArrow, setRotateArrow] = React.useState(false);
	const [options, setOptions] = React.useState(props.options);
	const [loading, setLoading] = React.useState(false);
	const [hasOptions, setHasOptions] = React.useState(true);
	const globalClasses = useGlobalStyles();
	const zoneNameRef = React.useRef<any>();
	const [rerender, setRerender] = React.useState<string>(uuidv4().toString());
	const [focused, setFocused] = React.useState(false);
	const [limitTags, setLimitTags] = React.useState(10);
	const autoCompleteRef = React.useRef<any>(null);

	const [open, setOpen] = React.useState(false);

	const classes = useAutocompleteStyles({
		removeCloseIcon: props.removeCloseIcon,
		rotate: rotateArrow,
		disabled: props.disabled,
		fixed: props.fixed,
		hasOptions: hasOptions,
		focused: focused,
		withCheckbox: props.withCheckbox,
		freeSolo: props.freeSolo,
	});

	React.useEffect(() => {
		if (props.zoneName) {
			setTimeout(() => {
				setRerender(uuidv4().toString());
			}, 20);
		}
	}, [props.zoneName]);

	const filterOptions = React.useMemo(
		() => (options: string[], params: FilterOptionsState<string>) => {
			const filterOptions =
				props.value && typeof props.value === 'string'
					? options.filter((option) => option.toLowerCase().includes((props.value as string).toLowerCase()))
					: options;
			const filtered = filter(filterOptions, params);

			if (
				props.multiple &&
				params.inputValue !== '' &&
				filtered.length === 0 &&
				!props.withOutAddOption &&
				params.inputValue.trim() !== ''
			) {
				filtered.push(`Add "${params.inputValue.trim()}"`);
			}
			if (filtered.length === 0 && !loading && props.freeSolo) {
				setHasOptions(false);
			} else {
				setHasOptions(true);
			}
			return filtered;
		},

		[props.multiple, props.value, loading, props.freeSolo, props.withOutAddOption]
	);

	React.useImperativeHandle(
		ref,
		(): StandardAutoCompleteRef => ({
			setLoading: (val: boolean) => setLoading(val),
			getLoading: () => loading,
		})
	);
	React.useEffect(() => {
		setOptions((opt) => {
			if (opt.length === 0) return props.options;

			if (
				opt.some((item) =>
					props.options.length === 0
						? true
						: props.options.reduce((prev, curr) => {
								if (prev) return prev;
								return curr === item;
						  }, false)
				) &&
				props.withOutAddOption
			) {
				return props.options;
			}
			return opt;
		});
	}, [props.options, props.withOutAddOption]);

	React.useEffect(() => {
		if (props.multiple) {
			if (autoCompleteRef.current && 0 > 40 - autoCompleteRef.current.offsetHeight) {
				setLimitTags((val) => (val === 10 ? (props.value as string[]).length - 1 : val));
			}
		}
	}, [props.value, props.multiple]);

	return (
		<div>
			<div
				className={classes.labelWrapper}
				key={rerender}
				style={{ transform: `translateX(${zoneNameRef.current ? zoneNameRef.current.offsetWidth : 0}px)` }}>
				<span className={classes.label}>{props.label}</span>
				{props.labelAddOn ? props.labelAddOn : null}
			</div>
			<Autocomplete
				disabled={props.disabled}
				options={options}
				limitTags={limitTags}
				value={props.value ? props.value : props.multiple ? [] : ''}
				classes={{
					inputRoot: classes.inputRoot,
					paper: classes.dropdownContainer,
					option: classes.optionClass,
					clearIndicator: classes.clearIndicator,
					listbox: classes.listBox,
				}}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => (
						<Chip
							label={t(option)}
							{...getTagProps({ index })}
							className={classes.chipHeight}
							classes={{ deleteIcon: classes.deleteIcon }}
						/>
					))
				}
				autoSelect={props.autoSelect}
				ref={autoCompleteRef}
				openText=""
				open={open}
				closeText=""
				getLimitTagsText={(val) => (
					<span className={globalClasses.smallText}>{`+${val} ${props.placeholder}`}</span>
				)}
				disableCloseOnSelect={props.disableCloseOnSelect}
				freeSolo={props.freeSolo}
				noOptionsText={t(props.noOptionText ? props.noOptionText : 'autocomplete_no_options')}
				loadingText={t('autocomplete_loading')}
				onClose={() => {
					setOpen(false);
					setRotateArrow(false);
				}}
				onOpen={() => {
					setOpen(true);
					setRotateArrow(true);
					if (props.onOpen) props.onOpen();
				}}
				popupIcon={
					!props.freeSolo ? (
						<div className={classes.dropdownArrowWrapper}>
							<DropdownArrow className={classes.rotate} disabled={props.disabled} />
						</div>
					) : undefined
				}
				multiple={props.multiple}
				filterOptions={filterOptions}
				onChange={(_, newValue) => {
					if (props.multiple) {
						const tempNewValue = (newValue as string[]).map((item, index, array) => {
							if (index + 1 === array.length) {
								return item.replace('Add "', '').replace('"', '').trim();
							}
							return item;
						});
						if (
							tempNewValue &&
							tempNewValue.length !== 0 &&
							!options.some((value) => tempNewValue[tempNewValue.length - 1] === value)
						) {
							setOptions([...options, tempNewValue[tempNewValue.length - 1]]);
						}
						if (tempNewValue.length === 0) {
							props.onChange(undefined);
						} else {
							props.onChange(tempNewValue);
						}
					} else {
						props.onChange(newValue);
					}
				}}
				getOptionLabel={(option) => t(option)}
				renderOption={(option) => (
					<MenuItem key={option} value={option} className={classes.menuItem}>
						<div className={classes.dropDownInputWrapper}>
							{props.withCheckbox ? (
								props.value &&
								props.multiple &&
								(props.value as string[]).some((item: string) => item === option) ? (
									<div className={classes.checkboxWrapper}>
										<CheckboxFilled />
									</div>
								) : (
									<div className={classes.checkboxWrapper}>
										<CheckboxEmpty />
									</div>
								)
							) : null}
							<span className={classes.dropdownInput}>{t(option)}</span>
						</div>
					</MenuItem>
				)}
				loading={loading}
				renderInput={(params) => (
					<div className={classes.inputRow}>
						{props.zoneName ? (
							<div ref={zoneNameRef} className={classes.zoneWrapper}>
								<span className={globalClasses.label}>{`${props.zoneName.toUpperCase()}:`}</span>
							</div>
						) : null}
						<TextFieldWithCSS
							{...params}
							InputProps={{
								...params.InputProps,
								classes: {
									input: classes.input,
								},
								onFocus: () => setFocused(true),
								onBlur: () => setFocused(false),

								endAdornment: (
									<div className={classes.endAdormentWrapper}>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{props.freeSolo ? (
											<div
												className={classes.dropdownArrowWrapper}
												onClick={() => {
													setOpen((prev) => {
														return prev ? false : true;
													});
													setRotateArrow((prev) => {
														return prev ? false : true;
													});
												}}>
												<DropdownArrow className={classes.rotate} disabled={props.disabled} />
											</div>
										) : null}
										{props.onScanClick ? (
											<div className={classes.barcode}>
												<BarcodeScannerIcon
													onClick={() => (!props.disabled ? props.onScanClick!() : {})}
												/>
											</div>
										) : null}
										{params.InputProps.endAdornment}
									</div>
								),
							}}
							onChange={props.freeSolo ? (e) => props.onChange(e) : () => {}}
							label={undefined}
							error={props.error}
							helperText={''}
							className={classes.inputWrapper}
						/>
					</div>
				)}
			/>
			{props.error ? (
				<div
					className={classes.row}
					style={{ transform: `translateX(${zoneNameRef.current ? zoneNameRef.current.offsetWidth : 0}px)` }}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={classes.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	removeCloseIcon?: boolean;
	disabled?: boolean;
	fixed?: boolean;
	focused: boolean;
	hasOptions: boolean;
	rotate: boolean;
	withCheckbox?: boolean;
	freeSolo?: boolean;
}

export const useAutocompleteStyles = makeStyles(() => ({
	barcode: (p: StyleProps) => ({
		backgroundColor: colors.someYellow,
		margin: '2px 8px 2px 0px ',
		height: 26,
		width: 28,
		borderRadius: 13,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		cursor: p.disabled ? 'auto' : 'pointer',
	}),
	label: (p: StyleProps) => ({
		color: p.disabled && !p.fixed ? colors.lightGray : colors.gray,
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
	}),
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre-line',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: (p: StyleProps) => ({
		width: '100%',
		borderRadius: '4px',
		backgroundColor: p.fixed ? colors.lightGray : colors.white,
		border: `1px solid ${p.focused && !p.disabled ? colors.darkGray : '#C0C0C0'}`,
	}),
	dropdownContainer: (p: StyleProps) => ({
		boxShadow: 'none',
		// maxHeight: '160px',
		// overflow: 'scroll',
		border: `${p.hasOptions ? 1 : 0}px solid ${colors.borderColor}`,
	}),
	dropdownArrowWrapper: (p: StyleProps) => ({
		height: 'fit-content',
		padding: '5px 12px',
		transform: 'translateY(0px)',
		alignItems: 'center',
		display: 'flex',
		//change pointer events
		pointerEvents: p.freeSolo ? 'unset' : 'none',
	}),
	dropdownMenuWrapper: {
		height: '1.1876em',
	},

	rotate: (p: StyleProps) => ({
		transform: p.rotate ? 'rotate(180deg)' : `rotate(0deg) translateY(${p.disabled ? 5 : 2}px)`,
		transition: 'all 0.5s',
		cursor: 'pointer',
	}),
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	error: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.error,
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	menuItem: {
		padding: 0,
	},
	dropdownInput: {
		margin: 0,
		color: colors.darkGray,
		fontSize: '16px',
		lineHeight: '24px',
		fontFamily: fonts.regular,
		whiteSpace: 'pre-wrap',
	},
	dropDownInputWrapper: {
		display: 'flex',
		alignItems: 'center',
		transform: 'translateY(-2px)',
	},
	zoneWrapper: { paddingRight: '15px' },
	inputRow: { display: 'flex', alignItems: 'center' },
	chipHeight: {
		height: '26px',
		margin: '3px',
		backgroundColor: colors.someYellow,
	},
	checkboxWrapper: {
		marginRight: '10px',
		display: 'flex',
		alignItems: 'center',
	},
	input: (p: StyleProps) => ({
		...globalInputStyle(p.disabled),
		whiteSpace: 'pre',
		color: p.fixed ? colors.lightGray : colors.darkGray,
		borderRadius: '5px',
		paddingLeft: '12px',
	}),
	optionClass: (p: StyleProps) => ({
		'&[aria-selected=true]': p.withCheckbox
			? {
					backgroundColor: colors.white,
			  }
			: {},
		'&[aria-selected=true]:hover': p.withCheckbox
			? {
					backgroundColor: 'rgba(0,0,0,0.04)',
			  }
			: {},
	}),
	deleteIcon: {
		color: colors.darkGray,
	},
	inputRoot: (p: StyleProps) => ({
		paddingRight: p.removeCloseIcon ? '0px !important' : '30px',
	}),
	clearIndicator: (p: StyleProps) => ({
		display: p.removeCloseIcon ? 'none' : 'inline-flex',
	}),
	listBox: {
		overflow: 'hidden',
		maxHeight: 'unset',
	},
	endAdormentWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default React.forwardRef(StandardAutoComplete);
