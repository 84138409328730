import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalStyles } from '../consts/globalStyles';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';
import Button from './Button';
import StandardDialog, { StandardDialogInterface } from './StandardDialog';

interface Props {
	data: any | undefined;
	isUserEditing: boolean;
	editButtonText: string;
	updateButtonText: string;
	createButtonText: string;
	createStartNewButtonText?: string;
	childrenElement?: JSX.Element;
	cancelButtonText: string;
	deleteButtonText: string;
	canDelete?: boolean;
	onSubmitPress: () => void;
	onSubmitStartNewPress?: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	removeEditUpdateCreateBtn?: boolean;
}

function StandardCRUDButtons({
	data,
	isUserEditing,
	editButtonText,
	updateButtonText,
	createButtonText,
	createStartNewButtonText,
	cancelButtonText,
	deleteButtonText,
	canDelete,
	childrenElement,
	removeEditUpdateCreateBtn,
	onSubmitPress,
	onSubmitStartNewPress,
	onCancelPress,
	onDeletePress,
}: Props) {
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles();
	const { user } = useAuthStateContext();
	const classes = useStyles();
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);

	const onAcceptDialog = () => {
		onCancelPress();
		dialogRef.current?.setDialogState(false);
	};

	const onCancelDialog = () => dialogRef.current?.setDialogState(false);

	const allowedToDelete = user?.role === UserRole.Administrator || canDelete || user?.role === UserRole.Accounting;

	return (
		<div className={classes.row}>
			{!removeEditUpdateCreateBtn ? (
				<Button
					text={data ? (isUserEditing ? t(updateButtonText) : t(editButtonText)) : t(createButtonText)}
					textClassName={globalClasses.buttonText}
					buttonWrapperClassName={globalClasses.buttonWrapper}
					onClick={onSubmitPress}
				/>
			) : null}

			{!isUserEditing && childrenElement ? childrenElement : null}

			{isUserEditing ? (
				<Button
					text={t(cancelButtonText)}
					textClassName={globalClasses.buttonTextInverted}
					buttonWrapperClassName={globalClasses.buttonWrapperInverted}
					onClick={() => dialogRef.current?.setDialogState(true)}
				/>
			) : allowedToDelete ? (
				<div className={globalClasses.deleteLinkWrapper} onClick={onDeletePress}>
					<span className={globalClasses.deleteLink}>{t(deleteButtonText)}</span>
				</div>
			) : null}

			{createStartNewButtonText && onSubmitStartNewPress && isUserEditing && !data && (
				<Button
					renderAsButton
					text={t(createStartNewButtonText)}
					textClassName={globalClasses.buttonTextInverted}
					buttonWrapperClassName={globalClasses.buttonWrapperSecondary}
					onClick={onSubmitStartNewPress}
				/>
			)}

			<StandardDialog
				onAccept={onAcceptDialog}
				onCancle={onCancelDialog}
				acceptText={t('warning_data_dialog_accept_button')}
				title={t('warning_data_dialog_title')}
				description={t('warning_data_dialog_description')}
				cancleText={t('warning_data_dialog_cancel_button')}
				ref={dialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default StandardCRUDButtons;
