import jwt_decode from 'jwt-decode';
import { JWT_TOKEN_PARAMS } from '../../helpers/userHelper/generalUserHelper';
import { GroupUser } from '../../pages/groupOverview/GroupOverview';

export enum UserRole {
	// Administrator
	Administrator = 0,
	// Teamleiter
	TeamLeader = 1,
	// Prüfer
	QualityAssuranceInspector = 2,
	// Kunde
	Customer = 3,
	// Buchhaltung
	Accounting = 4,
}

export enum OriginOfEmployee {
	FromIQR = 1,
	External = 2,
}

export interface CreateEmployeeParams {
	email: string;
	password: string;
	role: UserRole;
	first_name: string;
	last_name: string;
	origin: OriginOfEmployee;
	tl?: number[];
	phonenumber?: string;
	username?: string;
	tlGroupsIds?: number[] | null;
	contactFor?: number[] | null;
}

export interface UpdateEmployeeParams {
	email: string;
	password?: string;
	role: UserRole;
	first_name: string;
	last_name: string;
	origin: OriginOfEmployee;
	tl?: number[];
	phonenumber?: string;
	username?: string;
	tlGroupsIds?: number[] | null;
	contactFor?: number[] | null;
}

export interface UserFilterInterface {
	role: any;
	firstName: any;
	lastName: any;
	originOfEmployee: any;
}

export interface UserTableInterface extends UserFilterInterface {}

export interface IdNamePair {
	id: string;
	name: string;
}

class User implements UserFilterInterface {
	public id: number;
	public email: string;
	public teamLeaderIDs?: number[] | undefined;
	tlGroups?: GroupUser[];
	role: UserRole;
	contactFor?: number[];
	firstName: string;
	lastName: string;
	originOfEmployee: OriginOfEmployee;
	deleted: boolean;
	phonenumber?: string | undefined;
	username?: string | undefined;

	constructor(
		id: number,
		email: string,
		role: UserRole,
		firstName: string,
		lastName: string,
		originOfEmployee: OriginOfEmployee,
		teamLeaderIDs?: number[] | undefined,
		tlGroups?: GroupUser[],
		contactFor?: number[],
		phonenumber?: string | undefined,
		username?: string | undefined,
		deleted?: boolean
	) {
		this.id = id;
		this.email = email.trim();
		this.role = role;
		this.firstName = firstName.trim();
		this.lastName = lastName.trim();
		this.originOfEmployee = originOfEmployee;
		this.teamLeaderIDs = teamLeaderIDs;
		this.tlGroups = tlGroups;
		this.contactFor = contactFor;
		this.phonenumber = phonenumber ? phonenumber.trim() : undefined;
		this.username = username ? username.trim() : undefined;
		this.deleted = deleted || false;
	}

	public static fromJWT(token: string): User {
		const decodedToken: JWT_TOKEN_PARAMS = jwt_decode(token);

		return new User(
			0,
			decodedToken.sub,
			decodedToken.role,
			'',
			'',
			OriginOfEmployee.FromIQR,
			[],
			[],
			undefined,
			'',
			''
		);
	}

	public static fromJSON(maybe: any): User {
		if (typeof maybe.id !== 'number') {
			throw new Error('id must be number');
		}

		if (typeof maybe.role !== 'number') {
			throw new Error('roler must be number');
		}

		if (typeof maybe.email !== 'string') {
			throw new Error('email must be string');
		}

		if (typeof maybe.first_name !== 'string') {
			throw new Error('firstName must be string');
		}

		if (typeof maybe.last_name !== 'string') {
			throw new Error('lastName must be string');
		}

		if (typeof maybe.origin !== 'number') {
			throw new Error('origin must be number');
		}

		return new User(
			maybe.id,
			maybe.email.includes('@') ? maybe.email : '',
			maybe.role,
			maybe.first_name,
			maybe.last_name,
			maybe.origin,
			maybe.tl,
			maybe.tlGroups,
			maybe.contactFor,
			maybe.phonenumber,
			maybe.username,
			maybe.deleted
		);
	}

	public static fromCustomer(maybe: any): User {
		if (typeof maybe.id !== 'number') {
			throw new Error('id must be number');
		}

		if (typeof maybe.email !== 'string') {
			throw new Error('email must be string');
		}

		if (typeof maybe.first_name !== 'string') {
			throw new Error('firstName must be string');
		}

		if (typeof maybe.last_name !== 'string') {
			throw new Error('lastName must be string');
		}

		return new User(
			maybe.id,
			maybe.email,
			UserRole.Customer,
			maybe.first_name,
			maybe.last_name,
			OriginOfEmployee.External,
			maybe.tl,
			maybe.tlGroups,
			maybe.contactFor,
			maybe.phonenumber,
			maybe.username,
			maybe.deleted
		);
	}

	public static fromUserUpdate(maybe: any): User {
		return new User(
			maybe.id,
			maybe.email,
			maybe.role,
			maybe.firstName,
			maybe.lastName,
			maybe.originOfEmployee,
			maybe.teamLeader?.map((tl: any) => tl?.value?.id),
			maybe.tlGroups,
			maybe.contactFor,
			maybe.phonenumber,
			maybe.username,
			maybe.deleted
		);
	}

	public static toJSON(user: User) {
		return {
			id: user.id,
			email: user.email,
			role: user.role,
			first_name: user.firstName,
			last_name: user.lastName,
			origin: user.originOfEmployee,
			tlGroupsIds: user.tlGroups || [],
			contactFor: user.contactFor || [],
			tl: user.teamLeaderIDs || [],
			username: user.username,
			phonenumber: user.phonenumber,
			deleted: user.deleted,
		};
	}
}

export default User;
