import { makeStyles } from '@material-ui/core';
import React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { colors } from '../consts/colors';
import { fonts } from '../consts/fonts';
import { useAuthActionContext, useAuthStateContext } from '../context/auth/useAuth';
import { useWorkingTimeActionContext } from '../context/workingTime/useWorkingTime';
import { onAuthTokenError } from '../helpers/authHelper';
import { Routes } from '../router/Routes';
import service from '../service/service';
import { UserRole } from '../service/types/User';
import UserIcon from '../svgComponents/UserIcon';
import StandardDialog, { StandardDialogInterface } from './StandardDialog';
import { userAtom } from '../atoms/userAtom';
import { useAtom } from 'jotai';

function AvatarCard() {
	const { user } = useAuthStateContext();
	const { setUser } = useAuthActionContext();
	const { setRecord } = useWorkingTimeActionContext();
	const { t } = useTranslation();
	const classes = useStyles({});
	const standardDialogRef = React.useRef<StandardDialogInterface | undefined>();
	const [, setUserAtom] = useAtom(userAtom);

	const openDialog = () => {
		if (standardDialogRef.current) standardDialogRef.current.setDialogState(true);
	};

	const logout = () =>
		service
			.logout()
			.then((res) => {
				if (res) {
					unstable_batchedUpdates(() => {
						onAuthTokenError(setUser);
						setRecord(undefined);
						setUserAtom(undefined);
					});
				}
			})
			.catch(() => {});

	return (
		<div className={classes.container}>
			<div className={classes.infoWrapper}>
				<div className={classes.imageWrapper}>
					<UserIcon />
				</div>
				<div>
					<div>
						<span className={classes.name}>{`${user?.firstName} ${user?.lastName}`}</span>
					</div>
					<div className={classes.usernameWrapper}>
						<span className={classes.username}>{user?.email}</span>
					</div>
					<div className={classes.settingsWrapper}>
						<Link to={Routes.Settings} className={classes.settings}>
							{t('avatar_card_settings')}
						</Link>
					</div>
				</div>
			</div>
			<div className={classes.underline} />
			<div
				className={classes.logoutWrapper}
				onClick={() => {
					if (user?.role === UserRole.QualityAssuranceInspector) {
						openDialog();
					} else {
						logout();
					}
				}}>
				<span className={classes.logout}>{t('avatar_card_logout')}</span>
			</div>
			<StandardDialog
				ref={standardDialogRef}
				title={'avatar_card_dialog_title'}
				description={'avatar_card_dialog_description'}
				acceptText={'avatar_card_dialog_accept'}
				onAccept={logout}
				showWarning
			/>
		</div>
	);
}
const useStyles = makeStyles(() => ({
	container: {
		marginRight: '5px',
		marginTop: '5px',
		marginLeft: 'auto',
		width: 'fit-content',
		padding: '0 24px',
		boxShadow: '0 2px 10px -4px rgba(26,26,26,0.2)',
		borderRadius: '4px',
		backgroundColor: colors.white,
	},
	logoutWrapper: {
		width: 'fit-content',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingBottom: '16px',
		marginTop: '16px',
		cursor: 'pointer',
	},
	logout: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
		textDecoration: 'underline',
	},
	underline: {
		height: '1px',
		width: '100%',
		backgroundColor: '#EDEDED',
	},
	image: {
		height: '60px',
		width: '60px',
		borderRadius: '30px',
		backgroundColor: colors.gray,
	},
	imageWrapper: {
		marginRight: '12px',
	},
	infoWrapper: {
		display: 'flex',
		paddingTop: '20px',
		paddingBottom: '24px',
	},
	settings: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
		textDecoration: 'none',
	},
	usernameWrapper: {
		marginBottom: '8px',
	},
	username: {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: fonts.regular,
		color: colors.gray,
	},
	name: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.bold,
		color: colors.darkGray,
	},
	settingsWrapper: {
		width: 'fit-content',
		cursor: 'pointer',
	},
}));

export default AvatarCard;
