import * as React from 'react';

function DropdownIcon(props: any) {
	return (
		<svg width={12} height={12} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<path d="M8.5 4.5L6 7.5l-2.5-3" />
				<circle cx={6} cy={6} r={5.5} />
			</g>
		</svg>
	);
}

export default DropdownIcon;
