import * as React from 'react';

function DeleteIcon(props: any) {
	return (
		<svg width={18} height={18} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="#111" strokeWidth={1.455} fill="none" fillRule="evenodd" strokeLinecap="square">
				<path d="M14.818 6.818v8.727c0 .804-.651 1.455-1.454 1.455H4.636a1.455 1.455 0 01-1.454-1.455V6.818M1 3.909h16M6.09 3.91V1h5.82v2.91M11.182 8.989l-4.364 4.364M11.182 13.353L6.818 8.989" />
			</g>
		</svg>
	);
}

export default DeleteIcon;
