import { Expenses } from '../helpers/expenses/generalExpensesHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { IContractWorkLocation } from '../service/types/Contract';
import User from '../service/types/User';
import { materialTabelParams } from './material';
import { toolTabelParams } from './tools';
import { transportTabelParams } from './transport';
import { travelTabelParams } from './travel';

export const expensesTabelParams = (
	t: (value: string) => string,
	users: KeyValue[],
	user: User | undefined,
	workLocations: IContractWorkLocation[]
): Expenses => ({
	materials: materialTabelParams(users, user, t),
	tools: toolTabelParams(users, user, t),
	travels: travelTabelParams(t, users, user, workLocations),
	transport: transportTabelParams(users, user),
	invoice: null,
});
