import { makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../consts/colors';

import { useGlobalStyles } from '../consts/globalStyles';

interface Props {
	name: string;
	onPress: () => void;
}

function CustomerQAIZoneItem(props: Props) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();

	return (
		<div className={classes.wrapper} onClick={props.onPress}>
			<div className={classes.nameWrapper}>
				<span className={globalClasses.bigText}>{props.name}</span>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	nameWrapper: {
		padding: '28px 0px 28px 40px',
	},
	wrapper: {
		cursor: 'pointer',
		backgroundColor: colors.someYellow,
		width: '100%',
	},
}));

export default CustomerQAIZoneItem;
