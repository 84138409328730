import { userFields } from '../../containers/UserContainer';
import { Service } from '../../service/service';
import User, { IdNamePair, UpdateEmployeeParams } from '../../service/types/User';
import { History, LocationState } from 'history';
import { replaceUserHistoryData } from '../navigationHelper';
import { KeyValue } from './generalUserHelper';
import { Group, GroupUser } from '../../pages/groupOverview/GroupOverview';

export function updateUser(
	data: any,
	service: Service,
	user: User | undefined,
	history: History<LocationState>,
	setUser: (user: User | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	setGroups: (groups: Group[]) => void
) {
	if (user) {
		const updatedUser = getUpdatedUser(user, data) as UpdateEmployeeParams;
		service
			.updateUserByID(user.id, {
				...(updatedUser as UpdateEmployeeParams),
				password: data[userFields.password],
			})
			.then((res) => {
				if (res) {
					// This will force new fetch on groups screen
					setGroups([]);

					setIsUserEditing(false);
					setUser(User.fromUserUpdate({ ...data, id: user.id }));
					replaceUserHistoryData(history, { ...data, id: user.id });
				}
			})
			.catch(() => {});
	}
}

function getUpdatedUser(user: User, data: any) {
	return {
		id: user.id,
		email: data[userFields.email].trim(),
		role: data[userFields.role],
		first_name: data[userFields.firstName].trim(),
		last_name: data[userFields.lastName].trim(),
		origin: data[userFields.originOfEmployee],
		tl:
			userFields.teamLeader in data && data[userFields.teamLeader].length !== 0
				? data[userFields.teamLeader].map((item: KeyValue) => item.value.id)
				: undefined,
		tlGroupsIds:
			userFields.tlGroups in data && data[userFields.tlGroups].length > 0
				? data[userFields.tlGroups]?.map((el: GroupUser) => el.id)
				: [],
		phonenumber: userFields.phonenumber in data ? data[userFields.phonenumber] : undefined,
		username: userFields.username in data ? data[userFields.username].trim() : undefined,
		contactFor:
			userFields.contactFor in data && data[userFields.contactFor].length > 0
				? data[userFields.contactFor].map((el: IdNamePair) => el.id)
				: [],
	};
}
