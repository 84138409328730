import moment from 'moment';
import { RecordTimeType } from '../../service/types/BreakTime';
import { DEFAULT_NO_VALUE } from '../standardInputHelper/generalStandardInputHelper';

const SECOND = 1000;

export const addWorkingTimeFields = {
	qai: 'qai',
	from: 'from',
	to: 'to',
	date: 'date',
	billed: 'billed',
	type: 'type',
	duration: 'duration',
	profession: 'profession',
};

export const addBreakTimeFields = {
	qai: 'qai',
	date: 'date',
	duration: 'duration',
};

export interface WorkingTimeFilterInterface {
	qai: any;
	reporter: any;
	toDate: any;
	fromDate: any;
	billed: any;
	type: any;
}

export interface ExportWorkingTimeInterface {
	ExportMaterials: boolean;
	ExportTools: boolean;
	ExportTravels: boolean;
	ExportTransport: boolean;
	Anonymous: boolean;
}

export const exportWorkingTimeFields = {
	ExportMaterials: 'ExportMaterials',
	ExportTools: 'ExportTools',
	ExportTravels: 'ExportTravels',
	ExportTransport: 'ExportTransport',
	Anonymous: 'Anonymous',
};

export const workingTimeFields = {
	qai: 'qai',
	reporter: 'reporter',
	toDate: 'toDate',
	fromDate: 'fromDate',
	billed: 'billed',
	type: 'type',
};

export const initWorkingTimeFilters = {
	qai: '',
	reporter: '',
	toDate: null,
	fromDate: null,
	billed: '',
	type: '',
};

const data = Object.freeze({ ...initWorkingTimeFilters });
export type WorkingTimeFilterKeys = keyof typeof data;

export const filterWorkingTimeKeyValuePairs = [
	DEFAULT_NO_VALUE,
	{ key: 'Not billed', value: 0 },
	{ key: 'Billed', value: 1 },
];

export const createWorkingTimeKeyValuePairs = [
	{ key: 'Not billed', value: false },
	{ key: 'Billed', value: true },
];

export const createRecordTimeKeyValuePairs = [
	{ key: 'working_time_screen_add_working_time', value: RecordTimeType.Working },
	{ key: 'working_time_screen_add_break_time', value: RecordTimeType.Break },
	{ key: 'working_time_screen_add_waiting_time', value: RecordTimeType.Waiting },
];

export const filterRecordTimeTypeKeyValuePairs = [
	DEFAULT_NO_VALUE,
	{ key: 'working_time_screen_working_time', value: RecordTimeType.Working },
	{ key: 'working_time_screen_break_time', value: RecordTimeType.Break },
	{ key: 'working_time_screen_waiting_time', value: RecordTimeType.Waiting },
];

export const timeFormat = 'HH:mm';
export const initTimeValue = moment().set('h', 0).set('m', 0);

const padNumber = (num: number) => String(num).padStart(2, '0');

export function timeFromSeconds(_seconds: number) {
	const leadingSign = _seconds < 0 ? '-' : '';
	const hours = Math.floor(_seconds / 3600);
	const minutes = Math.floor((_seconds / 60) % 60);
	const seconds = Math.floor(_seconds % 60);

	return `${leadingSign}${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
}

export function timeFromUnixToStringFormat(unixTime: number) {
	const isNegative = unixTime < 0 ? '-' : '';
	const unixTimeAbs = Math.abs(unixTime);
	const hours = Math.floor(unixTimeAbs / 60 / 60);
	const minutes = Math.floor((unixTimeAbs / 60) % 60);
	const seconds = Math.floor((unixTimeAbs % 60) % 60);

	return `${isNegative}${hours < 10 ? '0' + hours : hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
}

export function calculateTimeDifference(from: string, to: string) {
	const momentFromTime = moment(from);
	const momentToTime = moment(to);

	if (momentFromTime.format(timeFormat + ':ss') === momentToTime.format(timeFormat + ':ss')) {
		return 0;
	} else if (momentFromTime.isBefore(momentToTime)) {
		return Math.abs(momentToTime.diff(momentFromTime)) / 1000;
	} else {
		return Math.abs(momentFromTime.diff(momentToTime.add(1, 'd'))) / 1000;
	}
}

export function updateWorkTime(
	time: number,
	timer: React.MutableRefObject<NodeJS.Timeout>,
	setWorkingTime: (timeString: string) => void
) {
	setWorkingTime(timeFromUnixToStringFormat(time / 1000));
	return setTimeout(() => {
		timer.current = updateWorkTime(time + SECOND, timer, setWorkingTime);
	}, SECOND);
}
