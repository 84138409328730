import * as React from 'react';

function UsersIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={150}
			height={150}
			viewBox="0 0 150 150"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<defs>
				<filter x="-5.6%" y="-2.8%" width="111.3%" height="111.3%" filterUnits="objectBoundingBox" id="user1">
					<feMorphology radius={2} in="SourceAlpha" result="shadowSpreadOuter1" />
					<feOffset dy={4} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0.2 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<rect id="user2" x={0} y={0} width={142} height={142} rx={4} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(4)">
					<use fill="#000" filter="url(#user1)" xlinkHref="#user2" />
					<use fill="#FFF" xlinkHref="#user2" />
				</g>
				<path fill="#FFD500" opacity={0.2} d="M146 0L4 143V0z" />
				<g stroke="#111" strokeLinecap="square" strokeWidth={2.5}>
					<path d="M98.39 107.92h19.902V94.711a5.686 5.686 0 00-3.573-5.28l-16.186-6.476a2.843 2.843 0 01-1.764-2.287l-.665-5.323a15.637 15.637 0 009.394-14.336V47.824a8.53 8.53 0 00-8.529-8.529l-2.207-4.416a1.648 1.648 0 00-1.972-.792l-10.528 3.758a10.804 10.804 0 00-3.186 1.841M86.288 92.275l-16.186-6.477a2.843 2.843 0 01-1.764-2.287l-.657-5.25a24.918 24.918 0 0015.073-.193 38.872 38.872 0 01-5.686-19.902v-1.421c0-8.636-7.001-15.637-15.637-15.637s-15.637 7-15.637 15.637v1.421a38.872 38.872 0 01-5.686 19.902 24.918 24.918 0 0015.08.193l-.657 5.25a2.843 2.843 0 01-1.765 2.287l-16.192 6.477A5.686 5.686 0 0033 97.555v10.365h56.862V97.554a5.686 5.686 0 00-3.574-5.28z" />
				</g>
			</g>
		</svg>
	);
}

export default UsersIcon;
