import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Cell, LabelList, Legend, Pie, PieChart } from 'recharts';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';
import AdminTLZoneOverview from '../components/AdminTLZoneOverview';
import Button from '../components/Button';
import Filter from '../components/Filter';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import RhenusCaseList from '../components/RhenusCaseList';
import StandardAutoComplete from '../components/StandardAutoComplete';
import StandardCaseInputList from '../components/StandardCaseInputList';
import StandardDatePicker from '../components/StandardDatePicker';
import StandardFileInput from '../components/StandardFileInput';
import StandardTextField from '../components/StandardTextField';
import SimpleDataTable from '../components/table/SimpleDataTable';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { ContractType } from '../helpers/contractHelper/generalContractHelper';
import { renderActiveShape, renderCustomLegend } from '../helpers/dashboardHelper/chartHelper';
import {
	chartColors,
	DashboardCaseFilterKeys,
	DashboardFilterInterface,
	dashboardInputFields,
	getChartData,
	getTotalPartsTested,
	initDashboardFilters,
} from '../helpers/dashboardHelper/generalDashboardHelper';
import { checkIfUserStopWriting } from '../helpers/filters';
import { DEFAULT_NO_VALUE } from '../helpers/standardInputHelper/generalStandardInputHelper';
import { Routes } from '../router/Routes';
import Contract, { ContractSummaryInterface } from '../service/types/Contract';
import Customer from '../service/types/Customer';
import RhenusCase from '../service/types/RhenusCase';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import StandardCaseInput from '../service/types/StandardCase';
import User from '../service/types/User';
import AttachmentIcon from '../svgComponents/AttachmentIcon';
import BackIcon from '../svgComponents/BackIcon';
import GoBack from '../svgComponents/GoBack';
import HomeButton from '../svgComponents/HomeButton';
import PdfAttachmentIcon from '../svgComponents/PdfAttachmentIcon';

interface Props {
	intervalDates: IntervalDatePickerDates;
	contract: Contract;
	contractSummary: ContractSummaryInterface | undefined;
	customer: Customer | undefined;
	teamLeader: User | undefined;
	contactQAI24: User | undefined;
	errors: StandardCaseInput[] | RhenusOverviewInformation[];
	filters: DashboardFilterInterface;
	currentZone: number;
	zoneInformations: { [key: string]: RhenusCase[] };
	partNumberOptions: string[];
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
	clearAllFilters: () => void;
	onFilterChange: (key: DashboardCaseFilterKeys, value: any) => void;
	onZoneOverviewPress: (item: RhenusOverviewInformation) => void;
	onBackIconPress: () => void;
	downloadTable: () => void;
	user: User | undefined;
}

const MAX_TABLE_HEIGHT = '640px';

function DashboardScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const width = useWindowWidth();
	const height = useWindowHeight();
	const classes = useStyles({});
	const [activeIndex, setActiveIndex] = React.useState<number>(0);
	const globalClasses = useGlobalStyles({ showFilters });
	const { t } = useTranslation();
	const filterListRef = React.useRef<any>(null);
	const searchRef = React.useRef<DashboardFilterInterface>({ ...initDashboardFilters });
	const onFilterPress = () => setShowFilters((prev) => !prev);
	const { errors, control } = useFormContext();
	const chartData = React.useMemo(
		() =>
			getChartData(
				props.currentZone ? props.zoneInformations[props.currentZone] : (props.errors as StandardCaseInput[])
			),
		[props.errors, props.currentZone, props.zoneInformations]
	);
	const totalData = React.useMemo(
		() =>
			getTotalPartsTested(
				props.currentZone ? props.zoneInformations[props.currentZone] : (props.errors as StandardCaseInput[])
			),
		[props.errors, props.currentZone, props.zoneInformations]
	);
	const onPieMouseEnter = (_: any, index: number) => setActiveIndex(index);

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack route={Routes.CustomerContracts} />
				<HomeButton />
			</div>
			<div className={classes.contractNumberWrapper}>
				<span className={globalClasses.smallText}>{`${t('dashboard_screen_contract_number')}: `}</span>
				<span className={globalClasses.smallTextDark}>{`${props.contract.contractNumber}`}</span>
			</div>
			<div className={classes.row}>
				<div>
					<span className={globalClasses.title}>{t('dashboard_screen_title')}</span>
				</div>
			</div>
			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.subtitle}>{t('dashboard_screen_subtitle')}</span>
			</div>
			<Grid container>
				<Grid item sm={2}>
					<StandardDatePicker
						label={t('dashboard_screen_date_of_creation').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						isUserNotEditing={false}
						value={moment(props.contract.date)}
					/>
				</Grid>

				<Grid item sm={7} />
				<Grid item sm={2}>
					<StandardFileInput
						label={'dashboard_screen_upload_pdf_label'}
						name={'dashboard_screen_no_pdf'}
						value={props.contract.contractData}
						icon={<PdfAttachmentIcon className={classes.iconWrappper} />}
						multiple={false}
						disabled={true}
						onChange={() => {}}
					/>
				</Grid>
			</Grid>
			<Grid container className={classes.wrapper}>
				<Grid item sm={5}>
					<StandardTextField
						label={t('dashboard_screen_customer_company').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						value={props.customer?.name}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<StandardTextField
						label={t('dashboard_screen_contact_person').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						value={props.contract.contactPerson.map((el) => el.name).join(', ')}
					/>
				</Grid>
				{/* <Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<StandardTextField
						label={t('dashboard_screen_work_location').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						value={props.contract.workLocation}
					/>
				</Grid> */}
				<Grid item sm={1} />
				<Grid item sm={5} />
			</Grid>
			<div className={globalClasses.underline} />
			<Grid container className={classes.wrapper}>
				<Grid item sm={5}>
					<StandardTextField
						label={t('dashboard_screen_contact_admin').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						value={`${props.teamLeader?.firstName} ${props.teamLeader?.lastName}`}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<StandardTextField
						label={t('dashboard_screen_contact_qai_24').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						value={`${props.contactQAI24?.firstName} ${props.contactQAI24?.lastName}`}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<StandardTextField
						label={t('dashboard_screen_teamleader_phone_number').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						value={`${
							props.teamLeader && props.teamLeader.phonenumber ? props.teamLeader.phonenumber : ''
						}`}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<StandardFileInput
						label={'dashboard_screen_other_attachments'}
						name={'dashboard_screen_current_attachments'}
						value={props.contract.attachments}
						icon={<AttachmentIcon className={classes.iconWrappper} />}
						multiple={true}
						disabled={true}
						margin={10}
						onChange={() => {}}
					/>
				</Grid>
			</Grid>
			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.subtitle}>{t('dashboard_screen_errors')}</span>
			</div>
			<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />
			<Button
				text={t('dashboard_screen_export_table')}
				textClassName={globalClasses.buttonText}
				buttonWrapperClassName={classes.buttonWrapper}
				onClick={props.downloadTable}
			/>
			<div
				className={globalClasses.filterWrapper}
				style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
				<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<Controller
							defaultValue={''}
							control={control}
							name={dashboardInputFields.partNumber}
							render={({ onChange, value }) => (
								<StandardAutoComplete
									value={value}
									freeSolo
									autoSelect
									removeCloseIcon
									label={t('standard_case_overview_screen_part_number')}
									onChange={(e) => {
										onChange(e);
										checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'partNumber');
									}}
									options={props.partNumberOptions}
									multiple={false}
								/>
							)}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<IntervalDatePicker
							id="start"
							error={errors['fromDate']}
							helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
							value={props.filters.fromDate}
							label={t('dashboard_screen_from_date')}
							dates={props.intervalDates}
							clearable={true}
							onKeyboardDateChange={props.onKeyboardDateChange}
							onDateChange={props.onDateChange}
							onDatesAccept={props.onDatesAccept}
							onDatesCancel={props.onDatesCancel}
							clearDates={props.clearDates}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<IntervalDatePicker
							id="end"
							error={errors['toDate']}
							helperText={errors['toDate'] ? errors['toDate'].message : ''}
							value={props.filters.toDate}
							label={t('dashboard_screen_to_date')}
							dates={props.intervalDates}
							clearable={true}
							onKeyboardDateChange={props.onKeyboardDateChange}
							onDateChange={props.onDateChange}
							onDatesAccept={props.onDatesAccept}
							onDatesCancel={props.onDatesCancel}
							clearDates={props.clearDates}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('dashboard_screen_type_of_inspection')}
							select
							value={props.filters.typeOfInspection}
							onChange={(e) => props.onFilterChange('typeOfInspection', e.target.value)}>
							{[' ', ...props.contract.typesOfInspection].map((item) => (
								<MenuItem key={item} value={item}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t(item)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('dashboard_screen_shift')}
							select
							value={props.filters.shift}
							onChange={(e) => {
								props.onFilterChange('shift', e.target.value);
							}}>
							{[
								DEFAULT_NO_VALUE,
								...props.contract.shiftTimes.map((shift) => ({
									key: shift.name,
									value: shift.id,
								})),
							].map((item) => (
								<MenuItem key={item.key} value={item.value}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t(item.key)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					</Grid>
				</Grid>
			</div>
			<div className={classes.bottomWrapper}>
				{props.contract.contractType === ContractType.Standard ? (
					<StandardCaseInputList
						displaySort
						onApprovePress={() => {}}
						filters={props.filters as any}
						shiftTimes={props.contract.shiftTimes}
						dashboard={true}
						photo={props.contract.isPhotoAllowed}
						automatedUpdates={props.contract.automatedUpdates}
						typesOfInspections={[' ', ...props.contract.typesOfInspection]}
						onFilterChange={props.onFilterChange as any}
						allAssignedQAIs={[]}
						standardCaseInputs={props.errors as StandardCaseInput[]}
						onViewPress={() => {}}
						maxHeight={MAX_TABLE_HEIGHT}
					/>
				) : props.currentZone === 0 ? (
					<AdminTLZoneOverview
						informations={props.errors as RhenusOverviewInformation[]}
						onZonePress={props.onZoneOverviewPress}
					/>
				) : (
					<div>
						<div className={classes.zoneRow}>
							<BackIcon className={classes.backIcon} onClick={props.onBackIconPress} />
							<div className={classes.zoneSubtitle}>
								<span className={globalClasses.subtitle}>
									{
										(props.errors as RhenusOverviewInformation[]).find(
											(error) => error.zone.id === props.currentZone
										)?.zone.name
									}
								</span>
							</div>
						</div>

						<RhenusCaseList
							allAssignedQAIs={[]}
							automatedUpdates={props.contract.automatedUpdates}
							filters={props.filters as any}
							onApprovePress={() => {}}
							typesOfInspections={[' ', ...props.contract.typesOfInspection]}
							errorTypes={[' ', ...props.contract.errorTypes]}
							amountOfParts={
								props.contract.amountOfParts ? [' ', ...props.contract.amountOfParts] : [' ']
							}
							shiftTimes={props.contract.shiftTimes}
							onFilterChange={props.onFilterChange as any}
							dashboard={true}
							onViewPress={() => {}}
							maxHeight={MAX_TABLE_HEIGHT}
							photo={props.contract.isPhotoAllowed}
							rhenusCaseInputs={props.zoneInformations[props.currentZone] as RhenusCase[]}
						/>
					</div>
				)}
			</div>
			<div className={classes.chartContainer}>
				{props.contactQAI24 &&
				props.errors.length !== 0 &&
				props.customer &&
				props.teamLeader &&
				((props.currentZone !== 0 && props.zoneInformations[props.currentZone].length !== 0) ||
					props.contract.contractType === ContractType.Standard) ? (
					<PieChart width={width - 64} height={height * 0.8}>
						<Legend verticalAlign="middle" align="left" layout="vertical" content={renderCustomLegend(t)} />
						<Pie
							data={chartData}
							dataKey="value"
							activeShape={renderActiveShape(totalData, t)}
							activeIndex={activeIndex}
							cx={width / 1.9}
							cy={height * 0.4}
							startAngle={270}
							endAngle={-90}
							innerRadius={height * 0.2}
							outerRadius={height * 0.3}
							onMouseEnter={onPieMouseEnter}
							offset={10}>
							{chartData.map((_, index) => (
								<Cell key={`slice-${index}`} fill={chartColors[index]} />
							))}

							<LabelList
								position="inside"
								fill="white"
								stroke="white"
								dataKey="value"
								//@ts-ignore
								formatter={(val: string) => {
									return ((Number(val) / totalData) * 100).toFixed(2) + '%';
								}}
							/>
						</Pie>
					</PieChart>
				) : null}
			</div>
			{props.contractSummary && (
				<div className={classes.tableWrapper}>
					<SimpleDataTable contractSummary={props.contractSummary} t={t} />
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '8px 22px',
		cursor: 'pointer',
		marginTop: '15px',
	},
	contractNumberWrapper: {
		marginTop: '3px',
	},
	subtitleWrapper: {
		margin: '20px 0px 16px',
	},
	zoneSubtitle: {
		marginLeft: '16px',
	},
	row: {
		display: 'flex',
	},
	zoneRow: {
		display: 'flex',
		alignItems: 'center',
		margin: '30px 0px 16px',
	},
	fieldWrapper: {
		marginTop: '32px',
	},
	iconWrappper: {
		marginRight: '12px',
	},
	wrapper: {
		marginTop: '40px',
		marginBottom: '43px',
	},
	bottomWrapper: {
		marginBottom: '50px',
	},
	input: {
		...globalInputStyle(),
		whiteSpace: 'pre',
	},
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	chartContainer: {
		backgroundColor: 'white',
		boxShadow: '0 4px 8px -4px rgba(26,26,26,0.2)',
	},
	backIcon: {
		backgroundColor: colors.yellow,
		padding: '10px 6px',
		borderRadius: '21px',
		cursor: 'pointer',
	},
	tableWrapper: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'white',
		boxShadow: '0 4px 8px -4px rgba(26,26,26,0.2)',
		padding: '20px 0',
	},
}));

export default DashboardScreen;
