import * as React from 'react';

function Plus(props: any) {
	return (
		<svg width={12} height={12} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g
				stroke="#111"
				strokeWidth={2}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				strokeLinejoin="round">
				<path d="M6 1v10M1 6h10" />
			</g>
		</svg>
	);
}

export default Plus;
