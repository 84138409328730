import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../consts/globalStyles';
import WorkingTime from '../service/types/WorkingTime';
import { timeFromUnixToStringFormat } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { RECORD_LIST_PARAMS } from './RecordListSummary';
import BreakTime, { RecordTimeType } from '../service/types/BreakTime';

interface Props {
	records: (WorkingTime | BreakTime)[];
	timeTitle: string;
}
function RecordTitleSummary({ records, timeTitle }: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	const sumTime = records.reduce((prev, curr) => {
		if (curr instanceof WorkingTime) {
			return prev + curr.duration;
		}
		if (curr.type === RecordTimeType.Break) {
			return prev - curr.duration;
		}
		return prev;
	}, 0);

	return (
		<div className={classes.row}>
			<div className={classes.rangeWrapper}>
				<span className={globalClasses.mediumTextSemiDark}>{timeTitle}</span>
			</div>
			<div className={classes.timeWrapper}>
				<div className={classes.timeTitleWrapper}>
					<span className={globalClasses.smallerTextLight}>{'Total: '}</span>
				</div>
				<div>
					<span className={globalClasses.mediumTextSemiDark}>{timeFromUnixToStringFormat(sumTime)}</span>
				</div>
			</div>
			<div className={classes.reporterWrapper}>
				<span className={globalClasses.mediumTextSemiDark}>{t('Reporter')}</span>
			</div>

			<div className={classes.statusWrapper}>
				<span className={globalClasses.mediumTextSemiDark}>{t('Status')}</span>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		marginTop: '6px',
	},
	rangeWrapper: {
		width: RECORD_LIST_PARAMS.RANGE,
	},
	timeWrapper: {
		width: RECORD_LIST_PARAMS.TIME,
		display: 'flex',
		alignItems: 'center',
	},
	reporterWrapper: {
		width: RECORD_LIST_PARAMS.REPORTER,
	},
	timeTitleWrapper: {
		display: 'flex',
		marginRight: '12px',
		alignItems: 'center',
	},
	statusWrapper: {
		width: RECORD_LIST_PARAMS.STATUS,
	},
}));

export default RecordTitleSummary;
