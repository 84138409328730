import * as React from 'react';

function AttachmentIcon(props: any) {
	return (
		<svg width={14} height={22} viewBox="0 0 14 22" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.818 3.727v11.364A5.897 5.897 0 016.91 21 5.897 5.897 0 011 15.09v-10A4.074 4.074 0 015.09 1a4.074 4.074 0 014.092 4.09v9.092c0 1.273-1 2.273-2.273 2.273a2.25 2.25 0 01-2.273-2.273V6.455"
				stroke="#111"
				strokeWidth={1.5}
				fill="none"
				fillRule="evenodd"
				strokeLinecap="square"
			/>
		</svg>
	);
}

export default AttachmentIcon;
