import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Controller, Control } from 'react-hook-form';

import Button from '../components/Button';
import AuthTextField from '../components/AuthTextField';
import { resetPasswordFields } from '../containers/ResetPasswordContainer';
import { useGlobalStyles } from '../consts/globalStyles';
import { PasswordResetInterface } from '../consts/login';
import IQRLogo from '../svgComponents/IQRLogo';

interface Props {
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	dataForResetPassword: PasswordResetInterface;
	onResetPasswordPress: () => void;
	getValues: (field: string) => string;
}

function ResetPasswordScreen(props: Props) {
	const classes = useStyles({});
	const globalClassess = useGlobalStyles({});
	const { t } = useTranslation();

	return (
		<div className={classes.wrappper}>
			<IQRLogo className={globalClassess.logo} />
			<Grid container className={globalClassess.container}>
				<Grid item sm={1} />
				<Grid item sm={4}>
					<div className={classes.titleWrapper}>
						<span className={globalClassess.title}>{t('resest_password_screen_title')}</span>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={resetPasswordFields.newPassword}
							rules={props.dataForResetPassword.rules(t).password}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('resest_password_screen_new_password')}
									error={props.errors[resetPasswordFields.newPassword] ? true : false}
									helperText={
										props.errors[resetPasswordFields.newPassword]
											? props.errors[resetPasswordFields.newPassword].message
											: ''
									}
									type="password"
									onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === 'Enter') {
											props.onResetPasswordPress();
										}
									}}
								/>
							)}
						/>
					</div>
					<div className={classes.emailWrapper}>
						<Controller
							defaultValue={''}
							control={props.control}
							name={resetPasswordFields.repeatPassword}
							rules={{
								...props.dataForResetPassword.rules(t).password,
								//@ts-ignore
								validate: (val) => {
									if (props.getValues(resetPasswordFields.newPassword) === val) return true;
									else return t('resest_password_screen_password_error');
								},
							}}
							render={({ onChange }) => (
								<AuthTextField
									onChange={onChange}
									label={t('resest_password_screen_repeat_password')}
									error={props.errors[resetPasswordFields.repeatPassword] ? true : false}
									helperText={
										props.errors[resetPasswordFields.repeatPassword]
											? props.errors[resetPasswordFields.repeatPassword].message
											: ''
									}
									type="password"
									onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
										if (e.key === 'Enter') {
											props.onResetPasswordPress();
										}
									}}
								/>
							)}
						/>
					</div>
					<div className={classes.buttonWrapper}>
						<Button
							onClick={props.onResetPasswordPress}
							text={t('resest_password_screen_button')}
							textClassName={globalClassess.buttonText}
							buttonWrapperClassName={globalClassess.buttonWrapper}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	wrappper: {
		marginTop: '108px',
	},
	buttonWrapper: {
		marginTop: '40px',
	},
	titleWrapper: {
		marginBottom: '20px',
	},
	emailWrapper: {
		marginBottom: '20px',
	},
}));

export default ResetPasswordScreen;
