import { DEFAULT_NO_VALUE } from '../standardInputHelper/generalStandardInputHelper';
import { KeyValue } from '../userHelper/generalUserHelper';

export enum ContractType {
	Standard = 1,
	RhenusType = 2,
}
export enum ContractStatusType {
	Closed = 0,
	NotClosed = 1,
}

export enum ErrorType {
	Missing = 'Missing',
	Incorrect = 'Incorrect',
	Damaged = 'Damaged',
	Other = 'Other',
}

export enum ContractViewType {
	Process,
	Admin,
	Customer,
}

export enum TypeOfInspection {
	StandardCheck = 'Standard check',
	Erstteilkontrolle = 'Erstteilkontrolle',
	PVBCount = 'PVB Count',
	Umpacken = 'Umpacken',
	Funktionsprufung = 'Funktionsprüfung',
	Sichtprufung = 'Sichtprüfung',
	NacharbeitenEinfachesWerkzeug = 'Nacharbeiten (einfaches Werkzeug)',
	NacharbeitenSpezialwerkzeug = 'Nacharbeiten (Spezialwerkzeug)',
}

export interface ContractZone {
	id: number;
	name: string;
}

export function ContractZoneFromJSON(maybe: any): ContractZone {
	if (!maybe) {
		throw new Error('zone must be exist');
	}
	if (typeof maybe.id !== 'number') {
		throw new Error('id must be number');
	}
	if (typeof maybe.name !== 'string') {
		throw new Error('name must be string');
	}
	return {
		id: maybe.id,
		name: maybe.name,
	};
}

export function ContractZoneToJSON(zone: ContractZone) {
	return {
		id: zone.id,
		name: zone.name,
	};
}

export interface ShiftInterface {
	id?: number;
	name: string;
	from: string;
	to: string;
}

export enum ShiftName {
	Morning = 'Morning shift',
	Afternoon = 'Afternoon shift',
	Night = 'Night shift',
}

export function ShiftFromJSON(maybe: any): ShiftInterface {
	if (typeof maybe.name !== 'string') {
		throw new Error('name must be string');
	}
	if (typeof maybe.shift_start !== 'string') {
		throw new Error('shift_start must be string');
	}
	if (typeof maybe.shift_end !== 'string') {
		throw new Error('shift_end must be string');
	}
	return {
		id: maybe.id,
		name: maybe.name.replaceAll('"', ''),
		from: maybe.shift_start,
		to: maybe.shift_end,
	};
}

export function ShiftToJSON(shift: ShiftInterface) {
	return {
		name: shift.name.replaceAll('"', ''),
		shift_start: shift.from,
		shift_end: shift.to,
	};
}

export const createContractIsPhotoKeyValuePairs: KeyValue[] = [
	{ key: 'contract_allowed', value: true },
	{ key: 'contract_not_allowed', value: false },
];

export const createContractErrorTypeKeyValuePairs: KeyValue[] = [
	{ key: 'contract_error_missing', value: ErrorType.Missing },
	{ key: 'contract_error_incorrect', value: ErrorType.Incorrect },
	{ key: 'contract_error_damaged', value: ErrorType.Damaged },
];

export const createContractTypeOfInspectionKeyValuePairs: KeyValue[] = [
	{ key: 'contract_type_of_inspection_standard', value: TypeOfInspection.StandardCheck },
	{ key: 'contract_type_of_inspection_erstteilkontrolle', value: TypeOfInspection.Erstteilkontrolle },
	{ key: 'contract_type_of_inspection_pvb_count', value: TypeOfInspection.PVBCount },
	{ key: 'contract_type_of_inspection_umpacken', value: TypeOfInspection.Umpacken },
	{ key: 'contract_type_of_inspection_funktionsprugun', value: TypeOfInspection.Funktionsprufung },
	{ key: 'contract_type_of_inspection_sichtprufung', value: TypeOfInspection.Sichtprufung },
	{
		key: 'contract_type_of_inspection_nacharbeiten_einfaches',
		value: TypeOfInspection.NacharbeitenEinfachesWerkzeug,
	},
	{ key: 'contract_type_of_inspection_nacharbeiten_spez', value: TypeOfInspection.NacharbeitenSpezialwerkzeug },
];

export const createContractShiftKeyValuePairs: KeyValue[] = [
	{ key: 'contract_screen_shift_name_placeholder', value: 'contract_screen_shift_name_placeholder' },
	{ key: 'Morning shift', value: ShiftName.Morning },
	{ key: 'Afternoon shift', value: ShiftName.Afternoon },
	{ key: 'Night shift', value: ShiftName.Night },
];

export const filterContractDataKeyValuePairs: KeyValue[] = [
	DEFAULT_NO_VALUE,
	{ key: 'contract_overview_screen_have_attachment', value: true },
	{ key: 'contract_overview_screen_dont_have_attachment', value: false },
];

export const filterContractData: KeyValue[] = [
	{ key: 'contract_overview_screen_have_attachment', value: true },
	{ key: 'contract_overview_screen_dont_have_attachment', value: false },
];

export const filterContractStatusKeyValuePairs: KeyValue[] = [
	DEFAULT_NO_VALUE,
	{ key: 'contract_screen_opened', value: ContractStatusType.NotClosed },
	{ key: 'contract_screen_closed', value: ContractStatusType.Closed },
];

export const filterOpenClosed: KeyValue[] = [
	{ key: 'contract_screen_closed', value: ContractStatusType.Closed },
	{ key: 'contract_screen_opened', value: ContractStatusType.NotClosed },
];

export interface FileInterface {
	data: string;
	name: string;
}
