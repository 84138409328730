import moment from 'moment';
import { ShiftInterface } from './contractHelper/generalContractHelper';
import { KeyValue } from './userHelper/generalUserHelper';
import { customerOverviewFields } from './customerHelper/generalCustomerHelper';
import Customer from '../service/types/Customer';

export function filterData(filters: any, data: any) {
	const filterKeys = Object.keys({ ...filters });

	if (filterKeys.some((key) => filters[key] !== '')) {
		return filterKeys.reduce((prev, curr) => {
			if (filters[curr] !== null && filters[curr] !== '') {
				return prev.filter((item: any) => {
					if (!item[curr] && Object.keys(customerOverviewFields).some((key) => key === curr)) {
						return (
							(item as Customer).billingAddressGenerated.includes(filters[curr]) ||
							(item as Customer).deliveryAddressGenerated.includes(filters[curr])
						);
					}

					if (typeof item[curr] === 'boolean') {
						if (filters[curr]) return item[curr];
						else return !item[curr];
					}
					//key contractData
					if (curr === 'contractData') {
						if (item[curr] && item[curr].name) {
							return filters[curr];
						} else return !filters[curr];
					}
					//TYPE STRING
					if (typeof item[curr] === 'string') {
						return item[curr].toLowerCase().includes(filters[curr].toLowerCase());
					}
					//key FROM DATE
					else if (curr === 'fromDate') {
						return (
							moment(item.fromDate || item.date)
								.format('DD.MM.YYYY')
								.toLowerCase()
								.includes(moment(filters[curr]).format('DD.MM.YYYY')) ||
							item?.date?.isAfter(moment(filters[curr])) ||
							item?.fromDate?.isAfter(filters[curr])
						);
					}
					//key TO DATE
					else if (curr === 'toDate') {
						return (
							moment(item.date)
								.format('DD.MM.YYYY')
								.toLowerCase()
								.includes(moment(filters[curr]).format('DD.MM.YYYY')) ||
							item?.date?.isBefore(moment(filters[curr]) || item?.toDate?.isBefore(moment(filters[curr])))
						);
					}
					//TYPE DATE
					else if (item[curr].format) {
						return item[curr]
							.format('DD.MM.YYYY')
							.toLowerCase()
							.includes(moment(filters[curr]).format('DD.MM.YYYY'));
					}
					//TYPE  item[curr]: number && filter[curr]: KEYVALUE
					else if (typeof item[curr] === 'number' && filters[curr].length && filters[curr][0].key) {
						return filters[curr].some((filter: KeyValue) => item[curr] === filter.value.id);
					}
					//TYPE NUMBER
					else if (typeof item[curr] === 'number') {
						return item[curr].toString().toLowerCase().includes(filters[curr].toString().toLowerCase());
					}
					//TYPE [] item[curr]: KEYVALUE
					else if (item[curr].length && item[curr][0].key) {
						return item[curr].some((val: KeyValue) =>
							val.key.toLowerCase().includes(filters[curr].toLowerCase())
						);
					}
					//TYPE KEYVALUE
					else if (item[curr].key) {
						return item[curr].key.toLowerCase().includes(filters[curr].toLowerCase());
					}
					//TYPE [] item[curr]: ShiftInterface[]
					else if (item[curr].length && item[curr][0].name) {
						return item[curr].some((val: ShiftInterface) =>
							val.name.toLowerCase().includes(filters[curr].toLowerCase())
						);
					}
					//TYPE ShiftInterface
					else if (item[curr].name) {
						return item[curr].name.toLowerCase().includes(filters[curr].toLowerCase());
					}
					//TYPE STRING[]
					else if (item[curr].length) {
						return item[curr]
							.reduce((prev: string, curr: string) => {
								return prev + curr + '';
							}, '')
							.toLowerCase()
							.includes(filters[curr].toLowerCase());
					} else {
						return item[curr] === filters[curr];
					}
				});
			}

			return prev;
		}, data);
	} else {
		return data;
	}
}

export function checkIfUserStopWriting(
	e: any,
	onChange: (key: any, value: any) => void,
	ref: React.MutableRefObject<any>,
	key: string
) {
	const value = e ? (e.target ? e.target.value : e) : '';
	ref.current[key] = value;
	setTimeout(() => {
		if (ref.current[key] === value) {
			onChange(key, value);
		}
	}, 500);
}
