import * as React from 'react';

function ContractsIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={150}
			height={150}
			viewBox="0 0 150 150"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<defs>
				<filter
					x="-5.6%"
					y="-2.8%"
					width="111.3%"
					height="111.3%"
					filterUnits="objectBoundingBox"
					id="contract1">
					<feMorphology radius={2} in="SourceAlpha" result="shadowSpreadOuter1" />
					<feOffset dy={4} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0.2 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<rect id="contract2" x={0} y={0} width={142} height={142} rx={4} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(4)">
					<use fill="#000" filter="url(#contract1)" xlinkHref="#contract2" />
					<use fill="#FFF" xlinkHref="#contract2" />
				</g>
				<path fill="#FFD500" opacity={0.2} d="M146 0L4 143V0z" />
				<g stroke="#111" strokeWidth={2.5}>
					<path d="M57.121 78.905h31.935M88.203 116.2H43V25h64.98v71.301z" />
					<path d="M87.702 116.029v-19.84h19.897M56.68 67.75h31.935" />
					<g transform="translate(57 35)" strokeLinecap="round" strokeLinejoin="round">
						<circle cx={9.396} cy={10.778} r={2.695} />
						<path d="M16.582 10.778a7.092 7.092 0 00-.255-1.796l2.299-1.976-1.347-2.336-2.837 1a7.166 7.166 0 00-3.14-1.797L10.743.898H8.05L7.49 3.88a7.166 7.166 0 00-3.14 1.796L1.514 4.67.167 7.006l2.299 1.976a7.092 7.092 0 00-.255 1.796c.008.607.093 1.211.255 1.796l-2.3 1.976 1.348 2.336 2.836-.997a7.166 7.166 0 003.14 1.796l.559 2.973h2.694l.56-2.982a7.166 7.166 0 003.14-1.796l2.836.997 1.347-2.336-2.3-1.967c.162-.585.248-1.189.256-1.796z" />
					</g>
				</g>
			</g>
		</svg>
	);
}

export default ContractsIcon;
