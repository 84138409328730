import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import { Control } from 'react-hook-form';
import { RulesInterface } from '../consts/rules';
import { colors } from '../consts/colors';
import Button from './Button';
import { goToInvoice } from '../helpers/navigationHelper';
import { useHistory } from 'react-router-dom';
import service from '../service/service';
import { InvoiceItem } from '../service/types/Invoice';

interface Props {
	edit: boolean;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	value: number;
	fieldKey: string;
	placeholder?: string;
	number?: string;
	rightAlign?: boolean;
	rules?: Partial<RulesInterface>;
	inputColor?: string;
	contractNumber?: string;
}
function ExpensesEditFieldCellType(props: Props) {
	const classes = useStyles({ number: props.number || props.rightAlign ? true : false });
	const globalClasses = useGlobalStyles({
		number: props.number || props.rightAlign ? true : false,
		inputColor: props.inputColor,
	});

	const history = useHistory();
	const [, setInvoice] = useState<InvoiceItem | undefined>(undefined);
	const handleClick = async () => {
		// e.preventDefault(x/);

		await service.getInvoiceById(props.value).then((res) => {
			setInvoice(res);
			goToInvoice(history, res);
		});

		// if (invoice)
	};
	return (
		<div className={classes.textWrapper}>
			<span
				className={globalClasses.smallTextDark}
				style={{ color: props.inputColor ? props.inputColor : colors.darkGray }}>
				{props.value}
			</span>
			<Button
				text={'Edit'}
				buttonWrapperClassName={classes.buttonWrapper}
				textClassName={classes.buttonText}
				onClick={handleClick}
				// className={globalClasses.smallTextDark}
				// style={{ color: props.inputColor ? props.inputColor : colors.darkGray }}
			/>
		</div>
	);
}

interface StyleProps {
	number: boolean;
}

const useStyles = makeStyles(() => ({
	wrapper: {
		width: '95%',
	},
	inputWrapper: {
		width: '100%',
		padding: '6px 0px',
		borderBottom: `1px solid ${colors.timetrackBorderd}`,
	},
	inputEndWrapper: {
		width: '95%',
		marginLeft: 'auto',
	},
	textWrapper: (p: StyleProps) => ({
		display: 'flex',
		placeContent: 'space-between',
	}),
	buttonText: (p: StyleProps) => ({
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '0.4px',
		// fontFamily: fonts.semiBold,
		color: `rgba(26, 26, 26,  1})`,
	}),
	buttonWrapper: {
		cursor: 'pointer',
		padding: '4px 19px',
		margin: '0px 10px',
		borderRadius: '4px',
		background: 'rgba(255, 214, 0,  1)',
		width: 'fit-content',
	},
}));

export default ExpensesEditFieldCellType;
