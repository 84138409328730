import * as React from 'react';

function ErrorIcon(props: any) {
	return (
		<svg width={15} height={15} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>{'error_red_icon'}</title>
			<g fill="none" fillRule="evenodd">
				<circle
					stroke="#DE1C22"
					strokeWidth={1.5}
					strokeLinecap="round"
					strokeLinejoin="round"
					cx={7.5}
					cy={7.5}
					r={7}
				/>
				<circle fill="#DE1C22" fillRule="nonzero" cx={7.5} cy={11} r={1} />
				<path stroke="#DE1C22" strokeWidth={1.5} strokeLinecap="square" d="M7.5 3.5V8" />
			</g>
		</svg>
	);
}

export default ErrorIcon;
