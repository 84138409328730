import { ClickAwayListener, makeStyles } from '@material-ui/core';
import React from 'react';

import AvatarIcon from '../svgComponents/AvatarIcon';
import AvatarCard from './AvatarCard';

function Avatar() {
	const [showAvatarCard, setShowAvatarCard] = React.useState(false);
	const classes = useStyles({ showAvatarCard: showAvatarCard });

	const onAvatarPress = () => setShowAvatarCard((prev) => !prev);

	return (
		<ClickAwayListener onClickAway={() => setShowAvatarCard(false)}>
			<div className={classes.avatarContainer}>
				<div className={classes.avatarWrapper}>
					<AvatarIcon onClick={onAvatarPress} />
				</div>
				<div className={classes.avatarCard}>
					<AvatarCard />
				</div>
			</div>
		</ClickAwayListener>
	);
}

interface StyleProps {
	showAvatarCard: boolean;
}

const useStyles = makeStyles(() => ({
	avatarContainer: {
		paddingTop: '20px',
		paddingRight: '32px',
	},
	avatarWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		cursor: 'pointer',
	},
	avatarCard: (p: StyleProps) => ({
		marginLeft: 'auto',
		marginRight: '0',
		position: 'absolute',
		right: '20px',
		top: '65px',
		width: '300px',
		height: p.showAvatarCard ? '200px' : '0px',
		overflow: 'hidden',
		transition: 'all 0.5s',
	}),
}));

export default Avatar;
