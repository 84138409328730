import React from 'react';

import { ErrorActionsContext, ErrorStateContext } from './ErrorContext';

export function useErrorStateContext() {
	return React.useContext(ErrorStateContext);
}

export function useErrorActionContext() {
	return React.useContext(ErrorActionsContext);
}
