import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Controller, useFormContext } from 'react-hook-form';
import ExpensesTabel from '../components/ExpensesTabel';
import Filter from '../components/Filter';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import StandardTextField from '../components/StandardTextField';
import TabTitle from '../components/TabTitle';
import { expensesTabelParams } from '../consts/expenses';
import { useAuthStateContext } from '../context/auth/useAuth';
import {
	Expenses,
	expensesFilterFields,
	ExpensesFilterInterface,
	ExpensesFilterKeys,
	ExpensesKeys,
	filterBilledOptions,
	initExpensesFilters,
} from '../helpers/expenses/generalExpensesHelper';
import { checkIfUserStopWriting } from '../helpers/filters';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { RouteParams, Routes } from '../router/Routes';
import { IContractWorkLocation } from '../service/types/Contract';
import MaterialTool from '../service/types/MaterialTool';
import Transport from '../service/types/Transport';
import Travel from '../service/types/Travel';
import { UserRole } from '../service/types/User';
import ExpensessIcon from '../svgComponents/ExpensessIcon';
import GoBack from '../svgComponents/GoBack';
import HomeButton from '../svgComponents/HomeButton';

interface Props {
	intervalDates: IntervalDatePickerDates;
	contractID: number;
	contractNumber: string;
	tabType: ExpensesKeys;
	expenses: Expenses;
	allUsers: KeyValue[];
	filters: ExpensesFilterInterface;
	routeParams: RouteParams;
	workLocations: IContractWorkLocation[];
	onUpdatePress: (expense: MaterialTool | Travel | Transport, data: any) => Promise<boolean>;
	onAddPress: (data: any) => Promise<boolean | MaterialTool | Travel | Transport>;
	onFilterChange: (key: ExpensesFilterKeys, value: any) => void;
	onTabPress: (type: ExpensesKeys) => void;
	clearAllFilters: () => void;
	onDeletePress: (expenseID: number) => void;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
}

function ExpensesScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const classes = useStyles({});
	const globalClasses = useGlobalStyles({ showFilters: showFilters });
	const { t } = useTranslation();
	const { user } = useAuthStateContext();
	const searchRef = React.useRef<ExpensesFilterInterface>({ ...initExpensesFilters });
	const filterListRef = React.useRef<any>(null);
	const { errors, control } = useFormContext();

	const onFilterPress = () => setShowFilters((prev) => !prev);

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack route={`${Routes.ProcessContract}/${props.routeParams.contract_id}`} />
				<HomeButton contractNumber={props.contractNumber} contractID={props.contractID} />
			</div>
			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.smallText}>{`${t('expenses_screen_subtitle')}: `}</span>
				<span className={globalClasses.smallTextDark}>{`${props.contractNumber}`}</span>
			</div>
			<div className={classes.row}>
				<div className={classes.iconWrapper}>
					<ExpensessIcon className={classes.icon} />
				</div>
				<div>
					<span className={globalClasses.title}>{t('expenses_screen_title')}</span>
				</div>
			</div>

			<div className={classes.tabWrapper}>
				<TabTitle
					type={'materials'}
					active={props.tabType}
					onPress={props.onTabPress}
					title={'expenses_screen_materials'}
				/>
				{user?.role !== UserRole.QualityAssuranceInspector ? (
					<TabTitle
						type={'tools'}
						active={props.tabType}
						onPress={props.onTabPress}
						title={'expenses_screen_tools'}
					/>
				) : null}
				<TabTitle
					type={'travels'}
					active={props.tabType}
					onPress={props.onTabPress}
					title={'expenses_screen_travel'}
				/>
				{user?.role !== UserRole.QualityAssuranceInspector ? (
					<TabTitle
						type={'transport'}
						active={props.tabType}
						onPress={props.onTabPress}
						title={'expenses_screen_transport'}
					/>
				) : null}
			</div>
			{user?.role !== UserRole.QualityAssuranceInspector ? (
				<div className={classes.filterWrapper}>
					<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />
					<div
						className={globalClasses.filterWrapper}
						style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
						<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
							{props.tabType === 'travels' || props.tabType === 'transport' ? null : (
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<Controller
										defaultValue={''}
										control={control}
										name={expensesFilterFields.name}
										render={({ onChange, value }) => (
											<StandardTextField
												label={t('expenses_screen_name')}
												value={value}
												onChange={(e) => {
													onChange(e);
													checkIfUserStopWriting(e, props.onFilterChange, searchRef, 'name');
												}}
											/>
										)}
									/>
								</Grid>
							)}
							<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
								<KeyValueAutoComplete
									label={t('expenses_screen_reporter')}
									onChange={(e) => {
										if (e) {
											props.onFilterChange('reporter', e.value.id.toString());
										} else {
											props.onFilterChange('reporter', '');
										}
									}}
									options={props.allUsers.filter((item) => !item.value.deleted)}
									multiple={false}
									value={{
										key: props.allUsers.find(
											(user) => user.value.id.toString() === props.filters.reporter
										)?.key
											? props.allUsers.find(
													(user) => user.value.id.toString() === props.filters.reporter
											  )!.key
											: '',
										value: props.filters.reporter,
									}}
								/>
							</Grid>
							<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
								<KeyValueAutoComplete
									label={t('expenses_screen_qai')}
									onChange={(e) => {
										if (e) {
											props.onFilterChange('qai', e.value.id.toString());
										} else {
											props.onFilterChange('qai', '');
										}
									}}
									options={props.allUsers.filter((item) => !item.value.deleted)}
									multiple={false}
									value={{
										key: props.allUsers.find(
											(user) => user.value.id.toString() === props.filters.qai
										)?.key
											? props.allUsers.find(
													(user) => user.value.id.toString() === props.filters.qai
											  )!.key
											: '',
										value: props.filters.qai,
									}}
								/>
							</Grid>
							<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
								<IntervalDatePicker
									id="start"
									error={errors['fromDate']}
									helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
									value={props.filters.fromDate}
									label={t('expenses_screen_from_date')}
									dates={props.intervalDates}
									clearable={true}
									onKeyboardDateChange={props.onKeyboardDateChange}
									onDateChange={props.onDateChange}
									onDatesAccept={props.onDatesAccept}
									onDatesCancel={props.onDatesCancel}
									clearDates={props.clearDates}
								/>
							</Grid>
							<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
								<IntervalDatePicker
									id="end"
									error={errors['toDate']}
									helperText={errors['toDate'] ? errors['toDate'].message : ''}
									value={props.filters.toDate}
									label={t('expenses_screen_to_date')}
									dates={props.intervalDates}
									clearable={true}
									onKeyboardDateChange={props.onKeyboardDateChange}
									onDateChange={props.onDateChange}
									onDatesAccept={props.onDatesAccept}
									onDatesCancel={props.onDatesCancel}
									clearDates={props.clearDates}
								/>
							</Grid>
							<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
								<StandardTextField
									label={t('expenses_screen_status')}
									select
									value={props.filters.billed}
									onChange={(e) => props.onFilterChange('billed', e.target.value)}>
									{filterBilledOptions.map((item) => (
										<MenuItem key={item.key} value={item.value}>
											<div className={classes.inputWrapper}>
												<span className={classes.input}>{t(item.key)}</span>
											</div>
										</MenuItem>
									))}
								</StandardTextField>
							</Grid>
						</Grid>
					</div>
				</div>
			) : null}
			<ExpensesTabel
				isSummary={false}
				onDeletePress={props.onDeletePress}
				expenses={props.expenses}
				expensesTableParams={expensesTabelParams(t, props.allUsers, user, props.workLocations)}
				tableKey={'Expenses'}
				users={props.allUsers}
				tabType={props.tabType}
				onAddPress={props.onAddPress}
				onUpdatePress={props.onUpdatePress}
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
	},
	iconWrapper: {
		height: '30px',
		width: '30px',
		marginRight: '18px',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	icon: {
		height: '32px',
		width: '32px',
	},
	tabWrapper: {
		display: 'flex',
		marginTop: '28px',
	},
	filterWrapper: {
		marginTop: '32px',
	},
	subtitleWrapper: {
		marginTop: '3px',
	},
	input: {
		...globalInputStyle(),
		whiteSpace: 'pre',
	},
	inputWrapper: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

export default ExpensesScreen;
