import React from 'react';
import { rhenusCaseInputListParams } from '../consts/rhenusCase';
import { RhenusCaseFilterKeys } from '../containers/ZoneOverviewContainer';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import {
	createRhenusCasePartStatusKeyValuePairs,
	filterRhenusCasePartStatusKeyValuePairs,
} from '../helpers/rhenusInputHelper/generalRhenusHelper';
import {
	ApprovedStatus,
	DEFAULT_NO_VALUE,
	filterApprovedStatusKeyValuePairs,
} from '../helpers/standardInputHelper/generalStandardInputHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import service from '../service/service';
import RhenusCase, { RhenusCaseFilterInterface } from '../service/types/RhenusCase';
import { UserRole } from '../service/types/User';
import CameraDialog, { CameraDialogInterface } from './CameraDialog';
import CustomTableBody from './CustomTableBody';
import { TableCellType } from './table/StandardTableCell';

interface Props {
	rhenusCaseInputs: RhenusCase[];
	allAssignedQAIs: KeyValue[];
	filters: RhenusCaseFilterInterface;
	photo: boolean;
	amountOfParts: string[];
	typesOfInspections: string[];
	errorTypes: string[];
	automatedUpdates: boolean;
	shiftTimes: ShiftInterface[];
	dashboard?: boolean;
	maxHeight?: string;
	onFilterChange: (key: RhenusCaseFilterKeys, value: any) => void;
	onViewPress: (rhenusCase: RhenusCase) => void;
	onApprovePress: (rhenusCase: RhenusCase) => void;
}

function RhenusCaseList({
	rhenusCaseInputs,
	allAssignedQAIs,
	filters,
	photo,
	amountOfParts,
	typesOfInspections,
	errorTypes,
	automatedUpdates,
	shiftTimes,
	dashboard,
	maxHeight,
	onFilterChange,
	onViewPress,
	onApprovePress,
}: Props) {
	const { user } = useAuthStateContext();
	const [partNumber, setPartNumber] = React.useState('');
	const cameraDialogRef = React.useRef<CameraDialogInterface | undefined>();
	const transformedStandardCaseInputs = React.useMemo(() => {
		return rhenusCaseInputs.map((rhenusCase) => {
			return {
				...rhenusCase,
				shift: shiftTimes.find((shift) => shift.id === rhenusCase.shift)?.name,
				qai: allAssignedQAIs.find((qai) => qai.value.id === rhenusCase.qai)?.key,
				approved: rhenusCase.approved ? ApprovedStatus.Approved : ApprovedStatus.NotApproved,
				partStatus: createRhenusCasePartStatusKeyValuePairs.find(
					(status) => status.value === rhenusCase.partStatus
				)?.key,
			};
		});
	}, [rhenusCaseInputs, allAssignedQAIs, shiftTimes]);

	const onPreviewImage = React.useCallback((rhenusCaseInput: RhenusCase) => {
		if (rhenusCaseInput.photo)
			service
				.getPhoto(rhenusCaseInput.photo.name)
				.then((res) => {
					if (res) {
						cameraDialogRef.current?.setDialogState(true, true, { name: '', data: res });
						setPartNumber(rhenusCaseInput.partNumber);
					}
				})
				.catch(() => {});
	}, []);

	const transformedListParams = React.useMemo(() => {
		if (user) {
			const tempParams = { ...rhenusCaseInputListParams };
			if (!photo || user?.role === UserRole.QualityAssuranceInspector) delete tempParams.photo;
			if (automatedUpdates || dashboard) delete tempParams.approved;
			if (user?.role === UserRole.QualityAssuranceInspector) {
				delete tempParams.qai;
				delete tempParams.shift;
				delete tempParams.photo;
				delete tempParams.approved;
			}
			if (dashboard) {
				delete tempParams.qai;
				delete tempParams.comment;
				delete tempParams.photo;
				delete tempParams.approved;
				tempParams.productionNumber = {
					...tempParams.productionNumber,
					type: TableCellType.Text,
					width: '15.56vw',
				};
			}
			return tempParams;
		}
	}, [photo, automatedUpdates, user, dashboard]);

	const filtersOptions: Partial<RhenusCaseFilterInterface> = React.useMemo(
		() => ({
			typeOfInspection: typesOfInspections.map((item) => ({ key: item, value: item })),
			partStatus: filterRhenusCasePartStatusKeyValuePairs,
			errorType: errorTypes.map((item) => ({ key: item, value: item })),
			shift: [DEFAULT_NO_VALUE, ...shiftTimes.map((shift) => ({ key: shift.name, value: shift.name }))],
			approved: filterApprovedStatusKeyValuePairs,
			amountOfParts: amountOfParts.map((item) => ({ key: item, value: item })),
		}),
		[typesOfInspections, errorTypes, shiftTimes, amountOfParts]
	);

	const table = React.useMemo(
		() => (
			<CustomTableBody
				data={transformedStandardCaseInputs}
				margin={'16px 0px'}
				dataTablePramas={transformedListParams}
				onFilterChange={onFilterChange}
				filters={filters}
				filtersOptions={filtersOptions}
				tableKey={'RhenusInputList'}
				maxHeight={maxHeight}
				buttonProps={{
					approved: {
						text: 'rhenus_case_zone_overview_screen_list_approve',
						onClick: (index) => onApprovePress(rhenusCaseInputs[index]),
					},
					photo: {
						text: 'rhenus_case_zone_overview_screen_list_photo',
						onClick: (index) => onPreviewImage(rhenusCaseInputs[index]),
					},
					productionNumber: {
						text: 'rhenus_case_zone_overview_screen_list_view',
						onClick: (index) => onViewPress(rhenusCaseInputs[index]),
					},
				}}
			/>
		),
		[
			transformedStandardCaseInputs,
			transformedListParams,
			onFilterChange,
			filters,
			filtersOptions,
			maxHeight,
			onApprovePress,
			rhenusCaseInputs,
			onPreviewImage,
			onViewPress,
		]
	);

	return (
		<div>
			{table}
			<CameraDialog ref={cameraDialogRef} partNumber={partNumber} />
		</div>
	);
}

export default RhenusCaseList;
