import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Grid, IconButton, makeStyles } from '@material-ui/core';

import AddressItem from '../components/AddressItem';
import ContactPersonList from '../components/ContactPersonList';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import StandardRadioButton from '../components/StandardRadioButton';
import StandardTextField from '../components/StandardTextField';
import { MIN_CUSTOMER_LENGTH, customerFields } from '../containers/CustomerContainer';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';

import { rules } from '../consts/rules';
import { fonts } from '../consts/fonts';
import User from '../service/types/User';
import { colors } from '../consts/colors';
import { Routes } from '../router/Routes';
import Customer from '../service/types/Customer';
import { MAX_PASSWORD_LENGTH } from '../consts/login';
import { useGlobalStyles } from '../consts/globalStyles';
import { AddressInterface } from '../service/types/Address';
import { transformFields } from '../helpers/customerHelper/readCustomerHelper';

import Plus from '../svgComponents/Plus';
import GoBack from '../svgComponents/GoBack';
import DeleteIcon from '../svgComponents/DeleteIcon';
import HomeButton from '../svgComponents/HomeButton';

interface Props {
	customer: Customer | undefined;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	isUserEditing: boolean;
	deleteDialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	formDirty: boolean;
	billingAddressWatch: AddressInterface;
	deliveryAddressWatch: AddressInterface;
	sameAddress: boolean;
	onEditContactPerson: (user: User) => void;
	onSubmitPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDeleteDialogPress: () => void;
	onDeleteCustomerDialogPress: () => void;
	emailFields: any;
	appendEmailFields: any;
	removeEmailFields: any;
	setValue: any;
}
function CustomerScreen(props: Props) {
	const classes = useStyles({ disabled: !props.isUserEditing });
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles({ description: colors.lightGray });

	const billingAddress = useMemo(
		() =>
			props.isUserEditing ? transformFields(props.billingAddressWatch) : props.customer?.billingAddressGenerated,
		[props.billingAddressWatch, props.isUserEditing, props.customer]
	);
	const deliveryAddress = useMemo(
		() =>
			props.isUserEditing
				? transformFields(props.deliveryAddressWatch)
				: props.customer?.deliveryAddressGenerated,
		[props.deliveryAddressWatch, props.isUserEditing, props.customer]
	);

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack edit={props.formDirty && props.isUserEditing} route={Routes.Customers} />
				<HomeButton edit={props.formDirty && props.isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>
					{props.customer ? `${props.customer.name}` : t('customer_screen_title')}
				</span>
			</div>
			<Grid container style={{ rowGap: '12px' }}>
				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.name : ''}
						control={props.control}
						name={customerFields.name}
						rules={{
							required: rules(t).required,
							minLength: rules(t).minLength(MIN_CUSTOMER_LENGTH),
							maxLength: rules(t).maxLength(MAX_PASSWORD_LENGTH),
							pattern: rules(t).notEmptyPattern,
						}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_screen_name').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[customerFields.name] ? true : false}
								helperText={
									props.errors[customerFields.name] ? props.errors[customerFields.name].message : ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.customerNumber : ''}
						control={props.control}
						name={customerFields.customerNumber}
						rules={{}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.supplierNumber : ''}
						control={props.control}
						name={customerFields.supplierNumber}
						rules={{}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_supplier_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.invoiceEmail : ''}
						control={props.control}
						name={customerFields.invoiceEmail}
						rules={{ pattern: rules(t).emailPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_invoice_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[customerFields.invoiceEmail] ? true : false}
								helperText={
									props.errors[customerFields.invoiceEmail]
										? props.errors[customerFields.invoiceEmail].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />

				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.reminderEmail : ''}
						control={props.control}
						name={customerFields.reminderEmail}
						rules={{ pattern: rules(t).emailPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_reminder_email').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
								error={props.errors[customerFields.reminderEmail] ? true : false}
								helperText={
									props.errors[customerFields.reminderEmail]
										? props.errors[customerFields.reminderEmail].message
										: ''
								}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5} style={{ display: 'flex', flexDirection: 'column', paddingTop: '16px' }}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<span>{t('customer_other_email').toUpperCase()}</span>
						<IconButton
							color="primary"
							disabled={!props.isUserEditing}
							onClick={() => {
								props?.appendEmailFields({ selectedValue: 'emails' });
							}}>
							<Plus />
						</IconButton>
					</div>
					{props?.emailFields.map((field: any, index: number) => {
						return (
							// make sure to set field.id to top mappped item
							<div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
								<StandardTextField
									label={''}
									disabled={!props.isUserEditing}
									// ref={props.register()}
									onChange={(e) => {
										const value = e.target.value;

										const oldValues = [...props.emailFields];
										const newValue = { ...(oldValues[index] as { value: string }[]) };
										// disable any type checking for this line
										// @ts-ignore
										newValue['value'] = value;
										oldValues[index] = newValue;
										props.setValue(customerFields.otherEmails, oldValues);
									}}
									name={`${customerFields.otherEmails}[${index}].value`}
									wrapper={classes.fullWidth}
									value={field.value} // make sure to include defaultValue
								/>

								<IconButton
									disabled={!props.isUserEditing}
									color="primary"
									onClick={() => {
										props?.removeEmailFields(index);
									}}>
									<DeleteIcon />
								</IconButton>
							</div>
						);
					})}
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.vatNumber : ''}
						control={props.control}
						name={customerFields.vatNumber}
						rules={{}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_vat_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
							/>
						)}
					/>
				</Grid>

				<Grid item sm={1} />

				<Grid item sm={5}>
					<Controller
						defaultValue={props.customer ? props.customer.termOfPayment : ''}
						control={props.control}
						name={customerFields.termOfPayment}
						rules={{}}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer_term_of_payment').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<div className={classes.subtitleWrapper}>
				<div className={classes.addressRow}>
					<span className={globalClasses.subtitle}>{t('customer_screen_address')}</span>
					{props.isUserEditing ? (
						<Controller
							control={props.control}
							defaultValue={true}
							name={customerFields.radio}
							render={({ onChange, value }) => (
								<div>
									<div className={classes.row}>
										<StandardRadioButton
											label={'customer_screen_same_address'}
											value={value}
											disabled={!props.isUserEditing}
											onChange={onChange}
											radioButtonValue={true}
										/>
										<StandardRadioButton
											label={'customer_screen_different_delivery_address'}
											value={value}
											disabled={!props.isUserEditing}
											onChange={onChange}
											radioButtonValue={false}
										/>
									</div>
								</div>
							)}
						/>
					) : null}
				</div>
			</div>
			<Grid container spacing={3}>
				<Grid item sm={5}>
					<StandardTextField
						label={t('customer_screen_billing_address').toUpperCase()}
						onChange={() => {}}
						value={billingAddress}
						disabled={true}
						fixed={true}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5}>
					<StandardTextField
						label={t('customer_screen_delivery_address').toUpperCase()}
						onChange={() => {}}
						value={
							props.sameAddress
								? `${billingAddress}${
										billingAddress && props.deliveryAddressWatch.costCenter ? ', ' : ''
								  }${props.deliveryAddressWatch.costCenter}`
								: deliveryAddress
						}
						disabled={true}
						fixed={true}
					/>
				</Grid>
			</Grid>
			{props.isUserEditing ? (
				<Grid container spacing={3}>
					<Grid item sm={5}>
						<AddressItem
							control={props.control}
							errors={props.errors}
							isUserEditing={props.isUserEditing}
							keys={customerFields.billingAddress}
						/>
					</Grid>
					<Grid item sm={1} />
					<Grid item sm={5}>
						<AddressItem
							control={props.control}
							errors={props.errors}
							disabled={props.sameAddress}
							isUserEditing={props.isUserEditing}
							keys={customerFields.deliveryAddress}
						/>
					</Grid>
				</Grid>
			) : null}
			{props.customer ? (
				<Grid container>
					<Grid item sm={11} className={classes.subtitleWrapper}>
						<span className={globalClasses.subtitle}>{t('customer_screen_contact_info')}</span>
					</Grid>
					<Grid item sm={1} />
					<Grid item sm={11}>
						<ContactPersonList
							contactPersons={props.customer.users}
							onEditPress={props.onEditContactPerson}
						/>
						{props.customer.users.length === 0 ? (
							<div className={classes.newPersonMessageWrapper}>
								<span className={globalClasses.description}>
									{t('customer_screen_table_add_new_contact')}
								</span>
							</div>
						) : null}
					</Grid>
				</Grid>
			) : null}
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'customer_screen_cancel'}
					updateButtonText={'customer_screen_update'}
					createButtonText={'customer_screen_create'}
					deleteButtonText={'customer_screen_delete'}
					editButtonText={'customer_screen_edit'}
					data={props.customer}
					isUserEditing={props.isUserEditing}
					onCancelPress={props.onCancelPress}
					onDeletePress={props.onDeletePress}
					onSubmitPress={props.onSubmitPress}
				/>
			</div>
			<StandardDialog
				acceptText={'customer_screen_delete_dialog_accept_button'}
				description={'customer_screen_delete_dialog_description'}
				title={'customer_screen_delete_dialog_title'}
				cancleText={'customer_screen_delete_dialog_cancel_button'}
				onCancle={props.onCancelDeleteDialogPress}
				onAccept={props.onDeleteCustomerDialogPress}
				ref={props.deleteDialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	fullWidth: {
		flex: 1,
		width: '100%',
	},
	titleWrapper: {
		marginBottom: '24px',
	},
	newPersonMessageWrapper: {
		marginLeft: '24px',
		marginTop: '8px',
	},
	subtitleWrapper: {
		margin: '28px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	textFieldWrapper: {
		marginBottom: '32px',
	},
	contactPerson: {
		fontSize: '16px',
		lineHeight: '24px',
		color: colors.darkGray,
		fontFamily: fonts.semiBold,
	},
	contactPersonWrapper: {
		marginBottom: '20px',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
	addNewWrapper: {
		marginLeft: '24px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		height: '34px',
		paddingLeft: '20px',
	},
	addressRow: {
		display: 'flex',
	},
}));

export default CustomerScreen;
