import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAuthStateContext } from '../context/auth/useAuth';
import { ContractZone } from '../helpers/contractHelper/generalContractHelper';
import { filterData } from '../helpers/filters';
import {
	goToExpenses,
	goToHome,
	goToRhenusCase,
	goToWorkingTime,
	ZoneOvervieNavigationInterface,
} from '../helpers/navigationHelper';
import { textsForRhenusAutomaticalUpdate } from '../helpers/rhenusInputHelper/generalRhenusHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import ZoneOverviewScreen from '../screens/ZoneOverviewScreen';
import service from '../service/service';
import RhenusCase, { RhenusCaseFilterInterface } from '../service/types/RhenusCase';
import { UserRole } from '../service/types/User';
import { rhenusCaseInputFields } from './RhenusCaseContainer';
import { findCurrentShift } from '../helpers/standardInputHelper/createStandardInputHelper';
import Contract from '../service/types/Contract';
import { RouteParams } from '../router/Routes';
import { unstable_batchedUpdates } from 'react-dom';
import { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';

export const initRhenusCaseInputFilters: RhenusCaseFilterInterface = {
	partNumber: '',
	partStatus: '',
	productionNumber: '',
	qai: '',
	storage: '',
	approved: '',
	typeOfInspection: '',
	errorType: '',
	toDate: moment(),
	fromDate: moment(),
	shift: '',
	colorNumber: '',
	amountOfParts: '',
};

const data = Object.freeze({ ...initRhenusCaseInputFilters });
export type RhenusCaseFilterKeys = keyof typeof data;

function ZoneOverviewContainer() {
	const methods = useForm();
	const [rhenusCaseInputs, setRhenusCaseInputs] = React.useState<RhenusCase[]>([]);
	const [contractData, setContractData] = React.useState<Contract | undefined>();
	const [zone, setZone] = React.useState<ContractZone | undefined>();
	const [allAssignedQAIs, setAllAssignedQAIs] = React.useState<KeyValue[]>([]);
	const { user } = useAuthStateContext();
	const [updatedText, setUpdatedText] = React.useState('');
	const [filters, setFilters] = React.useState<RhenusCaseFilterInterface>(initRhenusCaseInputFilters);
	const allRhenusCaseInputs = React.useRef<RhenusCase[]>([]);
	const history = useHistory();
	const timer = React.useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));
	const fetching = React.useRef(false);
	const fetchingUser = React.useRef(false);
	const routeParams: RouteParams = useParams();
	const [intervalDates, setIntervalDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});
	const { t } = useTranslation();

	React.useEffect(() => {
		const historyState = history.location.state as undefined | ZoneOvervieNavigationInterface;
		if (historyState && historyState.params) {
			setContractData(Contract.fromJSON(JSON.parse(historyState.params)));
			setZone(historyState.zone);
		} else if (routeParams.contract_id && routeParams.zone_id) {
			service
				.getContract(Number(routeParams.contract_id))
				.then((res) => {
					if (res) {
						setContractData(res);
						if (routeParams.zone_id && res.zones) {
							const zone = res.zones.find((zone) => zone.id === Number(routeParams.zone_id));
							if (zone) setZone(zone);
							else goToHome(history);
						} else {
							goToHome(history);
						}
					}
				})
				.catch((e) => {
					goToHome(history);
				});
		} else {
			goToHome(history);
		}
	}, [history, routeParams]);

	const onFilterChange = (key: RhenusCaseFilterKeys, value: any) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));
	};

	const clearAllFilters = () => {
		Object.values(rhenusCaseInputFields).forEach((value) => methods.setValue(value, ''));
		setFilters(initRhenusCaseInputFilters);
	};

	React.useEffect(() => {
		setRhenusCaseInputs(filterData(filters, allRhenusCaseInputs.current));
	}, [filters]);

	React.useEffect(() => {
		if (contractData && user?.role !== UserRole.QualityAssuranceInspector && !fetchingUser.current) {
			fetchingUser.current = true;
			getUserOptionsKeyValuePairs(service.getAllUsers(), () => {})
				.then((qais) => {
					setAllAssignedQAIs(qais.filter((qai) => qai.value.role !== UserRole.Customer));
				})
				.catch(() => {});
			setFilters({
				...initRhenusCaseInputFilters,
				shift: findCurrentShift(contractData.shiftTimes).id,
			});
		}
	}, [contractData, user]);

	const getDataAutomaticly = React.useCallback((contractID: number, zoneID: number, cycleNumber: number) => {
		return setTimeout(() => {
			setUpdatedText(textsForRhenusAutomaticalUpdate[cycleNumber === 0 ? 5 : cycleNumber]);
			if (cycleNumber === 0) {
				service
					.getZoneCaseInputs(contractID, zoneID)
					.then((res) => {
						unstable_batchedUpdates(() => {
							setRhenusCaseInputs(res);
							allRhenusCaseInputs.current = res;
							setUpdatedText(textsForRhenusAutomaticalUpdate[0]);
							timer.current = getDataAutomaticly(contractID, zoneID, 1);
						});
					})
					.catch((e) => {
						fetching.current = false;
					});
			} else if (cycleNumber === 4) {
				timer.current = getDataAutomaticly(contractID, zoneID, 0);
			} else {
				timer.current = getDataAutomaticly(contractID, zoneID, cycleNumber + 1);
			}
		}, 60000);
	}, []);

	React.useEffect(() => {
		if (zone && contractData) {
			service
				.getZoneCaseInputs(contractData.id, zone.id)
				.then((res) => {
					setRhenusCaseInputs(res);
					allRhenusCaseInputs.current = res;
					setFilters({
						...initRhenusCaseInputFilters,
						shift: findCurrentShift(contractData.shiftTimes).id,
					});
					setUpdatedText(textsForRhenusAutomaticalUpdate[0]);
				})
				.catch(() => {});
		}
	}, [zone, contractData]);

	React.useEffect(() => {
		if (zone && contractData) {
			if (user?.role !== UserRole.QualityAssuranceInspector && !fetching.current) {
				fetching.current = true;
				timer.current = getDataAutomaticly(contractData.id, zone.id, 1);
			}
		}
		return () => {
			clearTimeout(timer.current);
		};
	}, [zone, contractData, user, getDataAutomaticly]);

	const onViewPress = (rhenusCase: RhenusCase) => {
		if (zone && contractData)
			goToRhenusCase(
				history,
				{
					contract: contractData,
					data: rhenusCase,
				},
				zone
			);
	};

	const onWorkingTimePress = () => {
		if (contractData && zone) {
			goToWorkingTime(history, {
				contractID: contractData.id,
				contractNumber: contractData.contractNumber,
			});
		}
	};

	const onExpensesPress = () => {
		if (contractData && zone) {
			goToExpenses(history, {
				contractID: contractData.id,
				contractNumber: contractData.contractNumber,
			});
		}
	};

	const approveData = (rhenusCase: RhenusCase) => {
		if (contractData && zone) {
			service
				.aprroveInput(contractData.id, rhenusCase.id, zone.id)
				.then(() => {
					clearTimeout(timer.current);
					service
						.getZoneCaseInputs(contractData.id, zone.id)
						.then((res) => {
							unstable_batchedUpdates(() => {
								setRhenusCaseInputs(res);
								allRhenusCaseInputs.current = res;
								setUpdatedText(textsForRhenusAutomaticalUpdate[0]);
							});
						})
						.catch(() => {});
					timer.current = getDataAutomaticly(contractData.id, zone.id, 1);
				})
				.catch(() => {});
		}
	};

	const onNewErrorCasePress = () => {
		if (zone && contractData)
			goToRhenusCase(
				history,
				{
					contract: contractData,
				},
				zone
			);
	};

	const onDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);

			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	return (
		<FormProvider {...methods}>
			<ZoneOverviewScreen
				intervalDates={intervalDates}
				onApprovePress={approveData}
				onNewErrorCasePress={onNewErrorCasePress}
				onViewPress={onViewPress}
				onWorkingTimePress={onWorkingTimePress}
				filters={filters}
				onFilterChange={onFilterChange}
				clearAllFilters={clearAllFilters}
				allAssignedQAIs={allAssignedQAIs}
				rhenusCaseInputs={rhenusCaseInputs}
				contractData={contractData}
				zone={zone}
				updatedText={updatedText}
				onExpensesPress={onExpensesPress}
				onKeyboardDateChange={onKeyboardDateChange}
				onDateChange={onDateChange}
				onDatesAccept={onDatesAccept}
				onDatesCancel={onDatesCancel}
				clearDates={clearDates}
			/>
		</FormProvider>
	);
}

export default ZoneOverviewContainer;
