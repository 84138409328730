import { TableCellType } from '../components/table/StandardTableCell';
import { UserTableInterface } from '../service/types/User';
import { TableParamInterface } from './rhenusOverview';

interface UserTableParams extends UserTableInterface {
	firstName: TableParamInterface;
	lastName: TableParamInterface;
	originOfEmployee: TableParamInterface;
	role: TableParamInterface;
	edit: TableParamInterface;
}

export const userListParams: UserTableParams = {
	firstName: {
		title: 'user_overview_screen_first_name',
		width: '11.91vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	lastName: {
		type: TableCellType.Text,
		titleType: TableCellType.Title,
		title: 'user_overview_screen_last_name',
		width: '12.015vw',
	},
	originOfEmployee: {
		title: 'user_overview_screen_origin',
		width: '18.144vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	role: {
		title: 'user_overview_screen_role',
		width: '26.957vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	//@ts-ignore
	edit: {
		title: '',
		width: '5.6125vw',
		type: TableCellType.Link,
		titleType: TableCellType.Title,
	},
};
