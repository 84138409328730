import moment, { Moment } from 'moment';
import { FileInterface } from '../../helpers/contractHelper/generalContractHelper';
import { ErrorInputFromJSON, ErrorInputToJSON } from '../../helpers/standardInputHelper/generalStandardInputHelper';

export interface StandardCaseFilterInterface {
	partNumber: any;
	deliveryNoteNumber: any;
	deliveryNoteDate: any;
	totalPartsTested: any;
	partsNIO: any;
	partsIO: any;
	partsReworked: any;
	checkNumber: any;
	qai: any;
	typeOfInspection: any;
	approved: any;
	shift: any;
	toDate?: any;
	fromDate?: any;
}

export interface ErrorInputType {
	amount: number;
	type: string;
}

export interface StandardCaseTableInterface extends StandardCaseFilterInterface {
	errorTypes: any;
	photo?: any;
	comment?: any;
}

class StandardCaseInput implements StandardCaseTableInterface {
	id: number;
	partNumber: string;
	deliveryNoteNumber: number | null;
	deliveryNoteDate: Moment | null;
	totalPartsTested: number;
	partsNIO: number;
	partsIO: number;
	partsReworked: number;
	checkNumber: number;
	qai: number;
	typeOfInspection: string;
	errorTypes: ErrorInputType[];
	shift: number;
	date: Moment;
	approved: boolean;
	photo?: FileInterface;
	comment?: string;
	locationID?: number;

	constructor(
		id: number,
		partNumber: string,
		deliveryNoteNumber: number | null,
		deliveryNoteDate: Moment | null,
		totalPartsTested: number,
		partsNIO: number,
		partsIO: number,
		partsReworked: number,
		checkNumber: number,
		qai: number,
		typeOfInspection: string,
		errorTypes: ErrorInputType[],
		shift: number,
		date: Moment,
		approved: boolean,
		comment?: string,
		photo?: FileInterface,
		locationID?: number
	) {
		this.id = id;
		this.partNumber = partNumber.trim();
		this.deliveryNoteNumber = deliveryNoteNumber;
		this.deliveryNoteDate = deliveryNoteDate;
		this.totalPartsTested = totalPartsTested;
		this.partsNIO = partsNIO;
		this.partsIO = partsIO;
		this.partsReworked = partsReworked;
		this.checkNumber = checkNumber;
		this.qai = qai;
		this.typeOfInspection = typeOfInspection.trim();
		this.errorTypes = errorTypes;
		this.shift = shift;
		this.photo = photo;
		this.date = date;
		this.comment = comment ? comment.trim() : undefined;
		this.approved = approved;
		this.locationID = locationID;
	}

	public static fromJSON(maybe: any) {
		if (!maybe) {
			throw Error('standard case must exist');
		}
		if (typeof maybe.id !== 'number') {
			throw Error('id must be number');
		}
		if (typeof maybe.part_number !== 'string') {
			throw Error('part_number must be string');
		}
		if (typeof maybe.delivery_note_number !== 'number' && maybe.delivery_note_number !== null) {
			throw Error('deliveryNoteNumber must be number or null');
		}
		if (typeof maybe.total_parts_tested !== 'number') {
			throw Error('totalPartsTested must be number');
		}
		if (typeof maybe.parts_NIO !== 'number') {
			throw Error('partsNIO must be number');
		}
		if (typeof maybe.parts_IO !== 'number') {
			throw Error('partsIO must be number');
		}
		if (typeof maybe.parts_reworked !== 'number') {
			throw Error('partsReworked must be number');
		}
		if (typeof maybe.check_number !== 'number') {
			throw Error('checkNumber must be number');
		}
		if (!maybe.type_of_inspection) {
			throw Error('typeOfInspection must exist');
		}
		if (!maybe.qai_id) {
			throw Error('qai must exist');
		}
		if (!maybe.error_types) {
			throw Error('errorType must exist');
		}
		const deliveryNoteDate = maybe.delivery_note_date ? moment(maybe.delivery_note_date) : null;
		// if (!deliveryNoteDate.isValid()) {
		// 	throw new Error('deliveryNoteDate is not valid date');
		// }

		const date = moment(maybe.date);
		if (!date.isValid()) {
			throw new Error('date is not valid date');
		}

		if (maybe.approved === undefined) {
			throw new Error('approved must exist');
		}

		return new StandardCaseInput(
			maybe.id,
			maybe.part_number,
			maybe.delivery_note_number ? maybe.delivery_note_number.toString() : '',
			deliveryNoteDate,
			maybe.total_parts_tested,
			maybe.parts_NIO,
			maybe.parts_IO,
			maybe.parts_reworked,
			maybe.check_number,
			maybe.qai_id,
			maybe.type_of_inspection,
			maybe.error_types.map(ErrorInputFromJSON).filter((item: ErrorInputType) => item.amount),
			maybe.shift_id,
			date,
			maybe.approved,
			maybe.comment,
			maybe.photo && maybe.photo.name ? maybe.photo : undefined,
			maybe.location_id
		);
	}

	public static toJSON(standardCase: StandardCaseInput) {
		return {
			id: standardCase.id,
			part_number: standardCase.partNumber,
			delivery_note_number: standardCase.deliveryNoteNumber,
			delivery_note_date: standardCase?.deliveryNoteDate?.toISOString(),
			total_parts_tested: Number(standardCase.totalPartsTested),
			parts_NIO: standardCase.partsNIO,
			parts_IO: standardCase.partsIO,
			parts_reworked: Number(standardCase.partsReworked),
			check_number: standardCase.checkNumber,
			qai_id: standardCase.qai,
			error_types: standardCase.errorTypes.map(ErrorInputToJSON),
			photo: standardCase.photo,
			comment: standardCase.comment,
			type_of_inspection: standardCase.typeOfInspection,
			shift_id: standardCase.shift,
			date: standardCase.date.toISOString(),
			approved: standardCase.approved,
			location_id: standardCase.locationID,
		};
	}
}

export default StandardCaseInput;
