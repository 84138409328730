import React from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import moment from 'moment';

import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { rules } from '../consts/rules';
import StandardTextField from '../components/StandardTextField';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import { colors } from '../consts/colors';
import StandardDatePicker from '../components/StandardDatePicker';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import RhenusCase from '../service/types/RhenusCase';
import { rhenusCaseInputFields } from '../containers/RhenusCaseContainer';
import { createRhenusCasePartStatusKeyValuePairs } from '../helpers/rhenusInputHelper/generalRhenusHelper';
import { ContractZone, FileInterface, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import CameraDialog, { CameraDialogInterface } from '../components/CameraDialog';
import Contract from '../service/types/Contract';
import ApproveItem from '../components/ApproveItem';
import PhotoIcon from '../svgComponents/PhotoIcon';
import BarcodeScanner, { BarcodeScannerInterface } from '../components/BarcodeScanner';

interface Props {
	isUserEditing: boolean;
	rhenusCaseInput: RhenusCase | undefined;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	contract: Contract;
	shift: ShiftInterface | undefined;
	zone: ContractZone | undefined;
	onSubmitPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDialogPress: () => void;
	onDeleteErrorInputDialogPress: () => void;
	watch: (field: string) => any;
}

function RhenusCaseScreen(props: Props) {
	const globalClasses = useGlobalStyles();
	const commentRef = React.useRef<any>();
	const cameraDialogRef = React.useRef<CameraDialogInterface | undefined>();
	const barcodeDialogRef = React.useRef<BarcodeScannerInterface | undefined>();

	const { t } = useTranslation();
	const photo = props.watch(rhenusCaseInputFields.photo);
	const classes = useStyles({ disabled: !props.isUserEditing, photo: photo });
	const openCameraInCaptureMode = () => {
		cameraDialogRef.current?.setDialogState(true, false, undefined);
	};

	const openCameraInPreviewMode = () => {
		if (photo) cameraDialogRef.current?.setDialogState(true, true, photo);
	};

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow edit={props.isUserEditing} />
				<HomeButton edit={props.isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>
					{props.rhenusCaseInput
						? props.rhenusCaseInput.productionNumber
						: t('rhenus_case_screen_new_error_input')}
				</span>
			</div>
			<Grid container className={classes.topWrapper}>
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput ? props.rhenusCaseInput?.date : moment()}
						control={props.control}
						name={rhenusCaseInputFields.date}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('rhenus_case_zone_overview_screen_date').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								isUserNotEditing={!props.isUserEditing}
								error={props.errors[rhenusCaseInputFields.partNumber] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.partNumber]
										? props.errors[rhenusCaseInputFields.partNumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<StandardTextField
						label={t('rhenus_case_zone_overview_screen_shift').toUpperCase()}
						onChange={() => {}}
						value={props.shift ? (t(props.shift.name) as string) : ''}
						disabled={true}
						fixed={true}
						isUserNotEditing={!props.isUserEditing}
						error={props.errors[rhenusCaseInputFields.shift] ? true : false}
						helperText={
							props.errors[rhenusCaseInputFields.shift]
								? props.errors[rhenusCaseInputFields.shift].message
								: ''
						}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.productionNumber}
						control={props.control}
						name={rhenusCaseInputFields.productionNumber}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_production_number').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								onScanClick={() => {
									barcodeDialogRef.current?.setDialogState(true, (text: string) => {
										onChange(text);
									});
								}}
								error={props.errors[rhenusCaseInputFields.productionNumber] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.productionNumber]
										? props.errors[rhenusCaseInputFields.productionNumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.partNumberShould}
						control={props.control}
						name={rhenusCaseInputFields.partNumberShould}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_part_number_should').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.partNumberShould] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.partNumberShould]
										? props.errors[rhenusCaseInputFields.partNumberShould].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.partNumber}
						control={props.control}
						name={rhenusCaseInputFields.partNumber}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_part_number').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.partNumber] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.partNumber]
										? props.errors[rhenusCaseInputFields.partNumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.colorNumber}
						control={props.control}
						name={rhenusCaseInputFields.colorNumber}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_color_number_should').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.colorNumber] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.colorNumber]
										? props.errors[rhenusCaseInputFields.colorNumber].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.colorNumberShould}
						control={props.control}
						name={rhenusCaseInputFields.colorNumberShould}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_color_number').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.colorNumberShould] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.colorNumberShould]
										? props.errors[rhenusCaseInputFields.colorNumberShould].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<StandardTextField
						label={t('rhenus_case_screen_zone').toUpperCase()}
						onChange={() => {}}
						disabled={true}
						fixed={true}
						isUserNotEditing={!props.isUserEditing}
						value={props.zone ? props.zone.name : ''}
						error={props.errors[rhenusCaseInputFields.zone] ? true : false}
						helperText={
							props.errors[rhenusCaseInputFields.zone]
								? props.errors[rhenusCaseInputFields.zone].message
								: ''
						}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.storage}
						control={props.control}
						name={rhenusCaseInputFields.storage}
						rules={{ required: rules(t).required, pattern: rules(t).notEmptyPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_screen_storage_location').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.storage] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.storage]
										? props.errors[rhenusCaseInputFields.storage].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.partStatus}
						control={props.control}
						name={rhenusCaseInputFields.partStatus}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_part_status').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing}
								value={value}
								select
								error={props.errors[rhenusCaseInputFields.partStatus] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.partStatus]
										? props.errors[rhenusCaseInputFields.partStatus].message
										: ''
								}>
								{createRhenusCasePartStatusKeyValuePairs.map((item) => (
									<MenuItem key={item.key} value={item.value}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item.key)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.errorType}
						control={props.control}
						name={rhenusCaseInputFields.errorType}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_error_type').toUpperCase()}
								onChange={onChange}
								select
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.errorType] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.errorType]
										? props.errors[rhenusCaseInputFields.errorType].message
										: ''
								}>
								{props.contract.errorTypes.map((item) => (
									<MenuItem key={item} value={item}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />
				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.typeOfInspection}
						control={props.control}
						name={rhenusCaseInputFields.typeOfInspection}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_type_of_inspection').toUpperCase()}
								select
								disabled={!props.isUserEditing}
								value={value}
								onChange={onChange}
								error={props.errors[rhenusCaseInputFields.typeOfInspection] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.typeOfInspection]
										? props.errors[rhenusCaseInputFields.typeOfInspection].message
										: ''
								}>
								{props.contract.typesOfInspection.map((item) => (
									<MenuItem key={item} value={item}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
				<Grid item sm={1} className={classes.fieldWrapper} />

				<Grid item sm={3} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.rhenusCaseInput?.amountOfParts}
						control={props.control}
						name={rhenusCaseInputFields.amountOfParts}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_amount_of_parts').toUpperCase()}
								onChange={onChange}
								select
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.amountOfParts] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.amountOfParts]
										? props.errors[rhenusCaseInputFields.amountOfParts].message
										: ''
								}>
								{props.contract.amountOfParts?.map((item) => (
									<MenuItem key={item} value={item}>
										<div className={classes.inputWrapper}>
											<span className={classes.input}>{t(item)}</span>
										</div>
									</MenuItem>
								))}
							</StandardTextField>
						)}
					/>
				</Grid>
			</Grid>
			<div className={globalClasses.underline} />
			<Grid container className={classes.bottomWrapper}>
				<Grid
					item
					sm={3}
					className={classes.uploadImageWrapper}
					style={{ height: commentRef.current ? commentRef.current.offsetHeight : 'undefined' }}>
					{props.contract.isPhotoAllowed ? (
						<div className={classes.row}>
							<div className={classes.uploadPhotoWrapper}>
								<span
									className={globalClasses.standardLink}
									onClick={() => {
										if (props.isUserEditing) openCameraInCaptureMode();
									}}>
									{t('rhenus_case_screen_upload_photo')}
								</span>
							</div>
							<div className={classes.image} onClick={openCameraInPreviewMode}>
								{photo ? null : <PhotoIcon />}
							</div>
						</div>
					) : null}
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={8} className={classes.commentWrapper} ref={commentRef}>
					<Controller
						defaultValue={''}
						control={props.control}
						name={rhenusCaseInputFields.comment}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('rhenus_case_zone_overview_screen_comment').toUpperCase()}
								onChange={onChange}
								multiline={true}
								rows={4}
								placeholder={t('rhenus_case_screen_comment_placeholder')}
								disabled={!props.isUserEditing}
								value={value}
								error={props.errors[rhenusCaseInputFields.comment] ? true : false}
								helperText={
									props.errors[rhenusCaseInputFields.comment]
										? props.errors[rhenusCaseInputFields.comment].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
			</Grid>
			{props.contract?.automatedUpdates === false ? (
				<ApproveItem
					control={props.control}
					errors={props.errors}
					fieldName={rhenusCaseInputFields.approved}
					isUserEditing={props.isUserEditing}
				/>
			) : null}
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'rhenus_case_screen_cancel'}
					updateButtonText={'rhenus_case_screen_update'}
					createButtonText={'rhenus_case_screen_create'}
					deleteButtonText={'rhenus_case_screen_delete'}
					editButtonText={'rhenus_case_screen_edit'}
					data={props.rhenusCaseInput}
					isUserEditing={props.isUserEditing}
					onCancelPress={props.onCancelPress}
					onDeletePress={props.onDeletePress}
					onSubmitPress={props.onSubmitPress}
				/>
			</div>
			<BarcodeScanner ref={barcodeDialogRef} />
			<Controller
				control={props.control}
				name={rhenusCaseInputFields.photo}
				render={({ onChange }) => (
					<CameraDialog
						onChange={onChange}
						ref={cameraDialogRef}
						partNumber={props.rhenusCaseInput?.partNumber}
						cancelText={'rhenus_case_screen_camera_dialog_cancel_button'}
						saveText={'rhenus_case_screen_camera_dialog_save_button'}
						description={'rhenus_case_screen_camera_dialog_description'}
					/>
				)}
			/>
			<StandardDialog
				acceptText={'rhenus_case_screen_dialog_accept_button'}
				description={'rhenus_case_screen_dialog_description'}
				title={'rhenus_case_screen_dialog_title'}
				cancleText={'rhenus_case_screen_dialog_cancel_button'}
				onCancle={props.onCancelDialogPress}
				onAccept={props.onDeleteErrorInputDialogPress}
				ref={props.dialogRef}
				showWarning
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
	photo?: FileInterface;
}

const useStyles = makeStyles(() => ({
	approveWrapper: {
		marginBottom: '20px',
	},
	totalCheckWrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		transform: 'translateY(10px)',
	},
	totalCheckContentWrapper: {
		alignItems: 'center',
		paddingRight: '40px',
		transform: 'translateY(2px)',
	},
	titleWrapper: {
		marginBottom: '24px',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapper: {
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	fieldWrapper: {
		marginBottom: '40px !important',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
	bottomWrapper: {
		marginTop: '48px',
	},
	topWrapper: {
		marginBottom: '22px',
	},

	tableTitleWrapper: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: `2px solid #656565`,
	},

	commentWrapper: {
		marginTop: '64px',
	},
	uploadImageWrapper: {
		marginTop: '64px',
		display: 'flex',
		alignItems: 'flex-end',
	},
	tableRowWrapper: {
		borderBottom: '1px solid #C7C7C7',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
	},
	addErrorPart: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: '24px',
	},

	inputCheckNumber: {
		...globalInputStyle(),
		border: 'none',
		width: '70%',
		outline: 'none',
	},

	uploadPhotoWrapper: (props: StyleProps) => ({
		marginRight: '27px',
		cursor: props.disabled ? 'defalut' : 'pointer',
	}),
	image: (props: StyleProps) => ({
		position: 'relative',
		width: '100px',
		height: '100px',
		backgroundImage: props.photo ? `url(${props.photo.data})` : undefined,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100px 100px',
		backgroundColor: colors.keyButton,
		cursor: props.disabled || !props.photo ? 'default' : 'pointer',
	}),
}));

export default RhenusCaseScreen;
