import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fonts } from '../../consts/fonts';

interface Props {
	onClick: () => void;
	buttonText: string;
	value: string;
}

function ButtonCell(props: Props) {
	const classes = useStyles({ disabled: !props.value || props.value.length === 0 });
	const { t } = useTranslation();

	return (
		<div
			className={classes.buttonWrapper}
			onClick={() => {
				if (props.value && props.value.length !== 0) {
					props.onClick();
				}
			}}>
			<span className={classes.buttonText}>{t(props.buttonText)}</span>
		</div>
	);
}

interface StyleProps {
	disabled: boolean;
}

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	buttonText: (p: StyleProps) => ({
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '0.4px',
		fontFamily: fonts.semiBold,
		color: `rgba(26, 26, 26, ${p.disabled ? 0.2 : 1})`,
	}),
	buttonWrapper: (p: StyleProps) => ({
		cursor: p.disabled ? 'default' : 'pointer',
		padding: '4px 19px',
		margin: '0px 10px',
		borderRadius: '4px',
		backgroundColor: `rgba(255, 214, 0, ${p.disabled ? 0.2 : 1})`,
		width: 'fit-content',
	}),
}));

export default ButtonCell;
