import * as React from 'react';

function WorkingTimeIcon(props: any) {
	return (
		<svg width={18} height={18} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g transform="translate(1 1)" stroke="#111" strokeWidth={1.5} fill="none" fillRule="evenodd">
				<circle fill="#FFF7CC" strokeLinecap="square" cx={12.364} cy={12.364} r={3.636} />
				<path d="M6.545 8.727c-2.053 0-3.875.464-5.072.868A2.175 2.175 0 000 11.66v2.885h5.818" />
				<path
					d="M6.545 8.727c-2.008 0-3.636-2.355-3.636-4.363v-.728a3.636 3.636 0 117.273 0v.728c0 2.008-1.629 4.363-3.637 4.363zM12.364 10.91v1.454h1.454"
					strokeLinecap="square"
				/>
			</g>
		</svg>
	);
}

export default WorkingTimeIcon;
