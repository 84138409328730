export interface AddressInterface {
	postcode: string | number;
	city: string;
	street: string;
	houseNumber: string;
	country: string;
	costCenter?: string;
}

class Address implements AddressInterface {
	postcode: number;
	city: string;
	street: string;
	houseNumber: string;
	country: string;
	costCenter?: string;

	constructor(
		postcode: number,
		city: string,
		street: string,
		houseNumber: string,
		country: string,
		costCenter?: string
	) {
		this.postcode = postcode;
		this.city = city.trim();
		this.street = street.trim();
		this.houseNumber = houseNumber.trim();
		this.country = country.trim();
		this.costCenter = costCenter?.trim();
	}

	public static fromJSON(maybe: any): Address {
		if (!maybe) {
			throw Error('object must exist');
		}
		if (typeof maybe.postcode !== 'number') {
			throw Error('postcode must be type of number');
		}
		if (typeof maybe.city !== 'string') {
			throw Error('city must be type of string');
		}
		if (typeof maybe.street !== 'string') {
			throw Error('street must be type of string');
		}
		if (typeof maybe.house_number !== 'string') {
			throw Error('city must be type of string');
		}

		return new Address(
			maybe.postcode,
			maybe.city,
			maybe.street,
			maybe.house_number,
			maybe.country,
			maybe.cost_center
		);
	}

	public static toJSON(address: Address) {
		return {
			postcode: address.postcode,
			city: address.city,
			street: address.street,
			house_number: address.houseNumber,
			country: address.country,
			cost_center: address.costCenter,
		};
	}

	public toString() {
		return `${this.street} ${this.houseNumber}, ${this.postcode} ${this.city}, ${this.country}${
			this.costCenter ? ', ' + this.costCenter : ''
		}`;
	}
}

export default Address;
