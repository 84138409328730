import React from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { TableCellWithCss } from './table/StandardTableCell';
import { ExpenseTabelInterface } from '../consts/material';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import { fonts } from '../consts/fonts';
import AddExpenseItemCell from './AddExpenseItemCell';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';
import Travel from '../service/types/Travel';
import MaterialTool from '../service/types/MaterialTool';
import Transport from '../service/types/Transport';

interface Props {
	tableKey: string;
	expensesTablePramas: ExpenseTabelInterface;
	onAddPressHandler: (data: any) => Promise<boolean | MaterialTool | Travel | Transport>;
}

export const addExpenseFields = {
	name: 'name',
	quantity: 'quantity',
	qai: 'qai',
	billed: 'billed',
	price: 'price',
	travelTime: 'travelTime',
	distance: 'distance',
	waitedTime: 'waitedTime',
	typeOfTransport: 'typeOfTransport',
	type: 'type',
	edit: 'edit',
	reporter: 'reporter',
	date: 'date',
};

const data = Object.freeze({ ...addExpenseFields });
export type AddExpensesItemKeys = keyof typeof data;

function AddExpenseTabelItem({ tableKey, expensesTablePramas, onAddPressHandler }: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { control, errors, handleSubmit, setValue } = useForm();
	const { t } = useTranslation();
	const { user } = useAuthStateContext();

	const onAddPress = async (data: any) => {
		const res = await onAddPressHandler(data).catch(() => {});
		if (res) {
			Object.values(addExpenseFields).forEach((field) => setValue(field, ''));
		}
	};

	const keys: AddExpensesItemKeys[] = React.useMemo(
		() => Object.keys(expensesTablePramas) as AddExpensesItemKeys[],
		[expensesTablePramas]
	);

	return (
		<TableRow key={tableKey}>
			{keys.map((key, index) => {
				if (key === 'edit')
					return (
						<TableCellWithCss
							key={key + index}
							className={classes.itemWrapperStickyRight}
							style={{
								width: expensesTablePramas?.edit?.width ? expensesTablePramas?.edit?.width : '20px',
							}}>
							<div className={classes.add} onClick={handleSubmit(onAddPress)}>
								<span className={globalClasses.standardLink}>{t('expenses_screen_add')}</span>
							</div>
						</TableCellWithCss>
					);
				return key === 'billed' && user?.role === UserRole.QualityAssuranceInspector ? null : (
					<AddExpenseItemCell
						key={key + index}
						control={control}
						params={expensesTablePramas[key]}
						errors={errors}
						mainClass={index === 0 ? classes.itemWrapperStickyLeft : classes.itemWrapper}
						textClass={globalClasses.smallTextDark}
						inputKeyValueWrapper={classes.inputKeyValueWrapper}
						inputTextWrapper={classes.inputTextWrapper}
						fieldKey={key}
						wrapper={classes.wrapper}
					/>
				);
			})}
		</TableRow>
	);
}

const baseWrapper = {
	padding: '0px 14px',
	overflow: 'hidden',
	left: '0px',
	backgroundColor: colors.white,
};

const useStyles = makeStyles(() => ({
	itemWrapper: {
		...baseWrapper,
	},
	itemWrapperStickyLeft: {
		...baseWrapper,
		position: 'sticky',
		zIndex: 10,
	},
	itemWrapperStickyRight: {
		...baseWrapper,
		position: 'sticky',
		zIndex: 10,
		right: '0px',
		left: undefined,
	},
	wrapper: {
		width: '95%',
	},
	inputKeyValueWrapper: {
		width: '100%',
		padding: '6px 0px',
		transform: 'translateX(-10px)',
		'& .MuiInput-input': {
			margin: 0,
			color: colors.darkGray,
			fontSize: '14px',
			lineHeight: '20px',
			fontFamily: fonts.regular,
			borderRadius: '5px',
			paddingLeft: '12px',
		},
	},
	inputTextWrapper: {
		width: '100%',
		padding: '6px 0px',
	},
	add: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		cursor: 'pointer',
		width: 'fit-content',
	},
}));

export default AddExpenseTabelItem;
