import { Service } from '../../service/service';
import { History, LocationState } from 'history';
import { NotificationInterface } from '../../context/notification/notificationReducer';
import { goToAdminContracts } from '../navigationHelper';
import Contract from '../../service/types/Contract';

export function deleteContract(
	service: Service,
	contract: Contract | undefined,
	history: History<LocationState>,
	setNotification: (notification: NotificationInterface) => void
) {
	if (contract)
		service
			.deleteContractByID(contract.id)
			.then((res) => {
				if (res) {
					goToAdminContracts(history);
					setNotification({
						text: 'contract_screen_delete_notification',
						additional: contract.contractNumber,
					});
				}
			})
			.catch(() => {});
}
