import React from 'react';

export default function Arrow() {
	return (
		<svg width={30} height={22} viewBox="0 0 30 22">
			<g stroke="#111" strokeWidth={2} fill="none" fillRule="evenodd">
				<path d="M30 11H2" />
				<path strokeLinecap="square" d="M11 2l-9 9 9 9" />
			</g>
		</svg>
	);
}
