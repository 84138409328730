import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import ErrorIcon from '../svgComponents/ErrorIcon';
import { TextFieldWithCSS } from './StandardTextField';

interface Props {
	value?: string;
	error?: boolean;
	helperText?: string;
	onChange?: (event: any) => void;
	disabled?: boolean;
	select?: boolean;
	name?: string;
	children?: JSX.Element | JSX.Element[];
	placeholder?: string;
	onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
	customClassName?: string;
}

function TableTextField(props: Props) {
	const classes = useStyles({
		disabled: props.disabled,
	});
	const globalClasses = useGlobalStyles({ disabled: props.disabled });

	return (
		<div className={classes.container}>
			<TextFieldWithCSS
				value={props.value}
				select={props.select}
				disabled={props.disabled}
				placeholder={props.placeholder}
				children={props.children}
				onChange={props.onChange}
				error={props.error}
				className={`${classes.inputWrapper} ${props.customClassName || ''}`}
				onKeyDown={props.onKeyDown}
				SelectProps={{
					IconComponent: () => <div></div>,

					MenuProps: {
						PaperProps: {
							classes: {
								rounded: classes.dropdownContainer,
							},
						},
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
						style: {
							transform: 'translateX(-1px) translateY(7px)',
						},
					},
					classes: {
						selectMenu: classes.dropdownMenuWrapper,
					},
				}}
				InputProps={{
					classes: {
						input: classes.input,
						underline: classes.underlineField,
					},
				}}
			/>
			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error}>{props.helperText}</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: {
		width: '100%',
		borderRadius: '4px',
		backgroundColor: colors.white,
	},
	dropdownContainer: {
		boxShadow: 'none',
		border: `1px solid ${colors.borderColor}`,
		'& ul > li:first-child': {
			display: 'none',
		},
	},
	dropdownArrowWrapper: { position: 'absolute', right: '12.5px', cursor: 'pointer' },
	dropdownMenuWrapper: {
		height: '1.1876em',
	},
	input: (p: StyleProps) => ({
		...globalInputStyle(p.disabled),
		whiteSpace: 'pre',
		color: colors.darkGray,
		borderRadius: '5px',
	}),

	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
	inputSelect: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	inputWrapperSelect: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		transform: 'translateY(-2px)',
	},
}));

export default TableTextField;
