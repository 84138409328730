import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ContractType } from '../helpers/contractHelper/generalContractHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';

import ContractScreen from '../screens/ContractScreen';
import service from '../service/service';
import Contract from '../service/types/Contract';
import { useHistory, useParams } from 'react-router-dom';
import { StandardDialogInterface } from '../components/StandardDialog';
import { goToAdminContracts, goToSummary } from '../helpers/navigationHelper';
import { getCustomerOptionsKeyValuePairs } from '../helpers/customerHelper/readCustomerHelper';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import {
	onContractScreenMount,
	setBasicContractInfo,
	setContractInfo,
} from '../helpers/contractHelper/readContractHelper';
import { createContract } from '../helpers/contractHelper/createContractHelper';
import { updateContract } from '../helpers/contractHelper/updateContractHelper';
import { FileInputIntreface } from '../components/StandardFileInput';
import { deleteContract } from '../helpers/contractHelper/deleteContractHelper';
import { useNotificationActionContext } from '../context/notification/useNotification';
import { RouteParams } from '../router/Routes';
import Spinner from '../components/Spinner';
import User from '../service/types/User';
import { unstable_batchedUpdates } from 'react-dom';
import { priceListOptions } from '../consts/priceListOptions';
import { useTranslation } from 'react-i18next';

export const contractFields = {
	date: 'date',
	zones: 'zones',
	contractType: 'contractType',
	errorTypes: 'errorTypes',
	typesOfInspection: 'typesOfInspection',
	contractNumber: 'contractNumber',
	partDesignation: 'partDesignation',
	markingOfIOparts: 'markingOfIOparts',
	takingPhotos: 'takingPhotos',
	customer: 'customer',
	assignQAI: 'assignQAI',
	assignTeamLeader: 'assignTeamLeader',
	tlGroups: 'tlGroups',
	automatedUpdates: 'automatedUpdates',
	typeOfActivity: 'typeOfActivity',
	shiftName: 'shiftName',
	from: 'from',
	to: 'to',
	shiftTimes: 'shiftTimes',
	pdfContract: 'pdfContract',
	orderDescription: 'orderDescription',
	contactPerson: 'contactPerson',
	contactQAI24: 'contactQAI24',
	attachments: 'attachments',
	amountOfParts: 'amountOfParts',
	billingCycle: 'billingCycle',
	drivingRate: 'drivingRate',
	driving_time: 'driving_time',
	kilometer: 'kilometer',
	priceListType: 'priceListType',
	hourlyRate: 'hourlyRate',
	projectType: 'projectType',
	calculationType: 'calculationType',
	administrationFee: 'administrationFee',
	applicationIntake: 'applicationIntake',
};

export interface WorkLocationFormValues {
	name: string;
	distance: string;
}

export interface ShiftsFormValues {
	earlyShift: {
		from: string;
		to: string;
	};
	dayShift: {
		from: string;
		to: string;
	};
	lateShift: {
		from: string;
		to: string;
	};
	nightShift: {
		from: string;
		to: string;
	};
}

export interface IHourlyRateField {
	id: string;
	value: string;
	selectedValue: string;
}

interface MountedRefInterface {
	qais: React.MutableRefObject<boolean>;
	customer: React.MutableRefObject<boolean>;
	teamLeader: React.MutableRefObject<boolean>;
}

export const contractWorkLocationFields = {
	distance: 'distance',
	name: 'name',
};

function ContractContainer() {
	const { control, handleSubmit, errors, setValue, watch, getValues, register } = useForm();
	const {
		control: shiftControl,
		errors: shiftErrors,
		setError: setShiftErrors,
		clearErrors: clearShiftErrors,
		getValues: getShiftValues,
		setValue: setShiftValue,
	} = useForm<ShiftsFormValues>({
		defaultValues: {
			dayShift: {
				from: '',
				to: '',
			},
			earlyShift: {
				from: '',
				to: '',
			},
			lateShift: {
				from: '',
				to: '',
			},
			nightShift: {
				from: '',
				to: '',
			},
		},
	});

	const {
		control: workLocationControl,
		errors: workLocationErrors,
		handleSubmit: handleWorkLocationSubmit,
		reset: resetWorkLocationForm,
	} = useForm<WorkLocationFormValues>();

	const [workLocations, setWorkLocations] = useState<any>([]);

	const { t } = useTranslation();

	const [checkedShifts, setCheckedShifts] = useState<string[]>([]);

	const { setNotification } = useNotificationActionContext();

	const [contract, setContract] = React.useState<Contract | undefined>();
	const [teamLeaders, setTeamLeaders] = React.useState<KeyValue[]>([]);
	const [qais, setQAIs] = React.useState<KeyValue[]>([]);
	const [customers, setCustomers] = React.useState<KeyValue[]>([]);
	const [isUserEditing, setIsUserEditing] = React.useState(false);
	const [mounted, setMounted] = React.useState(false);

	const qaisLoading = React.useRef(false);
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const mountedRefs = React.useRef<MountedRefInterface>({
		customer: React.useRef(false),
		qais: React.useRef(false),
		teamLeader: React.useRef(false),
	});
	const history = useHistory();
	const contractType = watch(contractFields.contractType);
	const teamLeader = watch(contractFields.assignTeamLeader);
	const assignedQAIs = watch(contractFields.assignQAI);
	const customer = watch(contractFields.customer);
	const attachmentsInputRef = React.useRef<FileInputIntreface>();
	const routeParams: RouteParams = useParams();
	const mountedCustomer = React.useRef(false);

	const {
		fields: hourlyRateFields,
		append: appendHourlyRateField,
		remove: removeHourlyRateField,
	} = useFieldArray({
		control,
		name: contractFields.hourlyRate,
	});

	const getData = React.useCallback(async () => {
		try {
			const leaders = await getUserOptionsKeyValuePairs(service.getAllTeamLeaders(), () => {}, {
				property: 'deleted',
				value: false,
			});
			const customers = await getCustomerOptionsKeyValuePairs(service.getAllCustomers(), () => {});

			unstable_batchedUpdates(() => {
				setTeamLeaders(leaders);
				setCustomers(customers);
			});
		} catch (e) {}
	}, []);

	React.useEffect(() => {
		getData();
	}, [getData]);

	React.useEffect(() => {
		if (contract) {
			setValue(contractFields.tlGroups, contract?.tlGroups ?? []);
			if (typeof contract.billingCycle !== 'undefined')
				setValue(contractFields.billingCycle, contract.billingCycle);
			if (typeof contract.projectType !== 'undefined') setValue(contractFields.projectType, contract.projectType);
			if (typeof contract.calculationType !== 'undefined')
				setValue(contractFields.calculationType, contract.calculationType);

			if (typeof contract.drivingRate !== 'undefined' && contract.drivingRate !== null) {
				setValue(contractFields.drivingRate, contract.drivingRate);
			}
			if (typeof contract.drivingTime !== 'undefined' && contract.drivingTime !== null)
				setValue(contractFields.driving_time, contract.drivingTime);
			if (typeof contract.kilometer !== 'undefined' && contract.kilometer !== null)
				setValue(contractFields.kilometer, contract.kilometer);
			if (typeof contract.administrationFee !== 'undefined' && contract.administrationFee !== null)
				setValue(contractFields.administrationFee, contract.administrationFee);
			if (typeof contract.applicationIntake !== 'undefined' && contract.applicationIntake !== null)
				setValue(contractFields.applicationIntake, contract.applicationIntake);

			setWorkLocations(contract.workLocations);

			const shiftKeys = ['dayShift', 'earlyShift', 'lateShift', 'nightShift'];
			const defaultCheckedShifts = contract.shiftTimes
				.filter((shift) => shiftKeys.some((key) => key === shift.name))
				.map((val) => val.name);

			defaultCheckedShifts.forEach((shift) => {
				const defaultShift = contract.shiftTimes.find((el) => el.name === shift);

				if (defaultShift) {
					setShiftValue(`${shift}.from`, defaultShift.from);
					setShiftValue(`${shift}.to`, defaultShift.to);
				}
			});

			setCheckedShifts(defaultCheckedShifts);

			if (typeof contract.priceList !== 'undefined') {
				const values = priceListOptions
					.filter((option) => contract.priceList?.some((item) => item.type === option.value))
					.map((val) => val.label);

				const optionFiltered = priceListOptions.filter((item) =>
					contract.priceList?.some((val) => val.type === item.value)
				);

				const contractPriceListFiltered = optionFiltered.map((val) => {
					const found = contract.priceList?.find((item) => item.type === val.value);

					return {
						selectedValue: val.label,
						value: found ? found.hourly_rate : 0,
					};
				});

				setValue(contractFields.hourlyRate, contractPriceListFiltered);
				setValue(contractFields.priceListType, values);
			}
		}
	}, [contract, setShiftValue, setValue]);

	React.useEffect(() => {
		if (contract && teamLeaders.length !== 0 && !mountedRefs.current.teamLeader.current) {
			mountedRefs.current.teamLeader.current = true;
			setValue(
				contractFields.assignTeamLeader,
				teamLeaders.find((item) => item.value.id === contract.assignedTeamLeader)?.key
			);
		}
	}, [contract, teamLeaders, setValue]);

	React.useEffect(() => {
		if (!mountedCustomer.current && customer) {
			mountedCustomer.current = true;
		} else {
			setValue(contractFields.contactPerson, undefined);
		}
	}, [customer, setValue]);

	React.useEffect(() => {
		if (contract && customers.length !== 0 && !mountedRefs.current.customer.current) {
			mountedRefs.current.customer.current = true;
			const currCustomer = customers.find((item) => item.value.id === contract.customer);

			const contactPeopleIds = contract.contactPerson?.map((el) => +el.id);
			const contactPeople: User[] = currCustomer?.value.users.filter((user: User) =>
				contactPeopleIds?.includes(user.id)
			);

			setValue(contractFields.customer, currCustomer?.key);
			setValue(
				contractFields.contactPerson,
				contactPeople.length > 0
					? contactPeople?.map((user) => `${user.firstName} ${user.lastName}`)
					: undefined
			);
		}
	}, [contract, customers, setValue]);

	React.useEffect(() => {
		if (contract && qais.length !== 0 && !mountedRefs.current.qais.current) {
			mountedRefs.current.qais.current = true;
			setValue(
				contractFields.assignQAI,
				contract.assignedQAIs.map((id) => qais.find((qai) => qai.value.id === id)?.key)
			);
			setValue(contractFields.contactQAI24, qais.find((qai) => qai.value.id === contract.contactQAI24)?.key);
		}
	}, [contract, qais, setValue]);

	React.useEffect(() => {
		onContractScreenMount(history, routeParams, setContract, setIsUserEditing, setMounted);
	}, [history, routeParams]);

	React.useEffect(() => {
		if (contract) {
			setBasicContractInfo(contract, setValue);
		}
	}, [contract, setValue]);

	React.useEffect(() => {
		if (contractType === ContractType.Standard) {
			setValue(contractFields.zones, []);
		}
	}, [contractType, setValue]);

	React.useEffect(() => {
		const currentTeamLeader = teamLeaders.find((item) => item.key === teamLeader);
		if (currentTeamLeader) {
			if (!qaisLoading.current) {
				qaisLoading.current = true;
				getUserOptionsKeyValuePairs(service.getAllQAIsByTeamLeader(currentTeamLeader.value.id), () => {})
					.then((res) => {
						setQAIs(res);
						qaisLoading.current = false;
					})
					.catch((e) => {
						qaisLoading.current = false;
					});
			}
		} else {
			setQAIs([]);
			setValue(contractFields.assignQAI, null);
		}
	}, [teamLeader, teamLeaders, getValues, setValue]);

	React.useEffect(() => {
		if (assignedQAIs && !assignedQAIs.some((qai: string) => qai === getValues(contractFields.contactQAI24))) {
			setValue(contractFields.contactQAI24, undefined);
		}
		if (!assignedQAIs) {
			setValue(contractFields.contactQAI24, undefined);
		}
	}, [assignedQAIs, getValues, setValue]);

	const onCreatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				createContract(
					history,
					data,
					getShiftValues(),
					checkedShifts,
					customers,
					teamLeaders,
					qais,
					workLocations,
					hourlyRateFields as IHourlyRateField[]
				);
			}),
		[
			handleSubmit,
			history,
			getShiftValues,
			checkedShifts,
			customers,
			teamLeaders,
			qais,
			hourlyRateFields,
			workLocations,
		]
	);

	const onCancelPress = () => {
		if (contract) {
			setContractInfo(
				contract,
				teamLeaders,
				customers,
				qaisLoading,
				mountedRefs.current.qais,
				attachmentsInputRef,
				setQAIs,
				setValue,
				setShiftValue,
				setCheckedShifts,
				setWorkLocations
			);

			setIsUserEditing(false);
		} else goToAdminContracts(history);
	};

	const onDeletePress = () => dialogRef.current?.setDialogState(true);
	const onCancelDialogPress = () => dialogRef.current?.setDialogState(false);

	const onDeleteContractDialogPress = () => {
		deleteContract(service, contract, history, setNotification);
	};

	const onEditPress = () => setIsUserEditing(true);

	const onUpdatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (contract)
					updateContract(
						contract,
						data,
						getShiftValues(),
						checkedShifts,
						customers,
						teamLeaders,
						qais,
						history,
						setIsUserEditing,
						setContract,
						workLocations,
						hourlyRateFields as IHourlyRateField[]
					);
			}),
		[
			handleSubmit,
			contract,
			getShiftValues,
			checkedShifts,
			customers,
			teamLeaders,
			qais,
			history,
			hourlyRateFields,
			workLocations,
		]
	);
	const onSubmitPress = () => {
		if (contract) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		} else {
			onCreatePress();
		}
	};

	const onSummaryPress = () => {
		if (contract) {
			goToSummary(history, {
				contractID: contract.id,
				contractNumber: contract.contractNumber,
				status: contract.status,
				contractCustomer: contract.customer.toString(),
				workLocations: contract.workLocations,
			});
		}
	};

	const onShiftCheckboxChange = (val: boolean, name: string) => {
		const newChecked = checkedShifts.filter((shift) => shift !== name);

		const { from, to } = getShiftValues()[name as keyof ShiftsFormValues];

		if (val) {
			setCheckedShifts([...newChecked, name]);

			if (!from) {
				setShiftErrors(`${name}.from`, {
					message: t('field_required'),
				});
			}

			if (!to) {
				setShiftErrors(`${name}.to`, {
					message: t('field_required'),
				});
			}
		} else {
			setCheckedShifts(newChecked);
			clearShiftErrors([`${name}.from`, `${name}.to`]);
		}
	};

	const onWorkLocationSubmitPress = React.useMemo(
		() =>
			handleWorkLocationSubmit((data) => {
				resetWorkLocationForm({
					distance: '',
					name: '',
				});

				setWorkLocations((prev: any) => {
					return [
						...prev,
						{
							distance: parseFloat(data.distance),

							name: data.name,
						},
					];
				});
			}),
		[handleWorkLocationSubmit, resetWorkLocationForm]
	);

	if (!mounted) return <Spinner />;

	return (
		<ContractScreen
			customers={customers}
			control={control}
			register={register}
			errors={errors}
			qais={qais}
			setValue={setValue}
			getValues={getValues}
			teamLeaders={teamLeaders}
			contractType={contractType}
			isUserEditing={isUserEditing}
			shiftControl={shiftControl}
			shiftErrors={shiftErrors}
			contract={contract}
			dialogRef={dialogRef}
			attachmentsInputRef={attachmentsInputRef}
			hourlyRateFields={hourlyRateFields}
			checkedShifts={checkedShifts}
			workLocations={workLocations}
			workLocationControl={workLocationControl}
			workLocationErrors={workLocationErrors}
			onCancelDialogPress={onCancelDialogPress}
			onDeleteContractDialogPress={onDeleteContractDialogPress}
			setWorkLocations={setWorkLocations}
			onSubmitPress={onSubmitPress}
			onCancelPress={onCancelPress}
			onDeletePress={onDeletePress}
			onSummaryPress={onSummaryPress}
			appendHourlyRateField={appendHourlyRateField}
			removeHourlyRateField={removeHourlyRateField}
			onShiftCheckboxChange={onShiftCheckboxChange}
			clearShiftErrors={clearShiftErrors}
			onWorkLocationSubmitPress={onWorkLocationSubmitPress}
			assignedQAIs={assignedQAIs}
			customer={customer}
		/>
	);
}

export default ContractContainer;
