import { ErrorInputType } from '../../service/types/StandardCase';
import { KeyValue } from '../userHelper/generalUserHelper';

export const DEFAULT_NO_VALUE = {
	key: ' ',
	value: '',
};

export function ErrorInputFromJSON(maybe: any): ErrorInputType {
	if (typeof maybe.amount !== 'number') {
		throw new Error('amount must be number');
	}
	if (typeof maybe.type_name !== 'string') {
		throw new Error('type must be string');
	}
	return {
		amount: maybe.amount,
		type: maybe.type_name,
	};
}

export function ErrorInputToJSON(error: ErrorInputType) {
	return {
		amount: Number(error.amount),
		type_name: error.type,
	};
}

export enum ApprovedStatus {
	Approved = 'approved_status_aprroved',
	NotApproved = 'approved_status_not_approved',
}

export const filterApprovedStatusKeyValuePairs: KeyValue[] = [
	DEFAULT_NO_VALUE,
	{ key: ApprovedStatus.Approved, value: true },
	{ key: ApprovedStatus.NotApproved, value: false },
];

export const textsForStandardAutomaticalUpdate = [
	'standard_update_data_text_now',
	'standard_update_data_text_one_minute',
	'standard_update_data_text_two_minutes',
	'standard_update_data_text_three_minutes',
	'standard_update_data_text_four_minutes',
	'standard_update_data_text_five_minutes',
];
