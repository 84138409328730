import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import NewLink from '../../../components/NewLink';
import IQRLogo from '../../../svgComponents/IQRLogo';
import HomeButton from '../../../svgComponents/HomeButton';

import { Routes } from '../../../router/Routes';
import { useGlobalStyles } from '../../../consts/globalStyles';

export default function Header() {
	const { t } = useTranslation();
	const classes = useStyles();
	const globalClasses = useGlobalStyles();

	return (
		<>
			<Link to={Routes.Home}>
				<IQRLogo className={globalClasses.logo} />
			</Link>
			<div className={globalClasses.homeButtonWrapper}>
				<div />
				<HomeButton />
			</div>
			<div className={classes.row}>
				<div className={classes.titleWrapper}>
					<span className={globalClasses.title}>{t('group_overview_title')}</span>
				</div>
				<NewLink route={Routes.GroupCreate} text={'group_overview_new_group'} />
			</div>
		</>
	);
}

const useStyles = makeStyles(() => ({
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleWrapper: {
		marginBottom: '12px',
	},
}));
