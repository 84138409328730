import { Moment } from 'moment';

export class InvoiceItem {
	id: number;
	creation_date: Moment;
	date_of_invoice: string;
	from_date: string;
	to_date: string;
	completed: boolean;
	contract_id: number;
	customer: string;
	bill_number?: string;
	number_of_shifts?: number;
	number_of_workers?: number;
	inspector_hours?: number;
	inspector_late_hours?: number;
	inspector_night_hours?: number;
	inspector_sunday_night_hours?: number;
	inspector_week_overtime_hours?: number;
	teamlead_hours?: number;
	teamlead_late_hours?: number;
	teamlead_night_hours?: number;
	teamlead_sunday_night_hours?: number;
	teamlead_week_overtime_hours?: number;
	resident_hours?: number;
	resident_late_hours?: number;
	resident_night_hours?: number;
	resident_sunday_night_hours?: number;
	resident_week_overtime_hours?: number;
	gloves?: number;
	travel_time?: number;
	kilometers?: number;
	travel_fee?: number;
	administration_fee?: number;
	application_intake?: number;

	constructor(
		id: number,
		creation_date: Moment,
		date_of_invoice: string,
		from_date: string,
		to_date: string,
		completed: boolean,
		contract_id: number,
		customer: string,
		bill_number?: string,
		number_of_shifts?: number,
		number_of_workers?: number,
		inspector_hours?: number,
		inspector_late_hours?: number,
		inspector_night_hours?: number,
		inspector_sunday_night_hours?: number,
		inspector_week_overtime_hours?: number,
		teamlead_hours?: number,
		teamlead_late_hours?: number,
		teamlead_night_hours?: number,
		teamlead_sunday_night_hours?: number,
		teamlead_week_overtime_hours?: number,
		resident_hours?: number,
		resident_late_hours?: number,
		resident_night_hours?: number,
		resident_sunday_night_hours?: number,
		resident_week_overtime_hours?: number,
		gloves?: number,
		travel_time?: number,
		kilometers?: number,
		travel_fee?: number,
		administration_fee?: number,
		application_intake?: number
	) {
		this.id = id;
		this.creation_date = creation_date;
		this.date_of_invoice = date_of_invoice;
		this.from_date = from_date;
		this.to_date = to_date;
		this.completed = completed;
		this.contract_id = contract_id;
		this.customer = customer;
		this.bill_number = bill_number;
		this.number_of_shifts = number_of_shifts;
		this.number_of_workers = number_of_workers;
		this.inspector_hours = inspector_hours;
		this.inspector_late_hours = inspector_late_hours;
		this.inspector_night_hours = inspector_night_hours;
		this.inspector_sunday_night_hours = inspector_sunday_night_hours;
		this.inspector_week_overtime_hours = inspector_week_overtime_hours;
		this.teamlead_hours = teamlead_hours;
		this.teamlead_late_hours = teamlead_late_hours;
		this.teamlead_night_hours = teamlead_night_hours;
		this.teamlead_sunday_night_hours = teamlead_sunday_night_hours;
		this.teamlead_week_overtime_hours = teamlead_week_overtime_hours;
		this.resident_hours = resident_hours;
		this.resident_late_hours = resident_late_hours;
		this.resident_night_hours = resident_night_hours;
		this.resident_sunday_night_hours = resident_sunday_night_hours;
		this.resident_week_overtime_hours = resident_week_overtime_hours;
		this.gloves = gloves;
		this.travel_time = travel_time;
		this.kilometers = kilometers;
		this.travel_fee = travel_fee;
		this.administration_fee = administration_fee;
		this.application_intake = application_intake;
	}

	public static fromJSON(maybe: any): InvoiceItem {
		if (!maybe) {
			throw new Error('must exist');
		}

		return new InvoiceItem(
			maybe.id,
			maybe.creation_date,
			maybe.date_of_invoice,
			maybe.from_date,
			maybe.to_date,
			maybe.completed,
			maybe.contract_id,
			maybe.customer,
			maybe.bill_number,
			maybe.number_of_shifts,
			maybe.number_of_workers,
			maybe.inspector_hours,
			maybe.inspector_late_hours,
			maybe.inspector_night_hours,
			maybe.inspector_sunday_night_hours,
			maybe.inspector_week_overtime_hours,
			maybe.teamlead_hours,
			maybe.teamlead_late_hours,
			maybe.teamlead_night_hours,
			maybe.teamlead_sunday_night_hours,
			maybe.teamlead_week_overtime_hours,
			maybe.resident_hours,
			maybe.resident_late_hours,
			maybe.resident_night_hours,
			maybe.resident_sunday_night_hours,
			maybe.resident_week_overtime_hours,
			maybe.gloves,
			maybe.travel_time,
			maybe.kilometers,
			maybe.travel_fee,
			maybe.administration_fee,
			maybe.application_intake
		);
	}
}

class Invoice {
	id: number;
	creation_date: Moment;
	date_of_invoice: Moment;
	from_date: Moment;
	to_date: Moment;

	constructor(id: number, creation_date: Moment, date_of_invoice: Moment, from_date: Moment, to_date: Moment) {
		this.id = id;
		this.creation_date = creation_date;
		this.date_of_invoice = date_of_invoice;
		this.from_date = from_date;
		this.to_date = to_date;
	}

	public static fromJSON(maybe: any) {
		if (!maybe) {
			throw new Error('must exist');
		}

		return new Invoice(maybe.id, maybe.creation_date, maybe.date_of_invoice, maybe.from_date, maybe.to_date);
	}
}

export default Invoice;
