import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';

interface Props {
	onBackArrowPress?: () => void;
	edit?: boolean;
}

function BackArrow(props: Props) {
	const history = useHistory();
	const classes = useStyles({});
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);
	const { t } = useTranslation();

	const onBackPress = () => {
		props.edit ? dialogRef.current?.setDialogState(true) : history.goBack();
	};

	const onAcceptDialog = () => history.goBack();
	const onCancelDialog = () => dialogRef.current?.setDialogState(false);

	return (
		<div>
			<div className={classes.container} onClick={props.onBackArrowPress ? props.onBackArrowPress : onBackPress}>
				<svg width={30} height={22} viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg">
					<g stroke="#111" strokeWidth={2} fill="none" fillRule="evenodd">
						<path d="M30 11H2" />
						<path strokeLinecap="square" d="M11 2l-9 9 9 9" />
					</g>
				</svg>
			</div>
			<StandardDialog
				onAccept={onAcceptDialog}
				onCancle={onCancelDialog}
				acceptText={t('warning_data_dialog_accept_button')}
				title={t('warning_data_dialog_title')}
				description={t('warning_data_dialog_description')}
				cancleText={t('warning_data_dialog_cancel_button')}
				ref={dialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	container: {
		cursor: 'pointer',
	},
}));

export default BackArrow;
