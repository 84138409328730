import React from 'react';
import { NotificationInterface, NotificationState } from './notificationReducer';

export type NotificationContextActions = {
	setNotification: (notification: NotificationInterface) => void;
	hideNotification: () => void;
};

const initialStateContext: NotificationState = {
	visible: false,
	notification: {
		text: '',
		additional: '',
	},
};

const initalActionContext: NotificationContextActions = {
	setNotification: (notification: NotificationInterface) => {},
	hideNotification: () => {},
};

export const NotificationStateContext = React.createContext(initialStateContext);
export const NotificationActionsContext = React.createContext(initalActionContext);
