import User from '../../service/types/User';

export type AuthState = {
	user: User | undefined;
};

export type Action = {
	type: string;
	[key: string]: any;
};

export const authInitialState: AuthState = {
	user: undefined,
};

export const AUTH_TOKEN = 'AUTH_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const SET_USER = 'SET_USER';

const authReducer = (state = authInitialState, action: Action) => {
	switch (action.type) {
		case SET_USER:
			return { ...state, user: action.user };

		default:
			return state;
	}
};

export default authReducer;
