import { Service } from '../service/service';
import Contract from '../service/types/Contract';
import User, { CreateEmployeeParams, UpdateEmployeeParams } from '../service/types/User';
import { QAILoginParams, ResetPasswordParams, UserLoginParams } from '../helpers/userHelper/generalUserHelper';
import Customer from '../service/types/Customer';
import StandardCaseInput, { StandardCaseFilterInterface } from '../service/types/StandardCase';
import { ContactParams } from '../containers/ContactUsContainer';
import RhenusOverviewInformation from '../service/types/RhenusOverviewInformation';
import RhenusCase from '../service/types/RhenusCase';
import WorkingTime from '../service/types/WorkingTime';
import MaterialTool from '../service/types/MaterialTool';
import Travel from '../service/types/Travel';
import { ContractStatusType, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import { LoginResponse } from '../consts/login';
import { NotificationInterface } from '../context/notification/notificationReducer';
import Transport from '../service/types/Transport';
import { Moment } from 'moment';
import BreakTime from '../service/types/BreakTime';
import { DashboardFilterInterface } from '../helpers/dashboardHelper/generalDashboardHelper';
import { WorkingTimeFilterInterface } from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import { InvoiceItem } from '../service/types/Invoice';
import { CreateGroup, UpdateGroup } from '../pages/GroupCreatePage';
import { Group } from '../pages/groupOverview/GroupOverview';

class SuccessMiddleware implements Service {
	next: Service;
	setNotification: (notification: NotificationInterface) => void;

	constructor(next: Service, setNotification: (notification: NotificationInterface) => void) {
		this.next = next;
		this.setNotification = setNotification;
	}

	public setJWTToken(JWTToken: string) {
		this.next.setJWTToken(JWTToken);
	}

	public async login(params: UserLoginParams): Promise<LoginResponse | undefined> {
		return this.next.login(params);
	}

	public async loginQAI(params: QAILoginParams): Promise<LoginResponse | undefined> {
		return this.next.loginQAI(params);
	}

	public async checkToken(): Promise<boolean> {
		return this.next.checkToken();
	}
	public async getAuthToken(refreshToken: string): Promise<string | undefined> {
		return this.next.getAuthToken(refreshToken);
	}

	public async requestPasswordReset(email: string): Promise<boolean> {
		const res = await this.next.requestPasswordReset(email);
		this.setNotification({
			text: 'forgot_password_request_success',
			additional: '',
		});
		return res;
	}

	public async checkPasswordRessetToken(token: string): Promise<boolean> {
		return this.next.checkPasswordRessetToken(token);
	}

	public async resetPassword(params: ResetPasswordParams): Promise<boolean> {
		return await this.next.resetPassword(params);
	}
	public async resetPasswordQAI(params: ResetPasswordParams): Promise<boolean> {
		return await this.next.resetPasswordQAI(params);
	}
	public async getAllUsers(): Promise<User[]> {
		return await this.next.getAllUsers();
	}

	public async readGroups(): Promise<Group[]> {
		return await this.next.readGroups();
	}
	public async createGroup(data: CreateGroup): Promise<boolean> {
		return await this.next.createGroup(data);
	}
	public async readGroup(id: number): Promise<Group> {
		return await this.next.readGroup(id);
	}
	public async updateGroup(data: UpdateGroup): Promise<boolean> {
		return await this.next.updateGroup(data);
	}
	public async deleteGroup(id: number): Promise<boolean> {
		return await this.next.deleteGroup(id);
	}

	public async logout(): Promise<boolean> {
		return await this.next.logout();
	}

	public async getContracts(): Promise<Contract[]> {
		return await this.next.getContracts();
	}
	public async createEmployee(params: CreateEmployeeParams): Promise<boolean> {
		const res = await this.next.createEmployee(params);
		this.setNotification({
			text: 'user_screen_create_notification',
			additional: `${params.first_name} ${params.last_name}`,
		});
		return res;
	}
	public async createQAI(params: CreateEmployeeParams): Promise<boolean> {
		const res = await this.next.createQAI(params);
		this.setNotification({
			text: 'user_screen_create_notification',
			additional: `${params.first_name} ${params.last_name}`,
		});
		return res;
	}
	public async getUserInfo(): Promise<User> {
		return await this.next.getUserInfo();
	}
	public async getAllQAIs(): Promise<User[]> {
		return await this.next.getAllQAIs();
	}
	public async getAllTeamLeaders(): Promise<User[]> {
		return await this.next.getAllTeamLeaders();
	}
	public async getAllCustomers(): Promise<Customer[]> {
		return await this.next.getAllCustomers();
	}
	public async getAllQAIsByTeamLeader(teamLeaderID: number): Promise<User[]> {
		return await this.next.getAllQAIsByTeamLeader(teamLeaderID);
	}
	public async createCustomer(customer: Customer): Promise<number> {
		const res = await this.next.createCustomer(customer);
		return res;
	}
	public async createUserForCustomer(id: number, params: CreateEmployeeParams): Promise<number> {
		const res = await this.next.createUserForCustomer(id, params);
		this.setNotification({
			text: 'contact_person_screen_create_notification',
			additional: `${params.first_name} ${params.last_name}`,
		});
		return res;
	}
	public async getCustomerFromUser(id: number): Promise<Customer> {
		return await this.next.getCustomerFromUser(id);
	}
	public async deleteUserByID(id: number): Promise<boolean> {
		return await this.next.deleteUserByID(id);
	}
	public async updateUserByID(id: number, params: UpdateEmployeeParams): Promise<boolean> {
		const res = await this.next.updateUserByID(id, params);
		this.setNotification({
			text: 'contact_person_screen_update_notification',
			additional: `${params.first_name} ${params.last_name}`,
		});
		return res;
	}
	public async updateCustomerByID(id: number, customer: Customer): Promise<boolean> {
		const res = await this.next.updateCustomerByID(id, customer);
		this.setNotification({ text: 'customer_screen_update_notification', additional: customer.name });
		return res;
	}
	public async getAllStandardCaseInputs(contractID: number): Promise<StandardCaseInput[]> {
		return await this.next.getAllStandardCaseInputs(contractID);
	}
	public async updateStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		const res = await this.next.updateStandradCaseInput(contractID, errorInput);
		this.setNotification({
			text: 'standard_case_screen_update_notification',
			additional: errorInput.partNumber,
		});
		return res;
	}
	public async createContract(contract: Contract): Promise<number> {
		const res = await this.next.createContract(contract);
		this.setNotification({
			text: 'contract_screen_create_notification',
			additional: contract.contractNumber,
		});
		return res;
	}

	public async createInvoice(
		contractID: number,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment
	): Promise<number> {
		const res = await this.next.createInvoice(contractID, dateOfInvoice, fromDate, toDate);

		this.setNotification({
			text: 'invoice_create_request_success',
			additional: '',
		});

		return res;
	}

	public async updateInvoice(
		id: number,
		creationDate: Moment,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment,
		billNumber: string
	): Promise<void> {
		await this.next.updateInvoice(id, creationDate, dateOfInvoice, fromDate, toDate, billNumber);
		this.setNotification({
			text: 'invoice_screen_update_success',
			additional: '',
		});
	}

	public async completeInvoice(id: number): Promise<void> {
		await this.next.completeInvoice(id);
		this.setNotification({
			text: 'close_billing_request_success',
			additional: '',
		});
	}

	public async deleteInvoice(id: number): Promise<void> {
		await this.next.deleteInvoice(id);
	}

	public async getInvoiceById(invoiceID: number): Promise<InvoiceItem | undefined> {
		return await this.next.getInvoiceById(invoiceID);
	}
	public async deleteContractByID(contractID: number): Promise<boolean> {
		return await this.next.deleteContractByID(contractID);
	}
	public async contact(params: ContactParams): Promise<boolean> {
		return await this.next.contact(params);
	}
	public async createStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		const res = await this.next.createStandradCaseInput(contractID, errorInput);
		this.setNotification({
			text: 'standard_case_screen_create_notification',
			additional: errorInput.partNumber,
		});
		return res;
	}
	public async deleteStandradCaseInputByID(contractID: number, errorInputID: number): Promise<boolean> {
		return await this.next.deleteStandradCaseInputByID(contractID, errorInputID);
	}
	public async getRhenusOverviewInformations(
		contractID: number,
		from: Moment,
		to: Moment
	): Promise<RhenusOverviewInformation[]> {
		return await this.next.getRhenusOverviewInformations(contractID, from, to);
	}
	public async getZoneCaseInputs(contractID: number, zoneID: number): Promise<RhenusCase[]> {
		return await this.next.getZoneCaseInputs(contractID, zoneID);
	}
	public async deleteRhenusCaseInputByID(contractID: number, zoneID: number, errorID: number): Promise<boolean> {
		return await this.next.deleteRhenusCaseInputByID(contractID, zoneID, errorID);
	}
	public async createRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		const res = await this.next.createRhenusCaseInput(contractID, zoneID, rhenusCaseInput);
		this.setNotification({
			text: 'rhenus_case_screen_create_notification',
			additional: rhenusCaseInput.partNumber,
		});
		return res;
	}
	public async updateRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		const res = await this.next.updateRhenusCaseInput(contractID, zoneID, rhenusCaseInput);
		this.setNotification({
			text: 'rhenus_case_screen_update_notification',
			additional: rhenusCaseInput.partNumber,
		});
		return res;
	}
	public async sendErrorReport(message: string): Promise<boolean> {
		return await this.next.sendErrorReport(message);
	}
	public async addManualWorkTime(contractID: number, worktime: WorkingTime): Promise<number> {
		const res = await this.next.addManualWorkTime(contractID, worktime);
		this.setNotification({
			text: 'working_time_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async addManualBreakTime(contractID: number, worktime: BreakTime): Promise<number> {
		const res = await this.next.addManualBreakTime(contractID, worktime);
		this.setNotification({
			text: 'working_time_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async startTrackingTime(contractID: number): Promise<boolean> {
		const res = await this.next.startTrackingTime(contractID);
		this.setNotification({
			text: 'working_time_screen_start_notification',
			additional: '',
		});
		return res;
	}
	public async stopTrackingTime(
		contractID: number,
		profession: number | undefined
	): Promise<WorkingTime | undefined> {
		const res = await this.next.stopTrackingTime(contractID, profession);
		this.setNotification({
			text: 'working_time_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async getAllRecords(contractID: number): Promise<(WorkingTime | BreakTime)[]> {
		return await this.next.getAllRecords(contractID);
	}

	public async readShifts(contractID: number): Promise<ShiftInterface[]> {
		return await this.next.readShifts(contractID);
	}

	public async exportInvoice(id: number): Promise<string | undefined> {
		return await this.next.exportInvoice(id);
	}

	public async deleteRecordByID(contractID: number, recordID: number): Promise<boolean> {
		const res = await this.next.deleteRecordByID(contractID, recordID);
		this.setNotification({
			text: 'working_time_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async deleteBreakByID(contractID: number, recordID: number): Promise<boolean> {
		const res = await this.next.deleteBreakByID(contractID, recordID);
		this.setNotification({
			text: 'working_time_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async deleteWaitingByID(contractID: number, recordID: number): Promise<boolean> {
		const res = await this.next.deleteWaitingByID(contractID, recordID);
		this.setNotification({
			text: 'working_time_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async updateRecordByID(contractID: number, record: WorkingTime): Promise<boolean> {
		const res = await this.next.updateRecordByID(contractID, record);
		this.setNotification({
			text: 'working_time_screen_update_notification',
			additional: '',
		});
		return res;
	}
	public async updateBreakByID(contractID: number, record: BreakTime): Promise<boolean> {
		const res = await this.next.updateBreakByID(contractID, record);
		this.setNotification({
			text: 'working_time_screen_update_notification',
			additional: '',
		});
		return res;
	}
	public async updateWaitingByID(contractID: number, record: BreakTime): Promise<boolean> {
		const res = await this.next.updateWaitingByID(contractID, record);
		this.setNotification({
			text: 'working_time_screen_update_notification',
			additional: '',
		});
		return res;
	}
	public async getMaterialExpenses(contractID: number): Promise<MaterialTool[]> {
		return await this.next.getMaterialExpenses(contractID);
	}
	public async getToolExpenses(contractID: number): Promise<MaterialTool[]> {
		return await this.next.getToolExpenses(contractID);
	}
	public async getTravelExpenses(contractID: number): Promise<Travel[]> {
		return await this.next.getTravelExpenses(contractID);
	}
	public async createMaterialExpenses(contractID: number, material: MaterialTool): Promise<number> {
		const res = await this.next.createMaterialExpenses(contractID, material);
		this.setNotification({
			text: 'expenses_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async createTravelExpenses(contractID: number, travel: Travel): Promise<number> {
		const res = await this.next.createTravelExpenses(contractID, travel);
		this.setNotification({
			text: 'expenses_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async createToolExpenses(contractID: number, tool: MaterialTool): Promise<number> {
		const res = await this.next.createToolExpenses(contractID, tool);
		this.setNotification({
			text: 'expenses_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async updateMaterialExpenses(contractID: number, material: MaterialTool): Promise<boolean> {
		const res = await this.next.updateMaterialExpenses(contractID, material);
		this.setNotification({
			text: 'expenses_screen_update_notification',
			additional: '',
		});
		return res;
	}
	public async updateTravelExpenses(contractID: number, travel: Travel): Promise<boolean> {
		const res = await this.next.updateTravelExpenses(contractID, travel);
		this.setNotification({
			text: 'expenses_screen_update_notification',
			additional: '',
		});
		return res;
	}
	public async updateToolExpenses(contractID: number, tool: MaterialTool): Promise<boolean> {
		const res = await this.next.updateToolExpenses(contractID, tool);
		this.setNotification({
			text: 'expenses_screen_update_notification',
			additional: '',
		});
		return res;
	}

	public async deleteMaterialExpensesByID(contractID: number, materialID: number): Promise<boolean> {
		const res = await this.next.deleteMaterialExpensesByID(contractID, materialID);
		this.setNotification({
			text: 'expenses_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async deleteTravelExpensesByID(contractID: number, travelID: number): Promise<boolean> {
		const res = await this.next.deleteTravelExpensesByID(contractID, travelID);
		this.setNotification({
			text: 'expenses_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async deleteToolExpensesByID(contractID: number, toolID: number): Promise<boolean> {
		const res = await this.next.deleteToolExpensesByID(contractID, toolID);
		this.setNotification({
			text: 'expenses_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async openCloseContract(contractID: number, status: ContractStatusType): Promise<boolean> {
		return await this.next.openCloseContract(contractID, status);
	}

	public async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
		const res = await this.next.changePassword(oldPassword, newPassword);
		this.setNotification({
			text: 'password_changed',
			additional: '',
		});
		return res;
	}
	public async aprroveInput(contractID: number, inputID: number, zoneID?: number): Promise<boolean> {
		const res = await this.next.aprroveInput(contractID, inputID, zoneID);
		this.setNotification({
			text: 'approve_notification',
			additional: '',
		});
		return res;
	}
	public async updateContract(contract: Contract): Promise<boolean> {
		const res = await this.next.updateContract(contract);
		this.setNotification({
			text: 'contract_screen_update_notification',
			additional: contract.contractNumber,
		});
		return res;
	}
	public async checkTrackingTime(): Promise<WorkTimeTrack | undefined> {
		return await this.next.checkTrackingTime();
	}

	public async getAllInvoices(contractID: number): Promise<any> {
		return await this.next.getAllInvoices(contractID);
	}

	public async getTeamLeaderByContract(contractID: number): Promise<User | undefined> {
		return await this.next.getTeamLeaderByContract(contractID);
	}
	public async getCustomerByContract(contractID: number): Promise<Customer | undefined> {
		return await this.next.getCustomerByContract(contractID);
	}
	public async acceptPrivacyPolicy(): Promise<boolean> {
		return await this.next.acceptPrivacyPolicy();
	}
	public async getContract(contractID: number): Promise<Contract | undefined> {
		return await this.next.getContract(contractID);
	}
	public async getStandardCaseInputByID(
		contractID: number,
		errorInputID: number
	): Promise<StandardCaseInput | undefined> {
		return await this.next.getStandardCaseInputByID(contractID, errorInputID);
	}
	public async getUserByID(userID: number): Promise<User | undefined> {
		return await this.next.getUserByID(userID);
	}
	public async getCustomerByID(customerID: number): Promise<Customer | undefined> {
		return await this.next.getCustomerByID(customerID);
	}
	public async getTransportExpenses(contractID: number): Promise<Transport[]> {
		return await this.next.getTransportExpenses(contractID);
	}
	public async updateTransportExpenses(contractID: number, transport: Transport): Promise<boolean> {
		const res = await this.next.updateTransportExpenses(contractID, transport);
		this.setNotification({
			text: 'expenses_screen_update_notification',
			additional: '',
		});
		return res;
	}
	public async deleteTransportExpensesByID(contractID: number, transportID: number): Promise<boolean> {
		const res = await this.next.deleteTransportExpensesByID(contractID, transportID);
		this.setNotification({
			text: 'expenses_screen_delete_notification',
			additional: '',
		});
		return res;
	}
	public async createTransportExpenses(contractID: number, transport: Transport): Promise<number> {
		const res = await this.next.createTransportExpenses(contractID, transport);
		this.setNotification({
			text: 'expenses_screen_create_notification',
			additional: '',
		});
		return res;
	}
	public async getContactQAI24ByContract(contractID: number): Promise<User | undefined> {
		return await this.next.getContactQAI24ByContract(contractID);
	}

	public async getRhenusCaseInputByID(errorID: number): Promise<RhenusCase | undefined> {
		return await this.next.getRhenusCaseInputByID(errorID);
	}
	public async getPhoto(photoURL: string): Promise<string | undefined> {
		return await this.next.getPhoto(photoURL);
	}
	public async deleteCustomerByID(contractID: number): Promise<boolean> {
		const res = await this.next.deleteCustomerByID(contractID);
		this.setNotification({
			text: 'customer_screen_delete_notification',
			additional: '',
		});
		return res;
	}

	public async createStandardErrorInputsExcelSheet(
		contractID: number,
		data: DashboardFilterInterface,
		language: string
	): Promise<string | undefined> {
		const res = await this.next.createStandardErrorInputsExcelSheet(contractID, data, language);
		return res;
	}

	public async createProcessContractStandardErrorInputsExcelSheet(
		contractID: number,
		data: StandardCaseFilterInterface,
		language: string
	): Promise<string | undefined> {
		const res = await this.next.createProcessContractStandardErrorInputsExcelSheet(contractID, data, language);
		return res;
	}

	public async createWorkingTimeExcelSheet(
		contractID: number,
		data: WorkingTimeFilterInterface,
		language: string
	): Promise<string | undefined> {
		const res = await this.next.createWorkingTimeExcelSheet(contractID, data, language);
		return res;
	}

	public async contractSummary(contractID: number, fromDate: Moment | null, toDate: Moment | null): Promise<any> {
		return await this.next.contractSummary(contractID, fromDate, toDate);
	}
}

export default SuccessMiddleware;
