import { Service } from '../../service/service';
import User from '../../service/types/User';
import { contactPersonFields } from '../../containers/ContactPersonContainer';
import { History, LocationState } from 'history';
import { replaceContactPersonHistoryData } from '../navigationHelper';

export function updateContactPerson(
	data: any,
	contactPerson: User | undefined,
	service: Service,
	history: History<LocationState>,
	setContactPerson: (contactPerson: User | undefined) => void,
	setIsUserEditing: (val: boolean) => void
) {
	if (contactPerson) {
		const updatedContactPerson = getUpdatedContactPerson(data, contactPerson);

		service
			.updateUserByID(contactPerson.id, {
				...User.toJSON(updatedContactPerson),
				password: data.password,
				tlGroupsIds: null,
			})
			.then((res) => {
				if (res) {
					setIsUserEditing(false);
					setContactPerson(updatedContactPerson);
					replaceContactPersonHistoryData(history, updatedContactPerson);
				}
			})
			.catch(() => {});
	}
}

function getUpdatedContactPerson(data: any, contactPerson: User) {
	return new User(
		contactPerson.id,
		data[contactPersonFields.email],
		contactPerson.role,
		data[contactPersonFields.firstName],
		data[contactPersonFields.lastName],
		contactPerson.originOfEmployee,
		undefined,
		data[contactPersonFields.phonenumber]
	);
}
