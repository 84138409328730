import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import Spinner from '../components/Spinner';
import CustomerScreen from '../screens/CustomerScreen';
import { StandardDialogInterface } from '../components/StandardDialog';

import User from '../service/types/User';
import service from '../service/service';
import { RouteParams, Routes } from '../router/Routes';
import Customer from '../service/types/Customer';
import { AddressInterface } from '../service/types/Address';
import { createCustomer } from '../helpers/customerHelper/createCustomerHelper';
import { updateCustomer } from '../helpers/customerHelper/updateCustomerHelper';
import { deleteCustomer } from '../helpers/customerHelper/deleteCustomerHelper';
import {
	onCustomerScreenMount,
	setAddressInfo,
	setCustomerInfo,
	watchFields,
} from '../helpers/customerHelper/readCustomerHelper';

import { useNotificationActionContext } from '../context/notification/useNotification';

const addressFields = (key: string, costCenter?: boolean): AddressInterface => ({
	postcode: `${key}postcode`,
	country: `${key}country`,
	city: `${key}city`,
	houseNumber: `${key}houseNumber`,
	street: `${key}street`,
	costCenter: costCenter ? `${key}costCenter` : undefined,
});

export const customerFields = {
	radio: 'radio',
	name: 'name',
	billingAddress: addressFields('billingAddress'),
	deliveryAddress: addressFields('deliveryAddress', true),
	billingAddressGenerated: 'billingAddressGenerated',
	deliveryAddressGenerated: 'deliveryAddressGenerated',
	customerNumber: 'customerNumber',
	supplierNumber: 'supplierNumber',
	invoiceEmail: 'invoiceEmail',
	reminderEmail: 'reminderEmail',
	otherEmails: 'otherEmails',
	vatNumber: 'vatNumber',
	termOfPayment: 'termOfPayment',
};

export const MIN_CUSTOMER_LENGTH = 3;
export const MAX_CUSTOMER_LENGTH = 50;

function CustomerContainer() {
	const history = useHistory();
	const routeParams: RouteParams = useParams();

	const { setNotification } = useNotificationActionContext();
	const { control, handleSubmit, errors, setValue, watch, formState } = useForm({});

	const sameAddress = watch(customerFields.radio);
	const emails = watch(customerFields.otherEmails);
	const billingAddressWatch = watchFields('billingAddress', watch);
	const deliveryAddressWatch = watchFields('deliveryAddress', watch);

	const [mounted, setMounted] = useState<boolean>(false);
	const [isUserEditing, setIsUserEditing] = useState(false);
	const [customer, setCustomer] = useState<Customer | undefined>(undefined);
	const deleteDialogRef = useRef<null | StandardDialogInterface>(null);

	const {
		fields: emailFields,
		append: appendEmail,
		remove: removeEmail,
	} = useFieldArray({
		control: control,
		name: customerFields.otherEmails,
	});

	useEffect(() => {
		onCustomerScreenMount(history, routeParams, setCustomer, setIsUserEditing, setMounted);
	}, [routeParams, history]);

	const onEditContactPerson = (user: User) =>
		history.push(`${Routes.User}/${user.id}`, { backTo: `${Routes.Customer}/${customer?.id}` });

	useEffect(() => {
		if (customer) {
			setCustomerInfo(customer, setValue);
		}
	}, [customer, setValue]);

	useEffect(() => {
		// setValue(customerFields.otherEmails, customer ? customer?.otherEmails : []);
		if (isUserEditing && customer) {
			setAddressInfo(customerFields.billingAddress, customer.billingAdrress, setValue);
			setAddressInfo(customerFields.deliveryAddress, customer.deliveryAddress, setValue);

			if (!customer.deliveryAddress.costCenter)
				setValue(customerFields.radio, customer.billingAddressGenerated === customer.deliveryAddressGenerated);
			else {
				const tempArr = customer.deliveryAddressGenerated.split(',');
				tempArr.splice(-1, 1);
				setValue(customerFields.radio, customer.billingAddressGenerated === tempArr.toString());
			}
		} else {
			setValue(customerFields.radio, true);
		}

		setValue(
			customerFields.otherEmails,
			customer
				? customer?.otherEmails?.map((el, i) => ({
						value: el,
				  }))
				: []
		);
	}, [isUserEditing, customer, setValue]);

	const onEditPress = () => setIsUserEditing(true);

	const onUpdatePress = useMemo(
		() =>
			handleSubmit((data) => {
				updateCustomer(
					data,
					service,
					customer,
					history,
					setCustomer,
					setIsUserEditing,
					emails ? emails.map((el: any) => el.value) : []
				);
			}),
		[customer, history, handleSubmit, emails]
	);
	const onCancelPress = () => {
		if (customer) {
			setIsUserEditing(false);
			setCustomerInfo(customer, setValue);
		} else history.goBack();
	};

	const onDeletePress = () => deleteDialogRef.current?.setDialogState(true);

	const onCancelDeleteDialogPress = () => deleteDialogRef.current?.setDialogState(false);

	const onDeleteCustomerDialogPress = () => {
		deleteCustomer(history, service, customer, setNotification);
	};

	const onCreatePress = useMemo(
		() =>
			handleSubmit((data) => {
				createCustomer(
					data,
					service,
					setCustomer,
					emails ? emails.map((el: any) => el.value) : [],
					setIsUserEditing,
					setNotification
				);
			}),
		[handleSubmit, emails, setNotification]
	);

	const onSubmitPress = () => {
		if (customer) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		} else {
			onCreatePress();
		}
	};

	if (!mounted) return <Spinner />;

	return (
		<CustomerScreen
			control={control}
			customer={customer}
			errors={errors}
			isUserEditing={isUserEditing}
			deleteDialogRef={deleteDialogRef}
			formDirty={formState.isDirty}
			billingAddressWatch={billingAddressWatch}
			deliveryAddressWatch={deliveryAddressWatch}
			sameAddress={sameAddress}
			onEditContactPerson={onEditContactPerson}
			onSubmitPress={onSubmitPress}
			onDeletePress={onDeletePress}
			onDeleteCustomerDialogPress={onDeleteCustomerDialogPress}
			onCancelPress={onCancelPress}
			onCancelDeleteDialogPress={onCancelDeleteDialogPress}
			emailFields={emailFields}
			appendEmailFields={appendEmail}
			removeEmailFields={removeEmail}
			setValue={setValue}
		/>
	);
}

export default CustomerContainer;
