import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import CloseIcon from '../svgComponents/CloseIcon';
import WarningIcon from '../svgComponents/WarningIcon';
import { useErrorActionContext, useErrorStateContext } from '../context/error/useError';
import Transition from './Transition';

interface Props {}

function ErrorServiceDialog(props: Props, ref: any) {
	const classes = useStyles();
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const { error } = useErrorStateContext();
	const { setError } = useErrorActionContext();
	const [isOpen, setIsOpen] = React.useState(false);

	const onClose = () => {
		setIsOpen(false);
		setError('');
	};

	React.useEffect(() => {
		if (error) setIsOpen(true);
	}, [error]);

	return (
		<Dialog
			className={classes.wrapper}
			open={isOpen}
			style={{ zIndex: 2000 }}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}>
			<div className={classes.container}>
				<div className={classes.warningIconWrapper}>
					<WarningIcon />
				</div>
				<div>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.dialogTitle}>
							{t('error_service_dialog_title', { title: t(error) })}
						</span>
					</div>
					<div className={classes.descriptionWrapper}>
						<span className={globalClasses.dialogDescription}>{t('error_service_dialog_description')}</span>
					</div>
					<div className={classes.row}>
						<div className={classes.buttonWrapper} onClick={onClose}>
							<span className={globalClasses.dialogTitle}>{t('error_service_dialog_cancel')}</span>
						</div>
					</div>
				</div>
				<div className={classes.closeIconWarpper} onClick={onClose}>
					<CloseIcon color={colors.darkGray} />
				</div>
			</div>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	wrapper: { maxWidth: '40vw', marginLeft: 'auto', marginRight: 'auto' },
	container: { display: 'flex', padding: '16px 16px 16px 20px', borderRadius: '4px', flex: 1 },
	row: { display: 'flex' },
	titleWrapper: {
		marginBottom: '8px',
		transform: 'translateY(-2px)',
	},
	descriptionWrapper: {
		marginBottom: '20px',
		alignItems: 'center',
	},
	buttonWrapper: {
		marginRight: '24px',
		cursor: 'pointer',
	},
	closeIconWarpper: {
		marginLeft: '12px',
		cursor: 'pointer',
		height: 'fit-content',
	},
	warningIconWrapper: {
		marginRight: '16px',
	},
}));

export default React.forwardRef(ErrorServiceDialog);
