import { makeStyles, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import ReactList from 'react-list';

import { colors } from '../consts/colors';
import { useGlobalStyles } from '../consts/globalStyles';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { Routes } from '../router/Routes';
import { Sort } from './ContractList';
import StandardTableCell from './table/StandardTableCell';

interface Props {
	data: { id: number; [key: string]: any }[];
	dataTablePramas: any;
	displaySort?: boolean;
	tableKey: string;
	filters?: any;
	filtersOptions?: { [key: string]: KeyValue[] };
	onClickItemId?: (id: number) => void;
	buttonText?: string;
	buttonProps?: {
		[key: string]: {
			text: string;
			onClick: (params: any) => void;
		};
	};
	margin?: string;
	maxHeight?: string;
	redirectTo?: Routes;
	sort?: Sort;
	setSort?: React.Dispatch<React.SetStateAction<Sort>>;
	onLinkClick?: (params: any) => void;
	onFilterChange?: (key: any, value: any) => void;
	markTextAsRedFn?: (key: string, value: any) => boolean;
}

function CustomTableBody({
	data,
	dataTablePramas,
	displaySort,
	tableKey,
	filters,
	filtersOptions,
	buttonProps,
	buttonText,
	onClickItemId,
	margin,
	maxHeight,
	redirectTo,
	sort,
	setSort,
	onLinkClick,
	onFilterChange,
	markTextAsRedFn,
}: Props) {
	const classes = useStyles({ margin: margin, maxHeight: maxHeight });
	const globalClasses = useGlobalStyles({});
	const keys = React.useMemo(() => Object.keys(dataTablePramas), [dataTablePramas]);

	const header = keys.map((key: any) => (
		<StandardTableCell
			className={
				dataTablePramas[key].sticky ? globalClasses.tableTitleWrapperSticky : globalClasses.tableTitleWrapper
			}
			displaySort={displaySort}
			key={`${tableKey}Title${dataTablePramas[key].title}`}
			restProps={{
				type: dataTablePramas[key].titleType,
				filterOptions: filtersOptions ? filtersOptions[key] : undefined,
				filter: filters ? filters[key] : undefined,
				filterKey: key,
				onFilterChange: onFilterChange,
			}}
			sort={dataTablePramas[key].sortable && sort}
			setSort={setSort}
			style={{
				width: dataTablePramas[key].width,
			}}
			value={dataTablePramas[key].title}
		/>
	));

	const items = React.useMemo(() => {
		return data.map((item, index) => (
			<TableRow key={`${tableKey}Row${item.id}`}>
				{keys.map((key) => {
					const itemClass =
						index % 2 === 0
							? dataTablePramas[key].sticky
								? classes.stickyItemWrapper
								: classes.itemWrapper
							: dataTablePramas[key].sticky
							? classes.stickyItemWrapperInverse
							: classes.itemWrapperInverse;
					return (
						<StandardTableCell
							value={item[key]}
							className={itemClass}
							style={{
								width: dataTablePramas[key].width,
							}}
							key={`${tableKey}Item${key}${item.id}`}
							restProps={{
								type: dataTablePramas[key].type,
								sticky: dataTablePramas[key].sticky,
								onLinkClick: () => (onLinkClick ? onLinkClick(item) : {}),
								buttonText: buttonText ? buttonText : buttonProps ? buttonProps[key]?.text : '',
								onButtonClick: () =>
									onClickItemId
										? onClickItemId(item.id)
										: buttonProps
										? buttonProps[key]?.onClick(index)
										: {},
								index: item.id,
								redirectTo: redirectTo,
								markTextAsRed: markTextAsRedFn ? markTextAsRedFn(key, item[key]) : undefined,
							}}
						/>
					);
				})}
			</TableRow>
		));
	}, [
		data,
		tableKey,
		keys,
		dataTablePramas,
		classes.stickyItemWrapper,
		classes.itemWrapper,
		classes.stickyItemWrapperInverse,
		classes.itemWrapperInverse,
		buttonText,
		buttonProps,
		redirectTo,
		markTextAsRedFn,
		onLinkClick,
		onClickItemId,
	]);

	return (
		<div className={classes.container}>
			<TableContainer className={classes.tableContainer}>
				<Table className={classes.fixed}>
					<TableHead>
						<TableRow>{header}</TableRow>
					</TableHead>
					<ReactList
						length={data.length}
						//@ts-ignore
						itemsRenderer={(items, warpperRef) => <TableBody innerRef={warpperRef}>{items}</TableBody>}
						itemRenderer={(index) => items[index]}
					/>
				</Table>
			</TableContainer>
		</div>
	);
}

const itemWrapper = {
	padding: '14px 16px',
	overflow: 'hidden',
	left: '0px',
};

interface StyleProps {
	margin?: string;
	maxHeight?: string;
}

const useStyles = makeStyles(() => ({
	container: (p: StyleProps) => ({
		margin: p.margin ? p.margin : '48px 0px',
		overflow: 'auto',
	}),
	fixed: {
		tableLayout: 'fixed',
		borderCollapse: 'separate',
	},
	stickyItemWrapper: {
		...itemWrapper,
		backgroundColor: colors.darkGray,
		position: 'sticky',
	},
	stickyItemWrapperInverse: {
		...itemWrapper,
		backgroundColor: colors.lightDarkGray,
		position: 'sticky',
	},
	itemWrapper: {
		...itemWrapper,
		backgroundColor: colors.white,
	},
	itemWrapperInverse: {
		...itemWrapper,
		backgroundColor: 'rgba(228,228,228, 0.5)',
	},
	tableContainer: (p: StyleProps) => ({
		maxHeight: p.maxHeight ? p.maxHeight : '500px',
	}),
}));

export default CustomTableBody;
