import * as React from 'react';

function CustomersIcon(props: React.SVGProps<any>) {
	return (
		<svg
			width={149}
			height={150}
			viewBox="0 0 149 150"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}>
			<defs>
				<filter
					x="-5.7%"
					y="-2.8%"
					width="111.3%"
					height="111.3%"
					filterUnits="objectBoundingBox"
					id="customers1">
					<feMorphology radius={2} in="SourceAlpha" result="shadowSpreadOuter1" />
					<feOffset dy={4} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0 0.101960784 0 0 0 0.2 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<rect id="customers2" x={0} y={0} width={141} height={142} rx={4} />
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(4)">
					<use fill="#000" filter="url(#customers1)" xlinkHref="#customers2" />
					<use fill="#FFF" xlinkHref="#customers2" />
				</g>
				<path fill="#FFD500" opacity={0.2} d="M146 0L4 143V0z" />
				<g transform="translate(32 31)" stroke="#111" strokeLinecap="square" strokeWidth={2.5}>
					<path d="M46.933 0v38.133L61.6 26.4H88V0z" />
					<circle cx={23.467} cy={35.2} r={11.733} />
					<path d="M46.933 79.2H0V66.97c0-3.603 2.168-6.878 5.53-8.174 3.908-1.51 9.886-3.063 17.937-3.063 8.05 0 14.028 1.554 17.936 3.061 3.363 1.298 5.53 4.572 5.53 8.177V79.2z" />
				</g>
			</g>
		</svg>
	);
}

export default CustomersIcon;
