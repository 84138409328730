import React from 'react';
import { ErrorState } from './errorReducer';

export type ErrorContextActions = {
	setError: (error: string) => void;
};

const initialStateContext: ErrorState = {
	error: '',
};

const initalActionContext: ErrorContextActions = {
	setError: (error: string) => {},
};

export const ErrorStateContext = React.createContext(initialStateContext);
export const ErrorActionsContext = React.createContext(initalActionContext);
