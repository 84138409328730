import React from 'react';
import { makeStyles, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useGlobalStyles } from '../consts/globalStyles';
import ReactList from 'react-list';
import MaterialTool from '../service/types/MaterialTool';
import StandardTableCell from './table/StandardTableCell';
import AddExpenseTabelItem from './AddExpensesItem';
import Travel from '../service/types/Travel';
import { Expenses, ExpensesKeys } from '../helpers/expenses/generalExpensesHelper';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';
import Transport from '../service/types/Transport';
import ExpenseTabelItem from './ExpensesTabelItem';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';

interface Props {
	tableKey: string;
	expenses: Expenses;
	expensesTableParams: Expenses;
	tabType: ExpensesKeys;
	isSummary: boolean;
	users: KeyValue[];
	onAddPress: (data: any) => Promise<boolean | MaterialTool | Travel | Transport>;
	onUpdatePress: (expense: MaterialTool | Travel | Transport, data: any) => Promise<boolean>;
	onDeletePress: (expenseID: number) => void;
	contractNumber?: string;
}
function ExpensesTabel(props: Props) {
	const classes = useStyles({ isSummary: props.isSummary });
	const globalClasses = useGlobalStyles();
	const { user } = useAuthStateContext();
	const keys = React.useMemo(
		() => Object.keys(props.expensesTableParams[props.tabType]),
		[props.expensesTableParams, props.tabType]
	);

	return (
		<div className={classes.container}>
			<TableContainer className={classes.tableContainer}>
				<Table className={classes.fixed}>
					<TableHead>
						<TableRow>
							{keys.map((key: any, index, array) => {
								return key === 'billed' && user?.role === UserRole.QualityAssuranceInspector ? null : (
									<StandardTableCell
										restProps={{ type: props.expensesTableParams[props.tabType][key].titleType }}
										className={
											index === 0
												? globalClasses.tableTitleWrapperSticky
												: index === array.length - 1
												? globalClasses.tableTitleWrapperStickyRight
												: globalClasses.tableTitleWrapper
										}
										value={props.expensesTableParams[props.tabType][key].title}
										style={{
											width: props.expensesTableParams[props.tabType][key].width,
										}}
										key={`${props.tableKey}Title${
											props.expensesTableParams[props.tabType][key].title
										}`}
									/>
								);
							})}
						</TableRow>
					</TableHead>
					<ReactList
						length={props.expenses[props.tabType].length}
						itemsRenderer={(items, warpperRef) => (
							//@ts-ignore
							<TableBody innerRef={warpperRef}>
								{props.isSummary ? null : (
									<AddExpenseTabelItem
										tableKey={'Add'}
										expensesTablePramas={props.expensesTableParams[props.tabType]}
										onAddPressHandler={props.onAddPress}
									/>
								)}
								{items}
							</TableBody>
						)}
						itemRenderer={(index) => (
							<ExpenseTabelItem
								isSummary={props.isSummary}
								onDeletePress={props.onDeletePress}
								onUpdatePress={props.onUpdatePress}
								users={props.users}
								index={index}
								contractNumber={props.contractNumber}
								expenseTablePramas={props.expensesTableParams[props.tabType]}
								expense={props.expenses[props.tabType][index]}
								tableKey={`${props.tableKey}Row${props.expenses[props.tabType][index].id}`}
								key={`${props.tableKey}Item${props.tableKey}${props.expenses[props.tabType][index].id}`}
							/>
						)}
					/>
				</Table>
			</TableContainer>
		</div>
	);
}

interface StyleProps {
	isSummary?: boolean;
}

const useStyles = makeStyles(() => ({
	container: (p: StyleProps) => ({
		margin: `${p.isSummary ? 0 : 28}px 0px 48px`,
		overflow: 'auto',
	}),
	fixed: {
		tableLayout: 'fixed',
		borderCollapse: 'separate',
	},
	tableContainer: {
		maxHeight: '502px',
	},
}));

export default ExpensesTabel;
