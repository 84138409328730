import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../consts/globalStyles';
import { colors } from '../consts/colors';
import { ExpensesKeys } from '../helpers/expenses/generalExpensesHelper';

interface Props {
	title: string;
	active: ExpensesKeys;
	type: ExpensesKeys;
	onPress: (type: ExpensesKeys) => void;
}
function TabTitle(props: Props) {
	const classes = useStyles({});
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	const underlineClass = React.useMemo(
		() => (props.active === props.type ? classes.underlineDark : classes.underlineLight),
		[props.active, props.type, classes.underlineDark, classes.underlineLight]
	);

	const titleClass = React.useMemo(
		() => (props.active === props.type ? globalClasses.mediumTextSemiDark : globalClasses.mediumTextSemiLight),
		[props.active, props.type, globalClasses.mediumTextSemiDark, globalClasses.mediumTextSemiLight]
	);

	return (
		<div className={classes.subtitleWrapper} onClick={() => props.onPress(props.type)}>
			<span className={titleClass}>{t(props.title)}</span>
			<div className={underlineClass} />
		</div>
	);
}

const useStyles = makeStyles(() => ({
	subtitleWrapper: {
		marginRight: '28px',
		cursor: 'pointer',
	},
	underlineDark: {
		width: '100%',
		height: '1px',
		backgroundColor: colors.darkGray,
	},
	underlineLight: {
		width: '100%',
		height: '1px',
		backgroundColor: colors.lightGray,
	},
}));

export default TabTitle;
