import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { StandardDialogInterface } from '../components/StandardDialog';
import { useAuthStateContext } from '../context/auth/useAuth';
import { createNewErrorInput, createStandardInputCase } from '../helpers/standardInputHelper/createStandardInputHelper';
import {
	onStandardInputScreenMount,
	setStandardInputCaseInfo,
} from '../helpers/standardInputHelper/readStandardInputHelper';
import StandardCaseInputScreen from '../screens/StandardCaseInputScreen';
import service from '../service/service';
import StandardCaseInput, { ErrorInputType } from '../service/types/StandardCase';
import { updateStandradCaseInput } from '../helpers/standardInputHelper/updateStandardInputHelper';
import { deleteStandardInput } from '../helpers/standardInputHelper/deleteStandardInputHelper';
import { useNotificationActionContext } from '../context/notification/useNotification';
import Contract from '../service/types/Contract';
import { RouteParams } from '../router/Routes';
import Spinner from '../components/Spinner';
import { ShiftInterface } from '../helpers/contractHelper/generalContractHelper';

export const standardCaseInputFields = {
	approved: 'approved',
	checkNumber: 'checkNumber',
	comment: 'comment',
	date: 'date',
	errorType: 'errorType',
	deliveryNoteNumber: 'deliveryNoteNumber',
	deliveryNoteDate: 'deliveryNoteDate',
	partNumber: 'partNumber',
	partsIO: 'partsIO',
	partsNIO: 'partsNIO',
	partsReworked: 'partsReworked',
	photo: 'photo',
	totalPartsTested: 'totalPartsTested',
	typeOfInspection: 'typeOfInspection',
	shift: 'shift',
	location: 'location',
};

function StandardCaseInputContainer() {
	const { control, handleSubmit, errors, setValue, watch, getValues, reset } = useForm({});
	const {
		control: addControl,
		handleSubmit: addHandleSubmit,
		errors: addErrors,
		setValue: addSetValue,
		setError: addSetError,
		reset: addReset,
	} = useForm();

	const [isUserEditing, setIsUserEditing] = React.useState(false);
	const [errorParts, setErrorParts] = React.useState<ErrorInputType[]>([]);
	const [contract, setContract] = React.useState<Contract>();
	const [mounted, setMounted] = React.useState(false);
	const [standardInputCase, setStandardInputCase] = React.useState<undefined | StandardCaseInput>(undefined);

	const [contractShifts, setContractShifts] = React.useState<ShiftInterface[]>([]);

	const [allStandardCaseInputs, setAllStandardCaseInputs] = React.useState<StandardCaseInput[]>([]);
	const commentRef = React.useRef<any>();
	const { user } = useAuthStateContext();
	const { setNotification } = useNotificationActionContext();
	const routeParams: RouteParams = useParams();
	const totalPartsTested = watch(standardCaseInputFields.totalPartsTested, '');
	const partsNIO = React.useMemo(() => {
		return errorParts.reduce((prev, curr) => {
			return prev + Number(curr.amount);
		}, 0);
	}, [errorParts]);

	const partsIO = React.useMemo(() => {
		if (totalPartsTested) return totalPartsTested - partsNIO;
		return 0;
	}, [partsNIO, totalPartsTested]);

	const dialogRef = React.useRef<null | StandardDialogInterface>(null);

	const history = useHistory();

	const onDeletePress = () => dialogRef.current?.setDialogState(true);
	const onCancelDialogPress = () => dialogRef.current?.setDialogState(false);

	const onDeleteErrorInputDialogPress = () => {
		if (contract) deleteStandardInput(service, standardInputCase, contract.id, history, setNotification);
	};

	const onRemoveErrorPart = (index: number) => setErrorParts(errorParts.filter((_, i) => i !== index));

	const addNewErrorPart = React.useMemo(
		() =>
			addHandleSubmit((data) => {
				createNewErrorInput(data, errorParts, setErrorParts, addSetError, addSetValue, getValues);
			}),
		[addHandleSubmit, errorParts, addSetValue, getValues, addSetError]
	);

	React.useEffect(() => {
		const contractId = routeParams.contract_id;

		if (contractId) {
			service.getAllStandardCaseInputs(Number(contractId)).then(setAllStandardCaseInputs);

			service.readShifts(parseInt(contractId)).then((res) => setContractShifts(res));
		}
	}, [routeParams.contract_id]);

	React.useEffect(() => {
		onStandardInputScreenMount(
			history,
			routeParams,
			setIsUserEditing,
			setStandardInputCase,
			setContract,
			setMounted
		);
	}, [history, routeParams]);

	React.useEffect(() => {
		setStandardInputCaseInfo(standardInputCase, user, setValue, setErrorParts);
	}, [standardInputCase, setValue, user]);

	const updateAllStandardCaseInputs = (inputs: StandardCaseInput[]) => {
		setAllStandardCaseInputs(inputs);
	};

	const disablePaste = (e: any) => {
		e.preventDefault();
	};

	const onEditPress = () => setIsUserEditing(true);

	const onUpdatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (contract)
					updateStandradCaseInput(
						data,
						service,
						standardInputCase,
						partsNIO,
						partsIO,
						errorParts,
						contract.id,
						history,
						setStandardInputCase,
						setIsUserEditing,
						addSetValue,
						updateAllStandardCaseInputs,
						contract
					);
			}),
		[handleSubmit, standardInputCase, partsIO, partsNIO, errorParts, history, contract, addSetValue]
	);

	const onCreatePress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (contract)
					createStandardInputCase(data, history, contract, service, user!, partsNIO, partsIO, errorParts);
			}),
		[history, user, partsNIO, partsIO, errorParts, contract, handleSubmit]
	);

	const onCancelPress = () => {
		if (standardInputCase) {
			setStandardInputCaseInfo(standardInputCase, user, setValue, setErrorParts);
			setIsUserEditing(false);
		} else history.goBack();
	};

	const onSubmitPress = () => {
		if (standardInputCase) {
			if (isUserEditing) {
				onUpdatePress();
			} else {
				onEditPress();
			}
		} else {
			onCreatePress();
		}
	};

	const resetAllValues = React.useCallback(() => {
		setValue(standardCaseInputFields.approved, undefined);
		addSetValue(standardCaseInputFields.checkNumber, undefined);
		setValue(standardCaseInputFields.comment, undefined);
		setValue(standardCaseInputFields.deliveryNoteDate, null);
		setValue(standardCaseInputFields.deliveryNoteNumber, undefined);
		addSetValue(standardCaseInputFields.errorType, undefined);
		setValue(standardCaseInputFields.partNumber, undefined);
		setValue(standardCaseInputFields.partsIO, undefined);
		setValue(standardCaseInputFields.partsNIO, undefined);
		setValue(standardCaseInputFields.partsReworked, undefined);
		setValue(standardCaseInputFields.photo, undefined);
		setValue(standardCaseInputFields.totalPartsTested, undefined);
	}, [addSetValue, setValue]);

	const formRefresh = React.useCallback(() => {
		setErrorParts([]);
		addReset();
		reset();
		resetAllValues();
	}, [addReset, reset, resetAllValues]);

	const onCreateStartNewPress = React.useMemo(
		() =>
			handleSubmit((data) => {
				if (contract)
					createStandardInputCase(
						data,
						history,
						contract,
						service,
						user!,
						partsNIO,
						partsIO,
						errorParts,
						formRefresh,
						updateAllStandardCaseInputs
					);
			}),
		[handleSubmit, contract, history, user, partsNIO, partsIO, errorParts, formRefresh]
	);

	const partNumberOptions = React.useMemo(() => {
		const inputs: string[] = allStandardCaseInputs.map((input) => input.partNumber);

		const filteredInputs = inputs.filter((item, index) => inputs.indexOf(item) === index);

		return filteredInputs;
	}, [allStandardCaseInputs]);

	if (!mounted || !contract) return <Spinner />;

	return (
		<StandardCaseInputScreen
			commentRef={commentRef}
			control={control}
			errors={errors}
			addControl={addControl}
			addErrors={addErrors}
			isUserEditing={isUserEditing}
			standardCaseInput={standardInputCase}
			dialogRef={dialogRef}
			errorParts={errorParts}
			contract={contract}
			partsIO={partsIO}
			partsNIO={partsNIO}
			totalPartsTested={totalPartsTested}
			partNumberOptions={partNumberOptions}
			onCancelDialogPress={onCancelDialogPress}
			onCancelPress={onCancelPress}
			onDeletePress={onDeletePress}
			onDeleteErrorInputDialogPress={onDeleteErrorInputDialogPress}
			onSubmitPress={onSubmitPress}
			onSubmitStartNewPress={onCreateStartNewPress}
			onRemoveErrorPart={onRemoveErrorPart}
			addNewErrorPart={addNewErrorPart}
			watch={watch}
			disablePaste={disablePaste}
			contractShifts={contractShifts}
		/>
	);
}

export default StandardCaseInputContainer;
