import { Dialog, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Webcam from 'react-webcam';
import { useGlobalStyles } from '../consts/globalStyles';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import Transition from './Transition';
import { BrowserMultiFormatReader } from '@zxing/library';

export interface BarcodeScannerInterface {
	setDialogState: (open: boolean, onScan: (text: string) => void) => void;
}

const image = new Image();

interface Props {}

function BarcodeScanner(props: Props, ref: any) {
	const globalClasses = useGlobalStyles();
	const [isOpen, setIsOpen] = React.useState(false);
	const callback = React.useRef<(text: string) => void>(() => {});
	const classes = useStyles({});
	const webcamRef = React.useRef<Webcam | null>(null);
	const codeReader = React.useMemo(() => new BrowserMultiFormatReader(), []);
	const gridRef = React.useRef<HTMLElement | null>(null);
	const lineRef = React.useRef<HTMLDivElement | null>(null);
	const lineRef2 = React.useRef<HTMLDivElement | null>(null);

	const capture = React.useCallback(async () => {
		const imageSrc = webcamRef?.current?.getScreenshot();

		if (imageSrc) {
			if (lineRef.current && gridRef.current && !lineRef.current.style.backgroundColor) {
				const t = gridRef.current!.offsetHeight / 2 - 202;
				lineRef.current!.style.top = `${t}px`;
				lineRef.current!.style.border = '2px solid red';
				lineRef2.current!.style.top = `${t + 101}px`;
				lineRef2.current!.style.backgroundColor = 'red';
			}

			const canvas = document.getElementById('canvas');
			const canvas2 = document.getElementById('canvas2');

			//@ts-ignore
			const context = canvas.getContext('2d');
			//@ts-ignore
			const context2 = canvas2.getContext('2d');

			if (context && context2) {
				image.src = imageSrc;
				image.onload = function () {
					context.drawImage(
						image,
						gridRef.current!.offsetWidth * 0.3,
						gridRef.current!.offsetHeight / 2 - 202,
						gridRef.current!.offsetWidth,
						200,
						0,
						0,
						gridRef.current!.offsetWidth,
						200
					);
					codeReader
						//@ts-ignore
						.decodeFromImage(undefined, canvas.toDataURL())
						.then((res: any) => {
							if (res && res.text) {
								setIsOpen(false);
								callback.current(res.text);
							}
						})
						.catch(() => {
							context2.drawImage(
								image,
								0,
								gridRef.current!.offsetHeight / 2 - 101,
								gridRef.current!.offsetWidth,
								10,
								0,
								0,
								gridRef.current!.offsetWidth,
								10
							);
							codeReader
								//@ts-ignore
								.decodeFromImage(undefined, canvas2.toDataURL())
								.then((res: any) => {
									if (res && res.text) {
										setIsOpen(false);
										callback.current(res.text);
									}
								})
								.catch(() => {});
						});
				};
			}
		}
	}, [codeReader]);

	React.useEffect(() => {
		let interval: any;
		clearInterval(interval);
		if (isOpen) {
			interval = setInterval(capture, 100);
		} else {
			if (interval) clearInterval(interval);
		}
		return () => {
			clearInterval(interval);
		};
	}, [capture, isOpen]);

	const cancel = () => {
		setIsOpen(false);
	};

	React.useImperativeHandle(
		ref,
		(): BarcodeScannerInterface => ({
			setDialogState: (val: boolean, onScan: (text: string) => void) => {
				setIsOpen(val);
				callback.current = onScan;
			},
		})
	);

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition} keepMounted onClose={cancel}>
			<div className={globalClasses.container}>
				<div className={globalClasses.homeButtonWrapper}>
					<BackArrow onBackArrowPress={cancel} />
					<HomeButton />
				</div>
				<Grid container className={classes.container} justify="center" alignItems="center">
					<Grid item sm={3} />
					<Grid item sm={6} innerRef={gridRef}>
						{isOpen ? (
							<div style={{ position: 'relative' }}>
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									width={'100%'}
									videoConstraints={{ facingMode: { ideal: 'environment' } }}
								/>

								<canvas
									id="canvas"
									width={gridRef.current!.offsetWidth * 0.4}
									height={200}
									style={{ opacity: 0 }}></canvas>
								<canvas
									id="canvas2"
									width={gridRef.current!.offsetWidth}
									height={10}
									style={{ opacity: 0 }}></canvas>
								<div
									ref={lineRef}
									style={{
										position: 'absolute',
										left: '30%',
										right: '30%',
										borderRadius: '6px',
										height: 200,
									}}
								/>
								<div
									ref={lineRef2}
									style={{
										position: 'absolute',
										left: 0,
										right: 0,
										height: 10,
									}}
								/>
							</div>
						) : null}
					</Grid>
					<Grid item sm={3} />
				</Grid>
			</div>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flex: 1,
		position: 'relative',
	},
}));

export default React.forwardRef(BarcodeScanner);
