import React from 'react';
import WorkingTime from '../../service/types/WorkingTime';
import WorkTimeTrack from '../../service/types/WorkTimeTrack';
import { WorkingTimeState } from './workingTimeReducer';

export type WorkingTimeContextActions = {
	setRecord: (record: WorkTimeTrack | undefined) => void;
	startTracking: (record: WorkTimeTrack) => Promise<boolean | void>;
	stopTracking: (record: WorkTimeTrack) => Promise<WorkingTime | void>;
};

const initialStateContext: WorkingTimeState = {
	record: undefined,
};

const initalActionContext: WorkingTimeContextActions = {
	setRecord: (record: WorkTimeTrack | undefined) => {},
	startTracking: (record: WorkTimeTrack) => {
		return Promise.resolve();
	},
	stopTracking: (record: WorkTimeTrack) => {
		return Promise.resolve();
	},
};

export const WorkingTimeStateContext = React.createContext(initialStateContext);
export const WorkingTimeActionsContext = React.createContext(initalActionContext);
