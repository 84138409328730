import { ContactParams } from '../containers/ContactUsContainer';
import { AUTH_TOKEN, REFRESH_TOKEN } from '../context/auth/authReducer';
import { ContractStatusType, ShiftInterface } from '../helpers/contractHelper/generalContractHelper';
import { QAILoginParams, ResetPasswordParams, UserLoginParams } from '../helpers/userHelper/generalUserHelper';
import Axios from 'axios';
import { Instances, Service, services } from './service';
import Contract, { ContractSummaryInterface } from './types/Contract';
import Customer from './types/Customer';
import MaterialTool from './types/MaterialTool';
import RhenusCase from './types/RhenusCase';
import RhenusOverviewInformation from './types/RhenusOverviewInformation';
import StandardCaseInput, { StandardCaseFilterInterface } from './types/StandardCase';
import Travel from './types/Travel';
import User, { CreateEmployeeParams, UpdateEmployeeParams } from './types/User';
import WorkingTime from './types/WorkingTime';
import WorkTimeTrack from './types/WorkTimeTrack';
import moment, { Moment } from 'moment';
import { LoginResponse } from '../consts/login';
import Transport from './types/Transport';
import BreakTime, { breakTimefromJSON, RecordTimeType } from './types/BreakTime';
import { DashboardFilterInterface } from '../helpers/dashboardHelper/generalDashboardHelper';
import {
	ExportWorkingTimeInterface,
	WorkingTimeFilterInterface,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import BreakTimeQA from './types/BreakTimeQA';
import Invoice, { InvoiceItem } from './types/Invoice';
import { CreateGroup, UpdateGroup } from '../pages/GroupCreatePage';
import { Group } from '../pages/groupOverview/GroupOverview';

export const PHOTO_LOCATION_NAME = '/contract-attachments/';

class Client implements Service {
	constructor(private clientInstances: Instances) {}

	public setJWTToken(JWTToken: string) {
		services.forEach((service) => {
			//@ts-ignore
			this.clientInstances[service].setJWTtoken(JWTToken);
		});
	}

	public async login(params: UserLoginParams): Promise<LoginResponse | undefined> {
		const res = await this.clientInstances.auth.call('Login', {
			details: {
				...params,
			},
		});
		if (res?.result) {
			localStorage.setItem(REFRESH_TOKEN, res.result.rt);
			return { at: res.result.at, acceptedPp: res.result.acceptedPp };
		}

		return undefined;
	}

	public async loginQAI(params: QAILoginParams): Promise<LoginResponse | undefined> {
		const res = await this.clientInstances.auth.call('LoginQAI', {
			details: {
				...params,
			},
		});
		if (res?.result) {
			localStorage.setItem(REFRESH_TOKEN, res.result.rt);
			return { at: res.result.at, acceptedPp: res.result.acceptedPp };
		}

		return undefined;
	}

	public async checkToken(): Promise<boolean> {
		const res = await this.clientInstances.auth.call('CheckToken', {});
		if (res?.result) return true;
		return false;
	}

	public async getAuthToken(refreshToken: string): Promise<string | undefined> {
		const res = await this.clientInstances.auth.call('RefreshToken', { rt: refreshToken });
		if (res?.result) {
			return res.result.at;
		}
		return undefined;
	}

	public async requestPasswordReset(email: string): Promise<boolean> {
		const res = await this.clientInstances.auth.call('RequestPasswordReset', { email: email });
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async checkPasswordRessetToken(token: string): Promise<boolean> {
		const res = await this.clientInstances.auth.call('CheckPasswordResetToken', { uuid: token });
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async resetPassword(params: ResetPasswordParams): Promise<boolean> {
		const res = await this.clientInstances.auth.call('ConfirmPasswordReset', {
			...params,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async resetPasswordQAI(params: ResetPasswordParams): Promise<boolean> {
		const res = await this.clientInstances.auth.call('ConfirmPasswordResetQAI', {
			...params,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async getAllUsers(): Promise<User[]> {
		const res = await this.clientInstances.auth.call('ReadAllUsers', {});
		if (res?.result) {
			return res.result.userDetails.map(User.fromJSON);
		}
		return [];
	}

	public async readGroups(): Promise<Group[]> {
		const res = await this.clientInstances.auth.call('ReadUserGroups', {});
		return res?.result.details ?? [];
	}
	public async createGroup(data: CreateGroup): Promise<boolean> {
		const res = await this.clientInstances.auth.call('CreateUsersGroup', { details: data });
		return Boolean(res?.id);
	}
	public async readGroup(id: number): Promise<Group> {
		const res = await this.clientInstances.auth.call('ReadUsersGroupById', { groupId: id });
		return res?.result.details;
	}
	public async updateGroup(data: UpdateGroup): Promise<boolean> {
		const res = await this.clientInstances.auth.call('UpdateUsersGroup', { details: data });
		return Boolean(res?.id);
	}
	public async deleteGroup(id: number): Promise<boolean> {
		const res = await this.clientInstances.auth.call('DeleteUsersGroup', { groupId: id });
		return Boolean(res?.id);
	}

	public async getAll(): Promise<User[]> {
		const res = await this.clientInstances.auth.call('ReadAllUsers', {});
		if (res?.result) {
			return res.result.userDetails.map(User.fromJSON);
		}
		return [];
	}

	public async logout(): Promise<boolean> {
		const res = await this.clientInstances.auth.call('Logout', {});
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async getContracts(): Promise<Contract[]> {
		const res = await this.clientInstances.contract.call('ReadAll', {});
		if (res?.result && res?.result.contracts) {
			return res.result.contracts.map((contract: any) => Contract.fromJSON(contract));
		}
		return [];
	}

	public async createEmployee(params: CreateEmployeeParams): Promise<boolean> {
		const res = await this.clientInstances.auth.call('CreateEmployee', { details: params });
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async createQAI(params: CreateEmployeeParams): Promise<boolean> {
		const res = await this.clientInstances.auth.call('CreateQAI', { details: params });
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async getUserInfo(): Promise<User> {
		const res = await this.clientInstances.auth.call('ReadMyDetails', {});
		if (res?.result) {
			return User.fromJSON(res.result.userDetails);
		}
		//@ts-ignore
		return false;
	}

	public async getAllCustomers(): Promise<Customer[]> {
		const res = await this.clientInstances.auth.call('ReadAllCustomers', {});
		if (res?.result && res.result.customerDetails) {
			return res.result.customerDetails.map(Customer.fromJSON);
		}
		return [];
	}
	public async getAllQAIs(): Promise<User[]> {
		const res = await this.clientInstances.auth.call('ReadAllQAIs', {});
		if (res?.result.userDetails) {
			return res.result.userDetails.map(User.fromJSON);
		}
		return [];
	}
	public async getAllTeamLeaders(): Promise<User[]> {
		const res = await this.clientInstances.auth.call('ReadAllTeamLeaders', {});
		if (res?.result.userDetails) {
			return res.result.userDetails.map(User.fromJSON);
		}
		return [];
	}
	public async createCustomer(customer: Customer): Promise<number> {
		const res = await this.clientInstances.auth.call('CreateCustomer', { details: Customer.toJSON(customer) });
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}

	public async createUserForCustomer(id: number, params: CreateEmployeeParams): Promise<number> {
		const res = await this.clientInstances.auth.call('CreateUserForCustomer', { customerID: id, details: params });
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}

	public async getCustomerFromUser(id: number): Promise<Customer> {
		const res = await this.clientInstances.auth.call('GetCustomerForUser', { id: id });
		if (res?.result) {
			return Customer.fromJSON(res.result.customerDetails);
		}
		//@ts-ignore
		return false;
	}
	public async deleteUserByID(id: number): Promise<boolean> {
		const res = await this.clientInstances.auth.call('DeleteUser', { id: id });
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateUserByID(id: number, params: UpdateEmployeeParams): Promise<boolean> {
		const res = await this.clientInstances.auth.call('UpdateUser', { id: id, details: params });
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateCustomerByID(id: number, customer: Customer): Promise<boolean> {
		const res = await this.clientInstances.auth.call('UpdateCustomer', {
			id: id,
			details: Customer.toJSON(customer),
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async getAllStandardCaseInputs(contractID: number): Promise<StandardCaseInput[]> {
		const res = await this.clientInstances.contract.call('ReadAllStandardErrorInputs', {
			contractID: contractID,
		});

		if (res?.result && res.result.errorInputs) {
			return res.result.errorInputs.map(StandardCaseInput.fromJSON);
		}
		return [];
	}
	public async updateStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateStandardErrorInput', {
			errorInput: { ...StandardCaseInput.toJSON(errorInput), shift_id: errorInput.shift },
			contractID: contractID,
		});

		if (res?.result) {
			return true;
		}
		return false;
	}
	public async createContract(contract: Contract): Promise<number> {
		const res = await this.clientInstances.contract.call('Create', { contract: { ...contract.toJSON() } });
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}

	public async createInvoice(
		contractID: number,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment
	): Promise<number> {
		const res = await this.clientInstances.contract.call('CreateInvoice', {
			contractID: contractID,
			invoice: {
				date_of_invoice: dateOfInvoice,
				from_date: fromDate,
				to_date: toDate,
			},
		});

		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}

	public async updateInvoice(
		id: number,
		creationDate: Moment,
		dateOfInvoice: Moment,
		fromDate: Moment,
		toDate: Moment,
		billNumber: string
	): Promise<void> {
		await this.clientInstances.contract.call('UpdateInvoice', {
			invoice: {
				id: id,
				date_of_invoice: dateOfInvoice,
				from_date: fromDate,
				to_date: toDate,
				creation_date: creationDate,
				bill_number: billNumber,
			},
		});
	}

	public async completeInvoice(id: number): Promise<void> {
		await this.clientInstances.contract.call('CompleteInvoice', {
			id: id,
		});
	}

	public async deleteInvoice(id: number): Promise<void> {
		await this.clientInstances.contract.call('DeleteInvoice', {
			id: id,
		});
	}

	public async getAllInvoices(contractID: number): Promise<any> {
		const res = await this.clientInstances.contract.call('ReadAllInvoices', {
			contractID: contractID,
		});

		if (res?.result) {
			return res.result.invoices.map(Invoice.fromJSON).sort((a: Invoice, b: Invoice) => b.id - a.id);
		}

		return [];
	}

	public async exportInvoice(id: number): Promise<string | undefined> {
		const res = await this.clientInstances.contract.call('ExportInvoice', {
			id: id,
		});

		return res?.result && res.result.file ? res.result.file : undefined;
	}

	public async getInvoiceById(invoiceID: number): Promise<InvoiceItem | undefined> {
		const res = await this.clientInstances.contract.call('ReadInvoice', {
			id: invoiceID,
		});

		if (res?.result) {
			return InvoiceItem.fromJSON(res.result?.invoice);
		}

		return undefined;
	}

	public async deleteContractByID(contractID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('Delete', { id: contractID });
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async contact(params: ContactParams): Promise<boolean> {
		return Promise.resolve(true);
	}
	public async createStandradCaseInput(contractID: number, errorInput: StandardCaseInput): Promise<boolean> {
		const res = await this.clientInstances.contract.call('CreateStandardErrorInput', {
			errorInput: { ...StandardCaseInput.toJSON(errorInput), shift_id: errorInput.shift },
			contractID: contractID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteStandradCaseInputByID(contractID: number, errorInputID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteStandardErrorInput', {
			id: errorInputID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async getRhenusOverviewInformations(
		contractID: number,
		from: Moment,
		to: Moment
	): Promise<RhenusOverviewInformation[]> {
		const res = await this.clientInstances.contract.call('ReadRhenusOverviewInformations', {
			id: contractID,
			start: from.toISOString(),
			end: to.toISOString(),
		});
		if (res?.result && res.result.overview) {
			return res.result.overview.map(RhenusOverviewInformation.fromJSON);
		}
		return [];
	}
	public async getZoneCaseInputs(contractID: number, zoneID: number): Promise<RhenusCase[]> {
		const res = await this.clientInstances.contract.call('ReadAllRhenusInputsByZone', {
			ID: zoneID,
		});
		if (res?.result && res.result.errorInputs) {
			return res.result.errorInputs.map(RhenusCase.fromJSON);
		}
		return [];
	}
	public async deleteRhenusCaseInputByID(contractID: number, zoneID: number, errorID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteRhenusErrorInput', {
			ID: errorID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async createRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		const res = await this.clientInstances.contract.call('CreateRhenusErrorInput', {
			errorInput: { ...RhenusCase.toJSON(rhenusCaseInput) },
			contractID: contractID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateRhenusCaseInput(
		contractID: number,
		zoneID: number,
		rhenusCaseInput: RhenusCase
	): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateRhenusErrorInput', {
			errorInput: { ...RhenusCase.toJSON(rhenusCaseInput) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async sendErrorReport(message: string): Promise<boolean> {
		return true;
	}
	public async addManualWorkTime(contractID: number, worktime: WorkingTime): Promise<number> {
		const res = await this.clientInstances.contract.call('AddManualWorkingTime', {
			workingTime: { ...WorkingTime.toJSON(worktime) },
			contractID: contractID,
		});
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}
	public async addManualBreakTime(contractID: number, worktime: BreakTime): Promise<number> {
		const res = await this.clientInstances.contract.call(
			worktime.type === RecordTimeType.Break ? 'AddManualBreakTime' : 'AddManualWaitingTime',
			{
				breakTime: { ...BreakTimeQA.toJSON(worktime) },
				waitingTime: { ...BreakTime.toJSON(worktime) },
				contractID: contractID,
			}
		);
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}
	public async startTrackingTime(contractID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('StartTrackingTime', {
			contractID: contractID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async stopTrackingTime(
		contractID: number,
		profession: number | undefined
	): Promise<WorkingTime | undefined> {
		const res = await this.clientInstances.contract.call('StopTrackingTime', {
			contractID: contractID,
			profession: profession,
		});
		if (res?.result && res.result.wt) {
			return WorkingTime.fromJSON(res.result.wt);
		} else if (res?.result) {
			return new WorkingTime(0, 0, 0, moment(), moment(), moment(), 0, 0, false);
		}
		return undefined;
	}
	public async getAllRecords(contractID: number): Promise<WorkingTime[]> {
		const res = await this.clientInstances.contract.call('ReadAllTimes', {
			contractID: contractID,
		});

		if (res?.result) {
			return [
				...(res.result.times.working_time || []).map(WorkingTime.fromJSON),
				...(res.result.times.waiting_time || []).map((item: any) =>
					BreakTime.fromJSON(item, RecordTimeType.Waiting)
				),
				...(res.result.times.break_time || []).map((item: any) =>
					breakTimefromJSON(item, RecordTimeType.Break)
				),
			];
		}
		return [];
	}

	public async readShifts(contractID: number): Promise<ShiftInterface[]> {
		const res = await this.clientInstances.contract.call('ReadShifts', {
			contractID: contractID,
		});

		if (res?.result) {
			return res.result.shifts;
		}
		return [];
	}
	public async deleteRecordByID(contractID: number, recordID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteWorkingTime', {
			workID: recordID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteBreakByID(contractID: number, recordID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteBreakTime', {
			breakID: recordID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteWaitingByID(contractID: number, recordID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteWaitingTime', {
			waitiD: recordID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateRecordByID(contractID: number, record: WorkingTime): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateWorkingTime', {
			workingTime: { ...WorkingTime.toJSON(record) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateBreakByID(contractID: number, record: BreakTime): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateBreakTime', {
			breakTime: { ...BreakTimeQA.toJSON(record) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateWaitingByID(contractID: number, record: BreakTime): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateWaitingTime', {
			waitingTime: { ...BreakTime.toJSON(record) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async getMaterialExpenses(contractID: number): Promise<MaterialTool[]> {
		const res = await this.clientInstances.contract.call('ReadMaterialExpenses', {
			contractID: contractID,
		});
		if (res?.result && res.result.materials) {
			return res.result.materials
				.map(MaterialTool.fromJSON)
				.sort((a: MaterialTool, b: MaterialTool) => b.id - a.id);
		}
		return [];
	}
	public async getToolExpenses(contractID: number): Promise<MaterialTool[]> {
		const res = await this.clientInstances.contract.call('ReadToolExpenses', {
			contractID: contractID,
		});
		if (res?.result && res.result.tools) {
			return res.result.tools.map(MaterialTool.fromJSON).sort((a: MaterialTool, b: MaterialTool) => b.id - a.id);
		}
		return [];
	}
	public async getTravelExpenses(contractID: number): Promise<Travel[]> {
		const res = await this.clientInstances.contract.call('ReadTravelExpenses', {
			contractID: contractID,
		});
		if (res?.result && res.result.travels) {
			return res.result.travels.map(Travel.fromJSON).sort((a: Travel, b: Travel) => b.id - a.id);
		}
		return [];
	}
	public async createMaterialExpenses(contractID: number, material: MaterialTool): Promise<number> {
		const res = await this.clientInstances.contract.call('CreateMaterialExpenses', {
			contractID: contractID,
			material: { ...MaterialTool.toJSON(material) },
		});
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}
	public async createTravelExpenses(contractID: number, travel: Travel): Promise<number> {
		const res = await this.clientInstances.contract.call('CreateTravelExpenses', {
			contractID: contractID,
			travel: { ...Travel.toJSON(travel) },
		});
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}
	public async createToolExpenses(contractID: number, tool: MaterialTool): Promise<number> {
		const res = await this.clientInstances.contract.call('CreateToolExpenses', {
			contractID: contractID,
			tool: { ...MaterialTool.toJSON(tool) },
		});
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}
	public async updateMaterialExpenses(contractID: number, material: MaterialTool): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateMaterialExpenses', {
			material: { ...MaterialTool.toJSON(material) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateTravelExpenses(contractID: number, travel: Travel): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateTravelExpenses', {
			travel: { ...Travel.toJSON(travel) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateToolExpenses(contractID: number, tool: MaterialTool): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateToolExpenses', {
			tool: { ...MaterialTool.toJSON(tool) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteMaterialExpensesByID(contractID: number, materialID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteMaterialExpenses', {
			mID: materialID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteTravelExpensesByID(contractID: number, travelID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteTravelExpenses', {
			tID: travelID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteToolExpensesByID(contractID: number, toolID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteToolExpenses', {
			tID: toolID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async openCloseContract(contractID: number, status: ContractStatusType): Promise<boolean> {
		const res = await this.clientInstances.contract.call(status ? 'Open' : 'Close', {
			id: contractID,
		});

		if (res?.result) {
			return true;
		}
		return false;
	}
	public async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
		const res = await this.clientInstances.auth.call('ChangePassword', {
			oldPassword: oldPassword,
			newPassword: newPassword,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async getAllQAIsByTeamLeader(teamLeaderID: number): Promise<User[]> {
		const res = await this.clientInstances.auth.call('ReadAllQAISByTL', { TLID: teamLeaderID });
		if (res?.result.userDetails) {
			return res.result.userDetails.map((item: any) => User.fromJSON(item));
		}
		return [];
	}
	public async aprroveInput(contractID: number, inputID: number, zoneID?: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call(
			zoneID ? 'ApproveRhenusErrorInput' : 'ApproveStandardErrorInput',
			{
				contractID: contractID,
				seiID: inputID,
				ID: inputID,
			}
		);
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async updateContract(contract: Contract): Promise<boolean> {
		const res = await this.clientInstances.contract.call('Update', { contract: { ...contract.toJSON() } });
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async checkTrackingTime(): Promise<WorkTimeTrack | undefined> {
		const res = await this.clientInstances.contract.call('CheckTrackingTime', {});
		if (res?.result && res.result.tc && res.result.tc.contract_id) {
			return WorkTimeTrack.fromJSON(res.result.tc);
		}
		return undefined;
	}
	public async getTeamLeaderByContract(contractID: number): Promise<User | undefined> {
		const res = await this.clientInstances.contract.call('ReadTLByContract', {
			cID: contractID,
		});
		if (res?.result && res.result.userDetails) {
			return User.fromJSON(res.result.userDetails);
		}
		return undefined;
	}
	public async getCustomerByContract(contractID: number): Promise<Customer | undefined> {
		const res = await this.clientInstances.contract.call('ReadCustomerByContract', {
			cID: contractID,
		});
		if (res?.result && res.result.customerDetails) {
			return Customer.fromJSON(res.result.customerDetails);
		}
		return undefined;
	}
	public async acceptPrivacyPolicy(): Promise<boolean> {
		const res = await this.clientInstances.auth.call('AcceptPP', {});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async getContract(contractID: number): Promise<Contract | undefined> {
		const res = await this.clientInstances.contract.call('Read', {
			id: contractID,
		});
		if (res?.result && res.result.contract) {
			return Contract.fromJSON(res.result.contract);
		}
		return undefined;
	}
	public async getStandardCaseInputByID(
		contractID: number,
		errorInputID: number
	): Promise<StandardCaseInput | undefined> {
		const res = await this.clientInstances.contract.call('ReadStandardInputByID', {
			id: errorInputID,
		});
		if (res?.result && res.result.errorInputs) {
			return StandardCaseInput.fromJSON(res.result.errorInputs);
		}
		return undefined;
	}
	public async getUserByID(userID: number): Promise<User | undefined> {
		const res = await this.clientInstances.auth.call('ReadUserByID', {
			id: userID,
		});
		if (res?.result && res.result.userDetails) {
			return User.fromJSON(res.result.userDetails);
		}
		return undefined;
	}
	public async getCustomerByID(customerID: number): Promise<Customer | undefined> {
		const res = await this.clientInstances.auth.call('ReadCustomerByID', {
			id: customerID,
		});
		if (res?.result && res.result.customerDetails) {
			return Customer.fromJSON(res.result.customerDetails);
		}
		return undefined;
	}
	public async getTransportExpenses(contractID: number): Promise<Transport[]> {
		const res = await this.clientInstances.contract.call('ReadTransportExpenses', {
			contractID: contractID,
		});
		if (res?.result && res.result.transport) {
			return res.result.transport.map(Transport.fromJSON).sort((a: Transport, b: Transport) => b.id - a.id);
		}
		return [];
	}
	public async updateTransportExpenses(contractID: number, transport: Transport): Promise<boolean> {
		const res = await this.clientInstances.contract.call('UpdateTransportExpenses', {
			transport: { ...Transport.toJSON(transport) },
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async deleteTransportExpensesByID(contractID: number, transportID: number): Promise<boolean> {
		const res = await this.clientInstances.contract.call('DeleteTransportExpenses', {
			cID: transportID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}
	public async createTransportExpenses(contractID: number, transport: Transport): Promise<number> {
		const res = await this.clientInstances.contract.call('CreateTransportExpenses', {
			transport: { ...Transport.toJSON(transport) },
			contractID: contractID,
		});
		if (res?.result) {
			return res.result.id;
		}
		return 0;
	}
	public async getContactQAI24ByContract(contractID: number): Promise<User | undefined> {
		const res = await this.clientInstances.contract.call('ReadContactQAIByContract', {
			cID: contractID,
		});

		if (res?.result && res.result.userDetails) {
			return User.fromJSON(res.result.userDetails);
		}
		return undefined;
	}

	public async getRhenusCaseInputByID(errorID: number): Promise<RhenusCase | undefined> {
		const res = await this.clientInstances.contract.call('ReadRhenusInputByID', {
			id: errorID,
		});
		if (res?.result && res.result.errorInput) {
			return RhenusCase.fromJSON(res.result.errorInput);
		}
		return undefined;
	}
	public async getPhoto(photoURL: string): Promise<string | undefined> {
		const res = await Axios.get(PHOTO_LOCATION_NAME + photoURL, {
			headers: { 'Authorization-JWT': localStorage.getItem(AUTH_TOKEN) },
			responseType: 'blob',
		});
		if (res.data) return await convertFileToBase64(res.data);
		return undefined;
	}

	public async deleteCustomerByID(customerID: number): Promise<boolean> {
		const res = await this.clientInstances.auth.call('DeleteCustomerByID', {
			id: customerID,
		});
		if (res?.result) {
			return true;
		}
		return false;
	}

	public async createStandardErrorInputsExcelSheet(
		contractID: number,
		data: DashboardFilterInterface,
		language: string
	): Promise<string | undefined> {
		const res = await this.clientInstances.contract.call('CreateStandardErrorInputsExcelSheet', {
			contractID,
			filter: { ...data, language },
		});

		if (res?.result) {
			return res.result.excelFile;
		}
		return undefined;
	}

	public async createProcessContractStandardErrorInputsExcelSheet(
		contractID: number,
		data: StandardCaseFilterInterface,
		language: string
	): Promise<string | undefined> {
		const res = await this.clientInstances.contract.call('CreateProcessContractStandardErrorInputsExcelSheet', {
			contractID,
			filter: { ...data, language },
		});

		if (res?.result) {
			return res.result.excelFile;
		}
		return undefined;
	}

	public async createWorkingTimeExcelSheet(
		contractID: number,
		data: WorkingTimeFilterInterface & ExportWorkingTimeInterface,
		language: string
	): Promise<string | undefined> {
		const res = await this.clientInstances.contract.call('CreateWorkingTimeExcelSheet', {
			contractID,
			filter: {
				...data,

				qai: +data.qai || null,
				reporter: +data.reporter || null,
				language,
				type: +data.type || null,
				billed: typeof data.billed === 'number' ? data.billed : null,
				anonymous: data.Anonymous,
				export_materials: data.ExportMaterials,
				export_tools: data.ExportTools,
				export_travels: data.ExportTravels,
				export_transport: data.ExportTransport,
			},
		});

		if (res?.result) {
			return res.result.excelFile;
		}
		return undefined;
	}

	public async contractSummary(
		contractID: number,
		fromDate: Moment | null,
		toDate: Moment | null
	): Promise<ContractSummaryInterface | undefined> {
		const res = await this.clientInstances.contract.call('ContractSummary', {
			contractID,
			fromDate,
			toDate,
		});

		if (res?.result) {
			return res.result.contractSummary;
		}
		return undefined;
	}
}

const convertFileToBase64 = (file: any): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			if (reader.result) resolve(reader.result.toString());
		};
		reader.onerror = reject;
	});

export default Client;
