import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useWindowSize } from '@react-hook/window-size';

import { useGlobalStyles } from '../consts/globalStyles';
import { fonts } from '../consts/fonts';
import { colors } from '../consts/colors';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import { Controller, useForm } from 'react-hook-form';
import AuthTextField from '../components/AuthTextField';
import { rules } from '../consts/rules';
import service from '../service/service';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import { Routes } from '../router/Routes';
import ErrorScreenIcon from '../svgComponents/ErrorScreenIcon';

function ErrorScreen() {
	const { t } = useTranslation();
	const [sendReport, setSendReport] = React.useState(false);
	const classes = useStyles({});
	const [windowWidth, windowHeight] = useWindowSize();
	const globalClasses = useGlobalStyles({
		window: {
			width: windowWidth,
			height: windowHeight,
		},
	});
	const dialogRef = React.useRef<null | StandardDialogInterface>(null);

	const { control, handleSubmit, errors } = useForm();

	const sendErrorReport = (data: any) => {
		service
			.sendErrorReport(data['message'])
			.then(() => {
				dialogRef.current?.setDialogState(true);
			})
			.catch(() => {});
	};

	const refreshPage = () => {
		window.location.reload();
	};

	return (
		<div>
			<StandardDialog
				acceptText={'send_error_report_screen_dialog_accept_button'}
				description={'send_error_report_screen_dialog_description'}
				title={'send_error_report_screen_dialog_title'}
				onCancle={() => {
					dialogRef.current?.setDialogState(false);
				}}
				onAccept={() => {
					window.location.href = Routes.Home;
				}}
				ref={dialogRef}
				showWarning
			/>
			{!sendReport ? (
				<div className={globalClasses.authContainerWrapper}>
					<Grid container>
						<Grid item sm={3} />
						<Grid item sm={6} className={classes.gridWrapper}>
							<ErrorScreenIcon />
							<div className={classes.titleWrapper}>
								<span className={classes.title}>{t('error_screen_title')}</span>
							</div>
							<div className={classes.descriptionWarpper}>
								<span className={globalClasses.bigText}>{t('error_screen_description')}</span>
							</div>
							<div className={classes.row}>
								<Button
									text={t('error_screen_try_again')}
									textClassName={globalClasses.buttonText}
									buttonWrapperClassName={classes.buttonWrapper}
									onClick={refreshPage}
								/>
								<Button
									text={t('error_screen_report_error')}
									textClassName={globalClasses.buttonTextInverted}
									buttonWrapperClassName={classes.buttonWrapperInverted}
									onClick={() => {
										setSendReport(true);
									}}
								/>
							</div>
						</Grid>
						<Grid item sm={3} />
					</Grid>
				</div>
			) : (
				<div className={globalClasses.authContainerWrapper}>
					<Grid container>
						<Grid item sm={4} />
						<Grid item sm={4}>
							<div className={classes.reportErrorTitleWrapper}>
								<span className={globalClasses.title}>{t('send_error_report_screen_title')}</span>
							</div>
							<div className={classes.descriptionWrapper}>
								<span className={globalClasses.description}>
									{t('send_error_report_screen_description')}
								</span>
							</div>

							<div className={classes.emailWrapper}>
								<Controller
									defaultValue={''}
									control={control}
									name={'message'}
									rules={{
										required: rules(t).required,
										minLength: rules(t).minLength(10),
										pattern: rules(t).notEmptyPattern,
									}}
									render={({ onChange }) => (
										<AuthTextField
											onChange={onChange}
											label={t('send_error_report_screen_message')}
											multiline={true}
											rows={6}
											error={errors['message'] ? true : false}
											helperText={errors['message'] ? errors['message'].message : ''}
										/>
									)}
								/>
							</div>
							<Button
								onClick={handleSubmit(sendErrorReport)}
								text={t('send_error_report_screen_send')}
								textClassName={globalClasses.buttonText}
								buttonWrapperClassName={globalClasses.buttonWrapper}
							/>
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	gridWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'column',
		margin: '50px 0px',
	},
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '12px 42px',
		cursor: 'pointer',
	},
	buttonWrapperInverted: {
		marginLeft: '12px',
		height: 'fit-content',
		padding: '12px 29px',
		border: `1px solid ${colors.darkGray}`,
		borderRadius: '4px',
		backgroundColor: colors.white,
		cursor: 'pointer',
	},
	titleWrapper: {
		marginTop: '81px',
		marginBottom: '16px',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	row: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		fontFamily: fonts.bold,
		fontSize: '32px',
		lineHeight: '48px',
		color: colors.darkGray,
	},
	descriptionWarpper: {
		marginBottom: '32px',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		textAlign: 'center',
	},
	wrapper: {
		marginTop: '50px',
	},
	emailWrapper: {
		marginBottom: '24px',
	},
	descriptionWrapper: {
		marginBottom: '12px',
	},
	reportErrorTitleWrapper: {
		marginBottom: '20px',
	},
}));

export default ErrorScreen;
