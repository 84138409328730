import { History, LocationState } from 'history';
import { userFields } from '../../containers/UserContainer';
import { RouteParams } from '../../router/Routes';
import service, { Service } from '../../service/service';
import User, { UserRole } from '../../service/types/User';
import { goToHome, UserNavigationInterface } from '../navigationHelper';
import { KeyValue } from './generalUserHelper';

export function onUserScreenMount(
	history: History<LocationState>,
	routeParams: RouteParams,
	setUser: (user: User | undefined) => void,
	setIsUserEditing: (val: boolean) => void,
	setMounted: (val: boolean) => void
) {
	const historyState = history.location.state as undefined | UserNavigationInterface;
	if (historyState?.user && historyState.user.teamLeaderIDs) {
		setUser(historyState.user);
		setMounted(true);
	} else if (historyState?.user) {
		setUser(User.fromUserUpdate(historyState.user));
		setMounted(true);
	} else if (routeParams.user_id) {
		service
			.getUserByID(Number(routeParams.user_id))
			.then((res) => {
				if (res) {
					setMounted(true);
					setUser(res);
				}
			})
			.catch((e) => {
				goToHome(history);
			});
	} else {
		setIsUserEditing(true);
		setUser(undefined);
		setMounted(true);
	}
}

interface Filter {
	property: string;
	value: string | boolean | number;
}

export async function getUserOptionsKeyValuePairs(
	data: Promise<User[]>,
	setLoading: (val: boolean) => void,
	filter?: Filter
): Promise<KeyValue[]> {
	setLoading(true);
	const users = await data.catch((e) => {
		setLoading(false);
	});

	setLoading(false);

	if (users && filter) {
		return (
			users
				//@ts-ignore
				.filter((el: User) => el[filter.property] === filter.value)
				.map((user) => ({
					value: user,
					key: `${user.firstName} ${user.lastName}`,
				}))
		);
	}

	if (users)
		return users.map((user) => ({
			value: user,
			key: `${user.firstName} ${user.lastName}`,
		}));
	return [];
}

export async function setUserInfo(
	user: User,
	autoCompleteRef: React.MutableRefObject<boolean>,
	service: Service,
	setValue: (fieldName: string, val: any) => void,
	setTeamLeaders: (teamLeaders: KeyValue[]) => void
) {
	setValue(userFields.role, user.role);
	setValue(userFields.originOfEmployee, user.originOfEmployee);
	setValue(userFields.firstName, user.firstName);
	setValue(userFields.lastName, user.lastName);
	setValue(userFields.email, user.email);
	setValue(userFields.username, user.username);
	setValue(userFields.phonenumber, user.phonenumber);
	setValue(userFields.tlGroups, user.tlGroups);
	if (user.role === UserRole.QualityAssuranceInspector) {
		autoCompleteRef.current = true;
		const teamLeaders = await getUserOptionsKeyValuePairs(service.getAllTeamLeaders(), () => {});
		autoCompleteRef.current = false;
		setTeamLeaders(teamLeaders.filter((teamLeader) => teamLeader.value.id !== user.id));
		setValue(
			userFields.teamLeader,
			user.teamLeaderIDs
				?.map((id) => teamLeaders.find((teamLeader) => teamLeader.value.id === id && !teamLeader.value.deleted))
				.filter(Boolean)
		);
	}
}
