import React from 'react';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import TabTitle from '../components/TabTitle';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { useTranslation } from 'react-i18next';
import { Expenses, ExpensesKeys, filterBilledOptions } from '../helpers/expenses/generalExpensesHelper';
import { colors } from '../consts/colors';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import StandardDatePicker from '../components/StandardDatePicker';
import {
	IStartBillingValues,
	startBillingFields,
	SummaryFilterInterface,
	SummaryFilterKeys,
} from '../containers/SummaryContainer';
import moment, { Moment } from 'moment';
import { ContractStatusType } from '../helpers/contractHelper/generalContractHelper';
import StandardTextField from '../components/StandardTextField';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import RecordListSummary from '../components/RecordListSummary';
import SummaryExpensesWrapper from '../components/SummaryExpensesWrapper';
import { useAuthStateContext } from '../context/auth/useAuth';
import { UserRole } from '../service/types/User';
import Button from '../components/Button';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import Customer from '../service/types/Customer';
import { Controller, useFormContext } from 'react-hook-form';
import { rules } from '../consts/rules';
import CalendarIcon from '../assets/images/svgs/CalendarIcon';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InvoiceListSummary from '../components/InvoiceListSummary';
import Invoice from '../service/types/Invoice';
import { IContractWorkLocation } from '../service/types/Contract';

interface Props {
	intervalDates: IntervalDatePickerDates;
	startBillingDates: IntervalDatePickerDates;
	allUsers: KeyValue[];
	contractNumber: string;
	contractStatus: ContractStatusType;
	workLocations: IContractWorkLocation[];
	filters: SummaryFilterInterface;
	isAdmin: boolean;
	summaryData: Expenses;
	tabType: ExpensesKeys;
	contractCustomer: string;
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	customers: Customer[];
	openCloseContractPress: () => void;
	onFilterChange: (key: SummaryFilterKeys, val: any) => void;
	onTabPress: (tabType: ExpensesKeys) => void;
	openDialog: () => void;
	onDialogCancel: () => void;
	onSubmit: (data: IStartBillingValues) => void;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
	//start billing datepicker methods
	onKeyboardBillingDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onStartBillingDateChange: (date: MaterialUiPickersDate) => void;
	onStartBillingDatesAccept: () => void;
	onStartBillingDatesCancel: () => void;
	clearStartBillingDates: () => void;
}
function SummaryScreen({
	allUsers,
	contractNumber,
	contractStatus,
	filters,
	isAdmin,
	summaryData,
	tabType,
	startBillingDates,
	clearDates,
	intervalDates,
	onDateChange,
	onDatesAccept,
	onDatesCancel,
	openCloseContractPress,
	onFilterChange,
	onTabPress,
	onDialogCancel,
	clearStartBillingDates,
	onStartBillingDateChange,
	onStartBillingDatesAccept,
	onStartBillingDatesCancel,
	contractCustomer,
	customers,
	dialogRef,
	openDialog,
	onSubmit,
	workLocations,
	onKeyboardDateChange,
	onKeyboardBillingDateChange,
}: Props) {
	const classes = useStyles({ contractStatus: contractStatus });
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();
	const { user } = useAuthStateContext();
	const { control, errors, handleSubmit, getValues } = useFormContext();

	const timeTitle =
		(filters.fromDate ? moment(filters.fromDate).format('DD.MM.YYYY') : t('working_time_screen_no_selection')) +
		' - ' +
		(filters.toDate ? moment(filters.toDate).format('DD.MM.YYYY') : t('working_time_screen_no_selection'));

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
				<HomeButton />
			</div>
			<div className={classes.row}>
				<div className={classes.statusWrapper}>
					<span className={globalClasses.smallText}>
						{contractStatus
							? t('summary_screen_contract_status_opened')
							: t('summary_screen_contract_status_closed')}
					</span>
				</div>
				<div className={classes.subtitleWrapper}>
					<span className={globalClasses.smallText}>{`${t('expenses_screen_subtitle')}: `}</span>
					<span className={globalClasses.smallTextDark}>{`${contractNumber}`}</span>
				</div>
			</div>
			<div className={classes.titleRow}>
				<div className={classes.tabWrapper}>
					<div className={classes.titleWrapper}>
						<span className={globalClasses.title}>{t('summary_screen_title')}</span>
					</div>
					<div className={classes.horizontalLine} />
					<TabTitle
						type={'workingTime'}
						active={tabType}
						onPress={onTabPress}
						title={'summary_screen_working_time_title'}
					/>
					<TabTitle
						type={'materials'}
						active={tabType}
						onPress={onTabPress}
						title={'expenses_screen_materials'}
					/>
					<TabTitle type={'tools'} active={tabType} onPress={onTabPress} title={'expenses_screen_tools'} />
					<TabTitle type={'travels'} active={tabType} onPress={onTabPress} title={'expenses_screen_travel'} />
					<TabTitle
						type={'transport'}
						active={tabType}
						onPress={onTabPress}
						title={'expenses_screen_transport'}
					/>
					{user?.role === UserRole.Accounting && (
						<TabTitle
							type={'invoice'}
							active={tabType}
							onPress={onTabPress}
							title={'expenses_screen_invoice'}
						/>
					)}
				</div>
				<div className={classes.openCloseWrapper}>
					{isAdmin && (
						<span className={globalClasses.link} onClick={openCloseContractPress}>
							{contractStatus ? t('summary_screen_close_contract') : t('summary_screen_open_contract')}
						</span>
					)}
				</div>
			</div>
			{tabType !== 'invoice' && (
				<Grid container spacing={4} className={globalClasses.filterContainer}>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<IntervalDatePicker
							id="start"
							error={errors['fromDate']}
							helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
							value={filters.fromDate}
							label={t('expenses_screen_from_date')}
							dates={intervalDates}
							clearable={true}
							onKeyboardDateChange={onKeyboardDateChange}
							onDateChange={onDateChange}
							onDatesAccept={onDatesAccept}
							onDatesCancel={onDatesCancel}
							clearDates={clearDates}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<IntervalDatePicker
							id="end"
							error={errors['toDate']}
							helperText={errors['toDate'] ? errors['toDate'].message : ''}
							value={filters.toDate}
							label={t('expenses_screen_to_date')}
							dates={intervalDates}
							clearable={true}
							onKeyboardDateChange={onKeyboardDateChange}
							onDateChange={onDateChange}
							onDatesAccept={onDatesAccept}
							onDatesCancel={onDatesCancel}
							clearDates={clearDates}
						/>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<StandardTextField
							label={t('expenses_screen_status')}
							select
							value={filters.billed}
							onChange={(e) => onFilterChange('billed', e.target.value)}>
							{filterBilledOptions.map((item) => (
								<MenuItem key={item.key} value={item.value}>
									<div className={classes.inputWrapper}>
										<span className={classes.input}>{t(item.key)}</span>
									</div>
								</MenuItem>
							))}
						</StandardTextField>
					</Grid>
					<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
						<KeyValueAutoComplete
							label={t("QAI's").toUpperCase()}
							onChange={(e) => {
								if (e) {
									onFilterChange('qai', e);
								} else {
									onFilterChange('qai', []);
								}
							}}
							value={filters.qai}
							multiple={true}
							disableCloseOnSelect
							withCheckbox={true}
							options={allUsers.filter((user) => !user.value.deleted)}
						/>
					</Grid>
				</Grid>
			)}

			<div className={classes.tableWrapper}>
				{tabType === 'workingTime' ? (
					<div className={classes.recordWrapper}>
						{user?.role === UserRole.Accounting && (
							<div className={classes.btnContainer}>
								<Button
									text={t('start_billing_button')}
									buttonWrapperClassName={classes.btnWrapper}
									onClick={openDialog}
									textClassName={classes.btnText}
									renderAsButton
								/>
							</div>
						)}
						<RecordListSummary users={allUsers} timeTitle={timeTitle} records={summaryData.workingTime} />
					</div>
				) : tabType === 'invoice' ? (
					<InvoiceListSummary invoices={summaryData.invoice as Invoice[]} />
				) : (
					<SummaryExpensesWrapper
						summaryData={summaryData}
						users={allUsers}
						tabType={tabType}
						timeTitle={timeTitle}
						workLocations={workLocations}
					/>
				)}
			</div>

			<StandardDialog
				paperClass={classes.paper}
				acceptText={t('save')}
				description={
					<div>
						<div className={`${globalClasses.label} ${classes.periodLabel}`}>{t('billing_period')}</div>
						<div className={classes.fieldWrapper}>
							<Controller
								defaultValue={null}
								control={control}
								name={startBillingFields.billingPeriodStart}
								rules={{
									required: rules(t).required,
									validate: (val: Moment) => {
										const endDate = moment(getValues(startBillingFields.billingPeriodEnd));
										const startDate = moment(val);
										if (startDate.isAfter(endDate)) {
											return 'Start date must be before end date!';
										}

										return true;
									},
								}}
								render={({ onChange, value }) => (
									<IntervalDatePicker
										id="start"
										wrapper={`${classes.pickerInput}`}
										value={startBillingDates.fromDate}
										label={''}
										dates={startBillingDates}
										clearable={true}
										onKeyboardDateChange={onKeyboardBillingDateChange}
										onDateChange={onStartBillingDateChange}
										onDatesAccept={onStartBillingDatesAccept}
										onDatesCancel={onStartBillingDatesCancel}
										clearDates={clearStartBillingDates}
										error={errors[startBillingFields.billingPeriodStart] ? true : false}
										helperText={
											errors[startBillingFields.billingPeriodStart]
												? errors[startBillingFields.billingPeriodStart].message
												: ''
										}
										endAdornment={<CalendarIcon className={classes.calendarIcon} />}
									/>
								)}
							/>
							<Controller
								defaultValue={null}
								control={control}
								name={startBillingFields.billingPeriodEnd}
								rules={{ required: rules(t).required }}
								render={({ onChange, value }) => (
									<IntervalDatePicker
										id="end"
										wrapper={`${classes.pickerInput} ${classes.pickerInputRight}`}
										value={startBillingDates.toDate}
										label={''}
										dates={startBillingDates}
										clearable={true}
										onDateChange={onStartBillingDateChange}
										onDatesAccept={onStartBillingDatesAccept}
										onDatesCancel={onStartBillingDatesCancel}
										clearDates={clearStartBillingDates}
										onKeyboardDateChange={onKeyboardBillingDateChange}
										error={errors[startBillingFields.billingPeriodEnd] ? true : false}
										helperText={
											errors[startBillingFields.billingPeriodEnd]
												? errors[startBillingFields.billingPeriodEnd].message
												: ''
										}
										endAdornment={<CalendarIcon className={classes.calendarIcon} />}
									/>
								)}
							/>
						</div>

						<div className={classes.fieldWrapper}>
							<Controller
								defaultValue={moment()}
								control={control}
								name={startBillingFields.creationDate}
								rules={{ required: rules(t).required }}
								render={({ onChange, value }) => (
									<StandardDatePicker
										wrapper={`${classes.pickerInput} `}
										label={t('creation_date')}
										onChange={onChange}
										disabled={true}
										value={value}
										error={errors[startBillingFields.creationDate] ? true : false}
										helperText={
											errors[startBillingFields.creationDate]
												? errors[startBillingFields.creationDate].message
												: ''
										}
										endAdornment={<CalendarIcon className={classes.calendarIcon} />}
									/>
								)}
							/>

							<Controller
								defaultValue={moment()}
								control={control}
								name={startBillingFields.dateOfInvoice}
								rules={{ required: rules(t).required }}
								render={({ onChange, value }) => (
									<StandardDatePicker
										wrapper={`${classes.pickerInput} ${classes.pickerInputRight}`}
										label={t('date_of_invoice')}
										onChange={onChange}
										disabled={false}
										value={value}
										error={errors[startBillingFields.dateOfInvoice] ? true : false}
										helperText={
											errors[startBillingFields.dateOfInvoice]
												? errors[startBillingFields.dateOfInvoice].message
												: ''
										}
										endAdornment={<CalendarIcon className={classes.calendarIcon} />}
									/>
								)}
							/>
						</div>

						<Controller
							defaultValue={contractCustomer || ''}
							control={control}
							name={startBillingFields.customer}
							rules={{ required: rules(t).required }}
							render={({ onChange, value }) => (
								<StandardTextField
									wrapper={classes.last}
									label={t('customer')}
									onChange={onChange}
									disabled={true}
									value={value}
									select
									error={errors[startBillingFields.customer] ? true : false}
									helperText={
										errors[startBillingFields.customer]
											? errors[startBillingFields.customer].message
											: ''
									}>
									{customers.map((customer) => (
										<MenuItem key={customer.id} value={customer.id.toString()}>
											<div>
												<span>{customer.name}</span>
											</div>
										</MenuItem>
									))}
								</StandardTextField>
							)}
						/>
					</div>
				}
				onCancle={onDialogCancel}
				onAccept={handleSubmit(onSubmit)}
				title={t('start_billing_button')}
				cancleText={t('error_service_dialog_cancel')}
				ref={dialogRef}
				showWarning={false}
				notShrinkable
			/>
		</div>
	);
}

interface StyleProps {
	contractStatus: ContractStatusType;
}

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
	},
	tabWrapper: {
		display: 'flex',
		marginTop: '4px',
		alignItems: 'center',
	},
	titleWrapper: {
		marginRight: '32px',
	},
	horizontalLine: {
		width: '1px',
		height: '40px',
		backgroundColor: colors.darkGray,
		marginRight: '32px',
	},
	subtitleWrapper: {
		marginTop: '3px',
	},
	statusWrapper: (p: StyleProps) => ({
		padding: '4px 8px',
		marginRight: '12px',
		borderRadius: '4px',
		height: 'fit-content',
		alignSelf: 'center',
		backgroundColor: p.contractStatus ? colors.lightGreen : colors.lightPink,
	}),
	titleRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	openCloseWrapper: {
		alignSelf: 'center',
		cursor: 'pointer',
	},
	recordWrapper: {
		marginTop: '0px',
		padding: '10px 16px 20px',
		backgroundColor: colors.keyButton,
	},
	tableWrapper: {
		marginTop: '24px',
	},
	input: {
		...globalInputStyle(),
		whiteSpace: 'pre',
	},
	inputWrapper: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	btnWrapper: {
		background: 'transparent',
		outline: 'none',
		border: 'none',
		cursor: 'pointer',
	},
	btnText: {
		color: colors.darkGray,
		fontSize: '14px',
		borderBottom: `1px solid ${colors.darkGray}`,
	},
	//popup styles
	fieldWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	pickerInputRight: {
		marginLeft: theme.spacing(1.2),
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	pickerInput: {
		width: 150,
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
	},
	last: {
		marginBottom: theme.spacing(2),
	},
	calendarIcon: {
		position: 'absolute',
		right: '4px',
	},
	periodLabel: {
		marginBottom: '4px',
	},
	btnContainer: {
		textAlign: 'right',
	},
	paper: {
		maxWidth: 'fit-content',
	},
}));

export default SummaryScreen;
