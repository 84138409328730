import * as React from 'react';

function WarningIcon(props: any) {
	return (
		<svg width={18} height={18} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g transform="translate(1 1)" fill="none" fillRule="evenodd">
				<circle
					stroke="#111"
					strokeWidth={1.5}
					fill="#FFF7CC"
					strokeLinecap="round"
					strokeLinejoin="round"
					cx={8}
					cy={8}
					r={7.467}
				/>
				<circle fill="#111" fillRule="nonzero" cx={8} cy={11.733} r={1.067} />
				<path stroke="#111" strokeWidth={1.5} strokeLinecap="square" d="M8 3.733v4.8" />
			</g>
		</svg>
	);
}

export default WarningIcon;
