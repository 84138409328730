import { TableCellType } from '../components/table/StandardTableCell';
import { RhenusCaseTableInterface } from '../service/types/RhenusCase';
import { TableParamInterface } from './rhenusOverview';

interface RhenusCaseParamInterface extends RhenusCaseTableInterface {
	productionNumber: TableParamInterface;
	qai: TableParamInterface | undefined;
	partNumber: TableParamInterface;
	partStatus: TableParamInterface;
	errorType: TableParamInterface;
	typeOfInspection: TableParamInterface;
	storage: TableParamInterface;
	approved: TableParamInterface | undefined;
	photo: TableParamInterface | undefined;
	date: TableParamInterface;
	shift: TableParamInterface | undefined;
	comment?: TableParamInterface | undefined;
}

export const rhenusCaseInputListParams: RhenusCaseParamInterface = {
	productionNumber: {
		title: 'rhenus_case_zone_overview_screen_production_number',
		width: '21.56vw',
		type: TableCellType.Sticky,
		titleType: TableCellType.Title,
		sticky: true,
	},
	qai: {
		title: 'rhenus_case_zone_overview_screen_reporter',
		width: '17.957vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	partNumber: {
		title: 'rhenus_case_zone_overview_screen_part_number',
		width: '10.832vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	partNumberShould: {
		title: 'rhenus_case_zone_overview_screen_part_number_should',
		width: '10.832vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	colorNumber: {
		title: 'rhenus_case_zone_overview_screen_color_number',
		width: '10.832vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	colorNumberShould: {
		title: 'rhenus_case_zone_overview_screen_color_number_should',
		width: '10.832vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	partStatus: {
		title: 'rhenus_case_zone_overview_screen_part_status',
		width: '11.01vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	amountOfParts: {
		title: 'rhenus_case_zone_overview_screen_amount_of_parts',
		width: '11.01vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	errorType: {
		title: 'rhenus_case_zone_overview_screen_error_type',
		width: '11.01vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	typeOfInspection: {
		title: 'rhenus_case_zone_overview_screen_type_of_inspection',
		width: '11.01vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	storage: {
		title: 'rhenus_case_zone_overview_screen_storage',
		width: '17.957vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	approved: {
		title: 'rhenus_case_zone_overview_screen_approved',
		width: '15.87vw',
		type: TableCellType.Approve,
		titleType: TableCellType.TitleWithDropdown,
	},
	photo: {
		title: 'rhenus_case_zone_overview_screen_photo_for_error',
		width: '12.87vw',
		type: TableCellType.Button,
		titleType: TableCellType.Title,
	},
	date: {
		title: 'rhenus_case_zone_overview_screen_date',
		width: '10.832vw',
		type: TableCellType.Text,
		titleType: TableCellType.Title,
	},
	shift: {
		title: 'rhenus_case_zone_overview_screen_shift',
		width: '14.832vw',
		type: TableCellType.Text,
		titleType: TableCellType.TitleWithDropdown,
	},
	comment: {
		title: 'rhenus_case_zone_overview_screen_comment',
		width: '29.87vw',
		type: TableCellType.Expandable,
		titleType: TableCellType.Title,
	},
};
