import React from 'react';

import { AuthActionsContext, AuthStateContext } from './AuthContext';

export function useAuthStateContext() {
	return React.useContext(AuthStateContext);
}

export function useAuthActionContext() {
	return React.useContext(AuthActionsContext);
}
