import * as React from 'react';
import { SVGProps } from 'react';

const CalendarArrowIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={9} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd" d="m8.487 6.5-8 6V.5l8 6Z" fill="#1A1A1A" />
	</svg>
);

export default CalendarArrowIcon;
