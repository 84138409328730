import React, { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import GoBack from '../../svgComponents/GoBack';
import HomeButton from '../../svgComponents/HomeButton';
import MuiAutocomplete from '../../components/MuiAutocomplete';
import StandardTextField from '../../components/StandardTextField';
import StandardDialog, { StandardDialogInterface } from '../../components/StandardDialog';

import { rules } from '../../consts/rules';
import { fonts } from '../../consts/fonts';
import User from '../../service/types/User';
import service from '../../service/service';
import { colors } from '../../consts/colors';
import { Routes } from '../../router/Routes';
import { groupAtom } from '../../atoms/group';
import { Group } from '../groupOverview/GroupOverview';
import { teamLeadersAtom } from '../../atoms/teamLeaders';
import { useGlobalStyles } from '../../consts/globalStyles';
import { useNotificationActionContext } from '../../context/notification/useNotification';

export interface CreateGroup {
	name: string;
	teamLeadsIds: number[];
}

type FormValues = {
	groupName: string;
	groupMembers: User[];
};

const formFields = {
	groupName: 'groupName',
	groupMembers: 'groupMembers',
};

export default function GroupUpdatePage() {
	const history = useHistory();
	const { id }: { id: string } = useParams();
	const { state }: { state?: Group } = useLocation();

	const classes = useStyles();
	const { t } = useTranslation();
	const globalClasses = useGlobalStyles({ description: colors.lightGray });

	const mounted = useRef(false);
	const { setNotification } = useNotificationActionContext();
	const deleteDialogRef = React.useRef<StandardDialogInterface | null>(null);

	const [editMode, setEditMode] = useState(false);
	const [, setGroups] = useAtom(groupAtom);
	const [loading, setLoading] = useState(false);
	const [teamLeaders, setTeamLeaders] = useAtom(teamLeadersAtom);
	const groupRef = useRef<{ groupName: string; groupMembers: any } | undefined>({
		groupName: state?.name ?? '',
		groupMembers: [],
	});

	const { control, handleSubmit, errors, formState, setValue, getValues } = useForm<FormValues>({
		mode: 'onSubmit',
		defaultValues: {
			groupName: state?.name ?? '',
			groupMembers: [],
		},
		shouldUnregister: false,
	});

	const resetForm = () => {
		setValue(formFields.groupName, groupRef.current?.groupName);
		setValue(formFields.groupMembers, groupRef.current?.groupMembers);
	};

	const onEdit = () => setEditMode(true);

	/* const navigateOnSuccess = () => {
		if (history.location.key) {
			history.goBack();
		} else {
			history.push(Routes.GroupOverview);
		}
	}; */

	const onSubmit = async (data: FormValues) => {
		const teamLeadsIds = data.groupMembers.map((el) => el.id);
		const preparedData = { groupId: parseInt(id), name: data.groupName, teamLeadsIds };

		try {
			setLoading(true);
			const updateGroup = await service.updateGroup(preparedData);

			if (updateGroup) {
				const readGroups = await service.readGroups();
				setGroups(readGroups);
				setNotification({
					text: 'group_update_updated_notification',
					additional: data.groupName,
				});

				// This will force new fetch on users screen
				localStorage.removeItem('ALL_USERS');
			}
		} finally {
			setLoading(false);
			setEditMode(false);
		}
	};

	const openDeleteDialog = () => deleteDialogRef.current?.setDialogState(true);
	const closeDeleteDialog = () => deleteDialogRef.current?.setDialogState(false);

	const onDelete = async () => {
		try {
			const deleteSuccess = await service.deleteGroup(parseInt(id));
			if (deleteSuccess) {
				setGroups((prev) => prev.filter((group) => group.groupId !== parseInt(id)));
				history.goBack();
				setNotification({
					text: 'group_update_delete_notification',
					additional: getValues(formFields.groupName),
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const onCancel = () => {
		closeDeleteDialog();
		resetForm();
		setEditMode(false);
	};

	useEffect(() => {
		(async () => {
			try {
				if (!mounted.current && teamLeaders.length <= 0) {
					setLoading(true);
					const getTeamLeaders = await service.getAllTeamLeaders();
					setTeamLeaders(getTeamLeaders.filter((el) => el.deleted === false));
					mounted.current = true;
				} else {
					mounted.current = true;
				}
			} finally {
				setLoading(false);
			}
		})();
	}, [setTeamLeaders, teamLeaders.length]);

	useEffect(() => {
		(async () => {
			try {
				if (!state?.name && mounted.current) {
					setLoading(true);
					const readGroup = await service.readGroup(parseInt(id));

					if (readGroup) {
						setValue(formFields.groupName, readGroup.name);

						const users = readGroup.users.map((user) =>
							teamLeaders.find((leader) => leader.id === user.id)
						);
						if (users.length > 0) {
							setValue(formFields.groupMembers, users);
							groupRef.current = { groupName: readGroup.name, groupMembers: users as any };
						}
					}
				}
			} finally {
				setLoading(false);
			}
		})();
	}, [id, setValue, state?.name, teamLeaders]);

	useEffect(() => {
		if (state?.users) {
			const users = state.users.map((user) => teamLeaders.find((leader) => leader.id === user.id));
			if (users.length > 0) {
				setValue(formFields.groupMembers, users);
				groupRef.current = { groupName: groupRef.current?.groupName ?? '', groupMembers: users };
			}
		}
	}, [setValue, state?.users, teamLeaders]);

	if (loading) return <Spinner />;

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<GoBack edit={formState.isDirty && editMode} route={Routes.GroupOverview} />
				<HomeButton edit={formState.isDirty && editMode} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>{t('group_update_title')}</span>
			</div>

			<div className={classes.subtitleWrapper}>
				<span className={globalClasses.subtitle}>{t('group_update_subtitle')}</span>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container style={{ marginBottom: '32px' }}>
					<Grid item sm={5}>
						<Controller
							defaultValue=""
							control={control}
							name={formFields.groupName}
							rules={{
								required: rules(t).required,
								minLength: rules(t).minLength(3),
							}}
							render={({ onChange, value }) => (
								<StandardTextField
									disabled={!editMode}
									label={t('group_update_name').toUpperCase()}
									onChange={onChange}
									value={value}
									error={Boolean(errors.groupName)}
									helperText={Boolean(errors.groupName) ? errors.groupName?.message : ''}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={5}>
						<Controller
							defaultValue={[]}
							control={control}
							name={formFields.groupMembers}
							rules={{
								validate: (val) => {
									if (val === undefined) return rules(t).required.message;
									return val?.length === 0 ? rules(t).required.message : true;
								},
							}}
							render={({ onChange, value }) => (
								<MuiAutocomplete
									disabled={!editMode}
									label={t('group_update_member').toUpperCase()}
									onChange={onChange}
									options={teamLeaders}
									value={value}
									disableCloseOnSelect
									placeholder=""
									error={Boolean(errors.groupMembers)}
									helperText={Boolean(errors.groupMembers) ? t('field_required') : ''}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<div className={classes.rowWrapper}>
					{editMode ? (
						<input
							className={`${globalClasses.buttonText} ${globalClasses.buttonWrapper} ${classes.noMargin}`}
							type="submit"
							value={`${t('group_update_update_button')}`}
						/>
					) : (
						<Button
							text={t('group_update_edit_button')}
							textClassName={globalClasses.buttonText}
							buttonWrapperClassName={`${globalClasses.buttonWrapper} ${classes.noMargin}`}
							onClick={onEdit}
							otherProps={{
								type: 'button',
							}}
						/>
					)}

					{editMode ? (
						<Button
							text={t('group_update_cancel_button')}
							textClassName={globalClasses.buttonTextInverted}
							buttonWrapperClassName={globalClasses.buttonWrapperInverted}
							onClick={openDeleteDialog}
							otherProps={{
								type: 'button',
							}}
						/>
					) : (
						<Button
							text={t('group_update_delete_button')}
							textClassName={globalClasses.deleteLink}
							buttonWrapperClassName={globalClasses.buttonWrapperPlain}
							onClick={openDeleteDialog}
							otherProps={{
								type: 'button',
							}}
						/>
					)}
				</div>
			</form>
			<StandardDialog
				ref={deleteDialogRef}
				showWarning
				title={editMode ? 'group_update_cancel_title' : 'group_update_dialog_title'}
				description={editMode ? 'group_update_cancel_description' : 'group_update_dialog_description'}
				acceptText={editMode ? 'group_update_confirm_cancel_button' : 'group_update_confirm_delete_button'}
				cancleText="group_update_cancel_delete_button"
				onCancle={closeDeleteDialog}
				onAccept={editMode ? onCancel : onDelete}
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		marginBottom: '24px',
	},
	subtitleWrapper: {
		margin: '28px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	rowWrapper: {
		display: 'flex',
		margin: '48px 0px',
		alignItems: 'center',
	},
	noMargin: {
		margin: 'unset',
	},
	addMember: {
		fontSize: '14px',
		lineHeight: '20px',
		fontFamily: fonts.regular,
		color: colors.darkGray,
		textDecoration: 'underline',
		border: 'none',
		cursor: 'pointer',
		marginTop: '10px',
		marginLeft: 'auto',
		display: 'block',
		backgroundColor: 'transparent',
	},
}));
