import React from 'react';
import User from '../../service/types/User';
import { AuthContextActions } from './AuthContext';

import { AuthState, Action, SET_USER } from './authReducer';

export const createStateAuthContext = (state: AuthState): AuthState => ({
	...state,
});

export const createActionAuthContext = (dispatch: React.Dispatch<Action>): AuthContextActions => ({
	setUser: (user: User | undefined) => dispatch({ type: SET_USER, user }),
});
