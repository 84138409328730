import * as React from 'react';

function SaveIcon(props: any) {
	return (
		<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.667 2h1.997v3.333H8.667V2ZM16 2.667V16H0V0h13.333L16 2.667ZM4.667 6h6.666V1.333H4.667V6Zm10-2.78L12.78 1.332h-.114v6H3.333v-6h-2v13.334h13.334V3.219Z"
				fill="#000"
			/>
		</svg>
	);
}

export default SaveIcon;
