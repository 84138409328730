import { Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddWorkingTime from '../components/AddWorkingTime';
import Button from '../components/Button';
import Filter from '../components/Filter';
import IntervalDatePicker, { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import KeyValueAutoComplete from '../components/KeyValueAutoComplete';
import RecordListWorkingTime from '../components/RecordListWorkingTime';
import StandardCheckbox from '../components/StandardCheckbox';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import StandardTextField from '../components/StandardTextField';
import TrackWorkingTime from '../components/TrackWorkingTime';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import { useAuthStateContext } from '../context/auth/useAuth';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import {
	exportWorkingTimeFields,
	filterRecordTimeTypeKeyValuePairs,
	filterWorkingTimeKeyValuePairs,
	WorkingTimeFilterInterface,
	WorkingTimeFilterKeys,
} from '../helpers/workingTimeHelper/generalWorkingTimeHelper';
import BreakTime from '../service/types/BreakTime';
import { UserRole } from '../service/types/User';
import WorkingTime from '../service/types/WorkingTime';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import WorkingTimeIcon from '../svgComponents/WorkingTimeIcon';

interface Props {
	intervalDates: IntervalDatePickerDates;
	manualWorkTime: string;
	fromTime: any;
	contractID: number;
	contractNumber: string;
	filters: WorkingTimeFilterInterface;
	allUsers: KeyValue[];
	records: (WorkingTime | BreakTime)[];
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	onKeyboardDateChange: (date: MaterialUiPickersDate, key: IntervalKey) => void;
	onDateChange: (date: MaterialUiPickersDate) => void;
	onDatesAccept: () => void;
	onDatesCancel: () => void;
	clearDates: () => void;
	clearAllFilters: () => void;
	onAddManualWorkTime: () => void;
	onFilterChange: (key: WorkingTimeFilterKeys, value: any) => void;
	onSaveRecordPress: (record: WorkingTime | BreakTime) => Promise<boolean | void>;
	onDeletePress: (record: WorkingTime | BreakTime) => void;
	onCancelDialogPress: () => void;
	onDeleteRecordDialogPress: () => void;
	onStopButtonPressWhenCIDAndRID: (record: WorkingTime) => void;
	downloadTable: () => void;
	changeExportFields: (value: boolean, name: string) => void;
	exportWorkingTimeFields: any;
}
function WorkingTimeScreen(props: Props) {
	const [showFilters, setShowFilters] = React.useState(false);
	const classes = useStyles({});
	const globalClasses = useGlobalStyles({ showFilters: showFilters });
	const { t } = useTranslation();
	const filterListRef = React.useRef<any>(null);
	const { user } = useAuthStateContext();
	const { errors, control, watch } = useFormContext();

	const onFilterPress = () => setShowFilters((prev) => !prev);

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow />
				<HomeButton
					contractNumber={props.contractNumber}
					contractID={props.contractID}
					workingTimeScreen={true}
				/>
			</div>
			<div className={classes.row}>
				<div className={classes.iconWrapper}>
					<WorkingTimeIcon className={classes.icon} />
				</div>
				<div>
					<span className={globalClasses.title}>{t('working_time_screen_title')}</span>
				</div>
			</div>
			<div className={classes.trackWrapper}>
				<TrackWorkingTime
					contractID={props.contractID}
					contractNumber={props.contractNumber}
					onStopButtonPressWhenCIDAndRID={props.onStopButtonPressWhenCIDAndRID}
				/>
				<AddWorkingTime
					qais={props.allUsers.filter((item) => !item.value.deleted)}
					control={control}
					errors={errors}
					fromTime={props.fromTime}
					watch={watch}
					onAddManualWorkTime={props.onAddManualWorkTime}
					manualWorkTime={props.manualWorkTime}
				/>
			</div>
			<div className={classes.recordWrapper}>
				{user?.role !== UserRole.QualityAssuranceInspector ? (
					<div>
						<Filter onFilterPress={onFilterPress} onClearAllPress={props.clearAllFilters} />

						<div className={classes.rowCenter}>
							<Typography className={globalClasses.buttonText}>
								{t('working_time_screen_export_add_to')}
							</Typography>
							<div className={classes.checkBoxWrapper}>
								<StandardCheckbox
									onChange={() => {}}
									value={props.exportWorkingTimeFields.ExportMaterials}
									disabled={false}
									name={t('working_time_screen_export_materials')}
									customOnChange={props.changeExportFields}
									customName={exportWorkingTimeFields.ExportMaterials}
								/>
								<StandardCheckbox
									onChange={() => {}}
									value={props.exportWorkingTimeFields.ExportTools}
									disabled={false}
									name={t('working_time_screen_export_tools')}
									customOnChange={props.changeExportFields}
									customName={exportWorkingTimeFields.ExportTools}
								/>
								<StandardCheckbox
									onChange={() => {}}
									value={props.exportWorkingTimeFields.ExportTravels}
									disabled={false}
									name={t('working_time_screen_export_travels')}
									customOnChange={props.changeExportFields}
									customName={exportWorkingTimeFields.ExportTravels}
								/>
								<StandardCheckbox
									onChange={() => {}}
									value={props.exportWorkingTimeFields.ExportTransport}
									disabled={false}
									name={t('working_time_screen_export_transport')}
									customOnChange={props.changeExportFields}
									customName={exportWorkingTimeFields.ExportTransport}
								/>
							</div>

							<Button
								renderAsButton
								otherProps={{ disabled: errors['fromDate'] || errors['toDate'] }}
								text={t('working_time_screen_export_table')}
								textClassName={globalClasses.buttonText}
								buttonWrapperClassName={classes.buttonWrapper}
								onClick={props.downloadTable}
							/>

							<StandardCheckbox
								onChange={() => {}}
								value={props.exportWorkingTimeFields.Anonymous}
								disabled={false}
								name={t('working_time_screen_export_anonymous')}
								customOnChange={props.changeExportFields}
								customName={exportWorkingTimeFields.Anonymous}
							/>
						</div>
						<div
							className={globalClasses.filterWrapper}
							style={{ maxHeight: showFilters ? filterListRef.current.offsetHeight - 1 : '0px' }}>
							<Grid container spacing={4} className={globalClasses.filterContainer} ref={filterListRef}>
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<KeyValueAutoComplete
										label={t('working_time_screen_reporter')}
										onChange={(e) => {
											if (e) {
												props.onFilterChange('reporter', e.value.id.toString());
											} else {
												props.onFilterChange('reporter', '');
											}
										}}
										options={props.allUsers.filter((item) => !item.value.deleted)}
										multiple={false}
										value={{
											key: props.allUsers.find(
												(qai) => qai.value.id.toString() === props.filters.reporter
											)?.key
												? props.allUsers.find(
														(qai) => qai.value.id.toString() === props.filters.reporter
												  )!.key
												: '',
											value: props.filters.reporter,
										}}
									/>
								</Grid>
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<KeyValueAutoComplete
										label={t('working_time_screen_employee')}
										onChange={(e) => {
											if (e) {
												props.onFilterChange('qai', e.value.id.toString());
											} else {
												props.onFilterChange('qai', '');
											}
										}}
										options={props.allUsers.filter((item) => !item.value.deleted)}
										multiple={false}
										value={{
											key: props.allUsers.find(
												(qai) => qai.value.id.toString() === props.filters.qai
											)?.key
												? props.allUsers.find(
														(qai) => qai.value.id.toString() === props.filters.qai
												  )!.key
												: '',
											value: props.filters.qai,
										}}
									/>
								</Grid>
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<StandardTextField
										label={t('working_time_screen_billing_status')}
										select
										value={props.filters.billed}
										onChange={(e) => props.onFilterChange('billed', e.target.value)}>
										{filterWorkingTimeKeyValuePairs.map((item) => (
											<MenuItem key={item.key} value={item.value}>
												<div className={classes.inputWrapper}>
													<span className={classes.input}>{t(item.key)}</span>
												</div>
											</MenuItem>
										))}
									</StandardTextField>
								</Grid>
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<IntervalDatePicker
										id="start"
										error={errors['fromDate']}
										helperText={errors['fromDate'] ? errors['fromDate'].message : ''}
										value={props.filters.fromDate}
										label={t('working_time_screen_from_date')}
										dates={props.intervalDates}
										clearable={true}
										onKeyboardDateChange={props.onKeyboardDateChange}
										onDateChange={props.onDateChange}
										onDatesAccept={props.onDatesAccept}
										onDatesCancel={props.onDatesCancel}
										clearDates={props.clearDates}
									/>
								</Grid>
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<IntervalDatePicker
										id="end"
										error={errors['toDate']}
										helperText={errors['toDate'] ? errors['toDate'].message : ''}
										value={props.filters.toDate}
										label={t('working_time_screen_to_date')}
										dates={props.intervalDates}
										clearable={true}
										onKeyboardDateChange={props.onKeyboardDateChange}
										onDateChange={props.onDateChange}
										onDatesAccept={props.onDatesAccept}
										onDatesCancel={props.onDatesCancel}
										clearDates={props.clearDates}
									/>
								</Grid>
								<Grid item sm={3} className={globalClasses.filterFieldWrapper}>
									<StandardTextField
										label={t('Type')}
										select
										value={props.filters.type}
										onChange={(e) => props.onFilterChange('type', e.target.value)}>
										{filterRecordTimeTypeKeyValuePairs.map((item) => (
											<MenuItem key={item.key} value={item.value}>
												<div className={classes.inputWrapper}>
													<span className={classes.input}>{t(item.key)}</span>
												</div>
											</MenuItem>
										))}
									</StandardTextField>
								</Grid>
							</Grid>
						</div>
					</div>
				) : null}
				{props.records.length !== 0 ? (
					<RecordListWorkingTime
						records={props.records}
						users={props.allUsers}
						onSaveRecordPress={props.onSaveRecordPress}
						onDeletePress={props.onDeletePress}
					/>
				) : null}
			</div>
			<StandardDialog
				acceptText={'working_time_screen_dialog_accept_button'}
				description={'working_time_screen_dialog_description'}
				title={'working_time_screen_dialog_title'}
				cancleText={'working_time_screen_dialog_cancel_button'}
				onCancle={props.onCancelDialogPress}
				onAccept={props.onDeleteRecordDialogPress}
				ref={props.dialogRef}
				showWarning
			/>
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	buttonWrapper: {
		width: 'fit-content',
		backgroundColor: colors.yellow,
		borderRadius: 4,
		border: `1px solid ${colors.yellow}`,
		padding: '12px 24px',
		cursor: 'pointer',
		// height: '100%',
		// marginTop: '15px',
	},
	row: {
		display: 'flex',
	},
	recordWrapper: {
		marginTop: '12px',
		padding: '20px 16px',
		backgroundColor: colors.keyButton,
	},
	trackWrapper: {
		marginTop: '20px',
		padding: '16px',
		backgroundColor: colors.lighterYellow,
	},
	iconWrapper: {
		height: '30px',
		width: '30px',
		marginRight: '18px',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	icon: {
		height: '32px',
		width: '32px',
	},
	input: (props: StyleProps) => ({
		...globalInputStyle(props.disabled),
		whiteSpace: 'pre',
	}),
	rowCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		gap: '24px',
	},
	checkBoxWrapper: {
		display: 'flex',
		flexDirection: 'row',
		border: '1px solid #555252',
		padding: '12px 24px',
		gap: '24px',
	},
	inputWrapper: {
		minHeight: '20px',
		transform: 'translateY(-2px)',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}));

export default WorkingTimeScreen;
