import React from 'react';
import Config from '../config';

import { AUTH_TOKEN } from '../context/auth/authReducer';
import { useAuthActionContext } from '../context/auth/useAuth';
import { useErrorActionContext } from '../context/error/useError';
import { useNotificationActionContext } from '../context/notification/useNotification';
import { useWorkingTimeActionContext } from '../context/workingTime/useWorkingTime';
import service, { createServiceWithConfigAndToken } from '../service/service';
import User, { UserRole } from '../service/types/User';
import WorkTimeTrack from '../service/types/WorkTimeTrack';
import { userAtom } from '../atoms/userAtom';
import { useAtom } from 'jotai';

interface Props {
	children: JSX.Element;
}

function LoadingWrapper(props: Props) {
	const [isTokenChecked, setIsTokenChecked] = React.useState(false);
	const { setUser } = useAuthActionContext();
	const { setError } = useErrorActionContext();
	const { setNotification } = useNotificationActionContext();
	const { setRecord } = useWorkingTimeActionContext();
	const [, setUserAtom] = useAtom(userAtom);

	React.useEffect(() => {
		const token = localStorage.getItem(AUTH_TOKEN);
		const adapter = createServiceWithConfigAndToken(
			Config.getConfig(),
			token ? token : '',
			setUser,
			setError,
			setNotification
		);
		service.setService(adapter);

		if (token) {
			setUser(User.fromJWT(token));
			service
				.checkToken()
				.then(async () => {
					const user = await service.getUserInfo().catch(() => {});

					if (user) {
						setUser(user);
						if (user.role !== UserRole.Customer) {
							const record = await service.checkTrackingTime().catch(() => {});
							setRecord(record as undefined | WorkTimeTrack);
						}
						setUserAtom(user.firstName);
					}

					setIsTokenChecked(true);
				})
				.catch(() => {
					setIsTokenChecked(true);
				});
		} else {
			setIsTokenChecked(true);
		}
	}, [setUser, setError, setNotification, setRecord, setUserAtom]);

	if (!isTokenChecked) return <div></div>;

	return props.children;
}

export default LoadingWrapper;
