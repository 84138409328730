import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../consts/globalStyles';
import { Control, Controller } from 'react-hook-form';
import BackArrow from '../svgComponents/BackArrow';
import HomeButton from '../svgComponents/HomeButton';
import StandardTextField from '../components/StandardTextField';
import StandardDatePicker from '../components/StandardDatePicker';
import { invoiceInputFields } from '../containers/InvoiceContainer';
import { InvoiceItem } from '../service/types/Invoice';
import { rules } from '../consts/rules';
import StandardCRUDButtons from '../components/StandardCRUDButtons';
import StandardDialog, { StandardDialogInterface } from '../components/StandardDialog';
import Button from '../components/Button';
import SaveIcon from '../svgComponents/SaveIcon';

interface Props {
	invoice: InvoiceItem | undefined;
	isUserEditing: boolean;
	control: Control<Record<string, any>>;
	errors: Record<string, any>;
	dialogRef: React.MutableRefObject<StandardDialogInterface | null>;
	onSubmitPress: () => void;
	onCancelPress: () => void;
	onDeletePress: () => void;
	onCancelDialogPress: () => void;
	onDeleteInvoiceDialogPress: () => void;
	closeBilling: () => void;
	downloadAsPdf: () => void;
}

function InvoiceScreen(props: Props) {
	const globalClasses = useGlobalStyles();
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<div className={globalClasses.container}>
			<div className={globalClasses.homeButtonWrapper}>
				<BackArrow edit={props.isUserEditing} />
				<HomeButton edit={props.isUserEditing} />
			</div>
			<div className={classes.titleWrapper}>
				<span className={globalClasses.title}>
					{props.invoice ? props.invoice?.id : t('rhenus_case_screen_new_error_input')}
				</span>
				<div className={classes.downloadWrapper}>
					<SaveIcon />
					<Button
						onClick={props.downloadAsPdf}
						buttonWrapperClassName={`${classes.downloadBtn} ${globalClasses.linkWrapper}`}
						text={t('download_as_pdf')}
						textClassName={globalClasses.link}
					/>
				</div>
			</div>
			<Grid container>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.invoice ? props.invoice.creation_date : null}
						control={props.control}
						name={invoiceInputFields.creation_date}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('invoice_screen_creation_date').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing || (props.invoice && props.invoice.completed)}
								value={value}
								error={props.errors[invoiceInputFields.creation_date] ? true : false}
								helperText={
									props.errors[invoiceInputFields.creation_date]
										? props.errors[invoiceInputFields.creation_date].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.invoice && props.invoice.bill_number ? props.invoice.bill_number : '0'}
						control={props.control}
						name={invoiceInputFields.bill_number}
						rules={{ required: rules(t).required, pattern: rules(t).numberPattern }}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('invoice_screen_bill_number').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={!props.isUserEditing || (props.invoice && props.invoice.completed)}
								error={props.errors[invoiceInputFields.bill_number] ? true : false}
								helperText={
									props.errors[invoiceInputFields.bill_number]
										? props.errors[invoiceInputFields.bill_number].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1} />
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.invoice ? props.invoice.date_of_invoice : null}
						control={props.control}
						name={invoiceInputFields.date_of_invoice}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('date_of_invoice').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing || (props.invoice && props.invoice.completed)}
								value={value}
								error={props.errors[invoiceInputFields.date_of_invoice] ? true : false}
								helperText={
									props.errors[invoiceInputFields.date_of_invoice]
										? props.errors[invoiceInputFields.date_of_invoice].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.invoice ? props.invoice.customer : t('not_available')}
						control={props.control}
						name={invoiceInputFields.customer}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('customer').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.customer] ? true : false}
								helperText={
									props.errors[invoiceInputFields.customer]
										? props.errors[invoiceInputFields.customer].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.invoice ? props.invoice.from_date : null}
						control={props.control}
						name={invoiceInputFields.from_date}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('dashboard_screen_from_date').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing || (props.invoice && props.invoice.completed)}
								value={value}
								error={props.errors[invoiceInputFields.from_date] ? true : false}
								helperText={
									props.errors[invoiceInputFields.from_date]
										? props.errors[invoiceInputFields.from_date].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={props.invoice ? props.invoice.to_date : null}
						control={props.control}
						name={invoiceInputFields.to_date}
						rules={{ required: rules(t).required }}
						render={({ onChange, value }) => (
							<StandardDatePicker
								label={t('dashboard_screen_to_date').toUpperCase()}
								onChange={onChange}
								disabled={!props.isUserEditing || (props.invoice && props.invoice.completed)}
								value={value}
								error={props.errors[invoiceInputFields.to_date] ? true : false}
								helperText={
									props.errors[invoiceInputFields.to_date]
										? props.errors[invoiceInputFields.to_date].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
			</Grid>
			<div className={`${globalClasses.underline} ${classes.invoiceUnderline}`} />
			<Grid container>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.number_of_shifts !== null
								? props.invoice.number_of_shifts
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.number_of_shifts}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_number_of_shifts').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.number_of_shifts] ? true : false}
								helperText={
									props.errors[invoiceInputFields.number_of_shifts]
										? props.errors[invoiceInputFields.number_of_shifts].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.number_of_workers !== null
								? props.invoice.number_of_workers
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.number_of_workers}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_number_of_workers').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.number_of_workers] ? true : false}
								helperText={
									props.errors[invoiceInputFields.number_of_workers]
										? props.errors[invoiceInputFields.number_of_workers].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.inspector_hours !== null
								? props.invoice.inspector_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.inspector_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_qa').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.inspector_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.inspector_hours]
										? props.errors[invoiceInputFields.inspector_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.inspector_late_hours !== null
								? props.invoice.inspector_late_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.inspector_late_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_qa_8_10').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.inspector_late_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.inspector_late_hours]
										? props.errors[invoiceInputFields.inspector_late_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.inspector_night_hours !== null
								? props.invoice.inspector_night_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.inspector_night_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_qa_night_shift').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.inspector_night_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.inspector_night_hours]
										? props.errors[invoiceInputFields.inspector_night_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.inspector_sunday_night_hours !== null
								? props.invoice.inspector_sunday_night_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.inspector_sunday_night_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_qa_10_6_sunday').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.inspector_sunday_night_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.inspector_sunday_night_hours]
										? props.errors[invoiceInputFields.inspector_sunday_night_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.inspector_week_overtime_hours !== null
								? props.invoice.inspector_week_overtime_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.inspector_week_overtime_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_qa_8_weekday').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.inspector_week_overtime_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.inspector_week_overtime_hours]
										? props.errors[invoiceInputFields.inspector_week_overtime_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.teamlead_hours !== null
								? props.invoice.teamlead_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.teamlead_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_teamleader').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.teamlead_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.teamlead_hours]
										? props.errors[invoiceInputFields.teamlead_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.teamlead_late_hours !== null
								? props.invoice.teamlead_late_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.teamlead_late_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_teamleader_8_10').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.teamlead_late_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.teamlead_late_hours]
										? props.errors[invoiceInputFields.teamlead_late_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.teamlead_night_hours !== null
								? props.invoice.teamlead_night_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.teamlead_night_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_teamleader_night_shift').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.teamlead_night_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.teamlead_night_hours]
										? props.errors[invoiceInputFields.teamlead_night_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.teamlead_sunday_night_hours !== null
								? props.invoice.teamlead_sunday_night_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.teamlead_sunday_night_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_teamleader_10_6_sunday').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.teamlead_sunday_night_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.teamlead_sunday_night_hours]
										? props.errors[invoiceInputFields.teamlead_sunday_night_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.teamlead_week_overtime_hours !== null
								? props.invoice.teamlead_week_overtime_hours
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.teamlead_week_overtime_hours}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('total_working_hours_teamleader_8_weekday').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.teamlead_week_overtime_hours] ? true : false}
								helperText={
									props.errors[invoiceInputFields.teamlead_week_overtime_hours]
										? props.errors[invoiceInputFields.teamlead_week_overtime_hours].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<div className={`${globalClasses.underline} ${classes.invoiceUnderline}`} />
			<Grid container>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.gloves !== null ? props.invoice.gloves : t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.gloves}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('gloves').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.gloves] ? true : false}
								helperText={
									props.errors[invoiceInputFields.gloves]
										? props.errors[invoiceInputFields.gloves].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.travel_time !== null
								? props.invoice.travel_time
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.travel_time}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('travel_time').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.travel_time] ? true : false}
								helperText={
									props.errors[invoiceInputFields.travel_time]
										? props.errors[invoiceInputFields.travel_time].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.kilometers !== null
								? props.invoice.kilometers
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.kilometers}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('kilometers_to_customer').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.kilometers] ? true : false}
								helperText={
									props.errors[invoiceInputFields.kilometers]
										? props.errors[invoiceInputFields.kilometers].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.travel_fee !== null
								? props.invoice.travel_fee
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.travel_fee}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('fee_for_getting_to_customer').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.travel_fee] ? true : false}
								helperText={
									props.errors[invoiceInputFields.travel_fee]
										? props.errors[invoiceInputFields.travel_fee].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.administration_fee !== null
								? props.invoice.administration_fee
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.administration_fee}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('administration_fee').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.administration_fee] ? true : false}
								helperText={
									props.errors[invoiceInputFields.administration_fee]
										? props.errors[invoiceInputFields.administration_fee].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
				<Grid item sm={5} className={classes.fieldWrapper}>
					<Controller
						defaultValue={
							props.invoice && props.invoice.application_intake !== null
								? props.invoice.application_intake
								: t('not_available')
						}
						control={props.control}
						name={invoiceInputFields.application_intake}
						render={({ onChange, value }) => (
							<StandardTextField
								label={t('application_intake').toUpperCase()}
								onChange={onChange}
								value={value}
								disabled={true}
								error={props.errors[invoiceInputFields.application_intake] ? true : false}
								helperText={
									props.errors[invoiceInputFields.application_intake]
										? props.errors[invoiceInputFields.application_intake].message
										: ''
								}
							/>
						)}
					/>
				</Grid>
				<Grid item sm={1}></Grid>
			</Grid>
			<div className={`${globalClasses.underline} ${classes.invoiceUnderline}`} />
			<div className={classes.rowWrapper}>
				<StandardCRUDButtons
					cancelButtonText={'invoice_screen_cancel'}
					updateButtonText={'invoice_screen_update'}
					createButtonText={'invoice_screen_create'}
					deleteButtonText={'invoice_screen_delete'}
					editButtonText={'invoice_screen_edit'}
					removeEditUpdateCreateBtn={props.invoice && props.invoice.completed}
					childrenElement={
						props.invoice && props.invoice.completed ? undefined : (
							<Button
								text={t('invoice_screen_close_billing')}
								renderAsButton
								onClick={props.closeBilling}
								buttonWrapperClassName={globalClasses.buttonWrapperBlack}
								textClassName={globalClasses.buttonTextYellow}
							/>
						)
					}
					canDelete
					data={props.invoice}
					isUserEditing={props.isUserEditing}
					onCancelPress={props.onCancelPress}
					onDeletePress={props.onDeletePress}
					onSubmitPress={props.onSubmitPress}
				/>
			</div>
			<StandardDialog
				acceptText={'invoice_screen_dialog_accept_button'}
				description={'invoice_screen_dialog_description'}
				title={'invoice_screen_dialog_title'}
				cancleText={'invoice_screen_dialog_cancel_button'}
				onCancle={props.onCancelDialogPress}
				onAccept={props.onDeleteInvoiceDialogPress}
				ref={props.dialogRef}
				showWarning
			/>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '24px',
	},

	fieldWrapper: {
		marginBottom: '32px',
	},
	invoiceUnderline: {
		marginBottom: '32px',
	},
	rowWrapper: {
		display: 'flex',
		marginTop: '48px',
		alignItems: 'center',
		marginBottom: '32px',
	},
	downloadWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	downloadBtn: {
		marginLeft: '8px',
	},
}));

export default InvoiceScreen;
