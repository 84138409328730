import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from 'moment';
import React from 'react';

import { de, enGB } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { colors } from '../consts/colors';
import { globalInputStyle, useGlobalStyles } from '../consts/globalStyles';
import i18n from '../i18n';
import ErrorIcon from '../svgComponents/ErrorIcon';

export const TimePickerWithStyles = withStyles({
	root: {
		minWidth: 100,
		'& .Mui-disabled': {
			color: colors.gray,
		},
		'& label.Mui-focused:not(.Mui-error)': {
			color: colors.lightGray,
		},
		'& .MuiInput-input': {
			cursor: 'text',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'& .MuiInput-underline': {
			borderBottomStyle: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'white',
			borderRadius: '5px',
		},
		'& .Mui-error': {
			color: colors.error,
		},
		'& .MuiFormHelperText-root': {
			textWrap: 'wrap',
		},
	},
})(KeyboardTimePicker);

interface Props {
	label: string;
	onChange: (event: any) => void;
	error?: boolean;
	helperText?: string;
	value: string | Moment;
	placeholder?: string;
	inputWrapper?: string;
	input?: string;
	disabled?: boolean;
	step?: number;
	openTo?: 'hours' | 'minutes' | 'seconds';
}

function StandardTimePicker(props: Props) {
	const classes = useStyles({
		disabled: props.disabled,
	});
	const globalClasses = useGlobalStyles({ disabled: props.disabled });
	const { t } = useTranslation();

	const currentLanguage = i18n.language;

	return (
		<div>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLanguage === 'de' ? de : enGB}>
				<TimePickerWithStyles
					value={props.value ? props.value : null}
					cancelLabel={t('time_picker_cancel')}
					okLabel={t('time_picker_ok')}
					ampm={false}
					openTo={props.openTo}
					minutesStep={props.step || 5}
					placeholder={props.placeholder}
					disabled={props.disabled}
					className={props.inputWrapper ? props.inputWrapper : classes.inputWrapper}
					onChange={(val) => {
						props.onChange(val);
					}}
					InputProps={{
						classes: {
							input: props.input ? props.input : classes.input,
							underline: classes.underlineField,
						},
					}}
				/>
			</MuiPickersUtilsProvider>

			{props.error ? (
				<div className={classes.row}>
					<div className={classes.errorImageWrapper}>
						<ErrorIcon />
					</div>
					<span className={globalClasses.error} style={{ maxWidth: 100 }}>
						{props.helperText}
					</span>
				</div>
			) : null}
		</div>
	);
}

interface StyleProps {
	disabled?: boolean;
}

const useStyles = makeStyles(() => ({
	labelWrapper: {
		marginBottom: '4px',
		display: 'flex',
		whiteSpace: 'pre',
	},
	underlineField: {
		'&:before': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
		'&:after': {
			borderBottom: `0px solid ${colors.lightGray}`,
		},
	},
	inputWrapper: {
		width: '100%',
		borderRadius: '4px',
		padding: '0',
		backgroundColor: colors.white,
	},
	input: (p: StyleProps) => ({
		...globalInputStyle(p.disabled),
		whiteSpace: 'pre',
		cursor: p.disabled ? 'default' : 'pointer',
		color: colors.darkGray,
		borderRadius: '5px',
	}),
	row: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '3px',
	},
	errorImageWrapper: { marginRight: '5px', height: '16px' },
}));

export default StandardTimePicker;
