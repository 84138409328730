import React from 'react';
import { Sector } from 'recharts';
import { colors } from '../../consts/colors';
import { fonts } from '../../consts/fonts';

export const renderActiveShape = (totalData: number, t: (text: string) => string) => (props: any) => {
	const RADIAN = Math.PI / 180;
	const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text
				x={cx}
				y={cy}
				dy={-8}
				textAnchor="middle"
				fill={colors.darkGray}
				fontSize={32}
				fontFamily={fonts.bold}>
				{totalData}
			</text>
			<text x={cx} y={cy} dy={32} textAnchor="middle" fill={colors.gray} fontSize={20} fontFamily={fonts.regular}>
				{t('dashboard_screen_total')}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${
				payload.name.split(' ')[1]
			}: ${value} ${t('dashboard_screen_parts')}`}</text>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
				{`(${t('dashboard_screen_rate')} ${((Number(value) / totalData) * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

export function renderCustomLegend(t: (text: string) => string) {
	return (props: any) => {
		const { payload } = props;

		return (
			<div>
				{payload.map((entry: any, index: number) => (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: index === payload.length - 1 ? '0' : '25px',
							transform: 'translateX(55px)',
						}}>
						<div
							style={{
								width: '22px',
								height: '22px',
								borderRadius: '11px',
								backgroundColor: entry.color,
								marginRight: '15px',
								marginTop: 'auto',
								marginBottom: 'auto',
								transform: 'translateY(0.5px)',
							}}
						/>
						<span
							style={{
								fontFamily: fonts.regular,
								fontSize: '22px',
								color: colors.gray,
								lineHeight: '29px',
							}}>
							{t(entry.value)}
						</span>
					</div>
				))}
			</div>
		);
	};
}
