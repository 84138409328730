import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useAuthStateContext } from '../context/auth/useAuth';
import { filterData } from '../helpers/filters';
import { goToExpenses, goToProcessContracts, goToStandardCase, goToWorkingTime } from '../helpers/navigationHelper';
import { textsForStandardAutomaticalUpdate } from '../helpers/standardInputHelper/generalStandardInputHelper';
import { KeyValue } from '../helpers/userHelper/generalUserHelper';
import { getUserOptionsKeyValuePairs } from '../helpers/userHelper/readUserHelper';
import StandardCaseOverviewScreen from '../screens/StandardCaseOverviewScreen';
import service from '../service/service';
import StandardCaseInput, { StandardCaseFilterInterface } from '../service/types/StandardCase';
import { UserRole } from '../service/types/User';
import { standardCaseInputFields } from './StandardCaseInputContainer';
import Contract from '../service/types/Contract';
import { unstable_batchedUpdates } from 'react-dom';
import { getEarliestDate } from '../helpers/timeAndDate';
import { IntervalDatePickerDates, IntervalKey } from '../components/IntervalDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEY, languages } from '../consts/language';
import { generateAndDownloadExcel } from '../helpers/fileDownloader';

export const initStandardCaseInputFilters: StandardCaseFilterInterface = {
	partNumber: '',
	qai: '',
	checkNumber: '',
	deliveryNoteDate: null,
	deliveryNoteNumber: '',
	partsIO: '',
	partsNIO: '',
	partsReworked: '',
	totalPartsTested: '',
	typeOfInspection: '',
	approved: '',
	toDate: moment(),
	fromDate: moment(),
	shift: '',
};

interface Props {
	contractData: Contract;
}
const data = Object.freeze({ ...initStandardCaseInputFilters });
export type StandardCaseFilterKeys = keyof typeof data;

function StandardCaseOverviewContainer(props: Props) {
	const methods = useForm();
	const { user } = useAuthStateContext();
	const [updatedText, setUpdatedText] = React.useState('');
	const [standardCaseInputs, setStandardCaseInputs] = React.useState<StandardCaseInput[]>([]);
	const [allAssignedQAIs, setAllAssignedQAIs] = React.useState<KeyValue[]>([]);
	const [filters, setFilters] = React.useState<StandardCaseFilterInterface>(initStandardCaseInputFilters);
	const allStandardCaseInputs = React.useRef<StandardCaseInput[]>([]);
	const history = useHistory();
	const timer = React.useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));
	const fetching = React.useRef(false);
	const { t } = useTranslation();
	const [intervalDates, setIntervalDates] = React.useState<IntervalDatePickerDates>({
		fromDate: null,
		toDate: null,
	});

	const onFilterChange = (key: StandardCaseFilterKeys, value: any) => {
		const tempValue = value === ' ' ? '' : value;
		setFilters((prev) => ({ ...prev, [key]: tempValue }));
	};

	const onBackArrowPress = () => goToProcessContracts(history);

	const clearAllFilters = () => {
		Object.values(standardCaseInputFields).forEach((value) => methods.setValue(value, ''));
		setFilters(initStandardCaseInputFilters);
	};

	const initializeContainer = React.useCallback(async () => {
		try {
			const standardCaseInputs = await service.getAllStandardCaseInputs(props.contractData.id);
			let qais: KeyValue[];

			if (user?.role !== UserRole.QualityAssuranceInspector && !fetching.current) {
				fetching.current = true;
				qais = await getUserOptionsKeyValuePairs(service.getAllUsers(), () => {});
			}

			allStandardCaseInputs.current = standardCaseInputs;

			const smallestDate = getEarliestDate(standardCaseInputs);

			unstable_batchedUpdates(() => {
				setStandardCaseInputs(standardCaseInputs);
				setFilters({
					...initStandardCaseInputFilters,
					fromDate: smallestDate,
				});
				setUpdatedText(textsForStandardAutomaticalUpdate[0]);
				qais && setAllAssignedQAIs(qais.filter((qai) => qai.value.role !== UserRole.Customer));
			});
		} catch (e) {
			fetching.current = false;
		}
	}, [props.contractData.id, user?.role]);

	React.useEffect(() => {
		initializeContainer();
	}, [initializeContainer]);

	React.useEffect(() => {
		setStandardCaseInputs(filterData(filters, allStandardCaseInputs.current));
	}, [filters]);

	const getDataAutomaticly = React.useCallback((contractID: number, cycleNumber: number) => {
		return setTimeout(() => {
			setUpdatedText(textsForStandardAutomaticalUpdate[cycleNumber === 0 ? 5 : cycleNumber]);
			if (cycleNumber === 0) {
				service
					.getAllStandardCaseInputs(contractID)
					.then((standardCaseInputs) => {
						unstable_batchedUpdates(() => {
							setStandardCaseInputs(standardCaseInputs);
							allStandardCaseInputs.current = standardCaseInputs;
							setUpdatedText(textsForStandardAutomaticalUpdate[0]);
							timer.current = getDataAutomaticly(contractID, 1);
						});
					})
					.catch(() => {});
			} else if (cycleNumber === 4) {
				timer.current = getDataAutomaticly(contractID, 0);
			} else {
				timer.current = getDataAutomaticly(contractID, cycleNumber + 1);
			}
		}, 60000);
	}, []);

	React.useEffect(() => {
		if (user?.role !== UserRole.QualityAssuranceInspector) {
			timer.current = getDataAutomaticly(props.contractData.id, 1);
		}

		return () => {
			clearTimeout(timer.current);
		};
	}, [props.contractData.id, user, getDataAutomaticly]);

	const approveData = (standardInputCase: StandardCaseInput) => {
		service
			.aprroveInput(props.contractData.id, standardInputCase.id)
			.then(() => {
				clearTimeout(timer.current);
				service
					.getAllStandardCaseInputs(props.contractData.id)
					.then((standardCaseInputs) => {
						unstable_batchedUpdates(() => {
							setStandardCaseInputs(standardCaseInputs);
							allStandardCaseInputs.current = standardCaseInputs;
							setUpdatedText(textsForStandardAutomaticalUpdate[0]);
						});
					})
					.catch(() => {});
				timer.current = getDataAutomaticly(props.contractData.id, 1);
			})
			.catch(() => {});
	};

	const onWorkingTimePress = () => {
		goToWorkingTime(history, {
			contractID: props.contractData.id,
			contractNumber: props.contractData.contractNumber,
		});
	};

	const onExpensesPress = () => {
		goToExpenses(history, {
			contractID: props.contractData.id,
			contractNumber: props.contractData.contractNumber,
		});
	};

	const onViewPress = (standardInputCase: StandardCaseInput) => {
		goToStandardCase(history, {
			contract: props.contractData,
			data: standardInputCase,
		});
	};

	const onNewErrorCasePress = () =>
		goToStandardCase(history, {
			contract: props.contractData,
		});

	const onDateChange = React.useCallback(
		(date: MaterialUiPickersDate) => {
			const offset = moment(date).utcOffset();
			let dateMoment = moment(date).add(offset);

			if (!intervalDates.fromDate) {
				setIntervalDates({
					fromDate: dateMoment,
					toDate: null,
				});
				return;
			}

			if (
				intervalDates.fromDate &&
				!intervalDates.toDate &&
				moment(dateMoment).isSameOrAfter(intervalDates.fromDate)
			) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));

				return;
			}

			setIntervalDates({
				fromDate: dateMoment,
				toDate: null,
			});

			return;
		},
		[intervalDates]
	);

	const onKeyboardDateChange = (date: MaterialUiPickersDate, key: IntervalKey) => {
		const dateMoment = moment(date).utcOffset(0, true);
		methods.clearErrors(['fromDate', 'toDate']);
		if (key === 'start') {
			if (moment(dateMoment).isSameOrBefore(intervalDates.toDate) || !intervalDates.toDate) {
				setIntervalDates((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					fromDate: dateMoment,
				}));
			} else {
				methods.setError('fromDate', { message: t('start_interval_error') });
			}
		} else if (key === 'end') {
			if (moment(dateMoment).isSameOrAfter(intervalDates.fromDate)) {
				setIntervalDates((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
				setFilters((prev) => ({
					...prev,
					toDate: dateMoment,
				}));
			} else {
				methods.setError('toDate', { message: t('end_interval_error') });
			}
		}
	};

	const onDatesAccept = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: intervalDates.fromDate,
			toDate: intervalDates.toDate,
		}));
	};

	const onDatesCancel = () => {
		const prevDates: IntervalDatePickerDates = {
			fromDate: filters.fromDate,
			toDate: filters.toDate,
		};
		setIntervalDates(prevDates);
	};

	const clearDates = () => {
		setFilters((prev) => ({
			...prev,
			fromDate: null,
			toDate: null,
		}));

		setIntervalDates({
			fromDate: null,
			toDate: null,
		});
	};

	const downloadTable = async () => {
		const lang = localStorage.getItem(LANGUAGE_KEY) ?? languages.deutchland.i18n;
		if (props.contractData.id) {
			if (filters.fromDate) {
				const offset = moment(filters.fromDate).utcOffset();
				filters.fromDate = moment(filters.fromDate).add(offset);
			}
			if (filters.toDate) {
				const offset = moment(filters.toDate).utcOffset();
				filters.toDate = moment(filters.toDate).add(offset);
			}
			const excelData = await service.createProcessContractStandardErrorInputsExcelSheet(
				props.contractData.id,
				filters,
				lang
			);
			excelData &&
				generateAndDownloadExcel(
					excelData,
					t('export_table_process_contract_overview'),
					props.contractData.contractNumber
				);
		}
	};

	return (
		<FormProvider {...methods}>
			<StandardCaseOverviewScreen
				intervalDates={intervalDates}
				contractData={props.contractData}
				onWorkingTimePress={onWorkingTimePress}
				filters={filters}
				allAssignedQAIs={allAssignedQAIs}
				standardCaseInputs={standardCaseInputs}
				updatedText={updatedText}
				onBackArrowPress={onBackArrowPress}
				onFilterChange={onFilterChange}
				onNewErrorCasePress={onNewErrorCasePress}
				clearAllFilters={clearAllFilters}
				onViewPress={onViewPress}
				onApprovePress={approveData}
				onExpensesPress={onExpensesPress}
				onKeyboardDateChange={onKeyboardDateChange}
				onDateChange={onDateChange}
				onDatesAccept={onDatesAccept}
				onDatesCancel={onDatesCancel}
				clearDates={clearDates}
				downloadTable={downloadTable}
			/>
		</FormProvider>
	);
}

export default StandardCaseOverviewContainer;
