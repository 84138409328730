import { isMoment } from 'moment';
import { Sort } from '../components/ContractList';

export enum SortingDir {
	ASC = 'asc',
	DCS = 'dsc',
}

interface Props {
	data: any;
	sort: Sort;
}

export const dataSorter = ({ data, sort }: Props) => {
	if (!sort.field) return data;
	const sortedData = [...data].sort((a: any, b: any) => {
		if (!sort.field) return 0;
		if (a[sort.field] === undefined || b[sort.field] === undefined) return -1;

		if (typeof a[sort.field] === 'string') {
			return a[sort.field].localeCompare(b[sort.field], 'de', {
				numeric: true,
				sensitivity: 'base',
			});
		} else if (typeof a[sort.field] === 'number') {
			return a[sort.field] - b[sort.field];
		} else if (isMoment(a[sort.field])) {
			return b[sort.field] - a[sort.field];
		} else return a[sort.field].localeCompare(b[sort.field]);
	});

	return sort.direction === SortingDir.ASC ? sortedData : sortedData.reverse();
};
