import * as React from 'react';

function FilterIcon(props: any) {
	return (
		<svg width={18} height={17} viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>{'filter_icon'}</title>
			<path
				d="M1.5 1.5h15l-6 8v6h-3v-6z"
				fill="#FFF7CC"
				stroke="#111"
				strokeWidth={1.5}
				fillRule="evenodd"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default FilterIcon;
